export default function imageResolutionValidator(file) {
  return new Promise((resolve, reject) => {
    if (file.type.includes('image')) {
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.naturalWidth >= 10 && img.naturalHeight >= 50) {
          resolve({
            isImageValid: true
          });
        }
        reject({
          message:
            'Image size invalid. Recommended image size is at least 100x50 and maximum 4096x4096'
        });
      };
    } else {
      resolve({
        isImageValid: true
      });
    }
  });
}
