import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../components/header';
import { Common } from '../constants';
import PageContentWrapper from '../components/pageContentWrapper';
import PageContent from '../components/pageContent';
import RoleManagement from '../components/roleManagement';
import '../styles/roleManagement.css';
import {
  getRoles,
  getUsersWithRole,
  addUserToRole,
  updateUserToRole,
  deleteUser
} from '../actions/roleManagement.action';

function RoleManagementScreen(props) {
  const getPageTitle = () => {
    return Common.NGO.PAGE_TITLE.ROLE_MANAGEMENT;
  };

  const getRoleManagementScreen = () => {
    return <RoleManagement {...props} />;
  };

  return (
    <React.Fragment>
      <Header pageTitle={getPageTitle()} />
      <PageContentWrapper>
        <PageContent mainScreen={getRoleManagementScreen()} admin={true} />
      </PageContentWrapper>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  state: state
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getRoles,
      getUsersWithRole,
      addUserToRole,
      updateUserToRole,
      deleteUser
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleManagementScreen);
