import { find } from 'lodash';
import { Routes } from '../constants/path.constants';
const groups = {
  NGO: {
    route: [
      Routes.NGO.CAMPAIGN_LIST,
      Routes.NGO.MISSION_LIST,
      Routes.NGO.CAMPAIGN_CREATE,
      Routes.NGO.DASHBOARD,
      Routes.NGO.CAMPAIGN_EDIT,
      Routes.NGO.ADMIN_CAMPAIGN_LIST,
      Routes.NGO.NGO_LIST,
      Routes.NGO.CORPORATE_LIST,
      Routes.NGO.DONATION_REPORT,
      Routes.NGO.TRANSACTIONS_LIST,
      Routes.NGO.GAMES_LIST,
      Routes.NGO.VIEW_PROJECT_REPORT,
      Routes.NGO.SUPPORT
    ] // for future implementation
  }
};

export const userIsAuthorized = (appName = 'NGO', userProfile) => {
  let group = find(groups, function(value, key) {
    return key === appName;
  });
  const isAdmin = userProfile.user_type === 1;
  if (group) {
    if (
      isAdmin ||
      find(group.route, function(v) {
        return (
          v === window.location.pathname ||
          window.location.pathname.indexOf(v) > -1
        );
      }) !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isAuthorized = permissions => {
  if (
    permissions === 'CRUD' ||
    permissions === 'CRU' ||
    permissions === 'CR' ||
    permissions === 'RU' ||
    permissions === 'U'
  ) {
    return true;
  }
  if (permissions === 'R') return false;
  if (!permissions) return false;
};

export const getUserRole = () => {
  const policies = JSON.parse(window.localStorage.getItem('userPolicies'));
  let userRole = false;
  if (policies) {
    if (policies[0].role === 'NGO_Superadmin') userRole = true;
  }
  return userRole;
};
