import React from 'react';

const DropDownDash = ({ data, value, setValue }) => {
  const getActiveClass = item => {
    return item === value ? 'text-primary' : '';
  };
  return (
    <div className="dropdown">
      <div type="button" className="dropdown-toggle" data-toggle="dropdown">
        <span className="px-2 fs-14">{value}</span>
      </div>
      <div className="dropdown-menu col-11 fs-16">
        {data.map((item, index) => (
          <div
            key={item + index}
            onClick={() => setValue(item)}
            className={`dropdown-item ${getActiveClass(item)}`}>
            <span className="fs-14">{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default DropDownDash;
