import { Action } from '../constants';
let sidebarStatus_InitialState = {
  isSidebarVisible: false,
  isLoggedIn: false,
  userRole: '',
  currentPagePath: ''
};
export default function sidebarStatus(
  state = sidebarStatus_InitialState,
  action = {}
) {
  switch (action.type) {
    case Action.SET_SIDEBAR_STATUS:
      return action.sidebarStatus;
    default:
      return state;
  }
}
