import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getInvoices } from '../actions/invoices.actions';
import Header from '../components/header';
import Invoices from '../components/invoices/invoice';
import PageContent from '../components/pageContent';
import PageContentWrapper from '../components/pageContentWrapper';
import { getSavedNGOData } from '../actions/completeRegistration.action';
import { getRegionalSettings } from '../actions';
import { Common } from '../constants';

function InvoicesScreen(props) {
  const [ngoInfo, setNgoInfo] = React.useState({});
  function getPageTitle() {
    return Common.NGO.PAGE_TITLE.INVOICES;
  }
  function getInvoices() {
    return (
      <div className="">
        <Invoices {...props} ngoInfo={ngoInfo} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Header pageTitle={getPageTitle()} setNgoInfo={setNgoInfo} />
      <PageContentWrapper>
        <PageContent mainScreen={getInvoices()}></PageContent>
      </PageContentWrapper>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  state: state,
  donationList: state.donation_list.donationList,
  loginInfo: state.login
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getInvoices,
      getSavedNGOData,
      getRegionalSettings
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesScreen);
