import React from 'react';
import InfoIcon from '../../assets/icons/conditional_info.svg';

const CheckboxList = ({
  options,
  itemsInEachCol,
  type,
  onChange,
  selectedItems
}) => {
  const setChecked = id => {
    let newSelectedItems;
    if (id === 'everyone') {
      if (selectedItems.includes('everyone')) {
        newSelectedItems = [];
      } else {
        newSelectedItems = ['everyone'];
      }
      onChange(newSelectedItems);
    } else {
      const index = selectedItems.indexOf('everyone');
      if (index !== -1) {
        newSelectedItems = [id];
      } else {
        const isSelected = selectedItems.includes(id);
        if (isSelected) {
          newSelectedItems = selectedItems.filter(x => x !== id);
        } else {
          newSelectedItems = [...selectedItems, id];
        }
      }
      onChange(newSelectedItems);
    }
  };

  const getCheckboxItem = _option => {
    const checked = selectedItems?.filter(x => x === _option.id);
    const isEveryOne = selectedItems?.filter(x => x === 'everyone').length;
    const isEveryOneNot = selectedItems?.filter(x => x !== 'everyone').length;
    return (
      <div key={_option.id} className={' d-flex align-items-center'}>
        <input
          type="checkbox"
          className=" tnc-checkbox"
          id={`${_option.name}-${_option.id}`}
          title="cause_category"
          name="primary-cause-checkbox"
          disabled={
            (_option.id !== 'everyone' && isEveryOne) ||
            (_option.id === 'everyone' && isEveryOneNot)
          }
          checked={!!checked?.length}
          onChange={() => setChecked(_option.id)}
        />
        <label
          className="form-check-label fs-14 text-1C1C1C ml-3"
          htmlFor="checkboxSelection">
          {_option.name}
        </label>
      </div>
    );
  };

  // const columnStyle = {
  //   flex: '1 1 auto',
  //   overflowY: 'auto',
  //   maxHeight: '300px'
  // };

  const columns = [];
  const numCols = Math.ceil(options?.length / itemsInEachCol);

  for (let i = 0; i < numCols; i++) {
    columns.push(
      <div key={i} className="col-12 px-0">
        {options &&
          options
            .slice(i * itemsInEachCol, (i + 1) * itemsInEachCol)
            .map(option => {
              const checkIsConditional = option.is_conditional;

              if (checkIsConditional) {
                return (
                  <div
                    key={option.id}
                    className="px-4 d-flex justify-content-between bg_EFF9FF my-2">
                    {getCheckboxItem(option)}
                    <div className="d-flex justify-content-center align-items-center cursor-pointer tooltipss mr-5 pr-3">
                      {' '}
                      <img src={InfoIcon} alt="Info" width={15} height={15} />
                      <span className="tooltip-text tooltipss-dark fs-12">
                        Conditional Approved
                      </span>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={option.id} className="px-4 my-2">
                    {getCheckboxItem(option)}
                  </div>
                );
              }
            })}
      </div>
    );
  }

  return <div className="col-12 px-0">{columns}</div>;
};

export default CheckboxList;
