import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  getCampaign,
  getActivitiesById,
  createOrModifyExpense,
  deleteBudgetSection
} from '../../actions';
import {
  getExpenseCategory,
  getExpenseSubCategory,
  getProjectExpensesById
} from '../../actions/mneDashboard.actions';
import {
  // InputDaypicker,
  InputSelect,
  InputText
} from '../FormElements';
import PopupModal from './popupModals';
import {
  convertInCommaFormat,
  getNumber
} from '../../helpers/commaSeparatedInput';
import { dateFormatMMDDYYYY } from '../component/dateFormat';
import AuthHideShow from '../authHideShow';
import Modal from '../newDesign/Modals/Modal';

var pIndex = 0;
var totalBudgetAmount = 0;
var errors = false;
var expenseSubCategoryListArr = [];
var adminName = window.location.href.split('#')[1];
class ProposedBudget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: this.props.id,
      campaignName: '',
      noOfPhases: 1,
      overAllBudget: 0,
      errorExists: false,
      phaseDetailsArr: [],
      deletedExpenseArr: [],
      deleteSectionArr: [],
      saveAndNext: false,
      isSaved: false,
      RequestedFundAmount: this.props.RequestedFundAmount,
      emptyCategory: '',
      subCategoryData: '',
      expenseCategoryList: [],
      expenseSubCategoryList: [],
      pIndex: 0,
      fieldErrors: false,
      showCancelPopup: false,
      inValidBudget: false,
      tempDeleteArr: [],
      showReSubmitProposal: false,
      originalArr: [],
      deleteRowOrPhase: false
    };
  }

  componentDidMount() {
    this.setState({ phaseDetailsArr: [] });
    if (this.props.id) this.props.getProjectExpensesById(this.props.id);
    this.addSection();
    this.initData();
    this.getExpenseCategory();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.id !== prevProps.id) {
      if (this.props.id) this.props.getProjectExpensesById(this.props.id);
    }
  }

  initData = () => {
    const sectionObj = {
      expense_category_id: this.createFieldObject(null, `expense_category_id`),
      expense_details: []
    };

    for (let i = 0; i < 1; i++) {
      const obj = {
        expense_description: this.createFieldObject(
          null,
          `expense_description`
        ),
        expense_sub_category_id: this.createFieldObject(
          null,
          `expense_sub_category_id`
        ),
        amount: this.createFieldObject(null, `amount`),
        quantity: this.createFieldObject(null, `quantity`),
        metrics: this.createFieldObject(null, `metrics`),
        justification: this.createFieldObject(null, `justification`)
      };
      sectionObj.expense_details.push(obj);
    }
    this.setState({ phaseDetailsArr: [sectionObj] });
  };

  handleCancelPopup = value => {
    let flag = this.checkForModifications();
    if (!flag) {
      toast('No changes detected to cancel');
    } else {
      this.setState({
        showCancelPopup: value
      });
    }
  };
  removeUnsavedData = () => {
    this.props.getProjectExpensesById(this.props.id);
    this.setState({
      showCancelPopup: false
    });
    this.componentDidMount();
  };
  getExpenseCategory() {
    this.props
      .getExpenseCategory()
      .then(res => {
        this.setState({ expenseCategoryList: res.result });
      })
      .catch(async err => {});
  }

  getExpenseSubCategory(expenseId, index) {
    this.props
      .getExpenseSubCategory(expenseId)
      .then(res => {
        expenseSubCategoryListArr[index] = res.result;
        this.setState({
          expenseSubCategoryList: expenseSubCategoryListArr
        });
      })
      .catch(async err => {});
  }

  filterSubCategories(categoryId, index) {
    let filteredSunCategoryList = this.props.allExpenseSubCategoryList?.filter(
      x => x.expense_category_id === parseInt(categoryId)
    );
    expenseSubCategoryListArr[index] = filteredSunCategoryList;
    this.setState({
      expenseSubCategoryList: expenseSubCategoryListArr
    });
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    var financeDetailsSuccessResponseArr = [];
    if (
      nextprops.editSuccessResponse &&
      nextprops.editSuccessResponse.code === 200
    ) {
      this.setState({ campaignName: nextprops.editSuccessResponse.title });
    }
    if (
      nextprops.financeDetailsSuccessResponse?.project_proposed_budget_list
        ?.length > 0 &&
      parseInt(window.location.pathname.split('/')[4]) === nextprops.id
    ) {
      let filteredCampaigns = nextprops.financeDetailsSuccessResponse?.project_proposed_budget_list.filter(
        x => x.amount !== null
      );
      financeDetailsSuccessResponseArr = filteredCampaigns;
    }

    if (
      nextprops.financeDetailsSuccessResponse &&
      nextprops.financeDetailsSuccessResponse.project_proposed_budget_list
        ?.length > 0 &&
      financeDetailsSuccessResponseArr?.length > 0 &&
      parseInt(window.location.pathname.split('/')[4]) === nextprops.id
    ) {
      if (
        nextprops.financeDetailsSuccessResponse.project_proposed_budget_list
          .length > 0
      ) {
        this.setState({ isSaved: true });
      }
      let expenseArr = [];
      let category = 0;
      let sectionObject = {};
      var catIds = [];
      var tempExpenseArr = [];
      var expenseDataArr = financeDetailsSuccessResponseArr;
      expenseDataArr.map((expense, index) => {
        catIds.push(expense.expense_category_id);
      });
      if (catIds?.length > 0) {
        catIds = [...new Set(catIds)];
        catIds.map((itm, idx) => {
          expenseDataArr.map((expense, index) => {
            if (expense.expense_category_id === itm) {
              tempExpenseArr.push(expense);
            }
          });
        });
        expenseDataArr = tempExpenseArr;
      }
      let subcatCount = 0;
      expenseDataArr.map((expense, index) => {
        if (category !== expense.expense_category_id) {
          if (expense.expense_category_id !== null) {
            this.filterSubCategories(expense.expense_category_id, subcatCount);
            subcatCount = subcatCount + 1;
          }

          if (Object.keys(sectionObject).length > 0) {
            expenseArr.push({ ...sectionObject });
            sectionObject = {};
          }
          sectionObject = {
            expense_category_id: expense.expense_category_id,
            expense_details: [
              {
                id: expense.id,
                expense_description: expense.expense_description,
                amount: expense.amount,
                quantity: expense.quantity,
                expense_sub_category_id: expense.expense_sub_category_id,
                expense_type: expense.expense_type,
                actual_amount: expense.actual_amount,
                expense_date: expense.expense_date,
                metrics: expense.metrics,
                justification: expense.justification,
                // reciept: expense.reciept
                reciept: []
              }
            ]
          };
          category = expense.expense_category_id;
        } else {
          sectionObject.expense_details.push({
            // id: expense.expense_category_id, *** PRE-EXISTING expense category id
            id: expense.id,
            expense_description: expense.expense_description,
            expense_sub_category_id: expense.expense_sub_category_id,
            amount: expense.amount,
            quantity: expense.quantity,
            expense_type: expense.expense_type,
            actual_amount: expense.actual_amount,
            expense_date: expense.expense_date,
            metrics: expense.metrics,
            justification: expense.justification,
            // reciept: expense.reciept
            reciept: []
          });
        }
        return false;
      });
      if (Object.keys(sectionObject).length > 0) {
        expenseArr.push(sectionObject);
      }
      if (!this.state.errorExists) {
        this.setPhaseDetailsArr(expenseArr);
        this.setState({
          originalArr: expenseArr
        });
      }
    } else {
      if (this.state.phaseDetailsArr.length) {
        if (!this.state.errorExists) {
          const mainTy = typeof this.state.phaseDetailsArr[0]
            ?.expense_category_id;
          if (mainTy === 'string' || mainTy === 'number') {
            this.setState({ phaseDetailsArr: [] });
          } else if (mainTy === 'object') {
            if (this.state.phaseDetailsArr[0]?.expense_category_id.id) {
              this.setState({ phaseDetailsArr: [] });
            }
          }
        }
      } else {
        this.initData();
      }
    }
  }

  getTotalSum() {
    let list = document.getElementsByClassName('sumtotal');
    let overAllAmt = 0;
    for (let i = 0; i < list.length; i++) {
      let number = String(list[i].textContent).replace(/[^\d.-]/g, '');
      overAllAmt = overAllAmt + parseInt(number);
    }
    return overAllAmt;
  }
  handleSaveFunctionCall(pIndex) {
    this.setState({ saveAndNext: false });
    let overAllAmt = this.getTotalSum();
    if (overAllAmt > this.state.RequestedFundAmount) {
      toast.error(
        'Total Fund Utilisation Amount is Greater than the Requested Funding Amount. Please arrange requested funding amount or Project proposed budget'
      );
    } else {
      if (this.state.tempDeleteArr?.length > 0) {
        this.state.tempDeleteArr.map(array => {
          this.props
            .deleteBudgetSection(array)
            .then(res => {
              this.props.getProjectExpensesById(this.props.id);
            })
            .catch(async err => {});
        });
      }
      this.saveActivity(pIndex);
      this.props.showNext('ProposedBudget');
      this.props.getCampaignProposalStatus(this.state.campaignId);
    }
  }

  checkForModifications() {
    let tempPhaseDetailsArr = this.state.phaseDetailsArr.slice();
    var flag = false;
    let filledRowExist = false;
    this.state.phaseDetailsArr.map((phase, index) => {
      phase.expense_details.map((item, idx) => {
        //code to flag the  type of section

        for (const key in item) {
          if (tempPhaseDetailsArr[index][key]?.id !== null) {
            filledRowExist = true;
          }
          if (
            tempPhaseDetailsArr[index]?.expense_details[idx][key].id === null &&
            tempPhaseDetailsArr[index]?.expense_details[idx][key].value !==
              '' &&
            tempPhaseDetailsArr[index]?.expense_details[idx][key].value !== 0 &&
            tempPhaseDetailsArr[index]?.expense_details[idx][key].value !== null
          ) {
            flag = true;
          }
        }
      });
    });
    if (filledRowExist && flag) {
      return true;
    } else if (filledRowExist && flag) {
      return false;
    } else if (filledRowExist === false && flag === true) {
      return true;
    } else if (filledRowExist === false && flag === true) {
      return false;
    }
  }
  ignoreErrorMessages() {
    let tempPhaseDetailsArr = this.state.phaseDetailsArr.slice();

    var expenseArrTemp = [];
    var flag = 0;
    var saveIndexArr = [];
    var errorResult = '';
    var idExists = false;
    var uniqueArray = [];
    var expenseDetailsArrTemp = [];
    // var obj = {};
    var sectionArr = [];

    this.state.phaseDetailsArr.map((phase, index) => {
      if (phase.expense_category_id.error === undefined) {
        idExists = true;
      } else {
        idExists = false;
        errorResult = this.validate(
          'expense_category_id',
          phase['expense_category_id'].value
        );
        if (errorResult.msg !== '') {
          if (
            tempPhaseDetailsArr[index]['expense_category_id'].error !==
            undefined
          ) {
            tempPhaseDetailsArr[index]['expense_category_id'].error =
              errorResult.msg;
          }
        }
        this.setState({
          phaseDetailsArr: tempPhaseDetailsArr,
          fieldErrors: true
        });
      }

      if (idExists) {
        expenseDetailsArrTemp = [];
        expenseArrTemp = [];
        phase.expense_details.map((item, idx) => {
          //code to flag the  type of section
          for (const key in item) {
            if (
              tempPhaseDetailsArr[index]?.expense_details[idx][key].id ===
                null &&
              tempPhaseDetailsArr[index]?.expense_details[idx][key].value !==
                '' &&
              tempPhaseDetailsArr[index]?.expense_details[idx][key].value !==
                0 &&
              tempPhaseDetailsArr[index]?.expense_details[idx][key].value !==
                null
            ) {
              flag = 1; //text typed
              saveIndexArr.push(idx);
            } else if (
              tempPhaseDetailsArr[index].expense_details[idx][key].id !== null
            ) {
              flag = 2; //row already exist
              saveIndexArr.push(idx);
            }
          }
        });

        /*************************************************************/

        if (flag === 0) {
          this.props.showNext('ActivitiesPlan');
        }
        if (flag === 1) {
          phase.expense_details.map((item, idx) => {
            for (const key in item) {
              if (
                tempPhaseDetailsArr[index]?.expense_details[idx][key].id ===
                  null &&
                (tempPhaseDetailsArr[index]?.expense_details[idx][key].value ===
                  '' ||
                  tempPhaseDetailsArr[index]?.expense_details[idx][key]
                    .value === 0 ||
                  tempPhaseDetailsArr[index]?.expense_details[idx][key]
                    .value === null)
              ) {
                const errorResult = this.validate(
                  key,
                  tempPhaseDetailsArr[index].expense_details[idx][key].value
                );
                if (errorResult.msg !== '') {
                  tempPhaseDetailsArr[index].expense_details[idx][key].error =
                    errorResult.msg;
                  errors = true;
                }
              } else {
                errors = false;
              }
            }
          });

          if (saveIndexArr?.length > 0) {
            uniqueArray = [...new Set(saveIndexArr)];
            for (let i = 0; i < uniqueArray?.length; i++) {
              expenseDetailsArrTemp.push(
                tempPhaseDetailsArr[index].expense_details[i]
              );
            }
            let obj = {
              expense_category_id: phase.expense_category_id,
              expense_details: expenseDetailsArrTemp
            };
            expenseArrTemp.push(obj);
          }
          sectionArr.push(expenseArrTemp);
        }
      }
    });
  }
  handleSaveAndNextFunctionCall(pIndex) {
    let overAllAmt = this.getTotalSum();
    if (overAllAmt > this.state.RequestedFundAmount) {
      toast.error(
        'Total Fund Utilisation Amount is Greater Than the Requested Funding Amount. Please arrange requested funding amount or Project proposed budget'
      );
    } else {
      this.setState({ saveAndNext: true });
      this.ignoreErrorMessages();
      if (this.state.tempDeleteArr) {
        this.state.tempDeleteArr.map(array => {
          this.props
            .deleteBudgetSection(array)
            .then(res => {
              this.props.getProjectExpensesById(this.props.id);
            })
            .catch(async err => {});
        });

        if (errors === false) {
          this.saveActivity();
          this.props.showNext('ActivitiesPlan');
          this.props.getCampaignProposalStatus(this.state.campaignId);
        }
      }
    }
  }
  closeProposalResubmission(e) {
    this.setState({
      showReSubmitProposal: false
    });
  }

  checkForResubmitModifications(pIndex) {
    let tempPhaseDetailsArr = this.state.phaseDetailsArr.slice();
    var flag = false;
    let filledRowExist = false;
    if (this.state.deleteRowOrPhase) {
      flag = true;
    }
    this.state.phaseDetailsArr.map((phase, index) => {
      var errorExists = this.checkErrorMessage(pIndex);
      phase.expense_details.map((item, idx) => {
        //code to flag the  type of section
        if (!errorExists) {
          for (const key in item) {
            if (tempPhaseDetailsArr[index][key]?.id !== null) {
              filledRowExist = true;
            }
            if (
              tempPhaseDetailsArr[index]?.expense_category_id !==
              this.state.originalArr[index]?.expense_category_id
            ) {
              flag = true;
            } else if (
              tempPhaseDetailsArr[index]?.expense_details[idx][key].id ===
                null &&
              tempPhaseDetailsArr[index]?.expense_details[idx][key].value !==
                '' &&
              tempPhaseDetailsArr[index]?.expense_details[idx][key].value !==
                0 &&
              tempPhaseDetailsArr[index]?.expense_details[idx][key].value !==
                null
            ) {
              flag = true;
            } else if (
              tempPhaseDetailsArr[index]?.expense_details[idx][key].id !==
                null &&
              tempPhaseDetailsArr[index]?.expense_details[idx][key].value !==
                this.state.originalArr[index]?.expense_details[idx][key]
            ) {
              flag = true;
            }
          }
        }
      });
    });

    this.setState({ deleteRowOrPhase: false });

    if (filledRowExist && flag) {
      return true;
    } else if (filledRowExist && flag) {
      return false;
    } else if (filledRowExist === false && flag === true) {
      return true;
    } else if (filledRowExist === false && flag === true) {
      return false;
    }
  }
  handleProposalResubmit(pIndex) {
    let flag = this.checkForResubmitModifications(pIndex);
    if (!flag) {
      toast('No changes detected');
    } else {
      let overAllAmt = this.getTotalSum();
      if (overAllAmt > this.state.RequestedFundAmount) {
        toast.error(
          'Total Fund Utilisation Amount is Greater than the Requested Funding Amount. Please arrange requested funding amount or Project proposed budget'
        );
      } else {
        this.setState({
          showReSubmitProposal: true,
          saveAndNext: false
        });
      }
    }
  }
  handleNextFunctionCall(pIndex) {
    this.props.showNext('ActivitiesPlan');
    this.props.getCampaignProposalStatus(this.state.campaignId);
  }

  setPhaseDetailsArr(phaseArr) {
    let tempExpenseDetailsArr = [];
    phaseArr.map(expense => {
      const phase_obj = {
        expense_category_id: expense.expense_category_id,
        expense_details: []
      };
      expense.expense_details.map(item => {
        const activity_obj = {
          expense_description: this.createFieldObject(
            item.id,
            'expense_description',
            item.expense_description
          ),
          amount: this.createFieldObject(item.id, 'amount', item.amount),
          quantity: this.createFieldObject(item.id, 'quantity', item.quantity),

          metrics: this.createFieldObject(item.id, 'metrics', item.metrics),
          justification: this.createFieldObject(
            item.id,
            'justification',
            item.justification
          ),

          actual_amount: this.createFieldObject(
            item.id,
            'actual_amount',
            item.actual_amount
          ),
          expense_sub_category_id: this.createFieldObject(
            item.id,
            'expense_sub_category_id',
            item.expense_sub_category_id
          ),
          expense_type: this.createFieldObject(
            item.id,
            'expense_type',
            item.expense_type
          ),
          expense_date: this.createFieldObject(
            item.id,
            'expense_date',
            item.expense_date
          ),
          reciept: this.createFieldObject(item.id, 'reciept', [])
        };
        phase_obj.expense_details.push(activity_obj);

        return false;
      });
      tempExpenseDetailsArr.push(phase_obj);
      return false;
    });

    this.setState({
      phaseDetailsArr: tempExpenseDetailsArr
    });
  }

  createFieldObject(id, name, value) {
    return {
      id: id,
      idLabel: name,
      name: name,
      value: value !== undefined ? value : '',
      error: ''
    };
  }
  addSection(count) {
    const limit = count ? count : 1;
    let phaseDetailsArr = this.state.phaseDetailsArr.slice();

    for (let i = 0; i < limit; i++) {
      const sectionObj = {
        expense_category_id: this.createFieldObject(
          null,
          `expense_category_id`
        ),
        expense_details: []
      };

      for (let i = 0; i < 1; i++) {
        const obj = {
          // expense_category_id: this.createFieldObject(null, `expense_category_id`),
          expense_description: this.createFieldObject(
            null,
            `expense_description`
          ),
          expense_sub_category_id: this.createFieldObject(
            null,
            `expense_sub_category_id`
          ),
          amount: this.createFieldObject(null, `amount`),
          quantity: this.createFieldObject(null, `quantity`),
          metrics: this.createFieldObject(null, 'metrics'),
          justification: this.createFieldObject(null, 'justification')
        };
        sectionObj.expense_details.push(obj);
      }
      phaseDetailsArr.push(sectionObj);
    }
    this.setState({ phaseDetailsArr: phaseDetailsArr });
  }

  deletePhase(position, sectionId, campaignId) {
    const phaseDetailsArr = this.state.phaseDetailsArr;
    let savedPhaseFound;
    if (sectionId === null) {
      phaseDetailsArr.splice(position, 1);
    } else {
      phaseDetailsArr.splice(position, 1);
      this.state.phaseDetailsArr.map((phase, index) => {
        // this.setState({ deleteSectionArr: this.state.tempDeleteArr });

        if (index >= position && !phase.phase_objective?.id) {
          // const p_index = this.state.phaseDetailsArr.findIndex(
          //   x =>
          //     x.phase_objective.value === phase.phase_objective?.value &&
          //     !x.phase_objective.id
          // );
          // if (p_index > -1) this.state.phaseDetailsArr.splice(p_index, 1);
        } else if (index >= position && phase.phase_objective.id) {
          savedPhaseFound = true;
        }
        return false;
      });
      let data = {
        expense_category_id: sectionId, //  *** PRE-EXISTING expense category id
        campaign_id: campaignId
      };
      this.state.tempDeleteArr.push(data);
    }

    if (savedPhaseFound) {
      toast.error(
        "Aw Snap! Looks like the server couldn't delete the phase/s. Please try again in sometime"
      );
    }
    this.setState({
      phaseDetailsArr: phaseDetailsArr,
      noOfPhases: phaseDetailsArr?.length,
      deleteRowOrPhase: true
    });
  }
  addActivity(p_index) {
    let phaseDetailsArr = this.state.phaseDetailsArr.slice();
    const obj = {
      expense_description: this.createFieldObject(null, `expense_description`),
      expense_sub_category_id: this.createFieldObject(
        null,
        `expense_sub_category_id`
      ),
      amount: this.createFieldObject(null, `amount`),
      quantity: this.createFieldObject(null, `quantity`),
      metrics: this.createFieldObject(null, 'metrics'),
      justification: this.createFieldObject(null, 'justification')
    };

    phaseDetailsArr[p_index].expense_details.push(obj);
    this.setState({ phaseDetailsArr: phaseDetailsArr });
  }

  deleteActivity = (index, expense_index) => {
    let expenseArr = this.state.phaseDetailsArr.slice();
    let deletedExpenseArray = this.state.deletedExpenseArr.slice();

    const delObj = expenseArr[index].expense_details[expense_index];
    if (delObj.expense_description.id) {
      deletedExpenseArray.push(delObj);
    }
    expenseArr[index].expense_details.splice(expense_index, 1);
    this.setState({
      phaseDetailsArr: expenseArr,
      deletedExpenseArr: deletedExpenseArray,
      deleteRowOrPhase: true
    });
  };

  eventToValue(e, trimResults, toNumber) {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      if (value !== 'true' || value !== 'false') {
        value = Number(value);
      }
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  }
  Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    }
  };
  validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';

    if (value !== null && value !== undefined) {
      var hasNonZero = /\d*[1-9]\d*/.test(value.toString());
    }
    switch (name) {
      case 'expense_category_id':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please select the expense category';
        break;
      // case 'expense_sub_category_id':
      //   isFieldValid = this.Validations.required(value);
      //   msg = isFieldValid ? '' : 'Please select the expense sub category';
      //   break;
      case 'expense_description':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter the expense description';
        break;
      case 'amount':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid && hasNonZero ? '' : 'Please enter the amount';
        break;
      case 'quantity':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid && hasNonZero ? '' : 'Please enter the quantity';
        break;
      case 'metrics':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid && hasNonZero ? '' : 'Please enter the metric value';
        break;

      default:
    }
    return { isFieldValid, msg };
  };
  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  setFormErrorsAndValue = (event, callback, type) => {
    const idArr = event.target.id.split('__');
    let fieldId = idArr[0];
    let phaseId = parseInt(idArr[1]);
    let activityId = idArr.length === 3 ? parseInt(idArr[2]) : null;
    const { target } = event;
    let { value = '' } = target;
    if (event.target.maxLength !== undefined) {
      if (value?.length > event.target.maxLength) {
        toast.error('Max length should be ' + event.target.maxLength);
      }
      value = value.slice(0, event.target.maxLength);
    }
    if (!type) {
      value = this.eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = this.restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    const errorResult = this.validate(fieldId, value);
    let phaseDetailsArr = this.state.phaseDetailsArr.slice();

    if (phaseId && !activityId) {
      phaseDetailsArr[phaseId - 1][fieldId].value = value;
      phaseDetailsArr[phaseId - 1][fieldId].error = errorResult.msg;
    } else if (phaseId && activityId) {
      phaseDetailsArr[phaseId - 1].expense_details[activityId - 1][
        fieldId
      ].value = value;
      phaseDetailsArr[phaseId - 1].expense_details[activityId - 1][
        fieldId
      ].error = errorResult.msg;
    }

    this.setState({ phaseDetailsArr: phaseDetailsArr });
  };
  onHandleChangeOrBlur = (event, callback, type) => {
    this.setFormErrorsAndValue(event, callback, type);
  };

  handleCategoryChange = (index, event) => {
    let categoryIdArr = [];
    if (event.target.value !== 'Please select') {
      this.setState({ emptyCategory: '' });
    }
    let expenseDetailsArr = this.state.phaseDetailsArr.slice();
    if (expenseDetailsArr?.length > 0) {
      expenseDetailsArr.map((item, idx) => {
        if (item.expense_category_id.id !== null) {
          categoryIdArr.push(parseInt(item.expense_category_id));
        }
      });
    }

    if (categoryIdArr?.length > 0) {
      var isCategoryIdSelected = categoryIdArr.includes(
        parseInt(event.target.value)
      );
    }
    if (isCategoryIdSelected) {
      toast.error(
        'Category already selected for section ' +
          (categoryIdArr.indexOf(parseInt(event.target.value)) + 1) +
          '. Please edit the same section.'
      );
    } else {
      const catIndex = expenseDetailsArr.findIndex(
        x => x.expense_category_id === parseInt(event.target.value)
      );
      if (catIndex === -1) {
        expenseDetailsArr[index].expense_category_id = event.target.value;
      } else {
        toast.error(
          'Category already selected for section ' +
            (catIndex + 1) +
            '. Please edit the same section.'
        );
      }
    }
    if (expenseDetailsArr[index].expense_category_id !== null) {
      this.filterSubCategories(
        expenseDetailsArr[index].expense_category_id,
        index
      );
    }
    this.setState({ phaseDetailsArr: expenseDetailsArr });
  };

  onUploadSuceess = (fileUrl, event) => {
    let phaseDetailsArr = this.state.phaseDetailsArr.slice();
    const idArr = event.target.id.split('__');
    let fieldId = idArr[0];
    let phaseId = parseInt(idArr[1]);
    let activityId = parseInt(idArr[2]);
    phaseDetailsArr[phaseId - 1].expense_details[activityId - 1][
      fieldId
    ].value = fileUrl;
    this.setState({ phaseDetailsArr: phaseDetailsArr });
  };

  handlePhaseValueChange(e) {
    if (this.state.tempDeleteArr?.length > 0) {
      toast.error(
        'Please save your deleted budget changes before adding new section'
      );
    } else {
      const previousArrLen = this.state.phaseDetailsArr.length;
      const newArrLen = 1;
      this.setState({ noOfPhases: parseInt(e?.target?.value) });
      if (
        previousArrLen - newArrLen >= 0 ||
        this.state.phaseDetailsArr.length === 0
      ) {
        const count = 1;
        this.addSection(count);
      } else {
        this.deletePhase(parseInt(e.target.value));
      }
    }
  }

  checkErrorMessage(index) {
    let errorExists = false;
    let tempPhaseDetailsArr = this.state.phaseDetailsArr.slice();
    this.state.phaseDetailsArr.map((phase, p_index) => {
      if (
        phase.expense_category_id === null ||
        phase.expense_category_id === 'Please select'
      ) {
        toast.error('Please select expense category');
        this.setState({ emptyCategory: '' });
        phase.expense_details.map((item, idx) => {
          for (const key in item) {
            const errorResult = this.validate(key, item[key].value);
            if (errorResult.msg !== '') {
              phase.expense_details[idx][key].error = errorResult.msg;
              if (!errorExists) errorExists = true;
            }
          }
        });
      } else {
        this.setState({ emptyCategory: '' });
        phase.expense_details.map((item, idx) => {
          for (const key in item) {
            const errorResult = this.validate(key, item[key].value);
            if (errorResult.msg !== '') {
              phase.expense_details[idx][key].error = errorResult.msg;
              if (!errorExists) errorExists = true;
            }
          }
        });
      }
    });

    if (errorExists) this.setState({ phaseDetailsArr: tempPhaseDetailsArr });
    return errorExists;
  }
  saveActivity(p_index) {
    var errorExists = this.checkErrorMessage(p_index);
    this.setState({ errorExists: errorExists });
    if (this.state.saveAndNext === true) {
      errorExists = false;
      this.setState({ errorExists: errorExists });
    }

    if (errorExists) {
      return false;
    } else {
      const payloadObj = {
        campaign_id: this.props.id,
        expense_data: []
      };
      let expenseArr = [];
      this.state.phaseDetailsArr.map(section => {
        section.expense_details.map(expense => {
          let object = {
            expense_description: expense.expense_description.value,
            amount: parseInt(getNumber(expense.amount.value)),
            quantity: parseInt(getNumber(expense.quantity.value)),

            metrics: parseInt(getNumber(expense.metrics.value)),
            justification: expense.justification.value,

            expense_category_id: parseInt(section.expense_category_id), //  *** PRE-EXISTING expense category id
            expense_sub_category_id: parseInt(
              expense.expense_sub_category_id.value
            ),
            campaign_id: this.props.id,
            expense_type: expense?.expense_type?.value,
            actual_amount: parseInt(getNumber(expense?.actual_amount?.value)),
            expense_date: dateFormatMMDDYYYY(expense?.expense_date?.value),
            reciept:
              expense?.reciept?.value === null ? [] : expense?.reciept?.value
          };
          if (expense.expense_description.id)
            object['expense_id'] = expense.expense_description.id;
          expenseArr.push(object);
          return false;
        });
      });
      if (this.state.deletedExpenseArr.length > 0) {
        this.state.deletedExpenseArr.map(deletedExpense => {
          let object = {
            expense_id: deletedExpense.expense_description.id,
            is_deleted: true
          };
          expenseArr.push(object);
          return false;
        });
      }

      payloadObj.expense_data = [...expenseArr];

      this.props
        .createOrModifyExpense(payloadObj)
        .then(response => {
          this.props.getProjectExpensesById(this.props.id);
          toast.success('Budget saved successfully!');
          this.setState({ deletedExpenseArray: [], tempDeleteArr: [] });
        })
        .catch(error => {
          toast.error('Uh-Oh! Looks like we are having server issues.');
          console.error(error);
        });
    }
  }

  render() {
    return (
      <>
        <PopupModal
          showCancelPopup={this.state.showCancelPopup}
          handleCancelPopup={this.handleCancelPopup}
          expenseDataArr={this.state.existingArr}
          phaseDetailsArr={this.state.phaseDetailsArr}
          removeUnsavedData={this.removeUnsavedData}></PopupModal>

        {/* resubmit project proposal modal */}
        <Modal
          show={this.state.showReSubmitProposal}
          title={`Re-Submit new changes in Proposed Budget?`}
          handleCloseModal={e => this.closeProposalResubmission(e)}
          sizeClass="modal-md">
          <p className="mt-2 mb-5 fs-16 font-weight-normal">
            Are you sure you want to re-submit your new changes in Proposed
            Budget?
          </p>
          <div className="modal-footer " style={{ borderTop: 'none' }}>
            <button
              className="btn h-40 w-95 text-primary p-1 fs-14"
              onClick={e => this.closeProposalResubmission(e)}>
              Cancel
            </button>
            <button
              className="fs-14 btn btn-primary btn-lg fs-14 dm-bold ml-3"
              onClick={() => this.handleSaveFunctionCall(pIndex)}>
              Submit
            </button>
          </div>
        </Modal>

        <div className="d-flex justify-content-between  h-40 mx-5">
          <span className="fs-16 d-flex align-items-center">
            Proposed Budget
          </span>

          <AuthHideShow permissions={this.props.permissions}>
            <button
              className="btn btn-primary fs-14 dm-bold position-relative"
              onClick={e => this.handlePhaseValueChange(e)}>
              Add New Section
            </button>
          </AuthHideShow>
          {/* </div> */}
        </div>

        <div className="row p-4 create-campaign">
          <div className={`row`}>
            <div className="col-12 mt-5">
              <div
                className="bg-white pl-1"
                style={{
                  height: '55vh',
                  overflowY: 'scroll',
                  overflowX: 'hidden'
                }}>
                <div className="row justify-content-between mb-4"></div>

                {/* <hr className="section-divider w-100 mb-4" /> */}
                {this.state.phaseDetailsArr?.map((phase, index) => {
                  var sumTotal = 0;
                  pIndex = index;
                  return (
                    <>
                      {index > 0 && (
                        <hr className="section-divider w-100 mb-4" />
                      )}
                      <div className="row align-items-center">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                          className="col-md-4 px-0">
                          <InputSelect
                            infoIcon={true}
                            required={true}
                            labelText="Select Expense Category"
                            id={`sectionHeading${index + 1}`}
                            name="sectionHeading"
                            options={this.state.expenseCategoryList}
                            fieldValue={phase?.expense_category_id}
                            fieldError={phase?.expense_category_id?.error}
                            onHandleChange={e =>
                              this.handleCategoryChange(index, e)
                            }
                            dataType="num"
                            disableInput={false}
                            wrapperClassName={'col-11 ml-6 pl-0'}
                            permissions={this.props.permissions}
                          />
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2"></div>
                        {index >= 0 && (
                          <AuthHideShow permissions={this.props.permissions}>
                            <div className="col-md-1 pl-5">
                              <i
                                className="fas fa-trash-alt text-31AEFD fs-19 cursor-pointer position-relative pt-1"
                                style={{ right: '20px' }}
                                onClick={() =>
                                  this.deletePhase(
                                    index,
                                    phase.expense_category_id,
                                    this.state.campaignId
                                  )
                                }
                              />
                            </div>
                          </AuthHideShow>
                        )}
                      </div>

                      <div className="row text-left font-weight-medium fs-12">
                        <div className="col-2  py-3  bg-F8F8F8 ">
                          <span>DETAILS</span>
                        </div>
                        <div className="col-1  py-3  bg-F8F8F8 ">
                          <span>EXPENSE SUB-CATEGORY</span>
                        </div>
                        <div className="col-1  py-3 bg-F8F8F8 ">
                          <span>RATE(INR)</span>
                        </div>
                        <div className="col-1  py-3 bg-F8F8F8">
                          <span>QTY</span>
                        </div>
                        <div className="col-1  py-3 bg-F8F8F8">
                          <span>METRICS</span>
                        </div>
                        <div className="col-2  py-3 bg-F8F8F8">
                          <span>TOTAL(INR)</span>
                        </div>
                        <div className="col-1  py-3 bg-F8F8F8 ">
                          <span>% BUDGET</span>
                        </div>
                        <div className="col-2  py-3 bg-F8F8F8 ">
                          <span>JUSTIFICATION</span>
                        </div>
                        <div className="col-1 pl-2 pr-0"></div>
                      </div>

                      {phase.expense_details.map((activity, a_index) => {
                        if (
                          activity?.actual_amount?.value === null ||
                          activity?.actual_amount?.value === undefined ||
                          activity?.actual_amount?.value === '' ||
                          activity?.actual_amount?.value === 0
                        ) {
                          sumTotal =
                            getNumber(sumTotal) +
                            getNumber(activity.amount.value) *
                              getNumber(activity.quantity.value) *
                              getNumber(activity.metrics.value);
                          totalBudgetAmount = totalBudgetAmount + sumTotal;

                          return (
                            <>
                              <div
                                className="row row table-rows-proposal"
                                key={index + a_index}>
                                <InputText
                                  required={true}
                                  labelText={''}
                                  id={`${
                                    activity.expense_description.idLabel
                                  }__${index + 1}__${a_index + 1}`}
                                  name={`${
                                    activity.expense_description.name
                                  }__${index + 1}__${a_index + 1}`}
                                  maxLength={500}
                                  rows={3}
                                  PlaceHolder={
                                    'Maximum  Length is 500 Characters'
                                  }
                                  fieldError={
                                    activity.expense_description.error
                                  }
                                  onHandleChange={this.onHandleChangeOrBlur}
                                  fieldValue={
                                    activity.expense_description?.value
                                  }
                                  className="campaign_details campaign-textarea activity_border-radius border"
                                  // disableInput={!phase.expense_category_id}
                                  disableInput={
                                    phase.expense_category_id?.value === '' ||
                                    phase.expense_category_id ===
                                      'Please select'
                                  }
                                  wrapperClassName="col-2 w-100 pt-1 mb-0"
                                  permissions={this.props.permissions}
                                />
                                <InputSelect
                                  required={true}
                                  // PlaceHolder={'Rate'}
                                  labelText=""
                                  id={`${'expense_sub_category_id'}__${index +
                                    1}__${a_index + 1}`}
                                  name={`${'expense_sub_category_id'}__${index +
                                    1}__${a_index + 1}`}
                                  options={
                                    this.state.expenseSubCategoryList[index]
                                  }
                                  fieldValue={
                                    activity.expense_sub_category_id.value
                                  }
                                  fieldError={
                                    activity.expense_sub_category_id.error
                                  }
                                  onHandleChange={(e, c, t) =>
                                    this.onHandleChangeOrBlur(e, t, index)
                                  }
                                  className="campaign_details campaign-textarea activity_border-radius border"
                                  disableInput={
                                    phase.expense_category_id?.value === '' ||
                                    phase.expense_category_id ===
                                      'Please select'
                                  }
                                  wrapperClassName="col-1 w-100 pt-1 mb-0"
                                  permissions={this.props.permissions}
                                />
                                <InputText
                                  required={true}
                                  type="text"
                                  labelText={''}
                                  id={`${'amount'}__${index + 1}__${a_index +
                                    1}`}
                                  name={`${'amount'}__${index + 1}__${a_index +
                                    1}`}
                                  maxLength={13}
                                  rows={3}
                                  PlaceHolder={'Rate'}
                                  fieldError={activity.amount.error}
                                  onHandleChange={e =>
                                    this.onHandleChangeOrBlur(e)
                                  }
                                  // onHandleBlur={this.onHandleChangeOrBlur}
                                  fieldValue={convertInCommaFormat(
                                    getNumber(activity.amount.value)
                                  )}
                                  className="campaign_details campaign-textarea activity_border-radius"
                                  // disableInput={!phase.expense_category_id}
                                  disableInput={
                                    phase.expense_category_id?.value === '' ||
                                    phase.expense_category_id ===
                                      'Please select'
                                  }
                                  wrapperClassName="col-1 w-100 pt-1 mb-0"
                                  permissions={this.props.permissions}
                                />
                                <InputText
                                  required={true}
                                  type="text"
                                  labelText={''}
                                  id={`${'quantity'}__${index + 1}__${a_index +
                                    1}`}
                                  name={`${'quantity'}__${index +
                                    1}__${a_index + 1}`}
                                  maxLength={500}
                                  rows={3}
                                  PlaceHolder={'Quantity'}
                                  fieldError={activity.quantity.error}
                                  onHandleChange={e =>
                                    this.onHandleChangeOrBlur(e)
                                  }
                                  // onHandleBlur={this.onHandleChangeOrBlur}
                                  fieldValue={convertInCommaFormat(
                                    getNumber(activity.quantity.value)
                                  )}
                                  className="campaign_details campaign-textarea activity_border-radius"
                                  // disableInput={!phase.expense_category_id}
                                  disableInput={
                                    phase.expense_category_id?.value === '' ||
                                    phase.expense_category_id ===
                                      'Please select'
                                  }
                                  wrapperClassName="col-1 w-100 pt-1 mb-0"
                                  permissions={this.props.permissions}
                                />
                                <InputText
                                  required={true}
                                  type="text"
                                  labelText={''}
                                  id={`${'metrics'}__${index + 1}__${a_index +
                                    1}`}
                                  name={`${'metrics'}__${index + 1}__${a_index +
                                    1}`}
                                  maxLength={6}
                                  rows={3}
                                  PlaceHolder={'Metrics'}
                                  fieldError={activity.metrics.error}
                                  onHandleChange={e =>
                                    this.onHandleChangeOrBlur(e)
                                  }
                                  // onHandleBlur={this.onHandleChangeOrBlur}
                                  fieldValue={convertInCommaFormat(
                                    getNumber(activity.metrics.value)
                                  )}
                                  className="campaign_details campaign-textarea activity_border-radius"
                                  // disableInput={!phase.expense_category_id}
                                  disableInput={
                                    phase.expense_category_id?.value === '' ||
                                    phase.expense_category_id ===
                                      'Please select'
                                  }
                                  wrapperClassName="col-1 w-100 pt-1 mb-0"
                                  permissions={this.props.permissions}
                                />

                                <InputText
                                  required={true}
                                  type="text"
                                  labelText={''}
                                  id={`${'total'}__${index + 1}__${a_index +
                                    1}`}
                                  name={`${'total'}__${index + 1}__${a_index +
                                    1}`}
                                  maxLength={500}
                                  rows={3}
                                  PlaceHolder={
                                    'Maximum  Length is 1500 Characters'
                                  }
                                  onHandleChange={e =>
                                    this.onHandleChangeOrBlur(e)
                                  }
                                  fieldValue={` ${
                                    activity.amount?.value === '' ||
                                    activity.quantity?.value === ''
                                      ? '₹'
                                      : '₹'
                                  } ${convertInCommaFormat(
                                    getNumber(activity.amount?.value) *
                                      getNumber(activity.quantity?.value) *
                                      getNumber(activity.metrics?.value)
                                  )}`}
                                  className="campaign_details campaign-textarea activity_border-radius"
                                  disableInput={true}
                                  wrapperClassName="col-2 w-100 pt-1 mb-0"
                                  permissions={this.props.permissions}
                                />
                                {/* </span> */}
                                <InputText
                                  required={true}
                                  type="text"
                                  labelText={''}
                                  id={`${'budget'}__${index + 1}__${a_index +
                                    1}`}
                                  name={`${'budget'}__${index + 1}__${a_index +
                                    1}`}
                                  maxLength={500}
                                  rows={3}
                                  PlaceHolder={
                                    'Maximum  Length is 1500 Characters'
                                  }
                                  // fieldError={activity.activity.error}
                                  onHandleChange={e =>
                                    this.onHandleChangeOrBlur(e)
                                  }
                                  // onHandleBlur={this.onHandleChangeOrBlur}
                                  fieldValue={`${(
                                    ((getNumber(activity.amount?.value) *
                                      getNumber(activity.quantity?.value) *
                                      getNumber(activity.metrics?.value)) /
                                      this.state.RequestedFundAmount) *
                                    100
                                  ).toFixed(2)}%`}
                                  className=" campaign_details campaign-textarea activity_border-radius "
                                  disableInput={true}
                                  wrapperClassName="col-1 w-100 pt-1 mb-0 bg-e8eaeb"
                                  permissions={this.props.permissions}
                                />
                                <InputText
                                  required={true}
                                  labelText={''}
                                  id={`${'justification'}__${index +
                                    1}__${a_index + 1}`}
                                  name={`${'justification'}__${index +
                                    1}__${a_index + 1}`}
                                  maxLength={2500}
                                  rows={3}
                                  PlaceHolder={
                                    'Maximum  Length is 2500 Characters'
                                  }
                                  fieldError={activity.justification.error}
                                  onHandleChange={e =>
                                    this.onHandleChangeOrBlur(e)
                                  }
                                  fieldValue={activity.justification?.value}
                                  className="campaign_details campaign-textarea activity_border-radius border"
                                  // disableInput={!phase.expense_category_id}
                                  disableInput={
                                    phase.expense_category_id?.value === '' ||
                                    phase.expense_category_id ===
                                      'Please select'
                                  }
                                  wrapperClassName="col-2 w-100 pt-1 mb-0"
                                  permissions={this.props.permissions}
                                />

                                <div className="col-1 d-flex">
                                  <AuthHideShow
                                    permissions={this.props.permissions}>
                                    {a_index >= 0 && (
                                      <i
                                        className="fas fa-trash-alt cursor-pointer text-31AEFD fs-19 mt-2 pt-1"
                                        onClick={() =>
                                          this.deleteActivity(index, a_index)
                                        }></i>
                                    )}
                                  </AuthHideShow>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })}

                      <div
                        className="row col-11 "
                        style={{ backgroundColor: '#F8DD8D' }}>
                        <td className="col-6 pl-4 position-relative l-8">
                          Section Total
                        </td>
                        <td className="col-2 position-relative l-86 sumtotal">
                          ₹ {new Intl.NumberFormat('en-IN').format(sumTotal)}
                        </td>
                        <td className="col-2 position-relative l-106">
                          {(
                            (getNumber(sumTotal) /
                              getNumber(this.state.RequestedFundAmount)) *
                            100
                          ).toFixed(2)}
                          {'%'}
                        </td>
                      </div>

                      <AuthHideShow permissions={this.props.permissions}>
                        <div className="row mx-4">
                          <div className="col-12 pl-0 mt-2 mb-3 d-flex">
                            <button
                              type="button"
                              onClick={() => this.addActivity(index)}
                              className="btn btn-custom-outline-0193F4 font-weight-bold fs-14"
                              style={{
                                backGroundColor: '#31AEFD',
                                height: '34px',
                                width: '166px',
                                marginLeft: '-20px',
                                marginTop: '10px'
                              }}
                              disabled={phase.activity_details?.length === 5}>
                              Add New Row
                            </button>
                          </div>
                        </div>
                      </AuthHideShow>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          {/************Sticky bottom bar***************/}
          <AuthHideShow permissions={this.props.permissions}>
            <div className="stickyBottomNav p-4">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-5">
                  {this.props.currentCampaignStatus !== '' && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-lg fs-14 dm-bold"
                      onClick={() => this.props.previewCampaign('ACTIVE')}>
                      Preview Project
                    </button>
                  )}
                </div>
                <div className="col-6">
                  <div className="float-right">
                    <span
                      className="pl-4 fs-14 dm-medium color-007bff cursor-pointer"
                      onClick={() => this.handleCancelPopup(true)}>
                      Cancel
                    </span>

                    {!this.state.enableSubmitCampaignBtn && (
                      <>
                        <span
                          className="pl-4 fs-14 dm-medium color-007bff cursor-pointer"
                          onClick={e =>
                            this.props.showNext('ConceptNoteDetails', 0)
                          }>
                          Previous
                        </span>
                        <button
                          type="button"
                          onClick={() =>
                            this.props.currentCampaignStatus ===
                              'WAITING_FOR_FUNDING' && adminName === undefined
                              ? this.handleProposalResubmit(pIndex)
                              : this.handleSaveFunctionCall(pIndex)
                          }
                          className={`btn ${
                            this.props.currentCampaignStatus ===
                              'WAITING_FOR_FUNDING' && adminName === undefined
                              ? 'btn-primary'
                              : 'btn-outline-primary'
                          } btn-lg fs-14 dm-bold ml-3 `}>
                          {this.props.currentCampaignStatus ===
                            'WAITING_FOR_FUNDING' && adminName === undefined
                            ? 'Submit'
                            : 'Save'}
                        </button>
                      </>
                    )}

                    {!this.state.enableSubmitCampaignBtn &&
                      this.props.currentCampaignStatus !==
                        'WAITING_FOR_FUNDING' && (
                        <button
                          type="button"
                          onClick={() =>
                            this.handleSaveAndNextFunctionCall(pIndex)
                          }
                          className="btn btn-primary btn-lg fs-14 dm-bold ml-3">
                          Save & Next
                        </button>
                      )}

                    {!this.state.enableSubmitCampaignBtn &&
                      this.props.currentCampaignStatus ===
                        'WAITING_FOR_FUNDING' && (
                        <button
                          type="button"
                          onClick={() => this.handleNextFunctionCall(pIndex)}
                          className="btn btn-outline-primary btn-lg fs-14 dm-bold ml-3">
                          Next
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </AuthHideShow>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  editSuccessResponse: state.campaign.editSuccessResponse,
  editErrorResponse: state.campaign.editErrorResponse,
  userInfo: state.login.userInfo,
  financeDetailsSuccessResponse: state.mne.financeDetailsSuccessResponse,
  financeCreateModifySuccessResponse:
    state.mne.financeCreateModifySuccessResponse
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCampaign,
      createOrModifyExpense,
      getExpenseCategory,
      getExpenseSubCategory,
      getProjectExpensesById,
      getActivitiesById,
      deleteBudgetSection
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProposedBudget);
