import React from 'react';
export default function RadioButton(props) {
  let {
    uniqueIdentifierForInput,
    label,
    value,
    checked,
    // required,
    disabled,
    masterfield,
    visibilityFunc
  } = props;
  const inputProps = {
    value: value,
    name: uniqueIdentifierForInput
  };
  if (checked) {
    inputProps.defaultChecked = true;
  }
  if (disabled) {
    inputProps.disabled = true;
  }
  const handleChange = e => {
    if (masterfield) {
      visibilityFunc(masterfield, e.target.value);
    }
    if (props.fieldUpdated && props.stepIndex >= 0)
      props.fieldUpdated(props.stepIndex);
    if (label === 'no') {
      props.setFCRAFalse();
    } else {
      props.setFCRATrue();
    }
  };
  return (
    <label className="custom-radio">
      {label}
      <input
        type="radio"
        name="radio"
        {...inputProps}
        // required={props.required}
        onChange={e => {
          handleChange(e);
        }}
      />
      <span className="checkmark"></span>
    </label>
  );
}
