import React from 'react';
import '../styles/modal-styles.css';
import closeIcon from '../assets/images/close-button.png';

const ModalSide = props => {
  let body = document.body;
  if (props.showModal) {
    body.style.overflow = 'hidden';
  } else {
    body.style.overflow = 'auto';
  }

  const closeModal = e => {
    if (!props.disableOverlayClick) {
      props.handleCloseModal(e);
    }
    return false;
  };
  const handleModalTitle = () => {
    if (props.title !== '') {
      return (
        <div className="d-flex justify-content-between p-4">
          <span className="modal-title fs-16 color-344256">{props.title}</span>
          {!props.hideCancel && (
            <img
              src={closeIcon}
              alt="close"
              className="close-button-sm cursor-pointer"
              onClick={e => props.handleCloseModal(e)}
            />
          )}
        </div>
      );
    } else return '';
  };
  return (
    <>
      <div
        className={`side-modal-overlay ${!props.showModal ? 'd-none' : ' '}`}
        onClick={e => closeModal(e)}></div>
      <div
        className={`side-modal ${props.className} ${
          !props.showModal ? 'd-none' : ' '
        }`}>
        {handleModalTitle()}
        <>{props.children}</>
      </div>
    </>
  );
};

export default ModalSide;
