/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSecondaryObjectiveList } from '../actions/common.actions';
import completeRegistrationData from '../constants/completeRegistrationData';
import getValueAtPath from '../helpers/getValueAtPath';
import MultiSelect from './multiSelect';
import RadioButton from './radioButton';
import CheckboxComponent from './checkboxComponent';
import FileInput from './fileInput';
import FileInputMultiple from './fileInputMultiple';
import FileInputMultipleNew from './fileInputMultipleNew';
import Text from './text';
import TextArea from './textArea';
import DateInput from './dateInput';
import YearInput from './yearInput';
import TextNumber from './textNumber';
import FinancialYearPicker from './component/financialYearPicker';
import InfoIcon from '../assets/icons/infoGray_icon.svg';
const $ = window.$;
// import { isSectionSaved } from '../helpers/profileHelpers';
// const pincode = require('pincode-lat-long');
// var latLong;

/**************************************pin code**************/
// function displayLocation(latitude, longitude, locationUpdated) {
//   var request = new XMLHttpRequest();
//   var method = 'GET';
//   var url =
//     'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
//     latitude +
//     ',' +
//     longitude +
//     '&sensor=true&key=AIzaSyCGjwV7ui5Av5JYF9EOjDxaV6dJVDgPDYE';
//   var async = true;

//   request.open(method, url, async);
//   request.onreadystatechange = function() {
//     if (request.readyState === 4 && request.status === 200) {
//       var data = JSON.parse(request.responseText);
//       // var address = data.results[0];
//       var address = data.results[0].formatted_address.split(',');

//       let cityVal = address[address.length - 3].split(' ');
//       const stateFinalVal = address[address.length - 2].replace(/[0-9]/g, '');
//       const cityFinalVal = cityVal[cityVal.length - 1].replace(/[0-9]/g, '');
//       document.getElementById('state').value = address[
//         address.length - 2
//       ].replace(/[0-9]/g, '');
//       document.getElementById('city').value = cityVal[
//         cityVal.length - 1
//       ].replace(/[0-9]/g, '');
//       locationUpdated(stateFinalVal, cityFinalVal);
//     } else {
//       document.getElementById('state').value = '--';
//       document.getElementById('city').value = '--';
//       locationUpdated(null, null);
//     }
//   };
//   request.send();
// }
function displayLocations(pincode, locationUpdated) {
  var request = new XMLHttpRequest();
  var method = 'GET';
  var url =
    'https://maps.googleapis.com/maps/api/geocode/json?address=' +
    pincode +
    '&sensor=true&key=AIzaSyDxc9HLGnlXL0JWiftdZaVEv5quIAmTsyo';
  var async = true;

  request.open(method, url, async);
  request.onreadystatechange = function() {
    if (request.readyState === 4 && request.status === 200) {
      var data = JSON.parse(request.responseText);
      // var address = data.results[0];

      var address = data?.results[0]?.formatted_address?.split(',');
      var address_components = data?.results[0]?.address_components;
      let stateFinalVal = '';
      let cityFinalVal = '';
      let cityVal = '';
      if (address) {
        if (address.length < 3) {
          cityVal = address_components[1].long_name;
          stateFinalVal = address[address.length - 1].replace(/[0-9]/g, '');
          cityFinalVal = cityVal.replace(/[0-9]/g, '');
          document.getElementById('city').value = cityVal;
        } else {
          cityVal = address[address.length - 3].split(' ');
          stateFinalVal = address[address.length - 2].replace(/[0-9]/g, '');
          cityFinalVal = cityVal[cityVal.length - 1].replace(/[0-9]/g, '');
          document.getElementById('city').value = cityVal[
            cityVal.length - 1
          ].replace(/[0-9]/g, '');
        }

        document.getElementById('state').value = address[
          address.length - 2
        ].replace(/[0-9]/g, '');

        locationUpdated(stateFinalVal, cityFinalVal);
      }
    } else {
      document.getElementById('state').value = '--';
      document.getElementById('city').value = '--';
      locationUpdated(null, null);
    }
  };
  request.send();
}
function getSavedValue(ngoSavedData, stepIndex, uniqueIdentifierForInput) {
  const wrapperKey = completeRegistrationData[stepIndex].wrapperKey;
  let todayDate = new Date();
  const field = completeRegistrationData[stepIndex].fields.find(
    x => x.jsonfield === uniqueIdentifierForInput
  );

  if (ngoSavedData && ngoSavedData[wrapperKey]) {
    // for (var i = 0; i < x)
    if (
      uniqueIdentifierForInput ===
      `balance_sheet_for_fy_${todayDate.getFullYear() -
        2}_-_${todayDate.getFullYear() - 1}`
    ) {
      let test_val =
        ngoSavedData.financial_capacity.balance_sheet &&
        ngoSavedData.financial_capacity.balance_sheet.find(
          i => i.year === todayDate.getFullYear() - 2
        );
      if (test_val) {
        return test_val['report'];
      }
    }
    if (
      uniqueIdentifierForInput ===
      `balance_sheet_for_fy_${todayDate.getFullYear() -
        3}_-_${todayDate.getFullYear() - 2}`
    ) {
      let test_val =
        ngoSavedData.financial_capacity.balance_sheet &&
        ngoSavedData.financial_capacity.balance_sheet.find(
          i => i.year === todayDate.getFullYear() - 3
        );
      if (test_val) {
        return test_val['report'];
      }
    }
    if (
      uniqueIdentifierForInput ===
      `balance_sheet_for_fy_${todayDate.getFullYear() -
        4}_-_${todayDate.getFullYear() - 3}`
    ) {
      let test_val =
        ngoSavedData.financial_capacity.balance_sheet &&
        ngoSavedData.financial_capacity.balance_sheet.find(
          i => i.year === todayDate.getFullYear() - 4
        );
      if (test_val) {
        return test_val['report'];
      }
    }
    if (
      uniqueIdentifierForInput ===
      `balance_sheet_for_fy_${todayDate.getFullYear() -
        1}_-_${todayDate.getFullYear()}`
    ) {
      let test_val =
        ngoSavedData.financial_capacity.balance_sheet &&
        ngoSavedData.financial_capacity.balance_sheet.find(
          i => i.year === todayDate.getFullYear() - 1
        );
      if (test_val) {
        return test_val['report'];
      }
    }
    if (
      uniqueIdentifierForInput ===
      `organization_external_audit_report_for_fy_${todayDate.getFullYear() -
        2}-${todayDate.getFullYear() - 1}`
    ) {
      const getToday = uniqueIdentifierForInput.slice(-4);
      let test_val =
        ngoSavedData.financial_capacity.external_audit_report &&
        ngoSavedData.financial_capacity.external_audit_report.find(
          i => i.year === getToday - 1
        );
      if (test_val) {
        return test_val['report'];
      }
    }
    if (
      uniqueIdentifierForInput ===
      `organization_external_audit_report_for_fy_${todayDate.getFullYear() -
        3}-${todayDate.getFullYear() - 2}`
    ) {
      let test_val =
        ngoSavedData.financial_capacity.external_audit_report &&
        ngoSavedData.financial_capacity.external_audit_report?.find(
          i => i.year === todayDate.getFullYear() - 2
        );
      if (test_val) {
        return test_val['report'];
      }
    }
    if (
      uniqueIdentifierForInput ===
      `organization_external_audit_report_for_fy_${todayDate.getFullYear() -
        4}-${todayDate.getFullYear() - 3}`
    ) {
      let test_val =
        ngoSavedData.financial_capacity.external_audit_report &&
        ngoSavedData.financial_capacity.external_audit_report.find(
          i => i.year === todayDate.getFullYear() - 3
        );
      if (test_val) {
        return test_val['report'];
      }
    }
    if (
      uniqueIdentifierForInput ===
      `organization_external_audit_report_for_fy_${todayDate.getFullYear() -
        1}-${todayDate.getFullYear()}`
    ) {
      let test_val =
        ngoSavedData.financial_capacity.external_audit_report &&
        ngoSavedData.financial_capacity.external_audit_report.find(
          i => i.year === todayDate.getFullYear() - 1
        );
      if (test_val) {
        return test_val['report'];
      }
    }
    if (
      uniqueIdentifierForInput === 'pan_number' &&
      ngoSavedData.basic_details.pan_number !== null
    ) {
      if (
        field &&
        field.subWrapperKey &&
        ngoSavedData[wrapperKey][field.subWrapperKey]
      ) {
        return ngoSavedData[wrapperKey][field.subWrapperKey][
          uniqueIdentifierForInput
        ].toUpperCase();
      } else {
        return ngoSavedData[wrapperKey][uniqueIdentifierForInput].toUpperCase();
      }
    } else {
      if (
        field &&
        field.subWrapperKey &&
        ngoSavedData[wrapperKey][field.subWrapperKey]
      ) {
        return ngoSavedData[wrapperKey][field.subWrapperKey][
          uniqueIdentifierForInput
        ];
      } else {
        return ngoSavedData[wrapperKey][uniqueIdentifierForInput];
      }
    }
  }
  return null;
}

export default function ProfileSingleField({
  input,
  stepIndex,
  disabled,
  state,
  fieldUpdated,
  dynamicType,
  locationUpdated,
  setFCRATrue,
  setFCRAFalse,
  policiesValue,
  policyFieldUpdate
}) {
  // const [isFieldUpdated, setIsFieldUpdate] = useState(false);
  // useEffect(() => {
  //   c
  //   setIsFieldUpdate(true);
  // }, [fieldUpdated]);
  useEffect(() => {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);
  const getTooltip = data => {
    if (data?.tooltip) {
      return (
        <img
          src={InfoIcon}
          width={15}
          height={15}
          alt="tooltip"
          className=" mx-3 cursor-pointer text-1c1b1d"
          data-toggle="tooltip"
          data-placement="top"
          title={data?.tooltip}
        />
      );
    }
  };
  const ngoSavedData = state.register.ngoSavedData;
  const dispatch = useDispatch();
  const getSecondaryCausesList = primaryCauses => {
    dispatch(getSecondaryObjectiveList(primaryCauses));
  };
  let uniqueIdentifierForInput = input.jsonfield;
  const isImpersonated = window.location.href.includes('email=') ? true : false;

  // uniqueIdentifierForInput = uniqueIdentifierForInput
  //   .replace(/[?.]/g, '')
  //   .toLowerCase();
  let savedValue = null;
  const [parentFieldValue, setParentFieldValue] = useState();
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  let dependClasses = '';

  if (input.dependencyvalue) {
    if (input.dependencyvalue.includes(',')) {
      const dependencyValueArr = input.dependencyvalue.split(',');
      dependencyValueArr.map((value, index) => {
        dependClasses += `${index > 0 ? ' ' : ''}${input.dependenton}-${value}`;
        return false;
      });
    } else {
      dependClasses += `${input.dependenton}-${input.dependencyvalue}`;
    }
  }

  var existingVal, pincodeVal, panVal, gstVal;

  if (stepIndex === 0) {
    savedValue = input.jsonfield ? getValueAtPath(state, input.jsonfield) : '';

    if (
      input.impersonated &&
      window.location.href.includes('email=') &&
      state.register.ngoSavedData.l1_status !== 'submitted'
    ) {
      input.disabled = true;
    }
    if (input.prefilledUrl !== undefined) {
      savedValue = input.prefilledUrl;
    } else if (savedValue) {
      input.defaultValue = savedValue;
      input.readOnly = true;
    } else if (
      (savedValue === '' || !savedValue) &&
      input.valuekey &&
      input.valuekey.includes('login.userInfo')
    ) {
      savedValue = state.register.ngoSavedData.existence[input.jsonfield];
    } else {
      savedValue = getSavedValue(
        ngoSavedData,
        stepIndex,
        uniqueIdentifierForInput
      );
    }
  } else if (
    stepIndex === 2 &&
    (uniqueIdentifierForInput === 'fcra_bank_name' ||
      uniqueIdentifierForInput === 'fcra_ifsc_code')
  ) {
    if (uniqueIdentifierForInput === 'fcra_bank_name') {
      savedValue = 'State Bank Of India';
      input.readOnly = true;
    } else if (uniqueIdentifierForInput === 'fcra_ifsc_code') {
      savedValue = 'SBIN0000691';
      input.readOnly = true;
    }
  } else if (stepIndex === 3) {
    if (!dynamicType) {
      savedValue = getSavedValue(
        ngoSavedData,
        stepIndex,
        uniqueIdentifierForInput
      );
    } else if (dynamicType === 'member') {
      if (
        Object.keys(ngoSavedData).length > 0 &&
        ngoSavedData.institutional_capacity.ngo_board_members &&
        input.id &&
        savedValue == null
      ) {
        const memberObj = ngoSavedData.institutional_capacity.ngo_board_members.find(
          x => x.id === input.id
        );
        savedValue = memberObj[input.key];
        if (
          uniqueIdentifierForInput === 'educational_background' &&
          !Array.isArray(savedValue)
        ) {
          if (
            state.legalStructureDropdown &&
            state.legalStructureDropdown.educationalBackground
          ) {
            if (savedValue.length === 1) {
              savedValue = [savedValue];
            } else if (savedValue.includes('{')) {
              const tempArr = savedValue.replace('{', '').replace('}', '');
              savedValue = tempArr.split(',');
            } else {
              const listIndex = state.legalStructureDropdown.educationalBackground.findIndex(
                x => x.name === savedValue
              );
              if (listIndex > -1) {
                savedValue = [
                  Number(
                    state.legalStructureDropdown.educationalBackground[
                      listIndex
                    ].id
                  )
                ];
              }
            }
          }
        }
      }
    } else if (dynamicType === 'auditor') {
      if (
        Object.keys(ngoSavedData).length > 0 &&
        ngoSavedData.institutional_capacity.auditors &&
        input.id &&
        savedValue === null
      ) {
        const auditorObj = ngoSavedData.institutional_capacity.auditors.find(
          x => x.id === input.id
        );
        savedValue = auditorObj[input.key];
      }
    } else if (dynamicType === 'topDonor') {
      if (
        Object.keys(ngoSavedData).length > 0 &&
        ngoSavedData.institutional_capacity.institution_cap &&
        ngoSavedData.institutional_capacity.institution_cap.top_3_donors &&
        savedValue == null
      ) {
        if (
          ngoSavedData.institutional_capacity.institution_cap.top_3_donors[
            input.index
          ]
        ) {
          savedValue =
            ngoSavedData.institutional_capacity.institution_cap.top_3_donors[
              input.index
            ];
        }
      }
    } else if (dynamicType === 'fundsMobilized') {
      if (
        Object.keys(ngoSavedData).length > 0 &&
        ngoSavedData?.institutional_capacity?.fund_mobilised_arr &&
        savedValue == null
      ) {
        if (ngoSavedData?.institutional_capacity?.fund_mobilised_arr?.length) {
          const fundsdata = ngoSavedData?.institutional_capacity?.fund_mobilised_arr.filter(
            item => item.year === input.year
          );
          savedValue = fundsdata[0]?.funds_mobilised;
        }
      }
    } else if (dynamicType === 'policies') {
      savedValue = policiesValue;
    }
  } else if (stepIndex === 6 && input.inputtype === 'tncCheckBox') {
    savedValue = getSavedValue(ngoSavedData, stepIndex, 'tnc_accepted');
  } else {
    savedValue = getSavedValue(
      ngoSavedData,
      stepIndex,
      uniqueIdentifierForInput
    );

    pincodeVal = ngoSavedData.existence ? ngoSavedData.existence.pincode : null;
    panVal = ngoSavedData.legal_compliance
      ? ngoSavedData.legal_compliance.pan_card
      : null;
    gstVal = ngoSavedData.legal_compliance
      ? ngoSavedData.legal_compliance.gst_number
      : null;
  }
  useEffect(() => {
    if (
      uniqueIdentifierForInput === 'primary_cause_areas' &&
      savedValue &&
      savedValue.length > 0
    ) {
      getSecondaryCausesList(savedValue);
    }
    checkParentFieldValue();
    // const status = state.register.ngoSavedData.status;
    // input.disabled =
    //   isSectionSaved(ngoSavedData, stepIndex) &&
    //   (status === 'submitted' || status === 'approved');
  }, []);
  useEffect(() => {}, [parentFieldValue, savedValue, input.disabled]);
  if (uniqueIdentifierForInput === 'confirmed_account_number') {
    savedValue = getSavedValue(ngoSavedData, stepIndex, 'account_number');
  }
  if (uniqueIdentifierForInput === 'fcra_confirmed_account_number') {
    savedValue = getSavedValue(ngoSavedData, stepIndex, 'fcra_account_number');
  }
  let primaryCauseChangeHandler = null;
  if (uniqueIdentifierForInput === 'primary_cause_areas') {
    primaryCauseChangeHandler = getSecondaryCausesList;
  }
  if (disabled) {
    const status = state.register.ngoSavedData.status;
    input.disabled =
      disabled &&
      !isImpersonated &&
      (status === 'submitted' || status === 'approved');
  }
  //
  const profileStatus = state.register.ngoSavedData.l1_status;
  if (profileStatus === 'submitted' && !isImpersonated) {
    input.readOnly = true;
    input.disabled = true;
  }
  const hideOrShowInputField = (formFieldName, value) => {
    if (formFieldName) {
      const formGroups = document.getElementsByClassName(formFieldName);
      if (value === 'true') {
        for (let i = 0; i < formGroups.length; i++) {
          formGroups[i].className = formGroups[i].className.replace(
            ' d-none',
            ' '
          );
        }
      } else {
        for (let i = 0; i < formGroups.length; i++) {
          if (!formGroups[i].className.includes('d-none')) {
            formGroups[i].className += ' d-none';
          }
        }
      }
    }
  };

  const hideOrShowBySelect = (formFieldName, value) => {
    let list = [];
    if (uniqueIdentifierForInput === 'legal_structure') {
      list = state.legalStructureDropdown.legalStructure;
    }
    list.map((item, index) => {
      const formGroups = document.getElementsByClassName(
        `${formFieldName}-${item.id}`
      );
      for (let i = 0; i < formGroups.length; i++) {
        if (!formGroups[i].className.includes('d-none')) {
          formGroups[i].className += ' d-none';
          if (formGroups[i].children[1].className.includes('radio')) {
            const radioTrue = formGroups[i].children[1].children[0].children[1];
            const radioFalse =
              formGroups[i].children[1].children[1].children[1];
            if (!radioTrue.disabled) radioTrue.disabled = true;
            if (!radioFalse.disabled) radioFalse.disabled = true;
          }
        }
      }
      return false;
    });
    const formGroups = document.getElementsByClassName(
      `${formFieldName}-${value}`
    );
    for (let i = 0; i < formGroups.length; i++) {
      formGroups[i].className = formGroups[i].className.replace(' d-none', ' ');
      if (formGroups[i].children[1].className.includes('radio')) {
        formGroups[i].children[1].children[0].children[1].disabled = false;
        formGroups[i].children[1].children[1].children[1].disabled = false;
      }
    }
  };

  const hideOrShowField = (formFieldName, value) => {
    if (formFieldName) {
      const formGroups = document.getElementsByClassName(formFieldName);
      if (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        value.length > 0
      ) {
        for (let i = 0; i < formGroups.length; i++) {
          formGroups[i].className = formGroups[i].className.replace(
            ' d-none',
            ' '
          );
          const formGroupChildren = formGroups[i].children;
          for (let j = 0; j < formGroupChildren.length; j++) {
            formGroupChildren[j].setAttribute('disabled', true);
          }
        }
        if (formFieldName === 'pincode' && value.length === 6) {
          // latLong = pincode.getlatlong(value);
          // var x = latLong !== undefined ? latLong.lat : '';
          // var y = latLong !== undefined ? latLong.long : '';

          // displayLocation(x, y, locationUpdated);
          displayLocations(value, locationUpdated);
          if (value === undefined) {
            setShowErrorMsg(true);
          } else {
            setShowErrorMsg(false);
          }
        }
      } else {
        for (let i = 0; i < formGroups.length; i++) {
          if (!formGroups[i].className.includes('d-none')) {
            formGroups[i].className += ' d-none';
            const formGroupChildren = formGroups[i].children;
            for (let j = 0; j < formGroupChildren.length; j++) {
              formGroupChildren[j].setAttribute('disabled', true);
            }
          }
        }
      }
    }
  };

  const checkParentFieldValue = () => {
    if (
      input.dependenton &&
      input.dependencywrapperkey &&
      !input.dependencysubwrapperkey
    ) {
      if (ngoSavedData && ngoSavedData[input.dependencywrapperkey]) {
        if (!input.dependencyvalue) {
          setParentFieldValue(
            ngoSavedData[input.dependencywrapperkey][input.dependenton]
          );
        } else {
          const parentValue =
            ngoSavedData[input.dependencywrapperkey][input.dependenton];
          if (input.dependencyvalue.includes(',')) {
            const dependencyValueArr = input.dependencyvalue.split(',');
            let isValid = false;
            dependencyValueArr.map(value => {
              if (parentValue === value && !isValid) isValid = true;
              return false;
            });
            setParentFieldValue(isValid);
          } else {
            if (parentValue === input.dependencyvalue)
              setParentFieldValue(true);
          }
        }
      }
    } else if (
      input.dependenton &&
      input.dependencywrapperkey &&
      input.dependencysubwrapperkey
    ) {
      if (
        ngoSavedData &&
        ngoSavedData[input.dependencywrapperkey][input.dependencysubwrapperkey]
      ) {
        if (!input.dependencyvalue) {
          setParentFieldValue(
            ngoSavedData[input.dependencywrapperkey][
              input.dependencysubwrapperkey
            ][input.dependenton]
          );
        } else {
          const parentValue =
            ngoSavedData[input.dependencywrapperkey][
              input.dependencysubwrapperkey
            ][input.dependenton];
          if (input.dependencyvalue.includes(',')) {
            const dependencyValueArr = input.dependencyvalue.split(',');
            let isValid = false;
            dependencyValueArr.map(value => {
              if (parentValue === value && !isValid) isValid = true;
              return false;
            });
            setParentFieldValue(isValid);
          } else {
            if (parentValue === input.dependencyvalue)
              setParentFieldValue(true);
          }
        }
      }
    }
  };
  if (uniqueIdentifierForInput === 'pincode') {
    existingVal = pincodeVal;
  } else if (uniqueIdentifierForInput === 'pan_num') {
    existingVal = panVal;
  } else if (uniqueIdentifierForInput === 'gst_num') {
    existingVal = gstVal;
  }

  const getCheckedSavedValueWithList = (savedValue, value) => {
    let isSaved = false;
    if (savedValue) {
      let valueIndex = savedValue.findIndex(item => item === value);
      if (valueIndex > -1) isSaved = true;
    }
    return isSaved;
  };

  return (
    <>
      {input.heading && (
        <span
          className={`${
            input.dependenton
              ? `${input.dependenton} ${dependClasses} ${
                  (!input.dependencywrapperkey && !savedValue) ||
                  (input.dependencywrapperkey && !parentFieldValue) ||
                  (input.preonboarding && savedValue === false)
                    ? 'd-none'
                    : ' '
                }`
              : ' '
          } ${
            input.separator ? 'separator pb-4' : 'mt-0  b-25 '
          } ml-4 w-100 position-relative py-2 ${
            input.hidden && savedValue !== null ? 'd-none' : ' '
          }`}>
          {/* <span className="mx-4">{input.heading}{"9999"}</span> */}
          {input.heading} {getTooltip(input)}
        </span>
      )}

      {input.separatortop && <div className="separator w-100 mt-3 mb-3"></div>}
      {/* <div className="separator w-100 mt-3 mb-3"></div> */}
      <div
        className={`form-group col-sm-12 pl-4 pr-4 ${
          input.spanfull ? 'w-100' : 'col-md-6 col-lg-6'
        }  ${input.hidden && savedValue !== null ? 'd-none' : ' '} ${
          input.dependenton
            ? `${input.dependenton} ${dependClasses} ${
                (!input.dependencywrapperkey && !savedValue) ||
                (input.dependencywrapperkey && !parentFieldValue) ||
                (input.preonboarding && savedValue === false)
                  ? 'd-none'
                  : ' '
              }`
            : ' '
        }`}>
        {input.inputtype === 'file' && (
          <>
            <FileInput
              {...input}
              value={savedValue}
              id={uniqueIdentifierForInput}
              name={uniqueIdentifierForInput}
              disabled={disabled || input.disabled}
              refId={state.ngo.ngoProfile.ngo_id}
              fieldUpdated={fieldUpdated}
              stepIndex={stepIndex}
              policyFieldUpdate={policyFieldUpdate}
            />
            {input.validationMsg && (
              <p className="col-12 fs-12 fw-400 h6 pl-0 pt-1 text-muted">
                {input.validationMsg}
              </p>
            )}
          </>
        )}
        {input.inputtype === 'text' && (
          <>
            <Text
              uniqueIdentifierForInput={uniqueIdentifierForInput}
              input={input}
              savedValue={savedValue}
              disabled={disabled || input.disabled}
              fieldUpdated={fieldUpdated}
              stepIndex={stepIndex}
            />
            {input.validationMsg && (
              <p className="col-12 fs-12 fw-400 h6 pl-0 pt-1 text-muted">
                {input.validationMsg}
              </p>
            )}
          </>
        )}

        {input.inputtype === 'textNumber' && (
          <>
            <TextNumber
              uniqueIdentifierForInput={uniqueIdentifierForInput}
              input={input}
              savedValue={savedValue}
              disabled={disabled}
              fieldUpdated={fieldUpdated}
              stepIndex={stepIndex}
            />
            {input.validationMsg && <label>{input.validationMsg}</label>}
          </>
        )}

        {input.inputtype === 'masterTextFields' && (
          <>
            <Text
              uniqueIdentifierForInput={uniqueIdentifierForInput}
              input={input}
              value={savedValue}
              savedValue={savedValue}
              disabled={disabled}
              masterfield={input.masterfield}
              existingVal={existingVal}
              visibilityFunc={input.masterfield ? hideOrShowField : null}
              fieldUpdated={fieldUpdated}
              stepIndex={stepIndex}
            />
            {input.masterfield === 'pincode' && showErrorMsg && (
              <p className="col-12 fs-12 fw-400 h6 pl-0 pt-1 text-muted">
                {' '}
                {input.validationMsg}{' '}
              </p>
            )}
          </>
        )}

        {input.inputtype === 'dependentTextField' && (
          <Text
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            savedValue={savedValue}
            disabled={disabled}
            fieldUpdated={fieldUpdated}
            stepIndex={stepIndex}
          />
        )}
        {input.inputtype === 'textarea' && (
          <>
            <TextArea
              uniqueIdentifierForInput={uniqueIdentifierForInput}
              input={input}
              savedValue={savedValue}
              fieldUpdated={fieldUpdated}
              stepIndex={stepIndex}
              disabled={disabled}></TextArea>
            {input.validationMsg && (
              <p className="col-12 fs-12 fw-400 h6 pl-0 pt-1 text-muted">
                {input.validationMsg}
              </p>
            )}
          </>
        )}
        {input.inputtype === 'radio' && (
          <>
            <label>
              {input.label}
              <span className="color-FF2300">
                {' '}
                {input.required ||
                (input.validation && input.validation === 'required') ||
                input.showrequired
                  ? ' * '
                  : ''}
              </span>
            </label>
            <div className={`form-control border-0 pl-0 radio`}>
              <RadioButton
                label="yes"
                value="true"
                uniqueIdentifierForInput={uniqueIdentifierForInput}
                checked={savedValue === true}
                disabled={
                  disabled ||
                  input.disabled ||
                  (input.dependenton &&
                    ((!input.dependencywrapperkey &&
                      savedValue !== null &&
                      savedValue === false) ||
                      (input.dependencywrapperkey &&
                        parentFieldValue !== null &&
                        parentFieldValue === false)))
                }
                masterfield={input.masterfield}
                visibilityFunc={input.masterfield ? hideOrShowInputField : null}
                fieldUpdated={fieldUpdated}
                stepIndex={stepIndex}
                required={input.required || input.validation === 'required'}
                setFCRATrue={setFCRATrue}
              />
              <RadioButton
                label="no"
                value="false"
                uniqueIdentifierForInput={uniqueIdentifierForInput}
                checked={savedValue === false}
                disabled={
                  disabled ||
                  input.disabled ||
                  (input.dependenton &&
                    ((!input.dependencywrapperkey &&
                      savedValue !== null &&
                      savedValue === false) ||
                      (input.dependencywrapperkey &&
                        parentFieldValue !== null &&
                        parentFieldValue === false)))
                }
                masterfield={input.masterfield}
                visibilityFunc={input.masterfield ? hideOrShowInputField : null}
                fieldUpdated={fieldUpdated}
                stepIndex={stepIndex}
                required={input.required || input.validation === 'required'}
                setFCRAFalse={setFCRAFalse}
              />
            </div>
          </>
        )}
        {input.inputtype === 'radioArray' && (
          <>
            <label>
              {input.label}
              <span className="color-FF2300">
                {' '}
                {input.required ||
                (input.validation && input.validation === 'required') ||
                input.showrequired
                  ? ' * '
                  : ''}
              </span>
            </label>
            <div className={`form-control border-0 pl-0 radio`}>
              {input.values.map((value, index) => {
                return (
                  <RadioButton
                    key={index + value}
                    label={value}
                    value={value}
                    uniqueIdentifierForInput={uniqueIdentifierForInput}
                    checked={savedValue === value}
                    disabled={
                      disabled ||
                      input.disabled ||
                      (input.dependenton &&
                        ((!input.dependencywrapperkey && !savedValue) ||
                          (input.dependencywrapperkey && !parentFieldValue)))
                    }
                    masterfield={input.masterfield}
                    visibilityFunc={
                      input.masterfield ? hideOrShowInputField : null
                    }
                    fieldUpdated={fieldUpdated}
                    stepIndex={stepIndex}
                    required={input.required || input.validation === 'required'}
                  />
                );
              })}
            </div>
          </>
        )}

        {input.inputtype === 'checkboxGroup' && (
          <>
            <label>
              {input.label}
              <span className="color-FF2300">
                {' '}
                {input.required ||
                (input.validation && input.validation === 'required') ||
                input.showrequired
                  ? ' * '
                  : ''}
              </span>
            </label>
            <div className={`form-control border-0 pl-0 radio`}>
              {input.values.map((value, index) => {
                return (
                  <CheckboxComponent
                    key={index + value}
                    label={value}
                    value={value}
                    uniqueIdentifierForInput={uniqueIdentifierForInput}
                    checked={getCheckedSavedValueWithList(savedValue, value)}
                    disabled={
                      disabled ||
                      input.disabled ||
                      (input.dependenton &&
                        ((!input.dependencywrapperkey && !savedValue) ||
                          (input.dependencywrapperkey && !parentFieldValue)))
                    }
                    masterfield={input.masterfield}
                    visibilityFunc={
                      input.masterfield ? hideOrShowInputField : null
                    }
                    fieldUpdated={fieldUpdated}
                    stepIndex={stepIndex}
                    required={input.required || input.validation === 'required'}
                  />
                );
              })}
            </div>
          </>
        )}

        {input.inputtype === 'multiselect' && (
          <MultiSelect
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            state={state}
            value={savedValue}
            changeHandler={primaryCauseChangeHandler}
            fieldUpdated={fieldUpdated}
            stepIndex={stepIndex}
            masterfield={input.masterfield}
            disabled={disabled}
            visibilityFunc={input.masterfield ? hideOrShowBySelect : null}
          />
        )}
        {input.inputtype === 'tncCheckBox' && (
          <>
            <div className="row form-group border-0 pl-0">
              <input
                className="mr-1 tnc-checkbox"
                type="checkbox"
                name="tnc_accepted"
                value={true}
                defaultValue={false}
                required
                id={uniqueIdentifierForInput}
                disabled={savedValue}
                checked={savedValue}
                onChange={() =>
                  fieldUpdated ? fieldUpdated(stepIndex) : false
                }
              />
              <label className="col-11" htmlFor={uniqueIdentifierForInput}>
                {input.label}{' '}
                <a
                  href="https://www.mettasocial.com/terms-and-conditions"
                  className="color-1FA4FA text-underline cursor-pointer"
                  rel="noreferrer noopener"
                  target="_blank">
                  Terms &amp; Conditions
                </a>
                <span className="color-FF2300">*</span>
              </label>
            </div>
          </>
        )}
        {input.inputtype === 'date' && (
          <DateInput
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            state={state}
            savedValue={savedValue ? savedValue : ''}
            disabled={disabled || input.disabled}
            daysOfWeek={[]}
            fieldUpdated={fieldUpdated}
            stepIndex={stepIndex}
          />
        )}
        {input.inputtype === 'yearSelector' && (
          <YearInput
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            state={state}
            savedValue={savedValue ? savedValue : ''}
            disabled={disabled || input.disabled}
            daysOfWeek={[]}
            fieldUpdated={fieldUpdated}
            stepIndex={stepIndex}
          />
        )}
        {input.inputtype === 'yearSelector-FY' && (
          <FinancialYearPicker
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            state={state}
            savedValue={savedValue ? savedValue : ''}
            disabled={disabled || input.disabled}
            daysOfWeek={[]}
            fieldUpdated={fieldUpdated}
            stepIndex={stepIndex}
          />
        )}
        {input.inputtype === 'multiplefiles' && (
          <FileInputMultiple
            {...input}
            value={savedValue}
            id={uniqueIdentifierForInput}
            name={uniqueIdentifierForInput}
            disabled={disabled || input.disabled}
            refId={state.ngo.ngoProfile.ngo_id}
            fieldUpdated={fieldUpdated}
            stepIndex={stepIndex}
          />
        )}
        {input.inputtype === 'multiplefilesnew' && (
          <FileInputMultipleNew
            {...input}
            value={savedValue}
            id={uniqueIdentifierForInput}
            name={uniqueIdentifierForInput}
            disabled={disabled || input.disabled}
            refId={state.ngo.ngoProfile.ngo_id}
            fieldUpdated={fieldUpdated}
            stepIndex={stepIndex}
          />
        )}
        {input.error && input.error !== '' && (
          <p className={`text-danger fs-12 my-2`}>{input.error}</p>
        )}
        <p
          className={`text-danger fs-12 mb-0 ${uniqueIdentifierForInput}-error d-none`}></p>
      </div>
    </>
  );
}
