import React from 'react';
import './modal.css';
import closeIcon from '../../../assets/images/close-button.png';
import downloadIcon from '../../../assets/icons/download_icon.svg';
function Modal({
  show,
  title,
  handleCloseModal,
  formFieldExists,
  sizeClass,
  hideCloseBtn,
  children,
  hideCloseX,
  setShowModal,
  fixedPopup
}) {
  var modalId = '';
  const showHideClassName = show
    ? 'modal modal-overlay theme-modal d-flex align-items-center vh-100'
    : 'modal display-none';
  // let body = document.body;
  if (show === true) {
    // body.style.overflow = 'hidden';
    modalId = 'modal-overlay';
  } else {
    modalId = '';
  }
  const printAndSave = () => {
    window.print();
  };
  return (
    <div className={showHideClassName} id={modalId}>
      <div className={`modal-dialog ht-100 ${sizeClass ? sizeClass : ' '}`}>
        <div className="modal-content ">
          <div>
            {!hideCloseX && (
              <>
                <div className="modal-header  d-flex justify-content-between pt-4 pb-4">
                  <span className="modal-title no-print fs-20">{title}</span>
                  {!hideCloseBtn && (
                    <img
                      src={closeIcon}
                      alt="close"
                      className=" no-print close-button cursor-pointer"
                      onClick={() => handleCloseModal(false)}></img>
                  )}
                </div>
                <div
                  onClick={() => printAndSave()}
                  className={` no-print download_pdf_text px-4 float-right `}>
                  <img
                    src={downloadIcon}
                    className="row-icons cursor-pointer mx-2 mt-1"
                    alt="download-icon"
                  />
                  <span
                    className={`  text-primary fs-14 text-0085FF fw-500 cursor-pointer float-right`}>
                    Download Sample
                  </span>
                </div>
              </>
            )}
          </div>
          <hr className="w-100 no-print hr-color"></hr>
          <div
            className="modal-body py-2"
            style={{ maxHeight: '100vh', overflowY: 'hidden' }}>
            <section>{children}</section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
