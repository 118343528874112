/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-hot-toast';
import { EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';
import CampaignService from '../services/campaign.service';
import { getCloudinaryUrlForImage } from '../helpers/util';
import Loader from './loader';
import FileInput from './fileInput';

export function ProgressReport(props) {
  const { campaignId } = props;
  const [reportData, setReportData] = useState(null);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [showAddEntryForm, setShowAddEntryForm] = useState(false);
  let instance = HttpRequest.getInstance('HIROKU');

  const getProjectReport = () => {
    instance
      .get(
        `${EndPoint.getApiUrl('NGO', 'PROJECT_REPORT', 'HIROKU')}=${campaignId}`
      )
      .then(response => {
        if (response.data && response.data.length) {
          setReportData(response.data);
          setShowAddEntryForm(false);
        } else {
          setReportData([]);
          setShowAddEntryForm(true);
        }
      });
  };

  const getCampaignDetails = () => {
    CampaignService.getCampaign(campaignId, 'detail').then(res => {
      setCampaignDetails(res.data.campaign);
    });
  };

  const addProjectReport = e => {
    e.preventDefault();
    let data = {};
    new FormData(e.target).forEach(function(value, key) {
      if (key === 'campaignId') {
        value = parseInt(value, 10);
      }
      data[key] = value;
    });
    instance
      .post(
        EndPoint.getApiUrl('NGO', 'PROJECT_REPORT', 'HIROKU') +
          `=${campaignId}`,
        data
      )
      .then(response => {
        if (response.data.code === 200) {
          toast.success('Success');
          setShowAddEntryForm(false);
          getProjectReport();
          window.scrollTo(0, 0);
        } else {
          toast.error('Uh-Oh! Looks like we are having server issues.');
        }
      });
  };

  const renderAddReportEntryForm = () => {
    return (
      <>
        <h4>Add new project report entry</h4>
        <form
          className="row px-2 py-4 bg-white mb-4"
          onSubmit={addProjectReport}>
          <input type="hidden" name="campaignId" value={parseInt(campaignId)} />
          <div className="custom form-group col-12">
            <label className="required" htmlFor="projectReportTitle">
              Report Title
            </label>
            <input
              className="form-control"
              id="projectReportTitle"
              name="projectReportTitle"
              maxLength={75}
              required
            />
          </div>
          <div className="custom form-group col-12">
            <label className="required" htmlFor="projectSummary">
              Summary
            </label>
            <textarea
              rows="5"
              className="form-control"
              id="projectSummary"
              name="projectSummary"
              required
              minLength={75}
              maxLength={1000}
            />
          </div>
          <div className="custom form-group col-6">
            <label className="required" htmlFor="budgetUtilization">
              Budget Utilised (%)
            </label>
            <input
              className="form-control"
              type="number"
              required
              id="budgetUtilization"
              name="budgetUtilization"
              max={100}
              min={0}
            />
          </div>
          <div className="custom form-group col-6">
            <label className="required" htmlFor="projectCompletion">
              Project Completion (%)
            </label>
            <input
              className="form-control"
              type="number"
              required
              id="projectCompletion"
              name="projectCompletion"
              max={100}
              min={0}
            />
          </div>
          <div className="custom form-group col-6">
            <FileInput
              label="Report image"
              id="images"
              name="images"
              refId={campaignId}
              fileSizeLimit={10}
              accept="image/*"
              docType="report_image"
            />
          </div>
          <div className="custom form-group col-6">
            <FileInput
              label="Report video"
              id="projectVideo"
              name="projectVideo"
              refId={campaignId}
              fileSizeLimit={75}
              accept="video/*"
              docType="report_video"
            />
          </div>
          <div className="custom form-group col-6">
            <FileInput
              required={true}
              label="Report document"
              id="projectReport"
              name="projectReport"
              refId={campaignId}
              fileSizeLimit={20}
              accept=".pdf,.doc,.docx,.xls,.xlsx"
              docType="report_doc"
            />
          </div>
          <div className="col-12 row text-center justify-content-center">
            <button
              type="button"
              className="btn btn-lg btn-warning col-1 mr-4"
              onClick={() => setShowAddEntryForm(false)}>
              Cancel
            </button>
            <button type="submit" className="col-1 btn btn-lg btn-primary">
              Save
            </button>
          </div>
        </form>
      </>
    );
  };

  useEffect(() => {
    getCampaignDetails();
    getProjectReport();
  }, []);
  if (!reportData || !campaignDetails) {
    return <Loader></Loader>;
  }
  return (
    <>
      <div className="bg-white text-dark font-weight-bold row align-items-center pr-3 py-3 pl-2 mb-4">
        <p className="col-12 h4 mb-0">{campaignDetails.title}</p>
      </div>
      <div className="px-4 py-2">
        {!showAddEntryForm && (
          <div className="bg-white text-dark font-weight-bold row align-items-center p-2 mb-4">
            <button
              className="btn btn-link btn-lg"
              onClick={() => setShowAddEntryForm(true)}>
              <i className="fas fa-plus-square mr-2"></i>
              Add new entry
            </button>
          </div>
        )}
        {showAddEntryForm && renderAddReportEntryForm()}
        <div className="row timeline border-left border-primary mb-4 ml-2">
          {reportData.map(reportEntry => {
            const {
              creationDate,
              projectSummary,
              projectReportTitle,
              projectCompletion,
              budgetUtilization,
              images,
              projectVideo,
              projectReport
            } = reportEntry;
            return (
              <div className="col-12 timeline-event" key={creationDate}>
                <p className="w-100 mb-2">
                  Added on {new Date(creationDate).toLocaleString()}
                </p>
                <div className="bg-white timeline-event-content row p-4 lead">
                  <div className="col-6">
                    <p className="h4">{projectReportTitle}</p>
                    <p className="w-100 text-break">{projectSummary}</p>
                  </div>
                  <div className="col-6 sticky-top">
                    <dl className="row mb-0">
                      <div className="col-6 pl-0">
                        <dt className="font-weight-normal">
                          Budget Utilisation
                        </dt>
                        <dd>{budgetUtilization} %</dd>
                      </div>
                      <div className="col-6 pr-0">
                        <dt className="font-weight-normal">
                          Project Completion
                        </dt>
                        <dd>{projectCompletion} %</dd>
                      </div>
                      <div className="col-6 pl-0">
                        {images && images.length && images[0] && (
                          <>
                            <dt className="font-weight-normal mb-1">
                              Image{' '}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={images[0]}>
                                (Click to download)
                              </a>
                            </dt>
                            <dd>
                              <div className="img-16x9">
                                <a
                                  href={images[0]}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <img
                                    className="abs-full rounded-m"
                                    src={
                                      getCloudinaryUrlForImage(
                                        images[0],
                                        200
                                      ) ||
                                      getCloudinaryUrlForImage(
                                        'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                                        200
                                      )
                                    }
                                    data-src={
                                      images[0] ||
                                      'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                                    }
                                    onError={e =>
                                      (e.target.src = e.target.getAttribute(
                                        'data-src'
                                      ))
                                    }
                                    alt={projectReportTitle}></img>
                                </a>
                              </div>
                            </dd>
                          </>
                        )}
                      </div>
                      <div className="col-6 pr-0">
                        {projectVideo && (
                          <>
                            <dt className="font-weight-normal mb-1">
                              Video{' '}
                              <a
                                href={projectVideo}
                                target="_blank"
                                rel="noopener noreferrer">
                                (Click to download)
                              </a>
                            </dt>
                            <dd>
                              <div className="img-16x9">
                                <a
                                  href={projectVideo}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <video
                                    className="abs-full rounded-m"
                                    controls
                                    muted
                                    poster={
                                      getCloudinaryUrlForImage(
                                        images[0],
                                        200
                                      ) ||
                                      getCloudinaryUrlForImage(
                                        'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                                        200
                                      )
                                    }
                                    autoPlay
                                    src={projectVideo}></video>
                                </a>
                              </div>
                            </dd>
                          </>
                        )}
                      </div>
                      {projectReport && (
                        <div className="col-6 pl-0 mt-2">
                          <dt className="font-weight-normal">
                            Report Document
                          </dt>
                          <dd>
                            <a
                              href={projectReport}
                              target="_blank"
                              rel="noopener noreferrer">
                              <i className="fas fa-file-download mr-2"></i>
                              Download
                            </a>
                          </dd>
                        </div>
                      )}
                    </dl>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  user_type: state.login.userInfo.user_type
});

export default connect(mapStateToProps, null)(ProgressReport);
