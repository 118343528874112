/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
export default function TextArea(props) {
  const { uniqueIdentifierForInput, input, savedValue } = props;
  const [value, setValue] = useState(savedValue);
  const handleChange = e => {
    setValue(e.target.value);
  };
  useEffect(() => {
    if (value === null) {
      setValue(savedValue);
    }
  }, [props]);

  const setSameAsRegistered = () => {
    const regEle = document.getElementById('registration_address');
    setValue(regEle.value);
  };

  return (
    <>
      <label htmlFor={uniqueIdentifierForInput}>
        {input.label}
        <span className="color-FF2300">
          {input.required ||
          (input.validation && input.validation === 'required') ||
          input.showrequired ||
          input.preOnboardRequiredField
            ? ' * '
            : ''}
        </span>
        {uniqueIdentifierForInput === 'communication_address' && (
          <span
            className="fs-10 text-007bff cursor-pointer ml-3"
            onClick={() => setSameAsRegistered()}>
            same as registered address
          </span>
        )}
      </label>
      <textarea
        {...input}
        name={uniqueIdentifierForInput}
        className="form-control"
        id={uniqueIdentifierForInput}
        value={value}
        onChange={handleChange}
        onKeyUp={() =>
          props.fieldUpdated && props.stepIndex >= 0
            ? props.fieldUpdated(props.stepIndex)
            : false
        }></textarea>
    </>
  );
}
