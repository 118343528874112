import React from 'react';

const EmptyChart = ({ image, title, description, imageAlt }) => {
  return (
    <>
      <div className="py-5 d-flex justify-content-center">
        <img src={image} width={100} height={100} alt={imageAlt} />
      </div>
      <div className="mb-5 text-center">
        <p className="fs-18 fw-500  mb-2">{title}</p>
        <span className="fs-16 fw-400">{description}</span>
      </div>
    </>
  );
};
export default EmptyChart;
