import { toast } from 'react-hot-toast';
import MissionService from '../services/mission.service';
import { Action } from '../constants';

export const createOrModifyMission = (data, status) => dispatch => {
  dispatch(missionCreateRequest());
  return MissionService.createOrModifyMission(data, status)
    .then(response => {
      if (
        response.status === 200 &&
        (response.data.code === '200' || response.data.code === 200)
      ) {
        dispatch(missionCreateSuccess(response.data));
        return response.data;
      } else {
        dispatch(missionCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(missionCreateFailure(error));
    });
};

export const unpublishMission = missionId => dispatch => {
  dispatch(missionUnpublishRequest());
  return MissionService.unpublishMission(missionId)
    .then(response => {
      if (
        response.status === 200 &&
        (response.data.code === '200' || response.data.code === 200)
      ) {
        dispatch(missionUnpublishSuccess(response.data));
        return response.data;
      } else {
        dispatch(missionUnpublishFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(missionUnpublishFailure(error));
    });
};

export const getMission = (missionId, mode) => dispatch => {
  dispatch(missionEditRequest());
  MissionService.getMission(missionId, mode)
    .then(response => {
      if (response.status === 200 && response.data.code === '200') {
        dispatch(missionDetailSuccess(response.data.result, mode));
      } else {
        dispatch(
          missionDetailFailure(
            {
              code: response.data.code,
              message: response.data.message
            },
            mode
          )
        );
      }
    })
    .catch(error => {
      dispatch(missionDetailFailure(error, mode));
    });
};
export const getMissionFunds = missionId => dispatch => {
  MissionService.getMissionFunds(missionId)
    .then(response => {
      if (response.status === 200 && response.data.code === '200') {
        dispatch(missionFundsSuccess(response.data.result));
      } else {
        dispatch(
          missionDetailFailure({
            code: response.data.code,
            message: response.data.message
          })
        );
      }
    })
    .catch(error => {
      dispatch(missionDetailFailure(error));
    });
};

export const getTransactionsByMissionId = (
  missionId,
  ngoId,
  search,
  start_date,
  end_date
) => dispatch => {
  dispatch(missionTransactionsRequest());
  MissionService.getTransactionsByMissionId(
    missionId,
    ngoId,
    search,
    start_date,
    end_date
  )
    .then(response => {
      if (response.status === 200 && response.data.code === '200') {
        dispatch(missionTransactionSuccess(response.data.result));
      } else {
        dispatch(
          missionDetailFailure({
            code: response.data.code,
            message: response.data.message
          })
        );
      }
    })
    .catch(error => {
      dispatch(missionTransactionFailure(error));
    });
};
export const missionUpdateMail = (
  missionName,
  mission_id,
  ngo_name
) => dispatch => {
  MissionService.updateMissionMail(missionName, mission_id, ngo_name)
    .then(response => {
      if (response.status === 200 && response.data.code === '200') {
        dispatch(missionTransactionSuccess(response.data.result));
      } else {
        dispatch(
          missionDetailFailure({
            code: response.data.code,
            message: response.data.message
          })
        );
      }
    })
    .catch(error => {
      dispatch(missionTransactionFailure(error));
    });
};
export const getStatisticsByMissionId = missionId => dispatch => {
  dispatch(getStatisticsRequest());
  MissionService.getStatisticsByMissionId(missionId)
    .then(response => {
      if (response.status === 200 && response.data.code === '200') {
        dispatch(missionStatisticsSuccess(response.data.result));
      } else {
        dispatch(
          missionStatisticsFailure({
            code: response.data.code,
            message: response.data.message
          })
        );
      }
    })
    .catch(error => {
      dispatch(missionTransactionFailure(error));
    });
};
export const getAmountRaisedByCorporates = (
  missionId,
  ngoId,
  start_date,
  end_date
) => dispatch => {
  dispatch(getStatisticsRequest());
  MissionService.getAmountRaisedByCorporates(
    missionId,
    ngoId,
    start_date,
    end_date
  )
    .then(response => {
      if (response.status === 200 && response.data.code === '200') {
        dispatch(missionStatisticsSuccess(response.data.result));
      } else {
        dispatch(
          missionStatisticsFailure({
            code: response.data.code,
            message: response.data.message
          })
        );
      }
    })
    .catch(error => {
      dispatch(missionTransactionFailure(error));
    });
};

export const missionUpdate = data => dispatch => {
  dispatch(missionUpdateRequest());
  return MissionService.updateMission(data)
    .then(response => {
      if (
        response.status === 200 &&
        (response.data.code === '200' || response.data.code === 200)
      ) {
        dispatch(missionUpdateSuccess(response.data));
        return response.data;
      } else {
        dispatch(missionUpdateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(missionUpdateFailure(error));
    });
};

export const missionUpdateRequest = () => {
  return {
    type: Action.MISSION_UPDATE_REQUEST
  };
};
export const missionUpdateSuccess = missions => {
  return {
    type: Action.MISSION_UPDATE_SUCCESS,
    payload: missions
  };
};
export const missionUpdateFailure = error => {
  return {
    type: Action.MISSION_UPDATE_FAILURE,
    payload: error
  };
};

export const getMissionsForNgo = ngoId => dispatch => {
  dispatch(resetFormDataResponse());
  dispatch(missionListRequest());
  MissionService.getAllMissionsForNgo(ngoId)
    .then(response => {
      if (response.data.code === '200') {
        dispatch(missionListSuccess(response.data.result));
      } else {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      }
    })
    .catch(error => {
      dispatch(missionListFailure(error));
    });
};

export const resetFormDataResponse = () => {
  return {
    type: Action.MISSION_RESET_RESPONSE
  };
};

export const missionListRequest = () => {
  return {
    type: Action.MISSION_LIST_REQUEST
  };
};
export const missionListSuccess = missions => {
  return {
    type: Action.MISSION_LIST_SUCCESS,
    payload: missions
  };
};

export const missionListFailure = error => {
  return {
    type: Action.MISSION_LIST_FAILURE,
    payload: error
  };
};
export const missionFundsSuccess = missions => {
  return {
    type: Action.MISSION_FUNDS_SUCCESS,
    payload: missions
  };
};

export const missionFundsFailure = error => {
  return {
    type: Action.MISSION_FUNDS_FAILURE,
    payload: error
  };
};

export const missionTransactionsRequest = () => {
  return {
    type: Action.MISSION_TRANSACTIONS_REQUEST
  };
};
export const missionTransactionSuccess = trnsactions => {
  return {
    type: Action.MISSION_TRANSACTION_SUCCESS,
    payload: trnsactions
  };
};

export const missionTransactionFailure = error => {
  return {
    type: Action.MISSION_TRANSACTION_FAILURE,
    payload: error
  };
};

export const getStatisticsRequest = () => {
  return {
    type: Action.MISSION_STATISTICS_REQUEST
  };
};
export const missionStatisticsSuccess = statistics => {
  return {
    type: Action.MISSION_STATISTICS_SUCCESS,
    payload: statistics
  };
};

export const missionStatisticsFailure = error => {
  return {
    type: Action.MISSION_STATISTICS_FAILURE,
    payload: error
  };
};
export const missionCreateRequest = () => {
  return {
    type: Action.MISSION_CREATE_REQUEST
  };
};
export const missionCreateSuccess = missions => {
  return {
    type: Action.MISSION_CREATE_SUCCESS,
    payload: missions
  };
};
export const missionCreateFailure = error => {
  return {
    type: Action.MISSION_CREATE_FAILURE,
    payload: error
  };
};

export const missionEditRequest = () => {
  return {
    type: Action.MISSION_EDIT_REQUEST
  };
};

export const missionDetailSuccess = (mission, mode) => {
  let action;
  switch (mode) {
    case 'preview':
      action = {
        type: Action.MISSION_PREVIEW_SUCCESS,
        payload: mission
      };
      break;

    case 'edit':
      action = {
        type: Action.MISSION_EDIT_SUCCESS,
        payload: mission
      };
      break;

    case 'detail':
      action = {
        type: Action.MISSION_DETAIL_SUCCESS,
        payload: mission
      };
      break;
    default:
  }
  return action;
};
export const missionDetailFailure = (error, mode) => {
  let action;
  switch (mode) {
    case 'preview':
      action = {
        type: Action.MISSION_PREVIEW_FAILURE,
        payload: error
      };
      break;

    case 'edit':
      action = {
        type: Action.MISSION_EDIT_FAILURE,
        payload: error.response || {}
      };
      break;

    case 'detail':
      action = {
        type: Action.MISSION_DETAIL_FAILURE,
        payload: error
      };
      break;
    default:
  }
  return action;
};

export const missionUnpublishRequest = () => {
  return {
    type: Action.MISSION_UNPUBLISH_REQUEST
  };
};
export const missionUnpublishSuccess = response => {
  return {
    type: Action.MISSION_UNPUBLISH_SUCCESS,
    payload: response
  };
};
export const missionUnpublishFailure = error => {
  return {
    type: Action.MISSION_UNPUBLISH_FAILURE,
    payload: error
  };
};
