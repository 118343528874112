import React from 'react';
import { InputSelectAndAdd } from '../FormElements';
import ProjectIndicators from '../campaigns/proposedProjectIndicators';
import ProjectActivities from '../campaigns/proposedProjectActivities';
import ProjectBudget from '../campaigns/proposedProjectBudget';
const ProposedTable = props => {
  return (
    <div>
      <div
        className="container col-12"
        style={{
          backgroundColor: '#FFFFFF',
          marginBottom: '40px',
          width: 'auto',
          textAlign: 'left'
        }}>
        {props.name === 'Proposed Budget' && (
          <div>
            <p style={{ textAlign: 'left', fontSize: '12px' }}>
              Expense Category
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <InputSelectAndAdd
                required={true}
                labelText=" Expense Category"
                id="sectionHeading"
                name="sectionHeading"
                options={''}
                parentElement={true}
                disableInput={''}
                wrapperClassName={'col-6 pl-0'}
              />
              <button
                className="btn btn-sm border-0 "
                style={{ color: '#0085FF' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}

        <div className="row" style={{ marginTop: '20px' }}>
          <div className="col-12">
            <table className="table table-hover" id="tab_logic">
              {props.name === 'Project Indicators' && (
                <thead>
                  <tr>
                    {props.columnsArray?.map((column, index) => (
                      <th className="text-left" key={index}>
                        {column.name}
                      </th>
                    ))}
                    <th />
                  </tr>
                </thead>
              )}
              <tbody>
                {props.name === 'Project Budget' && (
                  <ProjectBudget
                    id={props.campaignId}
                    fundAmount={props.fundAmount}
                    tabName={props.name}
                    isCampaignUnderReview={props.isCampaignUnderReview}
                    campaignName={props.campaignName}
                    currentCampaignStatus={props.currentCampaignStatus}
                    proposalStatus={props.proposalStatus}
                    previewCampaign={props.previewCampaign}
                    showNext={props.showNext}
                    RequestedFundAmount={props.RequestedFundAmount}
                    permissions={props.permissions}
                    allExpenseSubCategoryList={props.allExpenseSubCategoryList}
                    adminName={props.adminName}
                    getCampaignProposalStatus={
                      props.getCampaignProposalStatus
                    }></ProjectBudget>
                )}
                {props.name === 'Project Activities' && (
                  <ProjectActivities
                    id={props.campaignId}
                    tabName={props.name}
                    showNext={props.showNext}
                    proposalStatus={props.proposalStatus}
                    previewCampaign={props.previewCampaign}
                    currentCampaignStatus={props.currentCampaignStatus}
                    permissions={props.permissions}
                    adminName={props.adminName}
                    getCampaignProposalStatus={
                      props.getCampaignProposalStatus
                    }></ProjectActivities>
                )}

                {props.name === 'Project Indicators' && (
                  <ProjectIndicators
                    id={props.campaignId}
                    tabName={props.name}
                    showNext={props.showNext}
                    onSubmit={props.onSubmit}
                    proposalStatus={props.proposalStatus}
                    campaignName={props.campaignName}
                    getCampaignProposalStatus={props.getCampaignProposalStatus}
                    previewCampaign={props.previewCampaign}
                    permissions={props.permissions}
                    adminName={props.adminName}
                    currentCampaignStatus={
                      props.currentCampaignStatus
                    }></ProjectIndicators>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposedTable;
