import { Action } from '../constants';

const initialState = {
  masterInfo: null,
  masterSecondaryInfo: null,
  loading: false,
  masterResponseStatus: null,
  secondaryCauses: [],
  listCountries: [],
  listStates: [],
  listDistricts: [],
  fcraPurpose: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.MASTER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.MASTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        masterInfo: {
          ...action.payload,
          masterResponseStatus: action.masterResponseStatus
        }
      };

    case Action.MASTER_SECONDARY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        masterSecondaryInfo: {
          ...action.payload,
          masterResponseStatus: action.masterResponseStatus
        }
      };

    case Action.MASTER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        masterResponseStatus: action.masterResponseStatus
      };

    case Action.SECONDARY_CAUSE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        secondaryCauses: action.payload.sub_categories
          ? [...action.payload.sub_categories]
          : []
      };

    case Action.FCRA_PURPOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        fcraPurpose: action.payload
      };

    case Action.LIST_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        listCountries: action.payload ? [...action.payload] : []
      };

    case Action.LIST_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        listStates: action.payload ? [...action.payload] : []
      };

    case Action.LIST_DISTRICT_SUCCESS:
      return {
        ...state,
        loading: false,
        listDistricts: action.payload ? [...action.payload] : []
      };

    default:
      return state;
  }
};
