import completeRegistrationData from '../constants/completeRegistrationData';

export function convertFormDataToJson(formData) {
  let object = {};
  formData.forEach(function(value, key) {
    if (!Reflect.has(object, key)) {
      if (value === 'true') {
        value = true;
      }
      if (value === 'false') {
        value = false;
      }
      if (
        key === 'primary_beneficiaries' ||
        key === 'primary_cause_areas' ||
        key === 'secondary_cause_areas' ||
        key === 'primary_funding_source' ||
        key === 'sustainable_development_goals' ||
        key === 'purpose_under_which_fcra_license_is_guaranteed'
      ) {
        object[key] = [];
        object[key].push(value);
        return;
      }

      object[key] = value;
      return;
    }
    if (!Array.isArray(object[key])) {
      object[key] = [object[key]];
    }
    object[key].push(value);
  });
  return object;
}

export function isSectionSaved(ngoSavedData, stepIndex) {
  const wrapperKey = completeRegistrationData[stepIndex].wrapperKey;
  let savedValuesFound = false;
  let allFieldsSaved = true;
  const savedSection = ngoSavedData[wrapperKey];
  // const isRejected = false
  // const isRejected =
  //   Object.keys(ngoSavedData).length > 0 &&
  //   ngoSavedData[`is_verified_step${stepIndex + 1}`] === false &&
  //   (ngoSavedData.status === 'draft' ? true : false);
  if (savedSection) {
    const stepData = completeRegistrationData[stepIndex];
    stepData.fields.map(field => {
      // let uniqueIdentifierForInput = field?.label?.replace(/\s/g, '_');
      // uniqueIdentifierForInput = uniqueIdentifierForInput
      //   ?.replace(/[?.]/g, '')
      //   ?.toLowerCase();
      let uniqueIdentifierForInput = field?.jsonfield;
      if (field.validation === 'required' && !field.dependenton) {
        if (!field.subWrapperKey) {
          if (
            savedSection[uniqueIdentifierForInput] === '' ||
            savedSection[uniqueIdentifierForInput] === null
          )
            allFieldsSaved = false;
        } else {
          if (savedSection[field.subWrapperKey]) {
            if (
              savedSection[field.subWrapperKey][uniqueIdentifierForInput] ===
                '' ||
              savedSection[field.subWrapperKey][uniqueIdentifierForInput] ===
                null
            )
              allFieldsSaved = false;
          } else {
            if (field?.crossWrapperKey) {
              const crossSavedSection = ngoSavedData[field?.crossWrapperKey];
              if (
                crossSavedSection[field.subWrapperKey][
                  uniqueIdentifierForInput
                ] === '' ||
                crossSavedSection[field.subWrapperKey][
                  uniqueIdentifierForInput
                ] === null
              ) {
                allFieldsSaved = false;
              } else {
                allFieldsSaved = true;
              }
            } else {
              allFieldsSaved = false;
            }
          }
        }
      }
    });
    savedValuesFound = allFieldsSaved;
  }
  return savedValuesFound;
}
