import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useSelector } from 'react-redux';
const HalfDonutChart = ({ data, colors, centerText }) => {
  const isSidebar = useSelector(state => state.sidebarStatus);
  const simplifyNumber = number => {
    const suffixes = ['', 'K', 'M', 'B', 'T'];

    const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);
    const simplifiedValue = (number / Math.pow(10, magnitude * 3)).toFixed(0);

    return simplifiedValue + suffixes[magnitude];
  };

  const widthRef = useRef(null);
  const svgRef = useRef(null);

  useEffect(() => {
    const width = widthRef.current.offsetWidth;
    const height = width;
    if (svgRef.current) {
      d3.select(svgRef.current)
        .selectAll('*')
        .remove();
      const svg = d3.select(svgRef.current);
      const totalValue = d3.sum(data, d => d.value);
      const pie = d3
        .pie()
        .sort(null)
        .startAngle(-Math.PI / 2)
        .endAngle(Math.PI / 2)
        .value(d => d.value);
      const innerRadius = width / 5;
      const outerRadius = innerRadius + 30;
      const labelOffset = 18;
      const arc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);
      const g = svg
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`);

      const arcs = g
        .selectAll('.arc')
        .data(pie(data))
        .enter();
      const tooltip = d3
        .select(svgRef.current)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);

      arcs
        .append('path')
        .attr('class', 'arc')
        .attr('fill', (d, i) => colors[i])
        .style('cursor', 'pointer')
        .on('mouseover', function(event, d) {
          const currentData = d.data;
          const [x, y] = d3.pointer(event);
          tooltip
            .transition()
            .duration(200)
            .style('opacity', 1);
          tooltip
            .html(`${currentData.label} : ${simplifyNumber(currentData.value)}`)
            .style('left', `${x}px`)
            .style('top', `${y}px`)
            .style('padding', '5px')
            .style('box-shadow', '2px 2px 4px rgba(0, 0, 0, 0.5)')
            .style('background', 'white')
            .style('border-radius', '5px')
            .style('font-size', '14px')
            .style('font-weight', '500');
        })
        .on('mouseout', () => {
          tooltip
            .transition()
            .duration(500)
            .style('opacity', 0);
        })
        .transition()
        .duration(1500)
        .attrTween('d', function(d) {
          const i = d3.interpolate(d.startAngle, d.endAngle);
          return function(t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });
      arcs
        .append('text')
        .attr('class', 'label')
        .attr('transform', d => {
          const pos = outerRadius + labelOffset;
          const midAngle = (d.startAngle + d.endAngle) / 2;
          const xPosition = pos * Math.sin(midAngle);
          const yPosition = -pos * Math.cos(midAngle);
          return `translate(${xPosition}, ${yPosition})`;
        })
        .attr('dy', '-0.35em')
        .attr('text-anchor', 'middle')
        .style('font-size', '12px')
        .style('position', 'relative')
        .text(d => {
          let percentage =
            d.data.value > 0 ? (d.data.value / totalValue) * 100 : 0;
          return d.data.value > 0 ? `${percentage.toFixed(0)}%` : '';
        });

      // Add text at the center with the sum of all values

      centerText &&
        svg
          .append('text')
          .attr('class', 'center-text')
          .attr('x', width / 2)
          .attr('y', height / 2.2)
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .style('font-size', '14px')
          .style('font-weight', 'bold')
          .text(simplifyNumber(totalValue));

      centerText &&
        svg
          .append('text')
          .attr('class', 'center-text')
          .attr('x', width / 2)
          .attr('y', height / 2)
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .style('font-size', '14px')
          .text(
            `${
              centerText.length > 15
                ? `${centerText.slice(0, 13)}...`
                : centerText
            }`
          );
    }
  }, [data, colors, isSidebar]);

  return (
    <div
      ref={widthRef}
      style={{
        width: '100%',
        margin: 'auto',
        height: '250px'
      }}>
      <svg ref={svgRef} width="100%" height="100%">
        <g />
      </svg>
    </div>
  );
};

export default HalfDonutChart;
