import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import { getCurrencyIcon, getFormattedDate } from '../../helpers/util';
import Loader from '../loader';
import ModalSide from '../modalSide';
import Pagination from '../paginationFullRow';
import Receipt from './recieptPreview';
const Invoices = props => {
  const currencySelect = useSelector(
    state => state?.currencyData?.currencyData?.currency
  );
  let currencySymbol = getCurrencyIcon(currencySelect);
  const [currentPage, setCurrentPage] = useState(1);
  const [isApiCalled, setIsApiCalled] = useState(0);

  const [postsPerPage, setPostsPerPage] = useState(10);
  const [invoices, setInvoices] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiData, setApiData] = useState();
  const [financialYears, setFinancialYears] = useState([]);
  const [financialYear, setFinancialYear] = useState('');
  const [loading, setLoading] = useState(false);
  const contentRef = useRef(null);
  const [showSideModal, setShowSideModal] = useState(false);
  const [item, setItem] = useState();
  const options = {
    resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: 'letter'
    }
  };

  const generateFinancialYears = startYear => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const endYear = today.getMonth() < 3 ? currentYear : currentYear + 1;
    const financialYears = [];
    for (let year = parseInt(startYear); year < endYear; year++) {
      const nextYear = year + 1;
      financialYears.push(`${year}-${String(nextYear).slice(2)}`);
    }
    return financialYears;
  };
  const showHideSideModal = () => {
    setShowSideModal(!showSideModal);
  };
  const getInvoices = () => {
    if (isApiCalled === 0) {
      props.getSavedNGOData(props.state.login.userInfo.sub);
      props.getRegionalSettings(props.state.login.userInfo.sub);
      setIsApiCalled(1);
    }
    setLoading(true);
    props
      .getInvoices(
        props.state.login.userInfo.sub,
        postsPerPage * currentPage - postsPerPage,
        postsPerPage,
        searchTerm,
        getFinancialYearDates(financialYear)
      )
      .then(res => {
        setLoading(false);
        setInvoices(res?.result?.invoice_list || []);
        setTotalCount(res?.result?.total_records);
        setApiData(res?.result);
      })
      .catch(() => {
        setLoading(false);
        setInvoices([]);
        toast.error('Error while fetching invoices');
        if (currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        }
      });
  };
  useEffect(() => {
    if (financialYear?.length) {
      getInvoices();
    }
    if (financialYears.length === 0) {
      setFinancialYears(generateFinancialYears('2022'));
    }
    if (financialYear === '') {
      setFinancialYear(generateFinancialYears('2022').pop());
    }
  }, [searchTerm, financialYear, currentPage, postsPerPage]);

  const getValue = value => {
    if (value) {
      return `${currencySymbol} ${' '} ${new Intl.NumberFormat('en-IN').format(
        value
      )}`;
    } else {
      return '0';
    }
  };
  const onPageChange = option => {
    if (option[0] === 'Prev') {
      setCurrentPage(prev => prev - 1);
    } else if (option[0] === 'Next') {
      setCurrentPage(prev => prev + 1);
    } else if (option[0] === 'postsPerPage') {
      setCurrentPage(1);
      setPostsPerPage(option[1]);
    } else if (option[0] === 'currentPage') {
      setCurrentPage(option[1]);
    }
  };

  const getFinancialYearDates = fy => {
    if (fy === '') {
      return '';
    }
    const [startYear, endYear] = fy.split('-').map(Number);
    const startDate = new Date(`${startYear}-04-01`);
    const endDate = new Date(`20${endYear}-03-31`);

    const formatDateString = date =>
      `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}/${String(date.getDate()).padStart(2, '0')}`;

    const startDateString = formatDateString(startDate);
    const endDateString = formatDateString(endDate);

    return `${startDateString},${endDateString}`;
  };

  const emptyState = () => {
    return (
      <div className="text-center mt-8 mx-auto">
        <img
          className="pl-2"
          src="/images/donationEmptyState.svg"
          alt=""
          width={300}
          // onClick={() => sortByColumn('mission_name')}
        />
        <div className="fs-18 mb-3 mt-4 text-1C1C1C">
          Sorry! We couldn’t find any Records
        </div>
        <div className="fs-14 text-626262 mb-5">
          We didn’t not finds any records matching your requirements. Please try
          other filters
        </div>
      </div>
    );
  };
  const getTable = () => {
    if (loading) {
      return <Loader />;
    }
    const getTableBody = () => {
      if (invoices?.length > 0) {
        return invoices?.map((item, index) => {
          return (
            <tbody key={item.id + index}>
              <tr className={'text-center'} key={`donation`}>
                <td className="table-td px-3 py-3 ">
                  {index + currentPage * postsPerPage + 1 - postsPerPage}
                </td>
                <td className="table-td px-2 py-3">{item?.invoice_number}</td>
                <td className="table-td px-2 py-3">
                  {getFormattedDate('dd/MM/yyyy', item?.invoice_date)}
                </td>
                <td className="table-td px-2 py-3">{getValue(item?.amount)}</td>

                <td className="table-td px-2 py-3">
                  {getValue(
                    Number(item?.mettasocial_fees) - Number(item?.gst_charges)
                  )}
                </td>
                <td className="table-td px-2 py-3">
                  {getValue(item?.gst_charges)}
                </td>
                <td className="table-td px-1 py-2 text-nowrap">
                  <span
                    className="text-primary cursor-pointer fw-500"
                    onClick={() => {
                      setShowSideModal(true);
                      setItem(item);
                    }}>
                    View Invoice
                  </span>
                </td>
              </tr>
            </tbody>
          );
        });
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={7}>{emptyState()}</td>
            </tr>
          </tbody>
        );
      }
    };
    return (
      <table
        className="table table-borders table-hover fs-14 bg-white "
        id="table-to-xls">
        <thead className={'table-thead text-uppercase'}>
          <tr className={'text-left table-th h-lh-45'}>
            <th scope="col" className="px-2 py-2 ">
              SR NO
            </th>
            <th scope="col" className="px-1 py-2 ">
              INVOICE NUMBER
            </th>
            <th scope="col" className="px-1 py-2 ">
              INVOICE DATE
            </th>
            <th scope="col" className="px-1 py-2 ">
              AMOUNT RECEIVED
            </th>
            <th scope="col" className="px-1 py-2 ">
              METTA SOCIAL FEES
            </th>
            <th scope="col" className="px-1 py-2 ">
              GST (18%)
            </th>
            <th scope="col" className="px-1 py-2 ">
              INVOICE
            </th>
          </tr>
        </thead>
        {getTableBody()}
      </table>
    );
  };
  const debouncedHandleChange = debounce(e => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setSearchTerm(e.target.value);
  }, 500);
  const getLoader = value => {
    if (loading) {
      return (
        <div className="spinner-border " role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    }
    return value;
  };

  return (
    <React.Fragment>
      <div className="px-4 pb-4 ">
        <div className="col-12  mb-4 bg-white">
          <ul className="nav nav-tabs text-dark sticky-tabs tabs-campaigns fs-14 fw-500">
            <li className="nav-item m-0 pr-0 cursor-none cursor-not-allowed">
              <a
                href="#socio-xn"
                className="nav-link cursor-not-allowed"
                aria-current="page ">
                Socio-XN
              </a>
            </li>
            <li className="nav-item m-0 pr-0">
              <a href="iGiv-xn" className="nav-link active">
                iGiv-XN
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex justify-content-between">
          <div className="transparent input-group mb-4 position-relative col-3 pl-0">
            <input
              className="form-control filter-search bg-white px-5 border-none"
              type="text"
              defaultValue={searchTerm}
              onChange={e => {
                e.persist();
                debouncedHandleChange(e);
              }}
              placeholder="Search by invoice number"
            />
            <i className="input-group-text fa fa-search  position-absolute text-969696 "></i>
          </div>
          <div className="dropdown  rounded col-2 pr-0">
            <button
              className="btn col-12 mr-0 dropdown bg-white dropdown-toggle  text-left "
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              FY&nbsp;{financialYear}
            </button>
            <ul
              className="dropdown-menu pagination_dropdown_menu "
              aria-labelledby="dLabel">
              {financialYears?.map(item => {
                return (
                  <li
                    key={item}
                    className="pagination_dropdown_item"
                    onClick={() => {
                      setFinancialYear(item);
                      if (currentPage !== 1) {
                        setCurrentPage(1);
                      }
                    }}>
                    FY&nbsp;{item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="row  bg-white">
          <div className="col-3 pl-0 pr-3 ">
            <div className="p-4">
              <div className="text-666565 fs-14 mb-3">Number Of Invoices</div>
              {getLoader(
                <h2 className=" fs-24 fw-500">
                  {apiData?.total_invoices || 0}
                </h2>
              )}
            </div>
          </div>
          <div className="col-3 px-3">
            <div className="p-4">
              <div className="text-666565 fs-14  mb-3">
                Total Amount Received
              </div>
              {getLoader(
                <h2 className=" fs-24 fw-500">
                  {getValue(apiData?.total_amount_raised)}
                </h2>
              )}
            </div>
          </div>
          <div className="col-3 px-3">
            <div className="p-4">
              <div className="text-666565 fs-14  mb-3">
                Metta Social Fees Paid
              </div>
              {getLoader(
                <h2 className="text-1C1C1C fs-24 fw-500">
                  {getValue(
                    Number(apiData?.total_ms_charges) -
                      Number(apiData?.total_gst_charges)
                  )}
                </h2>
              )}
            </div>
          </div>
          <div className="col-3 pl-3 pr-0">
            <div className="p-4">
              <div className="text-666565 fs-14 mb-3">GST Charges</div>
              {getLoader(
                <h2 className="text-1C1C1C fs-24 fw-500">
                  {getValue(apiData?.total_gst_charges)}
                </h2>
              )}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="invoice_table_overflow ">{getTable()}</div>
          <div className="my-4">
            <Pagination
              pageSize={postsPerPage}
              totalCount={totalCount}
              currentPage={currentPage}
              onPageChange={onPageChange}
              postsPerPageSelectArray={[10, 20, 30, 40, 50]}></Pagination>
          </div>
          <div className="col-12 row"></div>
        </div>
      </div>

      <ModalSide
        showModal={showSideModal}
        handleCloseModal={showHideSideModal}
        className={'side-modal-lg'}
        title={'Invoice Details'}
        hideCancel={false}
        disableOverlayClick={true}>
        <div className="d-flex justify-content-end px-4">
          <span
            onClick={() => generatePDF(contentRef, options)}
            className="text-primary fs-16 fw-500 cursor-pointer">
            Download PDF
          </span>
        </div>

        <div className="m-4">
          <div ref={contentRef}>
            <Receipt
              invoice={item}
              userInfo={props?.state?.register?.ngoSavedData?.existence}
              ngoName={props.ngoInfo?.name}
            />
          </div>
        </div>
      </ModalSide>
    </React.Fragment>
  );
};
export default Invoices;
