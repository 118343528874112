import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import MissionService from '../../services/mission.service';
import { sampleData } from './dummyData';
import DonutChart from '../newCharts/newDonutChart';
import EmptyChart from './emptyChart';
import DonutEmpty from '../../assets/icons/donut_chart_empty.svg';
import AreaEmpty from '../../assets/icons/line_chart_empty.svg';
import SupporterEmpty from '../../assets/icons/active_donors_empty.svg';
import DropDownDash from './chartDropdown';
import ChartColorsDetails from './donutList';
import NewLineChart from '../newCharts/newLineChart';
import HalfDonutChart from '../newCharts/semiDonutChart';
import { Routes } from '../../constants/path.constants';
import { getCurrencyIcon } from '../../helpers/util';
import { bindActionCreators } from 'redux';
import { getRegionalSettings } from '../../actions';
// const postsPerPage = 30;
class MissionStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      missionInfo: null,
      supporterType: 'recurring',
      getApi: true,
      missionStatistics: {},
      amountRaisedFromCorporates: [],
      amountRaised: [],
      recurringSupporters: [],
      oneTimeSupporters: [],
      all_corp_donation_details_thisYear: [],
      all_corp_donation_details_lastYear: [],
      activeSupporters: [],
      beneficiariesCount: [],
      fundsUtilization: [],
      donationCorpData: 'This year',
      currencyData: props.currency,
      colors: [
        '#F2711C',
        '#FBBD08',
        '#00B5AD',
        '#005492',
        '#B5CC18',
        '#21BA45',
        '#4D6918',
        '#F2711C',
        '#FBBD08',
        '#00B5AD',
        '#005492',
        '#21BA45',
        '#B5CC18',
        '#4D6918',
        '#F2711C',
        '#FBBD08',
        '#00B5AD',
        '#005492',
        '#B5CC18',
        '#21BA45',
        '#4D6918'
      ]
    };
  }

  // Format the dates as needed (e.g., to 'YYYY-MM-DD' format)
  formatDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  getAnonymousName(name) {
    let nameArr = name.split(' ');
    nameArr.map((name, index) => {
      nameArr[index] =
        name.length < 2
          ? name
          : name.substring(0, 1) +
            name.substring(1, name.length).replaceAll(/./g, '*');
    });
    return nameArr.join(' ');
  }

  setSupporterType = value => {
    this.setState({ supporterType: value });
  };
  getMission = () => {
    let missionId = window.location.href.split('/')[6];
    MissionService.getMission(missionId, 'detail')
      .then(response => {
        this.setState({ missionInfo: response.data.result });
      })
      .catch(() => {});
  };

  getAmountRaisedByCorporates = () => {
    let missionId = window.location.href.split('/')[6];
    let ngoId = this.props.userInfo.orgId || this.props.userInfo.sub;
    let startDate = '';
    let endDate = '';

    const today = new Date();
    const currentYear = today.getFullYear();
    const lastYear = currentYear - 1;

    if (this.state.donationCorpData === 'This year') {
      startDate = this.formatDate(new Date(currentYear, 0, 1));
      endDate = this.formatDate(today);
    } else if (this.state.donationCorpData === 'Last year') {
      startDate = this.formatDate(new Date(lastYear, 0, 1));
      endDate = this.formatDate(new Date(lastYear, 11, 31));
    }

    MissionService.getAmountRaisedByCorporates(
      missionId,
      ngoId,
      startDate.toString(),
      endDate.toString()
    )
      .then(response => {
        if (response.data.code === '200') {
          const data = response.data?.result.map(item => ({
            label: item.companyName,
            value: item.total
          }));
          this.setState({ amountRaisedFromCorporates: data });
        }
      })
      .catch(() => {});
  };

  getMissionStats = () => {
    let missionId = window.location.href.split('/')[6];
    let ngoId = this.props.userInfo.orgId || this.props.userInfo.sub;
    MissionService.getStatisticsByMissionId(missionId, ngoId)
      .then(response => {
        this.setState({
          missionStatistics: response.data.result
        });
        //  const data=  this.getStatsDatagetStatsData(this.state.missionStatistics);

        this.setState({
          recurringSupporters: response.data.result?.top_unique_user_payment,
          oneTimeSupporters: response.data.result?.onetime_top_supporters_res,
          activeSupporters: response.data.result?.active_supporters_res
        });
      })
      .catch(() => {});
  };
  componentDidMount() {
    if (this.state.getApi) {
      this.getMission();
      this.getAmountRaisedByCorporates();
      this.getMissionStats();
      this.props.getRegionalSettings(this.props.userInfo.sub);
    }
  }
  coammaSapertaedNumber = number => {
    if (number) return new Intl.NumberFormat('en-IN').format(number);
    return 0;
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.currency) {
      this.setState({ currencyData: nextprops.currency });
    }
    if (nextprops.missionInfo) {
      this.isShowLoader = false;
      this.setState({ missionInfo: nextprops.missionInfo });
    } else {
      if (
        nextprops.missionDetailErrorResponse &&
        nextprops.missionDetailErrorResponse.code
      ) {
        toast.error('Some error occurred, please try again in sometime');
      }
    }
  }
  convertDateFormat = dateStr => {
    const [year, month] = dateStr.split('-').map(Number);
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    return `${monthNames[month - 1]}${String(year).slice(-2)}`;
  };
  getStatsDatagetStatsData = missionStatistics => {
    let data = [];
    if (missionStatistics) {
      data.push({
        name: 'Recurring (Monthly)',
        value: missionStatistics?.onetime_top_supporters_res
      });
      data.push({
        name: 'One Time',
        value: missionStatistics?.recurring_top_supporters_res
      });
    }
    return data;
  };

  getDropdownYearValue = itm => {
    this.setState({ donationCorpData: itm }, () => {
      this.getAmountRaisedByCorporates();
    });
  };
  displayCorpDonation = (data, currencySymb) => {
    if (data.length > 0) {
      return (
        <div className="col-12 d-flex">
          <div className="col-8">
            <DonutChart
              data={data}
              colors={this.state.colors}
              isCurrency={true}
              currencySymb={currencySymb}
            />
          </div>
          <div className="col-4 mt-5 pt-5 pl-0">
            <ChartColorsDetails
              data={data}
              colors={this.state.colors}
              title={'Total Donation'}
              max={5}
              currencySymb={currencySymb}
            />
          </div>
        </div>
      );
    } else {
      return (
        <EmptyChart
          image={DonutEmpty}
          imageAlt={'Bar chart'}
          title={'No Funds received yet'}
          description={''}
        />
      );
    }
  };
  getSupportorsDisplayList = currencySymb => {
    return this.getSupporotersList(
      this.state.recurringSupporters,
      currencySymb
    );
    // if (
    //   this.state?.missionInfo?.mission_type === 'mission'
    //   // &&this.state.supporterType === 'recurring'
    //   // && this.state?.recurringSupporters
    // ) {
    //   return this.getSupporotersList(this.state.recurringSupporters);
    // } else if (
    //   this.state?.missionInfo?.mission_type === 'disaster'
    //   // &&this.state.supporterType === 'oneTime'
    //   // && this.state?.oneTimeSupporters
    // ) {
    //   return this.getSupporotersList(this.state.oneTimeSupporters);
    // }
  };
  getSupporotersList = (data, currencySymb) => {
    if (data?.length > 0) {
      return data.map((supporter, index) => {
        return (
          <div
            key={index + supporter.first_name}
            className={`d-flex justify-content-between ${
              index > 0 ? 'mt-3' : ' '
            }`}>
            <div>
              <img
                src={sampleData.stats.profileImage}
                alt={supporter.first_name}
                className="supporter-profile mr-3"
              />
              <span>
                {supporter.first_name} {supporter.last_name}
              </span>
            </div>
            <span className="d-flex align-items-center">
              {currencySymb} {this.coammaSapertaedNumber(supporter.amount)}
            </span>
          </div>
        );
      });
    } else {
      return (
        <EmptyChart
          image={SupporterEmpty}
          imageAlt={'Bar chart'}
          title={'No Supporters found'}
          description={''}
        />
      );
    }
  };
  nonEmpty = data => {
    let sum = 0;
    data &&
      data.forEach(item => {
        sum = sum + item.value;
      });
    return sum;
  };
  getSupporotersGraph = data => {
    if (data?.length > 0) {
      return <NewLineChart data={data} color={'#00B5AD'} />;
    } else {
      return (
        <EmptyChart
          image={AreaEmpty}
          imageAlt={'Bar chart'}
          title={'No Active Supporters'}
          description={''}
        />
      );
    }
  };
  getFundsUtilizationData = () => {
    if (this.state.fundsUtilization) {
      return this.getFundsUtilization(this.state.fundsUtilization);
    } else {
      return '';
    }
  };
  getFundsUtilization = data => {
    if (this.nonEmpty(data) > 0) {
      return (
        <HalfDonutChart
          data={data}
          colors={['#E47E09', '#007997']}
          isCurrency={true}
        />
      );
    } else {
      return (
        <EmptyChart
          image={DonutEmpty}
          imageAlt={'Bar chart'}
          title={'No Active Supporters'}
          description={''}
        />
      );
    }
  };

  render() {
    let currencySymb = getCurrencyIcon(this.state.currencyData);
    return (
      <>
        <div className="row justify-content-center bg-light-gray p-4 overflow-scroll">
          <div className="col-12 p-0">
            <h5 className="mb-4">
              <Link
                className="text-1FA4FA fs-12 h-lh-12"
                to={`/admin/allMissions`}>
                Missions
              </Link>
              &nbsp;&nbsp;
              <span className="text-1FA4FA fs-12 h-lh-12">&gt;</span>
              &nbsp;&nbsp;
              <Link
                className="text-1FA4FA fs-12 h-lh-12"
                to={`${Routes.NGO.MISSION_DETAILS}/${this.state.missionInfo?.mission_id}`}>
                {this.state.missionInfo?.mission_title}
              </Link>
              &nbsp;&nbsp;
              <span className="text-1FA4FA fs-12 h-lh-12">&gt;</span>
              &nbsp;&nbsp;
              <span className="text-7B8FA6 fs-12 h-lh-12">Stats</span>
            </h5>
          </div>
          <div className="col-12 bg-white card-box-shadow p-0 mb-5 rounded-8">
            <div className="super-wide w-100 h-0 position-relative rounded-top-8 loading-bg">
              {this.state.missionInfo && this.state.missionInfo.image_url && (
                <img
                  src={this.state.missionInfo.image_url}
                  data-src={
                    this.state.missionInfo.image_url ||
                    'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                  }
                  onError={e =>
                    (e.target.src = e.target.getAttribute('data-src'))
                  }
                  alt={`${this.state.missionInfo.mission_title} mission`}
                  className="abs-full rounded-top-8 preview-image"
                />
              )}
            </div>
            <div className="p-4 row">
              <div className="col-12 px-0 mt-2 mb-3 d-flex align-items-center">
                <p className="h2 px-0 mt-2 text-bold text-1C1C1C d-flex align-items-center text-break">
                  Stats:&nbsp;{this.state.missionInfo?.mission_title}
                </p>
              </div>
              <div className="row">
                <div className="col-3 pl-0 pr-3">
                  <div className="mission-card p-4">
                    <h4 className="text-666565 fs-16 fw-500 mb-3">
                      Total Amount Raised
                    </h4>
                    <h2 className="color-26AC48 fs-24 fw-500">
                      {currencySymb}{' '}
                      {this.coammaSapertaedNumber(
                        this.state.missionStatistics?.total_amount_raised
                      )}
                    </h2>
                  </div>
                </div>
                <div className="col-3 px-3">
                  <div className="mission-card p-4">
                    <h4 className="text-666565 fs-16 fw-500 mb-3">
                      This Month
                    </h4>
                    <h2 className="color-26AC48 fs-24 fw-500">
                      {currencySymb}{' '}
                      {this.coammaSapertaedNumber(
                        this.state.missionStatistics?.total_amount_this_month
                      )}
                    </h2>
                  </div>
                </div>
                <div className="col-3 px-3">
                  <div className="mission-card p-4">
                    <h4 className="text-666565 fs-16 fw-500 mb-3">
                      {this.state?.missionInfo?.mission_type === 'mission'
                        ? 'Total Contributors'
                        : 'Total Supporters'}
                    </h4>
                    <h2 className="text-1C1C1C fs-24 fw-500">
                      {this.state.missionStatistics?.total_supporters
                        ? this.state.missionStatistics?.total_supporters
                        : '0'}
                    </h2>
                  </div>
                </div>
                <div className="col-3 pl-3 pr-0">
                  <div className="mission-card p-4">
                    <h4 className="text-666565 fs-16 fw-500 mb-3">
                      Total Active Supporters
                    </h4>
                    <h2 className="text-1C1C1C fs-24 fw-500">
                      {this.coammaSapertaedNumber(
                        this.state.missionStatistics?.total_active_supporters
                      )}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-6 pl-0 pr-3 mt-4 pt-2">
                <div className="mission-card pr-3  stats-card-min-height">
                  <div className="d-flex justify-content-between mx-4 mt-4">
                    <h3 className="text-1C1C1C fs-18 fw-500 mb-4 ">
                      Amount Raised from Corporates
                    </h3>
                    <DropDownDash
                      data={['This year', 'Last year']}
                      value={this.state.donationCorpData}
                      setValue={itm => {
                        this.getDropdownYearValue(itm);
                      }}
                    />
                  </div>
                  <>
                    {this.displayCorpDonation(
                      this.state.amountRaisedFromCorporates,
                      currencySymb
                    )}
                  </>
                </div>
              </div>
              <div className="col-6 pr-0 pl-3 mt-4 pt-2">
                <div className=" mission-card  stats-card-min-height">
                  <h3 className="text-1C1C1C fs-18 fw-500 mb-3 px-4 my-4">
                    Top Supporters
                  </h3>
                  <div className="card-tab px-4 pb-3">
                    {this.state?.missionInfo?.mission_type === 'mission' && (
                      <span
                        className={`position-relative text-1C1C1C fs-14 cursor-pointer mr-5 ${
                          this.state.supporterType === 'recurring'
                            ? 'active'
                            : ' '
                        }`}
                        onClick={() => this.setSupporterType('recurring')}>
                        Recurring (Monthly)
                      </span>
                    )}
                    {this.state?.missionInfo?.mission_type === 'disaster' && (
                      <span
                        className={`position-relative text-1C1C1C fs-14 cursor-pointer ${
                          this.state.supporterType === 'oneTime'
                            ? 'active'
                            : ' '
                        }`}
                        onClick={() => this.setSupporterType('oneTime')}>
                        One Time
                      </span>
                    )}
                  </div>
                  <div className="p-5 pt-4 -list" style={{ width: '70%' }}>
                    {this.getSupportorsDisplayList(currencySymb)}
                  </div>
                </div>
              </div>

              <div className="col-12 pl-0 pr-3 mt-4 pt-2">
                <div className="mission-card stats-card-min-height p-4">
                  <h3 className="text-1C1C1C fs-18 fw-500 mb-4 px-4">
                    Active Supporters
                  </h3>
                  <div className="text-align-center">
                    {this.getSupporotersGraph(this.state.activeSupporters)}
                  </div>
                </div>
              </div>
              {/* <div className="col-6 pr-0 pl-3 mt-4 pt-2">
                <div className="mission-card stats-card-min-height p-4">
                  <h3 className="text-1C1C1C fs-18 fw-500 mb-4 px-4">
                    Funds Utilisation
                  </h3>
                  <div className="d-flex col-12 px-0">
                    <div className="stats-donut-chart col-8">
                      {this.getFundsUtilizationData()}
                    </div>
                    <div className="d-flex flex-column justify-content-center col-4 px-0">
                      <p className="fs-14 position-relative chart-label label-bg-FBBD08 pl-4 ">
                        <span className="mx-3 text-1C1C1C">
                          Funds Raised:&nbsp;
                        </span>
                        <span className="d-inline-block text-1B1C1D">
                          ₹&nbsp;
                          {this.coammaSapertaedNumber(
                            this.state?.fundsUtilization[0]?.value
                          )}
                        </span>
                      </p>
                      <p className="fs-14 position-relative chart-label label-bg-EBEBEB pl-4 ">
                        <span className="mx-3 text-1C1C1C">
                          Funds Utilised :&nbsp;
                        </span>
                        <span className="d-inline-block text-1B1C1D">
                          ₹&nbsp;
                          {this.coammaSapertaedNumber(
                            this.state?.fundsUtilization[1]?.value
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.login.userInfo,
  currency: state?.currencyData?.currencyData?.currency
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getRegionalSettings
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionStats);
