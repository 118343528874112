import { Action } from '../constants';
const initialState = {
  no_of_employees: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.NO_OF_FULL_TIME_EMPLOYEES:
      return {
        ...state,
        loading: true,
        no_of_employees: action.payload
      };
    default:
      return state;
  }
};
