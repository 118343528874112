import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../constants/path.constants';
import { InputText, InputTextArea } from '../FormElements';
import attachmentIcon from '../../assets/images/icon-feather-paperclip.png';
import FilePDF from '../../assets/images/filepdf.svg';
import { getCloudinaryUrlForImage } from '../../helpers/util';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-hot-toast';
import { getTicketDetails } from '../../actions/support.actions';

class SupportTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketData: {}
    };
  }

  componentDidMount() {
    this.getTicketData();
  }

  getTicketData = () => {
    let caseId = window.location.pathname.split('/')[3];
    this.props
      .getTicketDetails(caseId)
      .then(res => {
        this.setState({ ticketData: res.data });
      })
      .catch(err => {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      });
  };
  getImage = (link, index) => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" key={index}>
        <img
          src={
            getCloudinaryUrlForImage(link, 200) ||
            getCloudinaryUrlForImage(
              'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
              200
            )
          }
          alt="images"
          width={50}
          height={50}
          className="mx-2"
        />
      </a>
    );
  };
  convertDate = str => {
    if (str) {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      return [day, mnth, date.getFullYear()].join('-');
    } else return '';
  };
  getPDF = (link, index) => {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="mx-2"
        key={index}>
        <img src={FilePDF} alt="images" width={50} height={50} />
      </a>
    );
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Link
              to={Routes.NGO.SUPPORT_TICKET_LIST}
              className="text-decoration-none">
              <h3 className="d-flex align-items-center mt-5 fw-600">
                <i className="fas fa-angle-left fs-36 color-888686 cursor-pointer"></i>
                <span className="d-inline-block mr-4 color-888686 font-weight-bold pl-4 cursor-pointer">
                  Back
                </span>
              </h3>
            </Link>
            <h3 className="d-flex align-items-center mb-4 fw-600 font-weight-bolder">
              Previous ticket
            </h3>
            <div className="bg-white p-5 rounded-b ">
              <div className="row">
                <h4 className="col-12 font-weight-bolder pl-0 mb-4">
                  Status :&nbsp;&nbsp;
                  <span
                    className={`${
                      this.state.ticketData.resolved_on !== 'None'
                        ? 'text-81BC30'
                        : 'text-E47E09'
                    }`}>
                    {this.state.ticketData.resolved_on !== 'None'
                      ? 'Resolved'
                      : 'Pending'}
                  </span>
                </h4>

                <p className="fs-16 mb-4">
                  <span>Ticket ID :&nbsp;&nbsp;{this.state.ticketData.id}</span>
                  <span className="pl-5">
                    Date :&nbsp;&nbsp;
                    {this.convertDate(this.state.ticketData.created_on)}
                  </span>
                </p>
              </div>
              <div className="row">
                <div className="col-6 pl-0">
                  <InputText
                    required={false}
                    disableInput={true}
                    id="title"
                    name="title"
                    type={'text'}
                    labelText="Title"
                    fieldValue={this.state.ticketData.title}
                    fieldError={''}
                  />
                  <InputText
                    required={false}
                    disableInput={true}
                    id="issueType"
                    name="issueType"
                    type={'text'}
                    labelText="Issue type"
                    fieldValue={this.state.ticketData.issue_type}
                    fieldError={''}
                  />
                  <InputTextArea
                    required={false}
                    disableInput={true}
                    id="description"
                    name="description"
                    type={'text'}
                    labelText="Description"
                    fieldValue={this.state.ticketData.description}
                    fieldError={''}
                  />
                </div>
                <div className="col-6 border-left pr-0">
                  <div className="my-3">
                    <img
                      src={attachmentIcon}
                      className="attach-icon"
                      alt="ngo"
                      onClick={this.showHideRaiseTicketModal}
                    />
                    <span className="pl-2">Attachments</span>
                  </div>
                  {}
                  <div className="mb-5">
                    {this.state.ticketData.attachments?.map((link, index) => {
                      const attachment = link.includes('pdf')
                        ? this.getPDF(link, index)
                        : this.getImage(link, index);
                      return attachment;
                    })}
                  </div>
                  <div>
                    <InputTextArea
                      required={false}
                      disableInput={true}
                      id="comment"
                      name="comment"
                      type={'text'}
                      labelText="Reviews/Comments"
                      fieldValue={this.state.ticketData.resolution_description}
                      fieldError={''}
                      rows={6}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  state: state
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTicketDetails
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportTicket);
