import React, { useEffect, useState } from 'react';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/date-picker.css';
import CalenderIcon from '../assets/icons/calender_icon.svg';

export default function DateInput(props) {
  const { uniqueIdentifierForInput, input, savedValue } = props;
  const [date, setDate] = useState();
  let maxDate = null;
  let minDate = null;
  let disabled = props.disabled;

  if (input.maxDate && input.maxDate === 'now') {
    maxDate = new Date();
  } else if (input.maxDate) {
    maxDate = new Date(input.maxDate);
  }
  if (input.minDate && input.minDate === 'now') {
    minDate = new Date();
  } else if (input.minDate) {
    minDate = new Date(input.minDate);
  }

  const addIdAndName = () => {
    const dateElements = document.getElementsByClassName(
      uniqueIdentifierForInput
    );
    for (let i = 0; i < dateElements.length; i++) {
      dateElements[i].setAttribute('id', uniqueIdentifierForInput);
      dateElements[i].setAttribute('name', uniqueIdentifierForInput);
    }
  };

  useEffect(() => {
    addIdAndName();
  }, []);

  if (savedValue) {
    if (!date) {
      const tempDate = new Date(savedValue !== 'None' ? savedValue : null);
      setDate(tempDate);
    }
  }

  const setDateValue = date => {
    setDate(date);
    props.fieldUpdated(props.stepIndex);
  };

  return (
    <>
      <label htmlFor={uniqueIdentifierForInput}>
        {input?.label}
        {input?.required ||
        (input?.validation && input?.validation === 'required') ||
        input.showrequired ? (
          <span className="color-FF2300">&nbsp;* </span>
        ) : (
          ''
        )}
      </label>

      <div className="date-picker-custom d-flex position-relative">
        <DatePicker
          selected={date}
          onChange={date => setDateValue(date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="01/01/2022"
          disabledKeyboardNavigation
          maxDate={maxDate}
          minDate={minDate}
          yearDropdownItemNumber={130}
          scrollableYearDropdown={true}
          disabled={disabled}
          showYearDropdown
          className={`form-control w-100 position-relative ${uniqueIdentifierForInput}`}
          calendarClassName="date-calendar-custom"
          onChangeRaw={e => e.preventDefault()}></DatePicker>
        <div className="date-picker-icon d-flex justify-content-center align-items-center position-absolute">
          <img src={CalenderIcon} alt="calender" />
        </div>
      </div>
    </>
  );
}
