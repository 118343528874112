import React, { useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import {
  onStepCompletion,
  submitProfile
} from '../actions/completeRegistration.action';
import completeWorkplaceGivingRegistrationData from '../constants/completeWorkplaceGivingRegistrationData';
import completeRegistrationData from '../constants/completeRegistrationData';
import ProfileSubmit from './profileSubmit';
import NgoConditionalMettaVerified from './ConditionalApproval/NgoConditionalMettaVerified';
var completeRegistrationDataMapping;
// import { isSectionSaved } from '../helpers/profileHelpers';
function calculateStepStatuses(data, completeRegistrationDataMapping) {
  return completeRegistrationDataMapping.map((stepData, index) => {
    const { wrapperKey } = stepData;

    let isStepComplete = stepData.fields
      .slice(0)
      .reduce((acc, current, i, arr) => {
        let savedStepData = data[wrapperKey];
        let subWrapperKey = current.subWrapperKey;
        /* let uniqueIdentifierForInput = current.label.replace(/\s/g, '_'); */
        let uniqueIdentifierForInput = current.jsonfield;
        /*  uniqueIdentifierForInput = uniqueIdentifierForInput
          .replace(/[?.]/g, '')
          .toLowerCase(); */

        if (
          uniqueIdentifierForInput === 'area_state' ||
          uniqueIdentifierForInput === 'area_districts'
        ) {
          // console.log( uniqueIdentifierForInput, isCurrentFieldValid, cond1, cond2);
          uniqueIdentifierForInput = 'ngo_area_of_operation';
        }

        let cond1 =
          (!!savedStepData &&
            savedStepData[uniqueIdentifierForInput] !== null &&
            savedStepData[uniqueIdentifierForInput] !== undefined &&
            current?.excludeFromStepCompletionCalculation !== true &&
            savedStepData[uniqueIdentifierForInput] !== '' &&
            savedStepData[uniqueIdentifierForInput]?.length !== 0) ||
          current?.excludeFromStepCompletionCalculation === true;

        let cond2 =
          (!!savedStepData &&
            !!subWrapperKey &&
            savedStepData[subWrapperKey] !== null &&
            savedStepData[subWrapperKey] !== undefined &&
            savedStepData[subWrapperKey] !== '' &&
            savedStepData[subWrapperKey]?.length !== 0 &&
            savedStepData[subWrapperKey][uniqueIdentifierForInput] !== null &&
            savedStepData[subWrapperKey][uniqueIdentifierForInput] !==
              undefined &&
            savedStepData[subWrapperKey][uniqueIdentifierForInput] !== '' &&
            savedStepData[subWrapperKey][uniqueIdentifierForInput]?.length !==
              0) ||
          current?.excludeFromStepCompletionCalculation === true;

        var isCurrentFieldValid = cond1 || cond2;
        //if (index === 4) {
        //   console.log(
        //     uniqueIdentifierForInput,
        //     // savedStepData[uniqueIdentifierForInput],
        //     // savedStepData[subWrapperKey][uniqueIdentifierForInput],
        //     cond1,
        //     cond2,
        //     isCurrentFieldValid
        //   );
        // }

        if (uniqueIdentifierForInput === undefined) {
          var isCurrentFieldValid = true;
        }

        // if(index===0 && uniqueIdentifierForInput=== "ngo_area_of_operation"){
        //   console.log( uniqueIdentifierForInput, isCurrentFieldValid, cond1, cond2);
        // }

        if (
          (current.inputtype === 'radio' &&
            !!savedStepData &&
            typeof savedStepData[uniqueIdentifierForInput] === 'boolean') ||
          current.validation !== 'required' ||
          current.excludeFromStepCompletionCalculation
        ) {
          isCurrentFieldValid = true;
        }
        if (index === 6 && !savedStepData['tnc_accepted']) {
          isCurrentFieldValid = false;
        }

        if (
          index === 5 &&
          window.location.href.includes('email=') &&
          current.inputtype === 'radio' &&
          !!savedStepData
        ) {
          isCurrentFieldValid = true;
        }

        if (
          uniqueIdentifierForInput &&
          uniqueIdentifierForInput.includes(
            `organization_external_audit_report_for_fy`
          )
        ) {
          if (savedStepData['external_audit_report']?.length) {
            isCurrentFieldValid = true;
          }
        }
        if (
          uniqueIdentifierForInput &&
          uniqueIdentifierForInput.includes(`balance_sheet_for_fy`)
        ) {
          if (savedStepData['balance_sheet']?.length) {
            isCurrentFieldValid = true;
          }
        }

        let isCurrentStepValid = acc && isCurrentFieldValid;

        if (!isCurrentFieldValid) arr.splice(1);

        return isCurrentStepValid;
      }, true);
    // if (subwrapperKey === 'ngo_board_members') {
    //   const ngoBoardMembersData = data[wrapperKey];
    //   if (ngoBoardMembersData) {
    //     isStepComplete = true;
    //   } else {
    //     isStepComplete = false;
    //   }
    // }
    // if (data[`is_verified_step${index + 1}`] === false) {
    //   isStepComplete = false;
    // }

    return isStepComplete;
  });
}

function SideBarComponent(props) {
  if (props.state.ngo.ngoProfile.is_scn) {
    completeRegistrationDataMapping = completeRegistrationData;
  } else {
    completeRegistrationDataMapping = completeWorkplaceGivingRegistrationData;
  }
  const [stepStatuses, setStepStatuses] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);
  const [allCommentsResolved, setAllCommentsResolved] = useState(false);
  const [resolvedStatus, setResolvedStatus] = useState([]);

  useEffect(() => {
    setStepStatuses(
      calculateStepStatuses(
        props.state.register.ngoSavedData,
        completeRegistrationDataMapping
      )
    );
  }, [props]);

  const checkUnresolvedComment = () => {
    let stepStatusesArr = stepStatuses.slice();

    if (props.allNgoComments && props.allNgoComments.length > 0) {
      const unresolvedCommArr = props.allNgoComments.filter(
        comment =>
          comment.is_resolved === false &&
          comment.is_external === true &&
          comment.is_deleted === false
      );

      if (unresolvedCommArr.length === 0) {
        setAllCommentsResolved(true);
      } else {
        let resolvedStatusArr = [true, true, true, true, true, true, true];
        setAllCommentsResolved(false);
        unresolvedCommArr.map(review => {
          if (review.is_external && review.is_resolved === false) {
            stepStatusesArr[review.section_id - 1] = false;
            resolvedStatusArr[review.section_id - 1] = false;
          }
          return false;
        });
        setStepStatuses(stepStatusesArr);
        setResolvedStatus(resolvedStatusArr);
      }
    }
    // console.log("unresolvedCommArr", unresolvedCommArr);
  };
  useEffect(() => {
    if (
      props.state.register.ngoSavedData.l0_status === 'modification_required' ||
      props.state.register.ngoSavedData.l1_status === 'modification_required' ||
      props.state.register.ngoSavedData.l2_status === 'modification_required'
    )
      checkUnresolvedComment();
  }, [props.allNgoComments]);
  const getSubmissionStatus = () => {
    if (
      props.state.register.ngoSavedData.l1_status === 'submitted' ||
      props.state.register.ngoSavedData.l0_status === 'submitted'
    ) {
      return (
        <>
          <label
            style={{
              backgroundColor: '#FDEFE5',
              color: '#F2711C',
              padding: '0.45rem',
              borderRadius: '3px'
            }}>
            Under Review
          </label>
        </>
      );
    } else if (
      props.state.register.ngoSavedData.l1_status === 'l1_approved' ||
      props.state.register.ngoSavedData.l0_status === 'l0_approved'
    ) {
      return (
        <label
          style={{
            backgroundColor: '#DEF7E4',
            color: '#21BA45',
            padding: '0.45rem',
            borderRadius: '3px'
          }}>
          Approved
        </label>
      );
    } else if (
      props.state.register.ngoSavedData.l1_status === 'l1_rejected' ||
      props.state.register.ngoSavedData.l0_status === 'l0_rejected'
    ) {
      return (
        <label
          style={{
            backgroundColor: '#FFEDED',
            color: '#C52424',
            padding: '0.45rem',
            borderRadius: '3px'
          }}>
          {/* Draft */}
          Rejected
        </label>
      );
    } else if (
      props.state.register.ngoSavedData.l1_status === 'modification_required' ||
      props.state.register.ngoSavedData.l0_status === 'modification_required' ||
      props.state.register.ngoSavedData.l2_status === 'modification_required'
    ) {
      return (
        <label
          style={{
            backgroundColor: '#FFF7DE',
            color: '#FBBD08',
            padding: '0.45rem',
            borderRadius: '3px'
          }}>
          Modification Requested
        </label>
      );
    } else
      return (
        <label
          style={{
            backgroundColor: '#FFEDED',
            color: '#C52424',
            padding: '0.45rem',
            borderRadius: '3px'
          }}>
          Not Submitted{' '}
        </label>
      );
  };

  function openCloseTile(tileId) {
    window.location.href = tileId;
  }
  const getBulletPointStatus = (index, profileData) => {
    const ngoData = props.state.register.ngoSavedData;

    if (profileData?.title === 'Legal Compliance') {
      if (stepStatuses[index]) {
        if (ngoData?.legal_compliance?.legal_structure === '2') {
          if (
            ngoData?.legal_compliance?.trust_deed?.length &&
            ngoData?.legal_compliance?.trust_deed_registration_number?.length &&
            ngoData?.legal_compliance?.trust_registration_certificate?.length
          ) {
            return true && stepStatuses[index];
          } else {
            return false && stepStatuses[index];
          }
        } else if (ngoData?.legal_compliance?.legal_structure === '1') {
          if (
            ngoData?.legal_compliance?.society_registration_certificate
              ?.length &&
            ngoData?.legal_compliance?.society_registration_number?.length &&
            ngoData?.legal_compliance?.society_by_laws?.length
          ) {
            return true && stepStatuses[index];
          } else {
            return false && stepStatuses[index];
          }
        } else if (ngoData?.legal_compliance?.legal_structure === '3') {
          if (
            ngoData?.legal_compliance?.article_of_assc?.length &&
            ngoData?.legal_compliance?.cert_of_incorporation?.length &&
            ngoData?.legal_compliance?.date_of_incorp?.length &&
            ngoData?.legal_compliance?.moa?.length
          ) {
            return true && stepStatuses[index];
          } else {
            return false && stepStatuses[index];
          }
        }
      }
    }
    return stepStatuses[index];
  };
  const getstatusArr = () => {
    let temparr = [];
    completeRegistrationDataMapping.forEach((element, index) => {
      temparr.push(getBulletPointStatus(index, element));
    });

    return temparr;
  };
  return (
    <>
      <div className="col-3">
        <div className="registration-side-bar p-3 bg-white">
          <p className="pl-3 text-212529 fs-13 mb-2">
            Submission Status :{' '}
            {props.state.register.ngoSavedData.l1_status ||
            props.state.register.ngoSavedData.l0_status
              ? getSubmissionStatus()
              : 'Loading...'}
          </p>
          {props.state.register.ngoSavedData.l1_status !== 'l1_approved' &&
            props.state.register.ngoSavedData.l1_status !== 'submitted' && (
              <p className="text-7B8FA6 fs-12 pl-3">
                You should complete your Profile before creating{' '}
                {props.state.ngo?.ngoProfile.is_scn ? 'campaigns' : 'missions'}
              </p>
            )}
          <ul className="list-group w-100">
            {completeRegistrationDataMapping.map((data, i) => {
              let successClassName;
              if (getBulletPointStatus(i, data)) {
                successClassName = 'active';
              }
              return (
                <li
                  onClick={e => openCloseTile('#step-container-' + (i + 1))}
                  className={`list-group-item  fs-12 text-212529 cursor-pointer collapsed ${successClassName} ${
                    resolvedStatus.length > 0 && !resolvedStatus[i]
                      ? 'step-index-border'
                      : ' '
                  } py-3`}
                  key={i}>
                  {/* <a
                className="text-decoration-none fs-12 font-weight-normal"
                href={`#step-container-${i + 1}`}
                key={i}> */}
                  {data.title}
                  {/* </a> */}
                  {props.stepEditStatuses[i] && props.modificationRequired && (
                    // resolvedStatus?.length > 0 &&
                    // !resolvedStatus[i] &&
                    <span
                      className={` ${
                        props.resolveCmtsArr[i] === 0 &&
                        props.stepEditStatuses[i]
                          ? 'green-dot'
                          : 'alert-dot'
                      } float-right`}></span>
                  )}
                </li>
              );
            })}

            <ProfileSubmit
              data={props}
              stepStatuses={getstatusArr()}
              disabled={
                !(props.state.ngo?.ngoProfile.is_scn &&
                props.state.register.ngoSavedData.l1_status === 'new'
                  ? props.state.register.ngoSavedData.l1_status === 'new' ||
                    props.state.register.ngoSavedData.l1_status ===
                      'l1_rejected' ||
                    props.state.register.ngoSavedData.l1_status ===
                      'modification_required' ||
                    props.state.register.ngoSavedData.l2_status ===
                      'modification_required'
                  : props.state.ngo?.ngoProfile.is_wp &&
                    props.state.register.ngoSavedData.l0_status === 'new' &&
                    props.state.register.ngoSavedData.l1_status === 'new'
                  ? props.state.register.ngoSavedData.l0_status === 'new' ||
                    props.state.register.ngoSavedData.l0_status ===
                      'l0_rejected' ||
                    props.state.register.ngoSavedData.l0_status ===
                      'modification_required'
                  : (props.state.register.ngoSavedData.l1_status === 'new' ||
                      props.state.register.ngoSavedData.l1_status ===
                        'l0_rejected' ||
                      props.state.register.ngoSavedData.l1_status ===
                        'modification_required' ||
                      props.state.register.ngoSavedData.l2_status ===
                        'modification_required') &&
                    allCommentsResolved)
              }
              approved={props.state.ngo.ngoProfile.isProfileComplete}
            />
          </ul>
        </div>
        {props.state.ngo.ngoProfile.is_conditionally_approved &&
          props.state.ngo.ngoProfile.is_mettasocial_preonboarded && (
            <NgoConditionalMettaVerified {...props} />
          )}
      </div>
    </>
  );
}
// disabled={
//   !(
//     props.state.ngo?.ngoProfile.is_scn &&
//      (props.state.register.ngoSavedData.l1_status === 'submitted'
//   || props.state.register.ngoSavedData.l1_status === 'new')
//     ? props.state.register.ngoSavedData.l1_status === 'new' ||
//       props.state.register.ngoSavedData.l1_status === 'l0_rejected' ||
//       props.state.register.ngoSavedData.l1_status ===
//         'modification_required' ||
//         props.state.register.ngoSavedData.l2_status ===
//         'modification_required'
//     : (
//       // (props.state.register.ngoSavedData.l1_status === 'submitted'
//       // || props.state.register.ngoSavedData.l1_status === 'new') ?
//      props.state.register.ngoSavedData.l0_status === 'new' ||
//         props.state.register.ngoSavedData.l0_status ===
//           'l1_rejected' ||
//         props.state.register.ngoSavedData.l0_status ===
//           'modification_required'
//     ) &&
//       allCommentsResolved)
// }
const mapStateToPropsSidebar = state => ({
  state: state
});

const mapDispatchToPropsSidebar = dispatch => {
  return bindActionCreators(
    {
      onStepCompletion,
      submitProfile
    },
    dispatch
  );
};

export default connect(
  mapStateToPropsSidebar,
  mapDispatchToPropsSidebar
)(SideBarComponent);
