import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { Routes } from '../../constants/path.constants';
import MissionService from '../../services/mission.service';
import {
  getMission,
  missionUpdate,
  missionUpdateMail,
  unpublishMission,
  getMissionFunds,
  getRegionalSettings
} from '../../actions';
import {
  getCloudinaryUrlForImage,
  createMarkup,
  getCurrencyIcon
} from '../../helpers/util';
import ModalSide from '../modalSide';
import Modal from '../newDesign/Modals/Modal';
import AddUpdate from './addUpdate';
import ActivityCard from './activityCard';
import MissionGallery from './missionGallery';
import MissionGallery2 from './missionGallery2';
import EmptyUpdate from '../../assets/icons/update_empty_state.svg';
import { sendMail } from '../../helpers/emailSender';
class MissionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      missionInfo: null,
      missionFunds: null,
      selectedTab: 'Story',
      missionStatistics: {},
      showOptions: false,
      showSideModal: false,
      showModal: false,
      imagesArray: [],
      clearForm: false,
      currencyData: null
    };
    this.tabs = ['Story', 'Activities', 'Updates', 'Roadmap', 'Gallery'];
  }

  selectTab = tab => {
    this.setState({ selectedTab: tab });
  };

  showHideOptions = () => {
    if (this.state.showOptions) this.setState({ showOptions: false });
    else this.setState({ showOptions: true });
  };

  showHideSideModal = () => {
    if (this.state.showSideModal) {
      this.setState(
        {
          showSideModal: false,
          clearForm: true
        },
        () => {
          this.setState({ clearForm: false });
        }
      );
    } else this.setState({ showSideModal: true });
  };

  handleAddUpdateClick = () => {
    if (this.state.showOptions) this.showHideOptions();
    this.showHideSideModal();
  };

  showHideModal = () => {
    if (this.state.showModal) this.setState({ showModal: false });
    else this.setState({ showModal: true });
  };

  apiErrorToast = () => {
    toast.error("Oops! Looks like we couldn't process your request.");
  };

  unPublishMission = () => {
    this.props
      .unpublishMission(this.props.id)
      .then(res => {
        if (res.code && res.code !== '200' && res.code !== 200) {
          this.apiErrorToast();
          return false;
        } else {
          toast.success(`Mission Unpublished successfully.`);
          this.showHideModal();
          this.showHideOptions();

          sendMail({
            templateName: 'missionUnpublishAcknowledgementToNgo',
            sendTo: this.props.userProfile.email,
            subject: 'Mission Unpublish Confirmed',
            templateData: {
              mission_name: this.props.missionInfo.mission_title
            }
          });
          sendMail({
            templateName: 'missionUnpublishAlertToAdmin',
            sendTo: 'ngo.partnerships@mettasocial.com',
            subject: 'Mission Unpublish by NGO',
            templateData: {
              mission_name: this.props.missionInfo.mission_title,
              ngo_name: this.props.missionInfo.ngo_name
            }
          });
          setTimeout(() => {
            window.location.pathname = '/ngo/mission/details/' + this.props.id;
          }, 500);
        }
      })
      .catch(() => {
        this.apiErrorToast();
      });
  };
  getPrimaryCause = cause => {
    if (cause) {
      return <span className="preview-cause px-3 py-1 ml-2">{cause}</span>;
    }
  };
  getCauseList = list => {
    if (list) {
      return (
        <span>
          {list.map((cat, index) => {
            return (
              <span key={index + cat} className="preview-cause px-3 py-1 ml-2">
                {cat}
              </span>
            );
          })}
        </span>
      );
    }
  };
  getImages = res => {
    let imagesArray = [];

    if (res.updates?.length > 0) {
      res.updates.map(item => {
        const index = imagesArray.findIndex(x => {
          const t1 = new Date(x.timestamp);
          const t2 = new Date(item.created_on);
          if (
            t1.getDate() === t2.getDate() &&
            t1.getMonth() === t2.getMonth() &&
            t1.getFullYear() === t2.getFullYear()
          ) {
            return true;
          }
          return false;
        });

        if (index !== -1) {
          imagesArray[index].urlArray = [
            ...imagesArray[index].urlArray,
            ...item.media
          ];
        } else {
          let obj = {
            timestamp: item.created_on,
            urlArray: []
          };
          if (item.media && item.media.length > 0) {
            obj.urlArray = [...item.media];
          }
          if (obj.urlArray.length > 0) {
            imagesArray.push(obj);
          }
        }
        return false;
      });
    }
    imagesArray.sort((a, b) => {
      const timeStamp1 = new Date(a.timestamp);
      const timeStamp2 = new Date(b.timestamp);
      if (timeStamp1.getTime() < timeStamp2.getTime()) {
        return 1;
      }
      if (timeStamp1.getTime() > timeStamp2.getTime()) {
        return -1;
      }
      return 0;
    });
    // setImageArr(imagesArray);
    this.setState({ imagesArray: imagesArray });
  };

  getMissionStats = () => {
    let missionId = window.location.href.split('/')[6];
    let ngoId = this.props.userProfile.orgId || this.props.userProfile.sub;
    MissionService.getStatisticsByMissionId(missionId, ngoId)
      .then(response => {
        this.setState({
          missionStatistics: response.data.result
        });
      })
      .catch(() => {});
  };

  componentDidMount() {
    this.getMissionStats();
    this.props.getMission(this.props.id, 'detail');
    this.props.getRegionalSettings(this.props.userProfile.sub);
    // this.props.getMissionFunds(this.props.id);
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.missionInfo) {
      this.isShowLoader = false;
      if (
        !nextprops.code &&
        nextprops.missionInfo &&
        nextprops.missionInfo.updates?.length > 0
      ) {
        this.getImages(nextprops.missionInfo);
        nextprops.missionInfo.updates.sort((a, b) => {
          const timeStamp1 = new Date(a.created_on);
          const timeStamp2 = new Date(b.created_on);
          if (timeStamp1.getTime() < timeStamp2.getTime()) {
            return 1;
          }
          if (timeStamp1.getTime() > timeStamp2.getTime()) {
            return -1;
          }
          return 0;
        });
      }
      this.setState({
        missionInfo: nextprops.missionInfo,
        missionFunds: nextprops.missionFunds,
        currencyData: nextprops.currency
      });
    } else {
      if (
        nextprops.missionDetailErrorResponse &&
        nextprops.missionDetailErrorResponse.code
      ) {
        toast.error('Some error occurred, please try again in sometime');
      }
    }
  }
  missionUpdates = () => {
    if (this.state.missionInfo && this.state?.missionInfo?.updates?.length) {
      return (
        <div className="mt-3 fs-12 col-12 text-break">
          {this.state.missionInfo.updates.map((update, index) => {
            return (
              <div key={index + this.state.missionInfo.mission_title}>
                <ActivityCard
                  missionInfo={this.state.missionInfo}
                  update={update}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="my-5 col-12 d-flex justify-content-center mt-4">
          <div className="">
            <div className="d-flex justify-content-center">
              <img
                alt="img"
                className="mx-auto"
                src={EmptyUpdate}
                width={200}
                height={200}
              />
            </div>
            <div className="fs-16 fw-500 text-black text-center my-3">
              You have not added any update.
            </div>
            <div className="text-969696 fs-14 mt-3">
              There are no updates yet to show for this mission.
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    let currencySymb = getCurrencyIcon(this.state.currencyData);
    let causeList = this.state?.missionInfo?.sub_cause_category || [];
    let primaryCause = this.state?.missionInfo?.cause_category_slug || '';
    return (
      <>
        <div className="row justify-content-center bg-light-gray p-4">
          <div className="col-12 p-0">
            <h5 className="mb-4">
              <Link
                className="text-1FA4FA fs-12 h-lh-12"
                to={`/ngo/missions/list`}>
                Missions
              </Link>
              &nbsp;&nbsp;
              <span className="text-1FA4FA fs-12 h-lh-12">&gt;</span>
              &nbsp;&nbsp;
              <span className="text-7B8FA6 fs-12 h-lh-12 text-break">
                {this.state.missionInfo?.mission_title}
              </span>
            </h5>
          </div>
          <div className="col-12 bg-white card-box-shadow p-0 mb-5 rounded-8">
            <div className="super-wide w-100 h-0 position-relative rounded-top-8 loading-bg">
              {this.state.missionInfo && this.state.missionInfo.image_url && (
                <img
                  src={
                    getCloudinaryUrlForImage(
                      this.state.missionInfo.image_url,
                      500
                    ) ||
                    getCloudinaryUrlForImage(
                      'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                      500
                    )
                  }
                  data-src={
                    this.state.missionInfo.image_url ||
                    'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                  }
                  onError={e =>
                    (e.target.src = e.target.getAttribute('data-src'))
                  }
                  alt={`${this.state.missionInfo.mission_title} mission`}
                  className="abs-full rounded-top-8 preview-image"
                />
              )}
            </div>
            <div className="p-4 row">
              <div className="col-12 px-0 mt-2 d-flex align-items-center">
                <p className="h2 px-0 mt-2 text-bold text-1C1C1C w-50 text-break">
                  {this.state.missionInfo?.mission_title}
                  {this.state.missionInfo?.status === 'PUBLISHED' &&
                    (this.state.missionInfo?.version_status === 'PUBLISHED' ||
                      this.state.missionInfo?.version_status === 'DRAFT' ||
                      this.state.missionInfo?.version_status ===
                        'UNDER_REVIEW') && (
                      <span className="color-F2711C bg-FDEFE5 fw-500 px-3 py-2 ml-4 rounded fs-12">
                        Modified
                      </span>
                    )}
                  {this.state.missionInfo?.mission_type && (
                    <span className="preview-mission-type text-capitalize fs-12 px-3 py-2 ml-4">
                      {this.state.missionInfo?.mission_type === 'mission'
                        ? 'Mission'
                        : 'Disaster'}
                    </span>
                  )}
                </p>
                <div className="w-50 text-right">
                  {this.state.missionInfo?.status === 'PUBLISHED' && (
                    <span className="status-active rounded-b-3 fs-12 px-4 py-1 mr-2">
                      ACTIVE
                    </span>
                  )}
                  {this.state.missionInfo?.status === 'UNPUBLISHED' && (
                    <span className="status-inactive rounded-b-3 fs-12 px-4 py-1 mr-2">
                      UNPUBLISHED
                    </span>
                  )}
                  {this.state.missionInfo?.status === 'UNDER_REVIEW' && (
                    <span className="status-inactive rounded-b-3 fs-12 px-4 py-1 mr-2">
                      INACTIVE
                    </span>
                  )}
                  {(this.state.missionInfo?.status === 'PUBLISHED' ||
                    this.state.missionInfo?.status === 'UNPUBLISHED') && (
                    <>
                      <Link
                        className="mr-2"
                        to={Routes.NGO.MISSION_STATS + '/' + this.props.id}>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg mr-2 px-4 fs-14 dm-bold">
                          Stats
                        </button>
                      </Link>
                      <Link
                        className="mr-2"
                        to={
                          Routes.NGO.MISSION_PLEDGE_SUMMARY +
                          '/' +
                          this.props.id
                        }>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg mr-2 px-4 fs-14 dm-bold">
                          Donation Summary
                        </button>
                      </Link>
                    </>
                  )}
                  {/* <button
                    type="button"
                    className="btn btn-outline-primary btn-lg mr-2 px-3 fs-14 dm-bold">
                    <i className="fas fa-share-alt"></i>
                  </button> */}

                  {this.state.missionInfo?.status === 'PUBLISHED' &&
                    this.state.missionInfo?.version_status !==
                      'UNDER_REVIEW' && (
                      <>
                        <div className="position-relative d-inline-block">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg px-3 fs-14 dm-bold"
                            onClick={() => this.showHideOptions()}>
                            <i className="fas fa-ellipsis-v"></i>
                          </button>
                          {this.state.showOptions && (
                            <>
                              <div
                                className="options-overlay position-fixed"
                                onClick={() => this.showHideOptions()}></div>
                              <div className="options-pop-up  position-absolute p-3 fs-14 text-left">
                                <span
                                  className="d-block cursor-pointer mb-3"
                                  onClick={() =>
                                    this.props.history.push(
                                      Routes.NGO.MISSION_EDIT +
                                        '/' +
                                        this.props.id
                                    )
                                  }>
                                  Edit Mission
                                </span>
                                {
                                  <span
                                    className="d-block cursor-pointer mb-3"
                                    onClick={() => this.handleAddUpdateClick()}>
                                    Add Update
                                  </span>
                                }
                                <span
                                  className="d-block cursor-pointer"
                                  onClick={() => this.showHideModal()}>
                                  Unpublish Mission
                                </span>
                                {/* <span
                                className="d-block cursor-pointer">
                                Deactivate
                              </span> */}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                </div>
              </div>

              <div className="mt-3 col-12 px-0">
                <span>
                  <img
                    src={this.state?.missionInfo?.ngo_image}
                    height={43}
                    width={43}
                    className="img-logo mr-2"
                    alt=""
                  />
                </span>
                <span className="fs-14">
                  {this.state?.missionInfo?.ngo_name}
                </span>
              </div>
              <div className="mt-3 col-12 px-0">
                {/* {this.state.missionInfo?.cause_category_slug && (
                  <span className="preview-cause px-3 py-1">
                    {this.state.missionInfo?.cause_category_slug}
                  </span>
                )} */}
                <div>
                  {this.getPrimaryCause(primaryCause)}
                  {this.getCauseList(causeList)}
                </div>
              </div>

              <div className="d-flex justify-content-between w-100 align-items-center p-1">
                <div>
                  <p className="text-capitalize col-12 mt-3 mb-0 px-0 fs-14">
                    Program Location:&nbsp;
                    {this.state?.missionInfo?.locations?.map((loc, index) => {
                      return (
                        <span
                          key={index + loc.id + loc.country + loc.pincode}
                          className="">
                          {index !== 0 ? ', ' : ''}
                          {loc.city}
                        </span>
                      );
                    })}
                  </p>
                  {this.state?.missionInfo?.mission_type === 'mission' && (
                    <p className="text-capitalize col-12 mt-2 mb-4 px-0 pb-2 fs-14">
                      Target Beneficiaries:&nbsp;
                      {this.state.missionInfo?.target_beneficiary_slug?.map(
                        (beneficiary, index) => {
                          return (
                            <span key={index + beneficiary} className="">
                              {index !== 0 ? ', ' : ''}
                              {beneficiary}
                            </span>
                          );
                        }
                      )}
                    </p>
                  )}
                </div>
                <div className="float-right">
                  {this.state.missionInfo?.sdg_slug &&
                    this.state.missionInfo?.sdg_slug.map((itm, index) => {
                      return (
                        <img
                          key={itm + index}
                          src={itm.icon}
                          alt="sdg_icon"
                          width={60}
                          height={60}
                          className=" m-1"
                        />
                      );
                    })}
                </div>
              </div>

              <div className="w-100 mb-4 pb-4 ml-3">
                <div className="d-inline-block p-0 bg-F7F7F7 px-4 py-3">
                  <div className="d-inline-block pr-4 mr-2 position-relative">
                    <span className="d-block fs-15">Total Amount Raised</span>
                    <span className="d-block color-26AC48 fs-20 fw-400">
                      {currencySymb}{' '}
                      {this.state.missionStatistics?.total_amount_raised
                        ? new Intl.NumberFormat('en-IN').format(
                            this.state.missionStatistics?.total_amount_raised
                          )
                        : 0}
                    </span>
                  </div>
                  {/* <div className="d-inline-block pl-4 ml-2">
                    <span className="d-block fs-15">
                      {this.state?.missionInfo?.mission_type === 'mission'
                        ? 'Total Active Supporters'
                        : 'Total Supporters'}
                    </span>
                    <span
                      className="d-block fs-20 fw-400"
                      style={{ height: '30px' }}>
                      {this.state.missionFunds?.active_supporters
                        ? this.state.missionFunds?.active_supporters
                        : 0}
                    </span>
                  </div> */}
                </div>
                {this.state.missionInfo?.status === 'PUBLISHED' && (
                  <div className="d-inline-block p-0 bg-F7F7F7 px-4 py-3 ml-3">
                    <div className="d-inline-block ml-2">
                      <span className="d-block fs-15">Published For :</span>
                      <span
                        className="d-block fs-20 fw-400 "
                        style={{ height: '30px' }}>
                        {this.state.missionInfo?.published_for?.map(
                          (value, index) => {
                            if (index < 2) {
                              return (
                                <label
                                  key={index}
                                  className="bg-E0F3FF label-blue fs-12 text-005492 mr-1 border-radius-3">
                                  {value}
                                </label>
                              );
                            }
                          }
                        )}
                        {this.state.missionInfo?.published_for.length > 2 ? (
                          <span className="cursor-pointer fs-12 ml-2 text-007bff tooltipss">
                            {' '}
                            {`+ ${this.state.missionInfo.published_for.length -
                              2} More`}
                            <span className="tooltipss-location">
                              {this.state.missionInfo.published_for
                                .slice(2)
                                .join(', ')}
                            </span>
                          </span>
                        ) : null}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="preview-nav-tabs col-12 px-0 pb-2">
                {this.tabs.map((tab, index) => {
                  return (
                    <span
                      key={index + tab}
                      className={`tab p-3 position-relative fs-16 cursor-pointer ${
                        this.state.selectedTab === tab ? 'active' : ' '
                      }`}
                      onClick={() => this.selectTab(tab)}>
                      {tab}
                    </span>
                  );
                })}
              </div>
              {this.state.selectedTab === 'Story' && (
                <p
                  className="mt-3 fs-12 preview-content col-12 text-break"
                  dangerouslySetInnerHTML={createMarkup(
                    this.state.missionInfo?.story
                  )}></p>
              )}
              {this.state.selectedTab === 'Activities' && (
                <p
                  className="mt-3 fs-12 preview-content col-12 text-break"
                  dangerouslySetInnerHTML={createMarkup(
                    this.state.missionInfo?.activities
                  )}></p>
              )}
              {this.state.selectedTab === 'Updates' && this.missionUpdates()}
              {this.state.selectedTab === 'Roadmap' && (
                <p
                  className="mt-3 fs-12 preview-content col-12 text-break"
                  dangerouslySetInnerHTML={createMarkup(
                    this.state.missionInfo?.roadmap
                  )}></p>
              )}
              {this.state.selectedTab === 'Gallery' && (
                <div className="mt-3 fs-12  w-100">
                  <MissionGallery2 gallery={this.state.missionInfo?.gallery} />
                  <MissionGallery
                    imageArr={this.state.imagesArray}
                    hideEmpty={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <ModalSide
          showModal={this.state.showSideModal}
          handleCloseModal={this.showHideSideModal}
          className={'side-modal-lg'}
          title={''}
          hideCancel={true}>
          {!this.state.clearForm && (
            <AddUpdate
              title={'Add Update'}
              missionId={this.props.id}
              mission={this.state.missionInfo}
              userProfile={this.props.userProfile}
              handleCloseModal={this.showHideSideModal}
              missionUpdate={this.props.missionUpdate}
              missionUpdateMail={this.props.missionUpdateMail}
              getMission={this.props.getMission}
              permissions={this.props.permissions}
            />
          )}
        </ModalSide>
        <Modal
          show={this.state.showModal}
          title={'Unpublish Mission?'}
          handleCloseModal={this.showHideModal}
          sizeClass={'modal-lg'}
          hideCloseBtn={true}>
          <h4 className="text-black fs-16 fw-500">
            No new supporters can support your mission once the mission is
            unpublished.
          </h4>
          <p className="color-666565 fs-16 mb-4">
            Only the donors already supporting the cause can see the updates and
            the donations will continue by them.
          </p>
          <p className="color-666565 fs-16">
            Are you sure you want to unpublish the Mission?
          </p>
          <div className="text-right">
            <button
              type="button"
              onClick={() => this.unPublishMission()}
              className={`btn btn-primary btn-lg fs-14 px-4 dm-bold`}>
              Unpublish
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  missionInfo: state.missionData.missionDetailSuccessResponse,
  missionFunds: state.missionData.missionFundsSuccess,
  missionDetailErrorResponse: state.missionData.missionDetailErrorResponse,
  missionUpdateSuccessResponse: state.missionData.missionUpdateSuccessResponse,
  missionUpdateErrorResponse: state.missionData.missionUpdateErrorResponse,
  userProfile: state.login.userInfo,
  currency: state?.currencyData?.currencyData?.currency
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMission,
      getMissionFunds,
      missionUpdate,
      missionUpdateMail,
      unpublishMission,
      getRegionalSettings
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionDetails);
