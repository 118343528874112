import React, { useState } from 'react';
import { getFormattedDate } from '../../helpers/util';
import EmptyGallary from '../../assets/icons/gallary_empty_state.svg';

import pdfIcon from '../../assets/images/pdf.png';
import invalidFile from '../../assets/images/invalidFile.png';
import Modal from '../newDesign/Modals/Modal';

export default function MissionGallery({ imageArr, hideEmpty }) {
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrlArr] = useState('');

  const showModalFn = url => {
    setFileUrlArr(url);
    setShowModal(true);
  };
  const closeModalFn = () => {
    setShowModal(false);
  };
  const showThumbnails = (image, index) => {
    const getFileExtension = image => {
      const fileName = image.substring(image.lastIndexOf('/') + 1);
      const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      return extension;
    };
    let fileExt = getFileExtension(image);
    const supportedExtensions = [
      'jpg',
      'jpeg',
      'bmp',
      'png',
      'gif',
      'mp4',
      'avi',
      'mov',
      'mpeg',
      'mpg',
      'pdf'
    ];
    return (
      <>
        {fileExt === 'pdf' || fileExt === 'PDF' ? (
          <>
            <img
              key={index + image}
              src={pdfIcon}
              alt={'update'}
              width={124}
              height={84}
              className="thumbnail mr-3 cursor-pointer"
              onClick={e => showModalFn(image)}
            />
          </>
        ) : fileExt === 'mp4' ||
          fileExt === 'avi' ||
          fileExt === 'mov' ||
          fileExt === 'mpeg' ||
          fileExt === 'mpg' ? (
          <>
            <video
              key={index + image}
              src={image}
              alt={'update'}
              width={124}
              height={84}
              className="thumbnail mr-3 cursor-pointer"
              onClick={e => showModalFn(image)}
            />
          </>
        ) : supportedExtensions.includes(fileExt.toLowerCase()) ? (
          <>
            <img
              key={index + image}
              src={image}
              alt={'update'}
              width={124}
              height={84}
              className="thumbnail mr-3 cursor-pointer"
              onClick={e => showModalFn(image)}
            />
          </>
        ) : (
          <>
            <img
              key={index + image}
              src={invalidFile}
              alt={'invalid file'}
              width={124}
              height={84}
              className="thumbnail mr-3 "
            />
          </>
        )}
      </>
    );
  };
  const getGallery = () => {
    if (imageArr && imageArr.length > 0) {
      return (
        <div className="col-12">
          {imageArr.map((item, index) => {
            return (
              <div
                key={index + item.timestamp}
                className="mb-4 pb-2 pl-5 position-relative mission-gallery-row">
                <p className="color-81BC30 fs-14 fw-500 mb-2">
                  {getFormattedDate('dd MMM yyyy', item.timestamp)}
                </p>
                <div className="d-flex flex-wrap">
                  {item.urlArray.map((image, imageIndex) => {
                    return (
                      <>
                        {showThumbnails(image, imageIndex)}
                        {/* <img
                          key={imageIndex + image}
                          src={image}
                          alt={'mission'}
                          width={124}
                          height={84}
                          className="mr-2 mission-gallery-image"
                        /> */}
                      </>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    if (imageArr.length === 0 && !hideEmpty) {
      return (
        <div className="col-12 d-flex justify-content-center mt-4">
          <div className="">
            <div className="d-flex justify-content-center">
              <img
                className="mx-auto"
                src={EmptyGallary}
                width={200}
                height={200}
                alt="img"
              />
            </div>
            <div className="fs-16 fw-500 text-black text-center my-3">
              You have not added any updates.
            </div>
            <div className="text-969696 fs-14 mt-3">
              There are no photos uploaded for this mission yet.
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="">
        <div className="row">{getGallery()}</div>
        <Modal show={showModal} handleCloseModal={closeModalFn}>
          {showModal && (
            <div style={{ height: '70vh', width: '70vw' }}>
              <iframe
                src={fileUrl}
                title="file"
                width="75%"
                height="75%"></iframe>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
}
