import React from 'react';

const ReceiptSample = ({
  receiptData,
  directorName,
  directorSignature,
  directorStamp
}) => {
  const getDateFormat = dateValue => {
    const formattedDate = dateValue.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
    return formattedDate;
  };

  return (
    <>
      <div className=" fs-13 overflow-scroll-85vh ">
        <div className="row pl-2 pr-4 py-1 mb-2 justify-content-between align-items-center">
          <div className="col-6">
            <img
              src={receiptData?.ngo_logo}
              alt="ngo-logo"
              className="image_width"
            />
          </div>

          <div className="col-6 text-right mt-neg-1">
            <div className="fs-22 fw-600 color-1B1C1D my-1">
              {receiptData?.ngo_name}
            </div>
            <div className="fs-13 color-1B1C1D my-1">
              {receiptData?.address}
            </div>
            <div className="fs-13 color-1B1C1D my-1">
              {receiptData?.ngo_email_id} |{' '}
              <span>+91-{receiptData?.ngo_contact_number} </span>
            </div>
          </div>
        </div>
        <div className="row pb-4 mt-neg-2 justify-content-between">
          <div className="col-12">
            <div className="text-967F41 fs-28 fw-500">Receipt for Donation</div>
            <div className="fs-15 color-1B1C1D my-4 ">
              Date: {getDateFormat(new Date())}
            </div>

            <div className="row ml-1-neg">
              <div className="col-2 color-666565 fs-13">Received from:</div>
              <div className="col-1 color-1B1C1D fs-13">************</div>
              <div className="w-100"></div>
              <div className="col-2 color-666565 fs-13">PAN Number:</div>
              <div className="col-1 color-1B1C1D fs-13">*********</div>
              <div className="w-100"></div>
              <div className="col-2 color-666565 fs-13">Address: </div>
              <div className="col-1 color-1B1C1D fs-13">*****************</div>
            </div>

            <div className="fs-15 fw-600 color-666565 my-4 ">
              Amount Received:{' '}
              <span className="mx-4 color-1B1C1D fw-600">₹ *******.00</span>
            </div>

            <div className="fs-13  color-1B1C1D my-4 ">Dear Mr. **********</div>
            <div className="fs-13  color-1B1C1D my-4 ">
              We extend our heartfelt gratitude for your generous donation of ₹
              ******* to {receiptData?.ngo_name}. Your support contributes
              significantly to our ongoing efforts to make a positive impact on
              the lives of those in need.
            </div>
            <div className="fs-13  color-1B1C1D my-4 ">
              Donation Details:
              <div className="fs-13  color-1B1C1D mt-2">
                - Date of Donation: **********{' '}
              </div>
              <div className="fs-13  color-1B1C1D">
                - Amount: Rupees: ********
              </div>
            </div>
            <div className="fs-13  color-1B1C1D my-3 ">
              Please retain this receipt for your records.
              {receiptData?.ngo_name} is a registered charitable organization,
              and your donation is eligible for tax exemption under Section 80G
              of the Income Tax Act.
            </div>
            <div className="fs-13  color-1B1C1D my-3 ">
              For any queries or additional information, feel free to contact us
              at +91-{receiptData?.ngo_contact_number} or{' '}
              {receiptData?.ngo_email_id}.
            </div>
            <div className="fs-13  color-1B1C1D my-3 ">
              Thank you once again for your generosity and support.
            </div>
          </div>
        </div>

        <div className="row pl-2 pr-4 py-1 mt-2 mt-neg justify-content-between align-items-center">
          <div className="col-6">
            <div className="fs-13 color-1B1C1D my-2">Sincerely,</div>
            <div className="fs-13 color-1B1C1D my-2">
              {directorName
                ? directorName
                : receiptData?.board_member_designation}
            </div>
            <div className="fs-13 color-1B1C1D my-2">
              {receiptData?.designation
                ? receiptData?.designation
                : receiptData?.board_member_designation
                ? receiptData?.board_member_designation
                : 'Team'}{' '}
              - {receiptData?.ngo_name}
            </div>
          </div>

          <div className="col-6 text-right">
            <div className="float-right sign_stamp_position">
              <img
                src={directorStamp}
                alt="stamp"
                className="image_width_sign_stamp stamp_position"
              />
              <img
                src={directorSignature}
                alt="signature"
                className="image_width_sign_stamp sign_position"
              />
            </div>
          </div>
          {/* <div className="row text-right">
            <div className="col-6 text-right"></div>
            <div className="col-6 text-right">
              <div className="fs-13 color-1B1C1D mr-6rm">{directorName}</div>
              <div className="fs-13 color-1B1C1D float-right">
                {receiptData?.designation
                  ? receiptData?.designation
                  : 'Director'}{' '}
                - {receiptData?.ngo_name}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default ReceiptSample;
