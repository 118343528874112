import React, { useEffect, useState } from 'react';

const CauseAreaTooltip = ({ causeArea }) => {
  const [causeAreaString, setcauseAreaString] = useState('');
  useEffect(() => {
    let str = '';
    let str2 = '';
    function makeString() {
      for (let i = 0; i < causeArea.length; i++) {
        if (i < causeArea.length - 1) {
          if (str.length + causeArea[i].length + 1 < 35) {
            str += `${causeArea[i]}, `;
          } else {
            str2 += `${causeArea[i]}, `;
          }
        } else {
          if (str.length + causeArea[i].length + 1 < 35) {
            str += `${causeArea[i]}, `;
          } else {
            str2 += `${causeArea[i]} `;
          }
        }
      }
      //   console.log(str, 'str');
      //   console.log(str.slice(0, 50), 'str 50');
      setcauseAreaString(str2);
    }
    makeString();
  }, []);
  return <span style={{ marginRight: '5px' }}>{causeAreaString}</span>;
};

export default CauseAreaTooltip;
