import React, { useEffect, useState } from 'react';
import FileInput from './fileInput';

export default function FileInputMultiple(props) {
  const [filesArr, setFilesArr] = useState([]);

  const addFileInput = () => {
    if (filesArr.length < 3) {
      let tempFilesArr = [];
      filesArr.map(file => {
        tempFilesArr.push(file);
      });
      let fileObject = {
        label: `${props.label.replace('s', ' ')} ${tempFilesArr.length + 1}`,
        accept: props.accept ? props.accept : 'image/*,.pdf,.doc,.docx,.zip',
        inputtype: 'file',
        fileSizeLimit: props.fileSizeLimit ? props.fileSizeLimit : 25,
        required: props.required ? props.required : ''
      };
      tempFilesArr.push(fileObject);
      setFilesArr(tempFilesArr);
    }
  };

  const checkAndSetFilesArray = () => {
    if (props.value && props.value.length > 0) {
      let fileUrlArr = [];
      props.value.map((fileUrl, index) => {
        let fileObject = {
          label: `${props.label.replace(/s$/, ' ')} ${index + 1}`,
          accept: props.accept ? props.accept : 'image/*,.pdf,.doc,.docx,.zip',
          inputtype: 'file',
          fileSizeLimit: props.fileSizeLimit ? props.fileSizeLimit : 25,
          savedValue: fileUrl
        };
        fileUrlArr.push(fileObject);
      });
      setFilesArr(fileUrlArr);
    } else {
      addFileInput();
    }
  };

  useEffect(() => {
    checkAndSetFilesArray();
  }, []);

  useEffect(() => {}, [filesArr]);

  return (
    <>
      <div className="d-flex align-items-center flex-wrap">
        {filesArr.map((fileInput, index) => {
          return (
            <div
              className={`w-50 ${index % 2 === 0 ? 'pr-4' : 'pl-4'} mb-4`}
              key={`${props.id}_${index + 1}`}>
              <FileInput
                {...fileInput}
                value={fileInput.savedValue}
                id={`${props.id}_${index + 1}`}
                name={`${props.id}_${index + 1}`}
                disabled={props.disabled}
                refId={props.refId}
                previewDoc={props.previewDoc}
                fieldUpdated={props.fieldUpdated}
                stepIndex={props.stepIndex}
              />
            </div>
          );
        })}
        {!props.value && filesArr.length < 3 && (
          <div className="w-50 pl-0 pr-4 mb-4">
            <button
              type="button"
              className="form-control col-md-3 btn btn-outline-primary save-button btn-sm"
              disabled={props.disabled}
              onClick={() => addFileInput()}>
              Add more
            </button>
          </div>
        )}
      </div>
    </>
  );
}
