import { Action } from '../constants';
const initialState = {
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    //NGO - Project Finances CREATE screen
    case Action.FINANCE_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.FINANCE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        financeCreateModifySuccessResponse: action.payload
      };
    case Action.FINANCE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        financeCreateModifyErrorResponse: action.payload
      };

    case Action.FINANCE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.FINANCE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        financeDetailsSuccessResponse: action.payload
      };
    case Action.FINANCE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        financeDetailsErrorResponse: action.payload
      };

    //NGO - Project Activities CREATE screen
    case Action.ACTIVITIES_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.ACTIVITIES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        activitiesCreateModifySuccessResponse: action.payload
      };
    case Action.ACTIVITIES_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        activitiesCreateModifyErrorResponse: action.payload
      };

    case Action.ACTIVITIES_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.ACTIVITIES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        activitiesDetailsSuccessResponse: action.payload
      };
    case Action.ACTIVITIES_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        activitiesDetailsErrorResponse: action.payload
      };

    //NGO - Project Indicators CREATE screen
    case Action.INDICATORS_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.INDICATORS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        indicatorsCreateModifySuccessResponse: action.payload
      };
    case Action.INDICATORS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        indicatorsCreateModifyErrorResponse: action.payload
      };

    case Action.INDICATORS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.INDICATORS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        indicatorsDetailsSuccessResponse: action.payload
      };

    case Action.INDICATORS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        indicatorsDetailsErrorResponse: action.payload
      };

    //NGO - Project Beneficiaries screen
    case Action.BENEFICIARIES_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.BENEFICIARIES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        beneficiariesCreateModifySuccessResponse: action.payload
      };
    case Action.BENEFICIARIES_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        beneficiariesCreateModifyErrorResponse: action.payload
      };

    case Action.BENEFICIARIES_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.BENEFICIARIES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        beneficiariesDetailsSuccessResponse: action.payload
      };

    case Action.BENEFICIARIES_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        beneficiariesDetailsErrorResponse: action.payload
      };
    case Action.BENEFICIARIES_DYNAMIC_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.BENEFICIARIES_DYNAMIC_SUCCESS:
      return {
        ...state,
        loading: false,
        beneficiariesDynamicSuccessResponse: action.payload
      };

    case Action.BENEFICIARIES_DYNAMIC_FAILURE:
      return {
        ...state,
        loading: false,
        beneficiariesDynamicErrorResponse: action.payload
      };
    case Action.REMOVE_BENEFICIARIES_DYNAMIC_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.REMOVE_BENEFICIARIES_DYNAMIC_SUCCESS:
      return {
        ...state,
        loading: false,
        removeBeneficiarySuccessResponse: action.payload
      };
    case Action.REMOVE_BENEFICIARIES_DYNAMIC_FAILURE:
      return {
        ...state,
        loading: false,
        removeBeneficiaryErrorResponse: action.payload
      };
    case Action.SELECT_BENEFICIARIES_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.SELECT_BENEFICIARIES_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        removeBeneficiarySuccessResponse: action.payload
      };
    case Action.SELECT_BENEFICIARIES_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        removeBeneficiaryErrorResponse: action.payload
      };

    default:
      return state;
  }
};
