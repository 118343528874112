import { combineReducers } from 'redux';
import loginReducer from './login.reducer';
import commonReducer from './common.reducer';
import registerReducer from './register.reducer';
import campaignReducer from './campaign.reducer';
import mneReducer from './mne.reducer';
import corporate_listReducer from './corporate_list.reducer';
import ngoReducer from './ngo.reducer';
import legalStructureDropdownReducer from './legalStructureDropdown.reducer';
import accountTypeDropDownReducer from './accountTypeDropDown.reducer';
import numberOfEmployees from './numberOfEmployees.reducer';
import guideStarCertificationLevel from './guideStarCertificationLevel.reducer';
import transactionReducer from './transaction.reducer';
import donationReducer from './donation.reducer';
import missionReducer from './mission.reducer';
import roleManagementReducer from './roleManagement.reducer';
import sidebarStatus from './sidebarMenu_Reducer';
import currencyReducer from './currency.reducer';

const rootReducer = combineReducers({
  login: loginReducer,
  common: commonReducer,
  register: registerReducer,
  campaign: campaignReducer,
  mne: mneReducer,
  sidebarStatus: sidebarStatus,
  corporate_list: corporate_listReducer,
  transaction_list: transactionReducer,
  donation_list: donationReducer,
  ngo: ngoReducer,
  legalStructureDropdown: legalStructureDropdownReducer,
  accountTypeDropDown: accountTypeDropDownReducer,
  numberOfEmployees: numberOfEmployees,
  guideStarCertificationLevel: guideStarCertificationLevel,
  missionData: missionReducer,
  roleManagement: roleManagementReducer,
  currencyData: currencyReducer
});

export default rootReducer;
