import React, { useState, useCallback, useEffect } from 'react';
import SingleInput from '../components/singleOtpInput';

export function OtpScreen(props) {
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,
    onChangeOTP,
    clearInput,
    ...rest
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState('');
  // Clear input boxes when clearInput prop changes
  useEffect(() => {
    if (clearInput) {
      setOTPValues('');
      setActiveInput(0);
    }
  }, [clearInput, length]);

  const handleOtpChange = useCallback(
    otp => {
      const otpValue = otp.join('');
      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );
  const getRightValue = useCallback(
    str => {
      let changedValue = str.replace(/[^0-9]/g, '');
      if (!isNumberInput) {
        return changedValue;
      }
      return changedValue;
    },
    [isNumberInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    str => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    inputIndex => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    index => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    e => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      if (val.length > 1) {
        // Handle cases where multiple characters are pasted
        const otpArray = val.split('');
        let updatedOTPValues = [...otpValues];
        let nextFocusIndex = activeInput;
        for (let i = 0; i < otpArray.length; i++) {
          const otpChar = otpArray[i];
          if (/^[0-9]+$/.test(otpChar)) {
            updatedOTPValues[activeInput + i] = otpChar;
            nextFocusIndex = activeInput + i;
          }
        }
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      } else {
        changeCodeAtFocus(val);
        focusNextInput();
      }
    },
    [
      activeInput,
      changeCodeAtFocus,
      focusNextInput,
      getRightValue,
      length,
      otpValues
    ]
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    e => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case ' ': {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    e => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        handleOtpChange(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );

  const inputArray = Array.from({ length });
  return (
    <div {...rest}>
      {inputArray.map((_, index) => (
        <SingleInput
          key={`SingleInput-${index}`}
          focus={activeInput === index}
          autoFocus={autoFocus}
          value={otpValues && otpValues[index]}
          onFocus={handleOnFocus(index)}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onBlur={onBlur}
          onPaste={handleOnPaste}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

const OTPInput = OtpScreen;
export default OTPInput;
