import React, { useState } from 'react';
import editIcon from '../../../assets/icons/edit-blue.svg';
import UploadService from '../../../services/upload.service';
import toast from 'react-hot-toast';
import ReceiptSample from './receiptSample';
import Modal from '../../newDesign/Modals/receiptModal';
var uploadedUrl = '';
function UploadReceiptDocuments(props) {
  const [showModal, setShowModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [directorSignature, setDirectorSignature] = useState(
    props.uploadedReceiptData?.signature || ''
  );
  const [directorStamp, setDirectorStamp] = useState(
    props.uploadedReceiptData?.stamp || ''
  );
  const [directorName, setDirectorName] = useState(
    props.uploadedReceiptData?.name ||
      props.uploadedReceiptData?.board_member_name ||
      ''
  );
  const [editName, setEditName] = useState(
    props.uploadedReceiptData?.name === '' ? false : true
  );
  const [editSignature, setEditSignature] = useState(
    props.uploadedReceiptData?.signature === '' ? false : true
  );
  const [editStamp, setEditStamp] = useState(
    props.uploadedReceiptData?.stamp === '' ? false : true
  );
  const [loadingSignature, setLoadingSignature] = useState(false);
  const [loadingStamp, setLoadingStamp] = useState(false);
  const [initialEditValue, setInitialEditValue] = useState(false);

  const uploadFile = async (e, name) => {
    e.preventDefault();
    conditionsForLoader(name, true);
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('mimeType', e.target.files[0].type);
    formData.append('docType', 'campaign_image');
    formData.append('moduleName', 'ngo');
    formData.append('refId', props.ngoId);

    UploadService.upload(formData, props.ngoId, 'doc', '')
      .then(res => {
        uploadedUrl = res.data.fileUrl;
        editUpload(name);
        conditionsForLoader(name, false);
      })
      .catch(err => {
        toast.error(
          'Uh-Oh! Looks like we are having server issues., please contact our support!'
        );
        setLoadingSignature(false);
        setLoadingStamp(false);
      });
  };
  const uploadStamp = e => {
    let stampUrl = uploadFile(e, 'stamp');
    setDirectorStamp(stampUrl);
  };
  const uploadSignature = e => {
    let signatureUrl = uploadFile(e, 'signature');
    setDirectorSignature(signatureUrl);
  };
  const conditionsForLoader = (name, value) => {
    if (name === 'stamp') {
      setLoadingStamp(value);
    }
    if (name === 'signature') {
      setLoadingSignature(value);
    }
  };
  const nameOfDirector = e => {
    const textRegex = /^[a-zA-Z\s]*$/;

    if (textRegex.test(e.target.value) || e.target.value === '') {
      setDirectorName(e.target.value);
    }
  };

  const editUpload = name => {
    setDisableBtn(false);
    setInitialEditValue(true);
    if (name === 'name') {
      setEditName(!editName);
    }
    if (name === 'signature') {
      setEditSignature(!editSignature);
      setDirectorSignature(uploadedUrl);
    }
    if (name === 'stamp') {
      setEditStamp(!editStamp);
      setDirectorStamp(uploadedUrl);
    }
  };

  const getUploadedSignature = e => {
    return (
      <>
        {editSignature && (
          <>
            <span
              className="text-007bff mt-2 cursor-pointer ml-4 fs-14"
              onClick={() => editUpload('signature')}>
              x
            </span>
            <span className="fs-14 ml-2 text-007bff">{'signature.png'}</span>
          </>
        )}
      </>
    );
  };
  const getUploadedStamp = e => {
    return (
      <>
        {editStamp && (
          <>
            <span
              className="text-007bff cursor-pointer ml-4 fa-14 mt-2"
              onClick={() => editUpload('stamp')}>
              x
            </span>
            <span className="fs-14 ml-2 text-007bff">{'stamp.png'}</span>
          </>
        )}
      </>
    );
  };
  const postUploadedData = () => {
    let data = {
      id: props.uploadedReceiptData?.id || 0,
      name: directorName || '',
      ngo_id: props.ngoId || '',
      designation: 'Director',
      stamp: directorStamp,
      signature: directorSignature
    };
    setDisableBtn(true);
    props
      .postDonationReceiptUploadedData(data)
      .then(res => {
        if (res.code === '200') {
          setDirectorSignature(res.result?.signature);
          setDirectorStamp(res.result?.stamp);
          setDirectorName(res.result?.name);
          setEditName(true);
          toast.success('Data added successfully!');
        }
      })
      .catch(error => {
        setDisableBtn(false);
        if (error)
          toast.error('Error while resolving. Please try again later.');
      });
  };
  const getSpinner = loader => {
    if (loader) {
      return (
        <div
          className="spinner-border spinner-border-lg mx-3 text-primary"
          role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    }
  };

  const handleShowModal = value => {
    setShowModal(value);
  };
  const handleResetData = () => {
    setDisableBtn(true);
    setInitialEditValue(
      props.uploadedReceiptData?.signature === '' ? false : true
    );
    setEditStamp(props.uploadedReceiptData?.stamp === '' ? false : true);
    setEditName(props.uploadedReceiptData?.name === '' ? false : true);
    setEditSignature(true);
    setDirectorName(
      directorName ||
        props.uploadedReceiptData?.name ||
        props.uploadedReceiptData?.board_member_name ||
        ''
    );
    setDirectorSignature(
      directorSignature || props.uploadedReceiptData?.signature
    );
    setDirectorStamp(directorSignature || props.uploadedReceiptData?.stamp);
  };

  return (
    <>
      <div className="bg-white no-print">
        <div className="row">
          <div className="col-3 my-4">
            <div className="border-right">
              <div className="fs-14 text-1C1C1C my-1">
                Director Name <span className=" fs-12 color-FF2300">*</span>
              </div>
              <div className="text-7B8980 fs-12 my-1">
                To be added on footer of Donation Receipt
              </div>
              <div className="form-group w-50 d-flex my-2">
                <input
                  type="text"
                  className="form-control"
                  id="director-name"
                  pattern="[a-zA-Z\s]*"
                  aria-describedby="directorName"
                  onChange={e => {
                    nameOfDirector(e);
                  }}
                  value={directorName}
                  disabled={editName}
                />
                <img
                  src={editIcon}
                  className="row-icons cursor-pointer mx-4 mt-3"
                  onClick={() => editUpload('name')}
                  alt="Funding"
                />
              </div>
            </div>
          </div>

          <div className="col-3 my-4">
            <div className="border-right">
              <div className="fs-14 text-1C1C1C my-1">
                Director Signature{' '}
                <span className=" fs-12 color-FF2300">*</span>
              </div>
              <div className="text-7B8980 fs-12 my-1">
                Upload PNG file of signature
              </div>
              <button
                type="button"
                className="btn btn-outline-primary ht-36  my-2 "
                disabled={editSignature}>
                Upload
                <input
                  type="file"
                  accept="image/png"
                  id="upload_excel_sheet"
                  className="invisible-input"
                  onChange={e => uploadSignature(e)}
                  disabled={editSignature}
                />
              </button>
              {getUploadedSignature()}
              {getSpinner(loadingSignature)}
            </div>
          </div>

          <div className="col-6 my-4">
            <div>
              <button
                type="button"
                className="btn btn-primary ht-36 w-15 text-white my-2 float-right mx-2 mt-2rm cursor-pointer"
                onClick={() => postUploadedData()}
                disabled={
                  !initialEditValue ||
                  directorName === '' ||
                  !editSignature ||
                  !editStamp ||
                  disableBtn
                }>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary text-white my-2 w-20 ht-36 float-right mt-2rm  mx-4 cursor-pointer"
                disabled={directorName === '' || !editSignature || !editStamp}
                onClick={() => handleShowModal(true)}>
                Preview Receipt
              </button>

              <button
                type="link"
                className="btn text-007bff fs-14 cursor-pointer my-2 float-right mx-2 mt-2rm "
                onClick={() => handleResetData()}>
                reset
              </button>

              <div className="fs-14 text-1C1C1C my-1">
                Upload Stamp <span className=" fs-12 color-FF2300">*</span>
              </div>
              <div className="text-7B8980 fs-12 my-1">
                Upload PNG file of NGO Stamp
              </div>

              <button
                type="button"
                className="btn btn-outline-primary ht-36  my-2 "
                disabled={editStamp}>
                Upload
                <input
                  type="file"
                  accept="image/png"
                  id="upload_excel_sheet"
                  className="invisible-input"
                  onChange={e => uploadStamp(e)}
                  disabled={editStamp}
                />
              </button>
              {getUploadedStamp()}
              {getSpinner(loadingStamp)}
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          show={showModal}
          title={'Preview: Receipt of Donation'}
          handleCloseModal={handleShowModal}
          setShowModal={setShowModal}
          sizeClass={'modal-xl'}>
          <div className="m-3">
            <ReceiptSample
              receiptData={props.uploadedReceiptData}
              directorName={directorName}
              directorSignature={directorSignature}
              directorStamp={directorStamp}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
export default UploadReceiptDocuments;
