import React from 'react';
export default function CheckboxComponent(props) {
  let {
    uniqueIdentifierForInput,
    label,
    value,
    checked,
    // required,
    disabled,
    masterfield,
    visibilityFunc
  } = props;
  const inputProps = {
    value: value,
    name: uniqueIdentifierForInput
  };
  if (checked) {
    inputProps.defaultChecked = true;
  }
  if (disabled) {
    inputProps.disabled = true;
  }
  const handleChange = e => {
    if (masterfield) {
      visibilityFunc(masterfield, e.target.value);
    }
    if (props.fieldUpdated && props.stepIndex >= 0)
      props.fieldUpdated(props.stepIndex);
  };
  return (
    <label className="mx-2">
      {label}
      <input
        type="checkbox"
        name="check"
        className="tnc-checkbox mr-1 float-left"
        {...inputProps}
        // required={props.required}
        onChange={e => {
          handleChange(e);
        }}
      />
      {/* <span className="checkmark custom-radio-checkmark"></span> */}
      {/* <span className='tnc-checkbox'></span> */}
    </label>
  );
}
