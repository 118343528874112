import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  getCampaign,
  createOrModifyIndicators,
  getGoalsIndicators,
  getFrequencyListMasterData,
  getUomListMasterData
} from '../../actions';
import { InputSelect, InputText } from '../FormElements';
import PopupModal from './popupModals';
import { convertInCommaFormat } from '../../helpers/commaSeparatedInput';
import AuthHideShow from '../authHideShow';
import Modal from '../newDesign/Modals/Modal';
var removeUnsavedData = false;
var adminName = window.location.href.split('#')[1];
class ProjectIndicators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: this.props.id,
      campaignName: '',
      noOfPhases: 1,
      indicatorsArr: [],
      saved: false,
      errorExists: false,
      deletedIndicatorArr: [],
      frequencyList: [],
      uomList: [],
      indicatorsDataArrCount: 0,
      enableSubmitCampaignBtn: false,
      showCancelPopup: false,
      showSubmitPopupFilledWithProposal: false,
      showSubmitPopupUnFilledWithProposal: false,
      submitDisable: false,
      showReSubmitProposal: false,
      originalArr: [],
      deleteRowOrPhase: false,
      clearPrimaryIndicatorValue: false
    };
  }

  componentDidMount() {
    this.setState({ indicatorsArr: [] });
    this.getFrequencyListMasterData();
    this.getUomListMasterData();
    this.props.getGoalsIndicators(this.props.id);
    this.addIndicator();
  }
  getFrequencyListMasterData() {
    this.props
      .getFrequencyListMasterData()
      .then(res => {
        this.setState({ frequencyList: res });
      })
      .catch(async err => {});
  }
  getUomListMasterData() {
    this.props
      .getUomListMasterData()
      .then(res => {
        this.setState({ uomList: res });
      })
      .catch(async err => {});
  }

  handleCancelPopup = value => {
    let flag = this.checkForModifications();
    if (!flag) {
      toast('No changes detected to cancel');
    } else {
      this.setState({
        showCancelPopup: value
      });
    }
    removeUnsavedData = false;
  };

  checkForModifications() {
    let tempIndicatorsArr = this.state.indicatorsArr.slice();
    let flag = false;
    let filledRowExist = false;
    this.state.indicatorsArr.map((activity, index) => {
      for (const key in activity) {
        if (tempIndicatorsArr[index][key]?.id !== null) {
          filledRowExist = true;
        }
        if (
          tempIndicatorsArr[index][key]?.id === null &&
          tempIndicatorsArr[index][key]?.value !== '' &&
          tempIndicatorsArr[index][key]?.value !== 0 &&
          tempIndicatorsArr[index][key]?.value !== null
        ) {
          flag = true;
        }
      }
    });
    if (filledRowExist && flag) {
      return true;
    } else if (filledRowExist && flag) {
      return false;
    } else if (filledRowExist === false && flag === true) {
      return true;
    } else if (filledRowExist === false && flag === true) {
      return false;
    }
  }

  removeUnsavedData = () => {
    this.props.getGoalsIndicators(this.props.id);
    this.setState({
      showCancelPopup: false
    });
    removeUnsavedData = true;
    this.componentDidMount();
  };
  showNext = (tab, campaignId) => {
    if (tab === 'ProposedBudget') {
      window.location.href =
        '/ngo/projectProposal/detailsOverview/' + campaignId;
    } else if (tab === 'ActivitiesPlan') {
      window.location.href =
        '/ngo/projectProposal/activitiesPlan/' + campaignId;
    } else if (tab === 'ProjectIndicators') {
      window.location.href =
        '/ngo/projectProposal/impactIndicators/' + campaignId;
    }
  };
  campaignSubmissionConfirmation = () => {
    if (
      this.props.proposalStatus.is_project_activities === 'True' &&
      this.props.proposalStatus.is_project_finances === 'True' &&
      this.props.proposalStatus.is_project_indicators === 'True'
    ) {
      this.setState({
        showSubmitPopupFilledWithProposal: true,
        showSubmitPopupUnFilledWithProposal: false
      });
    } else {
      this.setState({
        showSubmitPopupFilledWithProposal: false,
        showSubmitPopupUnFilledWithProposal: true
      });
    }
  };
  closePopupCampaignSubmission = type => {
    if (type === 'filled') {
      this.setState({
        showSubmitPopupFilledWithProposal: false
      });
    } else {
      this.setState({
        showSubmitPopupUnFilledWithProposal: false
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (
      nextprops.editSuccessResponse &&
      nextprops.editSuccessResponse.code === 200
    ) {
      let indicatorsArray = this.state.indicatorsArr.slice();
      this.setState({
        campaignName: nextprops.editSuccessResponse.data.title,
        indicatorsArr: indicatorsArray
      });
    }
    if (
      nextprops.indicatorsDetailsSuccessResponse &&
      nextprops.indicatorsDetailsSuccessResponse?.length > 0 &&
      parseInt(window.location.pathname.split('/')[4]) ===
        nextprops.indicatorsDetailsSuccessResponse[0]?.campaign_id
    ) {
      const indicatorsDataArr = nextprops.indicatorsDetailsSuccessResponse;
      if (indicatorsDataArr?.length > 0) {
        this.setState({ indicatorsDataArrCount: indicatorsDataArr?.length });
      }
      let indicatorsArr = [];
      indicatorsDataArr.map((indicator, index) => {
        const obj = {
          indicator_description: this.createFieldObject(
            indicator.id,
            indicator.indicator_description
          ),
          uom_id: this.createFieldObject(
            indicator.id,
            index === 0
              ? indicator.indicator_description === ''
                ? ''
                : indicator.uom_id
              : indicator.uom_id
          ),
          frequency_id: this.createFieldObject(
            indicator.id,
            index === 0
              ? indicator.indicator_description === ''
                ? ''
                : indicator.frequency_id
              : indicator.frequency_id
          ),
          baseline_value: this.createFieldObject(
            indicator.id,
            indicator.baseline_value
          ),
          target_value: this.createFieldObject(
            indicator.id,
            indicator.target_value
          )
        };
        indicatorsArr.push(obj);
        return false;
      });
      if (!this.state.errorExists) {
        this.setState({
          indicatorsArr: indicatorsArr,
          originalArr: indicatorsDataArr
        });
      }
    } else {
      if (this.state.indicatorsArr.length) {
        if (!this.state.errorExists) {
          const mainTy = typeof this.state.indicatorsArr[0]?.id;
          if (mainTy === 'string' || mainTy === 'number') {
            this.setState({ indicatorsArr: [] });
          } else if (mainTy === 'object') {
            if (this.state.indicatorsArr[0]?.id.id) {
              this.setState({ indicatorsArr: [] });
            }
          }
        }
      } else {
        this.addIndicator();
      }
    }
  }
  handleSaveFunctionCall() {
    this.setState({ showReSubmitProposal: false });
    const isSaveDone = this.saveIndicators();
    if (isSaveDone) {
      this.setState({ enableSubmitCampaignBtn: true });
      this.props.showNext('ProjectIndicators');
      this.props.getCampaignProposalStatus(this.state.campaignId);
    }
  }

  createFieldObject(id, value) {
    return {
      id: id,
      value: value !== undefined ? value : '',
      error: ''
    };
  }
  addIndicator() {
    let indicatorsArray = this.state.indicatorsArr.slice();
    // const description = indicatorsArr.length === 0 ? null : null;
    let count = 1;
    if (removeUnsavedData) {
      removeUnsavedData = false;
      indicatorsArray = [];
      count = 1;
    }
    for (let i = 0; i < count; i++) {
      const obj = {
        indicator_description: this.createFieldObject(null),
        uom_id: this.createFieldObject(null),
        frequency_id: this.createFieldObject(null),
        baseline_value: this.createFieldObject(null),
        target_value: this.createFieldObject(null)
      };
      indicatorsArray.push(obj);
    }
    this.setState({ indicatorsArr: indicatorsArray });
  }

  deleteIndicator(index) {
    let indicatorsArr = this.state.indicatorsArr.slice();
    let deletedIndArr = this.state.deletedIndicatorArr.slice();
    const delObj = indicatorsArr[index];
    if (delObj.indicator_description.id) {
      deletedIndArr.push(delObj);
    }
    indicatorsArr.splice(index, 1);
    this.setState({
      indicatorsArr: indicatorsArr,
      deletedIndicatorArr: deletedIndArr,
      deleteRowOrPhase: true
    });
  }
  clearPrimaryIndicator(index) {
    let indicatorsArr = this.state.indicatorsArr.slice();
    var savedEntry = this.state.indicatorsArr[index];
    if (savedEntry?.indicator_description.value !== '') {
      if (savedEntry?.indicator_description.id) {
        for (const key in savedEntry) {
          if (key === 'uom_id' || key === 'frequency_id') {
            indicatorsArr[index][key].value = 0;
          } else {
            indicatorsArr[index][key].value = '';
          }
        }

        this.setState({
          indicatorsArr: indicatorsArr,
          clearPrimaryIndicatorValue: true
        });
      }
    }
  }
  eventToValue(e, trimResults, toNumber) {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      if (value !== 'true' || value !== 'false') {
        value = Number(value);
      }
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  }
  Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    }
  };
  checkForPattern(type, value) {
    if (type === 'number') {
      let pattern = /^[0-9]+$/;
      if (!pattern.test(value)) return 'Must contain only numbers';
    } else if (type === 'aplhabets') {
      let pattern = /^[A-Za-z\s]+$/;
      if (!pattern.test(value)) return 'Must contain only characters';
    } else if (type === 'aplhaNumeric') {
      let pattern = /^[a-zA-Z0-9]+$/;
      if (!pattern.test(value)) return 'Must be alphanumeric';
    }
    return false;
  }
  validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';
    let patternMsg = null;
    let valueInt = null;

    switch (name) {
      case 'indicator_description':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter the indicator description';
        break;
      case 'uom_id':
        isFieldValid = this.Validations.required(value);
        if (!isFieldValid || value === 0) {
          msg =
            isFieldValid && value !== 'Please select'
              ? ''
              : 'Please select a unit of measurement';
        }
        break;
      case 'frequency_id':
        isFieldValid = this.Validations.required(value);
        msg =
          isFieldValid && value !== 'Please select'
            ? ''
            : 'Please select a frequency value';
        break;
      case 'baseline_value':
        valueInt = parseInt(value.toString().replace(/,/g, '')) || 0;
        isFieldValid = this.Validations.required(valueInt);
        patternMsg = this.checkForPattern('number', valueInt);

        // if (!isFieldValid || parseInt(value) === 0) {
        if (parseInt(valueInt) < 0 || isNaN(valueInt)) {
          msg = 'Please enter baseline value';
        } else if (patternMsg) {
          msg = patternMsg;
        }
        break;
      case 'target_value':
        valueInt = parseInt(value.toString().replace(/,/g, '')) || 0;
        isFieldValid = this.Validations.required(valueInt);
        patternMsg = this.checkForPattern('number', valueInt);
        // if (!isFieldValid || parseInt(value) === 0) {
        if (parseInt(valueInt) < 0 || isNaN(valueInt)) {
          msg = 'Please enter target value';
        } else if (patternMsg) {
          msg = patternMsg;
        }
        break;
      default:
    }
    return { isFieldValid, msg };
  };
  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  setFormErrorsAndValue = (event, type, index) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;
    if (event.target.maxLength !== undefined) {
      if (value?.length > event.target.maxLength) {
        toast.error('Max length should be ' + event.target.maxLength);
      }
      value = value.slice(0, event.target.maxLength);
    }
    if (!type) {
      value = this.eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = this.restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    const errorResult = this.validate(id, value);
    let indicatorsArr = this.state.indicatorsArr.slice();
    indicatorsArr[index][id].value = value;
    indicatorsArr[index][id].error = errorResult.msg;
    this.setState({ indicatorsArr: indicatorsArr });
  };
  onHandleChangeOrBlur = (event, type, index) => {
    this.setState({ submitDisable: true });
    this.setFormErrorsAndValue(event, type, index);
  };

  checkErrorMessage() {
    let errorExists = false;
    let tempIndicatorsArr = this.state.indicatorsArr.slice();
    this.state.indicatorsArr.map((indicator, index) => {
      if (indicator.indicator_description.value !== '') {
        for (const key in indicator) {
          const errorResult = this.validate(key, indicator[key].value);
          if (errorResult.msg !== '') {
            tempIndicatorsArr[index][key].error = errorResult.msg;
            if (!errorExists) errorExists = true;
          }
        }
      }
      if (
        index === 0 &&
        this.state.indicatorsArr?.length > 1 &&
        (indicator.indicator_description.value === '' ||
          indicator.uom_id.value === '' ||
          indicator.frequency_id.value === '') &&
        ((indicator.baseline_value.value !== '' &&
          indicator.baseline_value.value !== 0) ||
          (indicator.target_value.value !== '' &&
            indicator.target_value.value !== 0)) &&
        this.state.clearPrimaryIndicatorValue === false
      ) {
        for (const key in indicator) {
          const errorResult = this.validate(key, indicator[key].value);
          if (errorResult.msg !== '') {
            tempIndicatorsArr[index][key].error = errorResult.msg;
            if (!errorExists) errorExists = true;
          }
        }
      } else if (
        index === 0 &&
        this.state.indicatorsArr?.length === 1 &&
        this.state.deletedIndicatorArr?.length === 0 &&
        (indicator.baseline_value.value !== '' ||
          indicator.target_value.value !== '')
      ) {
        for (const key in indicator) {
          const errorResult = this.validate(key, indicator[key].value);
          if (errorResult.msg !== '') {
            tempIndicatorsArr[index][key].error = errorResult.msg;
            if (!errorExists) errorExists = true;
          }
        }
      }

      return false;
    });
    if (errorExists) this.setState({ indicatorsArr: tempIndicatorsArr });
    return errorExists;
  }

  ignoreErrorMsgs() {
    let tempIndicatorsArr = this.state.indicatorsArr.slice();
    var indicatorArrTemp = [];
    let flag = 0;
    var saveIndexArr = [];
    var errors = false;
    var uniqueArray = [];

    this.state.indicatorsArr.map((indicator, index) => {
      for (const key in indicator) {
        if (
          tempIndicatorsArr[index][key].id === null &&
          tempIndicatorsArr[index][key].value !== '' &&
          tempIndicatorsArr[index][key].value !== 0 &&
          tempIndicatorsArr[index][key].value !== null
        ) {
          flag = 1; //new row added and modified
          // saveIndexArr.push(index);
        } else if (tempIndicatorsArr[index][key].id !== null) {
          flag = 2; // alreay saved rows present
          saveIndexArr.push(index);
        }
      }
    });

    //all empty rows
    if (flag === 0) {
      this.state.indicatorsArr.map((indicator, index) => {
        for (const key in indicator) {
          if (
            tempIndicatorsArr[index][key].id === null &&
            (tempIndicatorsArr[index][key].value === '' ||
              tempIndicatorsArr[index][key].value === 0 ||
              tempIndicatorsArr[index][key].value === null)
          ) {
            tempIndicatorsArr[index][key].error = '';
          }
        }
      });
      return false;
    }

    if (flag === 1) {
      this.state.indicatorsArr.map((indicator, index) => {
        for (const key in indicator) {
          if (
            tempIndicatorsArr[index][key].id === null &&
            (tempIndicatorsArr[index][key].value === '' ||
              tempIndicatorsArr[index][key].value === 0 ||
              tempIndicatorsArr[index][key].value === null)
          ) {
            const errorResult = this.validate(key, indicator[key].value);
            if (errorResult.msg !== '') {
              errors = true;
              tempIndicatorsArr[index][key].error = errorResult.msg;
            }
          } else {
            saveIndexArr.push(index);
          }
        }
      });
      this.setState({ indicatorsArr: tempIndicatorsArr });
      if (errors === false) {
        setTimeout(() => {
          this.saveIndicators();
        }, 1000);
        return false;
      } else {
        uniqueArray = [...new Set(saveIndexArr)];
        for (let i = 0; i <= uniqueArray?.length; i++) {
          if (uniqueArray.includes(i)) {
            indicatorArrTemp.push(tempIndicatorsArr[i]);
          }
        }
        this.setState({ indicatorsArr: indicatorArrTemp });
        if (indicatorArrTemp?.length === 1) {
          setTimeout(() => {
            this.saveIndicators();
          }, 1000);
          return false;
        } else {
          return true;
        }
      }
    }

    if (flag === 2) {
      uniqueArray = [...new Set(saveIndexArr)];
      for (let i = 0; i <= uniqueArray?.length; i++) {
        if (uniqueArray.includes(i)) {
          indicatorArrTemp.push(tempIndicatorsArr[i]);
        }
      }

      this.setState({ indicatorsArr: indicatorArrTemp });

      if (indicatorArrTemp?.length > 0) {
        setTimeout(() => {
          this.saveIndicators();
        }, 1000);
      }
      return false;
    }
  }
  checkForResubmitModifications(pIndex) {
    let tempIndicatorsArr = this.state.indicatorsArr.slice();
    let flag = false;
    let filledRowExist = false;
    if (this.state.deleteRowOrPhase) {
      flag = true;
    }
    var errorExists = this.checkErrorMessage(pIndex);
    this.state.indicatorsArr.map((indicator, index) => {
      if (!errorExists) {
        for (const key in indicator) {
          if (tempIndicatorsArr[index][key]?.id !== null) {
            filledRowExist = true;
          }
          if (
            tempIndicatorsArr[index][key]?.id === null &&
            key !== 'milestone' &&
            tempIndicatorsArr[index][key]?.value !== '' &&
            tempIndicatorsArr[index][key]?.value !== 0 &&
            tempIndicatorsArr[index][key]?.value !== null
          ) {
            flag = true;
          } else if (this.state.originalArr[index] !== undefined) {
            if (
              tempIndicatorsArr[index][key]?.id !== null &&
              tempIndicatorsArr[index][key]?.value !==
                this.state.originalArr[index][key]
            ) {
              flag = true;
            }
          }
        }
      }
    });
    this.setState({ deleteRowOrPhase: false });

    if (filledRowExist && flag) {
      return true;
    } else if (filledRowExist && flag) {
      return false;
    } else if (filledRowExist === false && flag === true) {
      return true;
    } else if (filledRowExist === false && flag === true) {
      return false;
    }
  }

  closeProposalResubmission(e) {
    this.setState({
      showReSubmitProposal: false
    });
  }
  handleProposalResubmit() {
    let flag = this.checkForResubmitModifications();
    if (!flag) {
      toast('No changes detected');
    } else {
      this.setState({
        showReSubmitProposal: true,
        saveAndNext: false
      });
    }
  }

  handleNextFunctionCall() {
    this.props.showNext('ProjectIndicators');
    this.props.getCampaignProposalStatus(this.state.campaignId);
  }

  saveIndicators() {
    const errorExists = this.checkErrorMessage();
    this.setState({ errorExists: errorExists });
    if (errorExists) return false;
    let indicatorsArr = [];
    this.state.indicatorsArr.map((indicator, index) => {
      let e_bs_value = indicator?.baseline_value?.value
        ?.toString()
        ?.replace(/,/g, '');
      let e_tg_value = indicator?.target_value?.value
        ?.toString()
        ?.replace(/,/g, '');
      let _bs_value = parseInt(e_bs_value);
      let _tg_value = parseInt(e_tg_value);

      var object = {
        indicator_description: indicator.indicator_description.value,
        is_primary: index === 0 ? true : false,
        uom_id:
          index === 0
            ? indicator.indicator_description.value === ''
              ? 1
              : indicator.uom_id.value
            : indicator.uom_id.value,
        frequency_id:
          index === 0
            ? indicator.indicator_description.value === ''
              ? '1'
              : indicator.frequency_id.value
            : indicator.frequency_id.value,
        baseline_value: isNaN(parseInt(_bs_value)) ? 0 : parseInt(_bs_value),
        target_value: isNaN(parseInt(_tg_value)) ? 0 : parseInt(_tg_value),
        is_deleted:
          index === 0 &&
          this.state.indicatorsArr?.length === 1 &&
          this.state.clearPrimaryIndicatorValue
            ? true
            : false
      };

      if (indicator.indicator_description.id)
        object['indicator_id'] = indicator.indicator_description.id;
      indicatorsArr.push(object);
      return false;
    });

    if (this.state.deletedIndicatorArr.length > 0) {
      this.state.deletedIndicatorArr.map(indicator => {
        const object = {
          indicator_description: indicator.indicator_description.value,
          uom_id: indicator.uom_id.value,
          frequency_id: indicator.frequency_id.value,
          baseline_value: parseInt(indicator.baseline_value.value),
          target_value: parseInt(indicator.target_value.value),
          is_deleted: true,
          indicator_id: indicator.indicator_description.id
        };
        indicatorsArr.push(object);
        return false;
      });
    }
    if (indicatorsArr.length > 0) {
      const payloadObj = {
        campaign_id: this.props.id,
        project_indicators: indicatorsArr
      };
      let isError;
      this.props
        .createOrModifyIndicators(payloadObj)
        .then(response => {
          if (
            this.props.currentCampaignStatus === 'WAITING_FOR_FUNDING' &&
            adminName === undefined
          ) {
            toast.success('Indicators saved and submitted successfully!');
          } else {
            toast.success('Indicators saved successfully!');
          }

          this.setState({ submitDisable: false });
          this.setState({ enableSubmitCampaignBtn: true });
          this.props.showNext('ProjectIndicators');
          this.props.getCampaignProposalStatus(this.state.campaignId);
          this.props.getGoalsIndicators(this.props.id);
          isError = true;
          setTimeout(() => {
            if (
              this.state.indicatorsArr?.length === 1 &&
              this.state.clearPrimaryIndicatorValue
            ) {
              this.setState({ indicatorsArr: [] });
              this.addIndicator();
            }
            if (
              this.state.clearPrimaryIndicatorValue &&
              this.state.deletedIndicatorArr?.length ===
                this.state.indicatorsArr?.length - 1
            ) {
              this.setState({ indicatorsArr: [] });
              this.addIndicator();
              this.setState({ clearPrimaryIndicatorValue: false });
            }
            this.setState({ deletedIndicatorArr: [] });
            this.setState({ clearPrimaryIndicatorValue: false });
          }, 2000);
        })
        .catch(error => {
          toast.error('Uh-Oh! Looks like we are having server issues.');
          console.error(error);
          isError = false;
        });
      return isError;
    }

    return true;
  }
  render() {
    const unitOfMeasurement = this.state.uomList;
    const frequencyList = this.state.frequencyList;
    return (
      <>
        <PopupModal
          showCancelPopup={this.state.showCancelPopup}
          handleCancelPopup={this.handleCancelPopup}
          expenseDataArr={this.state.existingArr}
          phaseDetailsArr={this.state.phaseDetailsArr}
          removeUnsavedData={this.removeUnsavedData}></PopupModal>

        <PopupModal
          filledSubmitPopup={this.state.showSubmitPopupFilledWithProposal}
          unFilledSubmitPopup={this.state.showSubmitPopupUnFilledWithProposal}
          campaignSubmissionConfirmation={this.campaignSubmissionConfirmation}
          closePopupCampaignSubmission={this.closePopupCampaignSubmission}
          onSubmit={this.props.onSubmit}
          campaignName={this.props.campaignName}
          currentCampaignStatus={this.props.currentCampaignStatus}
        />

        {/* resubmit project proposal modal */}
        <Modal
          show={this.state.showReSubmitProposal}
          title={`Re-Submit new changes in Project Indicators?`}
          handleCloseModal={e => this.closeProposalResubmission(e)}
          sizeClass="modal-md">
          <p className="mt-2 mb-5 fs-16 font-weight-normal">
            Are you sure you want to re-submit your new changes in Project
            Indicators?
          </p>
          <div className="modal-footer " style={{ borderTop: 'none' }}>
            <button
              className="btn h-40 w-95 text-primary p-1 fs-14"
              onClick={e => this.closeProposalResubmission(e)}>
              Cancel
            </button>
            <button
              className="fs-14 btn btn-primary btn-lg fs-14 dm-bold ml-3"
              onClick={() => this.handleSaveFunctionCall()}>
              {this.props.currentCampaignStatus === 'WAITING_FOR_FUNDING' &&
              adminName === undefined
                ? 'Save & Submit'
                : 'Submit'}
            </button>
          </div>
        </Modal>
        <div className="row p-4 create-campaign">
          <div className={`row`}>
            <div className="col-12">
              <div className={`col-12 p-4 bg-white`}>
                <div className="row justify-content-between mb-4">
                  <p className="fs-16 ">Project Indicators Details</p>
                </div>
                <div className="row ">
                  <div className="col-4  p-3 bg-F8F8F8 ">
                    <span className="fs-12 font-weight-medium ">
                      INDICATOR DESCRIPTION
                    </span>
                  </div>
                  <div className="col-3  p-3 bg-F8F8F8 px-2">
                    <span className="fs-12 font-weight-medium">
                      UNIT OF MEASUREMENT
                    </span>
                  </div>
                  <div className="col-2  p-3 bg-F8F8F8 px-2">
                    <span className="fs-12 font-weight-medium">
                      REPORTING FREQUENCY
                    </span>
                  </div>
                  <div className="col-1  p-3 bg-F8F8F8  px-2">
                    <span className="fs-12 font-weight-medium">
                      BASELINE VALUE
                    </span>
                  </div>
                  <div className="col-1  p-3 bg-F8F8F8  pl-2 pr-0">
                    <span className="fs-12 font-weight-medium">
                      TARGET VALUE
                    </span>
                  </div>
                </div>
                {this.state.indicatorsArr.map((indicator, index) => {
                  return (
                    <>
                      <div
                        // className={`row ${index === 0 ? 'bg-87C7F0 pt-4' : 'mt-4 '
                        //   } table-rows-proposal`}
                        // style={{ marginTop: "-6px" }}
                        className={` ${
                          index === 0 ? 'bg-D4EAFF' : ''
                        } row table-rows-proposal d-flex`}>
                        <InputText
                          tab={` ${index === 0 ? 'projectIndicators' : ''} `}
                          required={true}
                          labelText=""
                          maxLength={500}
                          type={'text'}
                          PlaceHolder={` ${
                            index === 0
                              ? 'Beneficiaries Impacted'
                              : 'Maximum Length is 500 Characters'
                          }`}
                          id="indicator_description"
                          name="indicator_description"
                          fieldValue={indicator.indicator_description.value}
                          fieldError={indicator.indicator_description.error}
                          onHandleChange={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          onHandleBlur={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          wrapperClassName={'col-4 pr-2 mb-0'}
                          disableInput={this.state.saved}
                          className={` ${
                            index === 0 ? 'bg-D4EAFF' : ''
                          } activity_border-radius`}
                          permissions={this.props.permissions}
                        />
                        <InputSelect
                          required={true}
                          labelText=""
                          id="uom_id"
                          name="uom_id"
                          options={unitOfMeasurement}
                          fieldValue={indicator.uom_id.value}
                          fieldError={indicator.uom_id.error}
                          onHandleChange={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          onHandleBlur={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          parentElement={true}
                          dataType="num"
                          wrapperClassName={'col-3 pr-2 mb-0'}
                          disableInput={this.state.saved}
                          className={`${
                            index === 0 ? 'bg-D4EAFF' : ''
                          } activity_border-radius`}
                          permissions={this.props.permissions}
                        />
                        <InputSelect
                          required={true}
                          labelText=""
                          id="frequency_id"
                          name="frequency_id"
                          options={frequencyList}
                          fieldValue={indicator.frequency_id.value}
                          fieldError={indicator.frequency_id.error}
                          onHandleChange={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          onHandleBlur={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          parentElement={true}
                          wrapperClassName={'col-2 pr-2 mb-0'}
                          disableInput={this.state.saved}
                          className={` ${
                            index === 0 ? 'bg-D4EAFF' : ''
                          } activity_border-radius pr-pl-none`}
                          permissions={this.props.permissions}
                        />
                        <InputText
                          required={true}
                          labelText=""
                          maxLength={100}
                          type={'text'}
                          PlaceHolder={''}
                          id="baseline_value"
                          name="baseline_value"
                          fieldValue={convertInCommaFormat(
                            indicator.baseline_value.value
                          )}
                          fieldError={indicator.baseline_value.error}
                          onHandleChange={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          onHandleBlur={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          wrapperClassName={'col-1 pr-2 mb-0'}
                          disableInput={this.state.saved}
                          className={`${
                            index === 0 ? 'bg-D4EAFF' : ''
                          } activity_border-radius `}
                          permissions={this.props.permissions}
                        />
                        <InputText
                          required={true}
                          labelText=""
                          maxLength={100}
                          type={'text'}
                          PlaceHolder={''}
                          id="target_value"
                          name="target_value"
                          fieldValue={convertInCommaFormat(
                            indicator.target_value.value
                          )}
                          fieldError={indicator.target_value.error}
                          onHandleChange={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          onHandleBlur={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          wrapperClassName={'col-1 pr-2 mb-0'}
                          disableInput={this.state.saved}
                          className={`${
                            index === 0 ? 'bg-D4EAFF' : ''
                          } activity_border-radius `}
                          permissions={this.props.permissions}
                        />
                        <span className="col-1 bg-white">
                          <AuthHideShow permissions={this.props.permissions}>
                            {index === 0 && !this.state.saved && (
                              <div
                                className="text-31AEFD  fs-12 mt-3 cursor-pointer"
                                onClick={() =>
                                  this.clearPrimaryIndicator(index)
                                }>
                                Clear
                              </div>
                            )}
                            {index > 0 && !this.state.saved && (
                              <i
                                className="fas fa-trash-alt text-31AEFD  fs-19 ml-4 mt-2 cursor-pointer"
                                style={{ backgroundColor: 'none' }}
                                onClick={() => this.deleteIndicator(index)}></i>
                            )}
                          </AuthHideShow>
                        </span>
                      </div>
                    </>
                  );
                })}
                <AuthHideShow permissions={this.props.permissions}>
                  <div className="row mt-4">
                    <div className="col-12 pl-0 mt-5 mb-3">
                      <button
                        id="indicator"
                        type="button"
                        onClick={() => this.addIndicator()}
                        className="btn btn-outline-primary btn-lg font-weight-bold fs-14"
                        style={{
                          // height: '34px',
                          // width: '166px',
                          marginTop: '-20px'
                        }}
                        disabled={
                          this.state.indicatorsArr.length === 20 ||
                          this.state.saved
                        }>
                        Add New Indicator
                      </button>
                    </div>
                  </div>
                </AuthHideShow>
              </div>
            </div>
          </div>

          {/************Sticky bottom bar***************/}
          <AuthHideShow permissions={this.props.permissions}>
            <div className="stickyBottomNav p-4">
              <div className="row">
                <div className="col-1"></div>

                <div className="col-5">
                  {this.props.currentCampaignStatus !== '' && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-lg fs-14 dm-bold"
                      onClick={() => this.props.previewCampaign('ACTIVE')}>
                      Preview Project
                    </button>
                  )}
                </div>

                <div className="col-6">
                  <div className="float-right">
                    <span
                      className="pl-4 fs-14 dm-medium color-007bff cursor-pointer"
                      onClick={() => this.handleCancelPopup(true)}>
                      Cancel
                    </span>
                    <>
                      <span
                        className="pl-4 fs-14 dm-medium color-007bff cursor-pointer"
                        onClick={e => this.props.showNext('ActivitiesPlan', 0)}>
                        Previous
                      </span>

                      <button
                        type="button"
                        onClick={() =>
                          this.props.currentCampaignStatus ===
                            'WAITING_FOR_FUNDING' && adminName === undefined
                            ? this.handleProposalResubmit()
                            : this.handleSaveFunctionCall()
                        }
                        className={` btn ${
                          this.props.currentCampaignStatus ===
                            'WAITING_FOR_FUNDING' && adminName === undefined
                            ? 'btn-primary'
                            : 'btn-outline-primary'
                        }  btn-lg fs-14 dm-bold ml-3 `}>
                        {this.props.currentCampaignStatus ===
                          'WAITING_FOR_FUNDING' && adminName === undefined
                          ? 'Submit'
                          : 'Save'}
                      </button>
                    </>
                    {this.props.currentCampaignStatus !==
                      'WAITING_FOR_FUNDING' &&
                      (adminName === undefined ? (
                        <button
                          disabled={this.state.submitDisable}
                          type="button"
                          className="btn btn-primary btn-lg fs-14 dm-bold ml-3"
                          onClick={() => this.campaignSubmissionConfirmation()}>
                          Submit
                        </button>
                      ) : (
                        ''
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </AuthHideShow>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  editSuccessResponse: state.campaign.editSuccessResponse,
  editErrorResponse: state.campaign.editErrorResponse,
  userInfo: state.login.userInfo,
  indicatorsDetailsSuccessResponse: state.mne.indicatorsDetailsSuccessResponse,
  indicatorsDetailsErrorResponse: state.mne.indicatorsDetailsErrorResponse
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCampaign,
      createOrModifyIndicators,
      getGoalsIndicators,
      getFrequencyListMasterData,
      getUomListMasterData
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIndicators);
