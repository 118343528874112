import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../actions';
import { initializeKeycloak } from '../helpers/keycloak';
import { getPathMatch } from '../constants/role.constants';
import Loader from './loader';
import Unauthorized from './unauthorized';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function getPermissions(policyArr) {
  if (!policyArr || policyArr.length === 0) return null;
  if (policyArr.findIndex(x => x.permissions === 'CRUD') > -1) {
    return 'CRUD';
  } else if (policyArr.findIndex(x => x.permissions === 'CRU') > -1) {
    return 'CRU';
  } else if (policyArr.findIndex(x => x.permissions === 'CR') > -1) {
    return 'CR';
  } else if (policyArr.findIndex(x => x.permissions === 'RU') > -1) {
    return 'RU';
  } else if (policyArr.findIndex(x => x.permissions === 'U') > -1) {
    return 'U';
  } else {
    return 'R';
  }
}

export function getUserPagePolicy(pageObjArr, policies) {
  let userPagePolicy = policies?.filter(option => {
    const value = pageObjArr.some(x => option.resource_feature === x.feature);
    if (value) return true;
    return false;
  });
  return userPagePolicy;
}

export function securedHOC(Component, path) {
  const Wrappedcomponent = props => {
    const dispatch = useDispatch();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [usertype, setUserType] = useState(0);
    let policy = [];
    isAuthenticated === null &&
      initializeKeycloak()
        .then(({ userDetails }) => {
          if (userDetails.user_type === 2) {
            dispatch(loginSuccess(userDetails));
            setIsAuthenticated(true);
            setUserType(userDetails.user_type);
          } else {
            // dispatch(loginSuccess(userDetails));
            // setIsAuthenticated(true);
            if (window.location.href.includes('email'))
              setUserType(userDetails.user_type);
            else {
              // dispatch(loginSuccess(userDetails));
              // setIsAuthenticated(true);
            }
          }
        })
        .catch(() => {
          setIsAuthenticated(false);
        });
    if (isAuthenticated || usertype === 1) {
      if (path) {
        const pageObjArr = getPathMatch(path);
        const policies = JSON.parse(
          window.localStorage.getItem('userPolicies')
        );
        if (pageObjArr.length > 0 && policies && policies.length > 0) {
          policy = getUserPagePolicy(pageObjArr, policies);
          if (
            (policy.length &&
              (!policy[0].permissions ||
                policy[0].permissions === '' ||
                policy[0].permissions === '-')) ||
            policy.length === 0
          ) {
            return <Unauthorized />;
          }
        }
      }

      return <Component {...props} permissions={getPermissions(policy)} />;
    }
    return <Loader loaderText="Checking your login" />;
  };
  Wrappedcomponent.displayName = `securedHOC(${getDisplayName(Component)})`;
  return Wrappedcomponent;
}
