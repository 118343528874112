import { toast } from 'react-hot-toast';
import CampaignService from '../services/campaign.service';
import { Action } from '../constants';
export const getCampaignsForNgo = ngoId => dispatch => {
  dispatch(resetCampaignFormDataResponse());
  dispatch(campaignListRequest());
  CampaignService.getAllCampaignsForNgo(ngoId)
    .then(response => {
      if (response.data.code === '200') {
        dispatch(campaignListSuccess(response.data.result));
      } else {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      }
    })
    .catch(error => {
      dispatch(campaignListFailure(error));
    });
};
export const deleteDraftCampaignForNGO = (campaignId, ngoId) => dispatch => {
  dispatch(resetCampaignFormDataResponse());
  dispatch(campaignDeleteRequest());
  CampaignService.deleteDraftCampiagn(campaignId)
    .then(response => {
      if (response.data.code === '200') {
        dispatch(campaignDeleteSuccess(response.data));
        toast.success('Project Draft Deleted Successfully');
        dispatch(getCampaignsForNgo(ngoId));
      } else {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      }
    })
    .catch(error => {
      dispatch(campaignDeleteFailure(error));
    });
};
export const duplicateCampaignForNgo = campaignId => dispatch => {
  CampaignService.duplicateCampaign(campaignId)
    .then(response => {
      if (response.data.code === '200') {
        // dispatch(campaignDeleteSuccess(response.data));
        toast.success('Project Duplicated Successfully');
        // dispatch(getCampaignsForNgo(ngoId));
      } else {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      }
    })
    .catch(error => {
      dispatch(campaignDeleteFailure(error));
    });
};

export const getCampaignsForAdmin = () => dispatch => {
  dispatch(resetCampaignFormDataResponse());
  dispatch(campaignListRequest());
  CampaignService.getAllCampaignsForAdmin()
    .then(response => {
      if (response.status === 200 && response.data.code === 200) {
        dispatch(campaignListSuccess(response.data));
      } else {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      }
    })
    .catch(error => {
      dispatch(campaignListFailure(error));
    });
};

export const getCampaign = (campaignId, mode) => dispatch => {
  dispatch(campaignDetailRequest());
  CampaignService.getCampaign(campaignId, mode)
    .then(response => {
      if (response.status === 200 && response.data.code === '200') {
        dispatch(campaignDetailSuccess(response.data.result, mode));
      } else {
        dispatch(
          campaignDetailFailure(
            {
              code: response.data.code,
              message: response.data.message
            },
            mode
          )
        );
      }
    })
    .catch(error => {
      dispatch(campaignDetailFailure(error, mode));
    });
};

export const createOrModifyCampaign = (data, status) => dispatch => {
  dispatch(campaignCreateRequest());
  return CampaignService.createOrModifyCampaign(data, status)
    .then(response => {
      if (
        response.status === 200 &&
        (response.data.code === '200' || response.data.code === 200)
      ) {
        dispatch(campaignCreateSuccess(response.data));
        return response;
      } else {
        dispatch(campaignCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(campaignCreateFailure(error));
    });
};

export const getActivitiesById = campaignId => dispatch => {
  dispatch(activitiesDetailsRequest());
  return CampaignService.getActivitiesById(campaignId)
    .then(response => {
      if (response.status === 200 || response.status === 204) {
        dispatch(activitiesDetailsSuccess(response.data));
      }
    })
    .catch(error => {
      if (error.response) dispatch(activitiesDetailsFailure(error));
    });
};

export const deleteBudgetSection = data => dispatch => {
  return CampaignService.deleteBudgetSection(data)
    .then(response => {})
    .catch(error => {
      if (error.response) dispatch(activitiesDetailsFailure(error));
    });
};

export const getNgoEmailByCampaign = campaignId => dispatch => {
  return CampaignService.getNgoEmailByCampaign(campaignId);
};
export const checkForCorpInterest = campaignId => dispatch => {
  return CampaignService.checkForCorpInterest(campaignId);
};
export const acceptCampaignMod = campaignId => dispatch => {
  return CampaignService.acceptCampaignMod(campaignId);
};
export const rejectCampaignMod = campaignId => dispatch => {
  return CampaignService.rejectCampaignMod(campaignId);
};
export const resetCampaignFormDataResponse = () => {
  return {
    type: Action.CAMPAIGN_RESET_RESPONSE
  };
};

export const campaignListRequest = () => {
  return {
    type: Action.CAMPAIGN_LIST_REQUEST
  };
};
export const campaignDeleteRequest = () => {
  return {
    type: Action.CAMPAIGN_DELETE_REQUEST
  };
};

export const campaignListSuccess = campaigns => {
  return {
    type: Action.CAMPAIGN_LIST_SUCCESS,
    payload: campaigns
  };
};
export const campaignDeleteSuccess = campaigns => {
  return {
    type: Action.CAMPAIGN_DELETE_SUCCESS,
    payload: campaigns
  };
};

export const campaignListFailure = error => {
  return {
    type: Action.CAMPAIGN_LIST_FAILURE,
    payload: error
  };
};
export const campaignDeleteFailure = error => {
  return {
    type: Action.CAMPAIGN_DELETE_FAILURE,
    payload: error
  };
};

export const campaignCreateRequest = () => {
  return {
    type: Action.CAMPAIGN_CREATE_REQUEST
  };
};

export const campaignCreateSuccess = campaigns => {
  return {
    type: Action.CAMPAIGN_CREATE_SUCCESS,
    payload: campaigns
  };
};

export const activitiesDetailsRequest = () => {
  return {
    type: Action.ACTIVITIES_DETAIL_REQUEST
  };
};
export const activitiesDetailsSuccess = activities => {
  return {
    type: Action.ACTIVITIES_DETAILS_SUCCESS,
    payload: activities
  };
};
export const activitiesDetailsFailure = error => {
  return {
    type: Action.ACTIVITIES_DETAILS_FAILURE,
    payload: error
  };
};

export const campaignCreateFailure = error => {
  return {
    type: Action.CAMPAIGN_CREATE_FAILURE,
    payload: error
  };
};

export const resetCampaignFormData = () => dispatch => {
  return dispatch(resetCampaignFormDataResponse());
};

export const campaignDetailRequest = () => {
  return {
    type: Action.CAMPAIGN_DETAIL_REQUEST
  };
};

export const campaignDetailSuccess = (campaigns, mode) => {
  let action;
  switch (mode) {
    case 'preview':
      action = {
        type: Action.CAMPAIGN_PREVIEW_SUCCESS,
        payload: campaigns
      };
      break;

    case 'edit':
      action = {
        type: Action.CAMPAIGN_EDIT_SUCCESS,
        payload: campaigns
      };
      break;

    case 'detail':
      action = {
        type: Action.CAMPAIGN_DETAIL_SUCCESS,
        payload: campaigns
      };
      break;
    default:
  }
  return action;
};

export const campaignDetailFailure = (error, mode) => {
  let action;
  switch (mode) {
    case 'preview':
      action = {
        type: Action.CAMPAIGN_PREVIEW_FAILURE,
        payload: error
      };
      break;

    case 'edit':
      action = {
        type: Action.CAMPAIGN_EDIT_FAILURE,
        payload: error.response || {}
      };
      break;

    case 'detail':
      action = {
        type: Action.CAMPAIGN_DETAIL_FAILURE,
        payload: error
      };
      break;
    default:
  }
  return action;
};
