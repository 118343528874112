import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Routes } from '../constants';
import { sendMail } from '../helpers/emailSender';
import thankyouIcon from '../assets/images/thankyou.png';
import Modal from './newDesign/Modals/Modal';

function calculateSubmitButtonValue(stepStatuses) {
  return (
    stepStatuses && stepStatuses.filter(Boolean).length === stepStatuses.length
  );
}

export default function ProfileSubmit({
  data,
  stepStatuses,
  disabled,
  approved
}) {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [show, showModal] = useState(false);

  function handleCloseModal() {
    showModal(false);
    let body = document.body;
    body.style.overflow = 'auto';
  }
  const handleChange = () => {
    setDisableSubmit(true);
    const userId = data.state.ngo.ngoProfile.ngo_id;
    data
      .submitProfile({
        at_step: '8',
        id: `${userId}`,
        status: 'submitted'
      })
      .then(res => {
        if (res.status === 200) {
          // setDisableSubmit(false);
          data.getNgoProfile(data.state.login.userInfo);
          toast.dismiss();
          data.disableSaveButton(true);
          toast.success('Profile Submitted Successfully');
          showModal(true);
          sendMail({
            // templateName:
            //   data.state.register.ngoSavedData.l2_status ===
            //   'modification_required'
            //     ? 'ngoRegistrationReSubmit'
            //     : 'ngoRegistrationSubmit',
            templateName:
              data.state.register.ngoSavedData.l2_status ===
              'modification_required'
                ? 'ngoRegistrationReSubmitNewDesign'
                : 'ngoRegistrationSubmitNewDesign',
            sendTo: data.state.login.userInfo.email,
            subject:
              data.state.register.ngoSavedData.l2_status ===
              'modification_required'
                ? 'Received Modification'
                : 'NGO Profile Submitted',
            templateData: {
              ngoName: data.state.register.ngoSavedData.existence.ngo_name
            }
          });
          if (
            data.state.register.ngoSavedData.l2_status ===
            'modification_required'
          ) {
            sendMail({
              // templateName: 'ngoRegistrationReSubmitAlert',
              templateName: 'ngoRegistrationReSubmitAlertNewDesign',
              sendTo: 'ngo.partnerships@mettasocial.com',
              subject: `Modification Submitted - ${data.state.register.ngoSavedData.existence.ngo_name}`,
              templateData: {
                ngoName: data.state.register.ngoSavedData.existence.ngo_name
              }
            });
          }
          const savedReqModObject = JSON.parse(
            window.localStorage.getItem('profileRequestMod')
          );
          if (savedReqModObject)
            window.localStorage.removeItem('profileRequestMod');
        }
      })
      .catch(() => {
        toast.success(
          'Uh-Oh! Looks like we are having server issues., please try again in some time',
          {
            autoClose: 2000
          }
        );
        setDisableSubmit(false);
      });
  };
  return (
    <>
      <button
        disabled={
          !calculateSubmitButtonValue(stepStatuses) || disabled || disableSubmit
        }
        type="button"
        onClick={handleChange}
        className="btn btn-primary btn-lg h5 mt-4 font-weight-bold">
        {data.state.register.ngoSavedData.l1_status ===
          'modification_required' ||
        data.state.register.ngoSavedData.l0_status ===
          'modification_required' ||
        data.state.register.ngoSavedData.l2_status ===
          'modification_required' ||
        data.state.register.ngoSavedData.l0_status === 'modification_required'
          ? 'Re-Submit Profile'
          : data.state.register.ngoSavedData.l1_status === 'submitted' ||
            data.state.register.ngoSavedData.l0_status === 'submitted' ||
            data.state.register.ngoSavedData.l2_status === 'submitted'
          ? 'Profile Submitted'
          : 'Submit Profile'}
      </button>
      {!approved && (
        <p className="h6 text-center text-danger">
          <i className="fas fa-info-circle mr-1"></i>
          You can not edit your profile after you submit it.
        </p>
      )}
      <Modal
        show={show}
        title={'Profile Submitted Successfully'}
        handleCloseModal={handleCloseModal}
        sizeClass={'modal-hw'}>
        <div>
          <h4 className="fs-18 font-weight-normal color-344256 mb-4">
            Your profile has been submitted successfully and will be reviewed by
            our team.
          </h4>
          <div className="d-flex my-4 justify-content-center">
            <span>
              <img
                src={thankyouIcon}
                width={120}
                height={125}
                alt="corporate"></img>
            </span>
          </div>
          <h4 className="fs-18 font-weight-normal color-344256 mt-4 ">
            Meanwhile you may view the list of corporates associated with our
            platform.
          </h4>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-4">
          <span
            className="color-1FA4FA fs-16 p-3 mr-5 cursor-pointer"
            onClick={() => handleCloseModal()}>
            Close
          </span>
          <Link to={Routes.NGO.CORPORATE_LIST}>
            <button
              type="button"
              className="btn btn-primary btn-lg font-weight-bold"
              // onClick={e => ResolveComment(e, commentId)}
              // disabled={disableResolve}
            >
              View Corporates
            </button>
          </Link>
        </div>
      </Modal>
    </>
  );
}
