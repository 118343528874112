import React, { useEffect, useState } from 'react';
import { getParsedDateDDMMYYYY } from '../../formatsForDate';
import ReceiptSentSuccessModal from './receiptSentSuccessModal';
import UploadReceiptDocuments from './uploadReceiptDocuments';
const $ = window.$;
function DonationReceipts(props) {
  const [receiptsSent, setReceiptsSent] = useState(false);
  useEffect(() => {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);

  const getValue = (value, field) => {
    if (field === 'amount' && value !== null) {
      return '₹ ' + value;
    } else if (value !== null) {
      return value;
    } else {
      return '--';
    }
  };
  const checkForReceipt = receipt => {
    if (receipt === true) {
      return 'Sent';
    } else {
      return 'Not Sent';
    }
  };
  const sentAllReceipts = () => {
    setReceiptsSent(true);
  };

  return (
    <>
      {props.donationReportListCount === 0 && (
        <div className="text-center mt-8 no-print">
          <img
            className="pl-2"
            src="/images/donationEmptyState.svg"
            alt=""
            width={300}
          />
          <div className="fs-18 my-2 text-1C1C1C">
            Sorry! We couldn’t find any Records
          </div>
          <div className="fs-14 text-626262 mb-5">
            We didn’t not finds any records matching your requirements. Please
            try other filters
          </div>
        </div>
      )}

      {props.donationReportListCount > 0 && (
        <>
          <div className="mb-4">
            <UploadReceiptDocuments
              ngoId={props?.ngoId}
              uploadedReceiptData={props.uploadedReceiptData}
              postDonationReceiptUploadedData={
                props.postDonationReceiptUploadedData
              }
            />
          </div>
        </>
      )}

      <div className="row mb-4 ml-1-neg no-print">
        <div className="col-6">
          {props.donationReportListCount > 0 && (
            <div className="fs-18 text-1C1C1C fw-400 mb-4 mt-4">
              {props.donationReportListCount}
              {' Records'}
            </div>
          )}
        </div>
        <div className="col-6">
          {props.donationReportListCount > 0 && (
            <div className="d-flex ml-2 float-right my-3">
              <span className="fs-12 text-7B8980 mt-2">
                Who will get the receipts?
              </span>
              <i
                className="fas fa-info-circle text-7B8980 mx-3 cursor-pointer mt-07rm"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  'Receipt will be sent to only those donors who have not received it. You can check Receipt Status column for the donors who are yet to receive the receipts.'
                }></i>

              <button
                className="btn btn-primary position-relative cursor-pointer"
                onClick={() => sentAllReceipts()}>
                Send Receipts To All
              </button>
            </div>
          )}
        </div>
      </div>

      {props.donationReportListCount > 0 && (
        <div className="bg-white no-print">
          <div className="overflow-scroll-50vh">
            <table
              className="table table-borders table-hover fs-14 bg-white w-100"
              id="table-to-xls">
              <thead className={'table-thead text-uppercase'}>
                <tr className={'text-left table-th h-lh-45'}>
                  <th scope="col" className="px-2 py-2 fs-12 w-5 px-4">
                    <b>SR NO</b>
                  </th>
                  <th scope="col" className="px-1 py-2 fs-12 w-15">
                    <b>DONOR NAME</b>
                  </th>
                  <th scope="col" className="px-1 py-2 fs-12 w-20 ">
                    <b>NAME OF MISSION</b>
                  </th>
                  <th scope="col" className="px-1 py-2 fs-12 w-10">
                    <b>PAN NUMBER</b>
                  </th>
                  <th scope="col" className="px-1 py-2 fs-12 w-10">
                    <b>ADDRESS</b>
                  </th>
                  <th className="fs-12 px-1 py-2 w-15">
                    <b>DONATION DATE</b>
                    <img
                      className="pl-2 cursor-pointer"
                      src="/images/shuffle.svg"
                      alt=""
                      width={15}
                      onClick={() => props.sortByColumn('transaction_date')}
                    />
                  </th>
                  <th className="fs-12 px-1 py-2 w-15">
                    <b> DONATION AMOUNT </b>
                    <img
                      className="pl-2 cursor-pointer"
                      src="/images/shuffle.svg"
                      alt=""
                      width={15}
                      onClick={() => props.sortByColumn('amount')}
                    />
                  </th>
                  <th scope="col" className="px-1 py-2 fs-12 w-15">
                    <b>RECEIPT STATUS</b>
                  </th>
                </tr>
              </thead>
              {props.donationReportList?.map((item, index) => {
                return (
                  <>
                    <tbody>
                      <tr className={'text-center'} key={`donation`}>
                        <td className="table-td px-2 py-3 px-4">
                          {' '}
                          {index + props.serialNumber}
                        </td>
                        <td className="table-td px-2 py-3">
                          {getValue(item?.employee_name, '')}
                        </td>
                        <td className="table-td px-2 py-3 text-007bff">
                          {getValue(item?.mission_name, '')}
                        </td>

                        <td className="table-td px-2 py-3">
                          {getValue(item?.pan_number, '')}
                        </td>

                        <td className="table-td px-2 py-3">
                          {getValue(item?.employee_address, '')}
                        </td>

                        <td className="table-td px-1 py-2">
                          {getValue(
                            getParsedDateDDMMYYYY(item?.transaction_date),
                            ''
                          )}
                        </td>
                        <td className="table-td px-1 py-2">
                          {getValue(item?.amount, 'amount')}
                        </td>
                        <td className="table-td px-1 py-2 color-DB2828">
                          {checkForReceipt(item?.is_receipt_sent)}
                        </td>
                      </tr>
                    </tbody>
                  </>
                );
              })}
            </table>
          </div>
        </div>
      )}
      <ReceiptSentSuccessModal
        receiptsSent={receiptsSent}
        setReceiptsSent={setReceiptsSent}
        sendReceiptToAllRecords={props.sendReceiptToAllRecords}
        ngoId={props?.ngoId}
        getDonationReport={props.getDonationReport}
        initialFyDate={props.initialFyDate}
      />
    </>
  );
}
export default DonationReceipts;
