import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { sendMail } from '../helpers/emailSender';
import { toast } from 'react-hot-toast';
import { getNgoEmailByCampaign, rejectCampaignMod } from '../actions';
import '../../src/styles/acceptCampaignMod.css';
class RejectCampaignMod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approversList: [],
      currentApprover: null,
      campaignDetails: [],
      ngoEmail: '',
      campaignName: '',
      corpEmail: '',
      ipAddress: '',
      cardContentType: 'REQUEST_OTP',
      length: 6,
      otp: '000000',
      otpError: '',
      approved: false,
      comment: '',
      corAdminName: '',
      corpAdminEmail: '',
      disableApproveBtn: false,
      campaignId: null
    };
  }
  validateOTP = () => {
    this.setState({ disableApproveBtn: true });
    const otpForm = document.getElementById('otp-form');
    let otp = '';
    let error = null;
    for (let index = 0; index < otpForm.length; index++) {
      let input = otpForm[index];
      otp += input.value;
    }
    if (otp.length === 0) {
      error = "OTP can't be blank";
    } else if (otp.length !== 6) {
      error = 'OTP must contain 6 digits';
    } else {
      let pattern = /^[0-9]+$/;
      if (!pattern.test(otp)) {
        error = 'OTP must contain only numbers';
      }
    }
    if (error) {
      this.setState({ otpError: error });
    } else {
      this.setState({ otpError: '' });
      this.handleApprove(this.state.campaignId);
    }
  };
  handleApprove = campaignId => {
    this.props
      .rejectCampaignMod(parseInt(window.location.pathname.split('/')[3]))
      .then(res => {
        if (res.status === 200) {
          sendMail({
            templateName: 'projectProposalModificationCorp',
            sendTo: this.state.corpEmail,
            subject: 'Express Interest - Project',
            templateData: {
              campaignName: this.state.campaignName
                ? this.state.campaignName
                : ''
            }
          });
        }
      });
  };
  componentDidMount() {
    this.getNgoDetails();
  }
  getNgoDetails() {
    let campaignId = window.location.pathname.split('/')[3];
    //
    this.props.getNgoEmailByCampaign(campaignId).then(res => {
      this.setState({
        ngoEmail: res.data.ngo_email,
        campaignName: res.data.campaign_name,
        corpEmail: res.data.corp_email
      });
    });
    // });
    // getActivitiesById(campaignId)
    // acceptCampaignMod(campaignId).then(res => {
    //   console.error(res);
    // });
  }
  generateOTP = () => {
    const digits = '0123456789';
    const otpLength = 6;
    let otp = '';

    for (let i = 1; i <= otpLength; i++) {
      var index = Math.floor(Math.random() * digits.length);
      otp = otp + digits[index];
    }
    return otp;
  };

  resendOTP = () => {
    let otp = this.generateOTP();
    sendMail({
      // templateName: "auditTrailOTP",
      templateName: 'auditTrailOTPNewDesign',
      sendTo: this.state.ngoEmail,
      subject: ' OTP to approve Project ' + this.state.campaignDetails.title,
      templateData: {
        otp: otp
      }
    });
  };
  getCampaignDetails = async campaignId => {
    if (campaignId) {
      await this.props
        .getCampaignDetailsForGuest(campaignId)
        .then(async res => {
          if (!!res && !!res.id) {
            let campaign = res;
            campaign.campaignId = res.id;
            campaign.title = res.name;
            campaign.image = res.image_url;
            campaign.video = res.video_url;
            await this.setState({ campaignDetails: campaign });
          } else if (!!res && !!res.message) {
            await this.setState({
              navigateToCampaignListPage: true,
              isLoading: false
            });
            toast.error(
              res.message
                ? res.message
                : 'Uh oh! An unexpected error while fetching the Project details!'
            );
          }
        })
        .catch(async err => {
          await this.setState({
            navigateToCampaignListPage: true,
            isLoading: false
          });
          toast.error(
            err.error
              ? err.error
              : 'Uh oh! An unexpected error while fetching the Project details!'
          );
        });
    }
  };

  requestOTP = () => {
    let otp = this.generateOTP();
    this.setState({ cardContentType: 'ENTER_OTP' });
    sendMail({
      templateName: 'auditTrailOTPNewDesign',
      sendTo: this.state.ngoEmail,
      subject: ' OTP to verify email ',
      templateData: {
        otp: otp
      }
    });
  };
  shiftInputFocus(position) {
    if (position < 6) {
      const currentInput = document.getElementsByName(`otp-field-${position}`);
      const nextInput = document.getElementsByName(`otp-field-${position + 1}`);
      if (
        (currentInput[0].value || currentInput[0].value !== '') &&
        (!nextInput[0].value || nextInput[0].value === '')
      ) {
        nextInput[0].focus();
      }
    }
  }
  //   onChangeComment = e => {
  //     this.setState({ comment: e.target.value });
  //   };
  render() {
    return (
      <div className="position-relative pt-3">
        <div className="page-bg position-absolute"></div>
        {this.state.currentApprover && this.state.currentApprover.logo && (
          <img
            src={this.state.currentApprover.logo}
            alt="Comapny logo"
            className="company-logo position-absolute"
          />
        )}
        <div className="position-relative">
          <div className="d-flex justify-content-center approver-container mb-5">
            {this.state.approversList &&
              this.state.approversList.map((approver, index) => {
                const isApproved =
                  approver.action && approver.action !== ''
                    ? 'APPROVED'
                    : 'PENDING APPROVAL';
                return (
                  <div
                    key={index + approver.approver_id + approver.approver}
                    className={`approver-item text-center position-relative text-center topBullet ${
                      index + 1 < this.state.approversList.length
                        ? 'topBorder'
                        : ' '
                    } ${
                      isApproved === 'APPROVED' ? 'topBorderApproved' : ' '
                    } ${
                      isApproved === 'APPROVED' ? 'topBulletApproved' : ' '
                    }`}>
                    <span
                      className={`topStatus mb-0 position-absolute ${
                        isApproved === 'APPROVED' ? 'statusGreen' : ' '
                      }`}>
                      {isApproved === 'APPROVED'
                        ? 'APPROVED'
                        : 'PENDING APPROVAL'}
                    </span>
                    <span className="d-block approverTitle mb-2">
                      {approver.approver}
                    </span>
                    <span className="d-block approverText">
                      {approver.designation}
                    </span>
                  </div>
                );
              })}
          </div>
          {this.state.currentApprover && this.state.approved && (
            <div className="d-flex justify-content-center mb-5 review-btn-container">
              <Link
                to={`/guest/approverSummary/${this.state.currentApprover?.campaign_id}`}>
                {/* <WhiteButton label="Review Approval Summary" /> */}
              </Link>
            </div>
          )}
          <div className="otp-card mx-auto p-5 bg-white">
            <h1 className="title mb-4 pb-1 text-center font-weight-bold">
              {!this.state.approved
                ? 'Corporate awaits your Approval...!'
                : 'Thank you for your Approval...!'}
            </h1>
            {this.state.cardContentType === 'ENTER_OTP' && (
              <>
                <h3 className="subtitle text-center mb-5">
                  We have sent you an OTP on your official email. Please enter
                  the OTP below and approve to continue.
                </h3>

                <div>
                  <form
                    method="post"
                    id="otp-form"
                    className="w-100 d-flex flex-nowrap justify-content-center mx-auto py-4">
                    {Array(this.state.length)
                      .fill('')
                      .map((_, index) => (
                        <input
                          key={`otp-field-${index + 1}`}
                          name={`otp-field-${index + 1}`}
                          className="otp-input mx-2"
                          type={'text'}
                          maxLength={1}
                          minLength={1}
                          onChange={() => this.shiftInputFocus(index + 1)}
                        />
                      ))}
                  </form>
                  {this.state.otpError !== '' && (
                    <p className="text-center text-danger mb-0">
                      {this.state.otpError}
                    </p>
                  )}
                </div>
                {/* <div className="comment mx-auto mt-4">
                    <text className="fieldLabel d-block mb-2">
                      Add Comment (optional)
                    </text>
                    <textarea
                      id="comment"
                      name="comment"
                      placeholder="Max. 500 characters"
                      value={this.props.value}
                      onChange={this.onChangeComment}
                      rows={4}
                      maxLength={500}
                      className="d-block w-100 noResize"></textarea>
                  </div> */}
                <div className="text-center pt-4 pb-2">
                  {/* <GreenButton
                      label="APPROVE"
                      type="submit"
                      onClick={this.validateOTP}
                      isDisabled={this.state.disableApproveBtn}
                    /> */}
                  <button
                    type="button"
                    className="form-control col-md-3 btn btn-success save-button"
                    disabled={this.state.disableApproveBtn}
                    onClick={() => this.validateOTP()}>
                    Verify OTP
                  </button>
                </div>
                <p className="text-center mb-0">
                  Did not receive an OTP?&nbsp;
                  <span
                    className="text-info text-underline cursor-pointer"
                    onClick={this.resendOTP}>
                    Resend OTP
                  </span>
                </p>
              </>
            )}
            {this.state.cardContentType === 'REQUEST_OTP' && (
              <>
                <h3 className="subtitle text-center mb-0">
                  Kindly verify the email ID to approve the Proposal
                  Modification{' '}
                  <span className="font-weight-bold">
                    {/* {'this.state.currentApprover.campaign_name'} */}
                  </span>{' '}
                  <span className="font-weight-bold">
                    {/* {this.state.currentApprover.ngo_name} */}
                  </span>{' '}
                </h3>
                <h3 className="subtitle text-center mb-5">
                  You will receive an OTP on your email, required to grant
                  Approval for the funds transfer process. Please click on
                  Request OTP button.
                </h3>

                <div className="text-center pt-4">
                  {/* <GreenButton
                      label="Request OTP"
                      onClick={this.requestOTP}
                    /> */}
                  <button
                    type="button"
                    className="form-control col-md-3 btn btn-success save-button btn-md"
                    //   disabled={props.disabled}
                    onClick={() => this.requestOTP()}>
                    Request OTP
                  </button>
                </div>
              </>
            )}
            {this.state.cardContentType === 'APPROVED' && (
              <>
                <h3 className="subtitle text-center mb-4">
                  Your approval brings us one step closer to achieve our goal.
                  Thank you for your time and support.
                </h3>
                <img
                  src={'/images/otp-approval-thank-you-icn.png'}
                  alt="OTP approval appreciation icon"
                  className="otp-approval-icn d-block mx-auto mb-1"
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
RejectCampaignMod.propTypes = {
  getApproversForCampaign: PropTypes.func.isRequired,
  getCampaignDetailsForGuest: PropTypes.func.isRequired,
  getCorpAdmin: PropTypes.func.isRequired
};
function mapStateToProps(state) {
  return {
    token_data: state.token_data
  };
}
export default connect(mapStateToProps, {
  //   getApproversForCampaign,
  //   getCampaignDetailsForGuest,
  //   initiateApprovalFlow,
  //   getCorpAdmin,
  getNgoEmailByCampaign,
  rejectCampaignMod
})(RejectCampaignMod);
