import { toast } from 'react-hot-toast';
import { Action, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

export const getRoles = () => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  instance
    .get(`${EndPoint.getApiUrl('NGO', 'GET_ROLES', 'HIROKU')}`)
    .then(response => {
      dispatch({
        type: Action.ROLES_LIST_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => {
      console.error(error.response);
    });
};

export const getUsersWithRole = ngoId => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'GET_USER_WITH_ROLES', 'HIROKU');
  apiUrl = apiUrl.replace('{ngo_id}', ngoId);
  return instance
    .post(apiUrl)
    .then(response => {
      if (response.data.code !== 500) {
        dispatch({
          type: Action.USERS_WITH_ROLES_LIST_SUCCESS,
          payload: response.data
        });
      } else {
        toast.error(response.data.message);
      }
      return response;
    })
    .catch(err => {
      toast.error('Something Went Wrong Please Try Again');
    });
};

export const addUserToRole = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'ADD_USER_TO_ROLE', 'HIROKU');
  return instance
    .post(apiUrl, data)
    .then(response => {
      if (response.data.code !== 500) {
        dispatch({
          type: Action.ADD_USER_SUCCESS,
          payload: response.data.result
        });
      } else {
        toast.error(response.data.message);
      }
      return response;
    })
    .catch(err => {
      toast.error('Something Went Wrong Please Try Again');
    });
};

export const updateUserToRole = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'UPDATE_USER_ROLES', 'HIROKU');
  return instance
    .post(apiUrl, data)
    .then(response => {
      if (response.data.code !== 500) {
        dispatch({
          type: Action.UPDATE_USER_SUCCESS,
          payload: response.data.result
        });
      } else {
        toast.error(response.data.message);
      }
      return response;
    })
    .catch(err => {
      toast.error('Something Went Wrong Please Try Again');
    });
};

export const deleteUser = userId => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'DELETE_USER', 'HIROKU');
  apiUrl = apiUrl.replace('{user_id}', userId);
  return instance
    .post(apiUrl)
    .then(response => {
      if (response.data.code !== 500) {
        dispatch({
          type: Action.DELETE_USER_SUCCESS,
          payload: response.data.result
        });
      } else {
        toast.error(response.data.message);
      }
      return response;
    })
    .catch(err => {
      toast.error('Something Went Wrong Please Try Again');
    });
};
