import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import docIcon from '../assets/images/doc.png';
import pdfIcon from '../assets/images/pdf.png';
// import removeIcon from '../assets/images/remove.png';
import zipIcon from '../assets/images/zip.png';
import UploadService from '../services/upload.service';
import Modal from './newDesign/Modals/Modal';
import AuthHideShow from './authHideShow';
const $ = window.$;
export default function FileInput(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [show, showModal] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(true);
  const [filePreview, setFilePreview] = useState(null);
  const [fileType, setFileType] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  // const [thumbnail, setThumbnail] = useState('');
  const [fileName, setfileName] = useState('');
  const [docFile, setDocFile] = useState(null);
  useEffect(() => {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);

  let {
    id,
    label,
    name,
    value,
    refId,
    preview,
    previewDoc,
    validateFile,
    required,
    validation,
    fileSizeLimit,
    onSuccess,
    docType,
    extraInfoAboutField,
    fieldKey,
    showrequired,
    policyFieldUpdate,
    permissions,
    ...restProps
  } = props;

  const [fileUrlValue, setFileUrlValue] = useState();
  let fileUploadRef = useRef();
  let fileUploadDummyRef = useRef();
  let thumbnailVal = '';
  let fileTypeVal = '';
  let closeIcon = true;

  if (value !== null && value !== undefined && value.length > 0) {
    const savedValue = Array.isArray(value) ? value[0] : value;
    if (savedValue.includes('pdf') || savedValue.includes('PDF')) {
      thumbnailVal = 'pdf';
      fileTypeVal = pdfIcon;
    } else if (
      savedValue.includes('doc') ||
      savedValue.includes('DOC') ||
      savedValue.includes('docx') ||
      savedValue.includes('DOCX') ||
      savedValue.includes('xls') ||
      savedValue.includes('XLS') ||
      savedValue.includes('xlsx') ||
      savedValue.includes('XLSX')
    ) {
      thumbnailVal = 'doc';
      fileTypeVal = docIcon;
    } else if (savedValue.includes('zip')) {
      thumbnailVal = 'zip';
      fileTypeVal = zipIcon;
    } else {
      thumbnailVal = 'image';
      fileTypeVal = savedValue;
    }
  }
  useEffect(() => {
    const url = Array.isArray(value) ? value[0] : value;
    setFileUrlValue(url);
    setFileType(thumbnailVal);
    // setThumbnail(fileTypeVal);
    setShowCloseIcon(closeIcon);
    initFile(url);
  }, [value, thumbnailVal, fileTypeVal, closeIcon]);

  useEffect(() => {}, [fileName]);

  const initFile = url => {
    if (fileName === '' && url) {
      // setfileName(url.substring(url.lastIndexOf('/') + 1));
      if (url.split('/')[5] === 'undefined' || url.split('/')[5] === 'null') {
        setfileName(url.split('/')[7]);
      } else {
        setfileName(url.split('/')[5]);
      }
    }
  };
  const uploadProgressTracker = e => {
    const { loaded, total } = e;
    setUploadProgress(Math.floor((loaded / total) * 100 + 1 / 11));
  };

  function handleCloseModal() {
    showModal(false);
    let body = document.body;
    body.style.overflow = 'auto';
  }
  function showFilePreview(url, fileType) {
    showModal(true);
    setFilePreview(url);
    if (
      fileType === 'doc' ||
      fileType === 'DOC' ||
      fileType === 'docx' ||
      fileType === 'DOCX' ||
      fileType === 'xls' ||
      fileType === 'XLS' ||
      fileType === 'xlsx' ||
      fileType === 'XLSX'
    ) {
      setDocFile(process.env.REACT_APP_DOC_FILE_PREVIEW_URL + url);
      setFileType('doc');
    }
    let body = document.body;
    body.style.overflow = 'hidden';
  }
  function removeFile(url) {
    setFilePreview('');
    setFileUrlValue('');
    if (props.fieldUpdated && props.stepIndex >= 0)
      props.fieldUpdated(props.stepIndex);
  }
  const uploadFile = async event => {
    event.persist();
    try {
      if (!event.target.files) {
        throw new Error('Some thing went wrong..');
      }

      if (
        event.target.files &&
        fileSizeLimit &&
        event.target.files.length &&
        event.target.files[0].size > fileSizeLimit * 1000 * 1000
      ) {
        throw new Error(`File size can not be more than ${fileSizeLimit}MB`);
      }

      if (event.target.files[0].name.split('.').length > 2) {
        throw new Error(
          'Incorrect file format. File name should not contain dots'
        );
      }
      if (validateFile) {
        await validateFile(event.target.files[0]);
      }
    } catch (e) {
      event.target.value = '';
      toast.error(e.message);
      return false;
    }
    let formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('mimeType', event.target.files[0].type);
    formData.append('docType', docType || 'campaign_image');
    formData.append('moduleName', 'ngo');
    formData.append('refId', refId);
    setIsLoading(true);
    if (props.setIsUploading) props.setIsUploading(true);
    setfileName(event.target.files[0].name);
    UploadService.upload(formData, props.userId, 'doc', uploadProgressTracker)
      .then(res => {
        if (props.fieldUpdated && props.stepIndex >= 0)
          props.fieldUpdated(props.stepIndex);

        if (policyFieldUpdate) {
          policyFieldUpdate(props.id, res.data.fileUrl);
        }
        setIsLoading(false);
        if (props.setIsUploading) props.setIsUploading(false);
        event.target.files[0].size <= 1024 * 1024 * 3 &&
          toast.success('File uploaded successfully.');
        setFileUrlValue(res.data.fileUrl);
        if (event.target.files[0].type.includes('image')) {
          setFileType('image');
        } else if (event.target.files[0].type.includes('pdf')) {
          setFileType('pdf');
          // setThumbnail(pdfIcon);
        } else if (
          event.target.files[0].type.includes('doc') ||
          event.target.files[0].type.includes('docx')
        ) {
          setFileType('doc');
          // setThumbnail(docIcon);
        } else if (event.target.files[0].type.includes('zip')) {
          setFileType('zip');
          // setThumbnail(zipIcon);
        } else {
          setFileType('doc');
          // setThumbnail(docIcon);
        }
        onSuccess && onSuccess(res.data.fileUrl, event);
        // fileUploadDummyRef.current.value = res.data.fileUrl;
        const elements = document.getElementsByClassName(`${id}-error`);
        if (elements.length > 0) {
          if (!elements[0].classList.contains('d-none')) {
            elements[0].classList.add('d-none');
            elements[0].innerHTML = '';
          }
        }
      })
      .catch(err => {
        setIsLoading(false);
        if (props.setIsUploading) props.setIsUploading(false);
        toast.error(
          'Uh-Oh! Looks like we are having server issues., please contact our support!'
        );
      });
    return false;
  };
  return (
    <>
      <div className={`${props.className ? props.className : ''}`}>
        {props.labelText !== '' && (
          <label htmlFor={id}>
            {label}
            {required ||
            (validation && validation === 'required') ||
            showrequired ? (
              <span className="color-FF2300">{`*`}</span>
            ) : (
              ''
            )}
          </label>
        )}
        {extraInfoAboutField && props.labelText !== '' && (
          <i
            className="fas fa-info-circle text-info ml-2 p-2"
            data-toggle="tooltip"
            data-placement="top"
            title={extraInfoAboutField}></i>
        )}
        <div
          className={`${
            previewDoc ? 'docurl ' : ''
          } input-group position-relative`}>
          <AuthHideShow permissions={permissions}>
            <input
              {...restProps}
              ref={fileUploadRef}
              className={`form-control d-none`}
              id={id}
              onClick={event => {
                event.target.value = null;
              }}
              onChange={uploadFile}
              type="file"
            />
            <button
              type="submit"
              className={`btn btn-browse position-relative ${
                props.disabled ? 'disabled' : ' '
              }`}>
              Browse
            </button>
            <input
              id={id}
              required={required}
              name={name}
              ref={fileUploadDummyRef}
              {...restProps}
              type="url"
              value={isLoading ? `${uploadProgress}% Uploaded` : fileUrlValue}
              onClick={() => fileUploadRef.current.click()}
              onChange={uploadFile}
              className={`form-control col-11 ${isLoading &&
                'upload-progress-bar'} ${
                props.className ? props.className : ' '
              }  btn-input cursor-pointer position-absolute w-25`}></input>
          </AuthHideShow>
          {
            <>
              <div className="input-group-append align-items-center col-1 pl-0 pl-3 text-right">
                {isLoading && (
                  <span>
                    <i
                      className="fas fa-spinner fa-pulse fa-2x"
                      aria-hidden="true"></i>
                  </span>
                )}
              </div>
              {fileSizeLimit && fieldKey !== 'ngo_logo' && (
                <AuthHideShow permissions={permissions}>
                  <p className="col-12 h6 fs-12 fw-400 pl-0 pt-1 text-muted">
                    Maximum file size can be {fileSizeLimit}MB
                  </p>
                </AuthHideShow>
              )}
              <div className={`${props.fieldError === '' ? 'd-none' : ''}`}>
                <small id={'error-' + id} className="text-danger">
                  {props.fieldError}
                </small>
              </div>
              {!isLoading && preview && fileUrlValue && (
                <span
                  className="ml-2 mt-2 color-1FA4FA fs-12 cursor-pointer text-decoration"
                  onClick={e => showFilePreview(fileUrlValue, fileType)}>
                  Preview
                </span>
              )}
              {!isLoading && previewDoc && fileUrlValue && (
                <span className="ml-2 mt-2 fs-12 cursor-pointer d-flex align-items-center w-100">
                  <i
                    className="fa fa-check-circle-o mr-2"
                    style={{ color: '#81bc30', fontSize: '22px' }}></i>
                  <span
                    onClick={e => showFilePreview(fileUrlValue, fileType)}
                    className="cursor-pointer">
                    {/* {fileName} */}
                    {fileName?.length > 25
                      ? fileName?.slice(0, 25) + '...'
                      : fileName}
                  </span>
                  {showCloseIcon && !props.disabled && (
                    <i
                      className="fas fa-trash-alt text-007bff fs-16 fas-imp ml-4"
                      onClick={() => removeFile(fileUrlValue)}></i>
                  )}
                </span>
              )}
              {!isLoading && fileUrlValue && props.thumbnail && (
                <span className="ml-2 mt-2 fs-12 cursor-pointer d-flex align-items-center position-relative">
                  <img
                    onClick={e => showFilePreview(fileUrlValue, fileType)}
                    src={fileUrlValue}
                    title={fileName}
                    alt="thumbnail"
                    width={50}
                  />
                  {showCloseIcon && !props.disabled && (
                    <i
                      className="fas fa-times-circle text-007bff thumbnail-close fs-16 fas-imp ml-4 bg-white"
                      onClick={() => removeFile(fileUrlValue)}></i>
                  )}
                </span>
              )}
            </>
          }
        </div>
      </div>

      <Modal
        show={show}
        title={fieldKey === 'ngo_logo' ? 'Preview NGO Logo' : 'File Preview'}
        handleCloseModal={handleCloseModal}
        sizeClass={preview === true ? 'modal-md' : 'modal-lg'}>
        <div>
          {fileType === 'image' && (
            <div className="text-center mb-4">
              <img src={filePreview} alt="file" width={300}></img>
            </div>
          )}
          {fileType !== 'image' && fileType !== 'zip' && (
            <div style={{ height: '50vh' }}>
              <iframe
                src={fileType === 'doc' ? docFile : fileUrlValue}
                title="file"
                width="100%"
                height="95%"></iframe>
            </div>
          )}
          {fileType === 'zip' && (
            <div style={{ height: '50vh', textAlign: 'center' }}>
              <a href={fileUrlValue} download className="text-center">
                <img
                  src={zipIcon}
                  alt="fileName"
                  width={200}
                  title={fileName}></img>
                <div className="fs-16">Download zip files</div>
              </a>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
