import React from 'react';
import PageContentWrapper from '../components/pageContentWrapper';
import PageContent from '../components/pageContent';
import Header from '../components/header';
import NgoSupport from '../components/support/support';
import SupportTicketList from '../components/support/supportTicketList';
import SupportTicket from '../components/support/supportTicket';
import { Common } from '../constants';
import '../components/support/support.css';
import { Routes } from '../constants/path.constants';

class SupportScreen extends React.Component {
  getProjectMainScreenByPathName = ({ id }) => {
    const pathname = this.props.location.pathname;
    if (
      pathname.includes(Routes.NGO.SUPPORT) &&
      pathname.length === Routes.NGO.SUPPORT.length
    ) {
      return <NgoSupport history={this.props.history} />;
    }
    if (
      pathname.includes(Routes.NGO.SUPPORT_TICKET_LIST) &&
      pathname.length === Routes.NGO.SUPPORT_TICKET_LIST.length
    ) {
      return <SupportTicketList history={this.props.history} />;
    }
    if (pathname.includes(Routes.NGO.SUPPORT_TICKET)) {
      return <SupportTicket id={id} history={this.props.history} />;
    }
  };

  render() {
    let { id } = this.props.match.params;
    return (
      <React.Fragment>
        <Header pageTitle={Common.NGO.PAGE_TITLE.SUPPORT} />
        <PageContentWrapper>
          <PageContent
            mainScreen={this.getProjectMainScreenByPathName({ id })}
          />
        </PageContentWrapper>
      </React.Fragment>
    );
  }
}
export default SupportScreen;
