import React, { useEffect, useState } from 'react';

const CauseAreaMain = ({ causeArea }) => {
  const [causeAreaString, setcauseAreaString] = useState('');
  useEffect(() => {
    let str = '';
    function makeString() {
      for (let i = 0; i < causeArea.length; i++) {
        if (i < causeArea.length - 1) {
          str += `${causeArea[i]}, `;
        } else {
          str += `${causeArea[i]}`;
        }
      }
      //   console.log(str, 'str');
      //   console.log(str.slice(0, 50), 'str 50');
      setcauseAreaString(
        str.length < 30 ? str.slice(0, 35) : str.slice(0, 35) + '... '
      );
    }
    makeString();
  }, []);
  return <span style={{ marginRight: '5px' }}>{causeAreaString}</span>;
};

export default CauseAreaMain;
