import React, { useEffect, useState } from 'react';
export default function InputText(props) {
  const {
    uniqueIdentifierForInput,
    input,
    savedValue,
    disabled,
    setErrMsgsArr
  } = props;
  const [value, setValue] = useState('');
  const handleChange = e => {
    setErrMsgsArr([]);
    if (
      input.disableDependentOn !== '' &&
      input.dependentOnFieldType === 'checkbox'
    ) {
      if (e.target.value?.trim().length > 0) {
        document.getElementById(input.disableDependentOn).disabled = true;
      } else {
        document.getElementById(input.disableDependentOn).disabled = false;
      }
    }

    if (input.textContent === 'uppercase') {
      setValue(e.target.value.toUpperCase());
      return setValue(e.target.value.toUpperCase());
    } else if (
      input.type === 'number' &&
      e.target.value.toString().length <= input.maxLength
    ) {
      return setValue(e.target.value);
    }
  };

  useEffect(() => {
    if (value === '' && savedValue && savedValue !== '') {
      setValue(savedValue);
    }
  }, [savedValue]);

  var extraProps;
  extraProps = {
    name: uniqueIdentifierForInput,
    className: 'form-control',
    id: uniqueIdentifierForInput,
    defaultValue: savedValue,
    disabled: disabled
  };

  return (
    <>
      <label htmlFor={uniqueIdentifierForInput}>
        {input.label} {input.labelAppend && input.labelAppend}
        <span className="color-FF2300">
          {input.required ||
          (input.validation && input.validation === 'required') ||
          input.showrequired ||
          input.preOnboardRequiredField
            ? ' * '
            : ''}
        </span>
      </label>
      <input
        {...input}
        {...extraProps}
        id={uniqueIdentifierForInput}
        onChange={handleChange}
        value={value}
        // onKeyUp={() =>
        //   props.fieldUpdated && props.stepIndex >= 0
        //     ? props.fieldUpdated(props.stepIndex)
        //     : false
        // }
      />
    </>
  );
}
