import { Action } from '../constants';

const no_of_employees = [
  {
    id: '1',
    name: '1-10 employees'
  },
  {
    id: '2',
    name: '11-20 employees'
  },
  {
    id: '3',
    name: '21-50 employees'
  },
  {
    id: '4',
    name: '51-200 employees'
  },
  {
    id: '5',
    name: '201-500 employees'
  },
  {
    id: '6',
    name: '501-1000 employees'
  },
  {
    id: '7',
    name: '1001-5000 employees'
  }
];

export const getFullTimeEmployees = () => dispatch => {
  dispatch({
    type: Action.NO_OF_FULL_TIME_EMPLOYEES,
    payload: no_of_employees
  });
};
