import React from 'react';
import './modal.css';
import closeIcon from '../../../assets/images/close-button.png';

function Modal({
  show,
  title,
  handleCloseModal,
  formFieldExists,
  sizeClass,
  hideCloseBtn,
  children,
  hideCloseX,
  fixedPopup
}) {
  var modalId = '';
  const showHideClassName = show
    ? 'modal modal-overlay theme-modal d-flex align-items-center vh-100'
    : 'modal display-none';
  // let body = document.body;
  if (show === true) {
    // body.style.overflow = 'hidden';
    modalId = 'modal-overlay';
  } else {
    modalId = '';
  }
  return (
    <div
      className={showHideClassName}
      id={modalId}
      style={{ zIndex: 1 }}
      // onClick={
      //   formFieldExists
      //     ? ''
      //     : fixedPopup === true
      //     ? ''
      //     : e => {
      //         handleCloseModal(e);
      //       }
      // }
    >
      <div
        className={`modal-dialog ${sizeClass ? sizeClass : ' '}`}
        // style={{ minWidth: '50vw', maxWidth: '50vw' }}
        // style={{ maxWidth: '80vw', marginBottom: '70px' }}>
      >
        <div className="modal-content ">
          {!hideCloseX && (
            <div className="modal-header d-flex justify-content-between pt-4 pb-4">
              <span className="modal-title fs-20">{title}</span>
              {!hideCloseBtn && (
                <img
                  src={closeIcon}
                  alt="close"
                  className="close-button cursor-pointer"
                  onClick={e => handleCloseModal(e)}></img>
              )}
            </div>
          )}
          <div
            className="modal-body py-2"
            style={{ maxHeight: '70vh', overflowY: 'hidden' }}>
            <section>{children}</section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
