import React from 'react';

export default function ChartColorsDetails({
  data,
  colors,
  title,
  max,
  currencySymb
}) {
  const displayData = data.slice(0, max).map(({ label, value }) => {
    return { label, value };
  });
  if (data.length > max) {
    const othersValue = data
      .slice(max)
      .reduce((sum, { value }) => sum + value, 0);
    displayData.push({ label: 'Others', value: othersValue });
  }
  const handleTotalDonors = () => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum = sum + data[i].value;
    }
    return coammaSapertaedNumber(sum);
  };
  const coammaSapertaedNumber = number => {
    return new Intl.NumberFormat('en-IN').format(number);
  };
  const getColorForData = index => {
    const colorIndex = index % colors.length;
    return colors[colorIndex];
  };
  return (
    <div className={`mt-3`}>
      <p className="mt-2 fs-16 text-black-1">
        {title}: {currencySymb}{' '}
        <span className="ms-1">{handleTotalDonors()}</span>
      </p>
      <div className={``}>
        {displayData.map((itm, index) => {
          return (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between my-3">
              <span
                className={`color_box_donut_chart `}
                style={{ backgroundColor: `${getColorForData(index)}` }}></span>
              <div className="px-3 text-black-1">{itm.label}</div>
              <div className="text-1C1C1C fw-500">
                {currencySymb} {coammaSapertaedNumber(itm.value)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
