import React from 'react';
import Header from '../../components/common/preRegHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFcraPurpose } from '../../actions/common.actions';
import { getNgoProfile } from '../../actions/ngo.actions';
// import { Common } from '../../constants/common.constants';
import PreRegistrationForm from '../../components/preregistration/preRegistrationForm';
import { savePreRegistrationDetails } from '../../actions/preOnboarding.action';
import {
  getSavedNGOData,
  systemRecommendation
} from '../../actions/completeRegistration.action';

import '../../styles/custom.css';
function PreRegistrationScreen(props) {
  return (
    <React.Fragment>
      <style jsx>
        {`
          body,
          html {
            background-color: #ffffff;
          }
        `}
      </style>
      <div className="main-container">
        {props.state.ngo.ngoProfile.ngo_id && <Header />}
        <main className="content">
          <PreRegistrationForm {...props} />
        </main>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  state: state
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      savePreRegistrationDetails,
      getNgoProfile,
      getSavedNGOData,
      getFcraPurpose,
      systemRecommendation
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreRegistrationScreen);
