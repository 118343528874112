import React from 'react';
import Header from '../components/header';
import { Common } from '../constants/common.constants';
import PageContent from '../components/pageContent';
import PageContentWrapper from '../components/pageContentWrapper';

import View from '../components/progressReport';
class ProgressScreen extends React.Component {
  getAddProgress = () => {
    return (
      <View
        history={this.props.history}
        campaignId={this.props.match.params.campaignId}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <Header pageTitle={Common.NGO.PAGE_TITLE.PROJECT_PROGRESS_REPORT} />
        <PageContentWrapper>
          <PageContent mainScreen={this.getAddProgress()}></PageContent>
        </PageContentWrapper>
      </React.Fragment>
    );
  }
}

export default ProgressScreen;
