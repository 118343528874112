import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Header from '../components/header';
import { Common } from '../constants';
import PageContent from '../components/pageContent';
import PageContentWrapper from '../components/pageContentWrapper';
import ListCorporate from '../components/corporateList';
class CorporateScreen extends React.Component {
  getPageTitle = () => {
    return Common.NGO.PAGE_TITLE.LIST_CORPORATES;
  };
  getCorporateList = () => {
    return (
      <div className="corporate-list">
        <ListCorporate />
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        <Header pageTitle={this.getPageTitle()} />
        <PageContentWrapper>
          <PageContent mainScreen={this.getCorporateList()}></PageContent>
        </PageContentWrapper>
      </React.Fragment>
    );
  }
}
export default connect(null, null)(CorporateScreen);
