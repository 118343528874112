import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../constants/path.constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-hot-toast';
import { getSupporttickets } from '../../actions/support.actions';
import dayjs from 'dayjs';

class SupportTicketList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketList: [],
      sortDateBy: 'ASC',
      sortStatusBy: 'PENDING'
    };
  }
  convertDate = str => {
    if (str) {
      const dates = new Date(str.split(' ')[0]);
      const formattedDate = dates
        .toLocaleDateString('en-IN')
        .replace(/\//g, '-');
      return formattedDate;
    } else return '';
  };
  componentDidMount() {
    this.getSupportTickets();
  }
  getSupportTickets = () => {
    this.props
      .getSupporttickets()
      .then(res => {
        this.setState({ ticketList: res.data });
      })
      .catch(err => {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      });
  };

  sortDataByDate = (dataArr, type) => {
    let sortedList = JSON.parse(JSON.stringify(dataArr));
    if (dataArr.length > 1) {
      sortedList.sort((a, b) => {
        if (dayjs(a.created_on).isAfter(b.created_on)) {
          return type === 'ASC' ? 1 : -1;
        }
        if (dayjs(a.created_on).isBefore(dayjs(b.created_on))) {
          return type === 'ASC' ? -1 : 1;
        }
        return 0;
      });
    }
    return sortedList;
  };
  sortByDate = () => {
    let sortBy = this.state.sortDateBy === 'ASC' ? 'DESC' : 'ASC';
    let sortedList = this.sortDataByDate(this.state.ticketList, sortBy);
    this.setState({
      ticketList: sortedList,
      sortDateBy: sortBy
    });
  };
  sortByStatus = () => {
    let pendingArr = this.state.ticketList.filter(x => !x.resolved_on);
    let resolvedArr = this.state.ticketList.filter(x => !!x.resolved_on);
    pendingArr = this.sortDataByDate(pendingArr, 'DESC');
    resolvedArr = this.sortDataByDate(resolvedArr, 'DESC');
    if (this.state.sortStatusBy === 'RESOLVED') {
      this.setState({
        ticketList: pendingArr.concat(resolvedArr),
        sortStatusBy: 'PENDING'
      });
    } else {
      this.setState({
        ticketList: resolvedArr.concat(pendingArr),
        sortStatusBy: 'RESOLVED'
      });
    }
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Link to={Routes.NGO.SUPPORT} className="text-decoration-none">
              <h3 className="d-flex align-items-center mt-5 fw-600">
                <i className="fas fa-angle-left fs-36 color-888686 cursor-pointer"></i>
                <span className="d-inline-block mr-4 color-888686 font-weight-bold pl-4 cursor-pointer">
                  Back
                </span>
              </h3>
            </Link>
            <h3 className="d-flex align-items-center mb-4 fw-600 font-weight-bolder">
              Previous tickets
            </h3>
            <div className="bg-white p-4">
              <div className="row table-head mb-2">
                <div className="col-2 p-3">Ticket ID</div>
                <div className="col-6 p-3">Title</div>
                <div className="col-2 p-3">
                  Date
                  <img
                    className="pl-2 cursor-pointer"
                    src="/images/shuffle.png"
                    alt="Sort by date"
                    onClick={this.sortByDate}
                  />
                </div>
                <div className="col-2 p-3">
                  Status
                  <img
                    className="pl-2 cursor-pointer"
                    src="/images/shuffle.png"
                    alt="Sort by status"
                    onClick={this.sortByStatus}
                  />
                </div>
              </div>
              {this.state.ticketList.map((ticket, index) => {
                return (
                  <div className="row" key={index + ticket.id + ticket.title}>
                    <div className="col-2 px-3 py-2">{ticket.id}</div>
                    <div className="col-6 px-3 py-2 text-overflow-ellipsis">
                      {ticket.title}
                    </div>
                    <div className="col-2 px-3 py-2">
                      {' '}
                      {this.convertDate(ticket.created_on)}
                    </div>
                    <div
                      className={`col-1 px-3 pt-2 pb-3 ${
                        ticket.resolved_on ? 'text-81BC30' : 'text-E47E09'
                      }`}>
                      {ticket.resolved_on ? 'Resolved' : 'Pending'}
                    </div>
                    <div className="col-1">
                      <Link to={`/ngo/supportTicket/${ticket.id}`}>
                        <div className="view float-right"></div>
                      </Link>
                    </div>
                  </div>
                );
              })}
              {this.state.ticketList.length === 0 && (
                <div className="row">
                  <div className="col-12">
                    <h4 className="text-center my-3">
                      No previous tickets found
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  state: state
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSupporttickets
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportTicketList);
