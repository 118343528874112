import { toast } from 'react-hot-toast';
import { Action, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

export const onStepCompletion = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  if (window.location.href.includes('email=')) {
    let email = window.location.href.split('email=')[1];
    let apiUrl = EndPoint.getApiUrl('NGO', 'NGO_REGISTER', 'HIROKU');
    apiUrl = apiUrl + '?email=' + `${email}`;
    return instance
      .post(apiUrl, data)
      .then(response => {
        if (response.data.status !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(() => {
        toast.error('Something Went Wrong Please Try Again');
      });
  } else {
    return instance
      .post(EndPoint.getApiUrl('NGO', 'NGO_REGISTER', 'HIROKU'), data)
      .then(response => {
        if (response.data.status !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(() => {
        toast.error('Something Went Wrong Please Try Again');
      });
  }
};
export const existence = (data, type) => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');

  if (type === 'post')
    return instance
      .post(EndPoint.getApiUrl('NGO', 'EXISTENCE', 'HIROKU'), data)
      .then(response => {
        if (response.data.code !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(err => {
        toast.error('Something Went Wrong Please Try Again');
      });
  else {
    if (window.location.href.includes('email=')) {
      let email = window.location.href.split('email=')[1];
      let apiUrl = EndPoint.getApiUrl('NGO', 'EXISTENCE', 'HIROKU');
      apiUrl = apiUrl + '?email=' + `${email}`;
      return instance
        .put(apiUrl, data)
        .then(response => {
          if (response.data.code !== 500) {
            dispatch({
              type: Action.NGO_SAVED_DATA_SUCCESS,
              payload: response.data.result
            });
          } else {
            toast.error(response.data.message);
          }
          return response;
        })
        .catch(err => {
          toast.error('Something Went Wrong Please Try Again');
        });
    } else {
      return instance
        .put(EndPoint.getApiUrl('NGO', 'EXISTENCE', 'HIROKU'), data)
        .then(response => {
          if (response.data.code !== 500) {
            dispatch({
              type: Action.NGO_SAVED_DATA_SUCCESS,
              payload: response.data.result
            });
          } else {
            toast.error(response.data.message);
          }
          return response;
        })
        .catch(err => {
          toast.error('Something Went Wrong Please Try Again');
        });
    }
  }
};
export const legal_compliance = (data, type) => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');

  if (type === 'post') {
    if (window.location.href.includes('email=')) {
      let email = window.location.href.split('email=')[1];
      let apiUrl = EndPoint.getApiUrl('NGO', 'LEGAL_COMPLIANCE', 'HIROKU');
      apiUrl = apiUrl + '?email=' + `${email}`;
      return instance
        .post(apiUrl, data)
        .then(response => {
          if (response.data.code !== 500) {
            dispatch({
              type: Action.NGO_SAVED_DATA_SUCCESS,
              payload: response.data.result
            });
          } else {
            toast.error(response.data.message);
          }
          return response;
        })
        .catch(err => {
          toast.error('Something Went Wrong Please Try Again');
        });
    } else
      return instance
        .post(EndPoint.getApiUrl('NGO', 'LEGAL_COMPLIANCE', 'HIROKU'), data)
        .then(response => {
          if (response.data.code !== 500) {
            dispatch({
              type: Action.NGO_SAVED_DATA_SUCCESS,
              payload: response.data.result
            });
          } else {
            toast.error(response.data.message);
          }
          return response;
        })
        .catch(err => {
          toast.error('Something Went Wrong Please Try Again');
        });
  } else {
    if (window.location.href.includes('email=')) {
      let email = window.location.href.split('email=')[1];
      let apiUrl = EndPoint.getApiUrl('NGO', 'LEGAL_COMPLIANCE', 'HIROKU');
      apiUrl = apiUrl + '?email=' + `${email}`;
      return instance
        .put(apiUrl, data)
        .then(response => {
          if (response.data.code !== 500) {
            dispatch({
              type: Action.NGO_SAVED_DATA_SUCCESS,
              payload: response.data.result
            });
          } else {
            toast.error(response.data.message);
          }
          return response;
        })
        .catch(err => {
          toast.error('Something Went Wrong Please Try Again');
        });
    } else {
      return instance
        .put(EndPoint.getApiUrl('NGO', 'LEGAL_COMPLIANCE', 'HIROKU'), data)
        .then(response => {
          if (response.data.code !== 500) {
            dispatch({
              type: Action.NGO_SAVED_DATA_SUCCESS,
              payload: response.data.result
            });
          } else {
            toast.error(response.data.message);
          }
          return response;
        })
        .catch(err => {
          toast.error('Something Went Wrong Please Try Again');
        });
    }
  }
};

export const financial_capacity = (data, type) => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'FINANCIAL_CAPACITY', 'HIROKU');
  let email = '';
  if (window.location.href.includes('email=')) {
    email = window.location.href.split('email=')[1];
    apiUrl = apiUrl + '?email=' + `${email}`;
  }

  if (type === 'post') {
    return instance
      .post(apiUrl, data)
      .then(response => {
        if (response.data.code !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(err => {
        toast.error('Something Went Wrong Please Try Again');
      });
  } else
    return instance
      .put(apiUrl, data)
      .then(response => {
        if (response.data.code !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(err => {
        toast.error('Something Went Wrong Please Try Again');
      });
};
export const institutional_capacity = (data, type) => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'INSTITUTIONAL_CAPACITY', 'HIROKU');
  let email = '';
  if (window.location.href.includes('email=')) {
    email = window.location.href.split('email=')[1];
    apiUrl = apiUrl + '?email=' + `${email}`;
  }

  if (type === 'post')
    return instance
      .post(apiUrl, data)
      .then(response => {
        if (response.data.code !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(err => {
        toast.error('Something Went Wrong Please Try Again');
      });
  else
    return instance
      .put(apiUrl, data)
      .then(response => {
        if (response.data.code !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(err => {
        toast.error('Something Went Wrong Please Try Again');
      });
};
export const assessment = (data, type) => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'ASSESSMENT', 'HIROKU');
  let email = '';
  if (window.location.href.includes('email=')) {
    email = window.location.href.split('email=')[1];
    apiUrl = apiUrl + '?email=' + `${email}`;
  }

  if (type === 'post')
    return instance
      .post(apiUrl, data)
      .then(response => {
        if (response.data.code !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(err => {
        toast.error('Something Went Wrong Please Try Again');
      });
  else
    return instance
      .put(apiUrl, data)
      .then(response => {
        if (response.data.code !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(err => {
        toast.error('Something Went Wrong Please Try Again');
      });
};
export const social_activity = (data, type) => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'SOCIAL_ACTIVITY', 'HIROKU');
  let email = '';
  if (window.location.href.includes('email=')) {
    email = window.location.href.split('email=')[1];
    apiUrl = apiUrl + '?email=' + `${email}`;
  }
  if (type === 'post')
    return instance
      .post(apiUrl, data)
      .then(response => {
        if (response.data.code !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(err => {
        toast.error('Something Went Wrong Please Try Again');
      });
  else
    return instance
      .put(apiUrl, data)
      .then(response => {
        if (response.data.code !== 500) {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        } else {
          toast.error(response.data.message);
        }
        return response;
      })
      .catch(err => {
        toast.error('Something Went Wrong Please Try Again');
      });
};

export const consent = () => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');

  return instance
    .put(EndPoint.getApiUrl('NGO', 'CONSENT', 'HIROKU'))
    .then(response => {
      if (response.data.code !== 500) {
        dispatch({
          type: Action.NGO_SAVED_DATA_SUCCESS,
          payload: response.data.result
        });
      } else {
        toast.error(response.data.message);
      }
      return response;
    })
    .catch(err => {
      toast.error('Something Went Wrong Please Try Again');
    });
};

export const submitProfile = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'SUBMIT_PROFILE', 'HIROKU');
  let email = '';
  if (window.location.href.includes('email=')) {
    email = window.location.href.split('email=')[1];
    apiUrl = apiUrl + '?email=' + `${email}`;
  }
  return instance
    .post(apiUrl, data)
    .then(response => {
      if (response.data.code !== 500) {
        dispatch({
          type: Action.NGO_SAVED_DATA_SUCCESS,
          payload: response.data.result
        });
      } else {
        toast.error(response.data.message);
      }
      return response;
    })
    .catch(err => {
      toast.error('Something Went Wrong Please Try Again');
    });
};
export const getSavedNGOData = id => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  if (window.location.href.includes('email=')) {
    let email = window.location.href.split('email=')[1];

    return new Promise((resolve, reject) => {
      let apiUrl = EndPoint.getApiUrl('NGO', 'NGO_REGISTER', 'HIROKU');
      apiUrl = apiUrl + '?email=' + `${email}`;
      instance
        .get(apiUrl)
        .then(response => {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        })

        .catch(err => {
          reject(err);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      instance
        .get(`${EndPoint.getApiUrl('NGO', 'NGO_REGISTER', 'HIROKU')}?id=${id}`)
        .then(response => {
          dispatch({
            type: Action.NGO_SAVED_DATA_SUCCESS,
            payload: response.data.result
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export const updateNgoId = id => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');

  instance
    .post(EndPoint.getApiUrl('NGO', 'NGO_PROFILE', 'HIROKU'))
    .then(() => {});
};

export const systemRecommendation = id => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');

  instance
    .post(EndPoint.getApiUrl('NGO', 'SYSTEM_RECOMMENDATION', 'HIROKU'), {
      ngo_id: id
    })
    .then(response => {
      dispatch({
        type: Action.NGO_PREONBOARDING_SAVED_DATA_SUCCESS,
        payload: response.data
      });
    });
};

export const getSavedNGODataForAdmin = id => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  instance
    .get(`${EndPoint.getApiUrl('ADMIN', 'GET_NGO_DATA', 'HIROKU')}/${id}`)
    .then(response => {
      dispatch({
        type: Action.NGO_SAVED_DATA_SUCCESS,
        payload: response.data
      });
    });
};

export const verifyStep = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  instance
    .post(EndPoint.getApiUrl('ADMIN', 'VERIFY_NGO', 'HIROKU') + `/${data.id}`, {
      at_step: data.at_step
    })
    .then(response => {
      dispatch({
        type: Action.NGO_VERIFY_DATA,
        payload: response.data
      });
    });
};
export const rejectStep = () => () => {};

export const approveNgo = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(EndPoint.getApiUrl('ADMIN', 'VERIFY_NGO', 'HIROKU') + `/${data.id}`, {
      approved: true
    })
    .then(response => {
      dispatch({
        type: Action.NGO_VERIFY_DATA,
        payload: response.data
      });
      return response;
    })
    .catch(() => {
      toast.error(
        'Uh-Oh! Looks like we are having server issues. Please try again'
      );
    });
};
export const rejectCampaign = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(
      `${EndPoint.getApiUrl('NGO', 'REJECT_CAMPAIGN', 'HIROKU')}?campaignId=${
        data.campaignId
      }`,
      {
        note: data.note
      }
    )
    .then(response => {
      dispatch({
        type: Action.CAMPAIGN_REJECT,
        payload: response.data
      });
      return response;
    })
    .catch(() => {
      toast.error(
        'Uh-Oh! Looks like we are having server issues. Please Try Again'
      );
    });
};

export const approveCampaign = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(
      `${EndPoint.getApiUrl('NGO', 'APPROVE_CAMPAIGN', 'HIROKU')}?campaignId=${
        data.campaignId
      }`
    )
    .then(response => {
      dispatch({
        type: Action.CAMPAIGN_APPROVE,
        payload: response.data
      });
      return response;
    })
    .catch(() => {
      toast.error(
        'Uh-Oh! Looks like we are having server issues. Please Try Again'
      );
    });
};

export const shareCampaign = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(`${EndPoint.getApiUrl('NGO', 'SHARE_CAMPAIGN', 'HIROKU')}`, data)
    .then(response => {
      dispatch({
        type: Action.SHARE_CAMPAIGN,
        payload: response.data
      });
      return response;
    });
};

export const getCorpEmail = data => dispatch => {
  let instance = HttpRequest.getInstance('KEYCLOAK');
  return instance
    .post(
      `${EndPoint.getApiUrl('KEYCLOAK', 'GET_USER_DETAILS', 'KEYCLOAK')}`,
      data
    )
    .then(response => {
      dispatch({
        type: Action.SHARE_CAMPAIGN,
        payload: response.data
      });
      let list = response.data.map(corp => corp.email);
      return list;
    });
};
