import React from 'react';
// import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  getMasterData,
  getMasterDataById,
  getStatesDataById,
  getDistrictsDataById,
  createOrModifyMission,
  getMission,
  getNGOCorpForPublished,
  getNgoProfile
} from '../../actions';
import { getHeaderData } from '../../actions/ngo.actions';
import { defaults, forEach, map } from 'lodash';
import { InputText, InputSelect } from '../FormElements';
import InputRichTextEditor from '../richTextEditorInput';
import { Common } from '../../constants';
import { toast } from 'react-hot-toast';
import FileInput from '../fileInput';
import imageResolutionValidator from '../../helpers/imageResValidator';
import {
  restrictToNumber,
  eventToValue,
  getCloudinaryUrlForImage
} from '../../helpers/util';
import { connect } from 'react-redux';
import { validate } from './validate';
// import '../../styles/mission.css';
import MissionPreview from './preview';
import Modal from '../newDesign/Modals/Modal';
import FilterOptionsDropdownChecklist from '../filter/filterOptionsDropdownChecklist';
import MultiSelect from '../multifilter/corpSelect';
import { sendMail } from '../../helpers/emailSender';
var ngoEmail = window.location.href.split('email=')[1];
class CreateMission extends React.Component {
  constructor(props) {
    super(props);
    let ngoId =
      this.props.userProfile.orgId || this.props.userProfile.sub || null;
    this.state = {
      ngoId: ngoId,

      missionId: null,
      showPopUp: false,
      issueTypeList: [],
      publishedFor: [],
      pubSelected: [],
      pubSelectedError: '',
      showPreview: false,
      enableSaveBtn: true,
      missionDetails: {},
      disable_campaign_content_save_button: true,
      currentMissionStatus: '',
      formFields: {
        missionType: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'mission_type'
        },
        missionTitle: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'mission_title'
        },
        primaryCauseArea: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'cause_category_id'
        },
        secondaryCauseArea: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'sub_cause_category_id'
        },
        targetBeneficiaries: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'target_beneficiary',
          dependentOnKey: 'missionType',
          dependentOnValue: 'mission'
        },
        sdg: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'sdg',
          dependentOnKey: 'missionType',
          dependentOnValue: 'mission'
        },
        missionLocation: {
          isValid: true,
          value: [
            {
              id: null,
              missionCountry: {
                isValid: false,
                value: 101,
                error: '',
                jsonField: 'country'
              },
              missionState: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'state'
              },
              missionDistrict: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'city'
              },
              pinCode: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'pincode'
              }
            }
          ],
          error: '',
          jsonField: 'mission_location'
        },
        rolloverImage: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'image_url'
        },
        gallery: {
          isValid: false,
          value: [],
          error: '',
          jsonField: 'gallery'
        },
        story: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'story'
        },
        activities: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'activities'
        },
        roadMap: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'roadmap'
        }
      },
      deletedLocationArr: [],
      showCancelModal: false,
      refreshRTE: false,
      secondaryRefresh: false
    };
    this.googleAPIKey = 'AIzaSyDxc9HLGnlXL0JWiftdZaVEv5quIAmTsyo';
    this.editDetailReceived = false;
    this.fetchingPreview = false;
  }

  setFormErrorsAndValue = (event, callback, type, childCallBack) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;
    if (!type) {
      value = eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    let formFields = {};
    const errorResult = validate(id, value);
    formFields[id] = {
      isValid: errorResult.isFieldValid,
      error: errorResult.msg,
      value,
      jsonField: this.state.formFields[id].jsonField
    };
    formFields = defaults({}, formFields, this.state.formFields);
    this.setState({ ...this.state, formFields }, () => {
      if (typeof callback === 'function') {
        callback();
      }
      if (typeof childCallBack === 'function') {
        childCallBack();
      }
    });
  };

  onHandleChange = (event, callback, type, childCallback, newValue) => {
    this.setFormErrorsAndValue(event, callback, type, childCallback, newValue);
    this.setState({ disable_campaign_content_save_button: false });
  };
  onHandleBlur = (event, callback, type) => {
    this.setFormErrorsAndValue(event, callback, type);
  };

  setFormValidStatus = () => {
    const self = this;
    const formInvalidFields = map(self.state.formFields, value => {
      return value.isValid === false;
    });
    self.setState({
      formValid: formInvalidFields.some(value => {
        return value === true;
      })
        ? false
        : true
    });
  };

  fillSecondaryCauseList = () => {
    this.props.getMasterDataById(this.state.formFields.primaryCauseArea.value);
    /* console.log('fillSecondaryCauseList() >> ', this.state.formFields.secondaryCauseArea.value);
    if (Array.isArray(this.state.formFields.secondaryCauseArea.value) && this.state.formFields.secondaryCauseArea.value.length) {
      let formFields = {};
      formFields['secondaryCauseArea'] = {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'sub_cause_category_id'
      };
      formFields = defaults({}, formFields, this.state.formFields);
      this.setState({ ...this.state, formFields });
    } */
  };

  onUploadSuccess = (fileUrl, event) => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        [event.target.id]: {
          isValid: true,
          error: '',
          value: fileUrl,
          jsonField: this.state.formFields[event.target.id].jsonField
        }
      },
      disable_campaign_content_save_button: false
    });
  };
  onUploadSuccessGallery = (fileUrl, event) => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        [event.target.id]: {
          isValid: true,
          error: '',
          value: [...this.state.formFields.gallery.value, fileUrl],
          jsonField: this.state.formFields[event.target.id].jsonField
        }
      },
      disable_campaign_content_save_button: false
    });
  };

  deleteRollOverImage = () => {
    let formFields = this.state.formFields;
    formFields.rolloverImage.value = '';
    this.setState({ formFields: formFields });
  };
  deleteGalleryImage = image => {
    let gallaryArr = this.state.formFields.gallery.value.filter(item => {
      return item !== image;
    });
    this.setState({
      formFields: {
        ...this.state.formFields,
        gallery: {
          isValid: true,
          error: '',
          value: gallaryArr,
          jsonField: this.state.formFields['gallery'].jsonField
        }
      },
      disable_campaign_content_save_button: false
    });
  };

  setLocationArrValue = (state, city, name, target, value, index) => {
    let locationsArray = this.state.formFields.missionLocation.value;
    const errorResult = validate(target.id, value);
    locationsArray[index][name].isValid = errorResult.isFieldValid;
    locationsArray[index][name].error = errorResult.msg;
    locationsArray[index].missionState.value = state;
    locationsArray[index].missionDistrict.value = city ? city : '';
    this.setState({
      ...this.state.formFields,
      missionLocation: locationsArray
    });
  };

  setLocationValue = (index, event, callback, type, childCallback) => {
    const { target } = event;
    const name = target.name;
    const _this = this;
    const value = parseInt(target.value);
    let stateFinalVal = '';
    let cityFinalVal = '';
    let cityVal = '';
    let locationsArray = this.state.formFields.missionLocation.value;
    locationsArray[index][name].value = value;
    this.setState({
      ...this.state.formFields,
      missionLocation: locationsArray
    });

    var request = new XMLHttpRequest();
    var method = 'GET';
    var url =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      value +
      '&sensor=true&key=' +
      this.googleAPIKey;
    var async = true;

    request.open(method, url, async);
    request.onreadystatechange = function() {
      if (request.readyState === 4 && request.status === 200) {
        var data = JSON.parse(request.responseText);
        var address = data.results[0]?.formatted_address?.split(',');
        var address_components = data.results[0]?.address_components;
        if (data.results.length > 0) {
          if (address.length < 3) {
            cityVal = address_components[1]?.long_name;
            stateFinalVal = address[address.length - 1]?.replace(/[0-9]/g, '');
            cityFinalVal = cityVal?.replace(/[0-9]/g, '');
            document.getElementsByName('missionDistrict')[
              index
            ].value = cityVal;
          } else {
            cityVal = address[address.length - 3].split(' ');
            stateFinalVal = address[address.length - 2]?.replace(/[0-9]/g, '');
            cityFinalVal = cityVal[cityVal.length - 1]?.replace(/[0-9]/g, '');
            document.getElementsByName('missionDistrict')[
              index
            ].value = cityVal[cityVal.length - 1].replace(/[0-9]/g, '');
          }
          document.getElementsByName('missionState')[index].value = address[
            address.length - 2
          ]?.replace(/[0-9]/g, '');
          document.getElementsByName('missionState')[
            index
          ].value = stateFinalVal;
        }
      } else {
        document.getElementsByName('missionState')[index].value = '--';
        document.getElementsByName('missionDistrict')[index].value = '--';
      }

      _this.setLocationArrValue(
        stateFinalVal,
        cityFinalVal,
        name,
        target,
        value,
        index
      );
    };
    request.send();

    // this.enableSaveButton();
  };

  handleChangeCity = (index, field, value) => {
    const updatedMissionLocation = { ...this.state.formFields.missionLocation };
    const updatedLocation = { ...updatedMissionLocation.value[index] };
    updatedLocation[field].value = value;
    updatedMissionLocation.value[index] = updatedLocation;
    this.setState({
      ...this.state.formFields,
      missionLocation: updatedMissionLocation
    });
  };
  addLocation = () => {
    let formFields = this.state.formFields;
    const obj = {
      id: null,
      missionCountry: {
        isValid: false,
        value: 101,
        error: '',
        jsonField: 'country'
      },
      missionState: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'state'
      },
      missionDistrict: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'city'
      },
      pinCode: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'pincode'
      }
    };
    formFields.missionLocation.value.push(obj);
    this.setState({
      formFields: formFields
    });
    this.setState({
      disable_campaign_content_save_button: false
    });
  };
  deleteLocation = index => {
    let formFields = this.state.formFields;
    let delLocArr = this.state.deletedLocationArr;
    if (formFields.missionLocation.value[index].id)
      delLocArr.push(formFields.missionLocation.value[index]);
    formFields.missionLocation.value.splice(index, 1);
    this.setState({
      formFields: formFields,
      deletedLocationArr: delLocArr,
      disable_campaign_content_save_button: false
    });
  };

  comgetNGOCorpForPublished = (ngoId, emailId, isEv) => {
    this.props
      .getNGOCorpForPublished(ngoId, emailId)
      .then(res => {
        if (res.code === '200') {
          this.setState(prevState => {
            return {
              publishedFor: Object.keys(res?.result)?.length
                ? isEv
                  ? [
                      {
                        name: 'Everyone',
                        id: 'everyone',
                        is_condtional: false
                      },
                      ...res.result.final_cond_list
                    ]
                  : [...res.result.final_cond_list]
                : [...prevState.publishedFor]
            };
          });
        }
      })
      .catch(e => {});
  };

  setRTEValueAndError = (id, value, callback) => {
    if (this.state.formFields[id].value !== value) {
      let formFields = {};
      const errorResult = validate(id, value);
      formFields[id] = {
        isValid: errorResult.isFieldValid,
        error: errorResult.msg,
        value,
        jsonField: this.state.formFields[id].jsonField
      };
      formFields = defaults({}, formFields, this.state.formFields);
      this.setState({ ...this.state, formFields }, () => {
        if (typeof callback === 'function') {
          callback();
        }
      });
      this.setState({ disable_campaign_content_save_button: false });
      // this.enableSaveButton();
    }
  };

  previewMission = () => {
    this.setState({ showPreview: true });
  };
  // onPreview = () => {
  //   this.setState({ showPreview: true });
  // };
  onClosePreview = () => {
    this.setState({ showPreview: false });
    this.setFetchPreview(false);
  };
  enablePreviewButton = () => {
    this.setState({
      disable_preview_button: false
    });
  };
  setFetchPreview = value => {
    this.fetchingPreview = value;
  };

  checkValidation = () => {
    const { formFields } = this.state;
    let errorExists = false;
    const errorResultForPublished = validate(
      'publishedFor',
      this.state.pubSelected
    );

    //this.setState({ pubSelectedError: errorResultForPublished.msg });
    if (errorResultForPublished.msg !== '' && !errorExists) {
      errorExists = true;
    }
    for (let field in formFields) {
      if (field !== 'missionLocation') {
        const cond1 = !formFields[field].dependentOnKey;
        const cond2 =
          formFields[field].dependentOnKey &&
          formFields[field].dependentOnValue &&
          formFields[formFields[field].dependentOnKey].value ===
            formFields[field].dependentOnValue;
        const cond3 =
          (formFields.missionType.value === '' ||
            formFields.missionType.value === 'mission') &&
          (field === 'targetBeneficiaries' || field === 'sdg');
        if (cond1 || cond2 || cond3) {
          const errorResult = validate(field, formFields[field].value);
          if (errorResult.msg !== '' && !errorExists) {
            errorExists = true;
          }
          const obj = {
            isValid: errorResult.isFieldValid,
            error: errorResult.msg,
            value: formFields[field].value,
            jsonField: formFields[field].jsonField
          };
          if (formFields[field].dependentOnKey) {
            obj['dependentOnValue'] = formFields[field].dependentOnValue;
            obj['dependentOnKey'] = formFields[field].dependentOnKey;
          }
          formFields[field] = obj;
        }
      } else {
        const { missionLocation } = formFields;
        let locErrExists = false;
        missionLocation.value.map((locObject, index) => {
          for (let locField in locObject) {
            if (locField !== 'id') {
              const locErrorResult = validate(
                locField,
                locObject[locField]?.value
              );
              if (locErrorResult.msg !== '' && !locErrExists) {
                locErrExists = true;
              }
              if (locField !== 'is_deleted')
                locObject[locField] = {
                  isValid: locErrorResult.isFieldValid,
                  error: locErrorResult.msg,
                  value: locObject[locField].value,
                  jsonField: locObject[locField].jsonField
                };
            }
          }
          return false;
        });
        if (locErrExists) errorExists = true;
        formFields['missionLocation'] = missionLocation;
        // return errorExists;
      }
    }
    this.setState({
      ...this.state
    });
    this.setState(prevState => {
      return {
        ...prevState,
        pubSelectedError: errorResultForPublished.msg,
        formFields: formFields
      };
    });

    return errorExists;
  };

  getJson = status => {
    let dataToSave = {};
    forEach(this.state.formFields, obj => {
      if (
        obj.jsonField === 'sub_cause_category_id' ||
        (obj.jsonField === 'mission_type' &&
          dataToSave[obj.jsonField] === 'disaster' &&
          !(obj.jsonField === 'sdg')) ||
        (obj.jsonField === 'mission_type' &&
          dataToSave[obj.jsonField] === 'mission' &&
          obj.jsonField === 'sdg')
      ) {
        dataToSave[obj.jsonField] = obj.value.map(Number);
      } else if (obj.jsonField === 'mission_location') {
        let locationArr = [];
        obj.value.map(loc => {
          const object = {
            country: Number(loc.missionCountry.value),
            state: loc.missionState.value.trim(),
            city: loc.missionDistrict.value.trim(),
            pincode: Number(loc.pinCode.value)
          };
          if (loc.id) object.location_id = loc.id;
          locationArr.push(object);
        });
        dataToSave[obj.jsonField] = locationArr;
      } else {
        dataToSave[obj.jsonField] = obj.value;
      }
    });
    if (this.state.pubSelected.length) {
      if (
        this.state.pubSelected.length === 1 &&
        this.state.pubSelected[0] === 'everyone'
      ) {
        dataToSave['corp_list'] = [];
        dataToSave['is_public'] = true;
      } else {
        dataToSave['corp_list'] = this.state.pubSelected;
        dataToSave['is_public'] = false;
      }
    }
    dataToSave['status'] = status;
    dataToSave['ngo_id'] = this.state.ngoId;
    dataToSave['is_new_project'] = true;
    dataToSave['is_published'] = true;
    dataToSave['tnc_accepted'] = true;
    if (this.state.missionId) dataToSave['mission_id'] = this.state.missionId;
    return dataToSave;
  };

  apiErrorToast = () => {
    toast.error("Oops! Looks like we couldn't process your request.");
  };

  onSave = saveAndClose => {
    const errorExists = this.checkValidation();
    if (errorExists) {
      toast.error(`Error(s) found! Correct the error(s) and try saving again.`);
      return false;
    }

    this.props
      .createOrModifyMission(
        this.getJson(
          window.location.href.includes('email')
            ? this.state.currentMissionStatus === '' ||
              this.state.currentMissionStatus === undefined ||
              this.state.currentMissionStatus === null
              ? Common.NGO.MISSION_STATUS.DRAFT
              : this.state.currentMissionStatus
            : Common.NGO.MISSION_STATUS.DRAFT
        )
      )
      .then(res => {
        if (res.code && res.code !== '200') {
          this.apiErrorToast();
          return false;
        }
        if (
          !this.state.missionId &&
          !this.props.id &&
          !this.props.isModifyMission
        ) {
          toast.success(`Mission created successfully.`);
          setTimeout(() => {
            if (window.location.href.includes('email')) {
              this.props.history.push(
                '/ngo/mission/modify/' +
                  res.result[0].mission_id +
                  '?email=' +
                  ngoEmail
              );
            } else {
              this.props.history.push(
                '/ngo/mission/modify/' + res.result[0].mission_id
              );
            }
          }, 3000);
        } else {
          toast.success(`Mission saved successfully.`);
          if (saveAndClose) {
            setTimeout(() => {
              if (window.location.href.includes('email')) {
                this.props.history.push(
                  '/ngo/missions/list' + '?email=' + ngoEmail
                );
              } else {
                this.props.history.push('/ngo/missions/list');
              }
            }, 3000);
          }
        }
        this.setState({
          disable_campaign_content_save_button: true
        });
      })
      .catch(() => {
        this.apiErrorToast();
      });
    // this.setState({
    //   disable_campaign_content_save_button: true
    // });
  };

  onSubmit = () => {
    const errorExists = this.checkValidation();
    if (errorExists) {
      toast.error(
        `Error(s) found! Correct the error(s) and try submitting again.`
      );
      return false;
    }
    this.props
      .createOrModifyMission(
        this.getJson(
          window.location.href.includes('email')
            ? this.state.currentMissionStatus === '' ||
              this.state.currentMissionStatus === undefined ||
              this.state.currentMissionStatus === null ||
              this.state.currentMissionStatus === 'DRAFT'
              ? Common.NGO.MISSION_STATUS.UNDER_REVIEW
              : this.state.currentMissionStatus
            : Common.NGO.MISSION_STATUS.UNDER_REVIEW
        )
      )
      .then(res => {
        if (res.code && res.code !== '200') {
          this.apiErrorToast();
          return false;
        } else {
          toast.success(`Mission submitted successfully.`);
          sendMail({
            templateName: 'missionPublishRequestSent',
            sendTo: this.props.userInfo.email,
            subject: 'New Mission Publish Request Received',
            templateData: {
              mission_name: this.state.missionDetails.mission_title,
              ngo_name: this.state.missionDetails.ngo_name,
              missionLink: `https://${process.env.REACT_APP_ENV}npo.mettasocial.com/ngo/mission/details/${this.state.missionDetails.mission_id}`
            }
          });
          sendMail({
            templateName: 'missionPublishAlertToAdmin',
            sendTo: 'ngo.partnerships@mettasocial.com',
            subject: 'New Mission Publish Request Received',
            templateData: {
              mission_name: this.state.missionDetails.mission_title,
              ngo_name: this.state.missionDetails.ngo_name
            }
          });
          // REDIRECT HERE
          if (window.location.href.includes('email')) {
            this.props.history.push('/ngo/missions/list?email=' + ngoEmail);
          } else {
            this.props.history.push('/ngo/missions/list');
          }
        }
      })
      .catch(() => {
        this.apiErrorToast();
      });
  };
  setCorp = list => {
    const sCorps = list?.map(cor => cor.id);
    if (sCorps) this.setState({ pubSelected: [...sCorps] });
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (
      nextprops.editMissionSuccessResponse &&
      nextprops.editMissionSuccessResponse.code !== null &&
      !this.editDetailReceived &&
      this.props.isModifyMission
    ) {
      let missionInfo = nextprops.editMissionSuccessResponse;
      let missionFormDetails = this.state.formFields;

      forEach(missionFormDetails, obj => {
        if (obj.jsonField !== 'mission_location') {
          obj.value =
            missionInfo[obj.jsonField] !== null
              ? missionInfo[obj.jsonField]
              : '';
          obj.isValid = obj.value !== null ? true : false;
        }
      });

      if (missionInfo.locations && missionInfo.locations.length > 0) {
        let locationsArr = [];
        missionInfo.locations.map(loc => {
          const obj = {
            id: loc.id,
            missionCountry: {
              isValid: false,
              value: loc.country,
              error: '',
              jsonField: 'country'
            },
            pinCode: {
              isValid: false,
              value: loc.pincode,
              error: '',
              jsonField: 'pincode'
            },
            missionState: {
              isValid: false,
              value: loc.state,
              error: '',
              jsonField: 'state'
            },
            missionDistrict: {
              isValid: false,
              value: loc.city,
              error: '',
              jsonField: 'city'
            }
          };
          locationsArr.push(obj);
          return false;
        });
        missionFormDetails.missionLocation.value = locationsArr;
      }
      this.editDetailReceived = true;
      this.setState(
        {
          ...this.state,
          formFields: missionFormDetails,
          missionId: missionInfo.mission_id,
          // fundAmount: missionInfo.fund_amount,
          currentMissionStatus: missionInfo['status'],
          missionDetails: missionInfo
        },
        () => {
          this.fillSecondaryCauseList();
          this.setState(
            { ...this.state, refreshRTE: true, secondaryRefresh: true },
            () => {
              this.setState({
                ...this.state,
                refreshRTE: false,
                secondaryRefresh: false
              });
            }
          );
          if (missionInfo.is_public) {
            this.setState({
              pubSelected: ['everyone'],
              publishedFor: [
                {
                  name: 'Everyone',
                  id: 'everyone',
                  is_condtional: false
                }
              ]
            });
          } else {
            this.setCorp(missionInfo.corp_list);
          }
        }
      );
    } else if (
      !this.editDetailReceived &&
      nextprops.editMissionErrorResponse &&
      nextprops.editMissionErrorResponse.code !== null
    ) {
      this.editDetailReceived = true;
      this.setState({ showLoader: false }, () => {
        toast.dismiss();
      });
    }

    if (nextprops?.isCreateMission === true) {
      const {
        l1_status: currentL1Status,
        l2_status: currentL2Status,
        l0_status: currentL0Status
      } = this.props.profileCompleted;

      const {
        l1_status: nxtL1Status,
        l2_status: nxtL2Status,
        l0_status: nxtL0Status
      } = nextprops.profileCompleted;
      const isStatusApproved =
        nxtL1Status === 'l1_approved' ||
        nxtL2Status === 'l2_approved' ||
        nxtL0Status === 'l0_approved';

      if (ngoEmail !== '' && ngoEmail !== undefined) {
        this.comgetNGOCorpForPublished(null, ngoEmail, isStatusApproved);
      } else if (this.state.ngoId) {
        this.comgetNGOCorpForPublished(
          this.state.ngoId,
          null,
          isStatusApproved
        );
      }

      if (!nextprops.profileCompleted.is_conditionally_approved) {
        this.setState({
          publishedFor: [
            {
              name: 'Everyone',
              id: 'everyone',
              is_condtional: false
            }
          ]
        });
      }

      if (
        currentL1Status !== nxtL1Status ||
        currentL2Status !== nxtL2Status ||
        currentL0Status !== nxtL0Status
      ) {
        if (isStatusApproved) {
          this.setState({
            pubSelected: ['everyone']
          });
        }
      }
    }
  }

  showHideCancelModal = value => {
    this.setState({ showCancelModal: value });
  };

  exitWithoutSave = () => {
    if (window.location.href.includes('email')) {
      // this.props.history.push('/ngo/missions/list' + '?email=' + ngoEmail);
      window.location.href = '/ngo/missions/list' + '?email=' + ngoEmail;
    } else {
      // this.props.history.push('/ngo/missions/list');
      window.location.href = '/ngo/missions/list';
    }
  };

  handleMultiSelection = (value, type) => {
    let formFields = {};
    const errorResult = validate(type, value);
    formFields[type] = {
      isValid: errorResult.isFieldValid,
      error: errorResult.msg,
      value,
      jsonField: this.state.formFields[type].jsonField
    };
    formFields = defaults({}, formFields, this.state.formFields);
    this.setState({ ...this.state, formFields });
    this.setState({ disable_campaign_content_save_button: false });
  };

  componentDidMount() {
    const {
      l1_status: currentL1Status,
      l2_status: currentL2Status,
      l0_status: currentL0Status
    } = this.props.profileCompleted;
    const isStatusApproved =
      currentL1Status === 'l1_approved' ||
      currentL2Status === 'l2_approved' ||
      currentL0Status === 'l0_approved';

    if (isStatusApproved) {
      this.setState({
        publishedFor: [
          {
            name: 'Everyone',
            id: 'everyone',
            is_condtional: false
          }
        ],
        pubSelected: ['everyone']
      });
    }

    const masterDataOptions = Common.NGO.MASTER_OPTIONS;
    this.props.getMasterData([
      masterDataOptions.PRIMARY_BENEFICIARIES,
      masterDataOptions.CAUSE_CATEGORY,
      masterDataOptions.SDG
    ]);
    // if (ngoEmail !== '' && ngoEmail !== undefined) {
    //   this.comgetNGOCorpForPublished(null, ngoEmail, isStatusApproved);
    // } else if (this.state.ngoId) {
    //   this.comgetNGOCorpForPublished(this.state.ngoId, null, isStatusApproved);
    // }
    if (this.props.id && this.props.isModifyMission) {
      this.props.getMission(this.props.id, 'edit');
    }

    this.props.getHeaderData().then(data => {
      const {
        name,
        l1_status,
        l2_status,
        l0_status,
        is_conditional
      } = data.result;

      this.setState({
        ngoName: name,
        isConditionalState: is_conditional,
        isL0State: l0_status,
        isL1State: l1_status,
        isL2State: l2_status
      });
      const isStatusApprovedNew =
        l0_status === 'l0_approved' ||
        l1_status === 'l1_approved' ||
        l2_status === 'l2_approved';
      if (this.state.ngoId) {
        this.comgetNGOCorpForPublished(
          this.state.ngoId,
          null,
          isStatusApproved || isStatusApprovedNew
        );
      } else if (ngoEmail !== '' && ngoEmail !== undefined) {
        this.comgetNGOCorpForPublished(
          null,
          ngoEmail,
          isStatusApproved || isStatusApprovedNew
        );
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      l1_status: currentL1Status,
      l2_status: currentL2Status,
      l0_status: currentL0Status
    } = this.props.profileCompleted;

    const {
      l1_status: prevL1Status,
      l2_status: prevL2Status,
      l0_status: prevL0Status
    } = prevProps.profileCompleted;

    const isStatusApproved =
      currentL1Status === 'l1_approved' ||
      currentL2Status === 'l2_approved' ||
      currentL0Status === 'l0_approved';

    if (
      currentL1Status !== prevL1Status ||
      currentL2Status !== prevL2Status ||
      currentL0Status !== prevL0Status
    ) {
      this.comgetNGOCorpForPublished(this.state.ngoId, null, isStatusApproved);
      if (isStatusApproved) {
        this.setState({
          publishedFor: [
            {
              name: 'Everyone',
              id: 'everyone',
              is_condtional: false
            }
          ]
        });
      }
    }
  }

  createMainHtml = () => {
    const isMissionUnderReview =
      this.state.currentMissionStatus ===
      Common.NGO.MISSION_STATUS.UNDER_REVIEW;
    const missionType = [
      { id: 'mission', name: 'Regular Mission' },
      { id: 'disaster', name: 'Disaster' }
    ];
    const countryList = [{ id: 101, name: 'India' }];
    const primaryCauseArea = this.props.masterInfo
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.CAUSE_CATEGORY]
      : null;
    const secondaryCauseArea = this.props.masterSecondaryInfo
      ? this.props.masterSecondaryInfo[
          Common.NGO.MASTER_OPTIONS.SUB_CAUSE_CATEGORY
        ]
      : null;
    const targetBeneficiaries =
      this.props.masterInfo && !this.state.secondaryRefresh
        ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.PRIMARY_BENEFICIARIES]
        : null;
    const sdg =
      this.props.masterInfo && !this.state.secondaryRefresh
        ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.SDG]
        : null;

    const handleCloseModal = e => {
      e.preventDefault();
      this.props.history.push('/ngo/profile');
    };

    return (
      <>
        {''}
        {(this.props.isCreateMission || this.props.isModifyMission) &&
          (this.props.profileCompleted?.is_conditionally_approved === false
            ? this.props.profileCompleted?.l0_status === 'new' ||
              this.props.profileCompleted?.l0_status === 'l0_rejected' ||
              this.props.profileCompleted?.l0_status ===
                'modification_required' ||
              this.props.profileCompleted?.l0_status === 'submitted'
            : this.props.profileCompleted?.is_conditionally_approved ===
              false) && (
            <Modal
              show={true}
              title={'Create Mission'}
              handleCloseModal={handleCloseModal}
              sizeClass="modal-hw">
              <p className="my-2 fs-18 font-weight-normal">
                You can create missions only after your profile is completed and
                approved. Please wait while we approve your profile if you have
                already submitted it or{' '}
                <button
                  className="btn-link btn btn-lg p-0 fs-18 font-weight-normal text-normal text-lowercase"
                  onClick={() => {
                    this.props.history.push('/ngo/profile');
                  }}>
                  Click Here To Complete Profile
                </button>
              </p>
            </Modal>
          )}
        <div className={`row mt-4 mb-5`}>
          <div className="col-8 mx-auto mb-5">
            <div className={`col-12 px-0 py-4 bg-white`}>
              <div className="row justify-content-between px-4 mb-4">
                <h3 className="fs-16 fw-400">Mission Details</h3>
              </div>
              <div className="bg_EFF9FF ">
                <div className="form-group required col-6 p-4">
                  <MultiSelect
                    labelText={'This Mission is to be published for'}
                    required={true}
                    ngoStatus={
                      this.props.profileCompleted?.is_conditionally_approved
                    }
                    areaOptions={this.state.publishedFor}
                    selectedData={this.state.pubSelected}
                    fieldUpdated={data => {
                      this.setState({
                        pubSelected: data
                      });
                    }}
                    isError={this.state.pubSelectedError}
                    permissions={this.props.permissions}
                  />
                </div>
              </div>
              <div className="row px-4 pt-2 bg-EFF9FF">
                <InputSelect
                  required={true}
                  labelText="Select Mission Type"
                  id="missionType"
                  name="missionType"
                  options={missionType}
                  fieldValue={this.state.formFields.missionType.value}
                  fieldError={this.state.formFields.missionType.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  // dataType="num"
                  disableInput={false}
                  wrapperClassName={'col-6 pl-0'}
                  permissions={this.props.permissions}
                />
              </div>
              <div className="row pt-2 px-4">
                <InputText
                  required={true}
                  type={'text'}
                  labelText="Mission Title"
                  id="missionTitle"
                  name="missionTitle"
                  fieldError={this.state.formFields.missionTitle.error}
                  fieldValue={this.state.formFields.missionTitle.value}
                  PlaceHolder={'Maximum Length is 300 Characters'}
                  wrapperClassName={'col-12 pl-0 pr-0'}
                  maxLength={300}
                  disableInput={false}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  permissions={this.props.permissions}
                />
                <InputSelect
                  required={true}
                  labelText="Primary Cause Area"
                  id="primaryCauseArea"
                  name="primaryCauseArea"
                  options={primaryCauseArea}
                  dataType="num"
                  fieldValue={this.state.formFields.primaryCauseArea.value}
                  fieldError={this.state.formFields.primaryCauseArea.error}
                  wrapperClassName={'col-6 pl-0'}
                  disableInput={false}
                  parentElement={true}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  childCallBack={this.fillSecondaryCauseList}
                  permissions={this.props.permissions}
                />
                <FilterOptionsDropdownChecklist
                  label={`Secondary Cause Area(s)`}
                  title={'Secondary Cause Area'}
                  placeHolder={'Please select'}
                  id={'secondaryCauseArea'}
                  name={`secondaryCauseArea`}
                  fieldValue={this.state.formFields.secondaryCauseArea.value}
                  fieldError={this.state.formFields.secondaryCauseArea.error}
                  options={secondaryCauseArea}
                  wrapperClass={'col-6 pr-0 ml-0-imp form-group'}
                  btnTextClass={'fieldCheckList'}
                  showTags={true}
                  fieldHeight={true}
                  hideFooter={true}
                  required={true}
                  disabled={!this.state.formFields.primaryCauseArea.value}
                  popUpToRight={true}
                  handleChange={this.handleMultiSelection}
                  permissions={this.props.permissions}
                />
                {(this.state.formFields.missionType.value === '' ||
                  !this.state.formFields.missionType.value ||
                  this.state.formFields.missionType.value === 'mission') && (
                  /* !this.state.secondaryRefresh && */ <>
                    <FilterOptionsDropdownChecklist
                      label={'Target Beneficiaries'}
                      title={'Target Beneficiary'}
                      placeHolder={'Please select'}
                      id={'targetBeneficiaries'}
                      name={`targetBeneficiaries`}
                      fieldValue={
                        this.state.formFields.targetBeneficiaries.value
                      }
                      fieldError={
                        this.state.formFields.targetBeneficiaries.error
                      }
                      options={targetBeneficiaries}
                      wrapperClass={'col-6 pl-0 ml-0-imp form-group'}
                      btnTextClass={'fieldCheckList'}
                      showTags={true}
                      fieldHeight={true}
                      hideFooter={true}
                      required={true}
                      handleChange={this.handleMultiSelection}
                      permissions={this.props.permissions}
                    />
                    <FilterOptionsDropdownChecklist
                      label={'Sustainable Development Goal(s)'}
                      title={'Sustainable Development Goal'}
                      placeHolder={'Please select'}
                      id={'sdg'}
                      name={`sdg`}
                      fieldValue={this.state.formFields.sdg.value}
                      fieldError={this.state.formFields.sdg.error}
                      options={sdg}
                      wrapperClass={'col-6 pr-0 ml-0-imp form-group'}
                      btnTextClass={'fieldCheckList'}
                      showTags={true}
                      fieldHeight={true}
                      hideFooter={true}
                      required={true}
                      popUpToRight={true}
                      handleChange={this.handleMultiSelection}
                      permissions={this.props.permissions}
                    />
                  </>
                )}

                <div className="col-12 p-0">
                  <label>
                    Mission Locations<span className="color-FF2300">*</span>
                  </label>
                  {this.state.formFields.missionLocation.value.map(
                    (location, index) => {
                      return (
                        <div
                          key={index + location.missionCountry.jsonField}
                          className="col-12 px-0">
                          <div className="row">
                            <InputSelect
                              required={true}
                              labelText="Mission Country"
                              id="missionCountry"
                              name="missionCountry"
                              options={countryList}
                              fieldValue={location.missionCountry.value}
                              fieldError={location.missionCountry.error}
                              onHandleChange={e =>
                                this.setLocationValue(index, e)
                              }
                              onChangeCallback={this.setFormValidStatus}
                              dataType="num"
                              disableInput={true}
                              wrapperClassName={'w-20 pr-2'}
                              permissions={this.props.permissions}
                            />
                            <InputText
                              required={true}
                              type={'number'}
                              labelText="Pin Code"
                              id="pinCode"
                              name="pinCode"
                              fieldError={location.pinCode.error}
                              onHandleChange={e =>
                                this.setLocationValue(index, e)
                              }
                              onChangeCallback={this.setFormValidStatus}
                              fieldValue={location.pinCode.value}
                              disableInput={false}
                              maxLength={6}
                              wrapperClassName={'w-20 px-2'}
                              permissions={this.props.permissions}
                            />

                            <InputText
                              required={true}
                              labelText="Mission State"
                              type={'text'}
                              id="missionState"
                              name="missionState"
                              fieldValue={location.missionState.value}
                              fieldError={location.missionState.error}
                              wrapperClassName={'w-20 px-2'}
                              permissions={this.props.permissions}
                              onChangeCallback={this.setFormValidStatus}
                              disableInput={true}
                            />
                            <InputText
                              required={true}
                              labelText="Mission City"
                              type={'text'}
                              id="missionDistrict"
                              name="missionDistrict"
                              fieldValue={location.missionDistrict.value}
                              fieldError={location.missionDistrict.error}
                              wrapperClassName={'w-20 px-2'}
                              permissions={this.props.permissions}
                              onChangeCallback={this.setFormValidStatus}
                              disableInput={true}
                            />
                            <span className="w-20 pl-2 d-flex mt-4">
                              <span className="w-100 text-right text-1FA4FA text-underline mr-4">
                                {this.state.formFields.missionLocation.value
                                  .length < 5 &&
                                  this.state.formFields.missionLocation.value
                                    .length ===
                                    index + 1 && (
                                    <span
                                      className="text-underline fs-12 cursor-pointer"
                                      onClick={this.addLocation}>
                                      Add
                                    </span>
                                  )}
                                {index !== 0 && (
                                  <i
                                    className="fas fa-trash-alt text-007bff fs-16 fas-imp ml-4 tooltipss cursor-pointer"
                                    onClick={() => this.deleteLocation(index)}>
                                    <span className="tooltip-text tooltipss-dark">
                                      Delete Mission Location
                                    </span>
                                  </i>
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <h3 className="mt-4 px-4 fs-16 fw-400">Mission Content</h3>
              <div className="row px-4">
                <div className="col-6 pl-0 mb-3">
                  <FileInput
                    required={true}
                    label="Rollover image"
                    placeholder="Resolution in between 600x400 and 4096x4096"
                    id="rolloverImage"
                    name="rolloverImage"
                    refId={this.state.missionId}
                    fileSizeLimit={10}
                    validateFile={imageResolutionValidator}
                    value={this.state.formFields.rolloverImage.value || ''}
                    docType="campaign_image"
                    input={this.inputAttributes}
                    accept={'.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG'}
                    disabled={false}
                    fieldError={this.state.formFields.rolloverImage.error}
                    onSuccess={this.onUploadSuccess}
                    extraInfoAboutField={
                      'Recommended high resolution, landscape oriented image (dimensions should be at least 100x50 and maximum 4096x4096) and 10MB max file size'
                    }
                    permissions={this.props.permissions}
                  />

                  {this.state.formFields.rolloverImage.value && (
                    <div className="w-100 img-16x9 mb-4 rounded-m loading-bg position-relative">
                      <img
                        src={
                          getCloudinaryUrlForImage(
                            this.state.formFields.rolloverImage.value,
                            200
                          ) ||
                          getCloudinaryUrlForImage(
                            'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                            200
                          )
                        }
                        data-src={
                          this.state.formFields.rolloverImage.value ||
                          'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                        }
                        onError={e =>
                          (e.target.src = e.target.getAttribute('data-src'))
                        }
                        alt=""
                        className="abs-full rounded-m"
                      />
                      <span
                        className="floating-delete-btn position-absolute bg-white d-flex justify-content-center align-items-center"
                        onClick={this.deleteRollOverImage}>
                        <i className="fas fa-trash-alt text-007bff fs-12 fas-imp tooltipss cursor-pointer">
                          <span className="tooltip-text tooltipss-dark">
                            Delete Rollover Image
                          </span>
                        </i>
                      </span>
                    </div>
                  )}
                </div>
                {!this.state.refreshRTE && (
                  <>
                    <InputRichTextEditor
                      required={true}
                      labelText="Story"
                      disableInput={false}
                      maxLength={20000}
                      PlaceHolder={'Maximum Length is 20,000 Characters'}
                      id="story"
                      name="story"
                      docType="campaign_image"
                      refId={this.state.missionId}
                      fieldError={this.state.formFields.story.error}
                      fieldValue={this.state.formFields.story.value}
                      wrapperClassName={'col-12 pl-0 pr-0'}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleChange={this.setRTEValueAndError}
                      onHandleBlur={this.onHandleBlur}
                      permissions={this.props.permissions}
                    />
                    <InputRichTextEditor
                      required={true}
                      labelText="Activities"
                      disableInput={false}
                      maxLength={20000}
                      PlaceHolder={'Maximum Length is 20,000 Characters'}
                      id="activities"
                      name="activities"
                      docType="campaign_image"
                      refId={this.state.missionId}
                      fieldError={this.state.formFields.activities.error}
                      fieldValue={this.state.formFields.activities.value}
                      wrapperClassName={'col-12 pl-0 pr-0'}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleChange={this.setRTEValueAndError}
                      onHandleBlur={this.onHandleBlur}
                      permissions={this.props.permissions}
                    />
                    <InputRichTextEditor
                      required={true}
                      labelText="Roadmap"
                      disableInput={false}
                      maxLength={20000}
                      PlaceHolder={'Maximum Length is 20,000 Characters'}
                      id="roadMap"
                      name="roadMap"
                      docType="campaign_image"
                      refId={this.state.missionId}
                      fieldError={this.state.formFields.roadMap.error}
                      fieldValue={this.state.formFields.roadMap.value}
                      wrapperClassName={'col-12 pl-0 pr-0 mb-0'}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleChange={this.setRTEValueAndError}
                      onHandleBlur={this.onHandleBlur}
                      permissions={this.props.permissions}
                    />
                  </>
                )}
              </div>
              <h3 className="mt-4 px-4 fs-14 fw-400">Mission Gallery</h3>

              <div className="row px-4">
                <FileInput
                  required={false}
                  label="Upload the images/pictures of your activities OR work done."
                  placeholder="Resolution in between 600x400 and 4096x4096"
                  id="gallery"
                  name="gallery"
                  refId={this.state.missionId}
                  fileSizeLimit={10}
                  validateFile={imageResolutionValidator}
                  value={''}
                  docType="campaign_image"
                  input={this.inputAttributes}
                  accept={'.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG'}
                  disabled={
                    this.state.formFields.gallery.value?.length < 10
                      ? false
                      : true
                  }
                  fieldError={this.state.formFields.gallery.error}
                  onSuccess={this.onUploadSuccessGallery}
                  extraInfoAboutField={
                    'Recommended high resolution, landscape oriented image (dimensions should be at least 100x50 and maximum 4096x4096) and 10MB max file size'
                  }
                  permissions={this.props.permissions}
                />
              </div>
              <div className="row my-3">
                {this.state.formFields.gallery.value &&
                  this.state.formFields.gallery.value.map((item, index) => {
                    return (
                      <div
                        className=" mb-4 rounded-m col-3 imageGallery"
                        key={index}>
                        <img src={item} alt="" height={'100%'} />
                        <span
                          className="delete_btn_gallery_img position-absolute bg-white d-flex justify-content-center align-items-center"
                          onClick={() => this.deleteGalleryImage(item)}>
                          <i className="fas fa-trash-alt text-007bff fs-12 fas-imp tooltipss cursor-pointer">
                            <span className="tooltip-text tooltipss-dark">
                              Delete Gallery Image
                            </span>
                          </i>
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="floating-bar position-fixed bg-white py-4 px-5 d-flex justify-content-between align-items-center">
          <div>
            {this.props.id && this.props.isModifyMission && (
              <span
                className="fs-14 dm-bold text-0085FF cursor-pointer py-2 px-4"
                onClick={() => this.previewMission()}>
                Preview Mission
              </span>
            )}
          </div>
          <div className="d-flex align-items-center">
            <span
              className="fs-13 mr-3 fs-14 dm-medium text-0085FF cursor-pointer p-2"
              onClick={() => this.showHideCancelModal(true)}>
              Cancel
            </span>
            <button
              disabled={this.state.disable_campaign_content_save_button}
              type="button"
              className="btn btn-outline-primary btn-lg mr-4 px-4 fs-14 dm-bold"
              onClick={() => this.onSave()}>
              Save
            </button>
            <button
              type="button"
              onClick={() => this.onSubmit()}
              disabled={!this.props.id && !this.props.isModifyMission}
              className={`btn btn-primary btn-lg fs-14 px-4 dm-bold ${
                isMissionUnderReview ? 'd-none' : 'd-inline-block'
              }`}>
              Submit
            </button>
          </div>
        </div>
        <Modal
          show={this.state.showCancelModal}
          title={'Submit Mission?'}
          handleCloseModal={() => this.showHideCancelModal(false)}
          sizeClass="modal-lg"
          hideHeader={true}>
          <div className="">
            <h4 className="mt-2">
              Do you want to save changes before closing?
            </h4>
            <p className="fs-16">
              All of the changes will be lost of you don&lsquo;t save them
              before closing.
            </p>
            <div className="d-flex justify-content-end align-items-center mt-5 pt-2">
              <span
                className="fs-13 mr-4 fs-14 dm-medium text-0085FF cursor-pointer p-2"
                onClick={() => this.showHideCancelModal(false)}>
                Cancel
              </span>
              <button
                type="button"
                className="btn btn-outline-primary btn-lg mr-4 px-4 fs-14 dm-bold"
                onClick={() => this.exitWithoutSave()}>
                Don&lsquo;t Save
              </button>
              <button
                type="button"
                onClick={() => this.onSave(true)}
                disabled={!this.props.id && !this.props.isModifyMission}
                className={`btn btn-primary btn-lg fs-14 px-4 dm-bold ${
                  isMissionUnderReview ? 'd-none' : 'd-inline-block'
                }`}>
                Save & Close
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };
  render() {
    if (!this.state.showPreview) {
      return <>{this.createMainHtml()}</>;
    }
    return (
      <>
        <MissionPreview
          missionId={this.state.missionId}
          fetchingPreview={this.fetchingPreview}
          currentMissionStatus={this.state.currentMissionStatus}
          showPreview={this.state.showPreview}
          onClosePreview={this.onClosePreview}
          setFetchPreview={this.setFetchPreview}
          onSubmit={this.onSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  state: state,
  userProfile: state.login.userInfo,
  masterInfo: state.common.masterInfo,
  masterSecondaryInfo: state.common.masterSecondaryInfo,
  listStates: state.common.listStates,
  listDistricts: state.common.listDistricts,
  createModifyMissionSuccessResponse:
    state.missionData.createModifyMissionSuccessResponse,
  createModifyMissionErrorResponse:
    state.missionData.createModifyMissionErrorResponse,
  editMissionSuccessResponse: state.missionData.editMissionSuccessResponse,
  editMissionErrorResponse: state.missionData.editMissionErrorResponse,
  loading: state.missionData.loading,
  profileCompleted: state.ngo.ngoProfile,
  missionData: state.missionData.missionList
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMasterData,
      getMasterDataById,
      getStatesDataById,
      getDistrictsDataById,
      createOrModifyMission,
      getMission,
      getNGOCorpForPublished,
      getNgoProfile,
      getHeaderData
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMission);
