import React, { useState } from 'react';
import { createMarkup, getFormattedDate } from '../../helpers/util';
import flagWhite from '../../assets/icons/flag-white.svg';
import mission from '../../assets/icons/mission-white.svg';
// import noLike from '../../assets/icons/like-gray.svg';
import pdfIcon from '../../assets/images/pdf.png';
import invalidFile from '../../assets/images/invalidFile.png';
import Modal from '../newDesign/Modals/Modal';

const ActivityCard = props => {
  let statusIcon =
    props.update.update_type === 'milestone_achieved' ? flagWhite : mission;
  let status =
    props.update.update_type === 'milestone_achieved'
      ? 'Milestone Achieved'
      : 'General';
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrlArr] = useState(''); //
  const getFileExtension = url => {
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    return extension;
  };
  const showModalFn = url => {
    setFileUrlArr(url);
    setShowModal(true);
  };
  const closeModalFn = () => {
    setShowModal(false);
  };
  let dateTimeString = getFormattedDate(
    'dd/MM/yyyy | hh/mm',
    props.update.created_on
  );

  return (
    <div className={`update-card mt-4`} key={props.key}>
      <div className={`borderBottom p-4`}>
        <div className="d-flex flex-column flex-md-row justify-content-between mb-3">
          <span className="d-flex mb-2 mb-md-0">
            {props.missionInfo.ngo_image ? (
              <img
                className={`ngoImage mr-3`}
                src={props.missionInfo.ngo_image}
                alt={props.missionInfo.ngo_name}
                width={43}
                height={43}
              />
            ) : (
              <span className={`d-inline-block ngoImgPlaceholder mr-3`}></span>
            )}
            <span className="d-inline-block">
              <span className="d-block text-1B1C1D fs-14 text-break">
                {props.missionInfo.mission_title}
              </span>
              <span className="d-block fs-12">
                <span className="d-block mr-md-3">
                  {props.missionInfo.ngo_name}
                </span>
                <span>{dateTimeString}</span>
              </span>
            </span>
          </span>
          <div>
            <div
              className={`d-flex align-items-center justify-content-center justify-content-md-start status p-2`}>
              <span
                className={`d-flex justify-content-center align-items-center statusIconContainer mr-2`}>
                <img
                  className=""
                  src={statusIcon}
                  alt="Update type"
                  width={11}
                  height={11}
                  priority
                />
              </span>
              <span className="fs-12 text-black-1">{status}</span>
            </div>
          </div>
        </div>
        {props.update.mission_image && (
          <img
            className={`missionImage mb-3`}
            src={props.update.mission_image}
            alt="No like"
            width={100}
            height={272}
          />
        )}
        <Modal show={showModal} handleCloseModal={closeModalFn}>
          {showModal && (
            <div style={{ height: '70vh', width: '70vw' }}>
              <iframe
                src={fileUrl}
                title="file"
                width="75%"
                height="75%"></iframe>
            </div>
          )}
        </Modal>

        <div
          className="fs-13 update-content preview-content_update"
          dangerouslySetInnerHTML={createMarkup(props.update.story)}></div>
        <div className="d-flex flex-wrap">
          {props.update.media.map((url, index) => {
            let fileExt = getFileExtension(url);
            const supportedExtensions = [
              'jpg',
              'jpeg',
              'bmp',
              'png',
              'gif',
              'mp4',
              'avi',
              'mov',
              'mpeg',
              'mpg',
              'pdf'
            ];
            return (
              <>
                {fileExt === 'pdf' || fileExt === 'PDF' ? (
                  <>
                    <img
                      key={index + url}
                      src={pdfIcon}
                      alt={'update'}
                      width={124}
                      height={84}
                      className="thumbnail mr-3 cursor-pointer"
                      onClick={e => showModalFn(url)}
                    />
                  </>
                ) : fileExt === 'mp4' ||
                  fileExt === 'avi' ||
                  fileExt === 'mov' ||
                  fileExt === 'mpeg' ||
                  fileExt === 'mpg' ? (
                  <>
                    <video
                      key={index + url}
                      src={url}
                      alt={'update'}
                      width={124}
                      height={84}
                      className="thumbnail mr-3 cursor-pointer"
                      onClick={e => showModalFn(url)}
                    />
                  </>
                ) : supportedExtensions.includes(fileExt.toLowerCase()) ? (
                  <>
                    <img
                      key={index + url}
                      src={url}
                      alt={'update'}
                      width={124}
                      height={84}
                      className="thumbnail mr-3 cursor-pointer"
                      onClick={e => showModalFn(url)}
                    />
                  </>
                ) : (
                  <>
                    <img
                      key={index + url}
                      src={invalidFile}
                      alt={'invalid file'}
                      width={124}
                      height={84}
                      className="thumbnail mr-3 "
                    />
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
      {/* <div className={`borderBottom d-flex align-items-center py-3 px-4 mb-3`}>
        <img
          className="mr-3"
          src={noLike}
          alt={'No like'}
          width={16}
          height={15}
        />
        <span className="fs-10 text-blue-1">
          {props.update.active_supporters} supporters loved it
        </span>
      </div> */}
    </div>
  );
};

export default ActivityCard;
