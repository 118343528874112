import React from 'react';
import { Common } from '../../constants';
import { logout } from '../../helpers/keycloak';
import LogoutSVG from '../../assets/icons/logout.svg';
import '../../styles/custom.css';
function PreRegistrationHeader(props) {
  function redirectUrl() {
    window.location.href = 'https://www.mettasocial.com/';
  }
  return (
    <React.Fragment>
      <div>
        <header className={`fixed-header`}>
          <div className={`ms-header-logo float-left`}>
            <img
              src={Common.NGO.IMAGES.mettaSocialLogo}
              alt="MettaSocial_Logo"
              width={`30%`}
              onClick={() => redirectUrl()}
            />
          </div>

          <div className={`fs-20 dm-medium text-212529`}>
            {Common.NGO.PAGE_TITLE.PRE_REGISTRATION}
          </div>

          <div className={`ms-logout`}>
            <span
              className="d-flex align-items-center cursor-pointer"
              onClick={logout}>
              <img
                src={LogoutSVG}
                width="18px"
                height="18px"
                alt="Logout"
                className="mr-4 ml-2 cursor-pointer"
              />
              <h4 className="color-344256 fs-16 m-0 cursor-pointer">Logout</h4>
            </span>
          </div>
        </header>
      </div>
    </React.Fragment>
  );
}

export default PreRegistrationHeader;
