import React from 'react';
import { Common } from '../../constants';

export default function StickyBottomBar(props) {
  var disableCondition = false;
  if (props.adminName !== undefined) {
    disableCondition = false;
  } else if (window.location.href.includes('edit')) {
    disableCondition = props.disable_campaign_content_save_button;
  } else {
    disableCondition =
      (props.currentCampaignStatus !== 'DRAFT' ||
        props.currentCampaignStatus !== 'NEW') &&
      props.disable_campaign_content_save_button;
  }

  return (
    <>
      <div className="stickyBottomNav p-4">
        <div className="row">
          {props.tabName === 'ConceptNoteDetails' && (
            <>
              <div className="col-1"></div>

              <div className="col-5">
                {/* props.currentCampaignStatus !== 'NEW' && (
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-lg fs-14 dm-bold"
                    onClick={() => props.previewCampaign('ACTIVE')}>
                    Preview Concept Note
                  </button>
                ) */}
              </div>

              <div className="col-6">
                <div className="float-right">
                  <span
                    className="pl-4 mr-4 fs-14 dm-medium color-007bff cursor-pointer"
                    onClick={() => props.handleCancelPopup(true)}>
                    Cancel
                  </span>
                  <button
                    style={{ width: 'auto' }}
                    type="button"
                    onClick={() =>
                      props.onSave(
                        props.currentCampaignStatus === 'NEW'
                          ? 'DRAFT'
                          : Common.NGO.CAMPAIGN_STATUS.DRAFT
                      )
                    }
                    // disabled={props.currentCampaignStatus !== 'DRAFT' }
                    disabled={disableCondition}
                    className={`btn btn-primary btn-lg fs-14 dm-bold mr-4 ${
                      props.isCampaignUnderReview ? 'd-none' : 'd-inline-block'
                    }`}>
                    {props.adminName === undefined ? 'Save & Next' : 'Save'}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
