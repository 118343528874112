import { EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';
export const getNgoDashboardData = () => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('HIROKU');
    instance
      .get(EndPoint.getApiUrl('NGO', 'NGO_DASHBOARD', 'HIROKU'))
      .then(res => {
        if (res.status === 200) {
          resolve(res.data.result);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
