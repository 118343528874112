import React, { useState } from 'react';
import { goalsColumn, historyColumn } from '../../constants/MNEDashboard';
import Table from './commonTable';
import { toast } from 'react-hot-toast';
import { dateFormatMMDDYYYY } from '../formatsForDate';
import Modal from '../newDesign/Modals/Modal';
import './Modals/modal.css';
import { forEach } from 'lodash';

function GoalsIndicators(props) {
  const columns = goalsColumn;
  const [updatedRows, setUpdatedRows] = useState([]);
  const [historyData, sethistoryData] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [history, setHistory] = useState({});
  const [totalAchievedTarget, setTotalAchievedTarget] = useState(0);
  const [fetchingGoalsHistory, setFetchingGoalsHistory] = useState(false);

  const showHistoryModal = (e, id) => {
    setShowHistory(true);
    setFetchingGoalsHistory(true);
    var total = 0;
    props
      .getGoalHistory(id)
      .then(res => {
        setFetchingGoalsHistory(false);
        sethistoryData(res);
        if (res?.length > 0) {
          forEach(res, (itm, idx) => {
            total = total + itm.achieved_target;
          });
          setTotalAchievedTarget(total);
        }
      })
      .catch(async err => {
        setFetchingGoalsHistory(false);
      });
    let goalData = props.goalsList.find(x => x.id === id);
    setHistory(goalData);
  };

  const rowUpdated = rowId => {
    const row_id = updatedRows.findIndex(index => index === rowId);
    if (row_id === -1) {
      const updatedRowsArr = updatedRows.slice();
      updatedRowsArr.push(rowId);
      setUpdatedRows(updatedRowsArr);
    }
  };
  const handleChange = (e, id) => {
    rowUpdated(id);

    const tempGoalsList = props.goalsList.slice();
    const row = tempGoalsList.find(x => x.id === id);
    if (row) {
      if (e.target.id === 'achived_target') {
        if (!e.target.value.match(/^\d+$/)) {
          row.error.achievedTarget = 'should contain only numbers';
        } else {
          row.error.achievedTarget = '';
        }
      } else if (e.target.id === 'indicator_date') {
        if (!e.target.value || e.target.value === '') {
          row.error.indicator_date = 'please provide a date';
        } else {
          row.error.indicator_date = '';
        }
      }
      row[e.target.id] = e.target.value;

      props.setGoalList(tempGoalsList);
    }
  };

  const submitIndicators = e => {
    e.preventDefault();
    const payloadArr = [];
    updatedRows.map(id => {
      const row = props.goalsList.find(x => x.id === id);
      if (row) {
        let obj = {
          indicator_id: row.id,
          // indicator_date: row.indicator_date,
          indicator_date: dateFormatMMDDYYYY(),
          achieved_target: parseInt(row.achived_target),
          attachment: row.attachment === null ? '' : row.attachment,
          baseline_value: row.baseline_value
        };
        payloadArr.push(obj);
      }
    });
    let emptyIndicatorDate = payloadArr.filter(
      x => x.indicator_date === undefined
    );
    let achievedTatgetArr = payloadArr.filter(x => x.achieved_target < 0);
    // if (emptyIndicatorDate.length > 0) {
    //   toast.error('Achieved Target Date is mandatory');
    // } else
    if (achievedTatgetArr.length > 0) {
      toast.error('Achieved Target cannot be a negative value');
    } else {
      if (payloadArr.length > 0 || emptyIndicatorDate.length === 0) {
        const payloadObj = {
          campaign_id: window.location.pathname.split('/')[3],
          project_indicators: payloadArr
        };

        if (payloadObj.project_indicators !== []) {
          props
            .creategoalHistory(payloadObj)
            .then(res => {
              setUpdatedRows([]);
              if (res) {
                toast.success('Indicators updated successfully');
              } else {
                toast.error(
                  'Uh-Oh! Looks like we are having server issues. Please update your Goals again! '
                );
              }
              props.getGoals();
            })
            .catch(() => {
              toast.error(
                'Uh-Oh! Looks like we are having server issues. Please update your Goals again! '
              );
            });
        }
      }
    }
  };

  const indicatorUploadSuccess = (fileUrl, e, id) => {
    rowUpdated(id);
    const tempGoalsList = props.goalsList.slice();
    const row = tempGoalsList.find(x => x.id === id);
    if (row) {
      row[e.target.id] = fileUrl;
    }
  };

  let body = document.body;
  if (showHistory) {
    body.style.overflow = 'hidden';
  } else {
    body.style.overflow = 'auto';
  }
  const hideHistory = () => {
    setShowHistory(false);
    sethistoryData([]);
  };
  return (
    <div className="p-4">
      <div className="w-100 bg-white">
        <div className="p-4">
          <div>
            <h3 className="color-344256 fs-20 ">Indicators</h3>
            <hr align="left" />
          </div>
          <Table
            column={columns}
            goalRow={props.goalsList}
            defaultGoals={props.defaultGoals}
            handleChange={handleChange}
            indicatorUploadSuccess={indicatorUploadSuccess}
            showHistoryModal={showHistoryModal}
            permissions={props.permissions}
            isUserAuthorized={props.isUserAuthorized}
          />
          <div className="d-flex mt-3 justify-content-center">
            {props.goalsList?.length > 0 && props.isUserAuthorized && (
              <button
                className="btn standard-btn fs-14"
                style={{ width: '120px' }}
                onClick={e => submitIndicators(e)}>
                Save
              </button>
            )}
          </div>
        </div>
      </div>

      <>
        <Modal
          show={showHistory}
          title={'History'}
          handleCloseModal={hideHistory}
          sizeClass="modal-lg">
          <div className="d-flex flex-column gap-2">
            <h5 className="color-344256 fs-12 my-2">
              <span className="font-weight-bold">Indicator Description : </span>
              {history.indicator_description}{' '}
              {/* {fetchingGoalsHistory ? 'TRUE' : 'FALSE'} */}
            </h5>
            <h5 className="color-344256 fs-12 my-2">
              <span className="font-weight-bold">Unit of Measurement : </span>
              {history.uom}
            </h5>
            <h5 className="color-344256 fs-12 my-2">
              <span className="font-weight-bold">Baseline value : </span>
              {new Intl.NumberFormat('en-IN').format(history.baseline_value)}
            </h5>
            <h5 className="color-344256 fs-12 my-2">
              <span className="font-weight-bold">Target value : </span>
              {new Intl.NumberFormat('en-IN').format(history.target_value)}
            </h5>
            <h5 className="color-344256 fs-12 my-2">
              <span className="font-weight-bold">Total Achieved value : </span>
              {totalAchievedTarget}
            </h5>
          </div>

          <div className="mt-5">
            <Table
              column={historyColumn}
              historyRow={historyData}
              fetchingGoalsHistory={fetchingGoalsHistory}
            />
          </div>

          {/* TODO:ADD TABLE */}
        </Modal>
      </>
    </div>
  );
}

export default GoalsIndicators;
