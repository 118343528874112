import React, { useState } from 'react';

const SelectOptionDropdown = ({
  hideSearch,
  selectedData,
  selectedOption,
  handleSelection,
  searchPlaceholder,
  disabled
}) => {
  const [searchText, setSearchText] = useState('');
  const renderOptionsList = () => {
    const filteredOptions = searchOptionsByName();
    return filteredOptions?.map(option => (
      <div
        key={option.id}
        className={`cursor-pointer my-2 mx-2 d-flex ${
          selectedOption?.id === option.id ? 'active-option' : ''
        }`}
        // onClick={() => handleOptionClick(option)}
        onClick={() => handleSelection(option)}>
        <span className="fs-14">{option.name}</span>
      </div>
    ));
  };

  const searchOptionsByName = () => {
    const lowerCaseSearchText = searchText.toLowerCase();
    return selectedData?.filter(option =>
      option.name.toLowerCase().includes(lowerCaseSearchText)
    );
  };

  return (
    <>
      <div className="form-group">
        <button
          className="btn btn-dropdown ht-36 "
          type="button"
          data-toggle="dropdown"
          disabled={disabled}>
          <span className="float-left mt-1">{selectedOption?.name}</span>
          <div className="float-right dropdown-toggle"></div>
        </button>

        <div className={`dropdown-menu col-12 px-0 w-15`}>
          {!hideSearch && (
            <div className="mx-3">
              <div className="col-12 position-relative rounded p-0 d-flex justify-content-center">
                <span className="form-group col-12 rounded p-0">
                  <div
                    type="button"
                    className="btn position-absolute"
                    style={{ right: '0px', top: '4px', zIndex: '1' }}>
                    <i className="fa fa-search form-control-feedback"></i>
                  </div>
                  <input
                    type="text"
                    name="search"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    placeholder={searchPlaceholder}
                    className="col-12 standard-search-style rounded cursor-pointer"
                  />
                </span>
              </div>
            </div>
          )}
          <div className={`col-12 px-0 mx-2 `}>
            <div className="overflow-scroll-40vh">{renderOptionsList()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectOptionDropdown;
