import { Common, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

const MissionService = {
  createOrModifyMission: function(data, status) {
    let instance = HttpRequest.getInstance('HIROKU');

    let pathKey, isQstr;

    switch (status) {
      case Common.NGO.MISSION_STATUS.APPROVED:
        pathKey = 'APPROVE_MISSION';
        isQstr = true;
        break;

      case Common.NGO.MISSION_STATUS.REJECTED:
        pathKey = 'REJECT_MISSION';
        isQstr = true;
        break;

      case Common.NGO.MISSION_STATUS.DRAFT:
      case Common.NGO.MISSION_STATUS.UNDER_REVIEW:
      default:
        pathKey = 'MISSION_CREATE';
        isQstr = false;
        break;
    }
    let apiUrl = EndPoint.getApiUrl('NGO', pathKey, 'HIROKU');
    if (window.location.href.includes('email=')) {
      let email = window.location.href.split('email=')[1];
      apiUrl = apiUrl + '?email=' + `${email}`;
    }
    return instance.post(
      apiUrl,
      isQstr ? null : data,
      isQstr
        ? {
            params: {
              missionId: data.missionId
            }
          }
        : null
    );
  },
  getMission: function(missionId, mode) {
    let instance = HttpRequest.getInstance('HIROKU');
    let endPointKey, isQstr;
    switch (mode) {
      case 'preview':
        endPointKey = 'MISSION_PREVIEW';
        isQstr = true;
        break;

      case 'edit':
      default:
        endPointKey = 'MISSION_EDIT';
        isQstr = false;
        break;

      /* case 'detail':
				endPointKey = 'MISSION_DETAIL';
				isQstr = true; */
    }
    let apiUrl = EndPoint.getApiUrl('NGO', endPointKey, 'HIROKU');
    if (!isQstr) {
      apiUrl = apiUrl.replace('{missionId}', missionId);
    }
    return instance.get(
      apiUrl,
      isQstr
        ? {
            params: {
              missionId: missionId
            }
          }
        : null
    );
  },
  updateMission: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.post(
      EndPoint.getApiUrl('NGO', 'MISSION_UPDATE', 'HIROKU'),
      data
    );
  },

  updateMissionMail: function(missionName, mission_id, ngo_name) {
    let instance = HttpRequest.getInstance('WORKPLACE');
    let apiUrl = EndPoint.getApiUrl('NGO', 'MISSION_UPDATE_MAIL', 'WORKPLACE');
    apiUrl =
      apiUrl +
      'mission_name=' +
      missionName +
      '&mission_id=' +
      mission_id +
      '&ngo_name=' +
      ngo_name;
    return instance.post(apiUrl);
  },
  getMissionFunds: function(missionId) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.get(
      EndPoint.getApiUrl('NGO', 'MISSION_FUNDS', 'HIROKU').replace(
        '{missionId}',
        missionId
      )
    );
  },
  unpublishMission: function(missionId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'MISSION_UNPUBLISH', 'HIROKU');
    apiUrl = apiUrl.replace('{missionId}', missionId);
    return instance.put(apiUrl);
  },
  getTransactionsByMissionId: function(
    missionId,
    ngoId,
    search,
    start_date,
    end_date
  ) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'MISSION_TRANSACTIONS', 'HIROKU');
    apiUrl =
      apiUrl +
      '?missionId=' +
      missionId +
      '&ngoId=' +
      ngoId +
      '&search=' +
      search +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date;
    return instance.get(apiUrl);
  },

  getStatisticsByMissionId: function(missionId, ngoId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'MISSION_STATISTICS', 'HIROKU');
    apiUrl = apiUrl + '?missionId=' + missionId + '&ngoId=' + ngoId;
    // '198' + //need remove static mission id dynamic
    // '38769976-8c58-42d4-9f0a-c53e67fbef76'; //need remove static ngo id
    return instance.get(apiUrl);
  },
  getAmountRaisedByCorporates: function(
    missionId,
    ngoId,
    start_date,
    end_date
  ) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl(
      'NGO',
      'AMOUNT_RAISED_BY_CORPORATES',
      'HIROKU'
    );
    apiUrl =
      apiUrl +
      '?missionId=' +
      missionId +
      '&ngoId=' +
      ngoId +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date;
    return instance.get(apiUrl);
  },

  getAllMissionsForNgo: function(ngoId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'MISSION_LIST', 'HIROKU');
    if (window.location.href.includes('email=')) {
      //mission imperonation code
      let email = window.location.href.split('email=')[1];

      apiUrl = apiUrl.replace('{ngoId}', ngoId);
      apiUrl = apiUrl + '?email=' + `${email}`;
    } else {
      apiUrl = apiUrl.replace('{ngoId}', ngoId);
    }

    return instance.get(apiUrl);
  }
};

export default MissionService;
