import React from 'react';
import raiseTickets from '../../assets/images/raise-tickets.png';
import attachmentIcon from '../../assets/images/icon-feather-paperclip.png';
import { Link } from 'react-router-dom';
import { defaults } from 'lodash';
import Modal from '../newDesign/Modals/Modal';
import { Routes } from '../../constants/path.constants';
import { InputSelect, InputText, InputTextArea } from '../FormElements';
import FileInputMultiple from '../fileInputMultiple';
import { toast } from 'react-hot-toast';
import { restrictToNumber, eventToValue } from '../../helpers/util';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { raiseTicket, getSupportTypes } from '../../actions/support.actions';
import { convertFormDataToJson } from '../../helpers/profileHelpers';
class NgoSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopUp: false,
      issueTypeList: [],
      formFields: {
        title: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'title'
        },
        issueType: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'issue_type'
        },
        description: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'description'
        },
        attachment: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'attachment'
        }
      }
    };
  }

  componentDidMount() {
    this.getIssueTypes();
  }
  getIssueTypes = () => {
    this.props
      .getSupportTypes()
      .then(res => this.setState({ issueTypeList: res }))
      .catch(err => {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      });
  };
  showHideRaiseTicketModal = () => {
    this.setState({ showPopUp: this.state.showPopUp ? false : true });
  };

  Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    }
  };
  validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';
    switch (name) {
      case 'title':
        isFieldValid = this.Validations.required(value);
        if (!isFieldValid) msg = 'Title required';
        if (value.length < 4) msg = 'Minimum 4 characters required';
        if (value.length > 100) msg = 'Maximum 100 characters allowed';
        break;
      case 'issueType':
        isFieldValid = this.Validations.required(value);
        if (!isFieldValid) msg = 'Issue type required';
        break;
      case 'description':
        isFieldValid = this.Validations.required(value);
        if (!isFieldValid) msg = 'Description required';
        if (value.length < 4) msg = 'Minimum 4 characters required';
        if (value.length > 500) msg = 'Maximum 500 characters allowed';
        break;
      default:
    }
    return { isFieldValid, msg };
  };
  setFormErrorsAndValue = (event, callback, type, childCallBack) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;
    if (!type) {
      value = eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    let formFields = {};
    const errorResult = this.validate(id, value);
    formFields[id] = {
      isValid: errorResult.isFieldValid,
      error: errorResult.msg,
      value,
      jsonField: this.state.formFields[id].jsonField
    };
    formFields = defaults({}, formFields, this.state.formFields);
    this.setState({ ...this.state, formFields });
  };

  onHandleChange = (event, callback, type, childCallback, newValue) => {
    this.setFormErrorsAndValue(event, callback, type, childCallback, newValue);
  };
  onHandleBlur = (event, callback, type) => {
    this.setFormErrorsAndValue(event, callback, type);
  };

  onUploadSuccess = (fileUrl, event) => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        [event.target.id]: {
          isValid: true,
          error: '',
          value: fileUrl,
          jsonField: this.state.formFields[event.target.id].jsonField
        }
      }
    });
  };

  getEmptyFieldName = () => {
    const { formFields } = this.state;
    const trimmedTitle = this.state.formFields.title.value.trim();
    if (trimmedTitle === '') return 'Title';
    if (formFields.issueType.value === '') return 'Issue Type';
    if (formFields.description.value === '') return 'Description';
    return false;
  };

  sendTicket = e => {
    e.preventDefault();
    let formData = convertFormDataToJson(new FormData(e.target));
    let attachmentsArr = [];
    for (let key in formData) {
      if (key.includes('attachments_')) {
        if (formData[key] !== '') attachmentsArr.push(formData[key]);
      }
    }
    const { formFields } = this.state;
    const trimmedTitle = this.state.formFields.title.value.trim();
    if (
      trimmedTitle === '' ||
      formFields.issueType.value === '' ||
      formFields.description.value === ''
    ) {
      return toast.error(`Please fill the ${this.getEmptyFieldName()}`);
    } else {
      let data = {
        title: formFields.title.value.trim(),
        issue_type: formFields.issueType.value,
        description: formFields.description.value.trim(),
        attachments: attachmentsArr
      };
      this.props
        .raiseTicket(data)
        .then(res => {
          toast.success('Issue raised successfully');
          this.props.history.push(Routes.NGO.SUPPORT_TICKET_LIST);
        })
        .catch(err => {
          toast.error('Uh-Oh! Looks like we are having server issues.');
        });
    }
  };

  render() {
    const multiFileInpAttr = {
      separator: true,
      heading: 'Attachments',
      label: 'Attachments',
      fileSizeLimit: 5,
      type: 'file'
    };
    return (
      <>
        <div className="row">
          <div className="col-12">
            <h3 className="mt-5 mb-4 fw-600">Raise a new ticket</h3>
            <div className="bg-white p-4">
              <div className="text-right">
                <Link to={Routes.NGO.SUPPORT_TICKET_LIST}>
                  <span className="fs-16 color-1FA4FA text-underline">
                    View previous tickets
                  </span>
                </Link>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <img src={raiseTickets} className="raise-ticket" alt="ngo" />
              </div>
              <div className="mb-5">
                <button
                  type="button"
                  className="btn btn-primary btn-custom-lg mx-auto d-block"
                  onClick={this.showHideRaiseTicketModal}>
                  Raise a Ticket
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showPopUp}
          title={'Raise New Ticket'}
          handleCloseModal={this.showHideRaiseTicketModal}
          sizeClass="modal-lg">
          <form
            className="form-row"
            id="raise-ticket-form"
            onSubmit={this.sendTicket}>
            <div className="row">
              <InputText
                required={true}
                id="title"
                name="title"
                type={'text'}
                maxLength={100}
                labelText="Title"
                PlaceHolder={'Maximum Length is 100 Characters'}
                fieldValue={this.state.formFields.title.value}
                fieldError={this.state.formFields.title.error}
                onHandleChange={this.onHandleChange}
                onHandleBlur={this.onHandleBlur}
                wrapperClassName={'col-12'}
              />
              <InputSelect
                required={true}
                id="issueType"
                name="issueType"
                labelText="Issue type"
                options={this.state.issueTypeList}
                fieldValue={this.state.formFields.issueType.value}
                fieldError={this.state.formFields.issueType.error}
                onHandleChange={this.onHandleChange}
                dataType="num"
                wrapperClassName={'col-12'}
              />
              <InputTextArea
                required={true}
                id="description"
                name="description"
                maxLength={500}
                labelText="Description"
                PlaceHolder={'Maximum Length is 500 Characters'}
                fieldValue={this.state.formFields.description.value}
                fieldError={this.state.formFields.description.error}
                onHandleChange={this.onHandleChange}
                onHandleBlur={this.onHandleBlur}
                className="campaign_objectives campaign-textarea"
                wrapperClassName={'col-12'}
              />
              <div className="col-12">
                <label>
                  <img
                    src={attachmentIcon}
                    className="attach-icon"
                    alt="Attachment Icon"
                  />
                  <span className="font-weight-bolder pl-2">
                    Attachments&nbsp;
                  </span>
                  <span>(JPEG, PNG, PDF)</span>
                </label>
                <FileInputMultiple
                  label="Audited Reports"
                  accept=".jpg,.jpeg,.png,.pdf"
                  {...multiFileInpAttr}
                  value={this.state.formFields.attachment.value}
                  id={'attachments'}
                  name={'attachments'}
                  refId={this.props.state.ngo.ngoProfile.ngo_id}
                />
              </div>
              <div className="col-12 mt-3 text-center">
                <button type="submit" className="btn btn-primary btn-custom">
                  Send
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  state: state
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      raiseTicket,
      getSupportTypes
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NgoSupport);
