import React, { useEffect, useState } from 'react';
import { getFormattedDate } from '../../helpers/util';
import SortIconDsc from '../../assets/icons/sort_icon_desc.svg';
import SortIconAsc from '../../assets/icons/sort_icon_asc.svg';
import SortIconDef from '../../assets/icons/sort_icon_default.svg';
import { convertInCommaFormat } from '../../helpers/commaSeparatedInput';
import { sortTableData } from './sortTable';
import EmptyImage from '../../assets/icons/gallary_empty_state.svg';

const TablePledgeSummary = ({ postsPerPage, currencySymb }) => {
  const [displayData, setDisplayData] = useState();
  const [activeSortField, setActiveSortField] = useState(['', '']);
  const [sortStateField, setSortStateField] = useState({
    amount: 'dec',
    date: 'dec'
  });
  useEffect(() => {
    setDisplayData(postsPerPage);
  }, [postsPerPage]);

  const sortTable = label => {
    sortTableData(
      label,
      sortStateField,
      setDisplayData,
      postsPerPage,
      setActiveSortField,
      setSortStateField
    );
  };

  const getAnonymousPanNum = pan_num => {
    const phoneNumber = pan_num;
    // const visibleDigits = phoneNumber.slice(2, -2);
    const maskedDigits = '*'?.repeat(6);
    const maskedPhoneNumber =
      phoneNumber?.slice(0, 2) + maskedDigits + phoneNumber?.slice(-1);
    return maskedPhoneNumber;
  };

  const getSortIcons = item => {
    const getIcons = () => {
      if (activeSortField[0] === item.label) {
        if (activeSortField[1] === 'dec') {
          return SortIconDsc;
        } else if (activeSortField[1] === 'asc') {
          return SortIconAsc;
        }
      }
      return SortIconDef;
    };
    if (item.sortLabel) {
      return (
        <img
          src={getIcons()}
          width={20}
          className="ms-3"
          height={30}
          alt="sort"
        />
      );
    }
  };
  if (displayData?.length === 0) {
    return (
      <div className="text-center  fs-14 my-5 py-5">
        <img src={EmptyImage} alt="empty list" width={200} height={180} />
        <div className="mt-4">
          No matching results found for your query. Please try again with
          different search terms.
        </div>{' '}
      </div>
    );
  }

  return (
    <>
      <table className="table table-borders table-hover table-pledge-summary bg-white">
        <thead className="table-head">
          <tr className="text-left">
            <th>SR NO</th>
            <th>FIRST NAME</th>
            <th>LAST NAME</th>
            <th>EMAIL (PERSONAL)</th>
            <th>EMAIL (ORGANISATION)</th>
            <th>PAN NUMBER</th>
            <th onClick={() => sortTable('Amount')}>
              AMOUNT {getSortIcons({ sortLabel: 'amount', label: 'Amount' })}
            </th>
            <th onClick={() => sortTable('Date')}>
              DONATION DATE{' '}
              {getSortIcons({
                sortLabel: 'donationDate',
                label: 'Date'
              })}
            </th>
            <th>CORPORATE NAME</th>
          </tr>
        </thead>
        <tbody>
          {displayData?.length > 0 &&
            displayData.map((pledge, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{pledge.first_name}</td>
                <td>{pledge.last_name}</td>
                <td>{pledge.personal_email}</td>
                <td>{pledge?.corp_email}</td>
                <td>
                  {pledge.pan_number !== null &&
                  pledge.pan_number !== '' &&
                  pledge.pan_number !== undefined
                    ? getAnonymousPanNum(pledge.pan_number)
                    : '--'}
                </td>
                <td>
                  {currencySymb} {convertInCommaFormat(pledge?.amount)}
                </td>
                <td>
                  {getFormattedDate('dd/MM/yyyy', pledge?.transaction_date)}
                </td>
                <td>{pledge?.companyName}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default TablePledgeSummary;
