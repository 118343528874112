import { Action } from '../constants';
const initialState = {
  currencyData: {
    currency: 'INR'
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_REGIONAL_SETTINGS_SUCCESS:
      return {
        ...state,
        currencyData: action.payload
      };
    default:
      return state;
  }
};
