///* global jQuery */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps*/
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { debounce, defaults, filter } from 'lodash';
// import React, { useEffect, useRef } from 'react';
import React from 'react';
import { DateUtils } from 'react-day-picker';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { toast } from 'react-hot-toast';
import {
  deleteDraftCampaignForNGO,
  getCampaignsForNgo,
  duplicateCampaignForNgo,
  // getDistrictsDataById,
  getMasterData,
  getMasterDataById,
  createOrModifyCampaign,
  startProject
  // getStatesDataById
} from '../../actions';
import { getSecondaryCauses } from '../../actions/common.actions';
import { Common } from '../../constants';
import { getCloudinaryUrlForImage } from '../../helpers/util';
// import { InputSelect } from '../FormElements';
import Loader from '../loader';
import Tabs from '../tabs';
import FilterOptionsDropdownChecklist from '../filter/filterOptionsDropdownChecklist';
// import DateDropdown from '../filter/DateDropdown';
// import LocationDropdown from '../filter/LocationDropdown';
// import ShowMore from "react-show-more";
import '../../styles/simple-sidebar.css';
import LocationMain from './locationMain';
import LocationTooltip from './locationTooltip';
import CauseAreaMain from './causeAreaMain';
import CauseAreaTooltip from './causeTooltip';
// import EditDelIcon from '../../assets/icons/editIcon.svg';
import EditIcon from '../../assets/icons/icn_kebab.svg';
import EmptySideScreenState from '../EmptySideScreenState';
import { getUserRole, isAuthorized } from '../../helpers/auth-roles';

dayjs.extend(isBetween);

let primary = [];
let secondary = [];

class CampaignsList extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      allCampaigns: null,
      dummy: null
    };
    this.state = {
      ...this.initialState,
      filters: this.getInitialFilterState(),
      userId: '',

      isDateRangeShow: false,
      ishandleToggleFilter: false,
      isListDisplay: false,
      popOverOptions: null,
      dateRangeArray: [],
      locationArr: [],
      campaignsReceived: false,
      primaryCauses: [],
      secondaryCauses: [],
      showFilterBox: false,
      secondaryCauses: [],
      clearFilter: false,
      checkedCount: 0,
      formFields: {
        primaryCauseArea: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'cause_area'
        },
        secondaryCauseArea: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'sub_cause_area'
        },

        projectCountry: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'country'
        },
        projectState: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'state'
        },
        projectDistrict: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'city'
        }
      },
      showOtherLocations: true,
      showMoreLessText: 'more',
      trackCampaignLocation: 0,
      campaignFetched: false,
      isSuperAdmin: false
    };
    this.isCalled = false;
  }

  fillStatesList = () => {
    //101 - country id of India
    // this.props.getStatesDataById(101);
  };
  fillDistrictsList = () => {
    this.props.getDistrictsDataById(this.state.formFields.projectState.value);
  };
  onHandleChange = (event, callback, type, childCallback, newValue) => {
    this.setFormErrorsAndValue(event, callback, type, childCallback, newValue);
    // this.enableSaveButton();
  };
  setFormErrorsAndValue = (event, callback, type, childCallBack) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;

    if (!type) {
      value = this.eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = this.restrictToNumber(value);
        break;

      default:
    }

    let formFields = {};

    formFields[id] = {
      value,
      jsonField: this.state.formFields[id].jsonField
    };
    formFields = defaults({}, formFields, this.state.formFields);

    this.setState({ ...this.state, formFields }, () => {
      if (typeof callback === 'function') {
        callback();
      }
      if (typeof childCallBack === 'function') {
        childCallBack();
      }
    });
  };

  handleDateRange = range => {
    this.setState({
      dateRangeArray: [
        {
          date: range[0].split('/')[0],
          month: range[0].split('/')[1],
          year: range[0].split('/')[2]
        },
        {
          date: range[1].split('/')[0],
          month: range[1].split('/')[1],
          year: range[1].split('/')[2]
        }
      ]
    });
  };
  // handleLocation = locationArr => {
  //   this.setState({
  //     locationArr: locationArr
  //   });
  // };
  handleLocation = (newlocationArr, callback) => {
    this.setState(
      {
        locationArr: newlocationArr
      },
      () => {
        if (callback) this.ApplyFilter();
      }
    );
  };

  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  eventToValue(e, trimResults, toNumber) {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      value = Number(value);
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  }
  getInitialDateState() {
    return {
      fromDate: null,
      toDate: null,
      enteredTo: null
    };
  }

  getInitialFilterState = () => {
    return {
      status: 'all',
      searchTerm: '',
      dateRange: this.getInitialDateState()
    };
  };

  isSelectingFirstDay(fromDate, toDate, day) {
    const isBeforeFirstDay = fromDate && DateUtils.isDayBefore(day, fromDate);
    const isRangeSelected = fromDate && toDate;
    return !fromDate || isBeforeFirstDay || isRangeSelected;
  }

  openFilterBox = e => {
    this.setState({ showFilterBox: true });
  };

  closeFilterBox = e => {
    this.setState({ showFilterBox: false });
    this.clearFilter();
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (window.location.href.includes('email')) {
      // this.props.getCampaignsForNgo(nextprops.ngoData.ngo_id);
      this.props.getCampaignsForNgo(this.props.userInfo.sub);
    }
    // this.props.getCampaignsForNgo(nextprops.ngoData.ngo_id);
    const masterDataOptions = Common.NGO.MASTER_OPTIONS;
    this.props.getMasterData([masterDataOptions.CAUSE_CATEGORY]);
    this.fillStatesList();
    this.setState({ isSuperAdmin: getUserRole() });
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.ngoData.ngo_id) {
      this.setState({ userId: nextprops.ngoData.ngo_id });
      if (!this.state.campaignFetched) {
        this.setState({ campaignFetched: true }, () => {
          this.props.getCampaignsForNgo(nextprops.ngoData.ngo_id);
        });
      }
    }
    if (nextprops.campaignInfo) {
      const status =
        this.props.permissions === 'R'
          ? Common.NGO.CAMPAIGN_STATUS.FUNDED
          : Common.NGO.CAMPAIGN_STATUS.DRAFT;
      this.setState(
        {
          allCampaigns: nextprops.campaignInfo.campaigns,
          campaignsReceived: true,
          filters: {
            ...this.state.filters,
            // status: Common.NGO.CAMPAIGN_STATUS.ALL //uncomment to allow ALL campaigns
            status: window.location.href.includes('campaign=')
              ? window.location.href.split('campaign=')[1]
              : status // Common.NGO.CAMPAIGN_STATUS.DRAFT
          }
        },
        () => {
          this.setState({ allCampaigns: this.filterData() });
        }
      );
      this.originalCampaigns = nextprops.campaignInfo.campaigns;
    }
  }

  clickHandler = {
    handleDateRangeClick: () => {
      this.setState({ isDateRangeShow: !this.state.isDateRangeShow });
    },
    handleTabClick: status => {
      this.setState(
        {
          filters: { ...this.state.filters, status: status.code }
        },
        () => {
          this.clearFilter();
          this.setState({ allCampaigns: this.filterData() });
        }
      );
    },
    handleSearchClick: () => {
      this.setState({
        allCampaigns: this.filterData()
      });
    },
    handleResetDateFilterClick: () => {
      this.setState({
        filters: {
          ...this.state.filters,
          dateRange: this.getInitialDateState()
        }
      });
    }

    // handleDayClick: day => {
    //   const { fromDate, toDate } = this.state.filters.dateRange;
    //   if (fromDate && toDate && day >= fromDate && day <= toDate) {
    //     this.clickHandler.handleResetDateFilterClick();
    //     return;
    //   }
    //   if (this.isSelectingFirstDay(fromDate, toDate, day)) {
    //     this.setState({
    //       filters: {
    //         ...this.state.filters,
    //         dateRange: {
    //           fromDate: day,
    //           toDate: null,
    //           enteredTo: null
    //         }
    //       }
    //     });
    //   } else {
    //     this.clickHandler.handleDateRangeClick();
    //     this.setState(
    //       {
    //         filters: {
    //           ...this.state.filters,
    //           dateRange: {
    //             fromDate: this.state.filters.dateRange.fromDate,
    //             toDate: day,
    //             enteredTo: day
    //           }
    //         }
    //       },
    //       () => {
    //         this.setState({ allCampaigns: this.filterData() });
    //       }
    //     );
    //   }
    // }
  };
  // handleDayClick= (startDate , endDate , campaign) => {
  // const { fromDate, toDate } = this.state.filters.dateRange;

  // if (fromDate && toDate && day >= fromDate && day <= toDate) {
  //   this.clickHandler.handleResetDateFilterClick();
  //   return;
  // }
  // if (this.isSelectingFirstDay(fromDate, toDate, day)) {
  //   this.setState({
  //     filters: {
  //       ...this.state.filters,
  //       dateRange: {
  //         fromDate: day,
  //         toDate: null,
  //         enteredTo: null
  //       }
  //     }
  //   });
  // } else {
  //   this.clickHandler.handleDateRangeClick();
  //   this.setState(
  //     {
  //       filters: {
  //         ...this.state.filters,
  //         dateRange: {
  //           fromDate: this.state.filters.dateRange.fromDate,
  //           toDate: day,
  //           enteredTo: day
  //         }
  //       }
  //     },
  //     () => {
  //       this.setState({ allCampaigns: this.filterData() });
  //     }
  //   );
  // }
  // }

  startProject = (e, campaignId) => {
    this.props
      .startProject(campaignId)
      .then(res => {
        if (res.code === '200') {
          toast.success('Project has been started');
          setTimeout(() => {
            window.open(
              `${process.env.REACT_APP_NEW}dashboard/single-mne-project/${campaignId}/dashboard`,
              '_blank'
            );
            window.location.reload();
          }, 500);
        }
      })
      .catch(async err => {});
  };

  clearFilter = () => {
    let status = this.state.filters.status.toLowerCase();
    let filteredCampaigns = this.originalCampaigns.filter(
      x => x.status.toLowerCase() === status
    );
    let list1 = document.getElementsByClassName('btn-dropdown-count');
    for (let i = 0; i < list1.length; i++) {
      list1[i].textContent = 0;
    }
    let list2 = document.getElementsByName('primary-cause-checkbox');
    for (let i = 0; i < list2.length; i++) {
      if (list2[i].checked === true) {
        list2[i].checked = false;
      }
      list2[i].textContent = 0;
    }
    let list3 = document.getElementsByClassName('secondary-cause-checkbox');
    for (let i = 0; i < list3.length; i++) {
      list3[i].textContent = 0;
    }
    let list4 = document.getElementsByName('location');
    for (let i = 0; i < list4.length; i++) {
      if (list4[i].checked === true) {
        list4[i].checked = false;
      }
      list4[i].textContent = 0;
    }
    primary = [];
    secondary = [];
    this.setState({
      allCampaigns: filteredCampaigns,
      clearFilter: true,
      secondaryCauses: []
    });
    this.handleLocation([], true);
  };

  handleToggleFilter = () => {
    const currentState = this.state.ishandleToggleFilter;
    this.setState({
      ishandleToggleFilter: !currentState
    });
  };

  handleListDisplay = e => {
    const currentState = this.state.isListDisplay;
    if (currentState === false) {
      document.querySelector('body').addEventListener('click', () => {
        this.setState({
          isListDisplay: false
          // popOverOptions: e.target.id.slice(6)
        });
        // document.querySelector('body').removeEventListener('click')
      });
    }
    this.setState({
      isListDisplay: !currentState,
      popOverOptions: e.target.id.slice(6)
    });
  };
  initProgramLocationList = arr => {
    let locations = [];
    if (arr && arr.length > 0) {
      arr.map((campaign, index) => {
        campaign.location.map(location => {
          let locIndex = locations.findIndex(x => x.name === location.city);
          if (locIndex === -1) {
            locations.push({
              id: location.city,
              name: location.city
            });
          }
          return false;
        });
        return false;
      });
    }
    locations.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    return locations;
  };
  // applyLocationFilter = (e, locations) => {
  //   e.persist();
  //   e.preventDefault();
  //   locationsArr = locations;
  //   this.ApplyFilter();
  // };
  applyCauseFilter = (e, id, name) => {
    e.persist();
    e.preventDefault();
    let checkListArr = [];
    let list = document.getElementsByName(name);

    for (let i = 0; i < list.length; i++) {
      if (
        list[i].checked === true &&
        list[i].value !== '' &&
        list[i].value !== undefined
      ) {
        checkListArr.push(parseInt(list[i].id));
      }
      if (id === 'primary-cause') {
        primary = checkListArr;
      } else if (id === 'secondary-cause') {
        secondary = checkListArr;
      }
    }
    this.ApplyFilter();
  };

  ApplyFilter = () => {
    if (
      primary === [] &&
      secondary === [] &&
      this.state.formFields.projectState.value === ''
    ) {
      this.props.getCampaignsForNgo(this.props.ngoData.ngo_id);
    }
    let filteredCampaigns = [];
    let selectedStatus = this.state.filters.status.toLowerCase();

    if (selectedStatus !== 'all') {
      let sortedCampaignsArr = this.originalCampaigns;
      if (primary.length > 0) {
        sortedCampaignsArr = sortedCampaignsArr?.filter(x => {
          const primaryCondn =
            x.primaryobjective !== [] &&
            primary.findIndex(
              a => a?.toString() === x.primaryobjective?.toString()
            ) > -1;
          if (primaryCondn) {
            return true;
          }
        });
      }
      if (secondary.length > 0) {
        sortedCampaignsArr = sortedCampaignsArr?.filter(x => {
          const secondaryCondn =
            x.secondaryobjective !== [] &&
            secondary.findIndex(
              a => a.toString() === x.secondaryobjective.toString()
            ) > -1;
          if (secondaryCondn) {
            return true;
          }
        });
      }
      // //filter for location
      // if (
      //   this.state.locationsArr !== '' &&
      //   this.state.locationsArr !== undefined &&
      //   this.state.locationsArr?.length > 0
      // ) {
      //   var locArr = [];
      //   var sortedListArr = [];

      //   sortedCampaignsArr.map(itm => {
      //     console.log("itm => " , itm)
      //     for (let i = 0; i < itm.location.length; i++) {
      //       //state
      //       if (
      //         locationsArr.includes(
      //           itm.location[i].state.toLowerCase().trim()
      //         ) ||
      //         locationsArr.includes(itm.location[i].city.toLowerCase().trim())
      //       ) {
      //         locArr.push(itm);
      //         sortedListArr.push(itm);
      //       }
      //       break;
      //     }
      //   });
      //   sortedCampaignsArr = sortedListArr;
      // }
      if (this.state.locationArr && this.state.locationArr.length > 0) {
        sortedCampaignsArr = sortedCampaignsArr.filter(campaign => {
          const value = this.state.locationArr.some(x => {
            let cityMatched = false;
            campaign.location.map(loc => {
              if (!cityMatched && loc.city === x) cityMatched = true;
              return false;
            });
            return cityMatched;
          });
          if (value) return true;
          return false;
        });
      }

      //remove duplicates
      if (sortedCampaignsArr) {
        sortedCampaignsArr.filter(
          (item, index) => sortedCampaignsArr.indexOf(item) === index
        );
        filteredCampaigns = sortedCampaignsArr.filter(
          x => x.status.toLowerCase() === selectedStatus
        );
      }
      if (
        this.state.location?.length === 0 &&
        this.state.primaryCondn?.length === 0 &&
        this.state.secondaryCondn?.length === 0
      ) {
        filteredCampaigns = this.originalCampaigns.filter(
          x => x.status.toLowerCase() === selectedStatus
        );
      }
    } else {
      filteredCampaigns = this.originalCampaigns.filter(x => {
        const primaryCondn =
          x.primaryobjective !== [] &&
          primary.findIndex(
            a => a.toString() === x.primaryobjective.toString()
          ) > -1;
        const secondaryCondn =
          x.secondaryobjective !== [] &&
          secondary.findIndex(
            a => a.toString() === x.secondaryobjective.toString()
          ) > -1;
        if (
          primaryCondn &&
          secondaryCondn &&
          this.state.formFields.projectState.value !== '' &&
          x.stateId === this.state.formFields.projectState.value
        )
          return true;
        if (
          primaryCondn &&
          secondaryCondn &&
          this.state.formFields.projectState.value === []
        )
          return true;
        if (
          this.state.formFields.projectState.value !== '' &&
          x.stateId === this.state.formFields.projectState.value
        )
          return true;

        if (
          primaryCondn &&
          this.state.formFields.projectState.value !== '' &&
          x.stateId === this.state.formFields.projectState.value
        )
          return true;
        if (primaryCondn) return true;
      });
    }
    this.setState({ allCampaigns: filteredCampaigns, clearFilter: false });
  };

  deleteDraftCampaign = (status, campaignId) => {
    if (status === Common.NGO.CAMPAIGN_STATUS.DRAFT) {
      this.props.deleteDraftCampaignForNGO(
        campaignId,
        this.props.ngoData.ngo_id
      );
      this.props.getCampaignsForNgo(this.props.ngoData.ngo_id);
    }
  };

  getCampaignsToFilter = (selectedStatus, filteredCampaigns, allCampaigns) => {
    return filteredCampaigns && filteredCampaigns.length
      ? filteredCampaigns
      : selectedStatus === 'all'
      ? allCampaigns
      : [];
  };

  filterData = () => {
    let allCampaigns = this.originalCampaigns
      ? JSON.parse(JSON.stringify(this.originalCampaigns))
      : null;
    let filteredCampaigns = [];
    let selectedStatus = this.state.filters.status.toLowerCase();

    if (selectedStatus !== 'all') {
      filteredCampaigns = filter(allCampaigns, o => {
        return (
          o.status && selectedStatus.split('|').includes(o.status.toLowerCase())
        );
      });
    }

    /* Apply the search term (search text box) */
    let searchTerm = this.state.filters.searchTerm.toLowerCase();
    if (this.state.filters.searchTerm !== '') {
      filteredCampaigns = filter(
        this.getCampaignsToFilter(
          selectedStatus,
          filteredCampaigns,
          allCampaigns
        ),
        o => {
          return o.title && o.title.toLowerCase().indexOf(searchTerm) > -1;
        }
      );
    }

    /* Apply the data range filter */
    const { fromDate, toDate } = this.state.filters.dateRange;
    if (fromDate && toDate) {
      filteredCampaigns = filter(
        this.getCampaignsToFilter(
          selectedStatus,
          filteredCampaigns,
          allCampaigns
        ),
        o =>
          dayjs(o.campaignStartDate).isBetween(
            new Date(fromDate),
            new Date(toDate)
          )
      );
    }

    return filteredCampaigns && filteredCampaigns.length
      ? filteredCampaigns
      : selectedStatus === 'all' && searchTerm === '' && !fromDate && !toDate
      ? allCampaigns
      : [];
  };

  getPercentage(
    campaignType,
    fundRaised,
    fundRemaining,
    volunterRequired,
    volunterRequestReceived
  ) {
    if (volunterRequired === 0 || fundRemaining === 0) {
      return 0;
    }
    if (campaignType === 'Volunteering') {
      return (+volunterRequestReceived / +volunterRequired) * 100;
    }
    return (+fundRaised / +fundRemaining) * 100;
  }

  getPathByStatus = (status, campaignId) => {
    let headTo = '';
    let email = '';
    switch (status) {
      case Common.NGO.CAMPAIGN_STATUS.ON_GOING_PROJECT:
        // headTo = '/ngo/mneSingleProject/' + campaignId;
        headTo = '/ngo/project/list';
        break;
      case Common.NGO.CAMPAIGN_STATUS.COMPLETED:
        headTo = '/ngo/mneSingleProject/' + campaignId;
        break;
      case Common.NGO.CAMPAIGN_STATUS.DRAFT:
        headTo = '/ngo/project/list';
        // if (!this.state.isSuperAdmin) {
        //   if (window.location.href.includes('email')) {
        //     email = window.location.href.split('email=')[1];
        //     headTo = 'detail/' + campaignId + '?email=' + email;
        //   } else {
        //     headTo = 'detail/' + campaignId;
        //   }
        // } else {
        //   if (window.location.href.includes('email')) {
        //     email = window.location.href.split('email=')[1];
        //     headTo = 'edit/' + campaignId + '?email=' + email;
        //   } else {
        //     headTo = 'edit/' + campaignId;
        //   }
        // }
        break;
      default:
        if (window.location.href.includes('email')) {
          email = window.location.href.split('email=')[1];
          headTo = 'detail/' + campaignId + '?email=' + email;
        } else {
          headTo = 'detail/' + campaignId;
        }
        break;
    }
    return headTo;
  };
  lengthOfLocation(locationArray) {
    let str = '';
    for (let i = 0; i < locationArray.length; i++) {
      if (i < locationArray.length - 1) {
        str += `${locationArray[i].city}, ${locationArray[i].state} | `;
      } else {
        str += `${locationArray[i].city}, ${locationArray[i].state} `;
      }
    }
    return str.length;
  }

  lengthOfCauseArea(causeArray) {
    let str = '';
    for (let i = 0; i < causeArray.length; i++) {
      if (i < causeArray.length - 1) {
        str += `${causeArray[i]}, `;
      } else {
        str += `${causeArray[i]} `;
      }
    }
    return str.length;
  }
  numberOfCampaignsInTooltip(locationArray) {
    let str = '';
    let count = 0;
    for (let i = 0; i < locationArray.length; i++) {
      if (i < locationArray.length - 1) {
        if (
          str.length +
            locationArray[i].city.length +
            locationArray[i].state.length +
            5 <
          50
        ) {
          str += `${locationArray[i].city}, ${locationArray[i].state} | `;
          count++;
        }
      } else {
        if (
          str.length +
            locationArray[i].city.length +
            locationArray[i].state.length +
            5 <
          50
        ) {
          str += `${locationArray[i].city}, ${locationArray[i].state} | `;
          count++;
        }
      }
    }
    return locationArray.length - count;
  }

  numberOfCauseAreaInTooltip(causeArray) {
    let str = '';
    let count = 0;
    for (let i = 0; i < causeArray.length; i++) {
      if (i < causeArray.length - 1) {
        if (str.length + causeArray[i].length + 1 < 35) {
          str += `${causeArray[i]}, `;
          count++;
        }
      } else {
        if (str.length + causeArray[i].length + 1 < 35) {
          str += `${causeArray[i]} `;
          count++;
        }
      }
    }
    return causeArray.length - count;
  }
  convertRupeesInCommaFormat = amount => {
    return amount?.toLocaleString();
  };
  renderNoCampaignsView = () => {
    const isReadOnly = this.props.permissions === 'R' ? true : false;
    return (
      <div className="row mt-4 justify-content-center">
        {/* <p className="h3">
          {this.state.filters.dateRange.fromDate ||
          this.state.filters.status !== 'ALL'
            ? 'There is nothing to display for this filter.'
            : 'There is nothing to display.'}
        </p> */}
        <p className="h3">
          {(this.state.filters.dateRange.fromDate ||
            this.state.filters.status !== 'ALL') &&
          !isReadOnly ? (
            <EmptySideScreenState
              pageName={'concept_note'}
              hide={!this.state.isSuperAdmin}
            />
          ) : (
            'There is nothing to display.'
          )}
        </p>
      </div>
    );
  };
  handlePrimaryAreas = (val, type) => {
    if (type === 'cause_category') {
      if (val.length > 0) {
        this.props.getSecondaryCauses(val).then(res => {
          this.setState({ secondaryCauses: res.sub_categories });
        });
      } else {
        this.setState({ secondaryCauses: [] }, () => {
          primary = [];
          secondary = [];
          this.ApplyFilter();
        });
      }
    }
    if (type === 'secondary_category') {
      const filteredCampaigns = this.originalCampaigns.filter(x =>
        secondary.includes(x.secondaryobjective)
      );
      this.setState({ dummy: filteredCampaigns });
    }
  };
  handleSecondaryCause = value => {
    if (value.length === 0) {
      this.setState({ secondaryCauses: [] }, () => {
        secondary = [];
        this.ApplyFilter();
      });
    }
  };

  checkFilterConditions = campaignDate => {
    if (this.state.dateRangeArray.length === 0) {
      return true;
    }
    let minDate = this.state.dateRangeArray[0];
    let maxDate = this.state.dateRangeArray[1];
    if (
      +campaignDate.year > +minDate.year &&
      +campaignDate.year <= +maxDate.year
    ) {
      return true;
    } else if (
      +campaignDate.year === +minDate.year &&
      +campaignDate.month > +minDate.month
    ) {
      return true;
    } else if (
      +campaignDate.year === +minDate.year &&
      +campaignDate.month === +minDate.month &&
      +campaignDate.date >= +minDate.date
    ) {
      return true;
    }
    return false;
  };
  handleRadioChange = e => {
    let filteredCampaigns = [];
    let selectedStatus = this.state.filters.status.toLowerCase();
    if (e.target.value === 'direct') {
      if (selectedStatus !== 'all') {
        filteredCampaigns = this.originalCampaigns.filter(
          x =>
            x.campaignType === 'Direct Funding' &&
            x.status.toLowerCase() === this.state.filters.status.toLowerCase()
        );
      } else {
        filteredCampaigns = this.originalCampaigns.filter(
          x => x.campaignType === 'Direct Funding'
        );
      }
    } else if (e.target.value === 'community') {
      if (selectedStatus !== 'all') {
        filteredCampaigns = this.originalCampaigns.filter(
          x =>
            x.campaignType === 'Community Funding' &&
            x.status.toLowerCase() === this.state.filters.status.toLowerCase()
        );
      } else {
        filteredCampaigns = this.originalCampaigns.filter(
          x => x.campaignType === 'Direct Funding'
        );
      }
    }
    this.setState({ allCampaigns: filteredCampaigns });
  };

  renderFilterBar = () => {
    // const { fromDate, toDate, enteredTo } = this.state.filters.dateRange;
    // const { fromDate, enteredTo } = this.state.filters.dateRange;
    // const modifiers = { start: fromDate, end: enteredTo };
    // const selectedDays = [fromDate, { from: fromDate, to: enteredTo }];
    const debouncedHandleChange = debounce(e => {
      this.setState(
        {
          filters: { ...this.state.filters, searchTerm: e.target.value }
        },
        () => {
          this.setState({ allCampaigns: this.filterData() });
        }
      );
    }, 300);

    // side panel filter commented code
    const primaryCauseArea = this.props.masterInfo
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.CAUSE_CATEGORY]
      : null;
    const secondaryCauseArea = this.state.secondaryCauses;
    const programLocationsList = this.props.masterInfo
      ? this.initProgramLocationList(this.originalCampaigns)
      : null;

    return (
      <>
        {
          // this.state.showFilterBox && (
          //   <div className=" pl-4 pr-4 ">
          //     <div id="mySidebar" className="sidebar-filter">
          //       <div className="d-flex justify-content-between align-items-center mx-4">
          //         <div
          //           className="filter-text fs-16"
          //           onClick={e => this.closeFilterBox(e)}>
          //           Filters
          //         </div>
          //         <div
          //           className="closebtn cursor-pointer"
          //           onClick={e => this.closeFilterBox(e)}>
          //           ×
          //         </div>
          //       </div>
          //       <form className="sidebar-lbl ml-4  pb-4 cursor-pointer">
          //         <div>
          //           <div style={{ marginRight: '20px' }}>
          //             <label>Primary Cause</label>
          //             <div className="causePrimary">
          //               {' '}
          //               <MultiSelect
          //                 id="primarycause"
          //                 uniqueIdentifierForInput={'cause_category'}
          //                 state={this.state}
          //                 name="cause_category"
          //                 value={primary}
          //                 options={primaryCauseArea}
          //                 handlePrimaryAreas={this.handlePrimaryAreas}
          //               />
          //             </div>
          //             <div className="tempclass">
          //               <MultiSelect
          //                 id="tempfield"
          //                 uniqueIdentifierForInput={'cause_category'}
          //                 state={this.state}
          //                 name="cause_category"
          //                 value={primary}
          //                 class="tempClass"
          //                 options={primaryCauseArea}
          //                 handlePrimaryAreas={this.handlePrimaryAreas}
          //               />{' '}
          //             </div>
          //             <hr className="w-100 my-5"></hr>
          //             <label>Secondary Cause</label>
          //             <div className="causePrimary">
          //               {' '}
          //               <MultiSelect
          //                 id="secondarycause"
          //                 uniqueIdentifierForInput={'secondary_category'}
          //                 state={this.state}
          //                 value={secondary}
          //                 name="secondary_category"
          //                 options={secondaryCauseArea}
          //                 handlePrimaryAreas={this.handlePrimaryAreas}
          //               />
          //             </div>
          //             <div className="tempclass">
          //               <MultiSelect
          //                 id="secCause"
          //                 uniqueIdentifierForInput={'secondary_category'}
          //                 state={this.state}
          //                 name="secondary_category"
          //                 value={secondary}
          //                 options={secondaryCauseArea}
          //                 handlePrimaryAreas={this.handlePrimaryAreas}
          //               />{' '}
          //             </div>
          //             <hr className="w-100 my-5"></hr>
          //              <InputSelect
          //               required={true}
          //               labelText="Project Country"
          //               id="projectCountry"
          //               name="projectCountry"
          //               options={countryList}
          //               fieldValue={this.state.formFields.projectCountry.value}
          //               fieldError={this.state.formFields.projectCountry.error}
          //               onHandleChange={this.onHandleChange}
          //               // onChangeCallback={this.setFormValidStatus}
          //               parentElement={true}
          //               childCallBack={this.fillStatesList}
          //               dataType="num"
          //               wrapperClassName={'w-100'}
          //             />
          //             <InputSelect
          //               required={true}
          //               labelText="Project State"
          //               id="projectState"
          //               name="projectState"
          //               options={stateList}
          //               fieldValue={this.state.formFields.projectState.value}
          //               fieldError={this.state.formFields.projectState.error}
          //               onHandleChange={this.onHandleChange}
          //               // onChangeCallback={this.setFormValidStatus}
          //               parentElement={true}
          //               // childCallBack={this.fillDistrictsList}
          //               dataType="num"
          //               disableInput={!this.state.formFields.projectCountry.value}
          //               wrapperClassName={'w-100 fs-14'}
          //             />
          //             <hr className="w-100 my-5"></hr>
          //             <div className="d-flex justify-content-between align-items-center mx-4">
          //               <div
          //                 className="filter-clear mt-5 link"
          //                 onClick={() => this.clearFilter()}>
          //                 Clear filter
          //               </div>
          //               <div className="filter-clear mt-5 link">
          //                 <button
          //                   type="button"
          //                   className="btn btn-primary  mx-auto d-block"
          //                   onClick={() => this.ApplyFilter()}>
          //                   Apply Filters
          //                 </button>
          //               </div>
          //             </div>
          //           </div>
          //         </div>
          //         <br></br>
          //       </form>
          //     </div>
          //   </div>
          // )
        }
        {/* //side panel filter commented code ends */}

        {/* <div
          className={`  pl-4 pr-4 mt-4 mb-4 ${this.state.showFilterBox ? 'list-on-filter col-8' : 'col-12 '
            }`}>
          <div className="row d-flex justify-content-between align-items-center">
            <div
              onClick={e => this.openFilterBox}
              id="mainTop"
              className="col-9">
              {!this.state.showFilterBox && (
                <button
                  className="openbtn fw-600"
                  onClick={e => this.openFilterBox(e)}>
                  Filters{' '}
                  <img
                    src="/images/filter.png"
                    style={{ borderRadius: 'unset' }}
                    height={14}
                    alt="icon"></img>
                </button>
              )}
              <div
                className={` d-inline-block  ${this.state.showFilterBox ? '' : ' ml-5'
                  }`}>
              </div>
            </div>

            <div className="col-3 text-right justify-content-end align-items-center">
              <span className="lead">
                {fromDate &&
                  toDate &&
                  `From ${fromDate.toLocaleDateString()} ~ to ${toDate.toLocaleDateString()}`}
              </span> 

              <button
                className="btn btn-link btn-lg pr-0"
                onClick={this.clickHandler.handleDateRangeClick}>
                <i className="fas fa-filter mr-2"></i>
                Date Range
              </button>
               <div className="col-12 position-absolute">
                <DayPicker
                  className={`date-range-picker bg-white ${this.state.isDateRangeShow ? '' : 'd-none'
                    }`}
                  numberOfMonths={2}
                  selectedDays={selectedDays}
                  modifiers={modifiers}
                  onDayClick={this.clickHandler.handleDayClick}
                />
              </div> 
            </div>
          </div>
        </div> */}

        {/* new filters  */}

        {/* 
        <section className="mt-4">
          <div className="row">
            <div className="col-md-3">
              <label>Primary Cause</label>
              <MultiSelect
                id="primarycause"
                uniqueIdentifierForInput={'cause_category'}
                state={this.state}
                name="cause_category"
                value={primary}
                options={primaryCauseArea}
                handlePrimaryAreas={this.handlePrimaryAreas}
              />
              <div className="tempclass">
                <MultiSelect
                  id="tempfield"
                  uniqueIdentifierForInput={'cause_category'}
                  state={this.state}
                  name="cause_category"
                  value={primary}
                  class="tempClass"
                  options={primaryCauseArea}
                  handlePrimaryAreas={this.handlePrimaryAreas}
                />{' '}
              </div>
            </div>

            <div className="col-md-3">
              <label>Secondary Cause</label>
              <MultiSelect
                id="secondarycause"
                uniqueIdentifierForInput={'secondary_category'}
                state={this.state}
                value={secondary}
                name="secondary_category"
                options={secondaryCauseArea}
                handlePrimaryAreas={this.handlePrimaryAreas}
              />
            </div>

            <div className="col-md-3">
               <InputSelect
                required={false}
                labelText="Project State"
                id="projectState"
                name="projectState"
                options={stateList}
                fieldValue={this.state.formFields.projectState.value}
                fieldError={this.state.formFields.projectState.error}
                onHandleChange={this.onHandleChange}
                // onChangeCallback={this.setFormValidStatus}
                parentElement={true}
                // childCallBack={this.fillDistrictsList}
                dataType="num"
                // disableInput={!this.state.formFields.projectCountry.value}
                wrapperClassName={'w-100 fs-14'}
              /> 
            </div>

            <div className="col-md-3 mt-4 ">
              <button
                className="btn btn-link btn-lg pr-0"
                onClick={this.clickHandler.handleDateRangeClick}>
                <i className="fas fa-filter mr-2"></i>
                Date Range
              </button>
              <div className="col-12 position-absolute">
                 <DayPicker
                  className={`date-range-picker bg-white ${this.state.isDateRangeShow ? '' : 'd-none'
                    }`}
                  numberOfMonths={2}
                  selectedDays={selectedDays}
                  modifiers={modifiers}
                  onDayClick={this.clickHandler.handleDayClick}
                /> 
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center mx-4">
            <div className="filter-clear link">
              <button
                type="button"
                className="btn btn-primary  mx-auto d-block"
                onClick={() => this.ApplyFilter()}>
                Apply Filters
              </button>
            </div>
            <div
              className="filter-clear cursor-pointer ml-4 link"
              onClick={() => this.clearFilter()}>
              Clear filter
            </div>
          </div>
        </section> */}
        <div
          className={`pl-4 pr-4 mt-4 mb-4 ${
            this.state.showFilterBox ? 'list-on-filter col-8 ' : 'col-12 '
          } ${!this.state.allCampaigns?.length > 0 ? 'd-none' : ''}`}>
          <div className="row justify-content-between align-items-center ">
            <div className="row col-6 align-items-center">
              <div
                className="transparent input-group p-0"
                style={{ width: '300px' }}>
                <div
                  className="input-group-prepend position-absolute"
                  style={{ zIndex: '9999' }}>
                  <i
                    className="input-group-text fa fa-search form-control-feedback position-relative"
                    style={{ left: '270px', color: '#969696' }}></i>
                </div>
                <input
                  className="form-control"
                  style={{
                    border: '1px solid var(--border_gray)',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 1px 1px #0000000F',
                    borderRadius: '5px'
                  }}
                  type="text"
                  defaultValue={this.state.filters.searchTerm}
                  onChange={e => {
                    e.persist();
                    debouncedHandleChange(e);
                  }}
                  placeholder="Search Project by Name"
                />
              </div>
              <button
                className="ml-2 btnToggle"
                onClick={() => this.handleToggleFilter()}
                style={{
                  border: '1px solid var(--border_gray)',
                  background: '#FFFFFF 0% 0% no-repeat padding-box',
                  boxShadow: '0px 1px 1px #0000000F',
                  borderRadius: '5px'
                }}>
                <i className="material-icons" style={{ color: '#969696' }}>
                  tune
                </i>
              </button>
            </div>
          </div>
        </div>

        <section
          className={`mt-4 ${
            this.state.ishandleToggleFilter ? 'd-block' : 'd-none'
          }`}>
          <div className="row d-flex mx-4">
            {/* <div>
              <DateDropdown
                dateRangeArray={this.state.dateRangeArray}
                handleDateRange={this.handleDateRange}
                onChange={this.onChange}
                // checkFilterConditions={this.checkFilterConditions}
              />
            </div> */}

            <FilterOptionsDropdownChecklist
              options={primaryCauseArea}
              title={'Primary Cause'}
              id={'primary-cause'}
              name={`primary-cause-checkbox`}
              searchTerm={this.state.filters.searchTerm}
              debouncedHandleChange={debouncedHandleChange}
              applyFilterFunction={this.ApplyFilter}
              applyCauseFilter={this.applyCauseFilter}
              checkedCount={this.state.checkedCount}
              handlePrimaryAreas={
                this.handlePrimaryAreas
              }></FilterOptionsDropdownChecklist>

            <FilterOptionsDropdownChecklist
              options={secondaryCauseArea}
              title={'Secondary Cause'}
              name={`secondary-cause-checkbox`}
              id={'secondary-cause'}
              searchTerm={this.state.filters.searchTerm}
              debouncedHandleChange={debouncedHandleChange}
              applyFilterFunction={this.ApplyFilter}
              checkedCount={this.state.checkedCount}
              applyCauseFilter={this.applyCauseFilter}
              handleChange={
                this.handleSecondaryCause
              }></FilterOptionsDropdownChecklist>
            {/* <LocationDropdown
              title={'Location'}
              id={'Location'}
              locationArr={this.state.locationArr}
              applyLocationFilter={this.applyLocationFilter}
              checkedCount={this.state.checkedCount}
              // applyFilterFunction={this.ApplyFilter}
              // applyCauseFilter={
              //   this.applyCauseFilter
              // }
              handleLocation={this.handleLocation}/> */}
            <FilterOptionsDropdownChecklist
              options={programLocationsList}
              title={'Location'}
              id={'location'}
              name={`location`}
              searchTerm={this.state.filters.searchTerm}
              wrapperClass={'mr-4'}
              debouncedHandleChange={debouncedHandleChange}
              applyFilterFunction={this.ApplyFilter}
              applyCauseFilter={this.applyCauseFilter}
              checkedCount={this.state.checkedCount}
              handleChange={this.handleLocation}
              locationChild={this.state.locationArr}
            />
            <button
              className="btn btn-link p-0 btn-lg"
              onClick={() => this.clearFilter()}>
              Clear filters
            </button>

            {/* <div className="col-md-3">
              <InputSelect
                required={false}
                labelText="Project State"
                id="projectState"
                name="projectState"
                options={stateList}
                fieldValue={this.state.formFields.projectState.value}
                fieldError={this.state.formFields.projectState.error}
                onHandleChange={this.onHandleChange}
                // onChangeCallback={this.setFormValidStatus}
                parentElement={true}
                // childCallBack={this.fillDistrictsList}
                dataType="num"
                // disableInput={!this.state.formFields.projectCountry.value}
                wrapperClassName={'w-100 fs-14'}
              />
            </div> */}
            {/* <div className="col-md-3 mt-4 ">
              <button
                className="btn btn-link btn-lg pr-0"
                onClick={this.clickHandler.handleDateRangeClick}>
                <i className="fas fa-filter mr-2"></i>
                Date Range
              </button>
              <div className="col-12 position-absolute">
                <DayPicker
                  className={`date-range-picker bg-white ${
                    this.state.isDateRangeShow ? '' : 'd-none'
                  }`}
                  numberOfMonths={2}
                  selectedDays={selectedDays}
                  modifiers={modifiers}
                  onDayClick={this.clickHandler.handleDayClick}
                />
              </div>
            </div> */}
          </div>
          {/* <div className="col-6"> */}

          {/* </div> */}
        </section>

        {/* new filters code ends */}
      </>
    );
  };

  editCampaign = (e, status, campaignId) => {
    if (window.location.href.includes('email')) {
      let email = window.location.href.split('email=')[1];
      window.location.href = 'edit/' + campaignId + '?email=' + email;
    } else window.location.href = 'edit/' + campaignId;
  };
  duplicateCampaign = campaignId => {
    this.props.duplicateCampaignForNgo(campaignId);
    setTimeout(() => {
      if (window.location.href.includes('email')) {
        let email = window.location.href.split('email=')[1];
        this.props.history.push(`/ngo/project/list?email=${email}`);
      } else {
        this.props.history.push(`/ngo/project/list`);
      }
      // window.location.href = '/ngo/project/list';
    }, 2000);
  };
  renderCampaignList = () => {
    const imageContainerRef = React.createRef();
    const isImpersonation = window.location.href.includes('email=');

    // if(this.state.allCampaigns==undefined || this.state.allCampaigns.length===0)
    // return( <EmptySideScreenState pageName={'campaign'} /> )

    return (
      <div className={'col-12'}>
        <div className={`row`}>
          {this.state.allCampaigns.map((campaign, index) => {
            let campaignDate = {
              year: campaign.created_on.split(' ')[0].split('-')[0],
              month: campaign.created_on.split(' ')[0].split('-')[1],
              date: campaign.created_on.split(' ')[0].split('-')[2]
            };

            var showStartReportButton = false;

            if (
              campaign.approved_funds !== '' &&
              campaign.approved_funds !== null &&
              campaign.approved_funds !== undefined
            ) {
              showStartReportButton = true;
            }

            if (this.checkFilterConditions(campaignDate)) {
              return (
                <article className={` col-4 mt-5`} key={campaign.campaignId}>
                  <div
                    className="card col-12 p-0  cardHover"
                    style={{
                      borderRadius: '5px',
                      overflow: 'visible',
                      transition: 'box-shadow .5s'
                    }}>
                    <div
                      className="card position-relative loading-image-placeholder loading-bg"
                      ref={imageContainerRef}
                      style={{
                        boxShadow: 'none',
                        paddingTop: '40%',
                        overflow: 'hidden',
                        borderRadius: '5px 5px 0px 0px'
                      }}>
                      <NavLink
                        onClick={() => {
                          if (campaign.status === 'DRAFT') {
                            window.open(
                              `${process.env.REACT_APP_NEW_EDIT_PROJECT}${
                                campaign.campaignId
                              }${
                                window.location.href.includes('email')
                                  ? `?email=${
                                      window.location.href.split('email=')[1]
                                    }#metta-analyst`
                                  : ''
                              }`,
                              '_blank'
                            );
                          } else if (
                            campaign.status ===
                            Common.NGO.CAMPAIGN_STATUS.ON_GOING_PROJECT
                          ) {
                            window.open(
                              `${process.env.REACT_APP_NEW}dashboard/single-mne-project/${campaign.campaignId}/dashboard`,
                              '_blank'
                            );
                          }
                        }}
                        to={this.getPathByStatus(
                          campaign.status,
                          campaign.campaignId
                        )}>
                        {!campaign.video && (
                          <img
                            style={{
                              height: 'auto',
                              width: '100%',
                              // marginBottom: '200px',
                              position: 'absolute',
                              top: '-30%'
                            }}
                            loading={index < 6 ? 'eager' : 'eager'}
                            src={
                              getCloudinaryUrlForImage(campaign.image, 400) ||
                              getCloudinaryUrlForImage(
                                'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                                400
                              )
                            }
                            data-src={
                              campaign.image ||
                              'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                            }
                            onError={e =>
                              (e.target.src = e.target.getAttribute('data-src'))
                            }
                            alt={campaign.title}
                            className="abs-full"
                          />
                        )}
                      </NavLink>

                      <span className="p-2 d-inline-block abs-top-right  text-light rounded-cross-nw"></span>
                    </div>

                    <div className="row card-body px-4 pt-4 mt-1 align-items-center border-none">
                      <NavLink
                        onClick={() => {
                          if (campaign.status === 'DRAFT') {
                            window.open(
                              `${process.env.REACT_APP_NEW_EDIT_PROJECT}${
                                campaign.campaignId
                              }${
                                window.location.href.includes('email')
                                  ? `?email=${
                                      window.location.href.split('email=')[1]
                                    }#metta-analyst`
                                  : ''
                              }`,
                              '_blank'
                            );
                          } else if (
                            campaign.status ===
                            Common.NGO.CAMPAIGN_STATUS.ON_GOING_PROJECT
                          ) {
                            window.open(
                              `${process.env.REACT_APP_NEW}dashboard/single-mne-project/${campaign.campaignId}/dashboard`,
                              '_blank'
                            );
                          }
                        }}
                        to={this.getPathByStatus(
                          campaign.status,
                          campaign.campaignId
                        )}
                        className="col-12 p-0 text-dark text-decoration-none"
                        style={{ overflow: 'visible' }}
                        exact>
                        <h4 className="card-title mb-4 text-overflow-ellipsis text-212529">
                          {campaign.title}
                        </h4>
                      </NavLink>
                      <dl className="lead dm-regular text-969696 fs-14 text-capitalize col-12 p-0">
                        <span>Location: </span>

                        {campaign.location?.length > 0 && (
                          <span className="text-1C1C1C">
                            <LocationMain locations={campaign.location} />
                          </span>
                        )}
                        <span
                          id={'location_' + campaign.campaignId}
                          className="toggleLocation"></span>
                        {this.lengthOfLocation(campaign?.location) > 48 && (
                          <span
                            id={'text_' + campaign.campaignId}
                            className="cursor-pointer text-007bff tooltipss">
                            {' +' +
                              this.numberOfCampaignsInTooltip(
                                campaign?.location
                              ) +
                              ' more'}
                            <span className=" tooltipss-location">
                              <LocationTooltip locations={campaign?.location} />
                            </span>
                          </span>
                        )}
                        <p></p>
                        <span>Primary Cause Area: </span>
                        {campaign.primaryobjectiveSlug?.length > 0 && (
                          <span className="text-1C1C1C">
                            <CauseAreaMain
                              causeArea={campaign?.primaryobjectiveSlug}
                            />
                          </span>
                        )}
                        <span
                          id={'location_' + campaign.campaignId}
                          className="toggleLocation"></span>
                        {this.lengthOfCauseArea(
                          campaign?.primaryobjectiveSlug
                        ) > 33 && (
                          <span
                            id={'text_' + campaign.campaignId}
                            className="cursor-pointer text-007bff tooltipss">
                            {' +' +
                              this.numberOfCauseAreaInTooltip(
                                campaign.primaryobjectiveSlug
                              ) +
                              ' more'}
                            <span className=" tooltipss-location">
                              <CauseAreaTooltip
                                causeArea={campaign.primaryobjectiveSlug}
                              />
                            </span>
                          </span>
                        )}

                        <div className="d-flex justify-content-between">
                          <p className="fs-20 text-81BC30 mt-3">
                            {'₹ '}
                            {this.convertRupeesInCommaFormat(
                              campaign.fund_amount
                            )}
                          </p>
                          {(campaign.status ===
                            Common.NGO.CAMPAIGN_STATUS.DRAFT ||
                            campaign.status ===
                              Common.NGO.CAMPAIGN_STATUS.UNDER_REVIEW ||
                            campaign.status ===
                              Common.NGO.CAMPAIGN_STATUS.WAITING_FOR_FUNDING ||
                            campaign.status ===
                              Common.NGO.CAMPAIGN_STATUS.FUNDED ||
                            campaign.status ===
                              Common.NGO.CAMPAIGN_STATUS.ON_GOING_PROJECT ||
                            campaign.status ===
                              Common.NGO.CAMPAIGN_STATUS.COMPLETED) && (
                            <div
                              className={`dropdown  bg-none d-flex align-items-center ${
                                campaign.status ===
                                Common.NGO.CAMPAIGN_STATUS.FUNDED
                                  ? 'option-funded-dots'
                                  : 'option-popup'
                              }`}>
                              {(isImpersonation ||
                                isAuthorized(this.props.permissions)) && (
                                <img
                                  id={`popup_${campaign.campaignId}`}
                                  data-toggle="dropdown"
                                  className="cursor-pointer dropdown-toggle mr-2 "
                                  src={EditIcon}
                                  width={30}
                                  alt="editIcon"></img>
                              )}

                              <div className="dropdown-menu shadow ">
                                {campaign.status === 'DRAFT' && (
                                  <>
                                    <span
                                      className="dropdown-item"
                                      onClick={() =>
                                        this.deleteDraftCampaign(
                                          campaign.status,
                                          campaign.campaignId
                                        )
                                      }>
                                      Delete
                                    </span>
                                  </>
                                )}
                                {(campaign.status === 'DRAFT' ||
                                  campaign.status === 'UNDER_REVIEW' ||
                                  campaign.status ===
                                    'WAITING_FOR_FUNDING') && (
                                  <>
                                    <span
                                      onClick={() => {
                                        window.open(
                                          `${process.env.REACT_APP_NEW_EDIT_PROJECT}${campaign.campaignId}`,
                                          '_blank'
                                        );
                                      }}
                                      className="dropdown-item cursor-pointer"
                                      href="#">
                                      Edit
                                    </span>
                                  </>
                                )}

                                <span
                                  onClick={e =>
                                    this.duplicateCampaign(campaign.campaignId)
                                  }
                                  className="dropdown-item"
                                  href="#">
                                  Duplicate
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </dl>
                    </div>

                    {isAuthorized(this.props.permissions) &&
                      showStartReportButton &&
                      campaign.status === 'FUNDED' && (
                        <>
                          {/* <hr></hr> */}
                          <div
                            className="card-footer bg-white p-3 mb-2 border-none text-center cursor-pointer"
                            onClick={e =>
                              this.startProject(e, campaign.campaignId)
                            }>
                            <div className="btn btn-primary btn-lg w-100 mb-3 cursor-pointer">
                              Start Project
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </article>
              );
            }
          })}
        </div>
      </div>
    );
  };

  render() {
    let tabsArr = [];
    let rejectedTab = [];
    let defaultTab =
      this.props.permissions === 'R'
        ? Common.NGO.CAMPAIGN_STATUS.FUNDED
        : Common.NGO.CAMPAIGN_STATUS.DRAFT;
    if (this.state.allCampaigns === null) {
      return <Loader loaderText="Loading..." />;
    }

    this.props.campaignInfo.statusTypes.map((tab, index) => {
      if (
        this.props.permissions === 'R' &&
        (tab.code === 'DRAFT' ||
          tab.code === 'UNDER_REVIEW' ||
          tab.code === 'WAITING_FOR_FUNDING' ||
          tab.code === 'REJECTED')
      )
        return false;
      if (tab.label !== 'Rejected') {
        if (tab.label !== 'Live') {
          tabsArr.push(tab);
        }
      } else {
        rejectedTab = tab;
      }
      if (this.props.campaignInfo.statusTypes.length === index + 1) {
        tabsArr.push(rejectedTab);
      }
    });
    if (window.location.href.includes('campaign=')) {
      defaultTab = window.location.href.split('campaign=')[1];
    }

    return (
      <div className="row">
        <div className="col-12 p-0">
          <Tabs
            // tabs={[
            //   {
            //     label: 'All',
            //     count: this.originalCampaigns.length,
            //     code: 'ALL'
            //   }
            // ].concat(tabsArr)}
            tabs={tabsArr.slice(1)}
            handlers={this.clickHandler}
            clickHandlerName={'handleTabClick'}
            // defaultSelectionValue={this.state.filters.status} //uncomment to allow ALL tab highlighting in blue color
            defaultSelectionValue={defaultTab}
          />
          {this.renderFilterBar()}
          {this.state.allCampaigns?.length ? this.renderCampaignList() : null}
          {!this.state.allCampaigns?.length && this.renderNoCampaignsView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaignInfo: state.campaign.campaignInfo,
  userInfo: state.login.userInfo,
  ngoData: state.ngo.ngoProfile,

  masterInfo: state.common.masterInfo,
  masterSecondaryInfo: state.common.masterSecondaryInfo,
  masterSecondaryResponseStatus: state.common.masterSecondaryResponseStatus,
  listCountries: state.common.listCountries,
  listStates: state.common.listStates,
  listDistricts: state.common.listDistricts,
  createModifySuccessResponse: state.campaign.createModifySuccessResponse,
  createModifyErrorResponse: state.campaign.createModifyErrorResponse
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCampaignsForNgo,
      deleteDraftCampaignForNGO,
      getMasterData,
      getMasterDataById,
      getSecondaryCauses,
      createOrModifyCampaign,
      startProject,
      duplicateCampaignForNgo
      // getStatesDataById,
      // getDistrictsDataById
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsList);
