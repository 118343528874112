import React, { useState } from 'react';
import './Styles.css';
import Table from './commonTable';
import Loader from '../loader';
import { allProjectColumn } from '../../constants/MNEDashboard.jsx';
import Pagination from '../pagination';

const columns = allProjectColumn;
const postsPerPage = 30;

function AllProjects(props) {
  const [currentPage, setCurrentPage] = useState(1);
  let projectListData = paginationRecords(props.list);

  function paginationRecords(data) {
    let currentTableData = [];
    if (data && data.length > 0) {
      const lastPageIndex = currentPage * postsPerPage;
      const firstPageIndex = lastPageIndex - postsPerPage;
      currentTableData = data.slice(firstPageIndex, lastPageIndex);
    }
    return currentTableData;
  }
  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };
  const NoDataView = (
    <p className="h3 text-center p-4">There is nothing to display here.</p>
  );

  if (!props.fetchAllProject) {
    return <Loader loaderText="Loading..." />;
  }

  return (
    <div>
      {props.fetchAllProject ? (
        <>
          <Table
            column={columns}
            projectStatus={props.projectStatus}
            row={projectListData}
          />

          {props.list.length > postsPerPage && (
            <div className="col-12 row justify-content-center">
              <div>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={props.list.length}
                  paginate={paginate}></Pagination>
              </div>
            </div>
          )}
        </>
      ) : (
        NoDataView
      )}
    </div>
  );
}

export default AllProjects;
