import React from 'react';
import Header from '../../../components/header';
import PageContentWrapper from '../../../components/pageContentWrapper';
import './dashboard.css';
import allocatedFunds from '../../../assets/images/Allocated-Funds.svg';
import balancedFunds from '../../../assets/images/Balance-Funds.svg';
import disbursedFunds from '../../../assets/images/Disbursed-Funds.svg';
import dayjs from 'dayjs';
import AllProjects from '../../../components/newDesign/allProjects';
import DonutCategoryChart from '../../../components/donutChart/donutCategoryChart';
import DonutFundsChart from '../../../components/donutChart/donutFundsChart';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { getNgoProfile } from '../../../actions';
import {
  getAllProjects,
  getFundInformation
} from '../../../actions/mneDashboard.actions';
import { getNgoDashboardData } from '../../../actions/ngoDashboard.actions';
import Icon1 from '../../../assets/icons/icon-ongoing-camp.png';
import Icon2 from '../../../assets/icons/icon-complete-project.png';
import Icon3 from '../../../assets/icons/icon-ongoing-project.png';
import Icon4 from '../../../assets/icons/icon-beneficiaries.png';
import { convertInCommaFormat } from '../../../helpers/commaSeparatedInput';
import EmptySideScreenState from '../../../components/EmptySideScreenState';
var totalApprovedFundAmount = 0;
// import FundUtilizations from '../../../components/newDesign/fundUtilization';
class MNEDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectStatus: 'Total',
      ngoDashboardData: [],
      allocatedFunds: [],
      utilizedFund: [],
      allocatedFund: [],
      allProject: [],
      categoryListArr: [],
      activeIdx: 2,
      colorCode: [
        '#DE1D1F',
        '#4752A4',
        '#ACE556',
        '#A72C8F',
        '#31AEFD',
        '#344256',
        '#81BC30',
        '#B2B2B2',
        '#7B8FA6',
        '#6495ED',
        '#CCCCFF',
        '#CCE619',
        '#CC9900',
        '#DB3B00',
        '#967981',
        '#267d50',
        '#13766D',
        '#ADC7F5',
        '#00007A',
        '#800000',
        '#FFFF00',
        '#808000',
        '#00FF00',
        '#008000',
        '#00FFFF',
        '#008080',
        '#0000FF',
        '#000080',
        '#FF00FF',
        '#800080'
      ],
      ngoData: []
    };
  }
  componentDidMount() {
    this.getNgoDashboardDetails();
    this.getAllProjects();
    // this.props.getNgoProfile(this.props.userInfo);
    // this.getDashboardFundInformation();
  }
  calculateDays = (startDate, endDate) => {
    let date_obj1 = dayjs(startDate);
    let date_obj2 = dayjs(endDate);
    return (this.dateDiff = date_obj2.diff(date_obj1, 'days'));
  };
  getNgoDashboardDetails = () => {
    this.props.getNgoDashboardData().then(res => {
      const categoryList = res?.category_list?.reduce((itm, curr) => {
        const item = itm.find(
          item => item.primary_category === curr.primary_category
        );
        if (item) {
          item.category_expense += curr.category_expense;
        } else {
          itm.push({
            primary_category: curr.primary_category,
            category_expense: curr.category_expense
          });
        }
        return itm;
      }, []);

      this.setState({
        ngoDashboardData: res,
        categoryListArr: categoryList,
        ngoData: [
          {
            image: Icon1,
            title: 'Total Number of In-Review Projects',
            value:
              res?.no_of_campaigns_under_review === undefined ||
              res?.no_of_campaigns_under_review === 0
                ? 0
                : convertInCommaFormat(res?.no_of_campaigns_under_review)
          },
          {
            image: Icon2,
            title: 'Total Number of Completed Projects',
            value:
              res?.no_of_completed_projects === undefined ||
              res?.no_of_completed_projects === 0
                ? 0
                : convertInCommaFormat(res?.no_of_completed_projects)
          },
          {
            image: Icon3,
            title: 'Total Number of Ongoing Projects',
            value:
              res?.no_of_ongoing_projects === undefined ||
              res?.no_of_ongoing_projects === 0
                ? 0
                : convertInCommaFormat(res?.no_of_ongoing_projects)
          },
          {
            image: Icon4,
            title: 'Total Beneficiaries Benefitted',
            value:
              res?.no_of_beneficiaries === undefined ||
              res?.no_of_beneficiaries === 0
                ? 0
                : convertInCommaFormat(res?.no_of_beneficiaries)
          }
        ]
      });
    });
  };

  getAllProjects = () => {
    let projectArr = [];
    // let status = '';
    // let date = new Date();

    var total = 0;
    this.props.getFundInformation(this.props.userProfile.sub).then(res => {
      // console.error('resss', res);
      if (res?.length > 0) {
        res.forEach(function(value, key) {
          total =
            total +
            parseInt(value.sanctioned !== 'None' ? value.sanctioned : 0);
        });
        totalApprovedFundAmount = total;
      }
    });

    setTimeout(() => {
      this.props
        .getAllProjects(this.props.userProfile.sub)
        .then(res => {
          res.list.map(item => {
            // console.log("res",res);
            // let days = this.calculateDays(
            //   item.actual_project_start_date !== 'None'
            //     ? item.actual_project_start_date
            //     : item.planned_start_date,
            //   date
            // );

            // if (item.is_project_complete === true) {
            //   status = 'Complete';
            // } else if (days < 0) status = 'Upcoming';
            // else if (days === 0) status = 'Ongoing';
            let obj = {
              id: item.id,
              name: item.name,
              description: item.description,
              actual_start_date: item.actual_project_start_date,
              actual_end_date: item.actual_project_end_date,
              planned_start_date: item.planned_start_date,
              planned_end_date: item.planned_end_date,
              allocated_funds: item.allocated_funds,
              status: item.status === 'FUNDED' ? 'Upcoming' : item.status
            };
            projectArr.push(obj);
          });
          projectArr.sort((a, b) => {
            const timeStamp1 = new Date(a.actual_start_date);
            const timeStamp2 = new Date(b.actual_start_date);
            if (timeStamp1.getTime() > timeStamp2.getTime()) {
              return -1;
            }
            if (timeStamp1.getTime() < timeStamp2.getTime()) {
              return 1;
            }
            return 0;
          });

          this.setState({ allProject: projectArr });
          this.setState({
            utilizedFund: [
              {
                name: 'Balance Funds',
                value: res.total_allocated_funds - totalApprovedFundAmount || 0,
                fill: '#E47E09'
              },
              {
                name: 'Disbursed Funds',
                value: totalApprovedFundAmount || 0,
                fill: '#DFCF03'
              }
            ],
            allocatedFund: [
              {
                name: 'Allocated Funds',
                value: res.total_allocated_funds || 0,
                fill: '#007997'
              }
            ]
          });
        })
        .catch(async err => {});
    }, 2000);
  };

  // getDashboardFundInformation = () => {
  //   this.props.getFundInformation(this.props.userProfile.sub).then(res => {
  //     console.error(res);
  //   });
  // };
  filterProject = value => {
    let orginal_list = [...this.state.allProject];
    let filteredList = '';

    if (value !== '') {
      filteredList = orginal_list.filter(item => {
        const lc = item && item.name && item.name.toLowerCase();
        const filter = value.toLowerCase();

        return lc && lc.includes(filter);
      });
    } else {
      this.getAllProjects();
    }

    this.setState({
      allProject: filteredList
    });
  };

  formatNumberToCurrency = value => {
    if (value) {
      return `₹ ${new Intl.NumberFormat('en-IN').format(value)}`;
    }
    return '';
  };
  render() {
    if (
      this.state?.ngoData[0]?.value === 0 &&
      this.state?.ngoData[1]?.value === 0 &&
      this.state?.ngoData[2]?.value === 0 &&
      this.state?.ngoData[3]?.value === 0
    ) {
      return (
        <React.Fragment>
          <Header pageTitle={`NGO Dashboard`} />
          <PageContentWrapper>
            {this.props.profileCompleted?.l1_status === 'l1_approved' ? (
              <EmptySideScreenState pageName={'dashboard'} />
            ) : this.props.profileCompleted?.l0_status === 'l0_approved' ? (
              <EmptySideScreenState pageName={'mission'} />
            ) : this.props.profileCompleted?.l0_status === 'l0_approved' &&
              this.props.profileCompleted?.l1_status === 'l1_approved' ? (
              <EmptySideScreenState pageName={'dashboard'} />
            ) : (
              <EmptySideScreenState pageName={''} />
            )}
          </PageContentWrapper>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Header pageTitle={`NGO Dashboard`} />
        <PageContentWrapper>
          {/*********Cards Section *******************/}
          <div className="my-3">
            <div className="ml-2 mr-2">
              {this.state.ngoData.map((item, index) => {
                return (
                  <>
                    <div className="column mb-3" key={index}>
                      <div className="ngo-card ">
                        <div className="text-center mt-3">
                          <img src={item.image} alt="icon" width={50}></img>
                        </div>
                        <p className="lato-medium fs-16 text-344256 mt-3">
                          {item.value}
                        </p>
                        <p className="lato-regular fs-14 text-7B8FA6">
                          {item.title}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            {/********* Funds Utilisation Donut Chart Section ********/}
            <div className="row">
              <div className="col-6 ">
                <div className=" bg-white p-4">
                  <div className="fs-16 lato-medium text-344256">
                    FUNDS UTILISATION
                  </div>
                  <div className="d-flex justify-content-center funds-section mt-3">
                    <DonutFundsChart
                      size={'90%'}
                      utilizedFund={this.state.utilizedFund}
                      allocatedFund={this.state.allocatedFund}
                      colorCode={['#E47E09', '#DFDF03']}></DonutFundsChart>
                  </div>

                  <div className="fundBox">
                    <div className="row">
                      <div className="funds-card col-6 d-flex mt-4">
                        <img
                          src={allocatedFunds}
                          alt="AllocatedFunds"
                          width={50}
                          height={50}
                        />
                        <div className="d-flex flex-column mx-3 justify-content-between">
                          <p className="text-344256 fs-12 m-0">
                            Allocated Funds
                          </p>
                          <h3 className="text-344256 fs-20 m-0">
                            {this.formatNumberToCurrency(
                              this.state.allocatedFund[0]?.value
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="funds-card col-6 d-flex my-5">
                        <img
                          src={disbursedFunds}
                          alt="disbursedFunds"
                          width={50}
                          height={50}
                        />
                        <div className="d-flex flex-column mx-3 justify-content-between">
                          <p className="text-344256 fs-12 m-0">
                            Disbursed Funds
                          </p>
                          <h3 className="text-344256 fs-20 m-0">
                            {this.formatNumberToCurrency(
                              this.state.utilizedFund[1]?.value
                            )}
                          </h3>
                        </div>
                      </div>
                      <div className="funds-card col-6 d-flex my-5">
                        <img
                          src={balancedFunds}
                          alt="balancedFunds"
                          width={50}
                          height={50}
                        />
                        <div className="d-flex flex-column mx-3 justify-content-between">
                          <p className="text-344256 fs-12 m-0">
                            Yet to be Disbursed Funds
                          </p>
                          <h3 className="text-344256 fs-20 m-0">
                            {this.formatNumberToCurrency(
                              parseInt(this.state.allocatedFund[0]?.value) -
                                parseInt(totalApprovedFundAmount)
                            )}
                            {/* {new Intl.NumberFormat('en-IN').format(
                              this.state.utilizedFund[0]?.value
                            )} */}
                            {/* {convertInCommaFormat(
                              this.state.utilizedFund[0]?.value
                            )} */}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/********* Funds Mobilised Donut Chart Section ********/}
              <div
                className="bg-white px-4 py-4"
                style={{ height: '56.5vh', width: '49%' }}>
                <div className="fs-16 lato-medium text-344256">
                  FUNDS MOBILISED BY CATEGORIES
                </div>
                <div className="d-flex funds-section mt-3">
                  <div className="mt-6">
                    <DonutCategoryChart
                      size={'60%'}
                      // categoryList={this.state.ngoDashboardData?.category_list}
                      categoryList={this.state.categoryListArr}
                      colorCode={this.state.colorCode}></DonutCategoryChart>
                  </div>
                  <div className="float-right p-2 funds-table overflow-funds-table">
                    <table>
                      <tbody>
                        <tr key={'main'}>
                          <th>Cause Category</th>
                          <th>Funds Mobilised (₹)</th>
                        </tr>
                        {/* {this.state.ngoDashboardData?.category_list?.map( */}
                        {this.state.categoryListArr?.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td className="d-flex">
                                  <ul style={{ listStyleType: 'circle' }}>
                                    <li
                                      className="mt-3"
                                      style={{
                                        color: this.state.colorCode[index],
                                        fontSize: '22px'
                                      }}></li>
                                  </ul>
                                  <span className="mt-4 text-344256">
                                    {item.primary_category}
                                  </span>
                                </td>
                                <td className="text-344256">
                                  {'₹ '}
                                  {new Intl.NumberFormat('en-IN').format(
                                    item.category_expense
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/********* ALL Projects Table Section ********/}
            <div className="p-4">
              <div className="w-100 bg-white">
                <div className="p-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-4">
                      <h1 className="text-344256 fs-20 mb-2 montserrat">
                        On Going Projects
                      </h1>
                      <hr align="left" className="w-100" />
                    </div>
                    <div className="transparent input-group col-3 p-0">
                      <div className="input-group-prepend">
                        <i className="input-group-text fa fa-search form-control-feedback"></i>
                      </div>
                      <input
                        className="form-control"
                        type="text"
                        name="search"
                        // value={this.state.searchCampaign}
                        onChange={e => {
                          e.persist();
                          this.filterProject(e.target.value);
                        }}
                        placeholder="Search Project by Name"
                      />
                    </div>
                  </div>
                  <AllProjects
                    projectStatus={this.state.projectStatus}
                    list={this.state.allProject}
                    filterProject={this.filterProject}
                    fetchAllProject={this.state.allProject}
                    utilizedFund={this.state.utilizedFund}
                  />
                </div>
              </div>
            </div>
          </div>
        </PageContentWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userProfile: state.login.userInfo,
  profileCompleted: state.ngo.ngoProfile
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllProjects,
      getNgoDashboardData,
      getFundInformation,
      getNgoProfile
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(MNEDashboard);
