import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { defaults, forEach, map } from 'lodash';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { Routes } from '../../constants/path.constants';
import {
  getMasterData,
  getMasterDataById,
  getStatesDataById,
  getDistrictsDataById,
  createOrModifyMission,
  getMission
} from '../../actions';
import { getCloudinaryUrlForImage } from '../../helpers/util';
import { validate } from './validate';
import { InputText, InputSelect } from '../FormElements';
import InputRichTextEditor from '../richTextEditorInput';
import FilterOptionsDropdownChecklist from '../filter/filterOptionsDropdownChecklist';
import FileInput from '../fileInput';
import { getNgoProfile } from '../../actions/ngo.actions';
import imageResolutionValidator from '../../helpers/imageResValidator';
import { Common } from '../../constants';
import Modal from '../newDesign/Modals/Modal';
import { sendMail } from '../../helpers/emailSender';
import MissionPreview from './preview';
var ngoEmail = window.location.href.split('email=')[1];
class MissionEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPreview: false,
      missionInfo: null,
      missionDetails: {},
      currentMissionStatus: null,
      ngoId: null,
      deletedLocationArr: [],
      currentDateTime: new Date(),
      formFields: {
        story: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'story'
        },
        activities: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'activities'
        },
        roadmap: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'roadmap'
        },
        missionLocation: {
          isValid: true,
          value: [
            {
              id: null,
              missionCountry: {
                isValid: false,
                value: 101,
                error: '',
                jsonField: 'country'
              },
              missionState: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'state'
              },
              missionDistrict: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'city'
              },
              pinCode: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'pincode'
              }
            }
          ],
          error: '',
          jsonField: 'mission_location'
        },
        rolloverImage: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'image_url'
        },
        gallery: {
          isValid: false,
          value: [],
          error: '',
          jsonField: 'gallery'
        }
      },
      show: false,
      refreshRTE: false
    };
    this.googleAPIKey = 'AIzaSyDxc9HLGnlXL0JWiftdZaVEv5quIAmTsyo';
    this.fetchingPreview = false;
  }

  setFormValidStatus = () => {
    const self = this;
    const formInvalidFields = map(self.state.formFields, value => {
      return value.isValid === false;
    });
    self.setState({
      formValid: formInvalidFields.some(value => {
        return value === true;
      })
        ? false
        : true
    });
  };

  setRTEValueAndError = (id, value, callback) => {
    // if (this.state.formFields[id].value !== value) {
    let formFields = {};
    const errorResult = validate(id, value);
    formFields[id] = {
      isValid: errorResult.isFieldValid,
      error: errorResult.msg,
      value,
      jsonField: this.state.formFields[id].jsonField
    };
    formFields = defaults({}, formFields, this.state.formFields);
    this.setState({ ...this.state, formFields }, () => {
      if (typeof callback === 'function') {
        callback();
      }
    });
    // }
  };

  onHandleBlur = (event, callback, type) => {
    this.setFormErrorsAndValue(event, callback, type);
  };

  checkValidation = () => {
    const { formFields } = this.state;
    let errorExists = false;
    for (let field in formFields) {
      const errorResult = validate(field, formFields[field].value);
      if (errorResult.msg !== '' && !errorExists) {
        errorExists = true;
      }
      const obj = {
        isValid: errorResult.isFieldValid,
        error: errorResult.msg,
        value: formFields[field].value,
        jsonField: formFields[field].jsonField
      };
      formFields[field] = obj;
    }
    this.setState({
      ...this.state,
      formFields: formFields
    });
    return errorExists;
  };

  apiErrorToast = () => {
    toast.error("Oops! Looks like we couldn't process your request.");
  };
  componentDidMount() {
    this.props.getNgoProfile();
    this.props.getMission(this.props.id, 'edit');
    const masterDataOptions = Common.NGO.MASTER_OPTIONS;
    this.props.getMasterData([
      masterDataOptions.PRIMARY_BENEFICIARIES,
      masterDataOptions.CAUSE_CATEGORY,
      masterDataOptions.SDG
    ]);
  }

  addLocation = () => {
    let formFields = this.state.formFields;
    const obj = {
      id: null,
      missionCountry: {
        isValid: false,
        value: 101,
        error: '',
        jsonField: 'country'
      },
      missionState: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'state'
      },
      missionDistrict: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'city'
      },
      pinCode: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'pincode'
      }
    };
    formFields.missionLocation.value.push(obj);
    this.setState({
      formFields: formFields
    });
    this.setState({
      disable_campaign_content_save_button: false
    });
  };
  deleteLocation = index => {
    let formFields = this.state.formFields;
    let delLocArr = this.state.deletedLocationArr;
    if (formFields.missionLocation.value[index].id)
      delLocArr.push(formFields.missionLocation.value[index]);
    formFields.missionLocation.value.splice(index, 1);
    this.setState({
      formFields: formFields,
      deletedLocationArr: delLocArr,
      disable_campaign_content_save_button: false
    });
  };

  deleteRollOverImage = () => {
    let formFields = this.state.formFields;
    formFields.rolloverImage.value = '';
    this.setState({ formFields: formFields });
  };

  onUploadSuccess = (fileUrl, event) => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        [event.target.id]: {
          isValid: true,
          error: '',
          value: fileUrl,
          jsonField: this.state.formFields[event.target.id].jsonField
        }
      },
      disable_campaign_content_save_button: false
    });
  };

  deleteGalleryImage = index => {
    let formFields = this.state.formFields;
    formFields.gallery.value.splice(index, 1);
    this.setState({ formFields: formFields });
  };

  onGalleryUploadSuccess = (fileUrl, event) => {
    let galleryArr = this.state.formFields[event.target.id].value || [];
    galleryArr.push(fileUrl);
    this.setState({
      formFields: {
        ...this.state.formFields,
        [event.target.id]: {
          isValid: true,
          error: '',
          value: galleryArr,
          jsonField: this.state.formFields[event.target.id].jsonField
        }
      },
      disable_campaign_content_save_button: false
    });
  };

  getLatestGalleryImage = () => {
    const galleryLen = this.state.formFields.gallery.value?.length;
    if (galleryLen > 0) {
      return this.state.formFields.gallery.value[galleryLen - 1];
    }
    return '';
  };

  getJson = () => {
    const dataObj = {};
    forEach(this.state.formFields, obj => {
      if (obj.jsonField === 'mission_location') {
        let locationArr = [];
        obj.value.map(loc => {
          const object = {
            country: Number(loc.missionCountry.value),
            state: loc.missionState.value.trim(),
            city: loc.missionDistrict.value.trim(),
            pincode: Number(loc.pinCode.value)
          };
          if (loc.id) object.location_id = loc.id;
          locationArr.push(object);
          return false;
        });
        dataObj['mission_location'] = locationArr;
      } else if (obj.jsonField === 'gallery') {
        dataObj['gallery'] = !obj.value || obj.value === '' ? [] : obj.value;
      } else {
        dataObj[obj.jsonField] = obj.value;
      }
    });
    dataObj['mission_type'] = this.state.missionInfo.mission_type;
    dataObj['mission_title'] = this.state.missionInfo.mission_title;
    dataObj['cause_category_id'] = this.state.missionInfo.cause_category_id;
    dataObj[
      'sub_cause_category_id'
    ] = this.state.missionInfo.sub_cause_category_id;
    dataObj['target_beneficiary'] = this.state.missionInfo.target_beneficiary;
    dataObj['sdg'] = this.state.missionInfo.sdg;
    dataObj['image_url'] = this.state.missionInfo.image_url;
    dataObj['corp_list'] = this.state.missionInfo.corp_list;
    dataObj['is_public'] = this.state.missionInfo.is_public;
    dataObj['status'] = this.state.missionInfo.status;
    dataObj['is_new_project'] = true;
    dataObj['is_published'] = true;
    dataObj['tnc_accepted'] = true;
    dataObj['mission_id'] = this.props.id;
    dataObj['ngo_id'] = this.state.ngoId;
    return dataObj;
  };

  onSave = () => {
    this.setState({
      currentDateTime: new Date()
    });
    const errorExists = this.checkValidation();
    if (errorExists) {
      toast.error(`Error(s) found! Correct the error(s) and try saving again.`);
      return false;
    }
    let json = this.getJson();
    if (this.props.id) json['is_resubmit'] = false;
    this.props
      .createOrModifyMission(json)
      .then(res => {
        if (res.code && res.code !== '200') {
          this.apiErrorToast();
          return false;
        }
        if (!this.props.id && !this.props.id && !this.props.isModifyMission) {
          toast.success(`Mission created successfully.`);
          setTimeout(() => {
            if (window.location.href.includes('email')) {
              this.props.history.push(
                '/ngo/mission/modify/' +
                  res.result[0].mission_id +
                  '?email=' +
                  ngoEmail
              );
            } else {
              this.props.history.push(
                '/ngo/mission/modify/' + res.result[0].mission_id
              );
            }
          }, 3000);
        } else {
          toast.success(`Mission saved successfully.`);
          this.props.getMission(this.props.id, 'edit');
        }
      })
      .catch(() => {
        this.apiErrorToast();
      });
  };

  onReSubmit = () => {
    const errorExists = this.checkValidation();
    this.setState({
      currentDateTime: new Date()
    });
    let trimmedString = this.state.currentDateTime.toString().substring(0, 24);
    if (errorExists) {
      toast.error(
        `Error(s) found! Correct the error(s) and try re-submitting again.`
      );
      return false;
    }
    let json = this.getJson();
    if (this.props.id) json['is_resubmit'] = true;
    this.props
      .createOrModifyMission(json)
      .then(res => {
        if (res.code && res.code !== '200') {
          this.apiErrorToast();
          return false;
        } else {
          toast.success(`Mission submitted successfully.`);
          sendMail({
            templateName: 'onGoingMissionUpdate',
            sendTo: 'ngo.partnerships@mettasocial.com',
            subject: 'Mission Edit Request Received',
            cc: this.state.missionInfo.ngo_email,
            templateData: {
              mission_name: this.state.missionInfo.mission_title,
              ngo_name: this.state.missionInfo.ngo_name,
              date_time: trimmedString
              // redirect_url: `www.admin.mettasocial/publishedMission/${this.state.missionInfo.mission_id}.com`
            }
          });
          // REDIRECT HERE
          if (window.location.href.includes('email')) {
            this.props.history.push('/ngo/missions/list?email=' + ngoEmail);
          } else {
            this.props.history.push('/ngo/missions/list');
          }
        }
      })
      .catch(() => {
        this.apiErrorToast();
      });
  };
  fillSecondaryCauseList = () => {
    this.props.getMasterDataById(this.state.missionInfo?.cause_category_id);
  };

  setLocationArrValue = (state, city, name, target, value, index) => {
    let locationsArray = this.state.formFields.missionLocation.value;
    const errorResult = validate(target.id, value);
    locationsArray[index][name].isValid = errorResult.isFieldValid;
    locationsArray[index][name].error = errorResult.msg;
    locationsArray[index].missionState.value = state;
    locationsArray[index].missionDistrict.value = city ? city : '';
    this.setState({
      ...this.state.formFields,
      missionLocation: locationsArray
    });
  };

  setLocationValue = (index, event) => {
    const { target } = event;
    const name = target.name;
    const _this = this;
    const value = parseInt(target.value);
    let stateFinalVal = '';
    let cityFinalVal = '';
    let cityVal = '';
    let locationsArray = this.state.formFields.missionLocation.value;
    locationsArray[index][name].value = value;
    this.setState({
      ...this.state.formFields,
      missionLocation: locationsArray
    });

    var request = new XMLHttpRequest();
    var method = 'GET';
    var url =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      value +
      '&sensor=true&key=' +
      this.googleAPIKey;
    var async = true;

    request.open(method, url, async);
    request.onreadystatechange = function() {
      if (request.readyState === 4 && request.status === 200) {
        var data = JSON.parse(request.responseText);
        var address = data.results[0]?.formatted_address?.split(',');
        var address_components = data.results[0]?.address_components;

        if (data.results.length > 0) {
          if (address.length < 3) {
            cityVal = address_components[1]?.long_name;
            stateFinalVal = address[address.length - 1]?.replace(/[0-9]/g, '');
            cityFinalVal = cityVal?.replace(/[0-9]/g, '');
            document.getElementsByName('missionDistrict')[
              index
            ].value = cityVal;
          } else {
            cityVal = address[address.length - 3].split(' ');
            stateFinalVal = address[address.length - 2]?.replace(/[0-9]/g, '');
            cityFinalVal = cityVal[cityVal.length - 1]?.replace(/[0-9]/g, '');
            document.getElementsByName('missionDistrict')[
              index
            ].value = cityVal[cityVal.length - 1].replace(/[0-9]/g, '');
          }
          document.getElementsByName('missionState')[index].value = address[
            address.length - 2
          ]?.replace(/[0-9]/g, '');
          document.getElementsByName('missionState')[
            index
          ].value = stateFinalVal;
        }
      } else {
        document.getElementsByName('missionState')[index].value = '--';
        document.getElementsByName('missionDistrict')[index].value = '--';
      }

      _this.setLocationArrValue(
        stateFinalVal,
        cityFinalVal,
        name,
        target,
        value,
        index
      );
    };
    request.send();

    // this.enableSaveButton();
  };
  handleChangeCity = (index, field, value) => {
    const updatedMissionLocation = { ...this.state.formFields.missionLocation };
    const updatedLocation = { ...updatedMissionLocation.value[index] };
    updatedLocation[field].value = value;
    updatedMissionLocation.value[index] = updatedLocation;
    this.setState({
      ...this.state.formFields,
      missionLocation: updatedMissionLocation
    });
  };
  handleModalOpenClose = () => {
    let body = document.body;
    if (this.state.show) {
      this.setState({ show: false });
      body.style.overflow = 'auto';
    } else {
      this.setState({ show: true });
      body.style.overflow = 'hidden';
    }
  };

  handleCancelEdit = () => {
    this.handleModalOpenClose();
  };
  exitEdit = () => {
    this.props.history.push(Routes.NGO.MISSION_DETAILS + '/' + this.props.id);
  };

  previewMission = () => {
    this.setState({ showPreview: true });
  };
  setFetchPreview = value => {
    this.fetchingPreview = value;
  };
  onClosePreview = () => {
    this.setState({ showPreview: false });
    this.setFetchPreview(false);
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.missionInfo) {
      this.isShowLoader = false;
      let missionInfo = nextprops.missionInfo;
      let missionFormDetails = this.state.formFields;
      forEach(missionFormDetails, obj => {
        if (obj.jsonField !== 'mission_location') {
          obj.value =
            missionInfo[obj.jsonField] !== null
              ? missionInfo[obj.jsonField]
              : '';
          obj.isValid = obj.value !== null ? true : false;
        }
      });
      if (missionInfo.locations && missionInfo.locations.length > 0) {
        let locationsArr = [];
        missionInfo.locations.map(loc => {
          const obj = {
            id: loc.id,
            missionCountry: {
              isValid: false,
              value: loc.country,
              error: '',
              jsonField: 'country'
            },
            pinCode: {
              isValid: false,
              value: loc.pincode,
              error: '',
              jsonField: 'pincode'
            },
            missionState: {
              isValid: false,
              value: loc.state,
              error: '',
              jsonField: 'state'
            },
            missionDistrict: {
              isValid: false,
              value: loc.city,
              error: '',
              jsonField: 'city'
            }
          };
          locationsArr.push(obj);
          return false;
        });
        missionFormDetails.missionLocation.value = locationsArr;
      }

      this.setState(
        {
          ...this.state,
          formFields: missionFormDetails,
          missionId: missionInfo.mission_id,
          currentMissionStatus: missionInfo['status'],
          missionInfo: missionInfo
        },
        () => {
          if (!this.props.masterSecondaryInfo) this.fillSecondaryCauseList();
          this.setState({ refreshRTE: true }, () => {
            this.setState({ refreshRTE: false });
          });
        }
      );
    } else {
      if (
        nextprops.missionDetailErrorResponse &&
        nextprops.missionDetailErrorResponse.code
      ) {
        toast.error('Some error occurred, please try again in sometime');
      }
    }
    if (nextprops.ngoProfile) {
      this.setState({ ngoId: nextprops.ngoProfile.ngo_id });
    }
  }
  createMainHtml = () => {
    const missionType = [
      { id: 'mission', name: 'Regular Mission' },
      { id: 'disaster', name: 'Disaster' }
    ];
    const countryList = [{ id: 101, name: 'India' }];
    const primaryCauseArea = this.props.masterInfo
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.CAUSE_CATEGORY]
      : null;
    const secondaryCauseArea = this.props.masterSecondaryInfo
      ? this.props.masterSecondaryInfo[
          Common.NGO.MASTER_OPTIONS.SUB_CAUSE_CATEGORY
        ]
      : null;
    const targetBeneficiaries =
      this.props.masterInfo && !this.state.secondaryRefresh
        ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.PRIMARY_BENEFICIARIES]
        : null;
    const sdg =
      this.props.masterInfo && !this.state.secondaryRefresh
        ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.SDG]
        : null;

    return (
      <>
        <div className="row justify-content-center bg-light-gray p-4 mb-4">
          <div className="col-8 mx-auto mb-4 note p-3">
            <h4 className="text-black fs-14">Note</h4>
            <p className="fs-12 mb-0">
              Edit and submit the changes; Team Metta will validate them for
              seamless mission execution. Changes done in the mission will be
              reflected/published post approval from team Metta.
            </p>
          </div>
          <div className="col-8 mx-auto bg-white card-box-shadow px-0 pt-4 mb-5 rounded-8">
            <div className="row justify-content-between px-4 mb-4">
              <h3 className="fs-16 fw-400">Mission Details</h3>
              <span className="color-21BA45 bg-DEF7E4 px-2 py-1 rounded d-inline-block mr-2">
                Published
              </span>
            </div>
            <div className="bg_EFF9FF ">
              <div className="row px-4 pt-2 bg-EFF9FF">
                <InputSelect
                  required={true}
                  labelText="Select Mission Type"
                  id="missionType"
                  name="missionType"
                  options={missionType}
                  fieldValue={this.state.missionInfo?.mission_type}
                  fieldError={''}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  // dataType="num"
                  disableInput={true}
                  wrapperClassName={'col-6 pl-0'}
                  permissions={this.props.permissions}
                />
              </div>
            </div>
            <div className="row pt-2 px-4">
              <InputText
                required={true}
                type={'text'}
                labelText="Mission Title"
                id="missionTitle"
                name="missionTitle"
                fieldValue={this.state.missionInfo?.mission_title}
                fieldError={''}
                PlaceHolder={'Maximum Length is 300 Characters'}
                wrapperClassName={'col-12 pl-0 pr-0'}
                maxLength={300}
                disableInput={true}
                onHandleChange={this.onHandleChange}
                onChangeCallback={this.setFormValidStatus}
                onHandleBlur={this.onHandleBlur}
                permissions={this.props.permissions}
              />
              <InputSelect
                required={true}
                labelText="Primary Cause Area"
                id="primaryCauseArea"
                name="primaryCauseArea"
                options={primaryCauseArea}
                dataType="num"
                fieldValue={this.state.missionInfo?.cause_category_id}
                fieldError={''}
                wrapperClassName={'col-6 pl-0'}
                disableInput={true}
                parentElement={true}
                onHandleChange={this.onHandleChange}
                onChangeCallback={this.setFormValidStatus}
                childCallBack={this.fillSecondaryCauseList}
                permissions={this.props.permissions}
              />
              <FilterOptionsDropdownChecklist
                label={`Secondary Cause Area(s)`}
                title={'Secondary Cause Area'}
                placeHolder={'Please select'}
                id={'secondaryCauseArea'}
                name={`secondaryCauseArea`}
                fieldValue={this.state.missionInfo?.sub_cause_category_id}
                fieldError={''}
                options={secondaryCauseArea}
                wrapperClass={'col-6 pr-0 ml-0-imp form-group'}
                btnTextClass={'fieldCheckList'}
                showTags={true}
                fieldHeight={true}
                hideFooter={true}
                required={true}
                disabled={true}
                popUpToRight={true}
                // handleChange={this.handleMultiSelection}
                // permissions={this.props.permissions}
              />
              {(this.state.missionInfo?.mission_type === '' ||
                !this.state.missionInfo?.mission_type ||
                this.state.missionInfo?.mission_type === 'mission') && (
                /* !this.state.secondaryRefresh && */ <>
                  <FilterOptionsDropdownChecklist
                    label={'Target Beneficiaries'}
                    title={'Target Beneficiary'}
                    placeHolder={'Please select'}
                    id={'targetBeneficiaries'}
                    name={`targetBeneficiaries`}
                    fieldValue={this.state.missionInfo?.target_beneficiary}
                    fieldError={''}
                    options={targetBeneficiaries}
                    wrapperClass={'col-6 pl-0 ml-0-imp form-group'}
                    btnTextClass={'fieldCheckList'}
                    showTags={true}
                    fieldHeight={true}
                    hideFooter={true}
                    required={true}
                    handleChange={this.handleMultiSelection}
                    permissions={this.props.permissions}
                    disabled={true}
                  />
                  <FilterOptionsDropdownChecklist
                    label={'Sustainable Development Goal(s)'}
                    title={'Sustainable Development Goal'}
                    placeHolder={'Please select'}
                    id={'sdg'}
                    name={`sdg`}
                    fieldValue={this.state.missionInfo?.sdg}
                    fieldError={''}
                    options={sdg}
                    wrapperClass={'col-6 pr-0 ml-0-imp form-group'}
                    btnTextClass={'fieldCheckList'}
                    showTags={true}
                    fieldHeight={true}
                    hideFooter={true}
                    required={true}
                    popUpToRight={true}
                    handleChange={this.handleMultiSelection}
                    permissions={this.props.permissions}
                    disabled={true}
                  />
                </>
              )}
            </div>
            <div className="row p-4">
              <div className="col-12 p-0">
                <label>
                  Mission Locations<span className="color-FF2300">*</span>
                </label>
                {this.state.formFields.missionLocation.value.map(
                  (location, index) => {
                    return (
                      <div
                        key={index + location.missionCountry.jsonField}
                        className="col-12 px-0">
                        <div className="row">
                          <InputSelect
                            required={true}
                            labelText="Mission Country"
                            id="missionCountry"
                            name="missionCountry"
                            options={countryList}
                            fieldValue={location.missionCountry.value}
                            fieldError={location.missionCountry.error}
                            onHandleChange={e =>
                              this.setLocationValue(index, e)
                            }
                            onChangeCallback={this.setFormValidStatus}
                            dataType="num"
                            disableInput={true}
                            wrapperClassName={'w-20 pr-2'}
                            permissions={this.props.permissions}
                          />
                          <InputText
                            required={true}
                            type={'number'}
                            labelText="Pin Code"
                            id="pinCode"
                            name="pinCode"
                            fieldError={location.pinCode.error}
                            onHandleChange={e =>
                              this.setLocationValue(index, e)
                            }
                            onChangeCallback={this.setFormValidStatus}
                            fieldValue={location.pinCode.value}
                            disableInput={false}
                            maxLength={6}
                            wrapperClassName={'w-20 px-2'}
                            permissions={this.props.permissions}
                          />

                          <InputText
                            required={true}
                            labelText="Mission State"
                            type={'text'}
                            id="missionState"
                            name="missionState"
                            fieldValue={location.missionState.value}
                            fieldError={location.missionState.error}
                            wrapperClassName={'w-20 px-2'}
                            disableInput={true}
                            permissions={this.props.permissions}
                          />
                          <InputText
                            required={true}
                            labelText="Mission City"
                            type={'text'}
                            id="missionDistrict"
                            name="missionDistrict"
                            fieldValue={location.missionDistrict.value}
                            fieldError={location.missionDistrict.error}
                            wrapperClassName={'w-20 px-2'}
                            disableInput={true}
                            permissions={this.props.permissions}
                          />
                          <span className="w-20 pl-2 d-flex mt-4">
                            <span className="w-100 text-right text-1FA4FA text-underline mr-4">
                              {this.state.formFields.missionLocation.value
                                .length < 5 &&
                                this.state.formFields.missionLocation.value
                                  .length ===
                                  index + 1 && (
                                  <span
                                    className="text-underline fs-12 cursor-pointer"
                                    onClick={this.addLocation}>
                                    Add
                                  </span>
                                )}
                              {index !== 0 && (
                                <i
                                  className="fas fa-trash-alt text-007bff fs-16 fas-imp ml-4 tooltipss cursor-pointer"
                                  onClick={() => this.deleteLocation(index)}>
                                  <span className="tooltip-text tooltipss-dark">
                                    Delete Mission Location
                                  </span>
                                </i>
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <h3 className="mt-4 px-4 fs-16 fw-400">Mission Content</h3>
            <div className="row px-4 pb-4">
              <div className="col-6 pl-0 mb-3">
                <FileInput
                  required={true}
                  label="Rollover image"
                  placeholder="Resolution in between 600x400 and 4096x4096"
                  id="rolloverImage"
                  name="rolloverImage"
                  refId={this.props.id}
                  fileSizeLimit={10}
                  validateFile={imageResolutionValidator}
                  value={this.state.formFields.rolloverImage.value || ''}
                  docType="campaign_image"
                  input={this.inputAttributes}
                  accept={'.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG'}
                  disabled={true}
                  fieldError={this.state.formFields.rolloverImage.error}
                  onSuccess={this.onUploadSuccess}
                  extraInfoAboutField={
                    'Recommended high resolution, landscape oriented image (dimensions should be at least 100x50 and maximum 4096x4096) and 10MB max file size'
                  }
                  permissions={this.props.permissions}
                />

                {this.state.formFields.rolloverImage.value && (
                  <div className="w-100 img-16x9 mb-4 rounded-m loading-bg position-relative">
                    <img
                      src={
                        getCloudinaryUrlForImage(
                          this.state.formFields.rolloverImage.value,
                          200
                        ) ||
                        getCloudinaryUrlForImage(
                          'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                          200
                        )
                      }
                      data-src={
                        this.state.formFields.rolloverImage.value ||
                        'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                      }
                      onError={e =>
                        (e.target.src = e.target.getAttribute('data-src'))
                      }
                      alt=""
                      className="abs-full rounded-m"
                    />
                    {/* <span
                      className="floating-delete-btn position-absolute bg-white d-flex justify-content-center align-items-center"
                      onClick={this.deleteRollOverImage}>
                      <i className="fas fa-trash-alt text-007bff fs-12 fas-imp tooltipss cursor-pointer">
                        <span className="tooltip-text tooltipss-dark">
                          Delete Rollover Image
                        </span>
                      </i>
                    </span> */}
                  </div>
                )}
              </div>
            </div>
            <div className="row p-4">
              {!this.state.refreshRTE && (
                <>
                  <InputRichTextEditor
                    required={true}
                    labelText="Story"
                    disableInput={false}
                    maxLength={20000}
                    PlaceHolder={'Maximum Length is 20,000 Characters'}
                    id="story"
                    name="story"
                    docType="campaign_image"
                    refId={this.props.id}
                    fieldError={this.state.formFields.story.error}
                    fieldValue={this.state.formFields.story.value}
                    wrapperClassName={'col-12 pl-0 pr-0'}
                    onChangeCallback={this.setFormValidStatus}
                    onHandleChange={this.setRTEValueAndError}
                    onHandleBlur={this.onHandleBlur}
                    permissions={this.props.permissions}
                  />
                  <InputRichTextEditor
                    required={true}
                    labelText="Activities"
                    disableInput={false}
                    maxLength={20000}
                    PlaceHolder={'Maximum Length is 20,000 Characters'}
                    id="activities"
                    name="activities"
                    docType="campaign_image"
                    refId={this.props.id}
                    fieldError={this.state.formFields.activities.error}
                    fieldValue={this.state.formFields.activities.value}
                    wrapperClassName={'col-12 pl-0 pr-0'}
                    onChangeCallback={this.setFormValidStatus}
                    onHandleChange={this.setRTEValueAndError}
                    onHandleBlur={this.onHandleBlur}
                    permissions={this.props.permissions}
                  />
                  <InputRichTextEditor
                    required={true}
                    labelText="Roadmap"
                    disableInput={false}
                    maxLength={20000}
                    PlaceHolder={'Maximum Length is 20,000 Characters'}
                    id="roadmap"
                    name="roadmap"
                    docType="campaign_image"
                    refId={this.props.id}
                    fieldError={this.state.formFields.roadmap.error}
                    fieldValue={this.state.formFields.roadmap.value}
                    wrapperClassName={'col-12 pl-0 pr-0 mb-0'}
                    onChangeCallback={this.setFormValidStatus}
                    onHandleChange={this.setRTEValueAndError}
                    onHandleBlur={this.onHandleBlur}
                    permissions={this.props.permissions}
                  />
                </>
              )}
            </div>
            <h3 className="mt-4 px-4 fs-16 fw-400">Gallery</h3>
            <div className="row px-4 pb-4">
              <div className="col-12 pl-0 mb-3">
                <FileInput
                  required={false}
                  label="Upload the images/pictures of your activities OR work done."
                  placeholder="Resolution in between 600x400 and 4096x4096"
                  id="gallery"
                  name="gallery"
                  refId={this.props.id}
                  fileSizeLimit={10}
                  validateFile={imageResolutionValidator}
                  value={this.getLatestGalleryImage() || ''}
                  docType="campaign_image"
                  input={this.inputAttributes}
                  accept={'.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG'}
                  disabled={false}
                  fieldError={this.state.formFields.gallery.error}
                  onSuccess={this.onGalleryUploadSuccess}
                  extraInfoAboutField={
                    'Recommended high resolution, landscape oriented image (dimensions should be at least 100x50 and maximum 4096x4096) and 10MB max file size'
                  }
                  // permissions={this.props.permissions}
                />
                <div className="d-flex flex-wrap">
                  {this.state.formFields.gallery?.value?.length > 0 &&
                    this.state.formFields.gallery.value.map((value, index) => {
                      return (
                        <div
                          key={index}
                          className="w-100 img-16x9 gallery-img mr-4 mb-4 rounded-m loading-bg position-relative pl-3 pb-3">
                          <img
                            src={
                              getCloudinaryUrlForImage(value, 200) ||
                              getCloudinaryUrlForImage(
                                'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                                200
                              )
                            }
                            data-src={
                              value ||
                              'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                            }
                            onError={e =>
                              (e.target.src = e.target.getAttribute('data-src'))
                            }
                            alt=""
                            className="abs-full rounded-m"
                          />
                          <span
                            className="floating-delete-btn position-absolute bg-white d-flex justify-content-center align-items-center"
                            onClick={() => this.deleteGalleryImage(index)}>
                            <i className="fas fa-trash-alt text-007bff fs-12 fas-imp tooltipss cursor-pointer">
                              <span className="tooltip-text tooltipss-dark">
                                Delete Gallery Image {'#' + (index + 1)}
                              </span>
                            </i>
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="floating-bar position-fixed bg-white py-4 px-5 d-flex justify-content-between align-items-center">
          <span
            className="fs-14 dm-bold text-0085FF cursor-pointer py-2 px-4"
            onClick={() => this.previewMission()}>
            Preview Mission
          </span>
          <div className="d-flex align-items-center">
            <span
              className="fs-13 mr-3 fs-14 dm-medium text-0085FF cursor-pointer p-2"
              onClick={this.handleCancelEdit}>
              Cancel
            </span>
            <button
              type="button"
              className="btn btn-primary btn-lg mr-4 px-4 fs-14 dm-bold"
              onClick={() => this.onSave()}>
              Save
            </button>
            <button
              type="button"
              className="btn btn-primary btn-lg mr-4 px-4 fs-14 dm-bold"
              onClick={() => this.onReSubmit()}
              disabled={this.state.missionInfo?.version_status !== 'DRAFT'}>
              Re-Submit
            </button>
          </div>
        </div>
        <Modal
          show={this.state.show}
          title={'Cancel Edited Changes?'}
          handleCloseModal={this.handleModalOpenClose}
          sizeClass={'modal-lg'}>
          <div>
            <p className="fs-16 color-666565">
              You are about to exit the mission edit. All of your changes will
              be lost. Are you sure?
            </p>
            <div className="d-flex align-items-center justify-content-end">
              <span
                className="fs-13 mr-3 fs-14 dm-medium text-0085FF cursor-pointer p-2"
                onClick={this.handleCancelEdit}>
                Cancel
              </span>
              <button
                type="button"
                className="btn btn-primary btn-lg mr-4 px-4 fs-14 dm-bold"
                onClick={this.exitEdit}>
                Exit
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };
  render() {
    if (!this.state.showPreview) {
      return <>{this.createMainHtml()}</>;
    }
    return (
      <>
        <MissionPreview
          missionId={this.props.id}
          fetchingPreview={this.fetchingPreview}
          currentMissionStatus={this.state.currentMissionStatus}
          editMission={true}
          showPreview={this.state.showPreview}
          onClosePreview={this.onClosePreview}
          setFetchPreview={this.setFetchPreview}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  missionInfo: state.missionData.editMissionSuccessResponse,
  masterInfo: state.common.masterInfo,
  masterSecondaryInfo: state.common.masterSecondaryInfo,
  listStates: state.common.listStates,
  listDistricts: state.common.listDistricts,
  editMissionErrorResponse: state.missionData.editMissionErrorResponse,
  userProfile: state.login.userInfo,
  ngoProfile: state.ngo.ngoProfile
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMasterData,
      getMasterDataById,
      getStatesDataById,
      getDistrictsDataById,
      createOrModifyMission,
      getMission,
      getNgoProfile
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionEdit);
