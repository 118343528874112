export function dateFormatMMDDYYYY() {
  // date format MM/DD/YYYY
  var date = new Date();
  var currentDate =
    date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  return currentDate;
}

export function dateFormatDDMMYYYY() {
  // date format DD/MM/YYYY
  const currentDate = new Date().toLocaleDateString('en-GB');
  return currentDate;
}

export const getParsedDate = date => {
  const inputDateString = date;
  const parsedDate = new Date(inputDateString);
  const formattedDate = parsedDate.toLocaleDateString('en-CA'); //yyyy-MM-dd format
  return formattedDate;
};
export const getParsedDateDDMMYYYY = date => {
  const inputDateString = date;
  const parsedDate = new Date(inputDateString);
  const formattedDate = parsedDate.toLocaleDateString('en-GB'); //yyyy-MM-dd format
  return formattedDate;
};
