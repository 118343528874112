import { Action } from '../constants';

const legalStructure = [
  {
    id: '1',
    name: 'Society Registration'
  },
  {
    id: '2',
    name: 'Trust Registration'
  },
  {
    id: '3',
    name: 'Company Registration(Section8/25)'
  }
];
const educationalBackground = [
  {
    id: '1',
    name: 'Bachelors'
  },
  {
    id: '2',
    name: 'Masters'
  },
  {
    id: '3',
    name: 'Doctorate'
  },
  {
    id: '4',
    name: 'PhD'
  }
];

export const getLegalStructure = () => dispatch => {
  dispatch({
    type: Action.LEGAL_STRUCTURE_DROPDOWN_SUCESS,
    payload: legalStructure
  });
};
export const getEducationalBackground = () => dispatch => {
  dispatch({
    type: Action.EDUCATIONAL_BACKGROUND_SUCCESS,
    payload: educationalBackground
  });
};
