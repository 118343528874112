import React from 'react';
import Header from '../components/header';
import { Common } from '../constants';
import PageContentWrapper from '../components/pageContentWrapper';
import PageContent from '../components/pageContent';
import TransactionList from '../components/transactionList';
class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getPageTitle = () => {
    return Common.NGO.PAGE_TITLE.ALL_TRANSACTIONS;
  };
  getTransactionScreen = () => {
    return <TransactionList />;
  };
  render() {
    return (
      <React.Fragment>
        <Header pageTitle={this.getPageTitle()} />
        <PageContentWrapper>
          <PageContent mainScreen={this.getTransactionScreen()} admin={true} />
        </PageContentWrapper>
      </React.Fragment>
    );
  }
}
export default Transactions;
