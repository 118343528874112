import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  getCampaign,
  getActivitiesById,
  getProjectExpensesById,
  getGoalsIndicators,
  getExpenseCategory
} from '../../actions';
import { Common } from '../../constants';
import { getCloudinaryUrlForImage } from '../../helpers/util';
import Modal from '../newDesign/Modals/Modal';
import docIcon from '../../assets/images/doc.png';
import pdfIcon from '../../assets/images/pdf.png';
import ProposedProject from './proposedProject';
import PopupModal from './popupModals';
import dumy_logo from '../../assets/icons/NgoLogo.png';
class CampaignPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
      campTypeCommunity: false,
      campTypeVolunteer: false,
      campTypeDirect: false,
      showModal: false,
      filePreview: null,
      fileType: null,
      docFile: null,
      expenseCategoryList: []
    };
  }

  componentDidMount() {
    if (!this.props.fetchingPreview) {
      this.props.setFetchPreview(true);
      this.props.getCampaign(this.props.campaignId, 'preview');
    }
    this.getExpenseCategories();
    this.props.getActivitiesById(this.props.campaignId);
    this.props.getProjectExpensesById(this.props.campaignId);
    this.props.getGoalsIndicators(this.props.campaignId);
  }

  campaignSubmissionConfirmation = () => {
    if (
      this.props.proposalStatus.is_project_activities &&
      this.props.proposalStatus.is_project_finances &&
      this.props.proposalStatus.is_project_indicators
    ) {
      this.setState({
        showSubmitPopupFilledWithProposal: true,
        showSubmitPopupUnFilledWithProposal: false
      });
    } else {
      this.setState({
        showSubmitPopupFilledWithProposal: false,
        showSubmitPopupUnFilledWithProposal: true
      });
    }
  };
  closePopupCampaignSubmission = type => {
    if (type === 'filled') {
      this.setState({
        showSubmitPopupFilledWithProposal: false
      });
    } else {
      this.setState({
        showSubmitPopupUnFilledWithProposal: false
      });
    }
  };
  getExpenseCategories() {
    this.props
      .getExpenseCategory()
      .then(res => {
        this.setState({ expenseCategoryList: res.result });
      })
      .catch(async err => {});
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.campaignInfo) {
      this.isShowLoader = false;
      let campaignTypeId = nextprops.campaignInfo.campaign_type_id || 0;
      this.setState({
        campTypeVolunteer:
          campaignTypeId === Common.NGO.CAMPAIGN_TYPE_VOLUNTEER_ID,
        campTypeCommunity:
          campaignTypeId === Common.NGO.CAMPAIGN_TYPE_COMMUNITY_FUNDING_ID,
        campTypeDirect:
          campaignTypeId === Common.NGO.CAMPAIGN_TYPE_DIRECT_FUNDING_ID
      });
    } else {
      if (
        nextprops.previewErrorResponse &&
        nextprops.previewErrorResponse.code
      ) {
        toast.error('Some error occurred, please try again in sometime', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
    if (nextprops.activitiesDetailsSuccessResponse) {
      let projectActivities = nextprops.activitiesDetailsSuccessResponse;
      this.setState({ projectActivities: projectActivities });
    }
    if (nextprops.financeDetailsSuccessResponse.project_proposed_budget_list) {
      let projectFinances =
        nextprops.financeDetailsSuccessResponse.project_proposed_budget_list;
      this.setState({ projectFinances: projectFinances });
    }
    if (nextprops.indicatorsDetailsSuccessResponse) {
      let projectIndicators = nextprops.indicatorsDetailsSuccessResponse;
      this.setState({ projectIndicators: projectIndicators });
    }
  }

  clickHandler = {
    playVideo: () => {}
  };

  getFileType = url => {
    const value = this.getFileName(url);
    if (
      value.includes('doc') ||
      value.includes('DOC') ||
      value.includes('docx') ||
      value.includes('DOCX') ||
      value.includes('xls') ||
      value.includes('XLS') ||
      value.includes('xlsx') ||
      value.includes('XLSX')
    ) {
      return 'doc';
    } else if (value.includes('pdf') || value.includes('PDF')) {
      return 'pdf';
    }
  };

  getFileName = url => {
    if (url.split('/')[5] === 'undefined' || !url.split('/')[5].includes('.')) {
      return url.split('/')[7];
    } else {
      return url.split('/')[5];
    }
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      filePreview: null,
      fileType: null,
      docFile: null
    });
    let body = document.body;
    body.style.overflow = 'auto';
  };

  showFilePreview(url) {
    const fileType = this.getFileType(url);
    this.setState({
      showModal: true,
      filePreview: url
    });
    if (
      fileType === 'doc' ||
      fileType === 'DOC' ||
      fileType === 'docx' ||
      fileType === 'DOCX' ||
      fileType === 'xls' ||
      fileType === 'XLS' ||
      fileType === 'xlsx' ||
      fileType === 'XLSX'
    ) {
      this.setState({
        docFile: process.env.REACT_APP_DOC_FILE_PREVIEW_URL + url,
        fileType: 'doc'
      });
    }

    let body = document.body;
    body.style.overflow = 'hidden';
  }

  createMarkup(value) {
    return { __html: value };
  }

  render() {
    // console.log(this.props.campaignInfo.title);
    return this.props.campaignInfo ? (
      <div className="row justify-content-center bg-light-gray p-4">
        <div className="row col-10 justify-content-between align-items-center">
          <p className="h2 col-11 m-0">Preview Project</p>
          <button
            type="button"
            className="btn col-1 text-right text-dark m-0 p-0"
            aria-label="Close"
            onClick={this.props.onClosePreview}>
            <i className="fas fa-times-circle fa-2x"></i>
          </button>
        </div>
        <hr className="border border-lime col-10 mt-2 mb-4" />
        <div className="col-10 bg-white card-box-shadow p-0 mb-5 rounded-8">
          <div className="img-16x9 position-relative rounded-top-8 loading-bg">
            {!this.props.campaignInfo.data?.video_url && (
              <img
                src={
                  getCloudinaryUrlForImage(
                    this.props.campaignInfo.image_url,
                    500
                  ) ||
                  getCloudinaryUrlForImage(
                    'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                    500
                  )
                }
                data-src={
                  this.props.campaignInfo.image_url ||
                  'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                }
                onError={e =>
                  (e.target.src = e.target.getAttribute('data-src'))
                }
                alt={`${this.props.campaignInfo.campaign_name} Project`}
                className="abs-full rounded-top-8"
              />
            )}
          </div>
          <div className="p-4 row mt-3">
            <p className="h2 row pr-3 mt-4 text-bold text-1C1C1C ml-3">
              {this.props.campaignInfo.campaign_name}
            </p>
            <span className="font-weight-bold text-344256 fs-14 row ml-3 mt-2">
              {this.props.campaignInfo.ngoLogo &&
              this.props.campaignInfo.ngoLogo ? (
                <img
                  src={this.props.campaignInfo.ngoLogo}
                  className="img-logo"
                  alt="images"
                  height={43}
                  width={43}
                />
              ) : (
                <img
                  src={dumy_logo}
                  className="img-logo"
                  alt="images"
                  height={43}
                  width={43}
                />
              )}
              <span className="pt-3 ml-2">
                {this.props.campaignInfo.ngo_name}{' '}
              </span>
            </span>

            {/* <div className="row"> */}
            <span className="w-100 pr-3 mb-3 ml-3">
              <span className="d-block text-969696 fs-14 mt-4 mb-2">
                Location
              </span>
              {this.props.campaignInfo.locations &&
              this.props.campaignInfo.locations.length > 0 ? (
                this.props.campaignInfo.locations.map((location, index) => {
                  return (
                    <div
                      key={index + location.id + location.pincode}
                      className="w-400 label-pastel mr-3 d-inline-block">
                      <p className=" word-break">{location.address}</p>
                    </div>
                  );
                })
              ) : (
                <span>{String.fromCharCode('0x2014')}</span>
              )}
            </span>
            {/* </div> */}
            {/* //tabs code */}
            <div className="row">
              <div className="col-12 px-2">
                <ProposedProject
                  financeList={this.state.projectFinances}
                  indicatorsList={this.state.projectIndicators}
                  activityList={this.state.projectActivities}
                  fundAmount={this.props.campaignInfo.fund_amount}
                  viewType={'campDetails-preview'}
                  expenseCategoryList={this.state.expenseCategoryList}
                />
              </div>
            </div>
            {/* <p className="h1 col-12 mb-4 p-0">
              {this.props.campaignInfo.campaign_name}
            </p> */}
            <div id="campDetails-preview" className="w-100">
              <div className="values-container p-4 mb-5 mx-3">
                <div className="d-flex flex-wrap">
                  <span className="w-33 pr-3 mb-3">
                    <span className="d-block mb-2 fs-14">
                      Primary Cause Category
                    </span>
                    {/* {this.props.campaignInfo.data?.cause_category_slug &&
                    this.props.campaignInfo.data?.cause_category_slug.length >
                      0 ? (
                      this.props.campaignInfo.cause_category_slug?.map(
                        (cause, index) => {
                          return (
                            <span
                              key={index + cause}
                              className="label-blue mr-3 mb-2 d-inline-block">
                              {cause}
                            </span>
                          );
                        }
                      )
                    ) : (
                      <span>{String.fromCharCode('0x2014')}</span>
                    )} */}
                    {this.props.campaignInfo.cause_category_slug.length > 0 && (
                      <label className="bg-E0F3FF label-blue fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 ">
                        {this.props.campaignInfo.cause_category_slug[0]}
                      </label>
                    )}
                    {this.props.campaignInfo.cause_category_slug.length > 1 ? (
                      <span className="tooltipss-campagin  text-0085FF">
                        {' '}
                        {`+ ${this.props.campaignInfo.cause_category_slug
                          .length - 1} More`}
                        <span className="tooltipss-sdg-text">
                          {this.props.campaignInfo.cause_category_slug
                            .slice(1)
                            .join(', ')}
                        </span>
                      </span>
                    ) : null}
                  </span>
                  <span className="w-33 pr-3 mb-3">
                    <span className="d-block mb-2 fs-14">
                      Secondary Cause Category
                    </span>
                    {this.props.campaignInfo?.sub_cause_category_slug.length >
                      0 && (
                      <label className="bg-E0F3FF label-blue fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 ">
                        <span>
                          {this.props.campaignInfo.sub_cause_category_slug[0]}{' '}
                        </span>
                      </label>
                    )}
                    {this.props.campaignInfo?.sub_cause_category_slug.length >
                    1 ? (
                      <span className="tooltipss-campagin  text-0085FF">
                        {' '}
                        {`+ ${this.props.campaignInfo.sub_cause_category_slug
                          .length - 1} More`}
                        <span className="tooltipss-sdg-text">
                          {this.props.campaignInfo.sub_cause_category_slug
                            .slice(1)
                            .join(', ')}
                        </span>
                      </span>
                    ) : null}
                  </span>
                  <span className="w-33 mb-3">
                    <span className="d-block mb-1 fs-14">
                      Requested Funding Amount
                    </span>
                    <span className="d-block font-weight-bold fs-15">
                      ₹{' '}
                      {new Intl.NumberFormat('en-IN').format(
                        this.props.campaignInfo.fund_amount
                      ) || String.fromCharCode('0x2014')}
                    </span>
                  </span>
                  <span className="w-33 pr-3 mb-3">
                    <span className="d-block mb-2 fs-14">
                      Target Beneficiaries
                    </span>
                    {this.props.campaignInfo.primary_beneficiary_slug?.length >
                      0 && (
                      <label className="bg-E0F3FF label-blue fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 ">
                        {this.props.campaignInfo.primary_beneficiary_slug[0]}
                      </label>
                    )}
                    {this.props.campaignInfo.primary_beneficiary_slug.length >
                    1 ? (
                      <span className="tooltipss-campagin  text-0085FF">
                        {' '}
                        {`+ ${this.props.campaignInfo.primary_beneficiary_slug
                          .length - 1} More`}
                        <span className="tooltipss-sdg-text">
                          {this.props.campaignInfo.primary_beneficiary_slug
                            .slice(1)
                            .join(', ')}
                        </span>
                      </span>
                    ) : null}
                  </span>
                  <span className="w-33 pr-3 mb-3">
                    <span className="d-block mb-2 fs-14">
                      Cost per Beneficiary
                    </span>
                    <span className="d-block font-weight-bold fs-15">
                      ₹{' '}
                      {new Intl.NumberFormat('en-IN').format(
                        parseFloat(
                          this.props.campaignInfo.fund_amount /
                            this.props.campaignInfo.no_of_beneficiaries
                        ).toFixed(2) || String.fromCharCode('0x2014')
                      )}
                    </span>
                  </span>
                  <span className="w-33 mb-3">
                    <span className="d-block mb-1 fs-14">
                      Number of Beneficiaries
                    </span>
                    <span className="d-block font-weight-bold fs-15">
                      {new Intl.NumberFormat('en-IN').format(
                        this.props.campaignInfo.no_of_beneficiaries ||
                          String.fromCharCode('0x2014')
                      )}
                    </span>
                  </span>
                  <span className="w-33 mb-3">
                    <span className="d-block mb-1 fs-14">
                      Proposed Period of the Project
                    </span>
                    <span className="d-block font-weight-bold fs-15">
                      {this.props.campaignInfo.project_period ||
                        String.fromCharCode('0x2014')}
                    </span>
                  </span>
                  <span className="w-33 mb-3">
                    <span className="d-block mb-1 fs-14">
                      Sustainable Development Goals (SDGs)
                    </span>
                    {this.props.campaignInfo.sdg &&
                    this.props.campaignInfo.sdg_icon.length > 0 ? (
                      this.props.campaignInfo.sdg_icon.map((sdg, index) => {
                        return (
                          <span
                            key={index + sdg}
                            className=" mr-3 mb-2 d-inline-block tooltipss-campagin">
                            <img
                              height={35}
                              width={35}
                              className=""
                              src={sdg.icon}
                              alt="SDG's Icon "
                            />
                            <span className="tooltipss-sdg-text">
                              {sdg.name}
                            </span>
                          </span>
                        );
                      })
                    ) : (
                      <span>{String.fromCharCode('0x2014')}</span>
                    )}
                  </span>
                </div>
              </div>
              {this.props.campaignInfo.is_new_project ? (
                <label className="bg-E0F3FF label-blue  fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 mb-4 ml-3">
                  This is a new project which is being executed for the first
                  time
                </label>
              ) : (
                <label className="bg-E0F3FF label-blue  fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 mb-4 ml-3 ">
                  We have already implemented this project in the past
                </label>
              )}
              <div className="col-12 p-0 ml-3">
                <h4 className="font-weight-bold mb-4">Project Goal</h4>
                {this.props.campaignInfo.project_goal ? (
                  <div
                    className="fs-14 mb-5 word-break w-90"
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.props.campaignInfo.project_goal
                    )}></div>
                ) : (
                  <span>{String.fromCharCode('0x2014')}</span>
                )}
                <h4 className="font-weight-bold mb-4">Project Synopsis</h4>
                {this.props.campaignInfo.project_summary ? (
                  <div
                    className="fs-14 mb-5 overflow-hidden project_synopsis word-break w-90"
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.props.campaignInfo.project_summary
                    )}></div>
                ) : (
                  <span>{String.fromCharCode('0x2014')}</span>
                )}
                <h4 className="font-weight-bold mb-4">Problem Objective</h4>
                {this.props.campaignInfo.project_objective ? (
                  <p className="fs-14 mb-5 word-break w-90">
                    {this.props.campaignInfo.project_objective}
                  </p>
                ) : (
                  <span className="d-block mb-5 pb-2 word-break w-90">
                    {String.fromCharCode('0x2014')}
                  </span>
                )}
              </div>
              <div className="col-12  bg-white p-0 mb-5 rounded-8 mx-auto text-1C1C1C">
                <div className="pl-3 row">
                  <div className="col-12 p-0">
                    <h4 className="font-weight-bold mb-4">Problem Statement</h4>
                    {this.props.campaignInfo.problem_statement ? (
                      <p className="fs-14 mb-5 word-break w-90">
                        {this.props.campaignInfo.problem_statement}
                      </p>
                    ) : (
                      <span className="d-block mb-5 pb-2">
                        {String.fromCharCode('0x2014')}
                      </span>
                    )}
                    <h4 className="font-weight-bold mb-4">
                      Models or ways to address the problem
                    </h4>
                    {this.props.campaignInfo.nature_of_solution ? (
                      <div
                        className="fs-14 mb-5 overflow-hidden project_synopsis word-break w-90"
                        dangerouslySetInnerHTML={this.createMarkup(
                          this.props.campaignInfo.nature_of_solution
                        )}></div>
                    ) : (
                      <span className="d-block mb-5 pb-2">
                        {String.fromCharCode('0x2014')}
                      </span>
                    )}

                    <h4 className="font-weight-bold mb-4">Project Execution</h4>
                    {this.props.campaignInfo.is_new_project ? (
                      <div className="fs-14 mb-5 word-break w-90">
                        This is a new project which is being executed for the
                        first time
                      </div>
                    ) : (
                      <div className="fs-14 mb-5 word-break w-90">
                        We have already implemented this project in the past
                      </div>
                    )}

                    {/* <h4 className="font-weight-bold mb-4">
                      Proposed Period of the project in months
                    </h4>
                    {this.props.campaignInfo.project_period ? (
                      <div
                        className="fs-14 mb-5"
                        dangerouslySetInnerHTML={this.createMarkup(
                          this.props.campaignInfo.project_period
                        )}></div>
                    ) : (
                      <span className="d-block mb-5 pb-2">
                        {String.fromCharCode('0x2014')}
                      </span>
                    )} */}

                    {/* <h4 className="font-weight-bold mb-4">
                      Cost per beneficiary (Project budget divided by number of
                      beneficiaries)
                    </h4>
                    {this.props.campaignInfo.beneficiary_cost ? (
                      <div
                        className="fs-14 mb-5"
                        dangerouslySetInnerHTML={this.createMarkup(
                          this.props.campaignInfo.beneficiary_cost
                        )}></div>
                    ) : (
                      <span className="d-block mb-5 pb-2">
                        {String.fromCharCode('0x2014')}
                      </span>
                    )} */}
                  </div>
                  <hr className="separator-E0E0E0" />
                  <div className="col-12 p-0 mt-3">
                    <div className="d-flex">
                      <span className="w-33 pr-3 mb-3">
                        <span className="d-block mb-2 fs-18">
                          Baseline Survey Document
                        </span>
                        <span className="d-flex align-items-center">
                          {this.props.campaignInfo.survey_document ? (
                            <>
                              <img
                                src={
                                  this.getFileType(
                                    this.props.campaignInfo.survey_document
                                  ) === 'doc'
                                    ? docIcon
                                    : pdfIcon
                                }
                                className="mr-2"
                                alt="fileName"
                                height={22}
                                width={16}
                                title="Baseline Survey Document"
                              />
                              <span
                                className="fs-14 color-1FA4FA cursor-pointer"
                                onClick={() =>
                                  this.showFilePreview(
                                    this.props.campaignInfo.survey_document
                                  )
                                }>
                                Baseline Survey Document
                                {/* {this.getFileName(
                                  this.props.campaignInfo.survey_document
                                )} */}
                              </span>
                            </>
                          ) : (
                            String.fromCharCode('0x2014')
                          )}
                        </span>
                      </span>
                      <span className="w-33 pr-3 mb-3">
                        <span className="d-block mb-2 fs-18">
                          Needs Assessment Report
                        </span>
                        <span className="d-flex align-items-center">
                          {this.props.campaignInfo.needs_assessment?.includes(
                            ''
                          ) ? (
                            <>
                              <img
                                src={
                                  this.getFileType(
                                    this.props.campaignInfo.needs_assessment
                                  ) === 'doc'
                                    ? docIcon
                                    : pdfIcon
                                }
                                className="mr-2"
                                alt="fileName"
                                height={20}
                                width={18}
                                title="Need assessment file"
                              />
                              <span
                                className="fs-14 color-1FA4FA cursor-pointer"
                                onClick={() =>
                                  this.showFilePreview(
                                    this.props.campaignInfo.needs_assessment
                                  )
                                }>
                                Needs Assessment Report
                                {/* {this.getFileName(
                                  this.props.campaignInfo.needs_assessment
                                )} */}
                              </span>
                            </>
                          ) : (
                            String.fromCharCode('0x2014')
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          title={'File Preview'}
          handleCloseModal={this.handleCloseModal}
          sizeClass={'modal-lg'}>
          <div>
            {this.state.fileType !== 'image' && this.state.fileType !== 'zip' && (
              <div style={{ height: '100vh' }}>
                <iframe
                  src={
                    this.state.fileType === 'doc'
                      ? this.state.docFile
                      : this.state.filePreview
                  }
                  title="file"
                  width="100%"
                  height="95%"></iframe>
              </div>
            )}
            {this.state.fileType === 'zip' && (
              <div style={{ height: '100vh', textAlign: 'center' }}>
                <a
                  href={this.state.fileUrlValue}
                  download
                  className="text-center">
                  <img
                    src={this.state.zipIcon}
                    alt="fileName"
                    width={200}
                    title={this.state.fileName}></img>
                  <div className="fs-16">Download zip files</div>
                </a>
              </div>
            )}
          </div>
        </Modal>
        <PopupModal
          filledSubmitPopup={this.state.showSubmitPopupFilledWithProposal}
          unFilledSubmitPopup={this.state.showSubmitPopupUnFilledWithProposal}
          campaignSubmissionConfirmation={this.campaignSubmissionConfirmation}
          closePopupCampaignSubmission={this.closePopupCampaignSubmission}
          onSubmit={this.props.onSubmit}
          campaignName={this.props.campaignInfo.campaign_name}
          currentCampaignStatus={this.props.currentCampaignStatus}
        />
        {/************Sticky bottom bar***************/}
        <div className="stickyBottomNav p-4">
          <div className="row">
            <div className="col-1"></div>
            <div className="col-5"></div>

            <div className="col-6">
              <div className="float-right">
                <>
                  <span onClick={this.props.onClosePreview}>
                    <span className="ml-2 dm-medium color-007bff cursor-pointer">
                      Close
                    </span>
                  </span>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg fs-14 dm-bold ml-3"
                    onClick={() => this.campaignSubmissionConfirmation()}>
                    Submit
                  </button>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}
const mapStateToProps = state => ({
  campaignInfo: state.campaign.previewSuccessResponse,
  previewErrorResponse: state.campaign.previewErrorResponse,
  userInfo: state.login.userInfo,
  financeDetailsSuccessResponse: state.mne.financeDetailsSuccessResponse,
  activitiesDetailsSuccessResponse: state.mne.activitiesDetailsSuccessResponse,
  indicatorsDetailsSuccessResponse: state.mne.indicatorsDetailsSuccessResponse
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCampaign,
      getActivitiesById,
      getProjectExpensesById,
      getGoalsIndicators,
      getExpenseCategory
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPreview);
