import React from 'react';
import Chart from 'react-apexcharts';
import { convertInCommaFormat } from '../../../helpers/commaSeparatedInput';

const DashboardDonutCharts = props => {
  let chartBlockSize = props.size;
  let labels = [];
  let series = [];

  const getSymbol = () => {
    return `${props.labelValuePrepend} ` || '';
  };

  if (props.dataArr) {
    for (var i = 0; i < props.dataArr.length; i++) {
      if (props.dataArr[i].value !== null || props.dataArr[i].value !== '')
        series[i] = props.dataArr[i].value;
      if (props.dataArr[i].name)
        labels[i] =
          props.dataArr[i].name +
          ' : ' +
          getSymbol() +
          convertInCommaFormat(props.dataArr[i].value);
    }
  }

  const options = {
    labels: labels,
    colors: props.colors,
    dataLabels: {
      enabled: true
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return '';
        },
        title: {
          formatter: function(labels) {
            return labels;
          }
        }
      }
    },
    legend: {
      show: props.type === 'semi' || props.hideLegend ? false : true
    },
    grid: {
      padding: {
        bottom: props.type === 'semi' ? -80 : 0
      }
    },
    plotOptions: {
      pie: {
        startAngle: props.type === 'semi' ? -90 : 0,
        endAngle: props.type === 'semi' ? 90 : 360,
        offsetY: 10,
        expandOnClick: true,
        donut: {
          show: true,
          size: chartBlockSize,
          labels: {
            show: props.donutName === 'Funds Utilisation' ? false : true,
            value: {
              show: true,
              offsetY: props.singleProject ? 8 : -8
            },
            total: {
              show: props.label ? true : false,
              label: props.label,
              showAlways: true,
              fontSize: props.fontSize,
              color: '#344256',
              formatter: function() {
                return convertInCommaFormat(props.labelValue) || '';
              }
            }
          }
        }
      }
    }
  };

  return series.length > 0 ? (
    <Chart
      options={options}
      series={series}
      type="donut"
      width={props.width || '100%'}
    />
  ) : (
    <p className="fw-600"> No Data Found</p>
  );
};

export default DashboardDonutCharts;
