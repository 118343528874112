import React from 'react';
import Modal from '../newDesign/Modals/Modal';

export default function MissionGallery2({ gallery }) {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState('');
  const showHideModal = () => {
    setShowModal(!showModal);
  };

  const getGallery = () => {
    if (gallery && gallery.length > 0) {
      return (
        <div className="col-12">
          <div className="mb-4 pb-2 pl-5 position-relative mission-gallery-row">
            <div className="d-flex flex-wrap">
              {gallery.map((url, index) => {
                return (
                  <img
                    key={index + url}
                    src={url}
                    alt={'mission'}
                    width={124}
                    height={84}
                    onClick={() => {
                      setSelectedImage(url);
                      setShowModal(true);
                    }}
                    className="mr-2 mission-gallery-image"
                  />
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="">
      <div className="row">{getGallery()}</div>
      <Modal
        show={showModal}
        title={'Unpublish Mission?'}
        handleCloseModal={showHideModal}
        sizeClass={'modal-lg'}
        hideCloseBtn={false}>
        <div>
          <img src={selectedImage} alt="preview gallery" />
        </div>
      </Modal>
    </div>
  );
}
