export const Common = {
  NGO: {
    IMAGES: {
      mettaSocialLogo: 'https://cdn.mettasocial.com/logos/MS_logo_640x180.svg'
    },
    SIDE_BAR: [
      {
        label: 'Dashboard',
        icon: '/images/ngo-dashboard-grey.svg',
        activeIcon: '/images/ngo-dashboard-blue.svg',
        path: '/ngo/ngoDashboard',
        isAdmin: false,
        impersonate: true
      },
      // {
      //   label: 'Home',
      //   icon: '/images/home.png',
      //   activeIcon: '/images/home-blue.png',
      //   path: '/ngo/dashboard',
      //   isAdmin: false,
      //   impersonate: false
      // },
      {
        label: 'Project',
        icon: '/images/campaign-grey.svg',
        activeIcon: '/images/campaign-blue.svg',
        path: '/ngo/project/list',
        isAdmin: false,
        impersonate: false
      },
      {
        label: 'Missions',
        icon: '/images/flag-circle-grey.svg',
        activeIcon: '/images/flag-circle-blue.svg',
        path: '/ngo/missions/list',
        isAdmin: false,
        impersonate: false,
        iconClass: 'p-2'
      },
      {
        label: 'Corporates',
        icon: '/images/corporate-grey.svg',
        activeIcon: '/images/corporate-blue.svg',
        path: '/ngo/corporate',
        isAdmin: false,
        impersonate: false
      },
      {
        label: 'Donation Reports',
        icon: '/images/icon_donation_report_grey.svg',
        activeIcon: '/images/icon_donation_report_blue.svg',
        path: '/ngo/donation_report',
        isAdmin: false,
        impersonate: false
      },
      {
        label: 'Transactions',
        icon: '/images/transaction-grey.svg',
        activeIcon: '/images/transaction-blue.svg',
        path: '/ngo/allTransaction',
        isAdmin: false,
        impersonate: false
      },
      {
        label: 'Invoices',
        icon: '/images/invoice_icon_inactive.svg',
        activeIcon: '/images/invoice_icon_active.svg',
        path: '/ngo/invoices',
        isAdmin: false,
        impersonate: false
      },
      {
        label: 'Profile',
        icon: '/images/profile-grey.svg',
        activeIcon: '/images/profile-blue.svg',
        path: '/ngo/profile',
        isAdmin: false,
        impersonate: true
      },
      {
        label: 'Role Management',
        icon: '/images/role-management-grey.svg',
        activeIcon: '/images/role-management-blue.svg',
        path: '/ngo/roleManagement',
        isAdmin: false,
        impersonate: true
      }
    ],
    IMPERSONATED_SIDE_BAR: [
      {
        label: 'Profile',
        icon: '/images/profile-grey.svg',
        activeIcon: '/images/profile-blue.svg',
        path: '/ngo/profile?email=' + window.location.href.split('email=')[1],
        isAdmin: false,
        impersonate: true,
        name: 'profile'
      },
      {
        label: 'Project',
        icon: '/images/campaign-grey.svg',
        activeIcon: '/images/campaign-blue.svg',
        path:
          '/ngo/project/list?email=' + window.location.href.split('email=')[1],
        isAdmin: false,
        impersonate: true,
        name: 'project'
      },
      {
        label: 'Missions',
        icon: '/images/flag-circle-grey.svg',
        activeIcon: '/images/flag-circle-blue.svg',
        path:
          '/ngo/missions/list?email=' + window.location.href.split('email=')[1],
        isAdmin: false,
        impersonate: false,
        iconClass: 'p-2',
        name: 'mission'
      }
    ],
    PAGE_TITLE: {
      DASHBOARD: 'Dashboard',
      PRE_REGISTRATION: 'Pre-Register Your Non-Profit Organisation',
      CREATE_CAMPAIGN: 'CREATE PROJECT',
      CAMPAIGN_EDIT: 'EDIT PROJECT',
      ALL_TRANSACTIONS: 'All Transactions',
      CAMPAIGN_LIST: 'All PROJECTS',
      CAMPAIGN_DETAIL: 'PROJECT DETAILS',
      NGO_SIGNUP: 'All NGOs',
      LIST_CORPORATES: 'List of Corporates',
      DONATION_REPORTS: 'Donation Reports',
      INVOICES: 'Invoices',
      ADD_GAME: 'Add New Game',
      LIST_OF_GAMES: 'All Games',
      PROJECT_PROGRESS_REPORT: 'Project Progress Report',
      SUPPORT: 'Support',
      COMPLETE_PROFILE: 'Profile',
      APPROVE_REJECT_NGO_PROFILE: 'Approve/Reject NGO Profile',
      ADD_GAME_DETAILS: 'Add Game Details',
      PROJECT: 'Start Project',
      ROLE_MANAGEMENT: 'Role Management',
      CREATE_MISSION: 'Create mission',
      MODIFY_MISSION: 'Modify mission',
      MISSION_DETAILS: 'Mission Details',
      MISSION_PLEDGE_SUMMARY: 'Donation Summary',
      MISSION_STATS: 'Mission Stats',
      MISSION_EDIT: 'Edit Mission'
    },
    MASTER_OPTIONS: {
      CAMPAIGN_VISIBILITY: 'campaign_visibility',
      CAUSE_CATEGORY: 'cause_categories',
      SUB_CAUSE_CATEGORY: 'sub_categories',
      CAMPAIGN_TYPE: 'campaign_type',
      CURRENCY: 'currency',
      VOLUNTEER_SKILLS: 'volunteer_skills',
      SDG: 'sdg',
      PRIMARY_BENEFICIARIES: 'primary_beneficiaries',
      PRIMARY_FUNDING_SOURCES: 'primary_funding_sources',
      NESTED_CAUSE_CATEGORY: 'nested_cause_category'
    },
    CAMPAIGN_STATUS: {
      UNDER_REVIEW: 'UNDER_REVIEW',
      DRAFT: 'DRAFT',
      APPROVED: 'APPROVED',
      REJECTED: 'REJECTED',
      WAITING_FOR_FUNDING: 'WAITING_FOR_FUNDING',
      PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL',
      NEW: 'NEW',
      ON_GOING_PROJECT: 'ON_GOING_PROJECT',
      PLEDGED: 'PLEDGED',
      ALL: 'ALL',
      FUNDED: 'FUNDED',
      COMPLETED: 'COMPLETED'
    },
    MISSION_STATUS: {
      UNDER_REVIEW: 'UNDER_REVIEW',
      DRAFT: 'DRAFT',
      APPROVED: 'APPROVED',
      REJECTED: 'REJECTED',
      WAITING_FOR_FUNDING: 'WAITING_FOR_FUNDING',
      PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL',
      NEW: 'NEW',
      ON_GOING_PROJECT: 'ON_GOING_PROJECT',
      PLEDGED: 'PLEDGED',
      ALL: 'ALL',
      FUNDED: 'FUNDED'
    },
    MNE_DASHBOARD: {
      DASHBOARD: 'DASHBOARD',
      GOAL_AND_INDICATOR: 'GOASL & INDICATOR',
      ACTIVITIES: 'ACTIVITIES',
      FINANCE: 'FINANCE',
      TIMELINE_AND_UPDATES: 'Timeline & Updates'
    },
    PAGE_ERRORS: {
      CAMPAIGN_NAME_REQUIRED: 'Please enter project title',
      CAMPAIGN_START_DATE_REQUIRED: 'Please select start date',
      CAMPAIGN_END_DATE_REQUIRED: 'Please select end date',
      PROJECT_OBJECTIVE_SUMMARY: 'Please enter project synopsis',
      PROJECT_OBJECTIVE_SUMMARY_LENGTH:
        'Maximum Length for Project Synopsis Should Be 50000 Characters (Including Style)',
      PROJECT_MODELS_OR_WAYS_TO_ADDRESS_THE_PROBLEM:
        'Maximum Length for Models or Ways to Address the problem Should Be 50000 Characters (Including Style)',
      // PROJECT_MODELS_OR_WAYS_TO_ADDRESS_THE_PROBLEM:
      //   'Please enter Models or ways to address the problem',
      PROJECT_GOAL: 'Please enter project goal',
      PROJECT_PERIOD: 'Please enter months from 1 to 60 ',
      PROJECT_OBJECTIVE_REQUIRED: 'Please enter project objective',
      PRIMARY_CAUSE_AREA_REQUIRED:
        'Please select at least one primary cause area',
      SECONDARY_CAUSE_AREA_REQUIRED:
        'Please select at least one secondary cause area',
      // CAMPAIGN_TYPE_REQUIRED: 'Please select campaign type',
      NUM_BENEFICIARIES_REQUIRED:
        'Please enter number of beneficiaries that will be impacted',
      NUM_BENEFICIARIES_CORRECT: 'Please input correct number of beneficiaries',
      BENEFICIARY_REQUIRED: 'Please select at least one target beneficiary',
      PROJECT_PERIOD_REQUIRED:
        'Please enter proposed period of the project in months',
      PROBLEM_STATEMENT_REQUIRED: 'Please enter problem statement',
      RESULTS_REQUIRED: 'Please enter results',
      VOLUNTEERS_CORRECT: 'Please input correct number of volunteers',
      VOLUNTEERS_ADDRESS_REQUIRED: 'Please enter volunteer address',
      VOLUNTEERS_CRITERIA_REQUIRED: 'Please enter volunteer criteria',
      CAMPAIGN_NEED_REQUIRED: 'Please enter Concept Note Need',
      CAMPAIGN_DETAILS_REQUIRED: 'Please enter Concept Note',
      CAMPAIGN_OBJECTIVE_REQUIRED: 'Please enter Concept Note Objective',
      FUND_AMOUNT_REQUIRED: 'Please enter fund amount',
      FUND_AMOUNT_CORRECT: 'Please enter amount between 1 to 9,999,999,999',
      MAX_VALUE_REACHED:
        'Maximum value of 10,00,00,000 beneficiaries is reached!',
      PROJECT_COUNTRY_REQUIRED: 'Please select project country',
      // PROJECT_ADDRESS_REQUIRED: 'Please select project address',
      PROJECT_STATE_REQUIRED: 'Please select project state',
      PROJECT_DISTRICT_REQUIRED: 'Please select project district',
      PIN_CODE_REQUIRED: 'Please enter pin code',
      PROJECT_START_DATE_REQUIRED: 'Please select project start date',
      PROJECT_END_DATE_REQUIRED: 'Please select project end date',
      SDG_REQUIRED: 'Please select at least one SDG',
      ACTUAL_PROJECT_START_DATE_REQUIRED:
        'Please select actual project start date',
      ACTUAL_PROJECT_END_DATE_REQUIRED: 'Please select actual project end date',
      PROJECT_ADDRESS_REQUIRED: 'Please enter project address',
      MIN_10_CHARACTERS: 'Please enter Minimum 10 Characters',
      ERROR_300_CHARACTERS: "Error: You can't use more than 300 characters.",
      ERROR_3000_CHARACTERS: "Error: You can't use more than 3000 characters.",
      ERROR_MORE_THAN_5_AREAS: "Error: You can't select more than 5 Areas",
      ERROR_MORE_THAN_5_SDG: "Error: You can't select more than 5 SDGs",
      ERROR_MORE_THAN_5_BENEFICIARIES:
        "Error: You can't select more than 5 target beneficiaries",
      ERROR_COST_PER_BENEFICIARY: 'Please enter amount between 1 to 10,00,000',
      INVALID_PIN_CODE: 'Invalid Pin code',
      MISSION_STATE_REQUIRED: 'Mission state required',
      MISSION_CITY_REQUIRED: 'Mission city required',
      MISSION_UPDATE_TYPE_REQUIRED: 'Update type required',
      MISSION_FUNDS_UTILIZED_REQUIRED: 'Funds Utilised required',
      MISSION_VISIBILITY_REQUIRED: 'Please select mission to be published for',
      ROLLOVER_IMAGE_REQUIRED: 'Rollover image required'
    },
    CAMPAIGN_TYPE_VOLUNTEER_ID: 1,
    CAMPAIGN_TYPE_COMMUNITY_FUNDING_ID: 2,
    CAMPAIGN_TYPE_DIRECT_FUNDING_ID: 3
  }
};

export const legalStructure = [
  {
    id: '1',
    name: 'Society Registration'
  },
  {
    id: '2',
    name: 'Trust Registration'
  },
  {
    id: '3',
    name: 'Company Registration(Section 8/25)'
  }
];

export const rteButtonConstants = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  '|',
  'font',
  'fontsize',
  'paragraph',
  'brush',
  'align',
  '|',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'image',
  'table',
  '|',
  'link',
  'ul',
  'ol',
  '|',
  'hr',
  'copyformat',
  '|',
  'outdent',
  'indent',
  '|',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'undo',
  'redo',
  '|',
  'fullsize'
];
export const rteButtonMDConstants = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  '|',
  'font',
  'fontsize',
  'paragraph',
  'brush',
  'align',
  '|',
  '',
  '',
  '',
  '',
  'image',
  'table',
  '|',
  'link',
  'ul',
  'ol',
  '|',
  'hr',
  'copyformat',
  '|',
  'outdent',
  'indent',
  '|',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'undo',
  'redo',
  '|',
  'fullsize'
];
