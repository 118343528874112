import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/date-picker.css';
import DonationReportIGivXn from './donationReport/donationReportListiGivXn';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { getHeaderData } from '../actions';
import toast from 'react-hot-toast';
function DonationReport(props) {
  // const [iGivXnReport, setShowIGivXnReport] = useState(true);
  const [headerInfo, setHeaderInfo] = useState({});
  useEffect(() => {
    getRegistrationInfo();
  }, []);
  const getRegistrationInfo = () => {
    props
      .getHeaderData()
      .then(res => {
        setHeaderInfo(res);
      })
      .catch(err => {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      });
  };

  return (
    <React.Fragment>
      {/*** uncomment for Socio-xn ***/}
      {/* <ul className="nav nav-pills">
        <li className="nav-item">
          <div
            className="nav-link active"
            onClick={() => setShowIGivXnReport(true)}>
            iGivXN
          </div>
        </li>
        <li className="nav-item">
          <div className="nav-link" onClick={() => setShowIGivXnReport(false)}>
            SocioXN
          </div>
        </li>
      </ul> */}
      {/* {iGivXnReport === true && ( */}
      <>
        <DonationReportIGivXn registrationInfo={headerInfo} {...props} />
      </>
      {/* )} */}
    </React.Fragment>
  );
}
const mapStateToProps = state => ({
  state: state
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getHeaderData
    },
    dispatch
  );
};
// export default connect(mapStateToPropsSidebar)(DonationReport);
export default connect(mapStateToProps, mapDispatchToProps)(DonationReport);
