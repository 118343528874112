import { EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';
export const getInvoices = (
  ngoId,
  skip,
  limit,
  searchTerm,
  date
) => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('WORKPLACE');

    instance
      .get(
        `${EndPoint.getApiUrl(
          'NGO',
          'INVOICES',
          'HIROKU'
        )}?ngo_id=${ngoId}&skip=${skip}&limit=${limit}&search_param=${searchTerm}&date_range=${date}`
      )
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
