import React from 'react';
import Modal from './newDesign/Modals/Modal';
import { convertInCommaFormat } from '../helpers/commaSeparatedInput';
function FundMobilisationPastData(props) {
  var sortedArr = [];
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var treshold = 2;
  const handleCloseModal = () => {
    props.setShowModal(false);
  };
  if (props?.fundsMobilized?.length > 0) {
    sortedArr = props?.fundsMobilized.sort((a, b) => b.year - a.year);
    if (currentYear - 1 !== sortedArr[0]?.year) {
      treshold = 1;
    }
  }
  return (
    <Modal
      show={props.showModal}
      title={'Funds Mobilisation History'}
      handleCloseModal={handleCloseModal}
      fundsMobilized={props.fundsMobilized}
      sizeClass={'modal-lg'}>
      <div>
        <div className="mr-2">
          {sortedArr &&
            sortedArr?.map((item, index) => {
              if (index > treshold) {
                return (
                  <div className="row my-2">
                    <div className="col-6 fs-14 text-969696">
                      Funds Mobilised in {item?.year}:
                    </div>
                    <div className="col-6 fs-14 text-1B1C1D text-left">
                      ₹ {convertInCommaFormat(item?.funds_mobilised)}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </Modal>
  );
}
export default FundMobilisationPastData;
