import { Routes } from './path.constants';

export const USER_ROLES = {
  ROLES: [
    {
      resource_name: 'NGO Team Management',
      resource_feature: [
        {
          feature: 'Manage team member',
          path: Routes.NGO.ROLE_MANAGEMENT
        },
        {
          feature: 'Transfer Ownership',
          path: Routes.NGO.ROLE_MANAGEMENT
        }
      ]
    },
    {
      resource_name: 'NGO Profile',
      resource_feature: [
        {
          feature: 'Pre-Registration',
          path: Routes.NGO.PRE_REGISTRATION
        },
        {
          feature: 'NGO Registration Profile',
          path: Routes.NGO.USER_PROFILE
        },
        {
          feature: 'Change NGO Registration Profile',
          path: Routes.NGO.USER_PROFILE
        }
      ]
    },
    {
      resource_name: 'NGO Manage Proposal',
      resource_feature: [
        {
          feature: 'Manage Concept note (Campaign)',
          path: Routes.NGO.CAMPAIGN_EDIT
        },
        {
          feature: 'Create Proposal - Basic Framework',
          path: Routes.NGO.CAMPAIGN_CREATE
        },
        {
          feature: 'Manage Budget',
          path: Routes.NGO.CAMPAIGN_EDIT
        },
        {
          feature: 'Manage Activities Plan',
          path: Routes.NGO.CAMPAIGN_EDIT
        },
        {
          feature: 'Manage Project Indicator',
          path: Routes.NGO.CAMPAIGN_EDIT
        }
      ]
    },
    {
      resource_name: 'NGO Manage Mission',
      resource_feature: [
        {
          feature: 'Create Mission',
          path: Routes.NGO.CREATE_MISSION
        },
        {
          feature: 'Manage Updates',
          path: Routes.NGO.MISSION_DETAILS
        },
        {
          feature: 'Manage Activities',
          path: Routes.NGO.MODIFY_MISSION
        },
        {
          feature: 'Manage Roadmaps',
          path: Routes.NGO.MISSION_EDIT
        },
        {
          feature: 'Manage Gallery',
          path: Routes.NGO.MISSION_DETAILS
        }
      ]
    },
    {
      resource_name: 'NGO Proposals',
      resource_feature: [
        {
          feature: 'NGO Dashboard',
          path: Routes.NGO.DASHBOARD
        },
        {
          feature: 'Campaign Dashboard',
          path: Routes.NGO.PROJECT_DETAILS_OVERVIEW
        },
        {
          feature: 'Finance Dashboard',
          path: Routes.NGO.CREATE_MISSION
        },
        {
          feature: 'Draft Campaign',
          path: Routes.NGO.CAMPAIGN_LIST
        },
        {
          feature: 'In Review Projects',
          path: Routes.NGO.CAMPAIGN_LIST
        },
        {
          feature: 'Awaiting Funds Projects',
          path: Routes.NGO.CAMPAIGN_LIST
        },
        {
          feature: 'Funded Projects',
          path: Routes.NGO.CAMPAIGN_LIST
        },
        {
          feature: 'Ongoing Project',
          path: Routes.NGO.CAMPAIGN_LIST
        },
        {
          feature: 'Project Completed',
          path: Routes.NGO.CAMPAIGN_LIST
        },
        {
          feature: 'Rejected Campaign',
          path: Routes.NGO.CAMPAIGN_LIST
        },
        {
          feature: 'List of Corporate',
          path: Routes.NGO.CORPORATE_LIST
        },
        {
          feature: 'NGO Donation Report',
          path: Routes.NGO.DONATION_REPORT
        },
        {
          feature: 'Transaction Details',
          path: Routes.NGO.TRANSACTIONS_LIST
        },
        {
          feature: 'Search Proposals',
          path: Routes.NGO.CAMPAIGN_LIST
        }
      ]
    },
    {
      resource_name: 'NGO Transaction',
      resource_feature: [
        {
          feature: 'View Transaction report',
          path: Routes.NGO.TRANSACTIONS_LIST
        }
      ]
    },
    {
      resource_name: 'NGO List of Corporates',
      resource_feature: [
        {
          feature: 'View list',
          path: Routes.NGO.CORPORATE_LIST
        }
      ]
    },
    {
      resource_name: 'NGO Donation Report',
      resource_feature: [
        {
          feature: 'View Donation Reports',
          path: Routes.NGO.DONATION_REPORT
        }
      ]
    },
    {
      resource_name: 'NGO M&E',
      resource_feature: [
        {
          feature: 'Start Project',
          path: Routes.NGO.CAMPAIGN_CREATE
        },
        {
          feature: 'M&E Dashboard',
          path: Routes.NGO.MNESINGLEDASHBOARD
        },
        {
          feature: 'Update Actual values against Indicator',
          path: Routes.NGO.MNESINGLEDASHBOARD
        },
        {
          feature: 'Update Activities start and end dates',
          path: Routes.NGO.MNESINGLEDASHBOARD
        },
        {
          feature: 'Respond to Proposal change request from Corporate',
          path: Routes.NGO.MNESINGLEDASHBOARD
        },
        {
          feature: 'Update Timeline ',
          path: Routes.NGO.MNESINGLEDASHBOARD
        },
        {
          feature: 'Manage Expenses',
          path: Routes.NGO.MNESINGLEDASHBOARD
        },
        {
          feature: 'Beneficiaries',
          path: Routes.NGO.MNESINGLEDASHBOARD
        }
      ]
    }
  ]
};

export const getPathMatch = path => {
  let pageObjArr = [];
  USER_ROLES.ROLES.map(role => {
    let arr = [];
    arr = role.resource_feature.filter(x => path.includes(x.path));
    pageObjArr = [...pageObjArr, ...arr];
    return false;
  });
  return pageObjArr;
};
