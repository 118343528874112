import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { getCampaign, getActualAndPlannedBudget } from '../../actions';
import { Link } from 'react-router-dom';
import { compareBudget } from '../campaigns/tabFields';
// import Circle from '../../assets/icons/orange-circle.svg';
import Star from '../../assets/icons/red-star.svg';
import { downloadExcelForActualAndPlannedBudget } from '../component/downloasAsExcel';
var flag = 0;
class ActualAndPlannedBudgetComparison extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: window.location.href.split('/').pop(),
      actualAndPlannedData: [],
      expenseCategories: [],
      actualVsProposedData: [],
      expensesNameArr: [],
      campaignFunds: 0
    };
  }

  componentDidMount() {
    this.getActualAndPlannedData(parseInt(this.state.campaignId));
    this.props.getCampaign(this.state.campaignId, 'preview');
  }

  downloadExcelFile() {
    let data = {
      expensesNameArr: this.state.expensesNameArr,
      actualVsProposedData: this.state.actualVsProposedData,
      campaignFunds: this.state.campaignFunds
    };
    downloadExcelForActualAndPlannedBudget(data);
  }
  getActualAndPlannedData(campaignId) {
    this.props
      .getActualAndPlannedBudget(campaignId)
      .then(res => {
        if (res.result?.length > 0) {
          // this.setState({
          //   actualVsProposedData: res.result
          // });
          const groupByExpenseNameArr = res.result.reduce(
            (accumulator, currentValue) => {
              const expenseHeader = currentValue.expense_header;
              const projectExpensesName = currentValue.project_expenses_name;

              // Find the index of the expense_header in the accumulator array
              const headerIndex = accumulator.findIndex(
                item => item.expense_header === expenseHeader
              );

              // If the expense_header exists in the accumulator array, update its corresponding project_expenses_name array
              if (headerIndex !== -1) {
                const projectIndex = accumulator[
                  headerIndex
                ].sections.findIndex(
                  item => item.project_expenses_name === projectExpensesName
                );
                if (projectIndex !== -1) {
                  accumulator[headerIndex].sections[projectIndex].data.push(
                    currentValue
                  );
                } else {
                  accumulator[headerIndex].sections.push({
                    project_expenses_name: projectExpensesName,
                    data: [currentValue]
                  });
                }
              } else {
                // If the expense_header does not exist in the accumulator array, create a new entry
                accumulator.push({
                  expense_header: expenseHeader,
                  sections: [
                    {
                      project_expenses_name: projectExpensesName,
                      data: [currentValue]
                    }
                  ]
                });
              }

              return accumulator;
            },
            []
          );

          const expensesArr = Object.keys(groupByExpenseNameArr);
          this.setState({
            actualVsProposedData: groupByExpenseNameArr,
            expensesNameArr: expensesArr
          });
        }
      })
      .catch(async err => {});
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.campaignInfo) {
      this.setState({ campaignFunds: nextprops.campaignInfo?.fund_amount });
    }
  }
  renderTable = () => {
    const commaSeparatedNumber = number => {
      if (number) {
        return new Intl.NumberFormat('en-IN').format(number);
      }
      return 0;
    };
    if (this.state.actualVsProposedData.length) {
      return this.state.actualVsProposedData.map((section, index) => {
        const getTotalHeader = data => {
          return data.reduce((acc, sec) => {
            const sectionSum = sec?.data.reduce((sum, obj) => {
              return sum + obj.project_actual_amount;
            }, 0);
            return acc + sectionSum;
          }, 0);
        };
        return (
          <div key={index} className="border rounded">
            <div className="bg_FFF0C3 fw-600 fs-16 p-3 mb-4">
              {`Header ${index + 1}`}
              {' : '}
              {section?.expense_header || `Expense Header ${index + 1}`}
            </div>
            <div className="px-4">
              {' '}
              {section?.sections?.map((subsection, id) => {
                const getTotal = data => {
                  return data?.reduce((acc, obj) => {
                    return acc + obj?.project_actual_amount;
                  }, 0);
                };
                return (
                  <div
                    key={id + subsection?.project_expenses_name}
                    className="border rounded mb-3">
                    <div className="bg_E8F2FF fw-600 fs-14 p-3">
                      {`Section ${id + 1}`}
                      {' : '}
                      {subsection?.project_expenses_name}
                    </div>
                    <table
                      id="dtBasicExample"
                      className="table table-borders table-hover fs-14 bg-white mb-0">
                      <thead className="table-head ">
                        <tr className="text-left h-lh-45">
                          {compareBudget.map(entry => {
                            return (
                              <th
                                className={`dm-medium fs-12 text-1B1C1D border-bottom ${
                                  id === 3 ? 'bg-F0F8FF' : ''
                                }`}
                                key={entry.id}
                                style={{
                                  width: `${entry.width}px`,
                                  fontWeight: 'bolder'
                                }}>
                                {entry.headerName}
                                {entry.required ? (
                                  <span className="color-FF2300">*</span>
                                ) : (
                                  ''
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>

                      <tbody>
                        {subsection.data.map((itm, subIndex) => {
                          return (
                            <>
                              {/* {itm.project_actual_amount !== null &&
                                            itm.project_actual_amount !== 0 && ( */}
                              <tr
                                key={subIndex}
                                className="text-left h-40 border-bottom">
                                <td className="align-top">{subIndex + 1}</td>
                                <td className="align-top">
                                  {itm.project_expenses_discription}
                                </td>
                                <td className="align-top">
                                  {itm.project_actual_amount === null ||
                                  (itm.project_actual_amount !== null &&
                                    itm.project_proposed_amount !== 0)
                                    ? itm.expense_sub_category
                                    : '-- '}
                                </td>
                                <td className="align-top">
                                  {itm.project_actual_amount !== null ||
                                  (itm.project_proposed_amount !== 0 &&
                                    itm.project_actual_amount !== null)
                                    ? itm.expense_sub_category === null
                                      ? '₹ '
                                      : itm.expense_sub_category
                                    : '₹ '}
                                </td>
                                <td className="align-top">
                                  {itm.project_proposed_amount !== null &&
                                  itm.project_proposed_amount !== 0
                                    ? `₹ ${commaSeparatedNumber(
                                        itm?.project_proposed_amount
                                      ) || 0}`
                                    : ' ₹ 0'}
                                </td>
                                <td className="align-top bg-F0F8FF">
                                  {'₹ '}
                                  {commaSeparatedNumber(
                                    itm?.project_actual_amount
                                  ) || '0'}
                                  {itm.project_actual_amount !== null &&
                                  itm.project_actual_amount !==
                                    itm.project_proposed_amount ? (
                                    <img
                                      src={Star}
                                      alt="star"
                                      width={'15'}
                                      className="p-1"></img>
                                  ) : (
                                    ''
                                  )}
                                  {/* {itm.project_proposed_amount ===
                                                0 &&
                                              itm.project_actual_amount !==
                                                null ? (
                                                <img
                                                  src={Circle}
                                                  alt="circle"
                                                  width={'15'}
                                                  className="p-1"></img>
                                              ) : (
                                                ''
                                              )} */}
                                </td>
                                <td className="align-top fw-800">
                                  {(
                                    (itm.project_actual_amount /
                                      this.state.campaignFunds) *
                                    100
                                  ).toFixed(2)}
                                  {' %'}
                                </td>
                              </tr>
                              {/* )} */}
                            </>
                          );
                        })}

                        <tr className="text-let  h-40 bg_F4F4F4 mb-0">
                          <td className="align-top"></td>
                          <td className="align-top  fs-14 text-344256">
                            {'Section Total'}
                          </td>
                          <td className="align-top"></td>
                          <td></td>
                          <td></td>
                          <td className="align-top  fs-14 text-344256 sumtotal_budget fw-800">
                            {' ₹ '}
                            {commaSeparatedNumber(getTotal(subsection?.data))}
                          </td>
                          <td className="align-top  fs-14 text-344256 sumtotal_budget fw-800">
                            {(
                              (getTotal(subsection?.data) /
                                this.state.campaignFunds) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
            <table
              id="dtBasicExample"
              className="table table-borders table-hover fs-14 bg-white mb-0">
              <thead className="table-head ">
                <tr className="text-left ">
                  {compareBudget.map(entry => {
                    return (
                      <th
                        className={`dm-medium fs-12 text-1B1C1D border-bottom `}
                        key={entry.id}
                        style={{
                          width: `${entry.width}px`,
                          fontWeight: 'bolder'
                        }}>
                        {/* {entry.headerName}
                        {entry.required ? (
                          <span className="color-FF2300">*</span>
                        ) : (
                          ''
                        )} */}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                {' '}
                <tr className="text-let   h-40 bg_EAF7F6">
                  <td className="align-top"></td>

                  <td className="align-top fw-800 fs-14 text-344256">
                    {'Header Total'}
                  </td>
                  <td className="align-top"></td>
                  <td></td>
                  <td></td>

                  <td className="align-top  fs-14 text-344256 sumtotal_budget fw-800">
                    {' ₹ '}
                    {commaSeparatedNumber(getTotalHeader(section?.sections))}
                  </td>
                  <td className="align-top  fs-14 text-344256 sumtotal_budget fw-800">
                    {(
                      (getTotalHeader(section?.sections) /
                        this.state.campaignFunds) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      });
    }
  };

  render() {
    // const unitOfMeasurement = this.state.uomList;
    // const frequencyList = this.state.frequencyList;
    return (
      <>
        <div className="row p-4 ">
          <div className={`row`}>
            <div className="col-12">
              <div className={`col-12 p-4 bg-white`}>
                <div className="row justify-content-between mb-4">
                  <p className="fs-16 ">
                    Comparison Between Planned & Actual Fund Utilisation
                  </p>
                  {/* 
                  <DownloadAsExcel
                  compareBudget ={compareBudget}
                  expensesNameArr={this.state.expensesNameArr}
                  actualVsProposedData={this.state.actualVsProposedData}
                  campaignFunds={this.state.campaignFunds}
                  ></DownloadAsExcel> */}
                  {/* <button
                    type="button"
                    className="btn btn-link text-decoration-none mx-3 cursor-pointer">
                    <span className="text-31AEFD">Bulk Upload Expenses</span>
                    <input
                      type="file"
                      accept=".xlsx"
                      id="upload_excel_sheet"
                      // onChange={e => handleBulkUpload(e)}
                      className="invisible-input"
                    />
                  </button> */}
                </div>

                <div>
                  <div className={'table-height-100vh mb-5'}>
                    {' '}
                    {this.renderTable()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/************Sticky bottom bar***************/}
          <div className="stickyBottomNav p-4">
            <div className="row">
              <div className="col-1"></div>

              <div className="col-5">
                {this.props.currentCampaignStatus !== '' && (
                  <>
                    <div>
                      {/* <img
                        src={Circle}
                        alt="circle"
                        width={'15'}
                        className="p-1"></img>
                      <span className="color-F2711C">
                        Actual expense not allocated in the propopsal
                      </span> */}
                    </div>
                    <div>
                      <img
                        src={Star}
                        alt="star"
                        width={'15'}
                        className="p-1"></img>
                      <span className="color-DB2828">
                        Actual expense does not match with the proposal expense
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div className="col-6">
                <div className="float-right">
                  <>
                    <Link
                      className="pl-4 fs-14 dm-medium cursor-pointer"
                      to={`/ngo/mneSingleProject/${this.state.campaignId}`}>
                      Back
                    </Link>

                    <button
                      type="button"
                      disabled={flag === 0}
                      // disabled={true}
                      onClick={() => this.downloadExcelFile()}
                      className="btn btn-primary btn-lg fs-14 dm-bold ml-3 cursor-pointer">
                      Download Excel
                    </button>

                    {/* <button
                      type="button"
                      onClick={() => this.downloadPdfFile()}
                      disabled={true}
                      className="btn btn-primary btn-lg fs-14 dm-bold ml-3 cursor-pointer">
                      Download PDF
                    </button> */}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  campaignInfo: state.campaign.previewSuccessResponse,
  userInfo: state.login.userInfo
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCampaign,
      getActualAndPlannedBudget
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActualAndPlannedBudgetComparison);
