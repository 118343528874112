import dayjs from 'dayjs';
import { defaults, forEach, map } from 'lodash';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  createOrModifyCampaign,
  getCampaign,
  getMasterData,
  getMasterDataById,
  getMasterListCountries,
  getStatesDataById,
  getDistrictsDataById
} from '../../actions';
import { Common, EndPoint } from '../../constants';
import HttpRequest from '../../helpers/http-request';
import imageResolutionValidator from '../../helpers/imageResValidator';
import { getCloudinaryUrlForImage } from '../../helpers/util';
import FileInput from '../fileInput';
import {
  InputDaypicker,
  InputSelect,
  InputText,
  InputTextArea
} from '../FormElements';
import Loader from '../loader';
import Modal from '../newDesign/Modals/Modal';
const $ = window.$;
class StartModifyProject extends React.Component {
  constructor(props) {
    super(props);
    this.fileInfo = null;
    let ngoId = this.props.userProfile.sub || null;
    this.state = {
      campaignId: null, // campaign_id
      ngoId: ngoId,
      uploadInProgress: false,
      currentCampaignStatus: '',
      saveRequestMade: false,
      ngoApproved: props.isNgoApproved,
      disable_campaign_type: false,
      formFields: {
        campaignName: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'title'
        },
        primaryCauseArea: {
          isValid: false,
          value: [],
          error: '',
          jsonField: 'cause_category_slug'
        },
        secondaryCauseArea: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'sub_cause_category_slug'
        },
        campaignType: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'campaign_type_id'
        },

        beneficiaries: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'no_of_beneficiaries'
        },
        volunteersNeeded: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'volunteer_count'
        },
        volunteersSkills: {
          isValid: true,
          value: [],
          error: '',
          jsonField: 'volunteer_skills'
        },
        sdg: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'sdg'
        },
        volunteersAddress: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'address'
        },
        volunteersHoursPerDay: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'hours_per_day'
        },
        volunteersCriteria: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'selection_criteria'
        },
        campaignDetails: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'details'
        },
        campaignObjective: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'objective'
        },
        fundAmount: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'fund_amount'
        },
        fundAllocated: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'funds_allocated'
        },
        projectCountry: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'country'
        },
        projectState: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'state'
        },
        projectDistrict: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'city'
        },
        projectImpact: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'impact'
        },
        beneficiariesImpacted: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'primary_beneficiary_slug'
        },

        actualProjectStartDate: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'actual_project_start_date'
        },
        actualProjectEndDate: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'actual_project_end_date'
        },
        rolloverImage: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'image_url'
        },
        problemStatement: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'problem_statement'
        },
        projectGoal: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'project_goal'
        },
        natureOfSolution: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'nature_of_solution'
        },
        rolloverVideo: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'video_url'
        },

        needsAssessment: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'needs_assessment'
        },
        tnc_accepted: {
          isValid: false,
          value: false,
          error: '',
          jsonField: 'tnc_accepted'
        },
        projectLocation: {
          isValid: true,
          value: [
            {
              id: null,
              projectCountry: {
                isValid: false,
                value: 101,
                error: '',
                jsonField: 'country'
              },
              pinCode: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'pincode'
              },
              projectState: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'state'
              },
              projectDistrict: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'city'
              }
            }
          ],
          error: '',
          jsonField: 'project_location'
        }
      },
      formValid: false,
      campTypeVolunteer: false,
      campTypeCommunity: false,
      campTypeDirect: false,
      isSaved: false,
      fieldUpdated: false
    };
    this.editDetailRequested = false;
    this.editDetailRecieved = false;
    this.praposalDocUploadSucess = false;
  }
  checkNgoApproved = () => {
    if (!this.props.isNgoApproved) {
      return true;
    }
  };
  Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    }
  };
  validate = (name, value) => {
    let isFielValid = true;
    let msg = '';
    switch (name) {
      case 'campaignName':
        isFielValid = this.Validations.required(value);
        if (isFielValid) msg = Common.NGO.PAGE_ERRORS.CAMPAIGN_NAME_REQUIRED;
        if (value.length >= 100)
          msg = Common.NGO.PAGE_ERRORS.MAX_CHARACTER_LENGTH_REACHED;
        else msg = '';
        break;
      case 'primaryCauseArea':
        isFielValid = this.Validations.required(value);
        msg = isFielValid
          ? ''
          : Common.NGO.PAGE_ERRORS.PRIMARY_CAUSE_AREA_REQUIRED;
        break;
      case 'secondaryCauseArea':
        isFielValid = this.Validations.required(value);
        msg = isFielValid
          ? ''
          : Common.NGO.PAGE_ERRORS.SECONDARY_CAUSE_AREA_REQUIRED;
        break;
      case 'campaignType':
        isFielValid = this.Validations.required(value);
        msg = isFielValid ? '' : Common.NGO.PAGE_ERRORS.CAMPAIGN_TYPE_REQUIRED;
        break;
      case 'beneficiariesImpacted':
        isFielValid = this.Validations.required(value);
        msg = isFielValid ? '' : Common.NGO.PAGE_ERRORS.BENEFICIARY_REQUIRED;
        break;
      case 'beneficiaries':
        isFielValid = this.Validations.required(value);
        if (isFielValid) {
          if (value <= 0) {
            isFielValid = false;
            msg = Common.NGO.PAGE_ERRORS.NUM_BENEFICIARIES_CORRECT;
          }
          if (value.length > 5) {
            isFielValid = false;
            msg = Common.NGO.PAGE_ERRORS.MAX_CHARACTER_LENGTH_REACHED;
          } else msg = '';
        }
        break;
      case 'sdg':
        isFielValid = this.Validations.required(value);
        msg = isFielValid ? '' : Common.NGO.PAGE_ERRORS.SDG_REQUIRED;
        break;
      case 'fundAmount':
        if (
          this.state.formFields.campaignType.value ===
          Common.NGO.CAMPAIGN_TYPE_COMMUNITY_FUNDING_ID
        ) {
          isFielValid = this.Validations.required(value);
          if (!isFielValid) {
            msg = Common.NGO.PAGE_ERRORS.FUND_AMOUNT_REQUIRED;
          } else if (parseInt(value) === 0 || parseInt(value) < 0) {
            msg = 'Value must be greater than 0';
          } else if (parseInt(value) > 10000000) {
            msg = 'Value must be less than 1,00,00,000';
          } else {
            msg = '';
          }
        }
        break;
      case 'campaignDetails':
        isFielValid = this.Validations.required(value);
        if (isFielValid) msg = Common.NGO.PAGE_ERRORS.CAMPAIGN_DETAILS_REQUIRED;
        if (value.length >= 1500)
          msg = Common.NGO.PAGE_ERRORS.MAX_CHARACTER_LENGTH_REACHED;
        else msg = '';
        break;
      case 'campaignObjective':
        isFielValid = this.Validations.required(value);
        if (isFielValid)
          msg = Common.NGO.PAGE_ERRORS.CAMPAIGN_OBJECTIVE_REQUIRED;
        if (value.length >= 1500)
          msg = Common.NGO.PAGE_ERRORS.MAX_CHARACTER_LENGTH_REACHED;
        else msg = '';
        break;
      case 'projectStartDate':
        isFielValid = this.Validations.required(value);
        msg = isFielValid
          ? ''
          : Common.NGO.PAGE_ERRORS.PROJECT_START_DATE_REQUIRED;
        break;
      case 'projectEndDate':
        isFielValid = this.Validations.required(value);
        msg = isFielValid
          ? ''
          : Common.NGO.PAGE_ERRORS.PROJECT_END_DATE_REQUIRED;
        break;
      case 'actualProjectStartDate':
        isFielValid = this.Validations.required(value);
        msg = isFielValid
          ? ''
          : Common.NGO.PAGE_ERRORS.ACTUAL_PROJECT_START_DATE_REQUIRED;
        break;
      case 'actualProjectEndDate':
        isFielValid = this.Validations.required(value);
        msg = isFielValid
          ? ''
          : Common.NGO.PAGE_ERRORS.ACTUAL_PROJECT_END_DATE_REQUIRED;
        break;
      case 'projectCountry':
        isFielValid = this.Validations.required(value);
        msg = isFielValid
          ? ''
          : Common.NGO.PAGE_ERRORS.PROJECT_COUNTRY_REQUIRED;
        break;
      case 'projectState':
        isFielValid = this.Validations.required(value);
        msg = isFielValid ? '' : Common.NGO.PAGE_ERRORS.PROJECT_STATE_REQUIRED;
        break;
      case 'projectDistrict':
        isFielValid = this.Validations.required(value);
        msg = isFielValid
          ? ''
          : Common.NGO.PAGE_ERRORS.PROJECT_DISTRICT_REQUIRED;
        break;
      case 'proposalDoc':
        isFielValid = this.Validations.required(value);
        msg = isFielValid ? '' : 'Please select Proposal Document';
        break;
      case 'needsAssessment':
        isFielValid = this.Validations.required(value);
        msg = isFielValid ? '' : 'Please select Need assessment Document';
        break;
      default:
    }
    return { isFielValid, msg };
  };
  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  eventToValue(e, trimResults, toNumber) {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      value = Number(value);
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  }
  getDependentFieldVisibleStatus = (value, event) => {
    let campTypeVolunteer,
      campTypeDirect,
      campTypeCommunity = false;
    if ((event && event.type === 'change') || !event) {
      switch (value) {
        case Common.NGO.CAMPAIGN_TYPE_VOLUNTEER_ID:
          $('.select2-container--default').css({ width: '393px' });
          campTypeVolunteer = true;
          campTypeCommunity = false;
          campTypeDirect = false;
          break;
        case Common.NGO.CAMPAIGN_TYPE_COMMUNITY_FUNDING_ID:
          campTypeVolunteer = false;
          campTypeCommunity = true;
          campTypeDirect = false;
          break;
        case 3: // direct campaign type
        default:
          campTypeVolunteer = false;
          campTypeCommunity = false;
          campTypeDirect = true;
          break;
      }
    }
    return { campTypeVolunteer, campTypeCommunity, campTypeDirect };
  };
  setFormErrorsAndValue = (event, callback, type, childCallBack) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;
    if (!type) {
      value = this.eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = this.restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    let visibleStatus =
      id === 'campaignType' &&
      this.getDependentFieldVisibleStatus(value, event);
    let formFields = {};
    const errorResult = this.validate(id, value);
    formFields[id] = {
      isValid: errorResult.isFielValid,
      error: errorResult.msg,
      value,
      jsonField: this.state.formFields[id].jsonField
    };
    formFields = defaults({}, formFields, this.state.formFields);
    if (id === 'campaignType') {
      this.setState(
        {
          ...this.state,
          formFields,
          campTypeVolunteer: visibleStatus.campTypeVolunteer,
          campTypeCommunity: visibleStatus.campTypeCommunity,
          campTypeDirect: visibleStatus.campTypeDirect,
          fieldUpdated: true
        },
        () => {
          if (typeof callback === 'function') {
            callback();
          }
          if (typeof childCallBack === 'function') {
            childCallBack();
          }
        }
      );
    } else {
      this.setState({ ...this.state, formFields, fieldUpdated: true }, () => {
        if (typeof callback === 'function') {
          callback();
        }
        if (typeof childCallBack === 'function') {
          childCallBack();
        }
      });
    }
  };
  setFormValidStatus = () => {
    const self = this;
    const formInvalidFields = map(self.state.formFields, value => {
      return value.isValid === false;
    });
    self.setState({
      formValid: formInvalidFields.some(value => {
        return value === true;
      })
        ? false
        : true
    });
  };
  onHandleBlur = (event, callback, type) => {
    this.setFormErrorsAndValue(event, callback, type);
  };

  onUploadSuceess = (fileUrl, event) => {
    this.setState(
      {
        formFields: {
          ...this.state.formFields,
          [event.target.id]: {
            isValid: true,
            error: '',
            value: fileUrl,
            jsonField: this.state.formFields[event.target.id].jsonField
          }
        }
      },
      () => {
        this.setFormValidStatus();
      }
    );
  };
  onHandleChange = (event, callback, type, childCallback, newValue) => {
    this.setFormErrorsAndValue(event, callback, type, childCallback, newValue);
  };
  fillSecondaryCauseList = () => {
    this.props.getMasterDataById(this.state.formFields.primaryCauseArea.value);
  };
  fillStatesList = () => {
    this.props.getStatesDataById(this.state.formFields.projectCountry.value);
  };
  fillDistrictsList = () => {
    this.props.getDistrictsDataById(this.state.formFields.projectState.value);
  };
  handleOptionChange = (selectedOption, eleId) => {
    let allSelectedOptions = [];
    forEach(selectedOption, obj => {
      allSelectedOptions.push(obj.id);
    });
    const event = {
      target: { id: eleId, value: [].concat(allSelectedOptions) }
    };
    this.onHandleChange &&
      this.onHandleChange(
        event,
        this.setFormValidStatus ? this.setFormValidStatus : () => {},
        ''
      );
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (
      nextprops.editSuccessResponse &&
      !this.editDetailRecieved &&
      nextprops.editSuccessResponse.code === 200
    ) {
      let campaignInfo = nextprops.editSuccessResponse.data;
      let campaignFormDetails = this.state.formFields;
      let isSaved = false;

      forEach(campaignFormDetails, obj => {
        const value =
          campaignInfo[obj.jsonField] !== 'None'
            ? campaignInfo[obj.jsonField] || ''
            : '';
        if (obj.jsonField === 'nature_of_solution') {
          var tempDivElement = document.createElement('div');
          tempDivElement.innerHTML = value;
          obj.value =
            tempDivElement.textContent || tempDivElement.innerText || '';
        } else obj.value = value;
        obj.isValid = !obj.value;
      });

      if (campaignInfo.locations && campaignInfo.locations.length > 0) {
        let locationsArr = [];
        campaignInfo.locations.map(loc => {
          const obj = {
            id: loc.id,
            projectCountry: {
              isValid: false,
              value: loc.country_slug,
              error: '',
              jsonField: 'country'
            },
            pinCode: {
              isValid: false,
              value: loc.pincode,
              error: '',
              jsonField: 'pincode'
            },
            projectState: {
              isValid: false,
              value: loc.state_slug,
              error: '',
              jsonField: 'state'
            },
            projectDistrict: {
              isValid: false,
              value: loc.city_slug,
              error: '',
              jsonField: 'city'
            }
          };
          locationsArr.push(obj);
          return false;
        });
        if (locationsArr.length > 0) this.getStateDistrictList(locationsArr);
        campaignFormDetails.projectLocation.value = locationsArr;
      }
      this.editDetailRecieved = true;
      if (
        campaignInfo.actual_project_start_date &&
        campaignInfo.actual_project_start_date !== '' &&
        campaignInfo.actual_project_start_date !== 'None' &&
        campaignInfo.actual_project_end_date &&
        campaignInfo.actual_project_end_date !== '' &&
        campaignInfo.actual_project_end_date !== 'None'
      ) {
        isSaved = true;
      }
      this.setState(
        {
          ...this.state,
          formFields: campaignFormDetails,
          campaignId: campaignInfo.campaign_id,
          currentCampaignStatus: campaignInfo['status'],
          isSaved: isSaved
        },
        () => {
          this.fillSecondaryCauseList();
          let visibleStatus = this.getDependentFieldVisibleStatus(
            this.state.formFields.campaignType.value
          );
          this.setState({
            campTypeVolunteer: visibleStatus.campTypeVolunteer,
            campTypeCommunity: visibleStatus.campTypeCommunity,
            campTypeDirect: visibleStatus.campTypeDirect
          });
        }
      );
    } else if (
      !this.editDetailRecieved &&
      nextprops.editErrorResponse &&
      nextprops.editErrorResponse.code !== null
    ) {
      this.editDetailRecieved = true;
      this.setState({ showLoader: false }, () => {
        toast.dismiss();
      });
    }
    if (
      this.state.saveRequestMade &&
      nextprops.createModifySuccessResponse &&
      nextprops.createModifySuccessResponse.code === 200
    ) {
      this.setState(
        {
          campaignId: nextprops.createModifySuccessResponse.campaignId,
          isSaved: true
        },

        () => {
          toast.dismiss();
          toast.success('Project saved successfully.');
        }
      );
    } else {
      if (
        nextprops.createModifyErrorResponse &&
        nextprops.createModifyErrorResponse.response &&
        nextprops.createModifyErrorResponse.response.status
      ) {
        toast.dismiss();
        toast.error('Some error occurred, please try again in sometime', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }
  componentDidMount() {
    const masterDataOptions = Common.NGO.MASTER_OPTIONS;
    let campaignId = '';
    this.props.getMasterData([
      masterDataOptions.PRIMARY_BENEFICIARIES,
      masterDataOptions.CAUSE_CATEGORY,
      masterDataOptions.CAMPAIGN_TYPE,
      masterDataOptions.VOLUNTEER_SKILLS
    ]);
    this.props.getMasterListCountries();
    if (this.props.isCreateCampaign) {
      let instance = HttpRequest.getInstance('HIROKU');
      instance
        .post(EndPoint.getApiUrl('NGO', 'CAMPAIGN_CREATE', 'HIROKU'), {
          ngo_id: this.state.ngoId,
          status: Common.NGO.CAMPAIGN_STATUS.NEW
        })
        .then(resp => {
          if (resp.data.status === 400) {
            return;
          }
          campaignId = resp.data.campaignId;
          window.localStorage.setItem('campaignId', campaignId);
          this.setState({
            campaignId: campaignId,
            currentCampaignStatus: 'NEW'
          });
        })
        .catch(() => {
          toast.error('error');
        });
    }
    if (
      window.localStorage.getItem('campaignId') !== null &&
      !this.props.isModify
    ) {
      this.setState({
        campaignId: window.localStorage.getItem('campaignId'),
        currentCampaignStatus: 'NEW'
      });
    }
    if (this.props.isModify) {
      this.setState(() => {
        return { isFormValid: this.setFormValidStatus() };
      });
    }
  }

  componentWillUnmount() {
    window.localStorage.removeItem('campaignId');
  }
  onSave = () => {
    const { formFields } = this.state;
    if (this.documentFileName === '') {
      return toast.error('Document upload is mandatory');
    }
    const trimmed_campaign_name = this.state.formFields.campaignName.value.trim();
    if (
      trimmed_campaign_name === '' ||
      formFields.primaryCauseArea.value === '' ||
      formFields.secondaryCauseArea.value === '' ||
      formFields.fundAmount.value === '' ||
      formFields.fundAllocated.value === '' ||
      formFields.campaignType.value === '' ||
      formFields.actualProjectStartDate.value === '' ||
      formFields.actualProjectEndDate.value === '' ||
      formFields.rolloverImage.value === ''
    ) {
      return toast.error(`Please fill the ${this.getEmptyFieldName()}`);
    }
    // let project_start_date = this.state.formFields.projectStartDate.value;
    // let project_end_date = this.state.formFields.projectEndDate.value;
    let actual_project_start_date = this.state.formFields.actualProjectStartDate
      .value;
    let actual_project_end_date = this.state.formFields.actualProjectEndDate
      .value;
    // if (Date.parse(project_start_date) < new Date()) {
    //   return toast.error('Project Start Date Should be Greater than Now');
    // }
    // if (Date.parse(project_end_date) < new Date()) {
    //   return toast.error('Project End Date Should be Greater than Now');
    // }
    // if (Date.parse(project_end_date) <= Date.parse(project_start_date)) {
    //   return toast.error(
    //     'Project End Date Should be Greater than Project Start Date'
    //   );
    //}
    if (
      Date.parse(actual_project_end_date) <=
      Date.parse(actual_project_start_date)
    ) {
      return toast.error(
        'Actual Project End Date Should be Greater than Actual Project Start Date'
      );
    }
    if (Date.parse(actual_project_start_date) < new Date()) {
      return toast.error(
        'Actual Project Start Date Should be Greater than Now'
      );
    }
    if (Date.parse(actual_project_end_date) < new Date()) {
      return toast.error('Actual Project End Date Should be Greater than Now ');
    }

    const payloadObj = this.getJson(this.state.currentCampaignStatus);
    if (
      !this.state.formFields.campaignName.value ||
      !this.state.formFields.campaignType.value
    ) {
      return toast.error('Please Enter the Project Name and Project Type');
    } else {
      this.setState({
        saveRequestMade: true,
        disable_campaign_type: true
      });
      this.props
        .createOrModifyCampaign(payloadObj)
        .then(() => {
          if (
            actual_project_start_date === '' ||
            actual_project_end_date === ''
          ) {
            let dateFormFields = {};
            dateFormFields['actualProjectStartDate'] = {
              isValid: formFields.actualProjectStartDate.isValid,
              error: formFields.actualProjectStartDate.error,
              value: payloadObj.actual_project_start_date,
              jsonField: formFields.actualProjectStartDate.jsonField
            };
            dateFormFields['actualProjectEndDate'] = {
              isValid: formFields.actualProjectEndDate.isValid,
              error: formFields.actualProjectEndDate.error,
              value: payloadObj.actual_project_end_date,
              jsonField: formFields.actualProjectEndDate.jsonField
            };
            dateFormFields = defaults(
              {},
              dateFormFields,
              this.state.formFields
            );
            this.setState({
              ...this.state,
              formFields: dateFormFields,
              isSaved: true
            });
          }
          this.setState({ fieldUpdated: false });
        })
        .catch(error => {
          toast.error('Uh-Oh! Looks like we are having server issues.');
          console.error(error);
        });
      window.localStorage.removeItem('campaignId');
    }
  };
  getFormattedValue = value => {
    let formattedValue;
    switch (true) {
      case value && Array.isArray(value):
        formattedValue = value.toLocaleString();
        break;
      case value && Object.prototype.toString.call(value) === '[object Date]':
        formattedValue = dayjs('value').format('YYYY-MM-DD');
        break;
      case value:
      default:
        formattedValue = value;
    }
    return formattedValue;
  };
  getJson = status => {
    let dataToSave = {};
    let campaignFormDetails = {};
    const formFields = this.state.formFields;
    forEach(this.state.formFields, obj => {
      let formattedValue = this.getFormattedValue(obj.value);
      if (formattedValue) {
        campaignFormDetails[obj.jsonField] = formattedValue;
      }
    });
    dataToSave['actual_project_start_date'] =
      formFields.actualProjectStartDate.value !== ''
        ? formFields.actualProjectStartDate.value
        : formFields.projectStartDate.value;
    dataToSave['actual_project_end_date'] =
      formFields.actualProjectEndDate.value !== ''
        ? formFields.actualProjectEndDate.value
        : formFields.projectEndDate.value;
    dataToSave['status'] = status.split(' ').join('_');
    dataToSave['ngo_id'] = this.state.ngoId;
    if (this.state.campaignId) {
      dataToSave['campaign_id'] = this.state.campaignId;
    }
    dataToSave['is_project'] = true;
    return dataToSave;
  };
  getCommunityFundFields = () => {
    return ['fund_amount', 'campaign_needs'];
  };
  getDirectFundFields = () => {
    return ['fund_amount', 'campaign_needs'];
  };
  getVolunteerFields = () => {
    return [
      'volunteer_count',
      'volunteer_skills',
      'hours_per_day',
      'selection_criteria',
      'address'
    ];
  };
  getCommonFields = () => {
    return [
      'title',
      'campaign_start_date',
      'campaign_end_date',
      // 'location',
      'country',
      'state',
      'city',
      'image_url',
      'video_url',
      'primary_beneficiary_slug',
      'impact',
      'cause_area',
      'sub_cause_area',
      'campaign_type_id',
      'objective',
      'no_of_beneficiaries',
      'details',
      'proposal_doc',
      'needs_assessment',
      // 'project_start_date',
      // 'project_end_date',
      'tnc_accepted'
    ];
  };
  getEmptyFieldName = () => {
    const { formFields } = this.state;
    const trimmed_campaign_name = this.state.formFields.campaignName.value.trim();
    if (trimmed_campaign_name === '') return 'Project Title';
    if (formFields.primaryCauseArea.value === '') return 'Primary Cause Area';
    if (formFields.secondaryCauseArea.value === '')
      return 'Secondary Cause Area';
    if (formFields.fundAmount.value === '') return 'Original Requested Funds';
    if (formFields.fundAllocated.value === '') return 'Actual Funds Approved';
    if (formFields.campaignType.value === '') return 'Project Type';
    if (formFields.beneficiaries.value === '') return 'Number of Beneficiaries';
    if (formFields.beneficiariesImpacted.value === '')
      return 'Beneficiaries Impacted';
    if (formFields.actualProjectStartDate.value === '')
      return 'Actual Project Start Date';
    if (formFields.actualProjectEndDate.value === '')
      return 'Actual Project End Date';
    if (formFields.rolloverImage.value === '') return 'Rollover image';
    if (formFields.problemStatement.value === '') return 'Problem statement';
    if (formFields.natureOfSolution.value === '')
      return 'Models or ways to address the problem';
    return false;
  };
  getStateDistrictList = () => {
    const countryValue = this.state.formFields.projectCountry.value;
    const stateValue = this.state.formFields.projectState.value;
    if (
      countryValue &&
      countryValue !== '' && // used double quotes
      this.props.listStates.length === 0
    )
      this.props.getStatesDataById(this.state.formFields.projectCountry.value);
    if (
      stateValue &&
      stateValue !== '' &&
      this.props.listDistricts.length === 0
    )
      this.props.getDistrictsDataById(this.state.formFields.projectState.value);
  };
  createMainHtml = () => {
    const campaignType = this.props.masterInfo
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.CAMPAIGN_TYPE]
      : null;
    if (
      this.props.masterInfo &&
      this.props.masterInfo.masterResponseStatus === 200
    ) {
      if (!this.editDetailRequested) {
        this.editDetailRequested = true;
        this.props.getCampaign(this.props.id, 'edit');
      }
    }
    // const isCampaignUnderReview =
    //   this.state.currentCampaignStatus ===
    //   Common.NGO.CAMPAIGN_STATUS.UNDER_REVIEW;
    if (this.props.id) this.getStateDistrictList();

    const handleCloseModal = e => {
      e.preventDefault();
      this.props.history.push('/ngo/profile');
    };

    return (
      <>
        {this.props.isCreateCampaign &&
          (this.props.profileCompleted.isProfileComplete === false ||
            this.props.isNgoApproved === false) && (
            <Modal
              show={true}
              title={'Create Project'}
              handleCloseModal={handleCloseModal}
              sizeClass="modal-lg">
              <p className="my-2 fs-18 font-weight-normal">
                You can create campaigns only after your profile is completed
                and approved. Please wait while we approve your profile if you
                have already submitted it or
                <button
                  className="btn-link btn btn-lg p-0 fs-18 font-weight-normal text-normal text-lowercase"
                  onClick={() => {
                    this.props.history.push('/ngo/profile');
                  }}>
                  Click Here To Complete Profile
                </button>
              </p>
            </Modal>
          )}
        <div className={`row`}>
          <div className="col-12">
            <h4 className="d-flex align-items-center mb-5 pb-4">
              <Link to={`/ngo/project/list`}>
                <i className="fas fa-angle-left fs-36 color-888686 cursor-pointer"></i>
              </Link>
              <Link to={`/ngo/project/list`}>
                <span className="d-inline-block mr-4 color-888686 font-weight-bold pl-4 cursor-pointer">
                  Back
                </span>
              </Link>
              <span className="d-inline-block font-weight-bolder">
                {this.state.formFields.campaignName.value !== ''
                  ? this.state.formFields.campaignName.value
                  : '-'}
              </span>
            </h4>
            <div className="d-flex justify-content-center mb-5">
              <span
                className="fs-16 font-weight-bold position-relative project-bullet project-tab-title project-bullet-active
                text-center project-top-border px-4">
                Project Details Overview
              </span>
              <span className="fs-16 position-relative project-bullet project-tab-title text-center project-top-border px-4">
                Project Activities Plan
              </span>
              <span className="fs-16 position-relative project-bullet project-tab-title text-center px-4">
                Project Impact Indicators
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className={`col-12 p-4 bg-white`}>
              <div className="row justify-content-between mb-4">
                <h3 className="campaign-details-heading fw-500">
                  Project Details
                </h3>
              </div>
              <div className="row">
                <InputText
                  required={true}
                  labelText="Project Title"
                  disableInput={true}
                  maxLength={100}
                  type={'text'}
                  PlaceHolder={'Maximum Length is 100 Characters'}
                  id="campaignName"
                  name="campaignName"
                  fieldError={this.state.formFields.campaignName.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  fieldValue={this.state.formFields.campaignName.value}
                  wrapperClassName={'col-12 pl-0 pr-0'}
                />
                <InputTextArea
                  required={true}
                  labelText="Primary Cause Area"
                  id="primaryCauseArea"
                  name="primaryCauseArea"
                  rows={3}
                  fieldValue={this.state.formFields.primaryCauseArea.value}
                  fieldError={this.state.formFields.primaryCauseArea.error}
                  disableInput={true}
                  wrapperClassName={'col-4 pl-0'}
                />

                <InputTextArea
                  className="secondary-cause"
                  required={true}
                  labelText="Secondary Cause Area"
                  id="secondaryCauseArea"
                  name="secondaryCauseArea"
                  rows={3}
                  fieldValue={this.state.formFields.secondaryCauseArea.value}
                  fieldError={this.state.formFields.secondaryCauseArea.error}
                  disableInput={true}
                  wrapperClassName={'col-4'}
                />
                <InputTextArea
                  required={true}
                  labelText="Beneficiaries Impacted"
                  id="beneficiariesImpacted"
                  name="beneficiariesImpacted"
                  rows={3}
                  fieldValue={this.state.formFields.beneficiariesImpacted.value}
                  fieldError={this.state.formFields.beneficiariesImpacted.error}
                  disableInput={true}
                  wrapperClassName={'col-4 pl-0'}
                />

                <InputText
                  required={true}
                  type={'number'}
                  labelText="Original Requested Funds"
                  id="fundAmount"
                  name="fundAmount"
                  fieldValue={this.state.formFields.fundAmount.value}
                  fieldError={this.state.formFields.fundAmount.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  disableInput={true}
                  maxLength={8}
                  wrapperClassName={'col-4 pl-0'}
                />
                <InputText
                  required={true}
                  type={'number'}
                  labelText="Actual Funds Approved"
                  id="fundAllocated"
                  name="fundAllocated"
                  fieldValue={this.state.formFields.fundAllocated.value}
                  fieldError={this.state.formFields.fundAllocated.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  disableInput={true}
                  maxLength={8}
                  wrapperClassName={'col-4 pl-0'}
                />
                <InputSelect
                  required={true}
                  labelText="Project Type"
                  id="campaignType"
                  name="campaignType"
                  options={campaignType}
                  fieldValue={this.state.formFields.campaignType.value}
                  fieldError={this.state.formFields.campaignType.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  dataType="num"
                  disableInput={true}
                  wrapperClassName={'col-4'}
                  extraInfoAboutField={
                    'This field is use to choose who is going to fund your Project.'
                  }
                />
                <InputText
                  required={true}
                  labelText="Number of Beneficiaries"
                  type={'number'}
                  maxLength={5}
                  id="beneficiaries"
                  name="beneficiaries"
                  fieldError={this.state.formFields.beneficiaries.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  fieldValue={this.state.formFields.beneficiaries.value}
                  disableInput={true}
                  PlaceHolder={'Maximum Length is 5 Characters'}
                  wrapperClassName={'col-4 pl-0'}
                />
              </div>
              <div className="row">
                <InputDaypicker
                  required={true}
                  labelText="Actual Project Start Date"
                  id="actualProjectStartDate"
                  name="actualProjectStartDate"
                  fieldError={
                    this.state.formFields.actualProjectStartDate.error
                  }
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  fieldValue={
                    this.state.formFields.actualProjectStartDate.value
                  }
                  daysOfWeek={[]}
                  wrapperClassName="col-4 pl-0"
                  disableInput={false}
                />
                <InputDaypicker
                  required={true}
                  labelText="Actual Project End Date"
                  id="actualProjectEndDate"
                  name="actualProjectEndDate"
                  fieldError={this.state.formFields.actualProjectEndDate.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  fieldValue={this.state.formFields.actualProjectEndDate.value}
                  daysOfWeek={[]}
                  wrapperClassName="col-4"
                  disableInput={false}
                />
              </div>

              <div className="row">
                {this.state.formFields.projectLocation?.value?.map(
                  (location, index) => {
                    return (
                      <div
                        key={index + location.projectCountry.jsonField}
                        className="w-100 d-flex align-items-center">
                        <InputText
                          required={true}
                          labelText="Project Country"
                          id="projectCountry"
                          name="projectCountry"
                          fieldValue={location.projectCountry.value}
                          fieldError={location.projectCountry.error}
                          disableInput={true}
                          wrapperClassName={'w-25 pr-2'}
                        />
                        <InputText
                          type={'number'}
                          labelText="Pin Code"
                          id="pinCode"
                          name="pinCode"
                          fieldError={location.pinCode.error}
                          // onHandleBlur={this.onHandleBlur}
                          fieldValue={location.pinCode.value}
                          disableInput={true}
                          maxLength={6}
                          wrapperClassName={'w-25 px-2'}
                        />
                        <InputText
                          required={true}
                          labelText="Project State"
                          id="projectState"
                          name="projectState"
                          fieldValue={location.projectState.value}
                          fieldError={location.projectState.error}
                          disableInput={true}
                          wrapperClassName={'w-25 px-2'}
                        />
                        <InputText
                          required={true}
                          labelText="Project City"
                          id="projectDistrict"
                          name="projectDistrict"
                          fieldValue={location.projectDistrict.value}
                          fieldError={location.projectDistrict.error}
                          disableInput={true}
                          wrapperClassName={'w-25 px-2'}
                        />
                      </div>
                    );
                  }
                )}
              </div>
              <hr className="section-divider w-100 mb-4" />
              <h4 className="campaign-details-heading fw-500 mb-4 w-100">
                Project Content
              </h4>

              <div className="row mb-5 pb-2">
                <div className="col-4 pl-0">
                  <FileInput
                    required={true}
                    label="Rollover image"
                    placeholder="Resolution in between 600x400 and 4096x4096"
                    id="rolloverImage"
                    name="rolloverImage"
                    refId={this.state.campaignId}
                    fileSizeLimit={10}
                    validateFile={imageResolutionValidator}
                    value={this.state.formFields.rolloverImage.value || ''}
                    accept="image/*"
                    docType="campaign_image"
                    disabled={true}
                    onSuccess={this.onUploadSuceess}
                    extraInfoAboutField={
                      'This image should depict Project cause and will be visible to users'
                    }
                  />
                  {this.state.formFields.rolloverImage.value && (
                    <div className="w-100 img-16x9 mb-4 rounded-m loading-bg">
                      <img
                        src={
                          getCloudinaryUrlForImage(
                            this.state.formFields.rolloverImage.value,
                            200
                          ) ||
                          getCloudinaryUrlForImage(
                            'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                            200
                          )
                        }
                        data-src={
                          this.state.formFields.rolloverImage.value ||
                          'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                        }
                        onError={e =>
                          (e.target.src = e.target.getAttribute('data-src'))
                        }
                        alt=""
                        className="abs-full rounded-m"
                      />
                    </div>
                  )}
                </div>

                <InputTextArea
                  required={true}
                  labelText="Project Goal"
                  id="projectGoal"
                  name="projectGoal"
                  maxLength={1500}
                  PlaceHolder={'Maximum  Length is 3000 Characters'}
                  fieldError={this.state.formFields.projectGoal.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  fieldValue={this.state.formFields.projectGoal.value}
                  className="campaign_details campaign-textarea"
                  disableInput={true}
                  wrapperClassName="w-100"
                />
                <InputTextArea
                  required={true}
                  labelText="Problem statement"
                  id="problemStatement"
                  name="problemStatement"
                  maxLength={1500}
                  PlaceHolder={'Maximum  Length is 1500 Characters'}
                  fieldError={this.state.formFields.problemStatement.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  fieldValue={this.state.formFields.problemStatement.value}
                  className="campaign_details campaign-textarea"
                  disableInput={true}
                  wrapperClassName="w-100"
                />
                <InputTextArea
                  required={true}
                  labelText=" Models or ways to address the problem"
                  id="natureOfSolution"
                  name="natureOfSolution"
                  PlaceHolder={'Maximum  Length is 1500 Characters'}
                  maxLength={1500}
                  fieldError={this.state.formFields.natureOfSolution.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  fieldValue={this.state.formFields.natureOfSolution.value}
                  className="campaign_objectives campaign-textarea"
                  disableInput={true}
                  wrapperClassName="w-100"
                />
                <div className="col-4 pl-0"></div>
                <div className="col-4">
                  <FileInput
                    label="Needs Assessment Report"
                    id="needsAssessment"
                    placeholder="Choose a pdf/word doc"
                    name="needsAssessment"
                    refId={this.state.campaignId}
                    fileSizeLimit={20}
                    value={this.state.formFields.needsAssessment.value || ''}
                    accept=".pdf,.doc,.docx"
                    disabled={true}
                    docType="campaign_needs_assessment"
                    onSuccess={this.onUploadSuceess}
                    extraInfoAboutField={
                      'This field is use to upload a document related to your Project needs'
                    }
                  />
                  {this.state.formFields.needsAssessment.value && (
                    <a
                      className="ml-2 mt-2 color-1FA4FA fs-12 cursor-pointer text-decoration"
                      href={this.state.formFields.needsAssessment.value}
                      rel="noreferrer noopener"
                      target="_blank">
                      View
                    </a>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 pl-0 text-center mb-3">
                  <button
                    type="button"
                    onClick={() => this.onSave()}
                    disabled={!this.state.fieldUpdated}
                    className={`btn btn-primary btn-custom`}>
                    Save
                  </button>
                  <Link to={'/ngo/project/activitiesPlan/' + this.props.id}>
                    <button
                      type="button"
                      disabled={!this.state.isSaved}
                      className={`btn btn-outline-primary btn-custom ml-3`}>
                      Next
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    if (this.props.loading) {
      return <Loader loaderText="Loading..." />;
    }
    return (
      <div className="row p-4 create-campaign">{this.createMainHtml()}</div>
    );
  }
}
const mapStateToProps = state => ({
  userProfile: state.login.userInfo,
  masterInfo: state.common.masterInfo,
  masterSecondaryInfo: state.common.masterSecondaryInfo,
  masterSecondaryResponseStatus: state.common.masterSecondaryResponseStatus,
  listCountries: state.common.listCountries,
  listStates: state.common.listStates,
  listDistricts: state.common.listDistricts,
  createModifySuccessResponse: state.campaign.createModifySuccessResponse,
  createModifyErrorResponse: state.campaign.createModifyErrorResponse,
  editSuccessResponse: state.campaign.editSuccessResponse,
  editErrorResponse: state.campaign.editErrorResponse,
  loading: state.campaign.loading,
  profileCompleted: state.ngo.ngoProfile
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMasterData,
      getMasterDataById,
      createOrModifyCampaign,
      getCampaign,
      getMasterListCountries,
      getStatesDataById,
      getDistrictsDataById
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(StartModifyProject);
