import { Action } from '../constants';
const initialState = {
  donationList: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.DONATION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.DONATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionList: action.payload
      };

    case Action.DONATION_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
