import React from 'react';
import iGiveXn from '../../assets/images/NewiGivXN_color.png';
import DummySign from '../../assets/images/dummySign.png';
import DummyStamp from '../../assets/images/dummy_stamp.png';
import MettaLogo from '../../assets/images/mettaSocialLogo.png';
import { getFormattedDate } from '../../helpers/util';
import './invoices.css';
const Receipt = ({ invoice, userInfo, ngoName }) => {
  const getValue = value => {
    if (value !== null) {
      return '₹ ' + new Intl.NumberFormat('en-IN').format(value);
    } else {
      return '0';
    }
  };
  const getPercentage = () => {
    if (invoice?.amount === 0) {
      return 0;
    }
    const amountTransferedByEcrow =
      Number(invoice?.amount) + Number(invoice?.mettasocial_fees);
    const mettasocialFees =
      Number(invoice?.mettasocial_fees) - Number(invoice?.gst_charges);
    const percentage = (mettasocialFees / amountTransferedByEcrow) * 100;

    return percentage.toFixed(2);
  };
  return (
    <>
      <div className="border fs-14">
        <div className="row pl-2 pr-4 py-4  justify-content-between align-items-center">
          <div className="">
            <img src={MettaLogo} alt="" className="logo_images_ms" />
          </div>
          <div className=" text-end d-flex align-items-center">
            <img src={iGiveXn} alt="igivexn" className="logo_images" />
            <div className="fs-36">Invoice</div>
          </div>
        </div>
        <div className="row pb-4  justify-content-between">
          <div className="col-5">
            <div className="fs-16 fw-500 color-000000">
              Metta Social Solutions Pvt Ltd
            </div>
            <div className="text-1B1C1D col-8 pl-0">
              1st Floor, Sterling Tower, Pan card club road, Baner Pune, 411022
              contactus@mettasocial.com
            </div>
          </div>
          <div className=" text-end pr-4">
            <div className="d-flex">
              <div className="color-666565">Invoice Number:</div>
              <div className="ml-3 fs-14">{invoice?.invoice_number}</div>
            </div>
            <div className="d-flex">
              <div className="color-666565">Date Of Issue:</div>
              <div className="ml-3 fs-14">
                {' '}
                {getFormattedDate('dd/MM/yyyy', invoice?.invoice_date)}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 border-top">
          <div>INVOICE TO</div>
          <div className="fs-16 fw-500 color-1B1C1D">{ngoName}</div>
          <div className="col-6 pl-0">{userInfo?.communication_address},</div>
          <div>{`${userInfo?.city},  ${userInfo?.pincode}.`}</div>
        </div>
        <div className="p-4">
          <table>
            <thead className="fs-14 bg-005492 text-fff">
              <th className="text-fff border">Total Payable Amount</th>
              <th className="text-fff border">Total</th>
            </thead>
            <tbody className="">
              <tr className="border">
                <td className="border">
                  {'Funds Raised from All Published Missions'}
                </td>
                <td className="border">
                  {getValue(
                    Number(invoice?.amount) + Number(invoice?.mettasocial_fees)
                  )}
                </td>
              </tr>
              <tr>
                <td>{`Metta Social fee (${getPercentage()} %)`}</td>
                <td>
                  -
                  {new Intl.NumberFormat('en-IN').format(
                    Number(invoice?.mettasocial_fees) -
                      Number(invoice?.gst_charges)
                  )}
                </td>
              </tr>
              <tr className="border-bottom">
                <td>{'GST (18%)'}</td>

                <td>
                  -{new Intl.NumberFormat('en-IN').format(invoice?.gst_charges)}
                </td>
              </tr>
              <tr>
                <td className="fs-16 fw-500 color-1B1C1D">
                  {'Total amount transferred'}
                </td>

                <td className="fw-500 fs-16 color-000000">
                  {getValue(Number(invoice?.amount))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row p-4 align-items-center justify-content-between">
          <div className="py-4">
            Thank you for choosing Metta Social as a Impact Partner
          </div>
          <div className="py-4 position-relative col-12">
            <img
              src={DummySign}
              width={100}
              height={100}
              alt="sign"
              className="position-absolute stamp_sign"
            />
            <img
              src={DummyStamp}
              width={100}
              height={100}
              alt="sign"
              className="position-absolute stamp_sign"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Receipt;
