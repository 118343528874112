import React, { useEffect, useState } from 'react';
import {
  getMNEBeneficiariesDynamic,
  removeMNEBeneficiariesDynamic
} from '../../actions';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import DeleteIcon from '../../assets/icons/delete_icon_benef.svg';
import Pagination from '../paginationFullRow';
import Modal from './Modals/Modal';
import SortIconAsc from '../../assets/icons/sort_icon_asc.svg';
import SortIconDec from '../../assets/icons/sort_icon_desc.svg';
import SortIconDefault from '../../assets/icons/sort_icon_default.svg';

const DynamicBeneficiaries = props => {
  const [tableHeaders, setTableHeaders] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [benfDeleteId, setBenefDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [sortedColumn, setSortedColumn] = useState('submittedOn');
  const [sortDirection, setSortDirection] = useState('desc');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    props.dynamicBeneficiaries && setSelectData(props.dynamicBeneficiaries);
  });
  const dispatch = useDispatch();
  const getDynamicBenefData = () => {
    dispatch(getMNEBeneficiariesDynamic(props.id));
  };
  const camelCaseToWords = key => {
    const words = key.split(/(?=[A-Z])/);
    return words.map(word => word.toLowerCase()).join(' ');
  };
  const handleSort = column => {
    setCurrentPage(1);
    let newSortDirection = 'asc';
    if (column === sortedColumn && sortDirection === 'asc') {
      newSortDirection = 'desc';
    }
    const sortedData = [...filteredData].sort((a, b) => {
      const dateA = new Date(a[column]);
      const dateB = new Date(b[column]);
      return newSortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setSortedColumn(column);
    setSortDirection(newSortDirection);
    setFilteredData(sortedData);
  };
  const getMoreItems = (id, value) => {
    return (
      <div
        className={
          value && value.length > 15
            ? 'h-50 overflow-scroll dropdown-menu moreItemsDiv p-0'
            : 'dropdown-menu moreItemsDiv p-0'
        }
        aria-labelledby={id}>
        {value
          ? value.map((item, index) => {
              if (index >= 2) {
                return (
                  <div key={item.id} className="moreItemsDropDiv p-2 rounded">
                    <p className="text-005492 fs-12 bg-D9F0FF mb-0 px-3 rounded">
                      {item}
                    </p>
                  </div>
                );
              }
            })
          : null}
      </div>
    );
  };
  const handleSearch = e => {
    const searchText = e.target.value.toLowerCase();
    setSearchText(searchText);

    const searchedData = selectData.filter(item => {
      return Object.entries(item).some(([key, value]) => {
        if (key === 'photograph') {
          return false; // Exclude 'photo' key from search
        }
        return String(value)
          .toLowerCase()
          .includes(searchText);
      });
    });

    setFilteredData(searchedData);
  };

  useEffect(() => {
    getDynamicBenefData();
  }, []);
  const handleDeleteRecord = () => {
    dispatch(removeMNEBeneficiariesDynamic(benfDeleteId)).then(() => {
      dispatch(getMNEBeneficiariesDynamic(props.id));
      setTimeout(() => {
        toast.success('Beneficiary has been deleted successfully');
      }, 500);
    });
  };
  useEffect(() => {
    if (selectData.length > 0) {
      setTableHeaders(Object.keys(selectData[0]));
    }
    setFilteredData(selectData);
  }, [selectData]);
  const DeleteBeneficiaries = id => {
    setShowModal(true);
    setBenefDeleteId(id);
    getDynamicBenefData();
  };
  const onPageChange = option => {
    if (option[0] === 'Prev') {
      setCurrentPage(prev => prev - 1);
    } else if (option[0] === 'Next') {
      setCurrentPage(prev => prev + 1);
    } else if (option[0] === 'postsPerPage') {
      setCurrentPage(1);
      setPostPerPage(option[1]);
    } else if (option[0] === 'currentPage') {
      setCurrentPage(option[1]);
    }
  };
  const paginationRecords = data => {
    let currentTableData = [];
    if (data && data.length > 0) {
      const lastPageIndex = currentPage * postsPerPage;
      const firstPageIndex = lastPageIndex - postsPerPage;
      currentTableData = data.slice(firstPageIndex, lastPageIndex);
    }
    return currentTableData;
  };
  const dataPerPage = paginationRecords(filteredData);
  const postsPerPageSelectArray = [15, 20, 25, 30, 35];
  const handleOpenCloseModal = () => {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end m-4 ">
        <div className="transparent input-group p-0  position-relative col-2">
          <input
            className="form-control filter-search bg-white"
            type="text"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search..."
          />
          <div className="input-group-prepend position-absolute search-icon">
            <i className="input-group-text fa fa-search form-control-feedback position-relative text-969696"></i>
          </div>
        </div>
      </div>
      <div
        className="dynamicBeneficiaryTable mx-4 mt-5"
        style={
          dataPerPage.length > 12
            ? { height: '48vh', overflowY: 'scroll' }
            : { height: 'auto', overflowY: 'scroll' }
        }>
        <div className="d-flex position-relative">
          <div
            className="px-0 dynamicBeneficiaryTable"
            style={{ paddingRight: '70px !important' }}>
            <table className="table table-hover table-pledge-summary bg-white">
              <thead className="table-head fs-14 border-blue">
                <tr className="text-left ">
                  {tableHeaders.map(header =>
                    header === 'campDate' || header === 'submittedOn' ? (
                      <th
                        key={header}
                        className="no-wrap table-header-sticky bg-white "
                        onClick={() => handleSort(header)}>
                        {camelCaseToWords(header).toUpperCase()}

                        <span className="ml-2">
                          {sortDirection === 'desc' &&
                          sortedColumn === header ? (
                            <img
                              className="mb-1"
                              src={SortIconDec}
                              alt="DescendingSort"
                              width={20}
                              height={20}
                            />
                          ) : sortDirection === 'asc' &&
                            sortedColumn === header ? (
                            <img
                              className="mb-1"
                              src={SortIconAsc}
                              alt="AscendingSort"
                              width={20}
                              height={20}
                            />
                          ) : (
                            <img
                              className="mb-1"
                              src={SortIconDefault}
                              alt="DefaultSort"
                              width={20}
                              height={20}
                            />
                          )}
                        </span>
                      </th>
                    ) : (
                      <th
                        key={header}
                        className="no-wrap table-header-sticky bg-white ">
                        {camelCaseToWords(header).toUpperCase()}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {dataPerPage &&
                  dataPerPage.map((beneficiaries, index) => {
                    return (
                      <tr key={index}>
                        {tableHeaders.map(header =>
                          header === 'photograph' ? (
                            <td key={header} className="text-left no-wrap">
                              {beneficiaries[header] !== '' ? (
                                <img
                                  src={beneficiaries[header]}
                                  alt="Photograph"
                                  width={30}
                                  height={30}
                                />
                              ) : (
                                'No Photo'
                              )}
                            </td>
                          ) : Array.isArray(beneficiaries[header]) ? (
                            <td key={header} className="text-left no-wrap">
                              {beneficiaries[header]?.length > 0 &&
                                beneficiaries[header]?.map((item, index) => {
                                  if (index < 2) {
                                    return (
                                      <span
                                        key={index + item}
                                        className="text-005492 fs-12 bg-D9F0FF mb-0 px-3 py-2 rounded mx-2">
                                        {item.length > 20
                                          ? `${item.slice(0, 20)}...`
                                          : item}
                                      </span>
                                    );
                                  } else {
                                    return false;
                                  }
                                })}
                              {beneficiaries[header]?.length > 2 && (
                                <>
                                  <span
                                    onClick={getMoreItems}
                                    id={'pca-' + index}
                                    className="text-005492"
                                    data-toggle="dropdown">
                                    +
                                    {beneficiaries[header]?.length === 3
                                      ? '1'
                                      : beneficiaries[header]?.length - 2}
                                  </span>
                                  {getMoreItems(
                                    'pca-' + index,
                                    beneficiaries[header]
                                  )}
                                </>
                              )}
                            </td>
                          ) : (
                            <td key={header} className="text-left no-wrap">
                              {beneficiaries[header]}
                            </td>
                          )
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {dataPerPage > 0 && (
            <div
              className="border-left position-sticky"
              style={{ right: '0px' }}>
              <table className="table table-hover table-pledge-summary bg-white">
                <thead className="table-head">
                  <tr className="text-left">
                    <th className="table-header-sticky bg-white fs-14">
                      DELETE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataPerPage &&
                    dataPerPage.map((beneficiaries, index) => {
                      return (
                        <tr key={index}>
                          {props.isUserAuthorized && (
                            <td
                              className="d-flex justify-content-center"
                              onClick={() =>
                                DeleteBeneficiaries(beneficiaries.beneficiaryId)
                              }>
                              <img
                                src={DeleteIcon}
                                width={15}
                                height={19}
                                alt="Delete"
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>{' '}
      <div className="mx-4">
        {' '}
        <Pagination
          pageSize={postsPerPage}
          totalCount={selectData?.length}
          currentPage={currentPage}
          onPageChange={onPageChange}
          postsPerPageSelectArray={postsPerPageSelectArray}
        />
      </div>
      <Modal
        show={showModal}
        title={'Are you sure you want to delete this beneficiary?'}
        handleCloseModal={handleOpenCloseModal}
        sizeClass={'modal-md'}>
        <div className="">
          <div className="fs-16 text-1C1C1C">
            <span>This action is irreversible.</span>
          </div>
          <div className="d-flex justify-content-end mt-4">
            {' '}
            <button
              className="btn btn-primary btn-lg"
              onClick={handleDeleteRecord}>
              Confirm
            </button>
            <button
              className="btn btn-secondary ml-3 btn-lg"
              onClick={() => {
                setShowModal(false);
              }}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DynamicBeneficiaries;
