export function DateTimeUTCFormat(dateTime) {
  if (dateTime !== '' || dateTime !== null || dateTime !== undefined) {
    var dateUTC = new Date(dateTime);
    dateUTC = dateUTC.getTime();
    var dateIST = new Date(dateUTC);

    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);
    let tempTimeValue = dateIST.toString();
    tempTimeValue = tempTimeValue.slice(16, 21);

    dateTime = dateTime?.slice(0, 16);
    let dateValue = dateTime?.slice(0, 10);
    // let time = dateTime?.slice(11);
    let time = tempTimeValue;
    let setDate = new Date(dateValue);
    dateValue =
      setDate.getDate() +
      '/' +
      parseInt(setDate.getMonth() + 1) +
      '/' +
      setDate.getFullYear();
    var timePartArray = time?.split(':');
    var ampm = 'am';
    if (timePartArray[0] >= 12) {
      ampm = 'pm';
    }
    if (timePartArray[0] > 12) {
      timePartArray[0] = timePartArray[0] - 12;
    }
    let formattedTime = timePartArray[0] + ':' + timePartArray[1] + ' ' + ampm;
    return dateValue + ' ' + formattedTime;
  } else {
    return '--';
  }
}

export function getTimeDifference(dateTime) {
  if (dateTime !== '' || dateTime !== null || dateTime !== undefined) {
    var dateUTC = new Date(dateTime);
    var currentDate = new Date();

    dateUTC = dateUTC.getTime();
    currentDate = currentDate.getTime();
    var dateIST = new Date(dateUTC);
    var currentDateIst = new Date(currentDate);

    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);
    const timeDiff = Math.abs(dateIST.getTime() - currentDateIst.getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  } else {
    return null;
  }
}

export function getTimestampDifference(dateTime) {
  const currentTime = new Date().getTime();
  let savedTime = new Date(dateTime);
  savedTime = savedTime.getTime();
  let dateIST = new Date(savedTime);

  dateIST.setHours(dateIST.getHours() + 5);
  dateIST.setMinutes(dateIST.getMinutes() + 30);
  savedTime = dateIST;

  const timeDiffInMilliseconds = currentTime - savedTime;
  const timeDiffInHours = timeDiffInMilliseconds / (1000 * 60 * 60);

  return timeDiffInHours;
}

export function getDayDifference(plannedStartDate, actualStartDate) {
  const date1 = new Date(plannedStartDate);
  const date2 = new Date(actualStartDate);

  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const diffDays = Math.round(
    Math.floor((date1.getTime() - date2.getTime()) / oneDay)
  );
  return parseInt(diffDays);
}

export function dateFormatMMDDYYYY(date) {
  if (date === undefined) {
    date = new Date();
  }
  const isoDate = date;
  const dateObj = new Date(isoDate);
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}

//eg. 21 April 2021
export function convertToDayMonthYearFormat(timeStamp) {
  return new Date(timeStamp)?.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
}

export function dateFormatYYYYMMDD(dateString) {
  var formattedDate = null;
  if (dateString !== '') {
    // split the date string into day, month and year
    const [day, month, year] = dateString.split('/');

    // create a new date object with the year, month and day in yyyy-mm-dd format
    const dateObj = new Date(`${year}-${month}-${day}`);

    // format the date object in yyyy-mm-dd format
    formattedDate = dateObj.toISOString().split('T')[0];
  } else {
    formattedDate = null;
  }

  return formattedDate;
}
export function dateFormatDDMMYYYY(dateString) {
  var formattedDate = null;
  if (dateString !== '') {
    // split the date string into day, month and year
    const [year, month, day] = dateString.split('-');

    // create a new date object with the year, month and day in dd-mm-yyyy format
    const dateObj = `${day}/${month}/${year}`;

    // format the date object in dd-mm-yyyy format
    formattedDate = dateObj;
  } else {
    formattedDate = null;
  }

  return formattedDate;
}
export function monthDifferenceCalculator(dateValue, months) {
  const startDate = dateValue;
  const startDateObject = new Date(startDate);
  startDateObject.setMonth(startDateObject.getMonth() + 3);
  const endDateString = startDateObject
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ');
  return endDateString;
}
