import React from 'react';
import PreOnBoardingContent from '../components/preOnBoardingContent';
import getDeviceType from '../helpers/getDeviceResolution';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getMasterData,
  getSecondaryObjectiveList
} from '../actions/common.actions';
import { getLegalStructure } from '../actions/legalStructureDropDown.action';
import {
  onSurveyStepCompletion,
  getSavedPreOnBoardingData,
  onSurveyStepOne,
  onSurveyStepTwo,
  onSurveyStepThree
} from '../actions/preOnboarding.action';
import PageContent from '../components/pageContent';
// import ProfileContent from '../components/profileContent';

function PreOnBoardingScreen(props) {
  const isMobile = getDeviceType();
  return (
    <React.Fragment>
      <style jsx>
        {`
          .sticky-sidebar {
            display: none;
          }
          .router-wrapper.row {
            display: block !important;
          }
          footer {
            display: none;
          }
          ::-webkit-scrollbar-thumb {
            background: #888 !important;
           }
          ::-webkit-scrollbar-track {
            background: #f1f1f1 !important;
          }
          .go2344853693, .go2534082608,.go685806154{
            display:none;
          }
          .go2072408551 {
            border-radius: 100px;
            font-size:16px;

          }

          @media only screen and (max-width: 991px) {
            .go2072408551{
                font-size:42px !important;
                margin-top:30px;
                max-width:unset!important;
            }
      
         
          .select2-container--open .select2-dropdown--below {
            font-size: 36px !important;
          }
          .onboarding-form .select2-selection__choice {
            font-size: 36px;
          }
          .select2-results {
            font-size: 36px !important;
          }
          select#legal_structure.form-control {
            padding: 10px;
            padding-right: 30px;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%0d0c0d%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
            background-repeat: no-repeat, repeat;
            background-position: right .7em top 50%, 0 0;
            background-size: 1.09em auto, 100%;
         }
          .switch {
            width: 110px !important;
            height: 64px !important;
          }
          .onSlider:before{
            height: 51px;
            width: 49px;
            left: 15px;
            bottom: 7px;
          }
        `}
      </style>
      <div className={'min-vh-100 onboarding'}>
        <div className="text-center">
          <img
            src="https://cdn.mettasocial.com/logos/MS_logo_640x180_white.svg"
            className={isMobile ? 'mt-8' : ''}
            width={isMobile ? '50%' : '20%'}
            alt="ms-logo"></img>
        </div>
        <div className={'row pt-2 justify-content-center text-dark'}>
          <PageContent mainScreen={<PreOnBoardingContent {...props} />} />
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  state: state
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      onSurveyStepCompletion,
      getMasterData,
      getSavedPreOnBoardingData,
      getLegalStructure,
      getSecondaryObjectiveList,
      onSurveyStepOne,
      onSurveyStepTwo,
      onSurveyStepThree
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreOnBoardingScreen);
