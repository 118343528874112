import React from 'react';
import loaderImg from '../assets/images/hexa.svg';
const Loader = props => {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <div className="loader-img">
          <div className="hexa-loader">
            <img src={loaderImg} className="hexa-img" alt="logo" />
          </div>
        </div>

        {/* main logo */}

        {/* <img
          className="loader-image loader-image-1"
          src="http://cdn.mettasocial.com/logos/128_without_name_color.svg"
          alt=""
        />
        <img
          className="loader-image loader-image-2"
          src="http://cdn.mettasocial.com/logos/128_without_name_color.svg"
          alt=""
        />
        <img
          className="loader-image loader-image-3"
          src="http://cdn.mettasocial.com/logos/128_without_name_color.svg"
          alt=""
        /> */}
        <h3 className="loader-text">{props.loaderText || 'Loading'}</h3>
      </div>
    </div>
  );
};

export default Loader;
