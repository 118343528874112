import React, { useState, useEffect } from 'react';
import './Styles.css';
import { InputDaypicker, InputText, InputSelect } from '../FormElements';
import MultipleFileUpload from '../multipleFileUpload';
import imageResolutionValidator from '../../helpers/imageResValidator';
import Modal from './Modals/Modal';
import { convertInCommaFormat } from '../../helpers/commaSeparatedInput';
import EmptyState from '../component/emptyState';
import { getDayDifference, dateFormatDDMMYYYY } from '../component/dateFormat';
import flagBlue from '../../assets/images/flag-blue.svg';
import flagGrey from '../../assets/images/flag-grey.svg';
import DeleteIcon from '../../assets/icons/delete_icon_benef.svg';

const $ = window.$;
var totalBudgetAmount = 0;
var totalBudgetPercentage = 0;

function Table(props) {
  const [showImg, setShowImg] = useState(false);
  const [fileCount, setFileCount] = useState(false);

  // const [expenseDescriptionList, setEexpenseDescriptionList] = useState([]);
  const column = props.column;
  const rowallProject = props.row;
  const rowFund = props.utilisationRow;
  const rowActivity = props.rowActivities;
  const editRowActivity = props.editRowActivity;
  const rowGoals = props.goalRow;
  const projectStatus = props.projectStatus;
  const mapRecords = props.records;
  totalBudgetAmount = 0;
  totalBudgetPercentage = 0;
  useEffect(() => {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);
  function handleCloseModal(e) {
    setShowImg(false);
  }
  function setExpenseDescription() {
    // setEexpenseDescriptionList(props.expenseCategoryList);
  }
  function getFileExtensionFromUrl(url) {
    let parts = '';
    if (!Array.isArray(url)) {
      parts = url?.split('.');
    }
    return parts === '' ? '' : '.' + parts.pop();
  }

  return props.projectStatus === 'ProposedBudget' ? (
    <div className={props.wrapperClass ? props.wrapperClass : ' '}>
      {mapRecords !== undefined &&
        mapRecords?.length > 0 &&
        mapRecords.map((entry, idx) => {
          let total = 0;
          var result = null;
          var data = props.expenseCategoryList;
          result = data.find(item => item.id === entry.category_id);
          return (
            <>
              <div className="dm-bold fs-16 mt-5 mb-4 text-1B1C1D">
                <b>{result?.name}</b>
              </div>
              <div className="proposalTables mt-1 mb-5">
                <table
                  id="dtBasicExample"
                  className="tabled table table-borders table-hover fs-14 ">
                  <thead className="table-head">
                    <tr className="text-left h-lh-45">
                      {column.map(entry => {
                        return (
                          <th
                            className="proposalH dm-medium fs-12 text-1B1C1D border-bottom"
                            key={entry.id}
                            style={{
                              width: `${entry.width}px`,
                              fontWeight: 'bolder'
                            }}>
                            {entry.headerName}
                            {entry.required ? (
                              <span className="color-FF2300">*</span>
                            ) : (
                              ''
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {entry?.expense_details?.length > 0 &&
                      entry?.expense_details?.map((itm, index) => {
                        if (
                          itm?.actual_amount === null ||
                          itm?.actual_amount === undefined ||
                          itm?.actual_amount === '' ||
                          itm?.actual_amount === 0
                        ) {
                          total =
                            total + itm.quantity * itm.amount * itm.metrics;
                          totalBudgetAmount =
                            totalBudgetAmount +
                            itm.quantity * itm.amount * itm.metrics;
                          totalBudgetPercentage =
                            (totalBudgetAmount / props.fundAmount) * 100;
                          return (
                            <>
                              {props.projectStatus === 'ProposedBudget' && (
                                <>
                                  <tr
                                    key={index}
                                    className="text-left h-70 border-bottom">
                                    <td className="align-top">{index + 1}</td>
                                    <td className="align-top">
                                      {itm.expense_description}
                                    </td>
                                    <td className="align-top">
                                      {itm.expense_sub_category}
                                    </td>
                                    <td className="align-top">
                                      {' ₹ '}
                                      {new Intl.NumberFormat('en-IN').format(
                                        itm.amount
                                      )}
                                    </td>
                                    <td className="align-top">
                                      {new Intl.NumberFormat('en-IN').format(
                                        itm.quantity
                                      )}
                                    </td>
                                    <td className="align-top">
                                      {new Intl.NumberFormat('en-IN').format(
                                        itm.metrics
                                      )}
                                    </td>
                                    <td className="align-top fw-800">
                                      {' ₹ '}{' '}
                                      {new Intl.NumberFormat('en-IN').format(
                                        itm.amount * itm.quantity * itm.metrics
                                      )}
                                    </td>
                                    <td className="align-top">
                                      {(
                                        ((itm.amount *
                                          itm.quantity *
                                          itm.metrics) /
                                          props.fundAmount) *
                                        100
                                      ).toFixed(2)}
                                      {'%'}
                                    </td>
                                    <td className="align-top">
                                      {itm.justification}
                                    </td>
                                  </tr>
                                </>
                              )}
                            </>
                          );
                        }
                      })}

                    <tr
                      className="text-let  h-40"
                      style={{ backgroundColor: '#F8DD8D' }}>
                      {/* <td className="activityData align-top"></td> */}
                      <td className="activityData align-top"></td>
                      <td className="activityData align-top fs-14 text-344256">
                        {'Section Total'}
                      </td>
                      <td className="activityData align-top"></td>
                      <td className="activityData align-top"></td>
                      <td className="activityData align-top"></td>
                      <td className="activityData align-top"></td>
                      <td className="activityData align-top  fs-14 text-344256 sumtotal_budget fw-800">
                        {' ₹ '}
                        {new Intl.NumberFormat('en-IN').format(total)}
                      </td>
                      <td className="activityData align-top">
                        {((total / props.fundAmount) * 100).toFixed(2)}
                        {'%'}
                      </td>
                      <td className="activityData align-top"></td>
                    </tr>

                    {(mapRecords === undefined || mapRecords?.length === 0) && (
                      <EmptyState tabName={props.projectStatus} />
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
        })}
      {mapRecords !== undefined && mapRecords.length > 0 && (
        <table>
          <tbody>
            <tr className="text-let mt-5 h-40">
              <td className="align-top" style={{ width: '1.5%' }}></td>
              <td
                className="align-top fs-16 dm-regular text-344256"
                style={{ width: '22%' }}>
                Total Fund:
              </td>
              <td
                className="align-top fs-18 dm-bold text-1B1C1D fw-800"
                style={{ width: '6%' }}>
                {' ₹ '}
                {new Intl.NumberFormat('en-IN').format(totalBudgetAmount)}
              </td>
              <td
                className="align-top fs-18 dm-bold text-1B1C1D fw-800"
                style={{ width: '10%' }}>
                {totalBudgetPercentage.toFixed(2)}
                {'%'}
              </td>
              {/* <td className="align-top" style={{ width: '100%' }}></td> */}
            </tr>
          </tbody>
        </table>
      )}

      {(mapRecords === undefined || mapRecords.length === 0) && (
        <EmptyState tabName={props.projectStatus} />
      )}
    </div>
  ) : (
    <div
      className={` proposalTable ${
        props.wrapperClass ? props.wrapperClass : ' '
      } `}>
      <table
        id="dtBasicExample"
        className=" tabled table table-borders table-hover fs-14 bg-white">
        {projectStatus !== 'ConceptNoteDetails' &&
          projectStatus !== 'ProposedBudget' &&
          projectStatus !== 'ActivitiesPlan' &&
          projectStatus !== 'ProjectIndicators' && (
            <thead className="table-head bg-D9F0FF">
              <tr className="text-left h-45">
                {column.map(entry => {
                  return (
                    <th
                      key={entry.id}
                      style={{
                        width: `${entry.width}px`,
                        fontWeight: 'bolder'
                      }}>
                      {entry.headerName}
                      {entry.required ? (
                        <span className="color-FF2300">*</span>
                      ) : (
                        ''
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
        {projectStatus !== 'ConceptNoteDetails' &&
          (projectStatus === 'ActivitiesPlan' ||
            projectStatus === 'ProjectIndicators') &&
          mapRecords?.length > 0 && (
            <thead className="table-head">
              <tr className="text-left h-l h-45">
                {column.map(entry => {
                  return (
                    <th
                      className="proposalH dm-medium fs-12 text-1B1C1D border-bottom"
                      key={entry.id}
                      style={{
                        width: `${entry.width}px`,
                        fontWeight: 'bolder'
                      }}>
                      {entry.headerName}
                      {entry.required ? (
                        <span className="color-FF2300">*</span>
                      ) : (
                        ''
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}

        <tbody className="z-index-1 h-50">
          {mapRecords?.length > 0 &&
            mapRecords?.map((entry, index) => {
              return (
                <>
                  {props.projectStatus === 'ActivitiesPlan' && (
                    <tr key={index} className="text-left h-50">
                      <td className="activityData align-top">
                        {entry.activity}
                      </td>
                      <td className="activityData align-top">
                        {dateFormatDDMMYYYY(
                          entry.planned_start_date.slice(0, 10)
                        )}
                      </td>
                      <td className="activityData align-top">
                        {dateFormatDDMMYYYY(
                          entry.planned_end_date.slice(0, 10)
                        )}
                      </td>
                      <td>
                        {/* <td
                        className={`align-top w-25 position-relative ${
                          entry.milestone ? 'blue-flag' : 'grey-flag'
                        }`}>                     
                        </td>
                       <td> */}
                        <td
                          className={`activityData align-top w-25 position-relative ${
                            entry.milestone ? 'blue-flag' : 'grey-flag'
                          }`}></td>
                      </td>
                    </tr>
                  )}
                  {props.projectStatus === 'ProjectIndicators' && (
                    <>
                      <tr>
                        <td className="align-top">
                          {entry.indicator_description === ''
                            ? '--'
                            : entry.indicator_description}
                        </td>
                        <td className="align-top">
                          {entry.indicator_description === ''
                            ? '--'
                            : entry.uom}
                        </td>
                        <td className="align-top">
                          {entry.indicator_description === ''
                            ? '--'
                            : entry.frequency_name}
                        </td>
                        <td className="align-top">
                          {' '}
                          {new Intl.NumberFormat('en-IN').format(
                            entry.baseline_value
                          )}
                        </td>
                        <td className="align-top">
                          {new Intl.NumberFormat('en-IN').format(
                            entry.target_value
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}

          {(mapRecords === undefined || mapRecords.length === 0) &&
            projectStatus !== 'ConceptNoteDetails' &&
            (projectStatus === 'ActivitiesPlan' ||
              projectStatus === 'ProjectIndicators') && (
              <EmptyState tabName={props.projectStatus} />
            )}

          {/* All Project Table Data */}
          {rowallProject &&
            rowallProject.map((entry, index) => {
              let url = `${process.env.REACT_APP_NEW}dashboard/single-mne-project/${entry.id}/dashboard`;
              return (
                <>
                  {projectStatus === entry.status ||
                  projectStatus === 'Total' ? (
                    <tr key={index} className="text-left ">
                      <td>{entry.id}</td>
                      <td>{entry.name}</td>
                      <td className="">
                        {entry.actual_start_date === null ||
                        entry.actual_start_date === 'None'
                          ? '--'
                          : dateFormatDDMMYYYY(
                              entry.actual_start_date?.slice(0, 10)
                            )}
                      </td>
                      <td className="">
                        {entry.actual_end_date === null ||
                        entry.actual_end_date === 'None'
                          ? '--'
                          : dateFormatDDMMYYYY(
                              entry.actual_end_date?.slice(0, 10)
                            )}
                      </td>
                      {entry.status === 'ON_GOING_PROJECT' && (
                        <td
                          className="font-weight-bold text-capitalize"
                          style={{ color: '#DFDF03' }}>
                          {'In Progress'.toLowerCase()}
                        </td>
                      )}
                      {entry.status === 'Upcoming' && (
                        <td
                          className="font-weight-bold text-capitalize"
                          style={{ color: '#E47E09' }}>
                          {entry.status.toLowerCase()}
                        </td>
                      )}
                      {entry.status === 'COMPLETED' && (
                        <td
                          className="font-weight-bold text-capitalize"
                          style={{ color: '#81BC30' }}>
                          {entry.status.toLowerCase()}
                        </td>
                      )}
                      {entry.status === 'DRAFT' && (
                        <td
                          className="font-weight-bold text-capitalize"
                          style={{ color: '#81BC30' }}>
                          {entry.status.toLowerCase()}
                        </td>
                      )}
                      <td className="">
                        {'₹ '}
                        {convertInCommaFormat(entry.allocated_funds)}
                      </td>
                      <td className="">
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          <div className="view"></div>
                        </a>
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </>
              );
            })}

          {/* All Fund Utilization Table Data */}
          {rowFund &&
            props.expenseData.map((expense, index) => {
              return (
                <tr key={index} style={{ height: '50px' }} className="table-td">
                  <td>
                    <InputSelect
                      required={true}
                      labelText=""
                      id="expense_category_id"
                      name="expense_category_id"
                      options={props.expenseCategoryList}
                      fieldValue={expense.expense_category_id.value}
                      fieldError={expense.expense_category_id.error}
                      onHandleChange={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      onHandleBlur={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      childCallBack={setExpenseDescription}
                      parentElement={true}
                      className="fs-15 "
                      permissions={props.permissions}
                    />
                  </td>
                  <td>
                    <InputSelect
                      required={true}
                      labelText=""
                      id="expense_sub_category_id"
                      name="expense_sub_category_id"
                      options={props.expenseSubCategoryLists}
                      fieldValue={expense.expense_sub_category_id?.value}
                      fieldError={expense.expense_sub_category_id?.error}
                      onHandleChange={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      onHandleBlur={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      childCallBack={setExpenseDescription}
                      parentElement={true}
                      className="fs-15 "
                      permissions={props.permissions}
                    />
                  </td>

                  <td>
                    <InputSelect
                      required={true}
                      labelText=""
                      id="expense_description"
                      name="expense_description"
                      options={props.expenseDescriptionList}
                      fieldValue={expense.expense_description?.value}
                      fieldError={expense.expense_description?.error}
                      onHandleChange={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      onHandleBlur={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      parentElement={true}
                      className="fs-15 "
                      permissions={props.permissions}
                    />

                    {/* <InputText
                      required={true}
                      labelText=""
                      maxLength={100}
                      type={'text'}
                      PlaceHolder={'Maximum Length is 100 Characters'}
                      id="expense_description"
                      name="expense_description"
                      fieldValue={expense.expense_description.value}
                      fieldError={expense.expense_description.error}
                      onHandleChange={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      onHandleBlur={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      className="fs-15"
                    /> */}
                  </td>
                  <td>
                    {/* {console.log(">>" , expense.expense_type.value , typeof(expense.expense_type.value) , typeof(parseInt(expense.expense_type.value)))} */}
                    <InputSelect
                      required={true}
                      labelText=""
                      id="expense_type_id"
                      name="expense_type_id"
                      options={props.expenseType}
                      // fieldValue={expense.expense_type}
                      // fieldError={expense.expense_type.error}
                      fieldValue={expense.expense_type_id?.value}
                      fieldError={expense.expense_type_id?.error}
                      onHandleChange={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      onHandleBlur={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      parentElement={true}
                      className="fs-15 "
                      permissions={props.permissions}
                    />
                  </td>
                  <td>
                    <InputText
                      required={true}
                      labelText=""
                      maxLength={13}
                      type={'text'}
                      PlaceHolder={''}
                      id="actual_amount"
                      name="actual_amount"
                      // fieldValue={expense.actual_amount.value}
                      fieldValue={convertInCommaFormat(
                        expense.actual_amount.value
                      )}
                      fieldError={expense.actual_amount.error}
                      onHandleChange={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      onHandleBlur={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      className="fs-15"
                      permissions={props.permissions}
                    />
                  </td>
                  <td>
                    <InputDaypicker
                      required={true}
                      labelText={''}
                      id="expense_date"
                      name="expense_date"
                      fieldValue={expense.expense_date.value}
                      fieldError={expense.expense_date.error}
                      onHandleChange={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      onHandleBlur={(e, c, t) =>
                        props.setFormErrorsAndValue(e, t, index)
                      }
                      daysOfWeek={[]}
                      permissions={props.permissions}
                    />
                  </td>
                  <td>
                    <div
                      className="d-flex align-items-center justify-content-between position-relative"
                      style={{ bottom: '10px' }}>
                      <label
                        htmlFor={`reciept_${index}`}
                        className="mb-0 mr-3 cursor-pointer">
                        <i
                          className="fas fa-upload ml-0 color-1FA4FA"
                          style={{ fontSize: '15px' }}
                        />
                      </label>
                      {/* )} */}
                      <MultipleFileUpload
                        required={false}
                        labelText={''}
                        id={`reciept_${index}`}
                        name={`reciept_${index}`}
                        placeholder="Choose an image file"
                        refId={props.campaignId}
                        fileSizeLimit={10}
                        validateFile={imageResolutionValidator}
                        // accept="image/*"
                        accept=".jpg, .jpeg, .png, .gif, .bmp, .docx,.doc,.xls,.xlsx,.pdf, .csv"
                        value={expense.reciept.value}
                        fieldError={expense.reciept.error}
                        docType="report_doc"
                        onSuccess={(f, e) =>
                          props.onUploadSuccess(f, 'reciept', index)
                        }
                        extraInfoAboutField={
                          'This field is use to upload an image related to receipt'
                        }
                        className="fs-15 d-none"
                        setIsUploading={v => props.setIsUploading(index, v)}
                        permissions={props.permissions}
                        multiple={true}
                        setFileCount={setFileCount}
                        setUploadedFiles={props.setUploadedFiles}
                        uploadedFiles={props.uploadedFiles}
                      />
                      {expense.reciept.value?.length > 0 && fileCount ? (
                        <div className="dropdown show">
                          <div
                            className="dropdown-toggle text-decoration-none-blue dropdown-toggle cursor-pointer"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            {expense.reciept.value?.length} Attachments
                          </div>

                          <div className="dropdown-menu">
                            {expense.reciept.value?.map((attachment, idx) => (
                              <div
                                key={idx}
                                className="dropdown-item popup-item">
                                {/* <img src={attachment} alt={`Attachment ${idx}`} className="attachment-image" /> */}
                                <div className="attachment-image text-white text-underline-none cursor-pointer">
                                  Receipt_{idx + 1}
                                  {getFileExtensionFromUrl(attachment)}{' '}
                                </div>
                                <span className="attachment-actions float-right">
                                  <i
                                    className="fas fa-eye mr-2 text-white"
                                    onClick={() => {
                                      props.showHideReceipt(
                                        attachment,
                                        index,
                                        idx
                                      );
                                    }}></i>
                                  <i
                                    className="fas fa-trash text-white"
                                    onClick={() =>
                                      props.deleteFile(
                                        props.expenseData,
                                        attachment,
                                        index,
                                        idx
                                      )
                                    }></i>
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      {expense.isUploading && !fileCount && (
                        <span>
                          <i
                            className="fas fa-spinner fa-pulse fa-2x"
                            aria-hidden="true"></i>
                        </span>
                      )}

                      <i
                        className={`fas fa-times fs-22 color-1FA4FA mx-2 cursor-pointer ${
                          props.disableUpdate ? 'disabled' : ''
                        }`}
                        onClick={() => props.deleteExpense()}></i>
                    </div>
                  </td>
                </tr>
              );
            })}
          {rowFund &&
            rowFund.map((entry, key) => {
              var expenseCategoryName = props.expenseCategoryList?.find(
                item => item.id === entry.expense_category_id
              );
              return entry.actual_amount !== null &&
                entry.actual_amount !== 0 ? (
                <tr
                  key={key}
                  className="text-left table-td "
                  style={{ height: '60px' }}>
                  <td>{expenseCategoryName?.name}</td>
                  <td>{entry.expense_sub_category}</td>
                  <td>{entry.expense_description}</td>
                  <td>{entry.expense_type}</td>
                  <td>
                    {'₹ '}
                    {new Intl.NumberFormat('en-IN').format(
                      entry?.actual_amount
                    )}
                  </td>
                  <td>
                    {dateFormatDDMMYYYY(entry.expense_date?.slice(0, 10))}
                  </td>

                  <td>
                    <div className="d-flex justify-content-between">
                      {entry.reciept?.length > 0 && entry.reciept[0] !== '' ? (
                        <div className="dropdown show">
                          <div
                            className="dropdown-toggle text-decoration-none-blue cursor-pointer"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            {entry.reciept?.length} Attachments
                          </div>

                          <div className="dropdown-menu">
                            {entry.reciept?.map((attachment, idx) => (
                              <div
                                key={idx}
                                className="dropdown-item popup-item">
                                <div
                                  // href={attachment}
                                  className="attachment-image text-white text-underline-none cursor-pointer">
                                  Receipt_{idx + 1}
                                  {getFileExtensionFromUrl(attachment)}{' '}
                                </div>
                                <span className="attachment-actions float-right">
                                  <i
                                    className="fas fa-eye mr-2 text-white ml-2"
                                    onClick={() => {
                                      props.showHideReceipt(
                                        attachment,
                                        key,
                                        idx
                                      );
                                    }}></i>
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        '--'
                      )}
                      <span>
                        <img
                          src={DeleteIcon}
                          alt="Delete"
                          width={15}
                          height={18}
                          className="cursor-pointer"
                          onClick={() =>
                            props.showDeleteExpenseByIdModal(entry)
                          }></img>
                      </span>
                    </div>
                  </td>
                </tr>
              ) : (
                ''
              );
            })}

          {/* All Activity Table Data */}
          {rowActivity &&
            rowActivity.map((entry, key) => {
              return (
                <>
                  {projectStatus === entry.status ||
                  projectStatus === 'allActivities' ? (
                    <tr
                      key={entry.id}
                      className="text-left table-td"
                      style={{ height: '50px' }}>
                      <td>{key + 1}</td>
                      {entry.milestone === true ? (
                        <td className="flag"></td>
                      ) : (
                        <td className=""></td>
                      )}
                      {/* <td>{entry.quarter}</td> */}
                      <td>{entry.activity}</td>
                      <td>
                        {entry.actual_start_date !== null
                          ? dateFormatDDMMYYYY(
                              entry.actual_start_date?.slice(0, 10)
                            )
                          : ''}
                      </td>
                      <td>
                        {entry.actual_end_date !== null
                          ? dateFormatDDMMYYYY(
                              entry.actual_end_date?.slice(0, 10)
                            )
                          : ''}
                      </td>
                      <td>{entry.status}</td>

                      <td
                        className={`${
                          entry.delayedByDays < 0
                            ? 'text-81BC30'
                            : 'text-E47E09'
                        }`}>
                        {entry.delayedByDays} days
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {projectStatus === 'projectMilestone' &&
                  entry.milestone === true ? (
                    <tr key={entry.id} className="text-left table-td">
                      <td>{key + 1}</td>
                      {entry.milestone === true ? (
                        <td className="flag"></td>
                      ) : (
                        <td className=""></td>
                      )}
                      <td>{entry.quarter}</td>
                      <td>{entry.activity}</td>
                      <td>
                        {entry.actual_start_date !== null
                          ? dateFormatDDMMYYYY(
                              entry.actual_start_date?.slice(0, 10)
                            )
                          : ''}
                      </td>
                      <td>
                        {entry.actual_end_date !== null
                          ? dateFormatDDMMYYYY(
                              entry.actual_end_date?.slice(0, 10)
                            )
                          : ''}
                      </td>
                      <td>{entry.status}</td>

                      <td style={{ color: '#E47E09' }}>
                        {entry.delayedByDays}
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </>
              );
            })}

          {/* All Edit Activity Table Data */}
          {editRowActivity &&
            editRowActivity.map((entry, key) => {
              return (
                <tr
                  key={entry.id}
                  className="text-left table-td"
                  style={{ height: '60px' }}>
                  <td>
                    {entry.milestone && (
                      <img
                        src={flagBlue}
                        alt="milestone-achieved"
                        width="20"></img>
                    )}
                    {!entry.milestone && (
                      <img
                        src={flagGrey}
                        alt="milestone-achieved"
                        width="20"></img>
                    )}
                  </td>

                  <td>{entry.activity}</td>
                  <td>
                    <InputDaypicker
                      required={true}
                      labelText=""
                      wrapperClassName="mb-1"
                      id="actual_start_date"
                      name="actual_start_date"
                      onHandleChange={e => props.handleChange(e, entry.id)}
                      disableInput={entry.status !== 'Upcoming'}
                      fieldValue={
                        entry.actual_start_date !== null
                          ? entry.actual_start_date?.split(' ')[0]
                          : ''
                      }
                      daysOfWeek={[]}
                      permissions={props.permissions}
                    />
                  </td>
                  <td>
                    <InputDaypicker
                      required={true}
                      labelText=""
                      wrapperClassName="mb-1"
                      id="actual_end_date"
                      name="actual_end_date"
                      onHandleChange={e => props.handleChange(e, entry.id)}
                      fieldValue={
                        entry.actual_end_date !== null
                          ? entry.actual_end_date?.split(' ')[0]
                          : ''
                      }
                      disableInput={entry.status !== 'In Progress'}
                      daysOfWeek={[]}
                      permissions={props.permissions}
                    />
                  </td>

                  <td>
                    {entry.status === 'Upcoming' ? (
                      <td style={{ color: '#A3B1C1' }}>{'Yet to Start'}</td>
                    ) : entry.status === 'Completed' ? (
                      <td style={{ color: '#81BC30' }}>{'Complete'}</td>
                    ) : entry.status !== 'Completed' &&
                      getDayDifference(
                        entry.planned_start_date,
                        entry.actual_start_date
                      ) < 0 ? (
                      <td style={{ color: '#FF4C2F' }}>{'Delayed'}</td>
                    ) : entry.status !== 'Completed' &&
                      getDayDifference(
                        entry.planned_start_date,
                        entry.actual_start_date
                      ) > 0 ? (
                      <td style={{ color: '#FBBD08' }}>{'Before Track'}</td>
                    ) : entry.status !== 'Completed' &&
                      getDayDifference(
                        entry.planned_start_date,
                        entry.actual_start_date
                      ) === 0 ? (
                      <td style={{ color: '#81BC30' }}>{'On Track'}</td>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-left table-td">
                    {entry.status === 'Upcoming' && props.isUserAuthorized && (
                      <button
                        type="button"
                        className="form-control btn btn-outline-primary w-75 fs-14"
                        onClick={() =>
                          props.handleFunction1('start', entry.id)
                        }>
                        Start
                      </button>
                    )}
                    {entry.status !== 'Completed' &&
                      entry.status === 'In Progress' &&
                      props.isUserAuthorized && (
                        <button
                          type="button"
                          className="form-control btn btn-outline-primary"
                          onClick={() =>
                            props.handleFunction1('complete', entry.id)
                          }>
                          Mark as Complete {}
                        </button>
                      )}
                    {entry.status === 'Completed' && (
                      <span className="bg-EBF5DC color-81BC30 py-1 px-2">
                        Completed
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}

          {/* All Goals and Indicator Table Data */}
          {rowGoals &&
            rowGoals.map((entry, key) => {
              return (
                <>
                  <tr
                    key={entry.id}
                    className={`text-left table-td ${
                      key === 0 ? 'bg-FBFFF2' : ''
                    }`}>
                    <td>
                      {entry.indicator_description === ''
                        ? '--'
                        : entry.indicator_description}
                    </td>
                    <td>
                      {entry.indicator_description === '' ? '--' : entry.uom}
                    </td>
                    <td>
                      {entry.indicator_description === ''
                        ? '--'
                        : entry.frequency_name || '--'}
                    </td>
                    <td>
                      {entry.baseline_value === null ||
                      entry.baseline_value === 0
                        ? 0
                        : convertInCommaFormat(entry.baseline_value)}
                    </td>
                    <td>
                      {entry.target_value === null || entry.target_value === 0
                        ? 0
                        : convertInCommaFormat(entry.target_value)}
                    </td>
                    <td>
                      {entry.last_target === null || entry.last_target === 0
                        ? 0
                        : convertInCommaFormat(entry.last_target)}
                    </td>
                    <span className="d-flex">
                      {key === 0 && entry.indicator_description === '' ? (
                        <div className="form-group mt-4">
                          <input
                            type="text"
                            PlaceHolder={'Maximum Length is 100 Characters'}
                            disabled="true"
                            className="form-control"
                          />
                        </div>
                      ) : (
                        <InputText
                          required={false}
                          labelText=" "
                          maxLength={100}
                          type={'text'}
                          PlaceHolder={'Maximum Length is 100 Characters'}
                          id="achived_target"
                          name="achived_target"
                          onHandleChange={e => props.handleChange(e, entry.id)}
                          fieldError={entry.error.achievedTarget}
                          autoComplete="on"
                          fieldValue={entry.achived_target}
                          permissions={props.permissions}
                        />
                      )}
                      {key === 0 && (
                        <i
                          className="fas fa-info-circle text-info ml-2 mt-2rem"
                          data-toggle="tooltip"
                          data-placement="top"
                          data-original-title={
                            'Primary indicator for the project'
                          }></i>
                      )}
                    </span>
                    <td className="previews text-center">
                      {props.defaultGoals[key].achived_target && (
                        <span
                          onClick={e => props.showHistoryModal(e, entry.id)}>
                          {' '}
                          <i className="fas fa-solid fa-eye mr-2"></i>History
                        </span>
                      )}
                    </td>
                  </tr>
                  {showImg && (
                    <Modal show={showImg} handleCloseModal={handleCloseModal}>
                      <div
                        className="position-absolute d-flex justify-content-center pt-2 px-3"
                        style={{ top: '50px' }}>
                        <img
                          src={entry.attachment}
                          alt="IMG"
                          width={'80%'}
                          style={{ objectFit: 'cover' }}
                        />
                      </div>
                    </Modal>
                  )}
                </>
              );
            })}

          {/* History Table Data */}
          {props.historyRow && props.fetchingGoalsHistory && (
            <tr>
              <td colSpan={3} className="text-center">
                <span>
                  <i
                    className="fas fa-spinner fa-pulse fa-2x"
                    aria-hidden="true"></i>
                </span>
              </td>
            </tr>
          )}
          {props.historyRow &&
            props.historyRow.map((entry, key) => {
              const date = new Date(entry.indicator_date + '+00:00')
                .toLocaleDateString('en-IN', {
                  month: 'short',
                  year: 'numeric'
                })
                .toUpperCase();
              return (
                <tr key={entry.updated_date} className="text-left table-td">
                  <td>{date}</td>
                  <td>{entry.updated_date.slice(0, 10)}</td>
                  <td>{entry.achieved_target}</td>

                  {/* <td>
                    {entry.attachment ? (
                      <a
                        href={entry.attachment}
                        rel="noreferrer noopener"
                        target="_blank">
                        <i className="fas fa-solid fa-eye mr-2"></i>
                        {' Preview'}
                      </a>
                    ) : (
                      <div>
                        <i className="fas fa-solid fa-eye"></i>
                        {' Preview'}
                      </div>
                    )}
                  </td> */}
                </tr>
              );
            })}
        </tbody>
      </table>

      {rowGoals?.length === 0 && (
        <EmptyState tabName={'ProjectMNEIndicators'} />
      )}
      {editRowActivity?.length === 0 && (
        <EmptyState tabName={'ProjectMNEActivities'} />
      )}
    </div>
  );
}

export default Table;
