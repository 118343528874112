import { EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

const MasterService = {
  get: function(criteria = null) {
    let instance = HttpRequest.getInstance('HIROKU');
    //this is with ?type=
    return instance.get(EndPoint.getApiUrl('NGO', 'MASTER_LIST', 'HIROKU'), {
      params: {
        type: criteria.join(',')
      }
    });
  },

  getGuestMasterList: function(criteria = null) {
    let instance = HttpRequest.getInstance('HIROKU');
    //this is with ?type=
    return instance.get(
      EndPoint.getApiUrl('NGO', 'GUEST_MASTER_LIST', 'HIROKU'),
      {
        params: {
          type: criteria.join(',')
        }
      }
    );
  },

  getById: function(masterId) {
    let instance = HttpRequest.getInstance('HIROKU');

    return instance.get(
      EndPoint.getApiUrl('NGO', 'MASTER_LIST_ID', 'HIROKU').replace(
        '{masterId}',
        masterId
      )
    );
  },
  getAllCurrencies: function() {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.get(
      'https://socionext-staging.mettasocial.com/master/currency'
    );
  },

  getAllCountries: function() {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.get(EndPoint.getApiUrl('NGO', 'LIST_COUNTRIES', 'HIROKU'));
  },

  getAllStatesForArea: function() {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.get(EndPoint.getApiUrl('NGO', 'LISTOFSTATES', 'HIROKU'));
  },

  getAllDistByStateForArea: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'LISTOFDISTRICT', 'HIROKU');
    return instance.post(apiUrl, data);
  },

  getStatesById: function(countryId) {
    let instance = HttpRequest.getInstance('HIROKU');

    return instance.get(
      EndPoint.getApiUrl('NGO', 'LIST_STATES', 'HIROKU').replace(
        '{countryId}',
        countryId
      )
    );
  },

  getDistrictsById: function(stateId) {
    let instance = HttpRequest.getInstance('HIROKU');

    return instance.get(
      EndPoint.getApiUrl('NGO', 'LIST_DISTRICTS', 'HIROKU').replace(
        '{stateId}',
        stateId
      )
    );
  }
};

export default MasterService;
