import React, { useEffect, useState } from 'react';

const LocationMain = ({ locations }) => {
  const [locationString, setLocationString] = useState('');
  useEffect(() => {
    let str = '';
    function makeString() {
      for (let i = 0; i < locations.length; i++) {
        if (i < locations.length - 1) {
          str += `${locations[i].city}, ${locations[i].state} | `;
        } else {
          str += `${locations[i].city}, ${locations[i].state} `;
        }
      }

      setLocationString(
        str.length < 46 ? str.slice(0, 50) : str.slice(0, 50) + '... '
      );
    }
    makeString();
  }, []);
  return <span style={{ marginRight: '5px' }}>{locationString}</span>;
};

export default LocationMain;
