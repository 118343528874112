import React from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { getNGOTransactions } from '../actions/ngo.actions';
import Loader from './loader';
import DayPicker, { DateUtils } from 'react-day-picker';
import { CSVLink } from 'react-csv';
import Pagination from './pagination';
import dayjs from 'dayjs';
import EmptySideScreenState from './EmptySideScreenState';
import ProjectTitleTrans from './component/projectTitleTransaction';

// const NoDataView = (
//   <p className="h3 text-center p-4">There is nothing to display here.</p>
// );

class TransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionList: [],
      masterTransactionList: [],
      renderNGOReportConatiner: true,
      renderDateRange: false,
      searchCampaign: '',
      currentPage: 1,
      postsPerPage: 15,
      setMinValue: 0,
      setMaxValue: 9999999,
      ...this.initialState,
      filters: this.getInitialFilterState(),
      amountSort: 'ASC',
      dateSort: 'ASC'
    };
    this.renderNGOReportConatiner = this.renderNGOReportConatiner.bind(this);
    this.searchCampaign = this.searchCampaign.bind(this);
    // this.data_url = `${SERVER_NAME.HIROKU}/admin/get_ngo_report`;
    this.noDataView = (
      <tr>
        <td colSpan={5} className="text-center">
          NO NGOs FOUND
        </td>
      </tr>
    );
    this.searchInputRef = React.createRef();
  }
  getInitialFilterState = () => {
    return {
      status: 'all',
      searchTerm: '',
      dateRange: this.getInitialDateState()
    };
  };
  getInitialDateState() {
    return {
      fromDate: null,
      toDate: null,
      enteredTo: null
    };
  }
  isSelectingFirstDay(fromDate, toDate, day) {
    const isBeforeFirstDay = fromDate && DateUtils.isDayBefore(day, fromDate);
    const isRangeSelected = fromDate && toDate;
    return !fromDate || isBeforeFirstDay || isRangeSelected;
  }

  filterData = () => {
    let filterredTransactions = [];
    /* Apply the data range filter */
    const { fromDate, toDate } = this.state.filters.dateRange;
    if (fromDate && toDate) {
      filterredTransactions = this.state.masterTransactionList.filter(x =>
        dayjs(x.transactionDate).isBetween(new Date(fromDate), new Date(toDate))
      );
      this.setState({ transactionList: filterredTransactions });
    }

    return filterredTransactions && filterredTransactions.length
      ? filterredTransactions
      : [];
  };
  clickHandler = {
    handleDateRangeClick: () => {
      this.setState({ renderDateRange: !this.state.renderDateRange });
    },
    handleTabClick: status => {
      this.setState(
        {
          filters: { ...this.state.filters, status: status.code }
        },
        () => {
          this.setState({ allCampaigns: this.filterData() });
        }
      );
    },
    handleSearchClick: () => {
      this.setState({
        allCampaigns: this.filterData()
      });
    },
    handleResetDateFilterClick: () => {
      this.setState({
        filters: {
          ...this.state.filters,
          dateRange: this.getInitialDateState()
        }
      });
    },
    handleDayClick: day => {
      const { fromDate, toDate } = this.state.filters.dateRange;
      if (fromDate && toDate && day >= fromDate && day <= toDate) {
        this.clickHandler.handleResetDateFilterClick();
        return;
      }
      if (this.isSelectingFirstDay(fromDate, toDate, day)) {
        this.setState({
          filters: {
            ...this.state.filters,
            dateRange: {
              fromDate: day,
              toDate: null,
              enteredTo: null
            }
          }
        });
      } else {
        this.clickHandler.handleDateRangeClick();
        this.setState(
          {
            filters: {
              ...this.state.filters,
              dateRange: {
                fromDate: this.state.filters.dateRange.fromDate,
                toDate: day,
                enteredTo: day
              }
            }
          },
          () => {
            this.setState({ transactionList: this.filterData() });
          }
        );
      }
    }
  };
  clearFilter = () => {
    this.setState({ filters: this.getInitialFilterState() });
    this.setState({ setMinValue: 0 });
    this.setState({ setMaxValue: 9999999 });
    this.setState({ searchCampaign: '' });
    this.getTransactions();
  };
  componentDidMount() {
    this.getTransactions();
  }
  getTransactions = () => {
    this.props.getNGOTransactions().then(res => {
      this.setState({
        transactionList: res?.result?.corporate_campaign_transactions_list,
        masterTransactionList: res
      });
    });
  };
  convertDate = str => {
    if (str) {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      return [day, mnth, date.getFullYear()].join('-');
    } else return '';
  };

  handleChange = value => {
    this.setState({ searchCampaign: value });
    let orginal_list = [...this.state.transactionList];
    let filteredList = '';
    if (value !== '') {
      filteredList = orginal_list.filter(item => {
        const lc = item && item.campaign.toString();
        const filter = value;

        return lc && lc.includes(filter);
      });
    } else {
      this.getTransactions();
    }
    this.setState({
      transactionList: filteredList,
      searchCampaign: value
    });
  };
  searchCampaign() {
    const { ngoReportListData, searchNgo } = this.state;
    let orginal_list = [...ngoReportListData];
    let filteredList = '';
    if (searchNgo !== '') {
      filteredList = orginal_list.filter(item => {
        const lc = item.ngoName.toLowerCase();
        const filter = searchNgo.toLowerCase();
        return lc.includes(filter);
      });
    }
    this.setState({
      actionRequiredData: filteredList
    });
  }
  paginationRecords = data => {
    let currentTableData = [];
    if (data && data.length > 0) {
      const lastPageIndex = this.state.currentPage * this.state.postsPerPage;
      const firstPageIndex = lastPageIndex - this.state.postsPerPage;
      currentTableData = data.slice(firstPageIndex, lastPageIndex);
    }
    return currentTableData;
  };
  sortByAmount = () => {
    let amtArr = this.state.transactionList.filter(x => !!x.amount);
    let nonAmtrr = this.state.transactionList.filter(x => !x.amount);
    let sortedList = JSON.parse(JSON.stringify(amtArr));
    if (amtArr.length > 1) {
      sortedList.sort((a, b) => {
        if (parseInt(a.amount) > parseInt(b.amount)) {
          return this.state.amountSort === 'ASC' ? 1 : -1;
        }
        if (parseInt(a.amount) < parseInt(b.amount)) {
          return this.state.amountSort === 'ASC' ? -1 : 1;
        }
        return 0;
      });
    }

    this.setState({
      transactionList: sortedList.concat(nonAmtrr),
      amountSort: this.state.amountSort === 'ASC' ? 'DESC' : 'ASC'
    });
  };

  displayMinMax = event => {
    this.setState({ setMaxValue: event.target.value });
    let filterredTransactions = this.state.transactionList.filter(
      x => x.amount < event.target.value
    );

    this.setState({ transactionList: filterredTransactions });
  };

  sortByDate = () => {
    // let array = this.state.transactionList;
    // array.sort((a, b) => {
    //   return new Date(b.transactionDate) - new Date(a.transactionDate);
    // });
    // this.setState({ transactionList: array });

    let dateArr = this.state.transactionList.filter(x => !!x.transactionDate);
    let nonDateArr = this.state.transactionList.filter(x => !x.transactionDate);
    let sortedList = JSON.parse(JSON.stringify(dateArr));
    if (dateArr.length > 1) {
      sortedList.sort((a, b) => {
        if (dayjs(a.transactionDate).isAfter(b.transactionDate)) {
          return this.state.dateSort === 'ASC' ? 1 : -1;
        }
        if (dayjs(a.transactionDate).isBefore(dayjs(b.transactionDate))) {
          return this.state.dateSort === 'ASC' ? -1 : 1;
        }
        return 0;
      });
    }

    this.setState({
      transactionList: sortedList.concat(nonDateArr),
      dateSort: this.state.dateSort === 'ASC' ? 'DESC' : 'ASC'
    });
  };
  renderNGOReportConatiner() {
    let transaction = this.state.transactionList;
    let transactionData = this.paginationRecords(transaction);

    let postsPerPage = this.state.postsPerPage;
    // const { transactionList } = this.props;
    if (!this.state.transactionList) {
      return <Loader loaderText="Loading..." />;
    }
    if (!this.state.transactionList.length) {
      return <EmptySideScreenState pageName={'transaction'} />;
    }
    const paginate = (e, pageNumber) => {
      e.preventDefault();
      this.setState({ currentPage: pageNumber });
    };

    if (transactionData === undefined || transactionData.length === 0)
      return <EmptySideScreenState pageName={'transaction'} />;
    return (
      <div className="p-4">
        <div>
          <table
            className="table table-borders table-hover fs-14 bg-white "
            id="table-to-xls">
            <thead className={'table-thead text-uppercase'}>
              <tr className={'text-left table-th h-lh-45'}>
                <th scope="col" className="px-2 py-2">
                  <b>PaymentId</b>
                </th>
                <th scope="col" className="px-1 py-2">
                  <b>Email Address</b>
                </th>
                <th scope="col" className="text-nowrap px-1 py-2">
                  <b>Name of Corporate</b>
                </th>

                <th scope="col" className="px-1 py-2">
                  <b>Project</b>
                </th>
                <th
                  scope="col"
                  className="px-1 py-2"
                  onClick={this.sortByAmount}>
                  <b>Amount (&#8377;)</b>
                </th>
                <th
                  scope="col"
                  className="px-1 py-2"
                  onClick={this.sortByAmount}>
                  <b>Sanctioned Amount</b>
                </th>
                <th scope="col" className="px-1 py-2" onClick={this.sortByDate}>
                  <b>Date</b>
                  <img className="pl-2" src="/images/shuffle.png" alt="" />
                </th>
                <th className=" px-1 py-2">
                  <b>Time</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionData &&
                transactionData.length &&
                transactionData.map((data, key) => {
                  return (
                    <tr className={'text-center'} key={`nsl-${key}`}>
                      <td className="table-td px-2 py-3">{data.paymentId}</td>
                      <td className="table-td px-2 py-3">{data.email}</td>
                      <td className="table-td px-2 py-3">{data.corporate}</td>
                      <td className="table-td px-2 py-3">
                        <ProjectTitleTrans title={data?.project_name} />
                      </td>
                      <td className="table-td px-2 py-3">
                        ₹{new Intl.NumberFormat('en-IN').format(data.amount)}
                      </td>
                      <td className="table-td px-2 py-3">
                        {data.sanctionedAmount === 'None'
                          ? 'None'
                          : ` ₹ ${new Intl.NumberFormat('en-IN').format(
                              data.sanctionedAmount
                            )}`}
                      </td>
                      <td className="table-td px-1 py-2 text-nowrap">
                        {this.convertDate(data.transactionDate)}
                      </td>
                      <td className="table-td px-1 py-2">
                        {dayjs(data.transactionDate).format('HH:mm:ss') ===
                        'Invalid Date'
                          ? ' '
                          : dayjs(data.transactionDate)
                              .format('HH:mm:ss')
                              .slice(0, 5)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="col-12 row">
            <div className="col-10">
              {this.state.postsPerPage * this.state.currentPage >
              transaction.length
                ? this.state.postsPerPage * this.state.currentPage -
                  14 +
                  '-' +
                  transaction.length
                : this.state.postsPerPage * this.state.currentPage -
                  14 +
                  '-' +
                  this.state.postsPerPage * this.state.currentPage}{' '}
              out of {transaction.length}
            </div>
            <div className="col-2 float-right">
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={transaction.length}
                paginate={paginate}></Pagination>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDateRange() {
    const { fromDate, enteredTo } = this.state.filters.dateRange;
    const modifiers = { start: fromDate, end: enteredTo };
    const selectedDays = [fromDate, { from: fromDate, to: enteredTo }];

    return (
      <div className="w-100 position-absolute h-100">
        <div
          className="w-100 position-absolute vh-100"
          onClick={() => {
            this.setState({ renderDateRange: false });
          }}></div>
        <div className="col-12 dateTab">
          <DayPicker
            className={`date-range-picker bg-white`}
            numberOfMonths={2}
            selectedDays={selectedDays}
            modifiers={modifiers}
            onDayClick={this.clickHandler.handleDayClick}
          />
        </div>
      </div>
    );
  }

  render() {
    let transaction = this.state.transactionList;
    let transactionData = this.paginationRecords(transaction);
    const { renderNGOReportConatiner } = this.state;
    const { renderDateRange } = this.state;
    const { fromDate, toDate } = this.state.filters.dateRange;
    const froDateRange = new Date(fromDate).toISOString().split('T')[0];
    const toDateRange = new Date(toDate).toISOString().split('T')[0];
    return (
      <React.Fragment>
        {/* <div className="row p-4">
          <div className="col-12 d-flex p-0 justify-content-between align-items-center">
            <div className="transparent input-group col-5 p-0">
              <div className="input-group-prepend">
                <button type="button" className="btn" onClick={this.searchNgo}>
                  <i className="input-group-text fa fa-search form-control-feedback"></i>
                </button>
              </div>

              <input
                className="form-control"
                type="text"
                name="search"
                value={this.state.searchCampaign}
                onChange={e => {
                  e.persist();
                  this.handleChange(e.target.value);
                }}
                placeholder="Search by campaign name"
              />
              <div className="pl-2 ml-2">
                <button
                  className="btn btn-link p-0 mr-2 btn-lg"
                  onClick={() => this.clearFilter()}>
                  Clear All filters
                </button>
              </div>
            </div>
            <div className="col-5 d-flex p-0 justify-content-end align-items-center">
              <button
                className="btn btn-range p-2 btn-lg mr-3"
                onClick={this.clickHandler.handleDateRangeClick}>
                {toDateRange !== '1970-01-01'
                  ? `Date Range (${froDateRange} to ${toDateRange})`
                  : 'Date Range'}
              </button>

              <div>
                {this.state.transactionList?.length > 0 && (
                  <CSVLink
                    className="btn btn-primary btn-lg fs-13 p-2"
                    filename={'transactions.csv'}
                    data={this.state.transactionList}>
                    Download as CSV
                  </CSVLink>
                )}
              </div>
            </div>
          </div>
        </div> */}

        <div
          className={`input-group row mt-3 ${
            transactionData.length > 0 ? '' : 'd-none'
          }`}>
          <div className="col-4"></div>
          <div
            className="col-4 align-items-center position-relative"
            style={{ left: '30px' }}>
            <span className="iform-group col-8 cols-8 float-right">
              <button
                type="button"
                className="btn position-absolute"
                style={{ right: '10px', zIndex: '99', top: '5px' }}
                onClick={this.searchNgo}>
                <i className="fa fa-search form-control-feedback"></i>
              </button>

              <input
                type="text"
                className=" standard-search-style col-12"
                // style={{ bottom: "8px" }}
                placeholder="Search Project by Name..."
                name="search"
                value={this.state.searchCampaign}
                onChange={e => {
                  e.persist();
                  this.handleChange(e.target.value);
                }}
              />
            </span>
          </div>

          <div className="col-4 d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary standard-btn mx-1 fs-14"
              onClick={() => this.clearFilter()}>
              Clear All filters
            </button>

            <button
              className={`btn btn-primary standard-btn mx-1 ${
                toDateRange !== '1970-01-01' ? 'fs-12 w-200' : 'fs-14 '
              }`}
              onClick={this.clickHandler.handleDateRangeClick}>
              {toDateRange !== '1970-01-01'
                ? `${froDateRange} to ${toDateRange}`
                : 'Date Range'}
            </button>
            <CSVLink
              className="btn btn-primary standard-csv-btn mx-1 d-flex align-items-center justify-content-center"
              filename={'transactions.csv'}
              data={this.state.transactionList}>
              Download as CSV
            </CSVLink>
          </div>
        </div>

        {renderNGOReportConatiner ? this.renderNGOReportConatiner() : ''}

        {renderDateRange ? this.renderDateRange() : ''}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  transactionList: state.transaction_list.transactionList
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getNGOTransactions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
