import React from 'react';
import EmptyStateImg from '../../assets/images/emptyStateImg.svg';
const EmptyState = props => {
  var title = '';
  var subtitle = '';
  if (props.tabName === 'ProposedBudget') {
    title = 'Proposed budget is not filled';
    subtitle =
      'Concept Note with available Proposed Budget are more likely to get funded soon';
  } else if (props.tabName === 'ActivitiesPlan') {
    title = 'Activities Plan is not created';
    subtitle =
      'Concept Note with available Activities Plan are more likely to get funded soon';
  } else if (props.tabName === 'ProjectIndicators') {
    title = 'Project Indicators are not filled';
    subtitle =
      'Concept Note with available Project Indicators are more likely to get funded soon';
  } else if (props.tabName === 'ProjectMNEIndicators') {
    title = 'Project Indicators are not filled';
    subtitle = '';
  } else if (props.tabName === 'ProjectMNEActivities') {
    title = 'Project Activities are not filled';
    subtitle = '';
  }

  return (
    <div className="text-center p-5">
      <img src={EmptyStateImg} alt="EmptyStateImg" width="300" />
      <div className="text-1B1C1D fs-18 mt-4">{title}</div>
      <div className="text-626262 fs-14 mt-4">{subtitle}</div>
    </div>
  );
};

export default EmptyState;
