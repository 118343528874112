import React from 'react';
import Header from '../../../components/header';
import PageContentWrapper from '../../../components/pageContentWrapper';
import PageContent from '../../../components/pageContent';
import ActualAndPlannedBudgetComparison from '../../../components/newDesign/actualAndPlannedBudgetComparison';

export default function ActualAndPlannedBudgetComparisonScreen() {
  return (
    <React.Fragment>
      {/* <Header pageTitle={Common.NGO.PAGE_TITLE.DASHBOARD} /> */}
      <Header pageTitle="Monitoring and Evaluation" />
      <PageContentWrapper>
        <PageContent mainScreen={<ActualAndPlannedBudgetComparison />} />
      </PageContentWrapper>
    </React.Fragment>
  );
}
