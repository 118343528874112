import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  getCampaign,
  getActivitiesById,
  createOrModifyActivities
} from '../../actions';
import { InputDaypicker, InputText, InputSwitch } from '../FormElements';
import PopupModal from './popupModals';
import AuthHideShow from '../authHideShow';
import Modal from '../newDesign/Modals/Modal';
var pIndex = 0;
var removeUnsavedData = 0;
var saveCounter = 1;
var adminName = window.location.href.split('#')[1];
class ProjectActivities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: this.props.id,
      campaignName: '',
      activitiesArr: [],
      errorExists: false,
      isSaved: false,
      campaignData: null,
      deletedActivitiesArr: [],
      enableSubmitCampaignBtn: false,
      showCancelPopup: false,
      saveAndNext: false,
      showReSubmitProposal: false,
      originalArr: [],
      deleteRowOrPhase: false
    };
    this.dateInput = {
      label: 'Select a year',
      type: 'date',
      minyear: '2017-01-01',

      validation: 'required'
    };
    this.quarterList = [1, 2, 3, 4];
  }

  componentDidMount() {
    this.setState({ activitiesArr: [] });
    this.props.getActivitiesById(this.props.id);
    this.addActivity();
  }

  componentWillUnmount() {
    this.setState({ activitiesArr: [], deletedActivitiesArr: [] });
  }

  handleCancelPopup = value => {
    let flag = this.checkForModifications();
    if (!flag) {
      toast('No changes detected to cancel');
    } else {
      this.setState({
        showCancelPopup: value
      });
    }
    removeUnsavedData = false;
  };

  checkForModifications() {
    let tempActivityArr = this.state.activitiesArr.slice();
    let flag = false;
    let filledRowExist = false;
    this.state.activitiesArr.map((activity, index) => {
      for (const key in activity) {
        if (tempActivityArr[index][key]?.id !== null) {
          filledRowExist = true;
        }
        if (
          tempActivityArr[index][key]?.id === null &&
          key !== 'milestone' &&
          tempActivityArr[index][key]?.value !== '' &&
          tempActivityArr[index][key]?.value !== 0 &&
          tempActivityArr[index][key]?.value !== null
        ) {
          flag = true;
        }
      }
    });
    if (filledRowExist && flag) {
      return true;
    } else if (filledRowExist && flag) {
      return false;
    } else if (filledRowExist === false && flag === true) {
      return true;
    } else if (filledRowExist === false && flag === true) {
      return false;
    }
  }

  checkForResubmitModifications(pIndex) {
    let tempActivityArr = this.state.activitiesArr.slice();
    let flag = false;
    let filledRowExist = false;
    if (this.state.deleteRowOrPhase) {
      flag = true;
    }
    var errorExists = this.checkErrorMessage(pIndex);
    this.state.activitiesArr.map((activity, index) => {
      if (!errorExists) {
        for (const key in activity) {
          if (tempActivityArr[index][key]?.id !== null) {
            filledRowExist = true;
          }
          if (
            tempActivityArr[index][key]?.id === null &&
            key !== 'milestone' &&
            tempActivityArr[index][key]?.value !== '' &&
            tempActivityArr[index][key]?.value !== 0 &&
            tempActivityArr[index][key]?.value !== null
          ) {
            flag = true;
          } else if (this.state.originalArr[index] !== undefined) {
            if (
              tempActivityArr[index]?.id !== null &&
              key !== 'id' &&
              tempActivityArr[index][key]?.value !==
                this.state.originalArr[index][key]
            ) {
              flag = true;
            }
          }
        }
      }
    });
    this.setState({ deleteRowOrPhase: false });

    if (filledRowExist && flag) {
      return true;
    } else if (filledRowExist && flag) {
      return false;
    } else if (filledRowExist === false && flag === true) {
      return true;
    } else if (filledRowExist === false && flag === true) {
      return false;
    }
  }

  closeProposalResubmission(e) {
    this.setState({
      showReSubmitProposal: false
    });
  }
  handleProposalResubmit(pIndex) {
    let flag = this.checkForResubmitModifications();
    if (!flag) {
      toast('No changes detected');
    } else {
      this.setState({
        showReSubmitProposal: true,
        saveAndNext: false
      });
    }
  }

  handleNextFunctionCall() {
    this.props.showNext('ProjectIndicators');
    this.props.getCampaignProposalStatus(this.state.campaignId);
  }

  removeUnsavedData = () => {
    this.props.getActivitiesById(this.props.id);
    this.setState({
      showCancelPopup: false
    });
    removeUnsavedData = true;
    this.componentDidMount();
  };
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (
      nextprops.editSuccessResponse &&
      nextprops.editSuccessResponse.code === 200 &&
      this.state.campaignData === null
    ) {
      this.setState({
        campaignName: nextprops.editSuccessResponse.data.title,
        campaignData: nextprops.editSuccessResponse.data
      });
    }
    if (
      nextprops.activitiesDetailsSuccessResponse &&
      parseInt(window.location.pathname.split('/')[4]) ===
        nextprops.activitiesDetailsSuccessResponse[0]?.campaign_id
    ) {
      if (
        nextprops.activitiesDetailsSuccessResponse.length > 0 &&
        this.state.activitiesArr.length
      )
        this.setState({ isSaved: true });
      if (
        nextprops.activitiesDetailsSuccessResponse.length > 0 &&
        this.state.activitiesArr.length
      ) {
        let activitiesArr = [];
        const activitiesDataArr = nextprops.activitiesDetailsSuccessResponse;
        activitiesDataArr.sort((a, b) => {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;
        });

        activitiesDataArr.map((activity, index) => {
          const obj = {
            id: activity.id,
            activity: this.createFieldObject(
              activity.id,
              `activity`,
              activity.activity
            ),
            planned_start_date: this.createFieldObject(
              activity.id,
              `planned_start_date`,
              activity.planned_start_date
            ),
            planned_end_date: this.createFieldObject(
              activity.id,
              `planned_end_date`,
              activity.planned_end_date
            ),
            milestone: this.createFieldObject(
              activity.id,
              `milestone`,
              activity.milestone
            )
          };

          activitiesArr.push(obj);
          return false;
        });
        if (!this.state.errorExists) {
          this.setState({
            activitiesArr: activitiesArr,
            originalArr: activitiesDataArr
          });
        }
      }
    } else {
      if (this.state.activitiesArr.length) {
        if (!this.state.errorExists) {
          const mainTy = typeof this.state.activitiesArr[0]?.id;
          if (mainTy === 'string' || mainTy === 'number') {
            this.setState({ activitiesArr: [] });
          } else if (mainTy === 'object') {
            if (this.state.activitiesArr[0]?.id.id) {
              this.setState({ activitiesArr: [] });
            }
          }
        }
      } else {
        this.addActivity();
      }
    }
  }

  handleSaveFunctionCall() {
    saveCounter = 1;
    const hasError = this.checkErrorMessage() || this.ignoreErrorMsgs();

    this.setState({ saveAndNext: false });

    if (this.state.deletedActivitiesArr?.length > 0) {
      this.saveActivity(
        'ActivitiesPlan',
        !hasError || this.state.activitiesArr.id === null
      );
    }

    if (!hasError || this.state.activitiesArr.id === null) {
      //this.props.showNext('ActivitiesPlan');
      setTimeout(() => {
        this.props.getCampaignProposalStatus(this.state.campaignId);
      }, 2000);
    }
  }

  handleSaveAndNextFunctionCall() {
    saveCounter = 1;
    const hasError = this.checkErrorMessage() || this.ignoreErrorMsgs();

    if (this.state.deletedActivitiesArr?.length > 0) {
      this.saveActivity(
        'ProjectIndicators',
        !hasError || this.state.activitiesArr.id === null
      );
    }

    if (!hasError || this.state.activitiesArr.id === null) {
      this.props.showNext('ProjectIndicators');
      this.props.getCampaignProposalStatus(this.state.campaignId);
    }
  }

  createFieldObject = (id, name, value) => {
    return {
      id: id,
      idLabel: name,
      name: name,
      value: value !== undefined ? value : '',
      error: '',
      modified: 0
    };
  };
  ignoreErrorMsgs() {
    let tempActivityArr = this.state.activitiesArr.slice();
    var activityArrTemp = [];
    let flag = 0;
    var saveIndexArr = [];
    var errors = false;
    var uniqueArray = [];
    this.state.activitiesArr.map((activity, index) => {
      for (const key in activity) {
        if (
          tempActivityArr[index][key]?.id === null &&
          key !== 'milestone' &&
          tempActivityArr[index][key]?.value !== '' &&
          tempActivityArr[index][key]?.value !== 0 &&
          tempActivityArr[index][key]?.value !== null
        ) {
          flag = 1; //new row added and modified
          // saveIndexArr.push(index);
        } else if (tempActivityArr[index][key].id !== null) {
          flag = 2; // alreay saved rows present
          saveIndexArr.push(index);
        }
      }
    });

    //all empty rows
    if (flag === 0) {
      this.state.activitiesArr.map((activity, index) => {
        for (const key in activity) {
          if (key !== 'milestone') {
            if (
              tempActivityArr[index][key].id === null &&
              key !== 'milestone' &&
              (tempActivityArr[index][key].value === '' ||
                tempActivityArr[index][key].value === 0 ||
                tempActivityArr[index][key].value === null)
            ) {
              tempActivityArr[index][key].error = '';
            }
          }
        }
      });
      return false;
    }

    if (flag === 1) {
      this.state.activitiesArr.map((activity, index) => {
        for (const key in activity) {
          if (
            tempActivityArr[index][key].id === null &&
            key !== 'milestone' &&
            (tempActivityArr[index][key].value === '' ||
              tempActivityArr[index][key].value === 0 ||
              tempActivityArr[index][key].value === null)
          ) {
            const errorResult = this.validate(key, activity[key].value);
            if (errorResult.msg !== '') {
              errors = true;
              tempActivityArr[index][key].error = errorResult.msg;
            }
          } else if (activity[key].value) {
            saveIndexArr.push(index);
          }
        }
      });
      this.setState({ activitiesArr: tempActivityArr });
      if (errors === false) {
        this.saveActivity();
        // setTimeout(() => {
        //   this.saveActivity();
        // }, 1000);
        return false;
      }
      //  else if (uniqueArray = [...new Set(saveIndexArr)]){
      //   for (let i = 0; i <= uniqueArray ; i++) {
      //     if (uniqueArray.includes(i)) {
      //       activityArrTemp.push(tempActivityArr[i]);
      //     }
      //   }
      //   this.setState({ activitiesArr: activityArrTemp });
      //   if (activityArrTemp?.length === 1) {
      //     setTimeout(() => {
      //       this.saveActivity();
      //     }, 1000);
      //     return true;
      //   } else {
      //     return true;
      //   }
      // }
      else {
        uniqueArray = [...new Set(saveIndexArr)];
        for (let i = 0; i <= uniqueArray?.length; i++) {
          if (uniqueArray.includes(i)) {
            activityArrTemp.push(tempActivityArr[i]);
          }
        }
        this.setState({ activitiesArr: activityArrTemp });
        if (activityArrTemp?.length === 1 && errors === false) {
          setTimeout(() => {
            this.saveActivity();
          }, 1000);
          return true;
        } else {
          return true;
        }
      }
    }

    if (flag === 2) {
      uniqueArray = [...new Set(saveIndexArr)];
      for (let i = 0; i <= uniqueArray?.length; i++) {
        if (uniqueArray.includes(i)) {
          activityArrTemp.push(tempActivityArr[i]);
        }
      }

      this.setState({ activitiesArr: activityArrTemp });

      if (activityArrTemp?.length > 0) {
        setTimeout(() => {
          this.saveActivity();
        }, 1000);
      }
      return false;
    }
  }
  addActivity = () => {
    let activitiesArr = this.state.activitiesArr.slice();
    let count = 1;

    if (removeUnsavedData) {
      removeUnsavedData = false;
      activitiesArr = [];
      count = 1;
    }

    for (let i = 0; i < count; i++) {
      const obj = {
        id: this.createFieldObject(null),
        activity: this.createFieldObject(null, `activity`),
        planned_start_date: this.createFieldObject(null, `planned_start_date`),
        planned_end_date: this.createFieldObject(null, `planned_end_date`),
        milestone: this.createFieldObject(null, `milestone`, false)
      };
      activitiesArr.push(obj);
    }
    this.setState({ activitiesArr: activitiesArr });
  };

  deleteActivity = index => {
    let activitiesArr = this.state.activitiesArr.slice();
    let deletedActivitiesArray = this.state.deletedActivitiesArr.slice();
    const delObj = activitiesArr[index];
    if (delObj.id) {
      deletedActivitiesArray.push(delObj);
    }
    activitiesArr.splice(index, 1);
    this.setState({
      activitiesArr: activitiesArr,
      deletedActivitiesArr: deletedActivitiesArray,
      deleteRowOrPhase: true
    });
  };

  eventToValue = (e, trimResults, toNumber) => {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      if (value !== 'true' || value !== 'false') {
        value = Number(value);
      }
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  };
  Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    }
  };
  validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';
    switch (name) {
      case 'activity':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter the activity';
        break;
      case 'planned_start_date':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Planned start date required';
        break;
      case 'planned_end_date':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Planned end date required';
        break;
      default:
    }
    return { isFieldValid, msg };
  };
  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  setFormErrorsAndValue = (event, callback, type) => {
    const idArr = event.target.id.split('__');

    let fieldId = idArr[0];
    let index = parseInt(idArr[1]) - 1;

    const { target } = event;
    let { value = '' } = target;
    if (event.target.maxLength !== undefined && event.target.maxLength !== -1) {
      if (value?.length > event.target.maxLength) {
        toast.error('Max length should be ' + event.target.maxLength);
      }
      value = value.slice(0, event.target.maxLength);
    }
    if (!type) {
      value = this.eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = this.restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    const errorResult = this.validate(fieldId, value);
    let activitiesArr = this.state.activitiesArr.slice();
    activitiesArr[index][fieldId].value = value;
    activitiesArr[index][fieldId].error = errorResult.msg;

    this.setState({ activitiesArr: activitiesArr });
  };
  onHandleChangeOrBlur = (event, callback, type) => {
    this.setFormErrorsAndValue(event, callback, type);
  };

  showNext = (tab, campaignId) => {
    if (tab === 'ProposedBudget') {
      window.location.href =
        '/ngo/projectProposal/detailsOverview/' + campaignId;
    } else if (tab === 'ActivitiesPlan') {
      window.location.href =
        '/ngo/projectProposal/activitiesPlan/' + campaignId;
    } else if (tab === 'ProjectIndicators') {
      window.location.href =
        '/ngo/projectProposal/impactIndicators/' + campaignId;
    }
  };

  checkErrorMessage = phase_index => {
    let errorExists = false;
    let tempPhaseDetailsArr = this.state.activitiesArr.slice();
    this.state.activitiesArr.map((activity, p_index) => {
      if (activity.planned_end_date.value > activity.planned_start_date.value) {
        for (const key in activity) {
          if (key !== 'id') {
            const errorResult = this.validate(key, activity[key].value);
            if (key === 'planned_end_date' && activity[key].value !== '') {
              if (
                Date.parse(activity[key].value) <
                new Date(activity['planned_start_date'].value)
              ) {
                errorResult.msg =
                  'End date must be greater than or same as Start date';
              }
            }
            if (errorResult.msg !== '') {
              tempPhaseDetailsArr[p_index][key].error = errorResult.msg;
              if (!errorExists) errorExists = true;
            }
          }
        }
      } else if (
        activity.planned_end_date.value < activity.planned_start_date.value
      ) {
        for (const key in activity) {
          if (key !== 'id') {
            const errorResult = this.validate(key, activity[key].value);
            if (key === 'planned_end_date' && activity[key].value !== '') {
              if (
                Date.parse(activity[key].value) <
                new Date(activity['planned_start_date'].value)
              ) {
                errorResult.msg =
                  'End date must be greater than or same as Start date';
              }
            }
            if (errorResult.msg !== '') {
              tempPhaseDetailsArr[p_index][key].error = errorResult.msg;
              if (!errorExists) errorExists = true;
            }
          }
        }
      }
      return false;
    });
    if (errorExists) this.setState({ activitiesArr: tempPhaseDetailsArr });
    return errorExists;
  };
  convertDateFormat = date => {
    if (date.includes('-')) {
      const dateComponents = date.split('-');
      const convertedDate = [
        dateComponents[1],
        dateComponents[2]?.slice(0, 2),
        dateComponents[0]
      ].join('/');
      return convertedDate;
    } else {
      return date;
    }
  };
  saveActivity = () => {
    var errorExists = this.checkErrorMessage();
    this.setState({ errorExists: errorExists });
    if (errorExists) return false;
    if (this.state.saveAndNext === true) {
      errorExists = false;
      this.setState({ errorExists: errorExists });
    }
    if (errorExists) {
      return false;
    } else {
      const payloadObj = {
        campaign_id: this.props.id,
        quarter: 1,
        activity_details: []
      };
      let activitiesArr = [];

      this.state.activitiesArr.map((activity, index) => {
        const object = {
          activity: activity.activity.value,
          planned_start_date: activity.planned_start_date.value,
          planned_end_date: activity.planned_end_date.value,
          milestone: activity.milestone.value,
          quarter: 1,
          attachment: ''
        };
        if (activity.activity.id) object['activity_id'] = activity.activity.id;
        activitiesArr.push(object);
        return false;
      });
      if (this.state.deletedActivitiesArr.length > 0) {
        this.state.deletedActivitiesArr.map(activity => {
          if (activity.activity.id !== null) {
            const object = {
              activity_id: activity.id,
              is_deleted: true
            };
            activitiesArr.push(object);
          }
          // return false;
        });
      }
      payloadObj.activity_details = [...activitiesArr];
      if (saveCounter === 1) {
        saveCounter = saveCounter + 1;
        this.props
          .createOrModifyActivities(payloadObj)
          .then(response => {
            this.setState({ saveAndNext: false });
            this.props.getActivitiesById(this.props.id);
            toast.success('Activity saved successfully!');
            this.setState({ deletedActivitiesArr: [] });
          })
          .catch(error => {
            toast.error('Uh-Oh! Looks like we are having server issues.');
            console.error(error);
          });
      }
    }
  };
  render() {
    let disabledBefore = null;
    let disabledAfter = null;

    if (this.state.campaignData) {
      disabledBefore = new Date(
        this.state.campaignData.actual_project_start_date
      );
      disabledAfter = new Date(this.state.campaignData.actual_project_end_date);
    }

    return (
      <>
        <PopupModal
          showCancelPopup={this.state.showCancelPopup}
          handleCancelPopup={this.handleCancelPopup}
          removeUnsavedData={this.removeUnsavedData}></PopupModal>

        {/* resubmit project proposal modal */}
        <Modal
          show={this.state.showReSubmitProposal}
          title={`Re-Submit new changes in Activities Plan?`}
          handleCloseModal={e => this.closeProposalResubmission(e)}
          sizeClass="modal-md">
          <p className="mt-2 mb-5 fs-16 font-weight-normal">
            Are you sure you want to re-submit your new changes in Activities
            Plan?
          </p>
          <div className="modal-footer " style={{ borderTop: 'none' }}>
            <button
              className="btn h-40 w-95 text-primary p-1 fs-14"
              onClick={e => this.closeProposalResubmission(e)}>
              Cancel
            </button>
            <button
              className="fs-14 btn btn-primary btn-lg fs-14 dm-bold ml-3"
              onClick={() => this.handleSaveFunctionCall(pIndex)}>
              Submit
            </button>
          </div>
        </Modal>

        <div className="row p-4 create-campaign">
          <div className={`row`}>
            <div className="col-12">
              <div className={`col-12 p-4 bg-white`}>
                <div className="row justify-content-between mb-4">
                  <p className="fs-16 font-weight-medium">Activities Plan</p>
                </div>

                <div
                  className="row pt-2 text-left text-uppercase"
                  style={{ height: '45px', lineHeight: '35px' }}>
                  <div className="col-6 bg-F8F8F8 ">
                    <span className="fs-12 font-weight-medium">
                      {' '}
                      Activity Details
                    </span>
                  </div>
                  <div className="col-2  bg-F8F8F8 ">
                    <span className="fs-12 font-weight-medium">
                      Proposed Start Date
                    </span>
                  </div>
                  <div className="col-2 bg-F8F8F8">
                    <span className="fs-12 font-weight-medium">
                      Proposed End date
                    </span>
                  </div>
                  <div className="col-1  bg-none text-center ">
                    <span className="fs-12 font-weight-medium">Milestone</span>
                  </div>
                  {/* <div className="col-1 pl-2 pr-0"></div> */}
                </div>

                {/* <div className="col-1 pl-2 pr-0"></div> */}
                {this.state.activitiesArr.map((activity, index) => {
                  return (
                    <>
                      <div className="row table-rows-proposal" key={index}>
                        <InputText
                          required={true}
                          labelText=""
                          id={`${activity.activity?.idLabel}__${index + 1}`}
                          name={`${activity.activity.name}__${index + 1}`}
                          maxLength={500}
                          rows={3}
                          PlaceHolder={'Maximum  Length is 500 Characters'}
                          fieldError={activity.activity.error}
                          onHandleChange={e => this.onHandleChangeOrBlur(e)}
                          onHandleBlur={e => this.onHandleChangeOrBlur(e)}
                          fieldValue={activity.activity.value}
                          className=" activity_border-radius"
                          // disableInput={saved}
                          wrapperClassName="col-6 pr-2 mb-0"
                          permissions={this.props.permissions}
                        />

                        <InputDaypicker
                          required={true}
                          labelText={''}
                          id={`${activity.planned_start_date.idLabel}__${index +
                            1}`}
                          name={`${activity.planned_start_date.name}__${index +
                            1}`}
                          fieldError={activity.planned_start_date.error}
                          onHandleChange={this.onHandleChangeOrBlur}
                          onHandleBlur={this.onHandleChangeOrBlur}
                          fieldValue={activity.planned_start_date.value}
                          daysOfWeek={[]}
                          wrapperClassName="col-2 px-2 mb-0"
                          // disableInput={saved}
                          className="activity_border-radius"
                          disableAfter={disabledAfter}
                          disableBefore={disabledBefore}
                          permissions={this.props.permissions}
                        />
                        <InputDaypicker
                          required={true}
                          labelText={''}
                          id={`${activity.planned_end_date.idLabel}__${index +
                            1}`}
                          name={`${activity.planned_end_date.name}__${index +
                            1}`}
                          fieldError={activity.planned_end_date.error}
                          onHandleChange={this.onHandleChangeOrBlur}
                          onHandleBlur={this.onHandleChangeOrBlur}
                          fieldValue={activity.planned_end_date.value}
                          daysOfWeek={[]}
                          wrapperClassName="col-2 px-2 mb-0"
                          // disableInput={saved}
                          className="activity_border-radius"
                          disableAfter={disabledAfter}
                          disableBefore={disabledBefore}
                          permissions={this.props.permissions}
                        />
                        <div className="col-1 px-2 text-center">
                          <InputSwitch
                            id={`${activity.milestone.idLabel}__${index + 1}`}
                            name={`${activity.milestone.name}__${index + 1}`}
                            defaultValue={activity.milestone.value}
                            value={activity.milestone.value}
                            wrapperClassName="d-inline-block mb-0"
                            onHandleChange={this.onHandleChangeOrBlur}
                            permissions={this.props.permissions}
                            // disableInput={saved}
                          />
                        </div>

                        <div className="col-1 d-flex">
                          <AuthHideShow permissions={this.props.permissions}>
                            {index >= 0 && (
                              <i
                                className="fas fa-trash-alt text-31AEFD fs-19 mt-1 pt-1"
                                onClick={() => this.deleteActivity(index)}></i>
                            )}
                          </AuthHideShow>
                        </div>
                      </div>
                    </>
                  );
                })}

                <AuthHideShow permissions={this.props.permissions}>
                  <div className="row mr-4 mt-4">
                    <div className="col-12 pl-0 mb-3 d-flex">
                      <button
                        type="button"
                        onClick={() => this.addActivity()}
                        className="btn btn-outline-primary btn-lg font-weight-bold mt-3"
                        style={{
                          backGroundColor: '#31AEFD'
                        }}
                        disabled={
                          this.state.activitiesArr.length === 10
                          // ||
                          // this.state.saved
                        }>
                        Add New Activity
                      </button>
                    </div>
                  </div>
                </AuthHideShow>
              </div>
            </div>
          </div>
          {/************Sticky bottom bar***************/}
          <AuthHideShow permissions={this.props.permissions}>
            <div className="stickyBottomNav p-4">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-5">
                  {this.props.currentCampaignStatus !== '' && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-lg fs-14 dm-bold"
                      onClick={() => this.props.previewCampaign('ACTIVE')}>
                      Preview Project
                    </button>
                  )}
                </div>
                <div className="col-6">
                  <div className="float-right">
                    <span
                      className="pl-4 fs-14 dm-medium color-007bff cursor-pointer"
                      onClick={() => this.handleCancelPopup(true)}>
                      Cancel
                    </span>

                    {!this.state.enableSubmitCampaignBtn && (
                      <>
                        <span
                          className="pl-4 fs-14 dm-medium color-007bff cursor-pointer"
                          onClick={e =>
                            this.props.showNext('ProposedBudget', 0)
                          }>
                          Previous
                        </span>
                        <button
                          type="button"
                          onClick={() =>
                            this.props.currentCampaignStatus ===
                              'WAITING_FOR_FUNDING' && adminName === undefined
                              ? this.handleProposalResubmit(pIndex)
                              : this.handleSaveFunctionCall(pIndex)
                          }
                          className={`btn ${
                            this.props.currentCampaignStatus ===
                              'WAITING_FOR_FUNDING' && adminName === undefined
                              ? 'btn-primary'
                              : 'btn-outline-primary'
                          } btn-lg fs-14 dm-bold ml-3`}>
                          {this.props.currentCampaignStatus ===
                            'WAITING_FOR_FUNDING' && adminName === undefined
                            ? 'Submit'
                            : 'Save'}
                        </button>
                      </>
                    )}

                    {!this.state.enableSubmitCampaignBtn &&
                      this.props.currentCampaignStatus !==
                        'WAITING_FOR_FUNDING' && (
                        <button
                          type="button"
                          onClick={() =>
                            this.handleSaveAndNextFunctionCall(pIndex)
                          }
                          className="btn btn-primary btn-lg fs-14 dm-bold ml-3">
                          Save & Next
                        </button>
                      )}

                    {!this.state.enableSubmitCampaignBtn &&
                      this.props.currentCampaignStatus ===
                        'WAITING_FOR_FUNDING' && (
                        <button
                          type="button"
                          onClick={() => this.handleNextFunctionCall()}
                          className="btn btn-outline-primary btn-lg fs-14 dm-bold ml-3">
                          Next
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </AuthHideShow>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  editSuccessResponse: state.campaign.editSuccessResponse,
  editErrorResponse: state.campaign.editErrorResponse,
  userInfo: state.login.userInfo,
  activitiesDetailsSuccessResponse: state.mne.activitiesDetailsSuccessResponse
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getCampaign, createOrModifyActivities, getActivitiesById },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectActivities);
