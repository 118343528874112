import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/date-picker.css';

const CustomDatePicker = ({
  dateRangeArray,
  handleDateRange,
  activeTab,
  permissions
}) => {
  const currentDate = new Date();
  const lastMonthStartDate = new Date(currentDate);
  lastMonthStartDate.setMonth(lastMonthStartDate.getMonth() - 1);

  const [startDate, setStartDate] = useState(lastMonthStartDate);
  const [endDate, setEndDate] = useState(currentDate);
  const formatDate = date => {
    if (!date) {
      return '';
    }

    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    };

    try {
      // Check if date is a valid Date object
      return new Date(date).toLocaleDateString('en-IN', options);
    } catch (error) {
      console.error('Invalid date format:', date);
      return '';
    }
  };

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  useEffect(() => {
    setStartDate(lastMonthStartDate);
    setEndDate(currentDate);
  }, [activeTab]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const inputDate1 = startDate;
      const formattedDate1 = formatDate(inputDate1);
      const inputDate2 = endDate;
      const formattedDate2 = formatDate(inputDate2);
      const handle = [formattedDate1, formattedDate2];
      handleDateRange(handle);
    }
  }, [startDate, endDate]);

  const handleClear = () => {
    const defaultStartDate = lastMonthStartDate;
    const defaultEndDate = currentDate;
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);

    const defaultStartDateFormatted = formatDate(defaultStartDate);
    const defaultEndDateFormatted = formatDate(defaultEndDate);
    handleDateRange([defaultStartDateFormatted, defaultEndDateFormatted]);
  };

  return (
    <div className="">
      <div className="row">
        <div className="d-flex">
          <div className="d-flex col-md-6 px-0">
            <label className="mt-2 mx-2">From:</label>
            <div className="d-flex ms-2 ">
              <DatePicker
                className="form-control cursor-pointer"
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="Please Select"
              />
              <span className="iconContainer">
                <i
                  className={`fa fa-calendar fa-regular text-muted btn_icon`}
                />
              </span>
            </div>
          </div>
          <div className="d-flex col-md-6 px-">
            <label className="mt-2 mx-2">To:</label>
            <div className="d-flex ms-2 ">
              <DatePicker
                className="form-control cursor-pointer"
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="Please Select"
              />
              <span className="iconContainer">
                <i
                  className={`fa fa-calendar fa-regular text-muted btn_icon`}
                />
              </span>
            </div>
          </div>{' '}
          <span>
            <button
              className="btn btn-outline-primary fs-14"
              onClick={handleClear}>
              Clear
            </button>{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;
