import React from 'react';

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ui className="pagination">
        {/* <li key={pageNumbers[0]} className="page-item">
          <a
            onClick={e => paginate(e, pageNumbers[0])}
            href="!#"
            className="page-link">
            {"First"}
          </a>
        </li> */}
        {pageNumbers.map(number => (
          <li key={number} className="page-item">
            <a
              onClick={e => paginate(e, number)}
              href="!#"
              className="page-link">
              {number}
            </a>
          </li>
        ))}
        {/* <li key={pageNumbers[pageNumbers.length]} className="page-item">
          <a
            onClick={e => paginate(e, pageNumbers[pageNumbers.length])}
            href="!#"
            className="page-link">
            {"Last"}
          </a>
        </li> */}
      </ui>
    </nav>
  );
};

export default Pagination;
