import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  createOrModifyBeneficiaries,
  getMNEBeneficiaries
} from '../../actions';
import { InputText, InputSelect } from '../FormElements';
import { CSVLink } from 'react-csv';
import readXlsxFile from 'read-excel-file'; //COMMENTED FOR FUTURE USE
import DeleteIcon from '../../assets/icons/delete_icon_benef.svg';

var removeUnsavedData = false;
class Beneficiaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: window.location.href.split('/').pop(),
      beneficiariesArr: [],
      saved: false,
      errorExists: false,
      deletedBeneficiariesArr: [],
      beneficiariesDataArrCount: 0,
      csvData: [],
      beneficiaryTypeList: [
        { name: 'Direct', id: 'Direct' },
        { name: 'Indirect', id: 'Indirect' }
      ],
      genderList: [
        { name: 'Male', id: 'Male' },
        { name: 'Female', id: 'Female' },
        { name: 'Other', id: 'Other' }
      ]
    };
  }

  componentDidMount() {
    this.setState({ indicatorsArr: [] });
    this.props.getMNEBeneficiaries(parseInt(this.state.campaignId));
    this.addBeneficiary();
  }

  checkForModifications() {
    let tempBeneficiariesArr = this.state.beneficiariesArr.slice();
    let flag = false;
    let filledRowExist = false;
    this.state.beneficiariesArr.map((beneficiary, index) => {
      for (const key in beneficiary) {
        if (tempBeneficiariesArr[index][key]?.id !== null) {
          filledRowExist = true;
        }
        if (
          tempBeneficiariesArr[index][key]?.id === null &&
          tempBeneficiariesArr[index][key]?.value !== '' &&
          tempBeneficiariesArr[index][key]?.value !== 0 &&
          tempBeneficiariesArr[index][key]?.value !== null
        ) {
          flag = true;
        }
      }
    });
    if (filledRowExist && flag) {
      return true;
    } else if (filledRowExist && flag) {
      return false;
    } else if (filledRowExist === false && flag === true) {
      return true;
    } else if (filledRowExist === false && flag === true) {
      return false;
    }
  }

  // removeUnsavedData = () => {
  //   this.props.getGoalsIndicators(this.props.id);
  //   this.setState({
  //     showCancelPopup: false
  //   });
  //   removeUnsavedData = true;
  //   this.componentDidMount();
  // };
  createCSVForDownload = data => {
    let CSVArr = [];
    data.map((item, index) => {
      CSVArr.push({
        SrNo: index + 1,
        FirstName: item.first_name.value,
        LastName: item.last_name.value,
        Gender: item.gender.value,
        District: item.district.value,
        Taluka: item.taluka.value,
        State: item.state.value,
        Country: 'India',
        BeneficiaryType: item.beneficiary_type.value
      });
    });
    if (CSVArr?.length > 0) {
      this.setState({ csvData: CSVArr });
    }
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    var filteredArr = [];
    if (
      nextprops.editSuccessResponse &&
      nextprops.editSuccessResponse.code === 200
    ) {
      let benefArray = this.state.beneficiariesArr.slice();
      // let benefArray = nextprops.beneficiariesDetailsSuccessResponse;
      this.setState({
        campaignName: nextprops.editSuccessResponse.data.title,
        beneficiariesArr: benefArray
      });
    }
    if (nextprops.beneficiariesDetailsSuccessResponse?.data.length > 0) {
      filteredArr = nextprops.beneficiariesDetailsSuccessResponse?.data.filter(
        itm => itm.is_deleted !== true
      );
    }

    if (filteredArr?.length === 0) {
      let benefArray = this.state.beneficiariesArr.slice();
      this.setState({
        beneficiariesArr: benefArray
      });
    } else if (
      nextprops.beneficiariesDetailsSuccessResponse !== undefined &&
      nextprops.beneficiariesDetailsSuccessResponse?.data.length > 0
    ) {
      const beneficiariesDataArr =
        nextprops.beneficiariesDetailsSuccessResponse.data;
      if (beneficiariesDataArr?.length > 0) {
        this.setState({
          beneficiariesDataArrCount: beneficiariesDataArr?.length
        });
      }
      let beneficiariesArr = [];
      beneficiariesDataArr.map(beneficiary => {
        if (beneficiary.is_deleted === false) {
          const obj = {
            first_name: this.createFieldObject(
              beneficiary.id,
              beneficiary.first_name
            ),
            last_name: this.createFieldObject(
              beneficiary.id,
              beneficiary.last_name
            ),
            gender: this.createFieldObject(beneficiary.id, beneficiary.gender),
            district: this.createFieldObject(
              beneficiary.id,
              beneficiary.district
            ),
            taluka: this.createFieldObject(beneficiary.id, beneficiary.taluka),
            state: this.createFieldObject(beneficiary.id, beneficiary.state),
            beneficiary_type: this.createFieldObject(
              beneficiary.id,
              beneficiary.beneficiary_type
            )
          };
          beneficiariesArr.push(obj);
        }
        return false;
      });
      if (!this.state.errorExists) {
        this.setState({ beneficiariesArr: beneficiariesArr });
        this.createCSVForDownload(beneficiariesArr);
      }
    } else {
      if (this.state.beneficiariesArr.length) {
        if (!this.state.errorExists) {
          const mainTy = typeof this.state.beneficiariesArr[0]?.id;
          if (mainTy === 'string' || mainTy === 'number') {
            this.setState({ beneficiariesArr: [] });
          } else if (mainTy === 'object') {
            if (this.state.beneficiariesArr[0]?.id.id) {
              this.setState({ beneficiariesArr: [] });
            }
          }
        }
      } else {
        this.addBeneficiary();
      }
    }
  }

  handleSaveFunctionCall() {
    this.saveBeneficiary();
    setTimeout(() => {
      this.props.getMNEBeneficiaries(parseInt(this.state.campaignId));
    }, 1000);
  }

  createFieldObject(id, value) {
    return {
      id: id,
      value: value !== undefined ? value : '',
      error: ''
    };
  }
  addBeneficiary() {
    let beneficiariesArr = this.state.beneficiariesArr.slice();
    // const description = indicatorsArr.length === 0 ? null : null;
    let count = 1;
    if (removeUnsavedData) {
      removeUnsavedData = false;
      beneficiariesArr = [];
      count = 1;
    }
    for (let i = 0; i < count; i++) {
      const obj = {
        first_name: this.createFieldObject(null),
        last_name: this.createFieldObject(null),
        gender: this.createFieldObject(null),
        district: this.createFieldObject(null),
        taluka: this.createFieldObject(null),
        state: this.createFieldObject(null),
        beneficiary_type: this.createFieldObject(null)
      };
      beneficiariesArr.push(obj);
    }
    this.setState({ beneficiariesArr: beneficiariesArr });
  }

  deleteBeneficiary(index) {
    let beneficiariesArr = this.state.beneficiariesArr.slice();
    let deletedBenefArr = this.state.deletedBeneficiariesArr.slice();
    const delObj = beneficiariesArr[index];
    if (delObj.first_name.id) {
      deletedBenefArr.push(delObj);
    }
    beneficiariesArr.splice(index, 1);
    this.setState({
      beneficiariesArr: beneficiariesArr,
      deletedBeneficiariesArr: deletedBenefArr
    });
  }

  eventToValue(e, trimResults, toNumber) {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      if (value !== 'true' || value !== 'false') {
        value = Number(value);
      }
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  }
  Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    }
  };
  checkForPattern(type, value) {
    if (type === 'number') {
      let pattern = /^[0-9]+$/;
      if (!pattern.test(value)) return 'Must contain only numbers';
    } else if (type === 'aplhabets') {
      let pattern = /^[A-Za-z\s]+$/;
      if (!pattern.test(value)) return 'Must contain only characters';
    } else if (type === 'aplhaNumeric') {
      let pattern = /^[a-zA-Z0-9]+$/;
      if (!pattern.test(value)) return 'Must be alphanumeric';
    }
    return false;
  }
  validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';
    // let patternMsg = null;
    switch (name) {
      case 'first_name':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter your first name';
        break;
      case 'last_name':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter your last name';
        break;
      case 'gender':
        if (value === 'Please select') {
          isFieldValid = false;
        } else {
          isFieldValid = this.Validations.required(value);
        }
        if (!isFieldValid || value === 0) {
          msg =
            isFieldValid && value !== 'Please select'
              ? ''
              : 'Please select gender';
        }
        break;
      case 'beneficiary_type':
        if (value === 'Please select') {
          isFieldValid = false;
        } else {
          isFieldValid = this.Validations.required(value);
        }
        if (!isFieldValid || value === 0) {
          msg =
            isFieldValid && value !== 'Please select'
              ? ''
              : 'Please select beneficiary type';
        }
        break;
      case 'district':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter district';
        break;
      case 'taluka':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter your taluka';
        break;
      case 'state':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter your state';
        break;
      default:
    }
    return { isFieldValid, msg };
  };
  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  setFormErrorsAndValue = (event, type, index) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;

    if (!type) {
      value = this.eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = this.restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    const errorResult = this.validate(id, value);
    let beneficiariesArr = this.state.beneficiariesArr.slice();
    beneficiariesArr[index][id].value = value;
    beneficiariesArr[index][id].error = errorResult.msg;
    this.setState({ beneficiariesArr: beneficiariesArr });
  };
  onHandleChangeOrBlur = (event, type, index) => {
    this.setState({ submitDisable: true });
    this.setFormErrorsAndValue(event, type, index);
  };

  checkErrorMessage() {
    let errorExists = false;
    let tempBeneficiariesArr = this.state.beneficiariesArr.slice();
    this.state.beneficiariesArr.map((beneficiary, index) => {
      for (const key in beneficiary) {
        const errorResult = this.validate(key, beneficiary[key].value);
        if (errorResult.msg !== '') {
          tempBeneficiariesArr[index][key].error = errorResult.msg;
          if (!errorExists) errorExists = true;
        }
      }
      return false;
    });
    if (errorExists) this.setState({ beneficiariesArr: tempBeneficiariesArr });
    return errorExists;
  }

  // ignoreErrorMsgs() {
  //   let tempBeneficiariesArr = this.state.beneficiariesArr.slice();
  //   var beneficiariesArrTemp = [];
  //   let flag = 0;
  //   var saveIndexArr = [];
  //   var errors = false;
  //   var uniqueArray = [];

  //   this.state.beneficiariesArr.map((beneficiary, index) => {
  //     for (const key in beneficiary) {
  //       if (
  //         tempBeneficiariesArr[index][key].id === null &&
  //         tempBeneficiariesArr[index][key].value !== '' &&
  //         tempBeneficiariesArr[index][key].value !== 0 &&
  //         tempBeneficiariesArr[index][key].value !== null
  //       ) {
  //         flag = 1; //new row added and modified
  //         // saveIndexArr.push(index);
  //       } else if (tempBeneficiariesArr[index][key].id !== null) {
  //         flag = 2; // alreay saved rows present
  //         saveIndexArr.push(index);
  //       }
  //     }
  //   });

  //   //all empty rows
  //   if (flag === 0) {
  //     this.state.beneficiariesArr.map((beneficiary, index) => {
  //       for (const key in beneficiary) {
  //         if (
  //           tempBeneficiariesArr[index][key].id === null &&
  //           (tempBeneficiariesArr[index][key].value === '' ||
  //             tempBeneficiariesArr[index][key].value === 0 ||
  //             tempBeneficiariesArr[index][key].value === null)
  //         ) {
  //           tempBeneficiariesArr[index][key].error = '';
  //         }
  //       }
  //     });
  //     return false;
  //   }

  //   if (flag === 1) {
  //     this.state.beneficiariesArr.map((beneficiary, index) => {
  //       for (const key in beneficiary) {
  //         if (
  //           tempBeneficiariesArr[index][key].id === null &&
  //           (tempBeneficiariesArr[index][key].value === '' ||
  //             tempBeneficiariesArr[index][key].value === 0 ||
  //             tempBeneficiariesArr[index][key].value === null)
  //         ) {
  //           const errorResult = this.validate(key, beneficiary[key].value);
  //           if (errorResult.msg !== '') {
  //             errors = true;
  //             tempBeneficiariesArr[index][key].error = errorResult.msg;
  //           }
  //         } else {
  //           saveIndexArr.push(index);
  //         }
  //       }
  //     });
  //     this.setState({ beneficiariesArr: tempBeneficiariesArr });
  //     if (errors === false) {
  //       setTimeout(() => {
  //         this.saveBeneficiary();
  //       }, 1000);
  //       return false;
  //     } else {
  //       uniqueArray = [...new Set(saveIndexArr)];
  //       for (let i = 0; i <= uniqueArray?.length; i++) {
  //         if (uniqueArray.includes(i)) {
  //           beneficiariesArrTemp.push(tempBeneficiariesArr[i]);
  //         }
  //       }
  //       this.setState({ beneficiariesArr: beneficiariesArrTemp });
  //       if (beneficiariesArrTemp?.length === 1) {
  //         setTimeout(() => {
  //           this.saveBeneficiary();
  //         }, 1000);
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     }
  //   }

  //   if (flag === 2) {
  //     uniqueArray = [...new Set(saveIndexArr)];
  //     for (let i = 0; i <= uniqueArray?.length; i++) {
  //       if (uniqueArray.includes(i)) {
  //         beneficiariesArrTemp.push(tempBeneficiariesArr[i]);
  //       }
  //     }

  //     this.setState({ beneficiariesArr: beneficiariesArrTemp });

  //     if (beneficiariesArrTemp?.length > 0) {
  //       setTimeout(() => {
  //         this.saveBeneficiary();
  //       }, 1000);
  //     }
  //     return false;
  //   }
  // }

  saveBeneficiary() {
    const errorExists = this.checkErrorMessage();
    this.setState({ errorExists: errorExists });
    if (errorExists) return false;
    let beneficiariesArr = [];
    this.state.beneficiariesArr.map((beneficiary, index) => {
      var object = {
        beneficairy_id: beneficiary.first_name.id,
        campaign_id: parseInt(this.state.campaignId),
        first_name: beneficiary.first_name.value,
        last_name: beneficiary.last_name.value,
        gender: beneficiary.gender.value,
        district: beneficiary.district.value,
        taluka: beneficiary.taluka.value,
        state: beneficiary.state.value,
        beneficiary_type: beneficiary.beneficiary_type.value,
        is_deleted: false
      };

      if (beneficiary.first_name.id) {
        beneficiariesArr.push(object);
      } else {
        beneficiariesArr.push(object);
      }
      return false;
    });

    if (this.state.deletedBeneficiariesArr.length > 0) {
      this.state.deletedBeneficiariesArr.map(beneficiary => {
        const object = {
          beneficairy_id: beneficiary.first_name.id,
          campaign_id: parseInt(this.state.campaignId),
          first_name: beneficiary.first_name.value,
          last_name: beneficiary.last_name.value,
          gender: beneficiary.gender.value,
          district: beneficiary.district.value,
          taluka: beneficiary.taluka.value,
          state: beneficiary.state.value,
          beneficiary_type: beneficiary.beneficiary_type.value,
          is_deleted: true
        };
        beneficiariesArr.push(object);
        return false;
      });
    }
    if (beneficiariesArr.length > 0) {
      const payloadObj = {
        beneficariry_details: beneficiariesArr
      };

      this.props
        .createOrModifyBeneficiaries(payloadObj)
        .then(response => {
          // this.props.getMNEBeneficiaries(this.props.id);
          this.createCSVForDownload(beneficiariesArr);
          toast.success('Beneficiaries saved successfully!');
          this.setState({ deletedBeneficiariesArr: [] });
          setTimeout(() => {}, 2000);
          this.setState({ submitDisable: false });
        })
        .catch(error => {
          toast.error('Uh-Oh! Looks like we are having server issues.');
        });
    }
  }

  handleBulkUpload = e => {
    // COMMENTED FOR FUTURE USE
    readXlsxFile(e.target.files[0]).then(rows => {
      const cond1 =
        rows[0][0]
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '') === 'first_name'
          ? true
          : false;
      const cond2 =
        rows[0][1]
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '') === 'last_name'
          ? true
          : false;
      const cond3 =
        rows[0][2]
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '') === 'gender'
          ? true
          : false;
      const cond4 =
        rows[0][3]
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '') === 'district'
          ? true
          : false;
      const cond5 =
        rows[0][4]
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '') === 'taluka'
          ? true
          : false;
      const cond6 =
        rows[0][5]
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '') === 'state'
          ? true
          : false;
      const cond7 =
        rows[0][6]
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '') === 'beneficiary_type'
          ? true
          : false;

      if (cond1 && cond2 && cond3 && cond4 && cond5 && cond6 && cond7) {
        let filteredArr = this.state.beneficiariesArr?.filter(
          itm => itm.first_name.id !== null
        );
        // let benefArr = this.state.beneficiariesArr.slice();
        let benefArr = filteredArr;
        rows.map((rowItem, index) => {
          if (index !== 0) {
            let obj = {
              first_name: this.createFieldObject(null, rowItem[0]),
              last_name: this.createFieldObject(null, rowItem[1]),
              gender: this.createFieldObject(null, rowItem[2]),
              district: this.createFieldObject(null, rowItem[3]),
              taluka: this.createFieldObject(null, rowItem[4]),
              state: this.createFieldObject(null, rowItem[5]),
              beneficiary_type: this.createFieldObject(null, rowItem[6])
            };
            benefArr.push(obj);
          }
          return false;
        });
        this.setState({ beneficiariesArr: benefArr });
      } else {
        toast.error(
          'Please check if the headers are matching the required format.'
        );
      }
    });
  };

  render() {
    return (
      <>
        <div className="row p-4 create-campaign">
          <div className={`row`}>
            <div className="col-12 px-0">
              <div className={`col-12 p-4 bg-white ht-50`}>
                <div className="row justify-content-between mb-4">
                  <p className="fs-16 ">
                    <b>Beneficiaries</b>
                  </p>
                  {this.props.isUserAuthorized &&
                    this.props.permissions === 'CRUD' && (
                      <div className="justify-content-right">
                        <a
                          href={`../../../../docs/Beneficiary_Sample_Data.xlsx`}
                          download
                          className="text-0177E3 fs-14 fw-500">
                          Download Template
                        </a>
                        {this.props.isUserAuthorized && (
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-lg fs-14 dm-bold ml-3">
                            Bulk Upload Beneficiaries
                            <input
                              type="file"
                              accept=".xlsx"
                              id="upload_excel_sheet"
                              onChange={e => this.handleBulkUpload(e)}
                              className="invisible-input"
                            />
                          </button>
                        )}
                      </div>
                    )}
                </div>

                <div className="row mne-fixedth ">
                  <div className="col-2  p-3 bg-F8F8F8 ">
                    <span className="fs-12 font-weight-medium ">NAME</span>
                  </div>
                  <div className="col-2  p-3 bg-F8F8F8 px-2">
                    <span className="fs-12 font-weight-medium">SURNAME</span>
                  </div>
                  <div className="col-1  p-3 bg-F8F8F8 px-2">
                    <span className="fs-12 font-weight-medium">GENDER</span>
                  </div>
                  <div className="col-1  p-3 bg-F8F8F8  px-2">
                    <span className="fs-12 font-weight-medium">DISTRICT</span>
                  </div>
                  <div className="col-1  p-3 bg-F8F8F8  pl-2 pr-0">
                    <span className="fs-12 font-weight-medium">TALUKA</span>
                  </div>
                  <div className="col-1  p-3 bg-F8F8F8  pl-2 pr-0">
                    <span className="fs-12 font-weight-medium">STATE</span>
                  </div>
                  <div className="col-1  p-3 bg-F8F8F8  pl-2 pr-0">
                    <span className="fs-12 font-weight-medium">COUNTRY</span>
                  </div>
                  <div className="col-2  p-3 bg-F8F8F8  pl-2 pr-0">
                    <span className="fs-12 font-weight-medium">
                      BENEFICIARY TYPE
                    </span>
                  </div>
                </div>

                <div className="">
                  <div className="mne-scroll-benef">
                    {this.state.beneficiariesArr.map((beneficiary, index) => {
                      return (
                        <>
                          <div className={`row table-rows-proposal d-flex`}>
                            <InputText
                              tab={` ${
                                index === 0 ? 'projectIndicators' : ''
                              } `}
                              required={true}
                              labelText=""
                              maxLength={100}
                              type={'text'}
                              PlaceHolder={`Maximum Length is 100 Characters`}
                              id="first_name"
                              name="first_name"
                              fieldValue={beneficiary?.first_name?.value}
                              fieldError={beneficiary?.first_name?.error}
                              onHandleChange={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              onHandleBlur={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              wrapperClassName={'col-2 pr-2 mb-0'}
                              disableInput={
                                this.state.saved ||
                                this.props.permissions !== 'CRUD'
                              }
                              className={`activity_border-radius`}
                            />
                            <InputText
                              tab={` ${
                                index === 0 ? 'projectIndicators' : ''
                              } `}
                              required={true}
                              labelText=""
                              maxLength={100}
                              type={'text'}
                              PlaceHolder={`Maximum Length is 100 Characters`}
                              id="last_name"
                              name="last_name"
                              fieldValue={beneficiary?.last_name?.value}
                              fieldError={beneficiary?.last_name?.error}
                              onHandleChange={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              onHandleBlur={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              wrapperClassName={'col-2 pr-2 mb-0'}
                              disableInput={
                                this.state.saved ||
                                this.props.permissions !== 'CRUD'
                              }
                              className={`activity_border-radius`}
                            />
                            <InputSelect
                              required={true}
                              labelText=""
                              id="gender"
                              name="gender"
                              options={this.state.genderList}
                              fieldValue={beneficiary.gender.value}
                              fieldError={beneficiary.gender.error}
                              onHandleChange={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              onHandleBlur={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              parentElement={true}
                              wrapperClassName={'col-1 pr-2 mb-0'}
                              disableInput={
                                this.state.saved ||
                                this.props.permissions !== 'CRUD'
                              }
                              className={`activity_border-radius pr-pl-none`}
                            />
                            <InputText
                              required={true}
                              labelText=""
                              maxLength={100}
                              type={'text'}
                              PlaceHolder={''}
                              id="district"
                              name="district"
                              fieldValue={beneficiary?.district?.value}
                              fieldError={beneficiary?.district?.error}
                              onHandleChange={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              onHandleBlur={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              wrapperClassName={'col-1 pr-2 mb-0'}
                              disableInput={
                                this.state.saved ||
                                this.props.permissions !== 'CRUD'
                              }
                              className={`activity_border-radius `}
                            />
                            <InputText
                              required={true}
                              labelText=""
                              maxLength={100}
                              type={'text'}
                              PlaceHolder={''}
                              id="taluka"
                              name="taluka"
                              fieldValue={beneficiary?.taluka?.value}
                              fieldError={beneficiary?.taluka?.error}
                              onHandleChange={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              onHandleBlur={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              wrapperClassName={'col-1 pr-2 mb-0'}
                              disableInput={
                                this.state.saved ||
                                this.props.permissions !== 'CRUD'
                              }
                              className={`activity_border-radius `}
                            />
                            <InputText
                              required={true}
                              labelText=""
                              maxLength={100}
                              type={'text'}
                              PlaceHolder={''}
                              id="state"
                              name="state"
                              fieldValue={beneficiary?.state?.value}
                              fieldError={beneficiary?.state?.error}
                              onHandleChange={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              onHandleBlur={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              wrapperClassName={'col-1 pr-2 mb-0'}
                              disableInput={
                                this.state.saved ||
                                this.props.permissions !== 'CRUD'
                              }
                              className={`activity_border-radius `}
                            />

                            <InputText
                              required={true}
                              labelText=""
                              maxLength={100}
                              type={'text'}
                              PlaceHolder={''}
                              id=""
                              name=""
                              fieldValue={'India'}
                              // fieldError={beneficiary?.state?.error}
                              // onHandleChange={(e, c, t) =>
                              //   this.onHandleChangeOrBlur(e, t, index)
                              // }
                              // onHandleBlur={(e, c, t) =>
                              //   this.onHandleChangeOrBlur(e, t, index)
                              // }
                              wrapperClassName={'col-1 pr-2 mb-0'}
                              disableInput={
                                true || this.props.permissions !== 'CRUD'
                              }
                              className={`activity_border-radius `}
                            />
                            <InputSelect
                              required={true}
                              labelText=""
                              id="beneficiary_type"
                              name="beneficiary_type"
                              options={this.state.beneficiaryTypeList}
                              fieldValue={beneficiary.beneficiary_type.value}
                              fieldError={beneficiary.beneficiary_type.error}
                              onHandleChange={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              onHandleBlur={(e, c, t) =>
                                this.onHandleChangeOrBlur(e, t, index)
                              }
                              parentElement={true}
                              wrapperClassName={'col-2 pr-2 mb-0'}
                              disableInput={
                                this.state.saved ||
                                this.props.permissions !== 'CRUD'
                              }
                              className={`activity_border-radius pr-pl-none`}
                            />

                            <div className="col-1 bg-white d-flex align-items-center">
                              {this.props.isUserAuthorized &&
                                this.props.permissions === 'CRUD' && (
                                  <img
                                    src={DeleteIcon}
                                    alt="Delete"
                                    width={15}
                                    height={18}
                                    onClick={() =>
                                      this.deleteBeneficiary(index)
                                    }></img>
                                )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {this.props.isUserAuthorized &&
                    this.props.permissions === 'CRUD' && (
                      <div className="row mt-4">
                        <div className="col-12 pl-0 mt-5 mb-3">
                          <button
                            id="indicator"
                            type="button"
                            onClick={() => this.addBeneficiary()}
                            className="btn btn-outline-primary btn-lg font-weight-bold fs-14 position-absolute"
                            style={{
                              marginTop: '-35px'
                            }}>
                            Add Beneficiary
                          </button>
                        </div>
                      </div>
                    )}
                </div>

                {/************Sticky bottom bar***************/}
                {this.props.isUserAuthorized && (
                  <div className="stickyBottomNav p-4">
                    <div className="d-flex justify-content-center">
                      <CSVLink
                        type="button"
                        className="btn btn-outline-primary text-1FA4FA fs-14 w-8 p-0"
                        filename={'All_Beneficiaries.csv'}
                        data={this.state.csvData}>
                        Download
                      </CSVLink>
                      {/* <button
                        type="button"
                        onClick={() => this.handleSaveFunctionCall()}
                        className="btn btn-outline-primary btn-lg fs-14 dm-bold ml-3">
                        Download
                      </button> */}
                      {this.props.isUserAuthorized &&
                        this.props.permissions === 'CRUD' && (
                          <button
                            type="button"
                            onClick={() => this.handleSaveFunctionCall()}
                            className="btn btn-primary btn-lg fs-14 dm-bold ml-3 w-8 p-0">
                            Update
                          </button>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  userInfo: state.login.userInfo,
  beneficiariesDetailsSuccessResponse:
    state.mne.beneficiariesDetailsSuccessResponse,
  beneficiariesDetailsErrorResponse:
    state.mne.beneficiariesDetailsErrorResponse,
  beneficiariesDynamicSuccessResponse:
    state.mne.beneficiariesDynamicSuccessResponse,
  beneficiariesDynamicErrorResponse: state.mne.beneficiariesDynamicErrorResponse
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createOrModifyBeneficiaries,
      getMNEBeneficiaries
      // getMNEBeneficiariesDynamic
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Beneficiaries);
