export const SERVER_NAME = {
  HIROKU: process.env.REACT_APP_HIROKU,
  SOCIONEXT: process.env.REACT_APP_SOCIONEXT,
  S3: process.env.REACT_APP_S3,
  WORKPLACE: process.env.REACT_APP_WORKPLACE,
  MAIL_ENGINE: process.env.REACT_APP_MAIL_ENGINE,
  KEYCLOAK: process.env.REACT_APP_KEYCLOAK,
  UPLOAD: process.env.REACT_APP_UPLOAD,
  NGOCORE_DEMO_CLONE: process.env.REACT_APP_NGOCORE_DEMO_CLONE
};

export const EndPoint = {
  PROD: {
    NGO: {
      EXISTENCE: '/ngo/existence',
      LEGAL_COMPLIANCE: '/ngo/legal_compliance',
      FINANCIAL_CAPACITY: '/ngo/financial_capacity',
      INSTITUTIONAL_CAPACITY: '/ngo/institutional_capacity',
      ASSESSMENT: '/ngo/assessment',
      SOCIAL_ACTIVITY: '/ngo/social_activity',
      CONSENT: '/ngo/consent',
      SUBMIT_PROFILE: '/ngo/submit_ngo',
      NGO_REGISTER: '/ngo/profile',
      CAMPAIGN_LIST: '/ngo/list/{ngoId}',
      PROJECTPROPOSALSTATUS: '/ngo/campaignProposalStatus/{campaignId}',
      CAMPAIGNMODIFICATIONPROPOSALSTATUS:
        '/ngo/checkIfCampaignMod?campaign_id=${campaignId}',

      CAMPAIGNMODIFICATIONPROPOSALACCEPT:
        '/ngo/acceptCampaignModification/{campaignId}',
      ADMIN_CAMPAIGN_LIST: '/admin/getAllCampaigns',
      CAMPAIGN_EDIT: '/ngo/edit/{campaignId}',
      GET_NGO_EMAIL:
        '/corporate/getNgoDetailsByCampaign?campaign_id=${campaign_id}',
      DELETE_BUDGET_SECTION: '/ngo/delete_budget_section',
      CAMPAIGN_DELETE: '/ngo/delete_campaign/{campaignId}',
      CAMPAIGN_PREVIEW: '/ngo/preview',
      CAMPAIGN_CREATE: '/ngo/create',
      DUPLICATE_CREATE: '/ngo/duplicate_campaign?campaign_id={campaignId}',
      CAMPAIGN_DETAIL: '/ngo/CampaignDetails',
      APPROVE_CAMPAIGN: '/admin/approveCampaign',
      REJECT_CAMPAIGN: '/admin/rejectCampaign',
      MISSION_CREATE: '/ngo/missioncreate',
      MISSION_LIST: '/ngo/missionlist/{ngoId}',
      INVOICES: '/ngo/ngo_invoices',
      MISSION_PREVIEW: '/ngo/missionpreview',
      MISSION_EDIT: '/ngo/missionedit/{missionId}',
      MISSION_UPDATE: '/ngo/missionUpdate',
      MISSION_UPDATE_MAIL:
        '/workplace/reports/send_mission_updates_bulk_emails?',
      MNE_FUNDS_INFO: '/corporate/get_funds_utilisation/',
      MISSION_FUNDS: '/wp/get_mission_transactions_by_id/{missionId}',
      MISSION_UNPUBLISH: '/ngo/unpublishMission/{missionId}',
      MISSION_TRANSACTIONS: '/ngo/get_donation_summary_for_ngos_misions',
      MISSION_STATISTICS: '/ngo/get_stats_by_mission_id',
      AMOUNT_RAISED_BY_CORPORATES: '/ngo/get_amount_raised_from_corporates',
      ACTIVITIES_CREATE: '/ngo/projectActivities',
      PROJECT_ACTIVITIES: '/ngo/projectActivities',
      EXPENSE_CREATE: '/ngo/projectFinances',
      DELETE_EXPENSE_BY_ID: '/ngo/delete_expense/{expense_id}',
      UPDATE_ACTIVITY: '/Campaigns/updateActivity',
      ACTIVITIES_DETAIL: '/ngo/projectActivities?campaignId={campaignId}',
      ACCEPT_MOD_REQ: '/corporate/acceptModification?campaign_id={campaign_id}',
      MASTER_LIST: '/ngo/master',
      GET_MASTER_FREQUENCY: '/ngo/get_frequency',
      GET_MASTER_UOM: '/ngo/uom_masterdata',
      GUEST_MASTER_LIST: '/master/guestmasterdata',
      LIST_CURRENCY: '/master/currency',
      MASTER_LIST_ID: '/ngo/sub_categories/{masterId}',
      UPLOAD_MEDIA: '/upload',
      UPLOAD_VIDEO: '/upload',
      FILE_UPLOAD: '/upload-file',
      UPLOAD_SIGNED_URL: '/getSignedUploadUrl',
      NGO_PROFILE: '/ngo/isProfileComplete',
      NGO_DASHBOARD: '/ngo/ngo_dashboard',
      GET_NGO_TRANSACTIONS: '/ngo/ngoTransactions',
      GET_DONATION_REPORT: '/wp/get_support_history_by_ngo',
      GET_DONATION_REPORT_EXPORT: '/wp/donation_summary_csv',
      GET_DONATION_REPORT_EXPORT_10BD: '/wp/unmasked_donation_summary_csv',
      SEND_RECEIPTS_TO_ALL: '/wp/update_receipt_status',
      SECONDARY_OBJECTIVE: '/ngo/sub_categories',
      FCRA_PURPOSE: '/ngo/fcraPurpose',
      PROJECT_REPORT: '/ngo/addProjectReport?campaignId',
      GET_ALL_CORPORATES: '/ngo/get_all_corporates',
      SHARE_CAMPAIGN: '/ngo/shareCampaign',
      LIST_COUNTRIES: '/master/countries',
      LIST_STATES: '/master/state/{countryId}',
      LIST_DISTRICTS: '/master/city/{stateId}',
      PRE_ONBOARDING: '/ngo/ngoPreOnBoarding',
      SURVEY_STEP_ONE: '/ngo/preonbaording_contact_details',
      PRE_REGISTRATION: '/ngo/preonbaording_about',
      SURVEY_STEP_TWO: '/ngo/preonbaording_about',
      SURVEY_STEP_THREE: '/ngo/preonbaording_checklist',
      ALL_PROJECTS_DASHBOARD: '/ngo/allProjectDashboard',
      ACTUAL_AND_PLANNED_BUDGET: '/ngo/actualFundsUtilization/{campaignId}',
      PROJECT_ACTIVITIES: '/ngo/projectActivities?campaignId=${campaignId}',
      PROJECT_GOALS: '/ngo/projectIndicators?campaignId=${campaignId}',
      PROJECT_BENEFICIARIES: '/ngo/projectBeneficiary/{campaignId}',
      PROJECT_BENEFICIARIES_DYNAMIC:
        '/project_beneficiary/get_beneficiries_npo?project_id=${projectId}',
      REMOVE_PROJECT_BENEFICIARIES_DYNAMIC:
        '/project_beneficiary/delete_dyanamic_project_beneficiary?beneficiary_id=${beneficiaryId}',
      SELECT_PROJECT_BENEFICIARIES_TYPE:
        '/project_beneficiary/update_is_beneficiary_dynamic',
      PROJECT_EXPENSES: '/ngo/projectFinances?campaignId=${campaignId}',
      INDICATORS_CREATE: '/ngo/projectIndicators',
      BENEFICIARY_CREATE: '/ngo/projectBeneficiaryDetails',
      INDICATORS_HISTORY_CREATE: '/ngo/projectIndicatorsHistory',
      INDICATORS_HISTORY: '/ngo/projectIndicatorsHistory/{indicatorId}',
      TIMELINE_CREATE: '/ngo/projectTimeline',
      // UPDATE_PROJECT: '/Campaigns/updateProject',
      UPDATE_PROJECT: '/ngo/update_project?campaignId=${campaignId}',
      EXPENSE_CATEGORY: '/ngo/expenseCategory',
      EXPENSE_SUB_CATEGORY: '/ngo/expenseSubCategory/{expenseId}',
      All_EXPENSE_SUB_CATEGORY: '/ngo/allExpenseSubCategory',
      EXPENSE_TYPE: '/ngo/expenseType',
      TIMELINE: '/ngo/projectTimeline/{campaignId}',
      SUPPORT: '/ngo/support',
      SUPPORTTYPES: '/ngo/issueTypes',
      HEADER: '/ngo/getHeaderDetails',
      TICKET_DETAILS: '/ngo/supportById?caseId={caseId}',
      GET_NGO_COMMENTS: '/ngo/ngoComments/',
      // GET_VENDOR_COMMENTS: '/vendor/ngoComments/',
      GET_VENDOR_COMMENTS: '/ngo/get_ngo_comments?ngo_id=',
      POST_VENDOR_COMMENTS: '/ngo/ngo_comments?ngoId=',

      // /ngo/get_ngo_comments?ngo_id=86bf6bcc-dd42-4ba8-9de0-1f7f318a6073
      ACTUAL_AND_PLANNED_BUDGET_COMPARISON:
        '/ngo/actualAndPlannedBudgetComparison',
      START_PROJECT: '/ngo/start_project?campaignId={campaignId}',
      CHECK_FOR_CORP_INTEREST:
        '/ngo/checkForCorpInterest?campaign_id=${campaign_id}',
      LISTOFSTATES: '/ngo/areaofoperation/states',
      LISTOFDISTRICT: '/ngo/areaofoperation/districts',
      SYSTEM_RECOMMENDATION: '/admin/system_recommendation_engine',
      GETPUBLISHEDFORCORP: '/ngo/corp_list',
      CORPLIST: '/workplace/reports/get_corporates_by_ngo',
      MISSIONLIST: '/wp/get_missions_by_ngo',
      ADMIN_ASSOCIATION_DETAILS: '/admin/ngoAssociationDetails/{ngo_id}',
      GET_ROLES: '/ngo/get_roles',
      GET_USER_WITH_ROLES: '/ngo/get_user_roles?ngo_id={ngo_id}',
      ADD_USER_TO_ROLE: '/ngo/add_user_to_role',
      UPDATE_USER_ROLES: '/ngo/update_user_roles',
      DELETE_USER: '/ngo/delete_user?user_id={user_id}',
      DONATION_RECEIPT_UPLOADED_DATA: '/ngo/get_ngo_administration_details',
      POST_DONATION_RECEIPT_UPLOADED_DATA:
        '/ngo/update_ngo_administration_details',
      GET_REGIONAL_SETTINGS: '/ngo/get_ngos_regional_settings?ngo_id={ngo_id}'
    },
    MAIL: {
      SEND_MAIL: '/sendMail'
    },
    KEYCLOAK: {
      GET_USER_DETAILS: '/getUserDetailsById'
    }
  },

  getApiUrl: function(appName, pathKey, serverName = 'HIROKU') {
    if (!pathKey && !appName) return '';
    pathKey = pathKey.trim();
    appName = appName.trim();
    serverName = serverName.trim();
    const apiUrl =
      this.getBaseUrl(serverName) + this['PROD'][appName || 'NGO'][pathKey];
    return apiUrl;
  },
  getBaseUrl: function(serverName) {
    return SERVER_NAME[serverName || 'HIROKU'];
  }
};

export const Routes = {
  NGO: {
    ACCEPT_CAMPAIGN_MOD: '/ngo/accept_campaign_mod/:campaignId',
    REJECT_CAMPAIGN_MOD: '/ngo/reject_campaign_mod/:campaignId',
    PRE_ONBOARDING: '/ngo/preonboarding',
    PRE_REGISTRATION: '/ngo/preRegistration',
    DASHBOARD: '/ngo/dashboard',
    CAMPAIGN_LIST: '/ngo/project/list',
    NGO_LIST: '/ngo/ngo-list',
    NGO_DETAILS: '/ngo/:userId/:ngoId',
    CAMPAIGN_CREATE: '/ngo/project/create',
    CAMPAIGN_EDIT: '/ngo/project/edit',
    COMPLETE_REGISTRATION: '/ngo/completeRegistration',
    CAMPAIGN_DETAILS: '/ngo/project/detail',
    CORPORATE_LIST: '/ngo/corporate',
    DONATION_REPORT: '/ngo/donation_report',
    TRANSACTIONS_LIST: '/ngo/allTransaction',
    PROJECT_REPORT: '/ngo/project_report',
    USER_PROFILE: '/ngo/profile',
    SUPPORT: '/ngo/support',
    SUPPORT_TICKET_LIST: '/ngo/supportTicketList',
    SUPPORT_TICKET: '/ngo/supportTicket',
    CREATE_MISSION: '/ngo/mission/create',
    MODIFY_MISSION: '/ngo/mission/modify',
    MISSION_DETAILS: '/ngo/mission/details',
    MISSION_LIST: '/ngo/missions/list',
    MISSION_PLEDGE_SUMMARY: '/ngo/mission/pledgeSummary',
    MISSION_STATS: '/ngo/mission/stats',
    MISSION_EDIT: '/ngo/mission/edit',
    PROJECT_DETAILS_OVERVIEW: '/ngo/project/detailsOverview',
    PROJECT_ACTIVITIES_PLAN: '/ngo/project/activitiesPlan',
    PROJECT_IMPACT_INDICATORS: '/ngo/project/impactIndicators',
    PROJECT_PROPOSAL_DETAILS_OVERVIEW: '/ngo/projectProposal/detailsOverview',
    PROJECT_PROPOSAL_ACTIVITIES_PLAN: '/ngo/projectProposal/activitiesPlan',
    PROJECT_PROPOSAL_IMPACT_INDICATORS: '/ngo/projectProposal/impactIndicators',
    ACTUAL_AND_PLANNED_BUDGET_COMPARISON:
      '/ngo/actualAndPlannedBudgetComparison',
    // ACTUAL_AND_PLANNED_BUDGET_COMPARISON: '/ngo/corporate',
    NEWDASHBOARD: '/ngo/newdashboard',
    MNEDASHBOARD: '/ngo/ngoDashboard',
    MNESINGLEDASHBOARD: '/ngo/mneSingleProject',
    ROLE_MANAGEMENT: '/ngo/roleManagement',
    INVOICES: '/ngo/invoices'
  }
};
