import { Action } from '../constants';

const initialState = {
  userInfo: { username: null, id: null, profile: null },
  isLogin: false,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.LOGOUT_REQUEST:
      return initialState;

    case Action.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload
      };

    case Action.LOGIN_FAILURE:
      return {
        ...state,
        loading: false
      };

    case Action.LOGOUT_SUCCESS:
      return initialState;

    case Action.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
