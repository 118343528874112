import React, { useEffect, useState } from 'react';
import DragAndDropFiles from './component/dragAndDropFiles';
import MultiSelect from './component/singleAndMultiSelectValues';
import Text from './inputText';
import getDeviceType from '../helpers/getDeviceResolution';
import DateInput from './dateInput';
import RadioButtonWithBorder from './component/radioButtonWithBorder';
import toast from 'react-hot-toast';
var savedMultiselectedValue = null;
export default function PreRegistrationSingleField({
  input,
  disabled,
  state,
  errorMsg,
  errMsgsArr,
  showNextStepIndicator,
  fieldUpdated,
  setErrMsgsArr,
  surveyStatus
}) {
  const isMobile = getDeviceType();
  let uniqueIdentifierForInput;

  const [tncSavedValue, setTncSavedValue] = useState(false);
  const [radioSavedValue, setRadioSavedValue] = useState(true);
  const [savedFieldValue, setSavedFieldValue] = useState();
  const [noteForRadioBtn, setNoteForRadioBtn] = useState('');
  const [disableField, setDisableField] = useState(false);

  useEffect(() => {
    var ngoSavedData;
    if (state.register.ngoSavedData.at_survey_step !== '1') {
      if (Object.keys(state.register.ngoSavedData).length > 0) {
        ngoSavedData = state.register?.ngoSavedData;
        getNgoSavedData(ngoSavedData);
      }
    }
  }, [state.register.ngoSavedData]);

  function checkedFields(ngoSavedData, input, type) {
    if (type === 'wrapper') {
      if (input.inputtype === 'tncCheckBox' || input.inputtype === 'radio') {
        if (
          ngoSavedData[input?.wrapperKey] !== undefined &&
          input.inputtype === 'tncCheckBox'
        ) {
          //is_tan_num field - not present in profile response. temporary handling it from code
          //once the field is added in profile response- remove this if condition
          if (input.jsonfield === 'is_tan_num') {
            setTncSavedValue(false);
          } else {
            setTncSavedValue(!ngoSavedData[input.wrapperKey][input.jsonfield]); //reverse condition for checkbox
          }
        } else if (
          ngoSavedData[input?.wrapperKey] !== undefined &&
          input.inputtype === 'radio'
        ) {
          setRadioSavedValue(ngoSavedData[input.wrapperKey][input.jsonfield]);
        }
      }
    } else {
      if (type === 'subWrapper') {
        if (input.inputtype === 'tncCheckBox' || input.inputtype === 'radio') {
          //check uncheck radio or checkbox - prepopulation
          if (
            ngoSavedData[input?.wrapperKey] !== undefined &&
            input.inputtype === 'tncCheckBox'
          ) {
            setTncSavedValue(
              !ngoSavedData[input.wrapperKey][input.subWrapperKey][
                input.jsonfield
              ]
            ); //reverse condition for checkbox
          } else if (
            ngoSavedData[input?.wrapperKey] !== undefined &&
            input.inputtype === 'radio'
          ) {
            setRadioSavedValue(
              ngoSavedData[input.wrapperKey][input.subWrapperKey][
                input.jsonfield
              ]
            );
          }
        }
      }
    }
  }
  function enableDiableDependentFields(ngoSavedData, input, type) {
    if (type === 'wrapper') {
      //disable dependent fields - prepopulation
      if (input.disableDependentOn !== '') {
        if (ngoSavedData[input?.wrapperKey] !== undefined) {
          //is_tan_num field - not present in profile response. temporary handling it from code
          //once the field is added in profile response- remove this if condition
          if (input.disableDependentOn === 'is_tan_num') {
            setDisableField(false);
          } else {
            if (input.dependentOnFieldType === 'checkbox') {
              setDisableField(
                !ngoSavedData[input.wrapperKey][input.disableDependentOn]
              );
            }
          }
        }
      }
    } else {
      if (type === 'subWrapper') {
        //disable dependent fields - prepopulation
        if (input.disableDependentOn !== '') {
          if (ngoSavedData[input?.wrapperKey] !== undefined) {
            if (input.dependentOnFieldType === 'checkbox') {
              setDisableField(
                !ngoSavedData[input.wrapperKey][input.subWrapperKey][
                  input.disableDependentOn
                ]
              );
            }
          }
        }
      }
    }
  }
  function getNgoSavedData(ngoSavedData) {
    setDisableField(false);
    setTncSavedValue(false);
    setRadioSavedValue(true);
    if (input.formField === true) {
      if (ngoSavedData?.length !== 0 && ngoSavedData !== undefined) {
        if (input.subWrapperKey !== '') {
          if (input.subWrapperKey === 'external_audit_report') {
            setSavedFieldValue('');
          } else {
            if (ngoSavedData[input.wrapperKey] !== undefined) {
              setSavedFieldValue(
                ngoSavedData[input.wrapperKey][input.subWrapperKey][
                  input.jsonfield
                ]
              );
              if (input.inputtype === 'multiselect') {
                savedMultiselectedValue =
                  ngoSavedData[input.wrapperKey][input.subWrapperKey][
                    input.jsonfield
                  ];
              }
            }
            if (
              input.inputtype === 'tncCheckBox' ||
              input.inputtype === 'radio'
            ) {
              checkedFields(ngoSavedData, input, 'subWrapper');
            }
            if (input.disableDependentOn !== '') {
              enableDiableDependentFields(ngoSavedData, input, 'subWrapper');
            }
          }
        } else {
          if (ngoSavedData[input?.wrapperKey] !== undefined) {
            setSavedFieldValue(
              ngoSavedData[input?.wrapperKey][input?.jsonfield]
            );
          }
          if (
            input.inputtype === 'tncCheckBox' ||
            input.inputtype === 'radio'
          ) {
            checkedFields(ngoSavedData, input, 'wrapper');
          }
          if (input.disableDependentOn !== '') {
            enableDiableDependentFields(ngoSavedData, input, 'wrapper');
          }
        }
      }
    }
  }

  /****functon for disabling dependent fields on checkbox click*****/
  function handleFieldsOnCheck(e, fieldValue, dependentFields, type) {
    setErrMsgsArr([]);
    setTncSavedValue(fieldValue);
    var checked;
    if (type === 'checkbox') {
      checked = fieldValue;
    } else if (type === 'radio') {
      checked = fieldValue === true ? false : true;
    }

    //validation for finance file upload
    if (checked) {
      let count = 0;
      let nullCount = 0;
      let totalSections = 3;
      if (e.target.id.includes('is_report')) {
        const currentYear = new Date().getFullYear();

        let financialYears = [];

        for (let i = 1; i <= 3; i++) {
          // If it's not yet September, include the previous three years as the start of the financial year
          if (new Date().getMonth() < 8) {
            financialYears = currentYear - 1;
          } else {
            financialYears = currentYear;
          }

          if (
            document.getElementById(
              'is_report_' + String(financialYears - i)
            ) !== null
          ) {
            if (
              document.getElementById('is_report_' + String(financialYears - i))
                .checked
            ) {
              count = count + 1;
            }
          } else {
            nullCount = nullCount + 1;
          }
        }

        totalSections = totalSections - nullCount;
        if (count === totalSections) {
          toast.error('Please upload atleast one audited financial report');
          document.getElementById(e.target.id).click();
          checked = false;
        }
      }
    }
    if (type === 'checkbox') {
      if (checked) {
        for (let i = 0; i < dependentFields?.length; i++) {
          document.getElementById(dependentFields[i]).disabled = true;
          if (document.getElementById('pre_' + dependentFields[i]) !== null) {
            document
              .getElementById('pre_' + dependentFields[i])
              .classList.add('disble-drag-area');
          }
        }
      } else {
        for (let j = 0; j < dependentFields?.length; j++) {
          document.getElementById(dependentFields[j]).disabled = false;
          if (document.getElementById('pre_' + dependentFields[j]) !== null) {
            document
              .getElementById('pre_' + dependentFields[j])
              .classList.remove('disble-drag-area');
          }
        }
      }
    }
    if (type === 'radio') {
      if (checked) {
        for (let i = 0; i < dependentFields?.length; i++) {
          document
            .getElementById('showHide_' + dependentFields[i])
            .setAttribute('hidden', 'true');
        }
      } else {
        for (let j = 0; j < dependentFields?.length; j++) {
          document
            .getElementById('showHide_' + dependentFields[j])
            .removeAttribute('hidden');
        }
      }
    }
  }

  return (
    <>
      {input.inputtype === 'text' && (
        <>
          <Text
            uniqueIdentifierForInput={input.jsonfield}
            input={input}
            savedValue={savedFieldValue}
            disabled={disableField}
            preOnboard={true}
            setErrMsgsArr={setErrMsgsArr}
          />
          {errorMsg?.length > 0 &&
            errorMsg.map(data => {
              return (
                uniqueIdentifierForInput === data.label && (
                  <div
                    className={` ${
                      isMobile ? 'fs-36' : 'fs-14'
                    } mt-2 text-212529`}>
                    {data.msg}
                  </div>
                )
              );
            })}
          {errMsgsArr !== undefined &&
            errMsgsArr[input.jsonfield] === true &&
            !showNextStepIndicator && (
              <div className="mt-1 color-FF0000">
                {errMsgsArr[input.jsonfield] ? input.validationMsg : ''}
              </div>
            )}
        </>
      )}
      {input.inputtype === 'multiselect' && (
        <>
          <MultiSelect
            uniqueIdentifierForInput={input.jsonfield}
            input={input}
            state={state}
            value={savedMultiselectedValue}
            masterfield={input.masterfield}
            disabled={disableField}
          />

          {errMsgsArr !== undefined &&
            errMsgsArr[input.jsonfield] === true &&
            !showNextStepIndicator && (
              <div className="mt-1 color-FF0000">
                {errMsgsArr[input.jsonfield] ? input.validationMsg : ''}
              </div>
            )}
        </>
      )}
      {/***********Datg and drop files************/}

      {input.inputtype === 'dragAndDrop' && (
        <>
          <DragAndDropFiles
            uniqueIdentifierForInput={input.jsonfield}
            input={input}
            {...input}
            value={savedFieldValue}
            id={input.jsonfield}
            name={input.jsonfield}
            disabled={disableField}
            refId={'be0195f5-588c-48f7-9f4e-e8528a0b8d0f'}
            fieldUpdated={fieldUpdated}
            stepIndex={1}
            setErrMsgsArr={setErrMsgsArr}
            errMsgsArr={errMsgsArr}
            showNextStepIndicator={showNextStepIndicator}
            surveyStatus={surveyStatus}
          />
        </>
      )}

      {/***checkbox********/}
      {input.inputtype === 'tncCheckBox' && (
        <>
          <div className="row form-group border-0 pl-0">
            <input
              className="tnc-checkbox"
              type="checkbox"
              name={input.jsonfield}
              value={true}
              defaultValue={false}
              id={input.jsonfield}
              checked={tncSavedValue}
              disabled={surveyStatus === 'submitted'}
              onChange={e =>
                handleFieldsOnCheck(
                  e,
                  e.target.checked,
                  input.dependentFields,
                  'checkbox'
                )
              }
            />
            <label className="col-11 mr-2" htmlFor={uniqueIdentifierForInput}>
              {input.label}{' '}
            </label>
          </div>
        </>
      )}
      {/**********Radio button*******/}
      {input.inputtype === 'radio' && (
        <>
          <label>
            {input.label}
            <span className="color-FF2300">
              {' '}
              {input.required ||
              (input.validation && input.validation === 'required') ||
              input.showrequired
                ? ' * '
                : ''}
            </span>
          </label>
          <div className={`border-0 pl-0 radio`}>
            <RadioButtonWithBorder
              value={input.jsonField}
              uniqueIdentifierForInput={input.jsonfield}
              // checked={savedValue === true}
              // checked={tncSavedValue}
              checked={radioSavedValue}
              input={input}
              handleFieldsOnCheck={handleFieldsOnCheck}
              setErrMsgsArr={setErrMsgsArr}
              setNoteForRadioBtn={setNoteForRadioBtn}
            />
          </div>
          {noteForRadioBtn !== '' && (
            <span className="color-FF0000 fs-13">{noteForRadioBtn}</span>
          )}
          {errMsgsArr !== undefined &&
            errMsgsArr[input.jsonfield] === true &&
            !showNextStepIndicator && (
              <div className="mt-1 color-FF0000">
                {errMsgsArr[input.jsonfield] ? input.validationMsg : ''}
              </div>
            )}
        </>
      )}
      {input.inputtype === 'date' && (
        <>
          <DateInput
            uniqueIdentifierForInput={input.jsonfield}
            input={input}
            state={state}
            disabled={disableField}
            savedValue={savedFieldValue ? savedFieldValue : ''}
            daysOfWeek={[]}
            fieldUpdated={fieldUpdated}
          />
          {errMsgsArr !== undefined &&
            errMsgsArr[input.jsonfield] === true &&
            !showNextStepIndicator && (
              <div className="mt-1 color-FF0000">
                {errMsgsArr[input.jsonfield] ? input.validationMsg : ''}
              </div>
            )}
        </>
      )}
    </>
  );
}
