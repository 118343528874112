import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTypesOfAccount } from '../actions/accountTypeDropDown.action';
import {
  getMasterData,
  getSecondaryObjectiveList,
  getFcraPurpose,
  getAllStatesForArea,
  getAllDistByStateForArea
} from '../actions/common.actions';
import {
  getSavedNGOData,
  updateNgoId,
  onStepCompletion,
  existence,
  legal_compliance,
  financial_capacity,
  institutional_capacity,
  assessment,
  social_activity,
  consent
} from '../actions/completeRegistration.action';
import {
  getNgoProfile,
  getCommentsByNgoId,
  postCommentsById
} from '../actions/ngo.actions';
import {
  getLegalStructure,
  getEducationalBackground
} from '../actions/legalStructureDropDown.action';
import { getFullTimeEmployees } from '../actions/numberOfEmployees.action';
import { getGuideStarCertificationLevel } from '../actions/guideStarCertificationLevel.action';
import Header from '../components/header';
import PageContent from '../components/pageContent';
import PageContentWrapper from '../components/pageContentWrapper';
import ProfileContent from '../components/profileContent';
import ProfileContentWorkplaceGiving from '../components/workplaceGiving/profileContentWorkplaceGiving';
import NgoConditionalApprovedDetailPage from '../components/ConditionalApproval/NgoConditionalApprovedDetailPage';
import NgoConditionalPendingDetailPage from '../components/ConditionalApproval/NgoConditionalPendingDetailPage';
import { Common } from '../constants/common.constants';
// import PopupModal from '../components/campaigns/popupModals';
import Loader from '../components/loader';
import { Redirect } from 'react-router';
import { Routes } from '../constants';
function completeRegistration(props) {
  // if(!Object.entries(props.state.register.ngoSavedData).length ){
  //   return <Loader />}
  // props.state.ngo?.ngoProfile.survey_status==="approved"

  return (
    <>
      <React.Fragment>
        {props.state.ngo.ngoProfile.ngo_id && (
          <Header
            pageTitle={
              props.state.ngo?.ngoProfile.survey_status !== 'new'
                ? Common.NGO.PAGE_TITLE.COMPLETE_PROFILE
                : ''
            }
          />
        )}
        {props.state.login.userInfo.user_type === 2 ? (
          <PageContentWrapper>
            <PageContent
              // mainScreen={<ProfileContent {...props} />}
              mainScreen={
                props.state.ngo?.ngoProfile.is_conditionally_approved ===
                  false ||
                (props.state.ngo?.ngoProfile.is_conditionally_approved ===
                  true &&
                  props.state.ngo?.ngoProfile.survey_status === 'approved') ? (
                  props.state.ngo?.ngoProfile.is_scn ? (
                    <ProfileContent {...props} />
                  ) : /*****workplace giving profile*****/
                  props.state.ngo?.ngoProfile.is_wp &&
                    !props.state.ngo?.ngoProfile.is_scn ? (
                    <ProfileContentWorkplaceGiving {...props} />
                  ) : props.state.ngo?.ngoProfile.survey_status ===
                    'submitted' ? (
                    <Redirect to={Routes.NGO.PRE_REGISTRATION} />
                  ) : // <PopupModal preRegUnderReview={true} />
                  !Object.entries(props.state.ngo?.ngoProfile).length ? (
                    <Loader />
                  ) : (
                    // <PopupModal completePreRegForm={true} />
                    <Redirect to={Routes.NGO.PRE_REGISTRATION} />
                  ) /*******socioNext profile**********/
                ) : props.state.ngo?.ngoProfile.is_conditionally_approved ===
                    true &&
                  props.state.ngo?.ngoProfile.survey_status !== 'approved' ? (
                  props.state.ngo?.ngoProfile.is_scn ||
                  props.state.ngo?.ngoProfile.is_wp ? (
                    <NgoConditionalApprovedDetailPage {...props} />
                  ) : !props.state.ngo?.ngoProfile.is_scn &&
                    !props.state.ngo?.ngoProfile.is_wp ? (
                    <NgoConditionalPendingDetailPage {...props} />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )
              }
            />
          </PageContentWrapper>
        ) : (
          <PageContentWrapper>
            <PageContent mainScreen={<ProfileContent {...props} />} />
          </PageContentWrapper>
        )}
      </React.Fragment>
    </>
  );
}

const mapStateToProps = state => ({
  state: state
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateNgoId,
      onStepCompletion,
      existence,
      legal_compliance,
      financial_capacity,
      institutional_capacity,
      assessment,
      social_activity,
      consent,
      getMasterData,
      getSavedNGOData,
      getLegalStructure,
      getSecondaryObjectiveList,
      getTypesOfAccount,
      getFullTimeEmployees,
      getGuideStarCertificationLevel,
      getNgoProfile,
      getEducationalBackground,
      getFcraPurpose,
      getCommentsByNgoId,
      postCommentsById,
      getAllStatesForArea,
      getAllDistByStateForArea
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(completeRegistration);
