import React, { useState, useEffect } from 'react';
var primaryCauseArr = [];
var secondaryCauseArr = [];
export default function FilterOptionsDropdownChecklist(props) {
  const [checkedCount, setCheckedCount] = useState(0);
  const [valuesArr, setValuesArr] = useState([]);
  const [optionLength, setOptionLength] = useState(null);
  var dropDownCols = 3;
  var itemsInEachCol = 0;
  if (props.options?.length > 0) {
    itemsInEachCol = Math.ceil(props.options.length / dropDownCols);
  }

  const handleCheckedCount = e => {
    let valuesArray = valuesArr.slice();
    if (e.target.checked) {
      setCheckedCount(checkedCount + 1);
    } else {
      setCheckedCount(checkedCount - 1);
    }
    if (e.target.checked) {
      if (e.target.name === 'primary-cause-checkbox') {
        let itemIndexPrimary = primaryCauseArr.findIndex(
          x => x === e.target.id
        );
        if (itemIndexPrimary === -1) primaryCauseArr.push(e.target.id);
      }
      if (e.target.name === 'secondary-cause-checkbox') {
        let itemIndexSecondary = secondaryCauseArr.findIndex(
          x => x === e.target.id
        );
        if (itemIndexSecondary === -1) secondaryCauseArr.push(e.target.id);
      }
      if (e.target.name === 'location') {
        let itemIndexlocation = props?.locationChild?.findIndex(
          x => x === e.target.id
        );
        if (itemIndexlocation === -1) props.locationChild.push(e.target.id);
      }
      if (
        e.target.name !== 'primary-cause-checkbox' &&
        e.target.name !== 'secondary-cause-checkbox' &&
        e.target.name !== 'location'
      ) {
        // let valuesArray = valuesArr.slice();
        let itemIndex = valuesArray.findIndex(x => x === e.target.id);
        if (itemIndex === -1) {
          valuesArray.push(e.target.id);
          setValuesArr(valuesArray);
        }
      }
    } else {
      setCheckedCount(checkedCount - 1);
      var itemIndexPrimary = primaryCauseArr.indexOf(e.target.id);
      if (itemIndexPrimary !== -1) {
        primaryCauseArr.splice(itemIndexPrimary, 1);
      }
      var itemIndexSecondary = secondaryCauseArr.indexOf(e.target.id);
      if (itemIndexSecondary !== -1) {
        secondaryCauseArr.splice(itemIndexSecondary, 1);
      }
      var itemIndexlocation = props.locationChild?.indexOf(e.target.id);
      if (
        (itemIndexlocation && itemIndexlocation !== -1) ||
        props.locationChild?.length > 1
      ) {
        props.locationChild.splice(itemIndexlocation, 1);
      }
      if (
        e.target.name !== 'primary-cause-checkbox' &&
        e.target.name !== 'secondary-cause-checkbox' &&
        e.target.name !== 'location'
      ) {
        let itemIndex = valuesArray.findIndex(
          x => x.toString() === e.target.id
        );
        if (itemIndex !== -1) {
          valuesArray.splice(itemIndex, 1);
          setValuesArr(valuesArray);
        }
      }
    }
    if (props.name === 'primary-cause-checkbox') {
      props.handlePrimaryAreas(primaryCauseArr, e.target.title);
    }
    //  else {
    if (props.handleChange && props.name === 'secondary-cause-checkbox') {
      props.handleChange(primaryCauseArr);
    }
    if (props.locationChild > 0 || props.name === 'location') {
      props.handleChange(props.locationChild);
    }
    if (
      props.name !== 'primary-cause-checkbox' &&
      props.name !== 'secondary-cause-checkbox' &&
      props.name !== 'location'
    ) {
      props.handleChange(valuesArray, props.name);
    }
  };
  // function handleCancel(id) {
  //   document.getElementById(id).classList.remove('show');
  // }
  const clearFilterSelection = (e, id, name) => {
    let list = document.getElementsByName(name);
    for (let i = 0; i < list.length; i++) {
      if (list[i].checked === true) {
        list[i].checked = false;
      }
      setCheckedCount(0);
    }
    // props.applyCauseFilter(e, id, name);
    if (props.name === 'primary-cause-checkbox') {
      props.handlePrimaryAreas([], 'cause_category', true);
    }
    //  else {
    if (props.handleChange) props.handleChange([], true);
    // }
  };

  // const checkAndResetSelection = () => {
  //   const filteredArr = props.options?.filter(option => {
  //     const value = valuesArr.some(x => {
  //       if (option.id.toString() === x) return true;
  //       return false;
  //     });
  //     if (value) return true;
  //     return false;
  //   });
  //   if (filteredArr.length === 0) {
  //     setValuesArr([]);
  //     let list = document.getElementsByName(props.name);
  //     for (let i = 0; i < list.length; i++) {
  //       if (
  //         list[i].checked === true &&
  //         list[i].value !== '' &&
  //         list[i].value !== undefined
  //       ) {
  //         list[i].checked = false;
  //       }
  //     }
  //     setCheckedCount(0);
  //     if (props.handleChange && props.id === 'secondaryCauseArea')
  //       props.handleChange([], 'secondaryCauseArea');
  //   }
  // };

  const initValuesArr = () => {
    // console.log('initValuesArr() >> ', props.id, props.fieldValue);
    setValuesArr(props.fieldValue);
    props.fieldValue.map(value => {
      let checkBox = document.getElementById(value.toString());
      if (checkBox !== null) {
        checkBox.checked = true;
      }
      return false;
    });
  };

  useEffect(() => {
    if (props?.options?.length === 0) {
      if (checkedCount > 0) setCheckedCount(0);
    }
    // if (props.label && valuesArr.length > 0) checkAndResetSelection();
    if (props.label && props.fieldValue?.length > 0 && valuesArr.length === 0) {
      setTimeout(() => {
        initValuesArr();
      }, 1000);
    }
    if (props.options && optionLength !== props.options.length) {
      // if (optionLength) checkAndResetSelection(null, props.id, props.name);
      setOptionLength(props.options.length);
    }
  }, [props.options]);

  const getTitle = () => {
    if (props.placeHolder && valuesArr.length === 0) {
      return (
        <span className={`mt-05 ${props.btnTextClass || 'fs-14 text-1C1C1C '}`}>
          {props.placeHolder}
        </span>
      );
    } else if (!props.placeHolder && valuesArr.length === 0) {
      return (
        <span className={`mt-05 ${props.btnTextClass || 'fs-14 text-1C1C1C '}`}>
          {props.title}
        </span>
      );
    }
  };

  const getFieldLabels = () => {
    let filteredArr = props.options?.filter(option => {
      const value = valuesArr.some(x => {
        if (option.id.toString() === x.toString()) return true;
        return false;
      });
      if (value) return true;
      return false;
    });
    if (filteredArr?.length > 0) {
      return (
        <span className="w-100 d-flex flex-wrap">
          {filteredArr.map((label, index) => {
            return (
              <span
                key={index + label.id}
                className="multi-select-pill d-inline-block mt-1 mr-1">
                {label.name}
              </span>
            );
          })}
        </span>
      );
    } else return <></>;
  };

  const getCheckboxItem = item => {
    return (
      <>
        <input
          type="checkbox"
          className="form-check-input tnc-checkbox"
          id={item.id}
          title={'cause_category'}
          name={props.name}
          onChange={e => handleCheckedCount(e)}
        />
        <label
          className="form-check-label fs-14 text-1C1C1C ml-3 mt-1"
          htmlFor="checkboxSelection">
          {item.name}
        </label>
      </>
    );
  };

  return (
    <>
      <div className={`dropdown ml-3 ${props.wrapperClass}`}>
        {props.label && (
          <label>
            {props.label}{' '}
            {props.required && <span className="color-FF2300">*</span>}
          </label>
        )}
        <button
          className={`btn btn-dropdown dropdown-toggle ${
            !props.fieldHeight ? 'ht-36' : ' '
          } ${
            props.disabled ? 'btn-field-disabled' : ' '
          } d-flex justify-content-between align-items-center`}
          type="button"
          id={props.id}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false">
          {getTitle()}
          {valuesArr.length > 0 && getFieldLabels()}
          {!props.showTags && (
            <label className="ml-5 btn-dropdown-count mb-0">
              {checkedCount}
            </label>
          )}
        </button>
        {props.fieldError && (
          <div>
            <small id={props.id} className="text-danger">
              {props.fieldError}
            </small>
          </div>
        )}
        <form
          onSubmit={e => props.applyCauseFilter(e, props.id, props.name)}
          id={props.id}
          className={props.disabled ? 'd-none' : ''}>
          <div
            id={`show_${props.id}`}
            className={`dropdown-menu p-2 checkbox-toggle-dropdown ${
              props.popUpToRight ? 'right' : ' '
            }`}
            aria-labelledby="dropdownMenu2"
            style={{ width: '800px' }}>
            <div className="text-1C1C1C fs-14 dm-medium mb-2 mx-3">
              Select {props.title}
              {'(s)'}
            </div>
            {/* <div className="bg-F7F7F7">
              <div className="row justify-content-between align-items-center p-2">
                <div className="align-items-center search-input">
                  <div className="search-filter input-group p-0">
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.searchTerm}
                      onChange={e => {
                        e.persist();
                        props.debouncedHandleChange(e);
                      }}
                      placeholder="Search by campaign name"
                    />
                    <div className="input-group-prepend">
                      <i className="input-group-text fa fa-search form-control-feedback"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className={`${
                props?.options?.length > 13 ? 'row overflow-handle ' : 'row'
              }`}>
              {/* <div className='row'> */}
              <div className="col-md-4">
                {props.options?.length > 0 &&
                  props.options &&
                  props.options.map((itm, index) => {
                    return (
                      <>
                        {index <= itemsInEachCol - 1 && (
                          <div key={index} className="form-check py-2">
                            {getCheckboxItem(itm)}
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
              <div className="col-md-4">
                {props.options?.length > 0 &&
                  props.options &&
                  props.options.map((itm, index) => {
                    return (
                      <>
                        {index > itemsInEachCol - 1 &&
                          index <= itemsInEachCol * 2 - 1 && (
                            <div className="form-check py-2">
                              {getCheckboxItem(itm)}
                            </div>
                          )}
                      </>
                    );
                  })}
              </div>
              <div className="col-md-4">
                {props.options?.length > 0 &&
                  props.options &&
                  props.options.map((itm, index) => {
                    return (
                      <>
                        {index > itemsInEachCol * 2 - 1 && (
                          <div className="form-check py-2">
                            {getCheckboxItem(itm)}
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
            {!props.hideFooter && (
              <div className="mb-2">
                <hr></hr>
                <button
                  type="button"
                  className={`btn btn-cancel ht-34 mx-2 mb-2 float-right`}
                  onClick={e => clearFilterSelection(e, props.id, props.name)}>
                  Clear Selection
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm ht-34 mb-2 mx-2 float-right">
                  {' '}
                  Apply
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
