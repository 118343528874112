import React, { useEffect, useState } from 'react';
import { defaults } from 'lodash';
import ModalSide from './modalSide';
import Modal from './newDesign/Modals/Modal';
import { InputText } from './FormElements';
import editIcon from '../assets/icons/edit-blue.svg';
import deleteIcon from '../assets/icons/delete-blue.svg';
import warningIcon from '../assets/icons/warning-yellow.svg';
import { toast } from 'react-hot-toast';
import { OtpModal } from '../components/otpModal';
import { sendMail } from '../helpers/emailSender';
import { userDetails } from '../helpers/keycloak';

export default function RoleManagement(props) {
  const userDetailsObj = userDetails();
  const [userList, setUserList] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [showSideModal, setShowSideModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sideModalTitle, setSideModalTitle] = useState('');
  const [formFields, setFormFields] = useState({
    full_name: { value: '', error: '' },
    email: { value: '', error: '' },
    contact_number: { value: '', error: '' },
    role: {}
  });
  const [rolesError, setRolesError] = useState('');
  const [editIndex, setEditIndex] = useState();
  const [fetchUserData, setFetchUserData] = useState(true);
  const [savingUser, setSavingUser] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedName, setSelectedName] = useState([]);
  const [superAdminID, setSuperAdminID] = useState([]);
  const userId = localStorage.getItem('sub');
  // const [timer, setTimer] = useState('01:30');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [timerEnd, setTimerEnd] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [superAdminEmail, setSuperAdminEmail] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const initialTime = 90; // 1 minute 30 seconds in seconds
  const [time, setTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const [isResponse, setIsResponse] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [dataFromChildComponent, setDataFromChildComponent] = useState(null);

  const getInitials = full_name => {
    return full_name
      .split(' ')
      .map(word => `${word.substring(0, 1)}`)
      .join('');
  };

  const setEditUserData = index => {
    let obj = {
      full_name: { value: userList[index].full_name, error: '' },
      email: { value: userList[index].email, error: '' },
      contact_number: { value: userList[index].contact_number, error: '' },
      role: {}
    };
    if (userRoles.length > 0) {
      userRoles.map(roleObj => {
        const roleName = roleObj.role_name.toLowerCase();
        const roleIndex = userList[index].role_list.findIndex(
          x => x.role_name === roleObj.role_name
        );
        obj.role[roleName] =
          roleIndex !== undefined && roleIndex > -1 ? true : false;
        return false;
      });
    }
    setEditIndex(index);
    setFormFields(obj);
  };

  const showHideSideModal = (e, type, index) => {
    e.preventDefault();
    e.stopPropagation();
    if (showSideModal) {
      setShowSideModal(false);
      setSideModalTitle('');
    } else {
      setSideModalTitle(type === 'add' ? 'Add New User' : 'Edit User');
      if (index || index === 0) setEditUserData(index);
      setShowTransferModal(false);
      setShowSideModal(true);
    }
  };

  const showHideTransferModal = () => {
    if (!showTransferModal) {
      // setShowSideModal(true);
      setShowTransferModal(true);
    }
  };

  const checkIfDuplicateEmail = value => {
    let editUser = null;
    if (editIndex != null) editUser = userList[editIndex];
    const emailIndex = userList.findIndex(user => user.email === value);
    if (editIndex === emailIndex && editUser && value === editUser.email)
      return false;
    if (emailIndex > -1) return true;
    return false;
  };

  const validate = (type, value) => {
    let error = '';
    if (type === 'full_name') {
      if (!value || value.trim() === '') {
        error = 'Please enter a valid full name';
      } else if (value.length < 3) {
        error = 'Please enter Minimum 3 Characters';
      } else if (value.length > 100) {
        error =
          'You have reached your maximum limit (100) of characters allowed.';
      } else {
        let pattern = /^[a-zA-Z\s\']+$/;
        if (!pattern.test(value)) error = 'Please enter Alphabets only';
      }
    } else if (type === 'contact_number') {
      if (value && value.trim() !== '') {
        value = value.replace(/^0+/, '');
        let pattern = /^[0-9]{10}$/;
        if (!pattern.test(value)) error = 'Please enter a valid contact number';
      } else {
        error = 'Please enter a valid contact number';
      }
    } else if (type === 'email') {
      if (value && value.trim() !== '') {
        let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!pattern.test(value))
          error = 'Invalid email address/Incorrect format';
        if (checkIfDuplicateEmail(value)) error = 'Email already in use';
      } else {
        error = 'Please enter a valid email address';
      }
    } else if (type === 'role') {
      let roleSelected = false;
      for (let roleKey in value) {
        if (value[roleKey]) roleSelected = true;
      }
      if (!roleSelected) {
        error = 'At least one role required';
      } else {
        error = '';
      }
    }
    return error;
  };

  const checkValidation = () => {
    let isValid = true;
    let formFieldsObj = formFields;
    for (let key in formFieldsObj) {
      if (key !== 'role') {
        const error = validate(key, formFieldsObj[key].value);
        if (isValid && error !== '') isValid = false;
        formFieldsObj[key].error = error;
      } else {
        const error = validate(key, formFieldsObj[key]);
        setRolesError(error);
        if (isValid && error !== '') isValid = false;
      }
    }
    setFormFields(formFieldsObj);
    return isValid;
  };

  const setValue = (event, doValidate) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;
    let formFieldsObj = {};
    let fieldError = formFields[id].error;
    formFieldsObj[id] = {
      value: value,
      error: doValidate ? validate(id, value) : fieldError
    };
    formFieldsObj = defaults({}, formFieldsObj, formFields);
    setFormFields(formFieldsObj);
  };

  const onHandleChange = event => {
    setValue(event, false);
  };

  const onHandleBlur = event => {
    setValue(event, true);
  };

  const setChecked = (event, checkedValue) => {
    let rolesArrDependentOnAdmin = ['ngo_fundraising_manager', 'ngo_finance'];

    const { target } = event;
    const { id } = target;
    const roleId = event.target.id;
    let formFieldsObj = { role: formFields.role };
    formFieldsObj.role[id] = checkedValue;

    if (roleId === 'ngo_admin') {
      for (let i = 0; i < rolesArrDependentOnAdmin?.length; i++) {
        formFields['role'][rolesArrDependentOnAdmin[i]] = checkedValue
          ? true
          : false;
        document.getElementById(
          rolesArrDependentOnAdmin[i]
        ).disabled = checkedValue ? true : false;
      }
    }
    if (roleId === 'ngo_fundraising_manager') {
      formFields['role'][rolesArrDependentOnAdmin[1]] = checkedValue
        ? true
        : false;
      document.getElementById(
        rolesArrDependentOnAdmin[1]
      ).disabled = checkedValue ? true : false;
    }
    // if (index > 0 && checkedValue) {
    //   userRoles.map((role, r_index) => {
    //     if (r_index !== index && r_index < index) {
    //       formFieldsObj.role[role.role_name.toLowerCase()] = true;
    //     }
    //     return false;
    //   });
    // }
    formFieldsObj = defaults({}, formFieldsObj, formFields);
    setRolesError(validate('role', formFieldsObj.role));
    setFormFields(formFieldsObj);
  };

  const clearForm = () => {
    let obj = {
      full_name: { value: '', error: '' },
      email: { value: '', error: '' },
      contact_number: { value: '', error: '' },
      role: {}
    };
    if (userRoles.length > 0) {
      userRoles.map(roleObj => {
        const roleName = roleObj.role_name.toLowerCase();
        obj.role[roleName] = false;
        return false;
      });
      let rolesArrDependentOnAdmin = [
        'ngo_fundraising_manager',
        'ngo_finance',
        'ngo_admin'
      ];
      for (let i = 0; i < rolesArrDependentOnAdmin?.length; i++) {
        if (document.getElementById(rolesArrDependentOnAdmin[i]))
          document.getElementById(rolesArrDependentOnAdmin[i]).disabled = false;
      }
    }
    setFormFields(obj);
  };

  const cancelSave = () => {
    clearForm();
    setShowSideModal(false);
  };

  const getSelectedRoles = () => {
    let roleIdArr = [];
    for (let role in formFields.role) {
      if (formFields.role[role]) {
        const obj = userRoles.find(
          userRole => userRole.role_name.toLowerCase() === role
        );
        if (obj) roleIdArr.push(obj.id);
      }
    }
    return roleIdArr;
  };

  const superAdminId = () => {
    const superAdminRole = userRoles.find(
      role => role.role_name === 'NGO_Superadmin'
    );
    if (superAdminRole) {
      let adminData;
      adminData = superAdminRole?.group_id.split(',');
      return adminData;
    }
  };

  const adminId = () => {
    const adminRole = userRoles.find(role => role.role_name === 'NGO_Admin');
    if (adminRole) {
      let adminData;
      adminData = adminRole?.group_id.split(',');
      return adminData;
    }
  };

  const addUser = () => {
    const isValid = checkValidation();
    if (!isValid) {
      toast.error('Error(s) found! Please resolve the error and try again');
      return false;
    }
    const obj = {
      full_name: formFields.full_name.value,
      email_address: formFields.email.value,
      contact_number: parseInt(formFields.contact_number.value),
      ngo_name: props.state.login.userInfo.ngo_name,
      roles: getSelectedRoles(),
      orgId: props.state.ngo.ngoProfile.ngo_id,
      type_id: 2
    };
    setSavingUser(true);
    props
      .addUserToRole(obj)
      .then(res => {
        const ngoId = props.state.ngo.ngoProfile.ngo_id;
        props.getUsersWithRole(ngoId);
        setSavingUser(false);
        clearForm();
        setShowSideModal(false);
        setUserList([]);
        setFetchUserData(true);
        toast.success('User added successfully');
      })
      .catch(err => {
        setSavingUser(false);
        console.error(err);
        toast.error('Oops! An unexpected error occurred while adding user.');
      });
  };

  const getGroupIds = () => {
    let rolesIdArr = getSelectedRoles();
    let groupIdArr = [];
    groupIdArr = userRoles.filter(role => {
      const value = rolesIdArr.some(x => {
        if (role.id === x) return true;
        return false;
      });
      if (value) return true;
      return false;
    });
    groupIdArr = groupIdArr.map(role => role.group_id);
    return groupIdArr;
  };

  const updateUser = async () => {
    try {
      let payload = {
        user_id: userList[editIndex].id,
        groups: getGroupIds()
      };
      setSavingUser(true);
      const res = await props.updateUserToRole(payload);

      if (res.data.code === '200') {
        const ngoId = props.state.ngo.ngoProfile.ngo_id;
        props.getUsersWithRole(ngoId);
        setShowSideModal(false);
        setSavingUser(false);
        clearForm();
        setEditIndex(null);
        setFetchUserData(true);
        setUserList([]);
      }
    } catch (err) {
      setSavingUser(false);
      console.error(err);
      toast.error(
        'Oops! An unexpected error occurred while updating the user.'
      );
    }
  };
  const transferOwnership = async () => {
    try {
      // const isValid = checkValidation();
      // if (!isValid) {
      //   toast.error('Error(s) found! Please resolve the error and try again');
      //   return false;
      // }

      let payload = {
        user_id: selectedUserId,
        groups: superAdminId()
      };

      let removeSuperAdminpayload = {
        user_id: superAdminID,
        groups: adminId(),
        orgId: superAdminID
      };

      setSavingUser(true);

      // Make the first API call and wait for it to complete.
      const res = await props.updateUserToRole(payload);

      if (res.data.code === '200') {
        setIsApiCall(true);
        const ngoId = props.state.ngo.ngoProfile.ngo_id;

        // Make the second API call and wait for it to complete.
        props.getUsersWithRole(ngoId);

        setShowSideModal(false);
        setSavingUser(false);
        clearForm();
        setEditIndex(null);
        setUserList([]);

        // Make the third API call and wait for it to complete.
        const res2 = await props.updateUserToRole(removeSuperAdminpayload);

        if (res2.data.code === '200') {
          setFetchUserData(true);
          toast.success('User updated successfully', {
            icon: '✅'
          });
          setIsResponse(true);
          updateDataFromChild();

          sendMail({
            templateName: 'userRoleTransfer',
            sendTo: superAdminEmail,
            subject: 'Role Transfer Successful',
            templateData: {
              role_name: 'ADMIN'
            }
          });
        }
      }
    } catch (err) {
      setSavingUser(false);
      console.error(err);
      toast.error(
        'Oops! An unexpected error occurred while updating the user.'
      );
    }
  };

  const saveOrUpdateUser = () => {
    if (editIndex || editIndex === 0) {
      updateUser();
    } else {
      addUser();
    }
  };

  const generateOTP = () => {
    const digits = '0123456789';
    const otpLength = 4;
    let otp = '';

    for (let i = 1; i <= otpLength; i++) {
      var index = Math.floor(Math.random() * digits.length);
      otp = otp + digits[index];
    }
    setOTP(otp);
    return otp;
  };

  // Function to start the timer
  const startTimer = () => {
    setIsRunning(true);
    setTimerEnd(false);
  };

  // Function to stop and reset the timer
  const stopTimer = () => {
    setIsRunning(false);
    setTime(initialTime);
    setTimerEnd(true);
  };

  // Use useEffect to update the timer every second when it's running
  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        if (time > 0) {
          setTime(time - 1);
        } else {
          clearInterval(interval);
          setIsRunning(false);
          setTimerEnd(true);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning, time]);

  const confirmButton = async () => {
    const newOTP = await generateOTP();
    sendMail({
      templateName: 'auditTrailOTPNewDesign',
      sendTo: superAdminEmail,
      subject: 'Verify OTP for Role Transfer',
      templateData: {
        otp: newOTP
      }
    });
    toast.success('Email sent successfully', {
      icon: '✅'
    });
    setShowOtpModal(true);
    startTimer();
  };

  const deleteUser = user => {
    setSavingUser(true);
    props
      .deleteUser(user.id)
      .then(res => {
        toast.success('User deleted successfully');
        setSavingUser(false);
        const ngoId = props.state.ngo.ngoProfile.ngo_id;
        handleDeleteCancel();
        setUserList([]);
        setFetchUserData(true);
        props.getUsersWithRole(ngoId);
      })
      .catch(err => {
        setSavingUser(false);
        toast.error(
          'Oops! An unexpected error occurred while deleting the user.'
        );
        console.error(err);
      });
  };

  const handleDeleteCancel = () => {
    setDeleteItem(null);
    setShowDeleteModal(false);
  };

  const handleDeleteClick = user => {
    setDeleteItem(user);
    setShowDeleteModal(true);
  };

  const handleUserDelete = () => {
    deleteUser(deleteItem);
  };

  const handleCancelSave = () => {
    setShowModal(true);
  };

  const handleCancelTransferModal = () => {
    setShowTransferModal(false);
    setSelectedName([]);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseOtpModal = () => {
    setShowOtpModal(false);
  };

  const handleCancel = () => {
    cancelSave();
    setShowModal(false);
    setEditIndex(null);
    setShowTransferModal(false);
    setSelectedName('');
  };

  const sortUserList = results => {
    let arr = results.filter(
      user =>
        !user.role_list.some(x =>
          x.role_name.toLowerCase().includes('superadmin')
        )
    );
    let superAdmin = results.filter(user =>
      user.role_list.some(x => x.role_name.toLowerCase().includes('superadmin'))
    );
    const userRole = results.find(x => x.id === userDetailsObj.sub);
    setUser(userRole);
    arr = [...arr, ...superAdmin];
    return arr;
  };

  useEffect(() => {
    // setUserList(userListData);
    props.getRoles();
  }, []);

  useEffect(() => {}, [formFields]);

  useEffect(() => {
    if (props?.state?.roleManagement.roles) {
      const results = props.state.roleManagement.roles?.result;
      if (results && (results.code === '200' || results.code === 200)) {
        results.result.sort((a, b) => {
          if (a.id > b.id) return -1;
          if (a.id < b.id) return 1;
          return 0;
        });
        setUserRoles(results.result);
        let formFieldsObj = { role: formFields.role };
        results.result.map(
          role => (formFieldsObj.role[role.role_name.toLowerCase()] = false)
        );
        formFieldsObj = defaults({}, formFieldsObj, formFields);
        setFormFields(formFieldsObj);
        if (userRoles.length === 0 && fetchUserData) {
          let ngoId = JSON.parse(localStorage.getItem('ngoId'));
          props.getUsersWithRole(ngoId);
        }
      }
    }
    if (props.state.roleManagement.userWithRoles) {
      const results = props.state.roleManagement.userWithRoles?.result;
      if (results && Array.isArray(results)) {
        setFetchUserData(false);
        setUserList(sortUserList(results));
      }
    }
  }, [props?.state?.roleManagement]);

  const getRoleElements = (user, recordIndex) => {
    return (
      <>
        {userRoles.map((role, index) => {
          const label = role.role_name.replace('NGO_', '').replace(/_/g, ' ');
          let isRoleActive = false;
          const roleIndex = user.role_list.findIndex(x => x.id === role.id);
          if (roleIndex > -1) isRoleActive = true;
          return userRoles?.length - 1 !== index ||
            recordIndex === userList?.length - 1 ? (
            <div
              className="d-flex align-items-center mr-4"
              key={role.id + role.role_name + index}>
              {isRoleActive ? (
                <>
                  <input type="checkbox" checked disabled />
                  <span className="ms-3 pl-2">{label}</span>
                </>
              ) : (
                <>
                  <input
                    type="checkbox"
                    checked={
                      recordIndex === userList?.length - 1 ? true : false
                    }
                    disabled
                  />
                  <span className="ms-3 pl-2">{label}</span>
                </>
              )}
            </div>
          ) : (
            ''
          );
        })}
      </>
    );
  };

  const getLastCheckedIndex = () => {
    let checkedIndex = -1;
    if (formFields && formFields.role) {
      let index = 0;
      for (let key in formFields.role) {
        if (formFields.role[key]) checkedIndex = index;
        ++index;
      }
    }
    return checkedIndex;
  };

  const getRoleCheckbox = (role, index) => {
    if (role.role_name.toLowerCase().includes('superadmin')) return <></>;
    if (
      user &&
      getRoleIndex(user, 'superadmin') === -1 &&
      role.role_name.toLowerCase().includes('admin')
    )
      return <></>;
    const lastCheckedIndex = getLastCheckedIndex();
    const id = role.role_name.toLowerCase();
    const label = role.role_name.replace('NGO_', '').replace(/_/g, ' ');
    const checked = formFields.role[role.role_name.toLowerCase()];
    let disabled = false;
    if (index < userRoles.length - 1 && index < lastCheckedIndex)
      disabled = true;
    return (
      <span
        className="d-flex col-auto mt-2 mb-3 pl-0"
        key={role.id + role.role_name + index}>
        <input
          id={id}
          name={id}
          type="checkbox"
          title={label}
          className="tnc-checkbox"
          onChange={e => setChecked(e, e.target.checked, index)}
          checked={checked}
          disabled={disabled}
        />
        <span className="form-check-label fs-14 text-1C1C1C ml-3 mt-1">
          {label}
        </span>
      </span>
    );
  };

  // const checkIfSuperAdmin = role => {
  //   let ngoId = localStorage.getItem('ngoId');
  //   // const temp = ngoId.replace(/"/g, '');
  //   if (ngoId === JSON.parse(ngoId)) return true;
  //   return false;
  // };

  const getEmptyStateText = () => {
    if (fetchUserData) {
      return 'Fetching user roles...';
    } else {
      return 'No user roles found';
    }
  };

  const getModules = () => {
    const filterRoleArr = deleteItem?.role_list.filter(role =>
      role.role_name.includes('NGO_')
    );
    return (
      <div className="mb-5">
        {filterRoleArr?.map(role => {
          const roleName = role.role_name
            .replace('NGO_', '')
            .replace(/\_/g, ' ');
          return (
            <p key={role.role_name + role.id} className="mb-2 text-black-2">
              - {roleName}
            </p>
          );
        })}
      </div>
    );
  };

  const isEdit = () => {
    if (editIndex === 0 || editIndex > 0) return true;
    return false;
  };

  const isUserSuperAdmin = () => {
    if (
      user?.role_list.filter(role =>
        role.role_name.toLowerCase().includes('superadmin')
      ).length > 0
    )
      return true;
    return false;
  };

  const getRoleIndex = (user, role) =>
    user?.role_list?.findIndex(x => x.role_name.toLowerCase().includes(role));

  const bgColorCheck = user => {
    const superAdminRoleIndex = getRoleIndex(user, 'superadmin');
    if (user.id === props.state.login.userInfo.sub || superAdminRoleIndex > -1)
      return 'data-row-superadmin';
    return '';
  };

  const displayEditOptions = user => {
    if (getRoleIndex(user, 'superadmin') > -1) {
      return false;
    } else if (!isUserSuperAdmin() && getRoleIndex(user, 'admin') > -1) {
      return false;
    } else if (user.id === props.state.login.userInfo.sub) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = event => {
    const selectedValue = event.target.options[
      event.target.selectedIndex
    ].getAttribute('value');
    const inputString = selectedValue;
    const parts = inputString.split(', ');
    const dataArray = parts.map(part => part.split(','));
    setName(dataArray[0][0]);
    setEmail(dataArray[0][1]);
    setSelectedUserId(dataArray[0][2]);
    setSelectedName(dataArray);
  };

  const updateDataFromChild = data => {
    setDataFromChildComponent(data);
    console.error(dataFromChildComponent);
  };

  useEffect(() => {
    let ngoId = JSON.parse(localStorage.getItem('ngoId'));
    const getUserGroup = userList?.filter(user => user.id === ngoId);
    if (getUserGroup.length) {
      let temp_id = getUserGroup[0].id;
      setSuperAdminID(temp_id);
      setSuperAdminEmail(getUserGroup[0].email);
    }
    superAdminId();
  }, [userList]);

  return (
    <>
      <div className="pt-4 px-4">
        <div className="d-flex justify-content-between mb-4 pb-2">
          <div className="d-flex mx-4">
            <h3 className=" fs-20 mb-0">Roles and Access management</h3>
          </div>
          <div className="p-0 mr-2 d-flex w-25">
            {isUserSuperAdmin() && (
              <span
                className="fs-14 text-primary cursor-pointer fw-bold mt-3 w-50"
                onClick={e => showHideTransferModal()}>
                Transfer Ownership
              </span>
            )}
            <button
              type="button"
              className="btn btn-primary btn-sm w-75"
              onClick={e => showHideSideModal(e, 'add')}
              disabled={savingUser}>
              Add New User
            </button>
          </div>
        </div>
        {userList.length === 0 && (
          <h3 className="text-center mt-5">{getEmptyStateText()}</h3>
        )}
        <div>
          {userList &&
            userList.map((user, index) => {
              return (
                <div
                  key={user.full_name + user.id + index}
                  className={`data-row bg-white row align-items-center py-3 px-3 mb-4 ${bgColorCheck(
                    user
                  )}`}>
                  <span className="col-2 pl-0 d-flex align-items-center">
                    <span className="d-flex justify-content-center align-items-center user-initials text-1B1C1D fs-12 mr-3">
                      {getInitials(user.full_name)}
                    </span>
                    <span className="text-1B1C1D fs-14 text-overflow-ellipsis">
                      {user.full_name}
                    </span>
                  </span>
                  <span className="col-2 text-1B1C1D fs-14 text-overflow-ellipsis">
                    {user.email}
                  </span>
                  <span className="col-7 d-flex px-0">
                    {getRoleElements(user, index)}
                  </span>
                  <span className="col-1 d-flex justify-content-around pr-0">
                    {/* {!checkIfSuperAdmin(user) && ( */}
                    {displayEditOptions(user) && (
                      <>
                        <img
                          src={editIcon}
                          className="row-icons cursor-pointer"
                          onClick={e => showHideSideModal(e, 'edit', index)}
                          alt="Funding"
                        />
                        <img
                          src={deleteIcon}
                          alt="Funding"
                          className="row-icons cursor-pointer"
                          // onClick={e => deleteUser(user)}
                          onClick={e => handleDeleteClick(user)}
                        />
                      </>
                    )}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <ModalSide
        showModal={showSideModal}
        handleCloseModal={showHideSideModal}
        className={'side-modal-sm'}
        title={sideModalTitle}
        hideCancel={true}
        disableOverlayClick={true}>
        <div className="px-4 pb-4">
          <div className="d-flex flex-column justify-content-between modal-container">
            <div className="row form-group">
              <InputText
                required={true}
                id="full_name"
                name="full_name"
                type={'text'}
                labelText="Name"
                PlaceHolder={'Eg: John Doe'}
                wrapperClassName={'col-12 pl-0 pr-0'}
                O
                fieldValue={formFields.full_name.value}
                fieldError={formFields.full_name.error}
                onHandleChange={onHandleChange}
                onHandleBlur={onHandleBlur}
                disableInput={isEdit()}
              />
              <InputText
                required={true}
                id="email"
                name="email"
                type={'text'}
                labelText="Email"
                PlaceHolder={'john.doe@example.com'}
                wrapperClassName={'col-12 pl-0 pr-0'}
                labelClassName="fs-14"
                fieldValue={formFields.email.value}
                fieldError={formFields.email.error}
                onHandleChange={onHandleChange}
                onHandleBlur={onHandleBlur}
                disableInput={isEdit()}
              />
              <InputText
                required={true}
                id="contact_number"
                name="contact_number"
                type={'text'}
                labelText="Phone"
                PlaceHolder={'1234567890 (10 Digit Number)'}
                wrapperClassName={'col-12 pl-0 pr-0'}
                labelClassName="fs-14"
                maxLength={10}
                fieldValue={formFields.contact_number.value}
                fieldError={formFields.contact_number.error}
                onHandleChange={onHandleChange}
                onHandleBlur={onHandleBlur}
                disableInput={isEdit()}
              />
              <div className="w-100">
                <label className="fs-14">
                  Assign Role<span className="color-FF2300">*</span>
                </label>
                <div className="row flex-wrap">
                  {userRoles.map((role, index) => {
                    return getRoleCheckbox(role, index);
                  })}
                </div>
                <div className={`${rolesError === '' ? 'd-none' : ''}`}>
                  <small id="role-error" className="text-danger">
                    {rolesError}
                  </small>
                </div>
              </div>
            </div>
            <div className="pb-4 text-right">
              <button
                type="button"
                className="btn btn-light px-4 mr-3"
                onClick={handleCancelSave}
                disabled={savingUser}>
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary px-4"
                onClick={saveOrUpdateUser}
                disabled={savingUser}>
                Save
              </button>
            </div>
          </div>
        </div>
      </ModalSide>
      <ModalSide
        showModal={showTransferModal}
        handleCloseModal={showHideTransferModal}
        className={'side-modal-md'}
        title="Transfer Ownership"
        hideCancel={true}
        disableOverlayClick={true}>
        <div className="px-4 py-2 pb-4">
          <div className="d-flex flex-column justify-content-between modal-container">
            <div className="form-group">
              <div className="border rounded border-warning bg-FFFCF4 px-3 py-2">
                <span className="fs-14 fw-500">
                  Add Role to transfer the ownership
                </span>
                <span
                  className="fs-12 float-right text-primary cursor-pointer fw-bold "
                  onClick={e => showHideSideModal(e, 'add')}>
                  Add New User
                </span>
                <p className="mt-3">
                  Ownership can be transferred to only existing user of the
                  platform. Please add a role first and then transfer the
                  ownership
                </p>
              </div>

              <div className="border rounded border-warning bg-FFFCF4 px-3 py-2 mt-4">
                <img src={warningIcon} alt="warning" className="w-4" />
                <span className="fs-14 ml-2 fw-500 ">Warning !</span>
                <p className="mt-3">
                  You are about to delegate your Super Admin ownership to the
                  designated individual. All privileges will be transferred to
                  them, and your role will be updated to Admin. Please be aware
                  that this transfer of ownership is permanent and cannot be
                  undone
                </p>
              </div>

              <p className="fs-14 fw-500 mt-4">
                Choose the user to whom you wish to transfer ownership.
              </p>

              <p className="fs-14 mt-2">Select User</p>
              <select
                className="role-transfer-select px-2 py-2 w-100"
                value={selectedName}
                onChange={handleChange}>
                <option value="" disabled>
                  Select
                </option>
                {userList.map(item => (
                  <>
                    {getRoleIndex(item, 'superadmin') === -1 && (
                      <option
                        key={item.id}
                        value={[item.full_name, item.email, item.id]}>
                        {item.full_name} ({item.email})
                      </option>
                    )}
                  </>
                ))}
              </select>
              <p className="fs-12 color-666565 mt-4">
                We will be sending you OTP to your email address for
                verification.
              </p>
            </div>
            <div className="pb-5 text-right">
              <button
                type="button"
                className="btn btn-light px-4 mr-3"
                onClick={handleCancelTransferModal}
                disabled={savingUser}>
                Cancel
              </button>

              {selectedName.length > 0 && (
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  onClick={confirmButton}
                  disabled={savingUser}>
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      </ModalSide>
      <Modal
        show={showOtpModal}
        title="Verification for Transferring Ownership"
        handleCloseModal={handleCloseOtpModal}
        sizeClass={'modal-md'}>
        <div>
          <OtpModal
            userId={userId}
            // timer={timer}
            time={time}
            selectedEmail={email}
            selectedName={name}
            superAdminEmail={superAdminEmail}
            otp={otp}
            // setTimer={setTimer}
            startTimer={startTimer}
            timerEnd={timerEnd}
            setShowOtpModal={setShowOtpModal}
            updateUser={transferOwnership}
            onCancel={stopTimer}
            generateOTP={generateOTP}
            setDataToChild={updateDataFromChild}
            isResponse={isResponse}
            isApiCall={isApiCall}
            isRunning={isRunning}
          />
        </div>
      </Modal>
      <Modal
        show={showModal}
        title={'Cancel changes?'}
        handleCloseModal={handleCloseModal}
        sizeClass={'modal-lg'}>
        <div>
          <p className="fs-14">
            Are you sure you want to cancel the changes you have made?
          </p>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-light px-4 mr-3"
              onClick={handleCloseModal}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={handleCancel}>
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={showDeleteModal}
        title={'Revoke Access'}
        handleCloseModal={handleDeleteCancel}
        sizeClass={'modal-lg'}
        hideCloseBtn={true}>
        <div>
          <p className="mb-3 text-black-1">
            Are you certain about revoking access for {deleteItem?.full_name}
            ?&nbsp; They currently have access to the following modules:
          </p>
          <p>{getModules()}</p>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-light px-4 mr-3"
              onClick={handleDeleteCancel}
              disabled={savingUser}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={handleUserDelete}
              disabled={savingUser}>
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
