// const columnsArray = [
//     { name: 'Details', type: 'text' },
//     { name: 'Rate', type: 'number' },
//     { name: 'Qty', type: 'number' },
//     { name: 'Total', type: 'number' },
//     { name: 'Budget', type: 'number' }
// ];
export const activityArray = [
  { name: 'Activity Details', type: 'text' },
  { name: 'Select Start Date', type: 'date' },
  { name: 'Select End Date', type: 'date' },
  { name: 'Mark as Milestone', type: 'switch' }
];
export const projectIndicatorArray = [
  { name: 'INDICATORS ARRAY', type: 'text' },
  { name: 'UNIT OF MEASUREMENT', type: 'select' },
  { name: 'REPORTING FREQUENCY', type: 'select' },
  { name: 'BASELINE VALUE', type: 'number' },
  { name: 'TARGET VALUE', type: 'number' }
];
export const proposedBudget = [
  {
    field: 'sr-no',
    headerName: 'SR',
    width: 10,
    sortable: false,
    align: 'right'
  },
  {
    field: 'details',
    headerName: 'DETAILS',
    width: 100,
    sortable: false,
    align: 'right'
  },
  {
    field: 'expense_sub_category',
    headerName: '	EXPENSE SUB-CATEGORY',
    width: 120,
    sortable: false,
    align: 'right'
  },
  {
    field: 'rate',
    headerName: 'RATE',
    width: 100,
    sortable: false
  },
  {
    field: 'qty',
    headerName: 'QTY',
    width: 90,
    sortable: false
  },
  {
    field: 'metrics',
    headerName: 'METRICS',
    width: 90,
    sortable: false
  },
  {
    field: 'total',
    headerName: 'TOTAL',
    type: 'date',
    width: 120,
    sortable: false,
    editable: true
  },
  {
    field: 'budget',
    headerName: 'BUDGET',
    type: 'date',
    width: 120,
    sortable: false,
    editable: true
  },
  {
    field: 'justification',
    headerName: 'JUSTIFICATION',
    width: 100,
    sortable: false,
    align: 'right'
  }
];

export const activityPlan = [
  {
    field: 'details',
    headerName: 'ACTIVITY DETAILS',
    // width: auto,
    sortable: false,
    align: 'right'
  },
  {
    field: 'rate',
    headerName: 'PROPOSED START DATE',
    // width: 50,
    sortable: false
  },
  {
    field: 'qty',
    headerName: 'PROPOSED END DATE',
    // width: 200,
    sortable: false
  },
  {
    field: 'total',
    headerName: 'MARK  AS MILESTONE',
    type: 'date',
    // width: 120,
    sortable: false,
    editable: true
  }
];

export const projectIndicators = [
  {
    field: 'details',
    headerName: 'INDICATOR DESCRIPTION',
    width: '120',
    sortable: false,
    align: 'right'
  },
  {
    field: 'rate',
    headerName: 'UNIT OF MEASUREMENT',
    width: 50,
    sortable: false
  },
  {
    field: 'qty',
    headerName: 'REPORTING FREQUENCY',
    width: 100,
    sortable: false
  },
  {
    field: 'total',
    headerName: 'BASELINE VALUE',
    // type: 'date',
    width: 120,
    sortable: false,
    editable: true
  },
  {
    field: 'total',
    headerName: 'TARGET VALUE',
    // type: 'date',
    width: 120,
    sortable: false,
    editable: true
  }
];

export const compareBudget = [
  {
    field: 'sr-no',
    headerName: 'SR',
    width: 10,
    sortable: false,
    align: 'right'
  },
  {
    field: 'details',
    headerName: 'DETAILS',
    width: 100,
    sortable: false,
    align: 'right'
  },
  {
    field: 'expense_sub_category',
    headerName: '	PROPOSED EXPENSE SUB-CATEGORY',
    width: 120,
    sortable: false,
    align: 'right'
  },
  {
    field: 'expense_sub_category',
    headerName: 'ACTUAL	EXPENSE SUB-CATEGORY',
    width: 100,
    sortable: false,
    align: 'right'
  },
  {
    field: 'proposed_fund_utilisation',
    headerName: 'PROPOSED FUND UTILISATION',
    width: 100,
    sortable: false
  },
  {
    field: 'actual_fund_utilisation',
    headerName: 'ACTUAL FUND UTILISATION',
    width: 100,
    sortable: false
  },
  {
    field: 'actual_budget',
    headerName: '% OF ACTUAL BUDGET',
    type: 'date',
    width: 100,
    sortable: false,
    editable: true
  }
];
