import React, { useState } from 'react';
import FilePDF from '../../assets/images/pdf-red.png';
import dayjs from 'dayjs';
import { getCloudinaryUrlForImage } from '../../helpers/util';
import Modal from '../newDesign/Modals/Modal';
import './Modals/modal.css';
import AddUpdateModal from './Modals/AddUpdateModal';

function TimelineUpdates(props) {
  const [showTimeline, setShowTimeline] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [fileType, setFileType] = useState();
  const [filePreviewUrl, setFilePreviewUrl] = useState();

  let body = document.body;
  if (showTimeline === false && showPreview === false) {
    body.style.overflow = 'auto';
  }
  const hideTimeLine = () => {
    setShowTimeline(false);
  };
  const hidePreview = () => {
    setShowPreview(false);
  };
  const showFilePreview = (type, url) => {
    setFileType(type);
    if (
      type === 'doc' &&
      (url.includes('doc') ||
        url.includes('DOC') ||
        url.includes('docx') ||
        url.includes('DOCX') ||
        url.includes('xls') ||
        url.includes('XLS') ||
        url.includes('xlsx') ||
        url.includes('XLSX'))
    ) {
      setFilePreviewUrl(process.env.REACT_APP_DOC_FILE_PREVIEW_URL + url);
    } else {
      setFilePreviewUrl(url);
    }
    setShowPreview(true);
  };

  return (
    <div className="p-4 font-dmSans">
      <div
        className="w-100 bg-white modal-container"
        style={{ height: 'auto' }}>
        <div className="p-4" style={{ height: 'auto', width: '100%' }}>
          <div className="d-flex justify-content-between my-3 align-items-center">
            <div>
              <h3 className="fs-20 color-344256 m-0">Timeline &amp; Updates</h3>
            </div>
            {props.isUserAuthorized && (
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowTimeline(true);
                }}>
                Add Update
              </button>
            )}
          </div>
          <hr align="left" />
          {props?.timelines &&
            props?.timelines?.map((item, index) => {
              return (
                <div key={index} className="row">
                  {item?.project_images?.length > 0 ||
                  item?.project_video?.length > 0 ||
                  item?.project_docs?.length > 0 ||
                  !(item.note === '') ? (
                    <>
                      <div className="p-2 col-12">
                        <div className="d-flex justify-content-between">
                          {item?.project_images?.length > 0 ||
                          item?.project_video?.length > 0 ||
                          item?.project_docs?.length > 0 ||
                          !(item?.note === '') ? (
                            <h4
                              className={`w-10 p-3 mr-3 fs-14 color-81BC30 bullet-line-vert ${
                                props?.timelines?.length === index + 1
                                  ? 'bullet-last'
                                  : ''
                              } position-relative`}>
                              <div className="fw-600">
                                {dayjs(item.creation_date).format('DD MMM')}{' '}
                                {dayjs(item.creation_date).format('YYYY')}
                              </div>
                              {/* <div className="fw-600">
                              {dayjs(item.creation_date).format('YYYY')}
                            </div> */}
                            </h4>
                          ) : (
                            ''
                          )}
                          <div className="w-90 ml-5 mt-3">
                            <h4 className="fw-600">Milestone Achieved</h4>
                            {/* Notes */}

                            {!(item?.note === '') && (
                              <div className="mt-0">
                                <div className="fs-14 mt-0 color-344256">
                                  {item.note}
                                </div>
                              </div>
                            )}

                            {/* Photos and Videos */}

                            {(item?.project_images?.length > 0 ||
                              item?.project_video?.length > 0) && (
                              <div className="mt-4">
                                <h5 className="fs-14 fw-600">Media</h5>
                                <div className="d-flex justify-content-left mt-3">
                                  {item.project_images &&
                                    item?.project_images?.map(
                                      (img, imgIndex) => {
                                        return (
                                          <img
                                            src={
                                              getCloudinaryUrlForImage(
                                                img,
                                                200
                                              ) ||
                                              getCloudinaryUrlForImage(
                                                'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                                                200
                                              )
                                            }
                                            alt="images"
                                            width={50}
                                            height={50}
                                            className="mx-2 cursor-pointer img-cover"
                                            key={imgIndex}
                                            onClick={() =>
                                              showFilePreview('image', img)
                                            }
                                          />
                                        );
                                      }
                                    )}
                                  {item.project_video &&
                                    item.project_video?.map((vid, vidIndex) => {
                                      return (
                                        <>
                                          <video
                                            width={100}
                                            height={50}
                                            onClick={() =>
                                              showFilePreview('video', vid)
                                            }
                                            key={vidIndex}
                                            className="mx-2 cursor-pointer"
                                            preload="metadata">
                                            <source
                                              src={vid}
                                              type="video/mp4"
                                            />
                                          </video>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            )}

                            {/* Documents */}

                            {item?.project_docs &&
                              item?.project_docs?.length > 0 && (
                                <div className="mt-4">
                                  <h5 className="fs-14 fw-600">Documents</h5>
                                  <div className="d-flex justify-content-left mt-3">
                                    {item.project_docs &&
                                      item.project_docs?.map(
                                        (doc, docIndex) => {
                                          return (
                                            <>
                                              <img
                                                src={FilePDF}
                                                alt="images"
                                                width={17}
                                                height={22}
                                                className="mx-2 cursor-pointer"
                                                onClick={() =>
                                                  showFilePreview('doc', doc)
                                                }
                                                key={docIndex}
                                              />
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
        </div>

        <Modal
          show={showTimeline || showPreview}
          formFieldExists={true}
          title={'Add Update'}
          handleCloseModal={showTimeline ? hideTimeLine : hidePreview}
          sizeClass="modal-lg">
          {showTimeline && (
            <AddUpdateModal
              createTimeline={props.createTimeline}
              setShowTimeline={setShowTimeline}
              userProfile={props.userProfile}
              getTimeline={props.getTimeline}
            />
          )}

          {showPreview && fileType === 'image' && (
            <div className="text-center mb-4">
              <img src={filePreviewUrl} alt="file" width={300}></img>
            </div>
          )}
          {showPreview && fileType === 'video' && (
            <div className="text-center mb-4">
              <video
                height={300}
                src={filePreviewUrl}
                controls
                autoPlay
                loop
                className="w-100 mr-2"
                preload="metadata">
                <source src={filePreviewUrl} type="video/mp4" />
              </video>
            </div>
          )}
          {showPreview && fileType === 'doc' && (
            <div style={{ height: '100vh' }}>
              <iframe
                src={filePreviewUrl}
                title="file"
                width="100%"
                height="95%"></iframe>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default TimelineUpdates;
