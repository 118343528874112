import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Routes } from '../../constants/path.constants';
import {
  getFormattedDate,
  getCloudinaryUrlForImage,
  getCurrencyIcon
} from '../../helpers/util';
import {
  getMission,
  getTransactionsByMissionId,
  getRegionalSettings
} from '../../actions';
import { debounce } from 'lodash';
import Pagination from '../paginationFullRow';
import { CSVLink } from 'react-csv';
import { convertInCommaFormat } from '../../helpers/commaSeparatedInput';
import TablePledgeSummary from './TablePledgeSummary';
import CustomDatePicker from './customDatePicker';

const MissionPledgeSummary = props => {
  const searchInputRef = useRef(null);
  const currentDate = new Date();
  const lastMonthDate = new Date();
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

  const [state, setState] = useState({
    missionInfo: null,
    dateRangeArray: [
      {
        date: lastMonthDate
          .getDate()
          .toString()
          .padStart(2, '0'),
        month: (lastMonthDate.getMonth() + 1).toString().padStart(2, '0'),
        year: lastMonthDate.getFullYear().toString()
      },
      {
        date: currentDate
          .getDate()
          .toString()
          .padStart(2, '0'),
        month: (currentDate.getMonth() + 1).toString().padStart(2, '0'),
        year: currentDate.getFullYear().toString()
      }
    ],
    filteredAllList: [],
    filteredPageList: [],
    allPledgeList: [],
    occurrence: '',
    currentPage: 1,
    postsPerPage: 10,
    filteredDataCsv: [],
    currencyData: null
  });
  const getDateFromString = (dateString, type) => {
    const [day, month, year] = dateString.split('/').map(Number);
    let date = new Date(year, month - 1, day);
    if (type === 'end') {
      date.setHours(23, 59, 59);
    } else {
      date.setHours(0, 0, 0);
    }
    return date;
  };

  const convertToStartDateEndDate = dateArray => {
    const convertToDate = date =>
      new Date(date.year, date.month - 1, date.date);

    const startDate = convertToDate(dateArray[0]);
    const endDate = convertToDate(dateArray[1]);

    const formatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const startDateFormatted = startDate.toLocaleDateString(
      'en-CA',
      formatOptions
    );
    const endDateFormatted = endDate.toLocaleDateString('en-CA', formatOptions);
    return { startDate: startDateFormatted, endDate: endDateFormatted };
  };

  const filterData = () => {
    let filteredList = state.allPledgeList.slice();
    if (state.occurrence !== '' && state.occurrence !== 'Please select') {
      filteredList = filteredList.filter(
        value => value.pledge_type === state.occurrence.toUpperCase()
      );
    }

    if (state.dateRangeArray.length > 0) {
      const dateRange = convertToStartDateEndDate(state.dateRangeArray);
      filteredList = filteredList.filter(mission => {
        const rangeDate1 = getDateFromString(
          `${state.dateRangeArray[0]?.month}/${state.dateRangeArray[0]?.date}/${state.dateRangeArray[0]?.year}`
        );
        const rangeDate2 = getDateFromString(
          `${state.dateRangeArray[1]?.month}/${state.dateRangeArray[1]?.date}/${state.dateRangeArray[1]?.year}`,
          'end'
        );
        const pledgedOnDate = new Date(mission.transaction_date);
        if (
          pledgedOnDate.getTime() >= rangeDate1.getTime() &&
          pledgedOnDate.getTime() <= rangeDate2.getTime()
        ) {
          return true;
        }
        return false;
      });

      setState({
        ...state,
        filteredAllList: filteredList,
        dateRangeArray: [dateRange.startDate, dateRange.endDate],
        currentPage: 1
      });
    } else {
      setState({
        ...state,
        filteredAllList: filteredList,
        currentPage: 1
      });
    }
  };
  const handleDateRange = range => {
    if (state.currentPage !== 1) {
      setState({ ...state, currentPage: 1 });
    }
    if (range === '') {
      setState({ ...state, dateRangeArray: [] }, () => {
        filterData();
        const { id } = props;
        let ngoId = props.userProfile.orgId || props.userProfile.sub;
        const { search, dateRangeArray } = state;

        // Send the default date range directly without conversion
        const [startDate, endDate] = dateRangeArray;
        getDonors(id, ngoId, search, startDate, endDate);
      });
    } else {
      setState(
        {
          ...state,
          dateRangeArray: [
            {
              date: range[0].split('/')[0],
              month: range[0].split('/')[1],
              year: range[0].split('/')[2]
            },
            {
              date: range[1].split('/')[0],
              month: range[1].split('/')[1],
              year: range[1].split('/')[2]
            }
          ]
        },
        () => {
          filterData();
          const { id } = props;
          let ngoId = props.userProfile.orgId || props.userProfile.sub;
          const [startDate, endDate] = state.dateRangeArray.map(
            date =>
              `${date.year}-${date.month.padStart(2, '0')}-${date.date.padStart(
                2,
                '0'
              )}`
          );
          getDonors(id, ngoId, state.search, startDate, endDate);
        }
      );
    }
  };

  const paginationRecords = data => {
    let currentTableData = [];
    if (data && data.length > 0) {
      const lastPageIndex = state.currentPage * state.postsPerPage;
      const firstPageIndex = lastPageIndex - state.postsPerPage;
      currentTableData = data.slice(firstPageIndex, lastPageIndex);
    }
    return currentTableData;
  };

  const onPageChange = option => {
    if (option[0] === 'Prev') {
      setState({ ...state, currentPage: state.currentPage - 1 });
    } else if (option[0] === 'Next') {
      setState({ ...state, currentPage: state.currentPage + 1 });
    } else if (option[0] === 'postsPerPage') {
      setState({ ...state, postsPerPage: option[1], currentPage: 1 });
    } else if (option[0] === 'currentPage') {
      setState({ ...state, currentPage: option[1] });
    }
  };

  const checkInput = event => {
    if (event.key === 'Enter') {
      callSearchCorp(event);
    }
  };

  const componentDidMount = () => {
    props.getMission(props.id, 'detail');
    props.getRegionalSettings(props.userProfile.sub);
    let ngoId = props.userProfile.orgId || props.userProfile.sub;
    if (state.dateRangeArray[0] && state.dateRangeArray[1]) {
      const [startDate, endDate] = state.dateRangeArray;
      getDonors(props.id, ngoId, state.searchTerm, startDate, endDate);
    } else {
      getDonors(props.id, ngoId, state.searchTerm);
    }
    setState({
      ...state,
      filteredPageList: paginationRecords(state.filteredAllList)
    });
  };

  useEffect(() => {
    componentDidMount();
  }, [state.dateRangeArray, state.searchTerm]);

  useEffect(() => {
    if (props.missionInfo) {
      setState({
        ...state,
        missionInfo: props.missionInfo,
        filteredAllList: props.missionTransactions,
        filteredPageList: props.missionTransactions,
        allPledgeList: props?.missionTransactions,
        currencyData: props.currency
      });
    } else {
      if (
        props.missionDetailErrorResponse &&
        props.missionDetailErrorResponse.code
      ) {
        toast.error('Some error occurred, please try again in sometime');
      }
    }
  }, [
    props.missionInfo,
    props.missionTransactions,
    props.missionDetailErrorResponse
  ]);

  const getDonors = (id, ngoId, search, start_date, end_date) => {
    const searchTerm = search || '';
    const startDate =
      start_date !== undefined ? formatDate(start_date) : start_date || '';
    const endDate =
      end_date !== undefined ? formatDate(end_date) : end_date || '';
    props.getTransactionsByMissionId(id, ngoId, searchTerm, startDate, endDate);
  };

  const formatDate = dateObj => {
    if (!dateObj || !dateObj.date || !dateObj.month || !dateObj.year) {
      return '';
    }
    return `${dateObj.year}-${dateObj.month.padStart(
      2,
      '0'
    )}-${dateObj.date.padStart(2, '0')}`;
  };

  const callSearchCorp = event => {
    toast.dismiss();
    const searchValue = event.target.value;
    if (searchValue.length >= 0) {
      let ngoId = props.userProfile.orgId || props.userProfile.sub;
      getDonors(
        props.id,
        ngoId,
        searchValue,
        state.dateRangeArray[0],
        state.dateRangeArray[1]
      );
    }
  };
  let currSymbol = props.currency;
  let currencySymb = getCurrencyIcon(currSymbol);

  const filterCsvData = state?.allPledgeList || [];

  const extractedData = filterCsvData?.map((item, index) => ({
    Sr_no: index + 1,
    First_name: item.first_name,
    Last_name: item.last_name,
    Personal_email: item.personal_email,
    Corp_email: item.corp_email,
    Pan_number: item.pan_number,
    [`Amount ( ${currencySymb})`]: convertInCommaFormat(item.amount),
    Donation_date: getFormattedDate('dd/MM/yyyy', item?.transaction_date)
  }));

  const debouncedHandleChangeX = debounce(e => {
    callSearchCorp(e);
  }, 300);

  const totalRecords = state.filteredAllList?.length || 0;
  const postsPerPageData = paginationRecords(state.filteredAllList);

  return (
    <>
      <div className="row justify-content-center bg-light-gray p-4">
        <div className="col-12 p-0">
          <h5 className="mb-4">
            <Link
              className="text-1FA4FA fs-12 h-lh-12"
              to={`/ngo/missions/list`}>
              Missions
            </Link>
            &nbsp;&nbsp;
            <span className="text-1FA4FA fs-12 h-lh-12">&gt;</span>
            &nbsp;&nbsp;
            <Link
              className="text-1FA4FA fs-12 h-lh-12"
              to={`${Routes.NGO.MISSION_DETAILS}/${state.missionInfo?.mission_id}`}>
              {state.missionInfo?.mission_title}
            </Link>
            &nbsp;&nbsp;
            <span className="text-1FA4FA fs-12 h-lh-12">&gt;</span>
            &nbsp;&nbsp;
            <span className="text-7B8FA6 fs-12 h-lh-12">Donation Summary</span>
          </h5>
        </div>
        <div className="col-12 bg-white card-box-shadow p-0 mb-5 rounded-8">
          <div className="super-wide w-100 h-0 position-relative rounded-top-8 loading-bg">
            {state.missionInfo && state.missionInfo.image_url && (
              <img
                src={
                  getCloudinaryUrlForImage(state.missionInfo.image_url, 500) ||
                  getCloudinaryUrlForImage(
                    'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                    500
                  )
                }
                data-src={
                  state.missionInfo.image_url ||
                  'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                }
                onError={e =>
                  (e.target.src = e.target.getAttribute('data-src'))
                }
                alt={`${state.missionInfo.mission_title} mission`}
                className="abs-full rounded-top-8 preview-image"
              />
            )}
          </div>
          <div className="p-4 row">
            <div className="col-12 px-0 mt-2 mb-3 d-flex align-items-center">
              <p className="h2 px-0 mt-2 text-bold text-1C1C1C text-break">
                Donation Summary:&nbsp;{state.missionInfo?.mission_title}
              </p>
            </div>
            <div className="row px-0 d-flex mt-4">
              <div className="pr-4 ">
                <CustomDatePicker
                  handleDateRange={handleDateRange}
                  dateRangeArray={state.dateRangeArray}
                />
              </div>
              <div
                className="d-flex col-3 position-relative"
                style={{ left: '5%' }}>
                <input
                  type="text"
                  autoComplete="off"
                  name="search"
                  placeholder="Search"
                  ref={searchInputRef}
                  onChange={e => {
                    e.persist();
                    debouncedHandleChangeX(e);
                    if (state.currentPage !== 1) {
                      setState({ ...state, currentPage: 1 });
                    }
                  }}
                  onKeyDown={checkInput}
                  className="standard-search-style col-9"
                />

                <button
                  type="button"
                  className="btn position-relative"
                  style={{ right: '30px' }}>
                  <i className="fa fa-search form-control-feedback"></i>
                </button>
              </div>
              <div className="d-flex text-right position-relative ml-auto mr-2">
                <CSVLink
                  className="btn btn-outline-primary btn-lg mr-2 px-4 fs-14 dm-bold "
                  filename={'Donation Summary.csv'}
                  data={extractedData ? extractedData : []}>
                  Export
                </CSVLink>
              </div>
            </div>
            <div className="col-12 px-0 mt-3">
              <TablePledgeSummary
                postsPerPage={postsPerPageData}
                currencySymb={currencySymb}
              />
              <div>
                <Pagination
                  pageSize={state.postsPerPage}
                  totalCount={totalRecords}
                  currentPage={state.currentPage}
                  onPageChange={onPageChange}
                  postsPerPageSelectArray={[10, 20, 30, 40, 50]}></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  missionInfo: state.missionData.missionDetailSuccessResponse,
  missionTransactions: state.missionData.missionTransactionSuccess,
  previewMissionErrorResponse: state.missionData.missionDetailErrorResponse,
  userProfile: state.login.userInfo,
  currency: state?.currencyData?.currencyData?.currency
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getMission, getTransactionsByMissionId, getRegionalSettings },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissionPledgeSummary);
