import React from 'react';

const TooltipList = ({
  list,
  max,
  showLabel,
  label,
  labelClass,
  wrapperClass,
  listClass,
  moreClass
}) => {
  let moreCount = list.length > max ? list.length - max : 0;
  let moreList = '';

  if (moreCount) {
    list.map((item, index) => {
      if (index > max - 1)
        moreList += `${item.city}${
          list.length !== index + 1 && index < list.length - 1 ? ', ' : '.'
        }`;
    });
  }

  const getPunctuation = index =>
    index + 1 < max && index < list.length - 1 ? ', ' : ' ';

  return (
    <span className={`d-flex justify-content-between ${wrapperClass || ' '}`}>
      <span className={listClass}>
        {showLabel && <span className={labelClass}>{label} :&nbsp;</span>}
        {list.map((item, index) => {
          if (index + 1 <= max) {
            return `${item.city}${getPunctuation(index)}`;
          }
          return false;
        })}
      </span>
      {moreCount > 0 && (
        <span className={moreClass}>
          +{moreCount} more
          <span className=" tooltipss-location">{moreList}</span>
        </span>
      )}
    </span>
  );
};
export default TooltipList;
