import React, { useEffect, useState } from 'react';
import CheckboxList from './corpCheckBox';
import { isAuthorized } from '../../helpers/auth-roles';

const MultiSelect = ({
  ngoStatus,
  input,
  key,
  stepIndex,
  disabled,
  labelText,
  required,
  state,
  fieldUpdated,
  locationUpdated,
  areaOptions: StateList,
  selectedData,
  isError,
  permissions
}) => {
  const isImpersonator = window.location.href.includes('email=');
  const [selectedItems, setSelectedItem] = useState([]);
  const [options, setOptions] = useState(StateList);
  const [searchText, setSearch] = useState('');
  useEffect(() => {}, [isError]);
  useEffect(() => {
    setOptions(StateList);
  }, [StateList]);

  useEffect(() => {
    setSelectedItem(selectedData);
  }, [selectedData]);

  const renderStateList = () => {
    return (
      <CheckboxList
        options={options}
        itemsInEachCol={Math.ceil(StateList?.length / 3) || 1}
        type={'This Project is to be published for :'}
        selectedItems={selectedItems}
        onChange={selectedData => {
          setSelectedOptions(selectedData);
        }}
      />
    );
  };

  const searchOptions = () => {};
  const setSelectedOptions = selectedData => {
    fieldUpdated(selectedData);
  };

  //   const clearSelection = () => {
  //     setSelectedItem([]);
  //     setSearch('');
  //   };

  const searchStateByName = (stateArray, name) => {
    // Convert the name to lowercase for case-insensitive search
    const lowerCaseName = name.toLowerCase();

    // Use the Array.find() method to search for the first state that matches the name
    const matchingStates = stateArray.filter(state =>
      state.name.toLowerCase().includes(lowerCaseName)
    );

    return matchingStates;
  };

  useEffect(() => {
    // const newSelectedItems = selectedItems;
    if (searchText) {
      const newState = searchStateByName(StateList, searchText);
      // setSelectedItem([]);
      setOptions(newState);
    } else {
      // setSelectedItem([]);
      setOptions(StateList);
    }
    //setSelectedItem(newSelectedItems);
  }, [searchText]);

  const renderValidationError = () => {
    if (isError && selectedItems.length === 0) {
      return <p className={`text-danger fs-12 my-2`}>{isError}</p>;
    }
  };

  return (
    <>
      <div className="form-group">
        <label className={``}>
          {labelText} {required ? <span className="color-FF2300">*</span> : ' '}
        </label>
        <button
          className="btn btn-dropdown ht-36 mt-3"
          type="button"
          id={'Select_Corporates'}
          disabled={
            permissions && !isAuthorized(permissions) && !isImpersonator
          }
          data-toggle="dropdown">
          <span className="float-left ">Selected</span>
          <div className=" float-right dropdown-toggle">
            {selectedItems?.length ? (
              <span className=" state-dropdown-count">
                {selectedItems?.length}
              </span>
            ) : null}
          </div>
        </button>
        {renderValidationError()}
        <div
          id={`show_Select_Corporates`}
          className={`dropdown-menu col-12 px-0 `}
          aria-labelledby="dropdownMenu2">
          <p className="text-1C1C1C fs-14 fw-500 px-4 mt-4">
            Select Corporates
          </p>
          <div className="px-4">
            <div className="col-12 position-relative rounded p-0 d-flex justify-content-center">
              <span className="form-group col-12 rounded p-0">
                <div
                  type="button"
                  className="btn position-absolute"
                  style={{ right: '0px', top: '4px', zIndex: '1' }}
                  onClick={() => searchOptions()}>
                  <i className="fa fa-search form-control-feedback"></i>
                </div>

                <input
                  type="text"
                  name="search"
                  value={searchText}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Search"
                  className="col-12 standard-search-style rounded"
                />
              </span>
            </div>
          </div>

          <div
            className={`col-12 px-0 ${
              StateList?.length > 9 ? 'dropDownHeight' : ''
            }`}>
            {renderStateList()}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiSelect;
