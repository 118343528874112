import { Action, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';
export const getNgoProfile = ngoId => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  instance
    .get(`${EndPoint.getApiUrl('NGO', 'NGO_PROFILE', 'HIROKU')}`)
    .then(response => {
      dispatch({
        type: Action.NGO_PROFILE_SUCCESS,
        payload: response.data
      });
      const policies = window.localStorage.getItem('userPolicies');
      if (!policies) {
        window.localStorage.setItem(
          'userPolicies',
          JSON.stringify(response.data.policies)
        );
        window.location.reload();
      }
      window.localStorage.setItem(
        'ngoId',
        JSON.stringify(response.data.ngo_id)
      );
      // dataCheck = response.data;
    });
};

// export const getNGOTransactions = () => dispatch => {
//   let instance = HttpRequest.getInstance('HIROKU');
//   instance
//     .get(EndPoint.getApiUrl('NGO', 'GET_NGO_TRANSACTIONS', 'HIROKU'))
//     .then(response => {
//       dispatch({
//         type: Action.TRANSACTIONS_LIST_SUCCESS,
//         payload: response.data
//       });
//       let list = response.data;
//       return list;
//     });
// };

export const getNGOTransactions = () => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('SOCIONEXT');
    instance
      .get(EndPoint.getApiUrl('NGO', 'GET_NGO_TRANSACTIONS', 'HIROKU'))
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getHeaderData = () => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  if (window.location.href.includes('email=')) {
    let email = window.location.href.split('email=')[1];

    return new Promise((resolve, reject) => {
      let apiUrl = EndPoint.getApiUrl('NGO', 'HEADER', 'HIROKU');
      apiUrl = apiUrl + '?email=' + `${email}`;
      instance
        .get(apiUrl)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      instance
        .get(`${EndPoint.getApiUrl('NGO', 'HEADER', 'HIROKU')}`)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export const getCommentsByNgoId = id => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'GET_NGO_COMMENTS', 'HIROKU');
  apiUrl = apiUrl + `${id}`;
  if (window.location.href.includes('email=')) {
    let email = window.location.href.split('email=')[1];

    return new Promise((resolve, reject) => {
      apiUrl = apiUrl + '?email=' + `${email}`;
      instance
        .get(apiUrl)
        .then(response => {
          if (response.status === 200) {
            resolve(response.data.result);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  } else {
    let APIUrl = EndPoint.getApiUrl('NGO', 'GET_VENDOR_COMMENTS', 'HIROKU');
    APIUrl += `${id}`;
    return new Promise((resolve, reject) => {
      instance
        .get(APIUrl)
        .then(response => {
          if (response.status === 200) {
            resolve(response.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export const postCommentsById = (id, data) => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'POST_VENDOR_COMMENTS', 'HIROKU');
  apiUrl = apiUrl + `${id}`;
  return new Promise((resolve, reject) => {
    instance
      .post(apiUrl, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getNGOCorpForPublished = (ngoId, emailId) => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('HIROKU');
    var params;
    if (ngoId) {
      params = 'ngoId=' + ngoId;
    } else {
      params = 'email=' + emailId;
    }

    instance
      .get(
        `${EndPoint.getApiUrl(
          'NGO',
          'GETPUBLISHEDFORCORP',
          'HIROKU'
        )}?${params}`
      )
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getDonationCorpList = ngoId => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('WORKPLACE');
    instance
      .get(
        `${EndPoint.getApiUrl('NGO', 'CORPLIST', 'WORKPLACE')}?ngo_id=${ngoId}`
      )
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getDonationMissionList = ngoId => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('WORKPLACE');
    instance
      .get(
        `${EndPoint.getApiUrl('NGO', 'MISSIONLIST', 'HIROKU')}?ngo_id=${ngoId}`
      )
      .then(res => {
        if (res.status === 200) {
          resolve(res.data?.result);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getDonationReceiptUploadedData = ngoId => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('WORKPLACE');
    instance
      .get(
        `${EndPoint.getApiUrl(
          'NGO',
          'DONATION_RECEIPT_UPLOADED_DATA',
          'HIROKU'
        )}?ngo_id=${ngoId}`
      )
      .then(res => {
        if (res.status === 200) {
          resolve(res.data?.result);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const postDonationReceiptUploadedData = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl(
    'NGO',
    'POST_DONATION_RECEIPT_UPLOADED_DATA',
    'HIROKU'
  );
  return new Promise((resolve, reject) => {
    instance
      .post(apiUrl, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
