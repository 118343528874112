/* Project Data */

export const projectColumn = [
  { field: 'id', headerName: 'ID', width: 10, sortable: false },
  {
    field: 'project',
    headerName: 'Project',
    width: 330,
    sortable: false
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 110,
    sortable: false
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    sortable: false
  }
];

/* All Projects Data */

export const allProjectColumn = [
  { field: 'id', headerName: 'ID', width: 10 },
  {
    field: 'project',
    headerName: 'Project',
    width: 370,
    sortable: false
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 115,
    sortable: false
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 110,
    sortable: false
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 80,
    sortable: false
  },
  {
    field: 'allocatedFunds',
    headerName: 'Allocated Fund',
    width: 110,
    sortable: false,
    align: 'center'
  },
  {
    field: 'view',
    headerName: '',
    width: 10,
    sortable: false,
    align: 'center'
  }
];

export const fundColumn = [
  {
    field: 'expanseCategory',
    headerName: 'Expense Category',
    headerAlign: 'center',
    width: 150,
    sortable: false,
    align: 'center',
    required: true
  },
  {
    field: 'expanseSubCategory',
    headerName: 'Expense Sub-Category',
    headerAlign: 'center',
    width: 150,
    sortable: false,
    align: 'center'
    //required: true
  },
  {
    field: 'expense',
    headerName: 'Details',
    width: 100,
    sortable: false,
    required: true
  },
  {
    headerName: 'Expense Type',
    width: 150,
    sortable: false,
    align: 'center'
  },
  {
    field: 'amount',
    headerName: 'Amount',
    headerAlign: 'center',
    width: 120,
    sortable: false,
    align: 'center',
    required: true
  },
  {
    field: 'created_date',
    headerName: 'Date of Expense',
    headerAlign: 'center',
    width: 120,
    sortable: false,
    align: 'center',
    required: true
  },
  {
    field: 'receipt',
    headerName: 'Receipt',
    width: 100,
    sortable: false,
    align: 'right'
  }
];

/* Activities Data */

export const activitiesColumn = [
  { field: 'id', headerName: 'ID', width: 5, sortable: false },
  {
    field: 'flag',
    headerName: '',
    width: 10,
    sortable: false,
    align: 'right'
  },
  // {
  //   field: 'phase',
  //   headerName: 'Quarter',
  //   width: 50,
  //   sortable: false
  // },
  {
    field: 'activity',
    headerName: 'Activity',
    width: 280,
    sortable: false
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    type: 'date',
    width: 120,
    sortable: false,
    editable: true
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    type: 'date',
    width: 120,
    sortable: false,
    editable: true
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 60,
    sortable: false
  },
  {
    field: 'delayedByDays',
    headerName: 'Delayed by (days)',
    width: 110,
    sortable: false
  }
];

export const activitiesRow = [
  {
    id: 1,
    flag: '',
    phase: 'Phase 1',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '02-03-2022',
    endDate: '22-03-2022',
    status: 'Upcoming',
    delayedByDays: '03'
  },
  {
    id: 2,
    flag: 'active',
    phase: 'Phase 2',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '02-03-2022',
    endDate: '22-03-2022',
    status: 'Delayed',
    delayedByDays: '--'
  },
  {
    id: 3,
    flag: 'active',
    phase: 'Phase 2',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '02-03-2022',
    endDate: '22-03-2022',
    status: 'Delayed',
    delayedByDays: '03'
  },
  {
    id: 4,
    flag: '',
    phase: 'Phase 1',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '02-03-2022',
    endDate: '22-03-2022',
    status: 'Upcoming',
    delayedByDays: '--'
  },
  {
    id: 5,
    flag: 'active',
    phase: 'Phase 2',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '02-03-2022',
    endDate: '22-03-2022',
    status: 'In Progress',
    delayedByDays: '03'
  },
  {
    id: 6,
    flag: '',
    phase: 'Phase 1',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '02-03-2022',
    endDate: '22-03-2022',
    status: 'In Progress',
    delayedByDays: '03'
  },
  {
    id: 7,
    flag: 'active',
    phase: 'Phase 2',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '02-03-2022',
    endDate: '22-03-2022',
    status: 'Delayed',
    delayedByDays: '--'
  }
];

export const editActivitiesColumn = [
  {
    field: 'milestone',
    headerName: 'Milestone',
    width: 10,
    sortable: false,
    align: 'right'
  },
  // {
  //   field: 'quarter',
  //   headerName: 'Quarter',
  //   width: 50,
  //   sortable: false
  // },
  {
    field: 'activity',
    headerName: 'Activity',
    width: 200,
    sortable: false
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    type: 'date',
    width: 120,
    sortable: false,
    editable: true
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    type: 'date',
    width: 120,
    sortable: false,
    editable: true
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 60,
    sortable: false
  },
  // {
  //   field: 'attachments',
  //   headerName: 'Attachments',
  //   type: 'file',
  //   width: 110,
  //   sortable: false
  // },
  {
    field: 'status',
    headerName: 'Change Status',
    width: 100,
    sortable: false
  }
];

export const editActivitiesRow = [
  {
    id: 1,
    flag: '',
    phase: 'Phase 1',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '2022-01-21',
    endDate: '2022-01-22',
    status: 'Upcoming',
    attachments: ''
  },
  {
    id: 2,
    flag: 'active',
    phase: 'Phase 2',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '2022-01-21',
    endDate: '2022-01-22',
    status: 'Delayed',
    attachments: ''
  },
  {
    id: 3,
    flag: 'active',
    phase: 'Phase 2',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '2022-01-21',
    endDate: '2022-01-22',
    status: 'Delayed',
    attachments: ''
  },
  {
    id: 4,
    flag: '',
    phase: 'Phase 1',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '2022-01-21',
    endDate: '2022-01-22',
    status: 'Upcoming',
    attachments: ''
  },
  {
    id: 5,
    flag: 'active',
    phase: 'Phase 2',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '2022-01-21',
    endDate: '2022-01-22',
    status: 'In Progress',
    attachments: ''
  },
  {
    id: 6,
    flag: '',
    phase: 'Phase 1',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '2022-01-21',
    endDate: '2022-01-22',
    status: 'In Progress',
    attachments: ''
  },
  {
    id: 7,
    flag: 'active',
    phase: 'Phase 2',
    activity:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    startDate: '2022-01-21',
    endDate: '2022-01-22',
    status: 'Delayed',
    attachments: ''
  }
];

export const goalsColumn = [
  {
    field: 'indicatorDescription',
    headerName: 'Indicator Description',
    width: 180,
    sortable: false,
    align: 'right'
  },
  {
    field: 'unitMeasurement',
    headerName: 'Unit of Measurement',
    width: 110,
    sortable: false
  },
  {
    field: 'frequency',
    headerName: 'Reporting Frequency',
    width: 70,
    sortable: false
  },
  {
    field: 'baselineValue',
    headerName: 'Baseline Value',
    width: 80,
    sortable: false
  },
  {
    field: 'targetValue',
    headerName: 'Target Value',
    width: 70,
    sortable: false
  },
  {
    field: 'lastTargetUpdate',
    headerName: 'Achieved Target Till Date',
    width: 100,
    sortable: false
  },
  {
    field: 'achievedTarget',
    headerName: 'Achieved Target',
    type: 'number',
    width: 90,
    sortable: false,
    editable: true
  },
  // {
  //   field: 'indicatorDate',
  //   headerName: 'Achieved Target Date',
  //   width: 120,
  //   sortable: false,
  //   editable: true
  // },
  // {
  //   field: 'attachments',
  //   headerName: 'Attachments',
  //   type: 'file',
  //   width: 80,
  //   sortable: false
  // },
  {
    field: 'history',
    headerName: '',
    width: 40,
    sortable: false
  }
];

export const goalsRow = [
  {
    indicatorDescription:
      'liquam faucibus purus in massa tempor. Sodales ut etiam sit amet.',
    unitMeasurement: 'Number of People',
    baselineValue: 100,
    targetValue: 200000,
    lastTargetUpdate: 120000,
    achievedTarget: 197000,
    attachments: 'Upload',
    history: 'View'
  },
  {
    indicatorDescription:
      'liquam faucibus purus in massa tempor. Sodales ut etiam sit amet.',
    unitMeasurement: 'Number of People',
    baselineValue: 100,
    targetValue: 200000,
    lastTargetUpdate: 120000,
    achievedTarget: 197000,
    attachments: 'Upload',
    history: 'View'
  },
  {
    indicatorDescription:
      'liquam faucibus purus in massa tempor. Sodales ut etiam sit amet.',
    unitMeasurement: 'Number of People',
    baselineValue: 100,
    targetValue: 200000,
    lastTargetUpdate: 120000,
    achievedTarget: 197000,
    attachments: 'Upload',
    history: 'View'
  },
  {
    indicatorDescription:
      'liquam faucibus purus in massa tempor. Sodales ut etiam sit amet.',
    unitMeasurement: 'Number of People',
    baselineValue: 100,
    targetValue: 200000,
    lastTargetUpdate: 120000,
    achievedTarget: 197000,
    attachments: 'Upload',
    history: 'View'
  },
  {
    indicatorDescription:
      'liquam faucibus purus in massa tempor. Sodales ut etiam sit amet.',
    unitMeasurement: 'Number of People',
    baselineValue: 100,
    targetValue: 200000,
    lastTargetUpdate: 120000,
    achievedTarget: 197000,
    attachments: 'Upload',
    history: 'View'
  },
  {
    indicatorDescription:
      'liquam faucibus purus in massa tempor. Sodales ut etiam sit amet.',
    unitMeasurement: 'Number of People',
    baselineValue: 100,
    targetValue: 200000,
    lastTargetUpdate: 120000,
    achievedTarget: 197000,
    attachments: 'Upload',
    history: 'View'
  },
  {
    indicatorDescription:
      'liquam faucibus purus in massa tempor. Sodales ut etiam sit amet.',
    unitMeasurement: 'Number of People',
    baselineValue: 100,
    targetValue: 200000,
    lastTargetUpdate: 120000,
    achievedTarget: 197000,
    attachments: 'Upload',
    history: 'View'
  }
];

export const historyColumn = [
  {
    headerName: 'Month',
    width: 150,
    sortable: false
  },
  {
    field: 'dateUpdated',
    headerName: 'Date Updated',
    width: 150,
    sortable: false
  },
  {
    field: 'achieved_target',
    headerName: 'Achieved Value',
    width: 150,
    sortable: false
  }
  // {
  //   field: 'attachments',
  //   headerName: 'Attachments',
  //   width: 150,
  //   sortable: false
  // }
];

export const historyRow = [
  {
    dateUpdated: '05/03/2022',
    targetValue: 200000,
    attachments: 'Preview'
  },
  {
    dateUpdated: '05/03/2022',
    targetValue: 200000,
    attachments: 'Preview'
  },
  {
    dateUpdated: '05/03/2022',
    targetValue: 200000,
    attachments: 'Preview'
  },
  {
    dateUpdated: '05/03/2022',
    targetValue: 200000,
    attachments: 'Preview'
  },
  {
    dateUpdated: '05/03/2022',
    targetValue: 200000,
    attachments: 'Preview'
  },
  {
    dateUpdated: '05/03/2022',
    targetValue: 200000,
    attachments: 'Preview'
  },
  {
    dateUpdated: '05/03/2022',
    targetValue: 200000,
    attachments: 'Preview'
  }
];

export const expenseColumn = [
  {
    field: 'expenseSummary',
    headerName: 'Expense Summary',
    width: 450,
    sortable: false
  },
  {
    field: 'expenseCategory',
    headerName: 'Expense Category',
    width: 150,
    sortable: false
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 150,
    sortable: false
  },
  {
    field: 'receipt',
    headerName: 'Receipt',
    width: 150,
    sortable: false
  }
];

export const expenseRow = [
  {
    expenseSummary:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    expenseCategory: 'Direct',
    amount: 125000,
    receipt: 'Upload'
  },
  {
    expenseSummary:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    expenseCategory: 'Direct',
    amount: 125000,
    receipt: 'Upload'
  },
  {
    expenseSummary:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    expenseCategory: 'Direct',
    amount: 125000,
    receipt: 'Upload'
  },
  {
    expenseSummary:
      'Morbi tincidunt ornare massa eget egestas purus. Rhoncus dolor purus.',
    expenseCategory: 'Direct',
    amount: 125000,
    receipt: 'Upload'
  }
];
