import React, { useState } from 'react';
import './Styles.css';
import Table from './commonTable';
import {
  activitiesColumn,
  editActivitiesColumn
} from '../../constants/MNEDashboard';
import dayjs from 'dayjs';
import { toast } from 'react-hot-toast';
import Modal from '../../components/newDesign/Modals/Modal';

export default function AllActivites(props) {
  const columns = activitiesColumn;
  const editColumn = editActivitiesColumn;
  const [projectStatus, setProjectStatus] = useState('allActivities');
  const [updatedRows, setUpdatedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activityType, setActivityType] = useState('');
  const [activityId, setActivityId] = useState('');

  const rowUpdated = rowId => {
    const row_id = updatedRows.findIndex(index => index === rowId);

    if (row_id === -1) {
      const updatedRowsArr = updatedRows.slice();
      updatedRowsArr.push(rowId);
      setUpdatedRows(updatedRowsArr);
    }
  };
  const handleChange = (e, id) => {
    rowUpdated(id);
    const tempActivityList = props.activityList.slice();
    const row = tempActivityList.find(x => x.id === id);
    if (row) {
      row[e.target.id] = dayjs(e.target.value).format('YYYY-MM-DD');
    }
  };

  // const submitActivities = e => {
  //   e.preventDefault();

  //   const payloadArr = [];
  //   updatedRows.map(id => {
  //     const row = props.activityList.find(x => x.id === id);
  //     if (row) {
  //       let obj = {
  //         activity_id: row.id,
  //         activity: row.activity,
  //         actual_start_date:
  //           row.actual_start_date !== 'None' ? row.actual_start_date : null,
  //         actual_end_date:
  //           row.actual_end_date !== 'None' ? row.actual_end_date : null,
  //         attachment: row.attachment !== 'None' ? row.attachment : null
  //       };
  //       payloadArr.push(obj);
  //     }
  //   });
  //   const payloadObj = {
  //     campaign_id: window.location.pathname.split('/')[3],
  //     activity_details: payloadArr
  //   };

  //   if (payloadArr.length > 0) {
  //     props
  //       .createOrModifyActivities(payloadObj)
  //       .then(() => {
  //         setUpdatedRows([]);
  //         toast.success('Activities updated successfully');
  //       })
  //       .catch(() => {
  //         toast.error("Oops! Looks like we couldn't process your request.");
  //       });
  //   }
  // };

  const activityUploadSuccess = (fileUrl, e, id) => {
    rowUpdated(id);
    const tempActivityList = props.activityList.slice();
    const row = tempActivityList.find(x => x.id === id);
    if (row) {
      row[e.target.id] = fileUrl;
    }
  };

  const showHideModal = (value, id) => {
    if (showModal) {
      setShowModal(false);
      setActivityType('');
      setActivityId(id);
    } else {
      setShowModal(true);
      setActivityType(value);
      setActivityId(id);
    }
  };

  const updateActivity = type => {
    if (type === 'start') {
      showHideModal();
    } else {
      showHideModal();
    }

    const tempActivityList = props.activityList.slice();
    const row = tempActivityList.find(x => x.id === activityId);
    if (row) {
      if (
        type === 'start' &&
        (row.actual_start_date === 'None' || row.actual_start_date === 'None')
      )
        return toast.error('Please select Actual Start Date ');
      if (
        type === 'In Progress' &&
        (row.actual_end_date === 'None' || row.actual_end_date === 'None')
      )
        return toast.error('Please select Actual End Date ');
    }
    let payloadObj = {
      status: type === 'start' ? 'In Progress' : 'Completed',
      campaign_id: window.location.pathname.split('/')[3],
      activity_id: activityId,
      actual_start_date: row.actual_start_date,
      actual_end_date: row.actual_end_date
    };

    // if (row) {
    //   if (type === 'start')
    //     payloadObj['actual_start_date'] = row.actual_start_date;
    //   else payloadObj['actual_end_date'] = row.actual_end_date;
    // }

    props
      .modifyProjectActivities(payloadObj)
      .then(() => {
        toast.success('Activity updated successfully');
      })
      .catch(() => {
        toast.error("Oops! Looks like we couldn't process your request.");
      });

    setActivityType('');
    props.getProjectActivities(window.location.pathname.split('/')[3]);
  };

  return (
    <div className="col-12 mt-4 modal-container">
      <div className="w-100 bg-white">
        <div className="p-4">
          <div>
            <h1 className="color-344256 fs-20 mb-2">
              All Activities and Milestones
            </h1>
            <hr align="left" />{' '}
          </div>
          {props.tabActivity ? (
            <>
              <Table
                column={editColumn}
                editRowActivity={props.activityList}
                editActivity={props.tabActivity}
                handleChange={handleChange}
                activityUploadSuccess={activityUploadSuccess}
                handleFunction1={showHideModal}
                permissions={props.permissions}
                isUserAuthorized={props.isUserAuthorized}
              />
              <div className="d-flex mt-3 justify-content-center">
                {/* <button
                  className="btn btn-activityPlan btn-custom"
                  onClick={e => submitActivities(e)}>
                  Update
                </button> */}
              </div>
            </>
          ) : (
            <>
              <div className="inline-tabs row d-flex align-items-center mb-4 mt-4">
                <span
                  className={`tab ${
                    projectStatus === 'allActivities'
                      ? 'active-tab color-0085FF'
                      : ''
                  } fs-14 font-weight-normal position-relative pl-2 pb-2 mr-3 cursor-pointer select-none`}
                  onClick={() => setProjectStatus('allActivities')}>
                  All Activities
                  <span className="tab-count d-inline-block text-center ml-2">
                    <span className="fs-12 font-weight-normal">
                      {props.activityList.length}
                    </span>
                  </span>
                </span>
                <span
                  className={`tab ${
                    projectStatus === 'In Progress'
                      ? 'active-tab color-0085FF'
                      : ''
                  } fs-14 font-weight-normal position-relative px-2 pb-2 mr-3 cursor-pointer select-none`}
                  onClick={() => setProjectStatus('In Progress')}>
                  In Progress
                  <span className="tab-count d-inline-block text-center ml-2">
                    <span className="fs-12 font-weight-normal">
                      {
                        props.activityList.filter(
                          x => x.status === 'In Progress'
                        ).length
                      }
                    </span>
                  </span>
                </span>
                <span
                  className={`tab ${
                    projectStatus === 'Completed'
                      ? 'active-tab color-0085FF'
                      : ''
                  } fs-14 font-weight-normal position-relative px-2 pb-2 mr-3 cursor-pointer select-none`}
                  onClick={() => setProjectStatus('Completed')}>
                  Completed
                  <span className="tab-count d-inline-block text-center ml-2">
                    <span className="fs-12 font-weight-normal">
                      {
                        props.activityList.filter(x => x.status === 'Completed')
                          .length
                      }
                    </span>
                  </span>
                </span>
                <span
                  className={`tab ${
                    projectStatus === 'Upcoming'
                      ? 'active-tab color-0085FF'
                      : ''
                  } fs-14 font-weight-normal position-relative px-2 pb-2 mr-3 cursor-pointer select-none`}
                  onClick={() => setProjectStatus('Upcoming')}>
                  Upcoming
                  <span className="tab-count d-inline-block text-center ml-2">
                    <span className="fs-12 font-weight-normal">
                      {
                        props.activityList.filter(x => x.status === 'Upcoming')
                          .length
                      }
                    </span>
                  </span>
                </span>
              </div>
              <Table
                column={columns}
                rowActivities={props.activityList}
                projectStatus={projectStatus}
                permissions={props.permissions}
                isUserAuthorized={props.isUserAuthorized}
              />
            </>
          )}
        </div>
      </div>

      <Modal
        show={showModal}
        title={
          activityType === 'start' ? 'Start Activity?' : 'Complete Activity?'
        }
        handleCloseModal={showHideModal}
        sizeClass={'modal-md'}>
        {activityType === 'start' ? (
          <p className="fs-18 font-weight-normal mb-5">
            Are you sure you want to start this activity? Please note that this
            action is irreversible.
          </p>
        ) : (
          <p className="fs-18 font-weight-normal mb-5">
            Are you sure you want to mark the activity as Complete? Please note
            that this action is irreversible.
          </p>
        )}
        <div className="text-center">
          <button
            className="btn btn-activityPlan"
            onClick={() => updateActivity(activityType)}>
            {activityType === 'start' ? 'Start Activity' : 'Mark Complete'}
          </button>
        </div>
      </Modal>
    </div>
  );
}
