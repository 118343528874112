import React from 'react';
import PageContentWrapper from '../components/pageContentWrapper';
import PageContent from '../components/pageContent';
import Header from '../components/header';
import StartModifyProject from '../components/campaigns/project';
import ProjectActivities from '../components/campaigns/projectActivities';
import ProjectIndicators from '../components/campaigns/projectIndicators';
import { Common } from '../constants';

class ProjectScreen extends React.Component {
  getProjectMainScreenByPathName = ({ id }) => {
    if (this.props.location.pathname.includes('/ngo/project/detailsOverview')) {
      return <StartModifyProject id={id} history={this.props.history} />;
    }
    if (this.props.location.pathname.includes('/ngo/project/activitiesPlan')) {
      return <ProjectActivities id={id} />;
    }
    if (
      this.props.location.pathname.includes('/ngo/project/impactIndicators')
    ) {
      return <ProjectIndicators id={id} history={this.props.history} />;
    }
  };

  render() {
    let { id } = this.props.match.params;
    return (
      <React.Fragment>
        <Header pageTitle={Common.NGO.PAGE_TITLE.PROJECT} />
        <PageContentWrapper>
          <PageContent
            mainScreen={this.getProjectMainScreenByPathName({ id })}
          />
        </PageContentWrapper>
      </React.Fragment>
    );
  }
}
export default ProjectScreen;
