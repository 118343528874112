///* global jQuery */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps*/
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { debounce, /* defaults, */ filter } from 'lodash';
// import React, { useEffect, useRef } from 'react';
import React from 'react';
import { DateUtils } from 'react-day-picker';
import connect from 'react-redux/es/connect/connect';
// import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { toast } from 'react-hot-toast';
import {
  deleteDraftCampaignForNGO,
  getMissionsForNgo,
  // getDistrictsDataById,
  getMasterData,
  // getMasterDataById,
  createOrModifyMission
  // getStatesDataById
} from '../../actions';
import { getSecondaryCauses } from '../../actions/common.actions';
import { Common } from '../../constants';
import {
  /* getCloudinaryUrlForImage, */ getLimitedCharString
} from '../../helpers/util';
import Loader from '../loader';
import Tabs from '../tabs';
import FilterOptionsDropdownChecklist from '../filter/filterOptionsDropdownChecklist';
import '../../styles/simple-sidebar.css';
import TooltipList from './tooltipList';
import EmptySideScreenState from '../EmptySideScreenState';
// import supporters from '../../assets/icons/supporters.svg';
import { Routes } from '../../constants/path.constants';
import dumyLogo from '../../assets/icons/NgoLogo.png';
import { getUserRole } from '../../helpers/auth-roles';

dayjs.extend(isBetween);

let primary = [];
// let secondary = [];
let locationsArr = [];
var ngoEmail = window.location.href.split('email=')[1];
class MissionsList extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      allMissions: null,
      dummy: null
    };
    this.state = {
      ...this.initialState,
      filters: this.getInitialFilterState(),
      isDateRangeShow: false,
      ishandleToggleFilter: false,
      isListDisplay: false,
      popOverOptions: null,
      // dateRangeArray: [],
      locationArr: [],
      campaignsReceived: false,
      showFilterBox: false,
      primaryCauses: [],
      secondaryCauses: [],
      clearFilter: false,
      checkedCount: 0,
      showOtherLocations: true,
      showMoreLessText: 'more',
      trackCampaignLocation: 0,
      campaignFetched: false,
      isSuperAdmin: false
    };
    // this.originalMissions = [];
  }

  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  eventToValue(e, trimResults, toNumber) {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      value = Number(value);
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  }
  getInitialDateState() {
    return {
      fromDate: null,
      toDate: null,
      enteredTo: null
    };
  }

  getInitialFilterState = () => {
    return {
      status: Common.NGO.MISSION_STATUS.DRAFT,
      searchTerm: ''
      // dateRange: this.getInitialDateState()
    };
  };

  isSelectingFirstDay(fromDate, toDate, day) {
    const isBeforeFirstDay = fromDate && DateUtils.isDayBefore(day, fromDate);
    const isRangeSelected = fromDate && toDate;
    return !fromDate || isBeforeFirstDay || isRangeSelected;
  }

  openFilterBox = e => {
    this.setState({ showFilterBox: true });
  };

  closeFilterBox = e => {
    this.setState({ showFilterBox: false });
    this.clearFilter();
  };

  initProgramLocationList = arr => {
    let locations = [];
    if (arr && arr.length > 0) {
      arr.map((mission, index) => {
        mission.locations.map(location => {
          let locIndex = locations.findIndex(x => x.name === location.city);
          if (locIndex === -1) {
            locations.push({
              id: location.city,
              name: location.city
            });
          }
          return false;
        });
        return false;
      });
    }
    locations.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    return locations;
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.props.getMissionsForNgo(this.props.ngoData.ngo_id);
    if (window.location.href.includes('email')) {
      // this.props.getCampaignsForNgo(nextprops.ngoData.ngo_id);
      this.props.getMissionsForNgo(this.props.ngoData.ngo_id);
    }
    const masterDataOptions = Common.NGO.MASTER_OPTIONS;
    this.props.getMasterData([masterDataOptions.CAUSE_CATEGORY]);
    this.setState({ isSuperAdmin: getUserRole() });
  }

  sortByCreatedOnDesc = arr => {
    return arr.sort((a, b) => {
      const timeStamp1 = new Date(a.created_on);
      const timeStamp2 = new Date(b.created_on);
      if (timeStamp1.getTime() < timeStamp2.getTime()) {
        return 1;
      }
      if (timeStamp1.getTime() > timeStamp2.getTime()) {
        return -1;
      }
      return 0;
    });
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.ngoData.ngo_id) {
      this.setState({ userId: nextprops.ngoData.ngo_id });
      if (!this.state.campaignFetched) {
        this.setState({ campaignFetched: true }, () => {
          this.props.getMissionsForNgo(nextprops.ngoData.ngo_id);
        });
      }
    }
    if (nextprops.missionList) {
      let missionList = nextprops.missionList?.missions || [];
      if (missionList.length > 0) {
        missionList = missionList.filter(mission => !!mission.status);
        missionList = this.sortByCreatedOnDesc(missionList);
      }
      this.initProgramLocationList(missionList);
      this.setState(
        {
          allMissions: missionList,
          campaignsReceived: true,
          filters: {
            ...this.state.filters,
            status: Common.NGO.MISSION_STATUS.DRAFT
          }
        },
        () => {
          this.setState({ allMissions: this.filterData() });
        }
      );
      this.originalMissions = missionList;
    }
  }

  clickHandler = {
    handleDateRangeClick: () => {
      this.setState({ isDateRangeShow: !this.state.isDateRangeShow });
    },
    handleTabClick: status => {
      this.setState(
        {
          filters: { ...this.state.filters, status: status.code }
        },
        () => {
          this.ApplyFilter();
          this.setState({ locationArr: [] });
          // this.setState({ allMissions: this.filterData() });
        }
      );
    },
    handleSearchClick: () => {
      this.setState({
        allMissions: this.filterData()
      });
    }
  };

  startProject = (e, mission_id) => {
    this.props
      .startProject(mission_id)
      .then(res => {
        if (res.code === '200') {
          toast.success('Project has been started');
          setTimeout(() => {
            window.location.href = '/ngo/mneSingleProject/' + mission_id;
          }, 500);
        }
      })
      .catch(async err => {});
  };

  clearFilter = () => {
    let list1 = document.getElementsByClassName('primary-cause-checkbox');
    for (let i = 0; i < list1.length; i++) {
      if (list1[i].checked === true) {
        list1[i].checked = false;
      }
    }
    let list2 = document.getElementsByClassName('location');
    for (let i = 0; i < list2.length; i++) {
      if (list1[i].checked === true) {
        list1[i].checked = false;
      }
    }
    this.setState(
      {
        primaryCauses: [],
        locationArr: [],
        filters: { ...this.state.filters, searchTerm: '' }
      },
      () => {
        this.ApplyFilter();
      }
    );
  };

  handleToggleFilter = () => {
    const currentState = this.state.ishandleToggleFilter;
    this.setState({
      ishandleToggleFilter: !currentState
    });
  };

  handleListDisplay = e => {
    const currentState = this.state.isListDisplay;
    if (currentState === false) {
      document.querySelector('body').addEventListener('click', () => {
        this.setState({
          isListDisplay: false
        });
      });
    }
    this.setState({
      isListDisplay: !currentState,
      popOverOptions: e.target.id.slice(6)
    });
  };
  applyLocationFilter = (e, locations) => {
    e.persist();
    e.preventDefault();
    locationsArr = locations;
    this.ApplyFilter();
  };
  applyCauseFilter = (e, id, name) => {
    e.persist();
    e.preventDefault();
    let checkListArr = [];
    let list = document.getElementsByName(name);

    for (let i = 0; i < list.length; i++) {
      if (
        list[i].checked === true &&
        list[i].value !== '' &&
        list[i].value !== undefined
      ) {
        checkListArr.push(parseInt(list[i].id));
      }
      if (id === 'primary-cause') {
        primary = checkListArr;
        primary = primary;
      } else if (id === 'location') {
        locationsArr = checkListArr;
        locationsArr = locationsArr;
      }
    }
    this.ApplyFilter();
  };
  handlePublishedTabData = (selectedTab, missionData) => {
    if (
      selectedTab === 'published' &&
      missionData.version_status !== 'DRAFT' &&
      missionData.version_status !== 'UNDER_REVIEW'
    ) {
      return missionData.status === 'PUBLISHED';
    }
  };
  handleReviewTabData = (selectedTab, missionData) => {
    if (selectedTab === 'under_review') {
      if (missionData.status !== 'DRAFT' && missionData.status !== 'REJECTED') {
        return (
          missionData.status === 'UNDER_REVIEW' ||
          missionData.version_status === 'UNDER_REVIEW'
        );
      }
    }
  };

  handleDraftTabData = (selectedTab, missionData) => {
    if (selectedTab === 'draft') {
      return (
        missionData.status === 'DRAFT' || missionData.version_status === 'DRAFT'
      );
    }
  };
  handleUnpublishedTabData = (selectedTab, missionData) => {
    if (selectedTab === 'unpublished') {
      return missionData.status === 'UNPUBLISHED';
    }
  };
  ApplyFilter = () => {
    let filteredMissions = this.originalMissions.slice();
    let selectedStatus = this.state.filters.status.toLowerCase();
    if (selectedStatus !== 'all') {
      filteredMissions = filteredMissions.filter(
        // mission => mission.status.toLowerCase() === selectedStatus
        mission => {
          // if (mission.version_status !== 'REJECTED') {
          // const cond1 =
          //   !mission.version_status &&
          //   mission.status.toLowerCase() === selectedStatus;

          // const cond2 =
          //   mission.version_status &&
          //   mission.version_status !== '' &&
          //   mission.version_status.toLowerCase() === selectedStatus;
          const cond1 = this.handleDraftTabData(selectedStatus, mission);
          const cond3 = this.handleReviewTabData(selectedStatus, mission);
          const cond4 = this.handlePublishedTabData(selectedStatus, mission);
          const cond2 = this.handleUnpublishedTabData(selectedStatus, mission);
          const cond5 =
            selectedStatus === 'rejected' && mission.status === 'REJECTED';
          if (cond1 || cond2 || cond3 || cond4 || cond5) return true;
          return false;
        }
      );
    }
    if (this.state.filters.searchTerm && this.state.filters.searchTerm !== '') {
      filteredMissions = filteredMissions.filter(mission =>
        mission.mission_title
          .toLowerCase()
          .includes(this.state.filters.searchTerm.toLocaleLowerCase())
      );
    }
    if (this.state.primaryCauses.length > 0) {
      filteredMissions = filteredMissions.filter(mission => {
        const value = this.state.primaryCauses.some(x => {
          if (mission.cause_category_id === x) return true;
          return false;
        });
        if (value) return true;
        return false;
      });
    }
    if (this.state.locationArr.length > 0) {
      filteredMissions = filteredMissions.filter(mission => {
        const value = this.state.locationArr.some(x => {
          let cityMatched = false;
          mission.locations.map(loc => {
            if (!cityMatched && loc.city === x) cityMatched = true;
            return false;
          });
          return cityMatched;
        });
        if (value) return true;
        return false;
      });
    }

    this.setState({ allMissions: [] }, () => {
      this.setState({
        allMissions: this.sortByCreatedOnDesc(filteredMissions)
      });
    });
  };

  deleteDraftCampaign = (status, mission_id) => {
    if (status === Common.NGO.MISSION_STATUS.DRAFT) {
      this.props.deleteDraftCampaignForNGO(
        mission_id,
        this.props.ngoData.ngo_id
      );
      this.props.getCampaignsForNgo(this.props.ngoData.ngo_id);
    }
  };

  getCampaignsToFilter = (selectedStatus, filteredMissions, allMissions) => {
    return filteredMissions && filteredMissions.length
      ? filteredMissions
      : selectedStatus === 'all'
      ? allMissions
      : [];
  };

  filterData = () => {
    let allMissions = this.originalMissions
      ? JSON.parse(JSON.stringify(this.originalMissions))
      : null;
    let filteredMissions = [];
    let selectedStatus = this.state.filters.status.toLowerCase();
    /* Apply the status filter (setting from tabs) */
    if (selectedStatus !== 'all') {
      filteredMissions = filter(allMissions, o => {
        return (
          (o.status &&
            selectedStatus.split('|').includes(o.status.toLowerCase())) ||
          (o.version_status &&
            selectedStatus.split('|').includes(o.version_status.toLowerCase()))
        );
      });
    }

    /* Apply the search term (search text box) */
    let searchTerm = this.state.filters.searchTerm.toLowerCase();
    if (this.state.filters.searchTerm !== '') {
      filteredMissions = filter(
        this.getCampaignsToFilter(
          selectedStatus,
          filteredMissions,
          allMissions
        ),
        o => {
          return o.title && o.title.toLowerCase().indexOf(searchTerm) > -1;
        }
      );
    }

    return filteredMissions && filteredMissions.length
      ? filteredMissions
      : selectedStatus === 'all' && searchTerm === ''
      ? allMissions
      : [];
  };

  getPercentage = (
    campaignType,
    fundRaised,
    fundRemaining,
    volunterRequired,
    volunterRequestReceived
  ) => {
    if (volunterRequired === 0 || fundRemaining === 0) {
      return 0;
    }
    if (campaignType === 'Volunteering') {
      return (+volunterRequestReceived / +volunterRequired) * 100;
    }
    return (+fundRaised / +fundRemaining) * 100;
  };

  getPathByStatus = (status, mission_id) => {
    let headTo = '';
    switch (status) {
      case Common.NGO.MISSION_STATUS.DRAFT:
        if (window.location.href.includes('email')) {
          headTo = '/ngo/mission/modify/' + mission_id + '?email=' + ngoEmail;
        } else {
          headTo = '/ngo/mission/modify/' + mission_id;
        }
        break;
      default:
        if (window.location.href.includes('email')) {
          headTo = '/ngo/mission/details/' + mission_id + '?email=' + ngoEmail;
        } else {
          headTo = '/ngo/mission/details/' + mission_id;
        }
        break;
    }
    return headTo;
  };
  lengthOfLocation(locationArray) {
    let str = '';
    for (let i = 0; i < locationArray.length; i++) {
      if (i < locationArray.length - 1) {
        str += `${locationArray[i].city}, ${locationArray[i].state} | `;
      } else {
        str += `${locationArray[i].city}, ${locationArray[i].state} `;
      }
    }
    return str.length;
  }

  lengthOfCauseArea(causeArray) {
    let str = '';
    for (let i = 0; i < causeArray.length; i++) {
      if (i < causeArray.length - 1) {
        str += `${causeArray[i]}, `;
      } else {
        str += `${causeArray[i]} `;
      }
    }
    return str.length;
  }
  numberOfCampaignsInTooltip(locationArray) {
    let str = '';
    let count = 0;
    for (let i = 0; i < locationArray.length; i++) {
      if (i < locationArray.length - 1) {
        if (
          str.length +
            locationArray[i].city.length +
            locationArray[i].state.length +
            5 <
          50
        ) {
          str += `${locationArray[i].city}, ${locationArray[i].state} | `;
          count++;
        }
      } else {
        if (
          str.length +
            locationArray[i].city.length +
            locationArray[i].state.length +
            5 <
          50
        ) {
          str += `${locationArray[i].city}, ${locationArray[i].state} | `;
          count++;
        }
      }
    }
    return locationArray.length - count;
  }

  numberOfCauseAreaInTooltip(causeArray) {
    let str = '';
    let count = 0;
    for (let i = 0; i < causeArray.length; i++) {
      if (i < causeArray.length - 1) {
        if (str.length + causeArray[i].length + 1 < 35) {
          str += `${causeArray[i]}, `;
          count++;
        }
      } else {
        if (str.length + causeArray[i].length + 1 < 35) {
          str += `${causeArray[i]} `;
          count++;
        }
      }
    }
    return causeArray.length - count;
  }
  convertRupeesInCommaFormat = amount => {
    return amount?.toLocaleString();
  };
  renderNoCampaignsView = () => {
    return (
      <div className="row mt-4 justify-content-center">
        <p className="h3">
          {/* {this.state.filters.status !== 'ALL' ? ( */}
          <EmptySideScreenState
            pageName={'mission'}
            hide={!this.state.isSuperAdmin}
          />
          {/* ) : (
            'There is nothing to display.'
          )} */}
        </p>
      </div>
    );
  };
  handlePrimaryAreas = (val, type, callback) => {
    if (type === 'cause_category') {
      this.setState({ primaryCauses: val }, () => {
        if (callback) this.ApplyFilter();
      });
    }
  };
  handleLocation = (locationArr, callback) => {
    this.setState(
      {
        locationArr: locationArr
      },
      () => {
        if (callback) this.ApplyFilter();
      }
    );
  };

  redirect = (status, missionId, versionStatus) => {
    if (window.location.href.includes('email')) {
      this.props.history.push(
        Routes.NGO.MODIFY_MISSION + '/' + missionId + '?email=' + ngoEmail
      );
    } else {
      if (status === 'DRAFT') {
        // this.props.history.push(Routes.NGO.MODIFY_MISSION + '/' + missionId);
        setTimeout(() => {
          window.location.href = `/ngo/mission/modify/${missionId}`;
        }, 200);
      }
      if (versionStatus === 'DRAFT') {
        setTimeout(() => {
          window.location.href = `/ngo/mission/edit/${missionId}`;
        }, 500);
      } else {
        // mission/edit/235
        this.props.history.push(Routes.NGO.MISSION_DETAILS + '/' + missionId);
      }
    }
  };

  handleMissionTag = missionData => {
    if (
      missionData?.status === 'PUBLISHED' &&
      (missionData?.version_status === 'UNDER_REVIEW' ||
        missionData?.version_status === 'DRAFT')
    ) {
      return (
        <div className="mission-card-tag-wrapper position-absolute d-flex justify-content-end w-100 mt-1">
          <span className="color-F2711C bg-FDEFE5 fw-500 px-2 py-1 mr-2 rounded d-inline-block">
            Modified
          </span>
          <span className="color-21BA45 bg-DEF7E4 px-2 py-1 rounded d-inline-block mr-2">
            Published
          </span>
        </div>
      );
    }
    if (
      missionData?.status === 'PUBLISHED' &&
      missionData?.version_status === 'PUBLISHED'
    ) {
      return (
        <div className="mission-card-tag-wrapper position-absolute d-flex justify-content-end w-100 mt-1">
          <span className="color-F2711C bg-FDEFE5 fw-500 fs-12 px-2 py-1 mr-2 rounded d-inline-block">
            Modified
          </span>
        </div>
      );
    }
  };

  getLocationsByStatus = () => {
    let filteredLocArr = this.originalMissions.filter(
      mission => mission.status === this.state.filters.status
    );
    return filteredLocArr;
  };

  renderFilterBar = () => {
    const debouncedHandleChange = debounce(e => {
      this.setState(
        {
          filters: { ...this.state.filters, searchTerm: e.target.value }
        },
        () => {
          this.ApplyFilter();
        }
      );
    }, 300);

    // side panel filter commented code
    const primaryCauseArea = this.props.masterInfo
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.CAUSE_CATEGORY]
      : null;
    const programLocationsList = this.props.masterInfo
      ? this.initProgramLocationList(this.getLocationsByStatus())
      : [];

    return (
      <>
        <section className={`mt-4 d-flex justify-content-between`}>
          <div className="d-flex mx-4">
            <FilterOptionsDropdownChecklist
              options={primaryCauseArea}
              title={'Primary Cause'}
              id={'primary-cause'}
              name={`primary-cause-checkbox`}
              searchTerm={this.state.filters.searchTerm}
              debouncedHandleChange={debouncedHandleChange}
              applyFilterFunction={this.ApplyFilter}
              applyCauseFilter={this.applyCauseFilter}
              checkedCount={this.state.checkedCount}
              handlePrimaryAreas={this.handlePrimaryAreas}
            />

            <FilterOptionsDropdownChecklist
              options={programLocationsList}
              title={'Program Location'}
              id={'location'}
              name={`location`}
              searchTerm={this.state.filters.searchTerm}
              wrapperClass={'mr-4'}
              debouncedHandleChange={debouncedHandleChange}
              applyFilterFunction={this.ApplyFilter}
              applyCauseFilter={this.applyCauseFilter}
              checkedCount={this.state.checkedCount}
              handleChange={this.handleLocation}
              locationChild={this.state.locationArr}
            />

            <button
              className="btn btn-link p-0 btn-lg"
              onClick={() => this.clearFilter()}>
              Clear filters
            </button>
          </div>
          <div className="transparent input-group p-0 mr-5 position-relative col-3">
            <input
              className="form-control filter-search bg-white px-5"
              type="text"
              defaultValue={this.state.filters.searchTerm}
              onChange={e => {
                e.persist();
                debouncedHandleChange(e);
              }}
              placeholder="Search by mission name"
            />
            <i className="input-group-text fa fa-search form-control-feedback position-absolute text-969696 float-right"></i>
          </div>
        </section>
      </>
    );
  };

  editCampaign = (e, status, mission_id) => {
    if (window.location.href.includes('email')) {
      let email = window.location.href.split('email=')[1];
      window.location.href = 'edit/' + mission_id + '?email=' + email;
    } else window.location.href = 'edit/' + mission_id;
  };
  renderMissionList = () => {
    // const imageContainerRef = React.createRef();

    return (
      <div
        className={
          this.state.showFilterBox ? 'list-on-filter col-8' : 'col-12 '
        }>
        <div className={`row align-items-stretch`}>
          {this.state.allMissions.map((mission, index) => {
            return (
              <div
                className="col-4 mt-4 pb-3 "
                key={
                  index + mission.mission_id + mission.mission_title + index
                }>
                <div
                  className="card cursor-pointer"
                  onClick={() =>
                    this.redirect(
                      mission.status,
                      mission.mission_id,
                      mission.versionStatus
                    )
                  }>
                  {this.handleMissionTag(mission)}
                  <img
                    className="card-img-top"
                    src={mission.image_url}
                    alt={mission.mission_title}
                    width={640}
                    height={160}
                  />
                  <div className="card-body">
                    <p className="fs-12 text-blue-1 text-uppercase mb-1 color-0085FF">
                      {mission.cause_category_slug}
                    </p>
                    <p className="fs-18 text-black-1 mb-3 mission_title fw-500">
                      {getLimitedCharString(mission.mission_title, 60)}
                    </p>
                    <span className="fs-14 text-black-1 d-flex w-100 mb-3 align-items-center">
                      {mission.ngo_name && mission?.ngo_image ? (
                        <img
                          className={`styles.ngo_mini_logo mr-2`}
                          src={mission?.ngo_image}
                          alt={mission.ngo_name}
                          width={22}
                          height={22}
                        />
                      ) : (
                        <img
                          className={`styles.ngo_mini_logo mr-2`}
                          src={dumyLogo}
                          alt={mission.ngo_name}
                          width={22}
                          height={22}
                        />
                      )}
                      <span>{mission.ngo_name}</span>
                    </span>
                    {/* {(mission.status === 'PUBLISHED' ||
                      mission.status === 'UNPUBLISHED') && (
                      <span className="fs-14 text-black-1 d-block mb-3">
                        <img
                          className="mr-2"
                          src={supporters}
                          alt={'Active supporters'}
                          width={24}
                          height={24}
                        />
                        <span>
                          {mission?.active_supporters || 0}{' '}
                          {mission?.mission_type === 'mission'
                            ? 'Total Active Supporters'
                            : 'Total Supporters'}
                        </span>
                      </span>
                    )} */}

                    <span className="fs-12 d-block mb-3">
                      <TooltipList
                        list={mission.locations}
                        max={2}
                        showLabel={true}
                        label="Program Location"
                        labelClass="text-969696"
                        wrapperClass=""
                        listClass=""
                        moreClass="cursor-default mr-3 cursor-pointer text-007bff tooltipss"
                      />
                    </span>
                    <button
                      type="button"
                      onClick={() =>
                        this.redirect(
                          mission.status,
                          mission.mission_id,
                          mission.version_status
                        )
                      }
                      className="btn btn-primary btn-lg w-100 fs-14 px-4 dm-bold">
                      {mission.status === 'DRAFT' ||
                      mission.version_status === 'DRAFT'
                        ? 'Modify Mission'
                        : 'View Details'}
                    </button>
                  </div>
                </div>
              </div>
            );
            // }
          })}
        </div>
      </div>
    );
  };

  render() {
    var tabsArr = [];
    var rejectedTab = [];
    if (this.state.allMissions === null) {
      return <Loader loaderText="Loading..." />;
    }

    this.props.missionList.statusTypes.map((tab, index) => {
      if (!tab.label && !tab.count && !tab.code) return false;
      if (tab.label !== 'Rejected') {
        if (tab.label !== 'Live') {
          // const missions = this.props.missionList.missions.filter(
          //   x =>
          //     x.status?.toLowerCase() === tab.code.toLowerCase() ||
          //     x.version_status?.toLowerCase() === tab.code.toLowerCase()
          // );
          // if (editStatusMissions.length > 0)
          // tab.count = tab.count;
          tabsArr.push(tab);
        }
      } else {
        rejectedTab = tab;
      }
      if (this.props.missionList.statusTypes.length === index + 1) {
        tabsArr.push(rejectedTab);
      }
    });

    return (
      <div className="row">
        <div className="col-12 p-0">
          <Tabs
            tabs={tabsArr}
            handlers={this.clickHandler}
            clickHandlerName={'handleTabClick'}
            defaultSelectionValue={'DRAFT'}
          />
          {this.renderFilterBar()}
          {this.state.allMissions.length ? this.renderMissionList() : null}
          {!this.state.allMissions.length && this.renderNoCampaignsView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  missionList: state.missionData.missionList,
  userInfo: state.login.userInfo,
  ngoData: state.ngo.ngoProfile,

  masterInfo: state.common.masterInfo,
  masterSecondaryInfo: state.common.masterSecondaryInfo,
  masterSecondaryResponseStatus: state.common.masterSecondaryResponseStatus,
  listCountries: state.common.listCountries,
  listStates: state.common.listStates,
  listDistricts: state.common.listDistricts,
  createModifySuccessResponse:
    state.missionData.createModifyMissionSuccessResponse,
  createModifyErrorResponse: state.missionData.createModifyMissionErrorResponse
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMissionsForNgo,
      deleteDraftCampaignForNGO,
      getMasterData,
      getSecondaryCauses,
      createOrModifyMission
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionsList);
