import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { getMission } from '../../actions';
import { getCloudinaryUrlForImage, createMarkup } from '../../helpers/util';
import dumyLogo from '../../assets/icons/NgoLogo.png';
import ActivityCard from './activityCard';
import MissionGallery from './missionGallery';
import MissionGallery2 from './missionGallery2';
import EmptyUpdate from '../../assets/icons/update_empty_state.svg';

class MissionPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
      showModal: false,
      filePreview: null,
      fileType: null,
      docFile: null,
      missionInfo: null,
      selectedTab: 'Story',
      imagesArray: []
    };
    this.tabs = ['Story', 'Activities', 'Updates', 'Roadmap', 'Gallery'];
  }

  selectTab = tab => {
    this.setState({ selectedTab: tab });
  };

  getImages = res => {
    let imagesArray = [];

    if (res.updates?.length > 0) {
      res.updates.map(item => {
        const index = imagesArray.findIndex(x => {
          const t1 = new Date(x.timestamp);
          const t2 = new Date(item.created_on);
          if (
            t1.getDate() === t2.getDate() &&
            t1.getMonth() === t2.getMonth() &&
            t1.getFullYear() === t2.getFullYear()
          ) {
            return true;
          }
          return false;
        });

        if (index !== -1) {
          imagesArray[index].urlArray = [
            ...imagesArray[index].urlArray,
            ...item.media
          ];
        } else {
          let obj = {
            timestamp: item.created_on,
            urlArray: []
          };
          if (item.media && item.media.length > 0) {
            obj.urlArray = [...item.media];
          }
          if (obj.urlArray.length > 0) {
            imagesArray.push(obj);
          }
        }
        return false;
      });
    }
    imagesArray.sort((a, b) => {
      const timeStamp1 = new Date(a.timestamp);
      const timeStamp2 = new Date(b.timestamp);
      if (timeStamp1.getTime() < timeStamp2.getTime()) {
        return 1;
      }
      if (timeStamp1.getTime() > timeStamp2.getTime()) {
        return -1;
      }
      return 0;
    });
    // setImageArr(imagesArray);
    this.setState({ imagesArray: imagesArray });
  };

  missionUpdates = () => {
    if (this.state.missionInfo && this.state?.missionInfo?.updates?.length) {
      return (
        <div className="mt-3 fs-12 col-12 text-break">
          {this.state.missionInfo.updates.map((update, index) => {
            return (
              <div key={index + this.state.missionInfo.mission_title}>
                <ActivityCard
                  missionInfo={this.state.missionInfo}
                  update={update}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="my-5 col-12 d-flex justify-content-center mt-4">
          <div className="">
            <div className="d-flex justify-content-center">
              <img
                alt="img"
                className="mx-auto"
                src={EmptyUpdate}
                width={200}
                height={200}
              />
            </div>
            <div className="fs-16 fw-500 text-black text-center my-3">
              You have not added any update.
            </div>
            <div className="text-969696 fs-14 mt-3">
              There are no updates yet to show for this mission.
            </div>
          </div>
        </div>
      );
    }
  };

  componentDidMount() {
    if (!this.props.fetchingPreview) {
      this.props.setFetchPreview(true);
      if (!this.props.editMission) {
        this.props.getMission(this.props.missionId, 'preview');
      } else {
        this.props.getMission(this.props.missionId, 'edit');
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.missionInfo) {
      this.isShowLoader = false;
      this.setState({ missionInfo: nextprops.missionInfo });
      this.getImages(nextprops.missionInfo);
    } else if (nextprops.editMissionInfo) {
      this.isShowLoader = false;
      this.setState({ missionInfo: nextprops.editMissionInfo });
      this.getImages(nextprops.editMissionInfo);
    } else {
      if (
        (nextprops.previewErrorResponse &&
          nextprops.previewErrorResponse.code) ||
        (nextprops.editMissionErrorResponse &&
          nextprops.editMissionErrorResponse.code)
      ) {
        toast.error('Some error occurred, please try again in sometime', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }

  render() {
    return (
      <>
        <div className="row justify-content-center bg-light-gray p-4">
          <div className="col-10 bg-white card-box-shadow p-0 mb-5 rounded-8">
            <div className="super-wide w-100 h-0 position-relative rounded-top-8 loading-bg">
              {this.state.missionInfo && this.state.missionInfo.image_url && (
                <img
                  src={
                    getCloudinaryUrlForImage(
                      this.state.missionInfo.image_url,
                      500
                    ) ||
                    getCloudinaryUrlForImage(
                      'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                      500
                    )
                  }
                  data-src={
                    this.state.missionInfo.image_url ||
                    'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                  }
                  onError={e =>
                    (e.target.src = e.target.getAttribute('data-src'))
                  }
                  alt={`${this.state.missionInfo.mission_title} mission`}
                  className="abs-full rounded-top-8 preview-image"
                />
              )}
            </div>
            <div className="p-4 row">
              <p className="h2 col-12 px-0 mt-2 text-bold text-1C1C1C text-break">
                {this.state.missionInfo?.mission_title}
                <span className="preview-mission-type text-capitalize fs-12 px-3 py-2 ml-4">
                  Mission
                </span>
              </p>
              <span className="text-344256 fs-14 col-12 px-0 mt-2">
                {this.state.missionInfo?.ngo_image &&
                this.state.missionInfo?.ngo_image ? (
                  <img
                    src={this.state.missionInfo?.ngo_image}
                    className="preview-ngo-logo"
                    alt="images"
                  />
                ) : (
                  <img
                    src={dumyLogo}
                    className="preview-ngo-logo"
                    alt="images"
                  />
                )}
                <span className="pt-3 ml-2">
                  By {this.state.missionInfo?.ngo_name}
                </span>
              </span>
              <div className="mt-3">
                {this.state.missionInfo?.cause_category_slug && (
                  <span className="preview-cause px-3 py-1">
                    {this.state.missionInfo?.cause_category_slug}
                  </span>
                )}
                {this.state.missionInfo?.sub_cause_category.map(
                  (cat, index) => {
                    return (
                      <span
                        key={index + cat}
                        className="preview-cause px-3 py-1 ml-2">
                        {cat}
                      </span>
                    );
                  }
                )}
              </div>
              <p className="text-capitalize col-12 mt-3 mb-0 px-0 fs-14">
                Program Location:&nbsp;
                {this.state.missionInfo?.locations.map((loc, index) => {
                  return (
                    <span
                      key={index + loc.id + loc.country + loc.pincode}
                      className="">
                      {index !== 0 ? ', ' : ''}
                      {loc.city}
                    </span>
                  );
                })}
              </p>
              <p className="text-capitalize col-12 mt-2 mb-4 px-0 fs-14">
                Target Beneficiaries:&nbsp;
                {this.state.missionInfo?.target_beneficiary_slug.map(
                  (beneficiary, index) => {
                    return (
                      <span key={index + beneficiary} className="">
                        {index !== 0 ? ', ' : ''}
                        {beneficiary}
                      </span>
                    );
                  }
                )}
              </p>
              <div className="preview-nav-tabs col-12 px-0 pb-2">
                {this.tabs.map((tab, index) => {
                  return (
                    <span
                      key={index + tab}
                      className={`tab p-3 position-relative fs-16 cursor-pointer ${
                        this.state.selectedTab === tab ? 'active' : ' '
                      }`}
                      onClick={() => this.selectTab(tab)}>
                      {tab}
                    </span>
                  );
                })}
              </div>
              {this.state.selectedTab === 'Story' && (
                <p
                  className="mt-3 fs-12 preview-content text-break"
                  dangerouslySetInnerHTML={createMarkup(
                    this.state.missionInfo?.story
                  )}></p>
              )}
              {this.state.selectedTab === 'Activities' && (
                <p
                  className="mt-3 fs-12 preview-content text-break"
                  dangerouslySetInnerHTML={createMarkup(
                    this.state.missionInfo?.activities
                  )}></p>
              )}
              {this.state.selectedTab === 'Roadmap' && (
                <p
                  className="mt-3 fs-12 preview-content text-break"
                  dangerouslySetInnerHTML={createMarkup(
                    this.state.missionInfo?.roadmap
                  )}></p>
              )}
              {this.state.selectedTab === 'Updates' && this.missionUpdates()}
              {this.state.selectedTab === 'Gallery' && (
                <div className="mt-3 fs-12 w-100 preview-content">
                  <MissionGallery
                    imageArr={this.state.imagesArray}
                    hideEmpty={true}
                  />
                  <MissionGallery2 gallery={this.state.missionInfo?.gallery} />
                </div>
              )}
            </div>
          </div>
          <div className="col-10 p-0 text-right">
            <span
              className="fs-13 mr-3 fs-14 dm-medium text-0085FF cursor-pointer p-2"
              onClick={() => this.props.onClosePreview()}>
              Close
            </span>
            {this.props.onSubmit && (
              <button
                type="button"
                onClick={() => this.props.onSubmit()}
                className="btn btn-primary btn-lg fs-14 px-4 dm-bold">
                Submit
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  missionInfo: state.missionData.previewMissionSuccessResponse,
  previewMissionErrorResponse: state.missionData.previewMissionErrorResponse,
  editMissionInfo: state.missionData.editMissionSuccessResponse,
  editMissionErrorResponse: state.missionData.editMissionErrorResponse,
  userProfile: state.login.userInfo
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getMission }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionPreview);
