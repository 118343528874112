import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { InputText, InputSelect } from '../FormElements';
import InputRichTextEditor from '../richTextEditorInput';
import { validate } from './validate';
import { convertFormDataToJson } from '../../helpers/profileHelpers';
import {
  convertInCommaIndianFormat,
  removeCommas
} from '../../helpers/commaSeparatedInput';
import FileUpdateInput from '../fileUpdateInput';
import { sendMail } from '../../helpers/emailSender';

const getFieldObject = (isValid, value, error, jsonField) => {
  return {
    isValid: isValid,
    value: value,
    error: error,
    jsonField: jsonField
  };
};

const AddUpdate = props => {
  const [updateType, setUpdateType] = useState(
    getFieldObject(false, null, '', 'update_type')
  );
  const [fundsUtilized, setFundsUtilized] = useState(
    getFieldObject(false, null, '', 'funds_utilised')
  );
  const [media, setMedia] = useState(getFieldObject(false, [], '', 'media'));
  const [story, setStory] = useState(getFieldObject(false, null, '', 'story'));
  const uploadTypeList = [
    { id: 'general', name: 'General' },
    {
      id: 'milestone_achieved',
      name: 'Milestone achieved'
    }
  ];

  const onHandleChange = event => {
    const { target } = event;
    const { id } = target;
    const value = target.value;
    const errorResult = validate(id, value);

    if (id === 'update_type') {
      setUpdateType({
        isValid: errorResult.isFieldValid,
        error: errorResult.msg,
        value,
        jsonField: updateType.jsonField
      });
    } else if (id === 'funds_utilised') {
      setFundsUtilized({
        isValid: errorResult.isFieldValid,
        error: errorResult.msg,
        value: convertInCommaIndianFormat(value),
        jsonField: fundsUtilized.jsonField
      });
    }
  };

  const setRTEValueAndError = (id, value) => {
    let val = value.replace(/<\/?[^>]+(>|$)|&nbsp;/g, ' ').trim();
    if (story.value !== value && val !== '') {
      const errorResult = validate(id, value);
      setStory({
        isValid: errorResult.isFieldValid,
        error: errorResult.msg,
        value,
        jsonField: story.jsonField
      });
    }
  };

  const validateFields = () => {
    let errorExists = false;
    let errorResult = {};
    errorResult = validate('update_type', updateType.value);
    setUpdateType(
      getFieldObject(
        errorResult.isFieldValid,
        updateType.value,
        errorResult.msg,
        updateType.jsonField
      )
    );
    if (errorResult.msg !== '' && !errorExists) errorExists = true;
    errorResult = validate('funds_utilised', fundsUtilized.value);
    setFundsUtilized(
      getFieldObject(
        errorResult.isFieldValid,
        fundsUtilized.value,
        errorResult.msg,
        fundsUtilized.jsonField
      )
    );
    if (errorResult.msg !== '' && !errorExists) errorExists = true;
    errorResult = validate('story', story.value);
    setStory(
      getFieldObject(
        errorResult.isFieldValid,
        story.value,
        errorResult.msg,
        story.jsonField
      )
    );
    if (errorResult.msg !== '' && !errorExists) errorExists = true;
    return errorExists;
  };

  const clearFormAndClose = () => {
    setUpdateType(getFieldObject(false, null, '', 'update_type'));
    setFundsUtilized(getFieldObject(false, null, '', 'funds_utilised'));
    setMedia(getFieldObject(false, [], '', 'media'));
    setStory(getFieldObject(false, null, '', 'story'));
    props.handleCloseModal();
  };

  const onSave = e => {
    toast.dismiss();
    e.preventDefault();
    let data = convertFormDataToJson(new FormData(e.target));
    const errorExists = validateFields();
    if (errorExists) {
      toast.error(`Error(s) found! Correct the error(s) and try saving again.`);
      return false;
    }
    const payloadObject = {
      mission_id: Number(props.missionId),
      funds_utilised: Number(removeCommas(fundsUtilized.value)),
      update_type: updateType.value,
      story: story.value,
      media: data.media === '' ? [] : data.media
    };
    if (data['media'] && data['media'] !== '') {
      payloadObject['media'] = data.media.includes(',')
        ? data.media.split(',')
        : Array.isArray(data.media)
        ? data.media
        : [data.media];
    }
    props
      .missionUpdate(payloadObject)
      .then(response => {
        if (response.code === '200' || response.code === 200) {
          props.missionUpdateMail(
            props.mission?.mission_title,
            props.mission?.mission_id,
            props.mission?.ngo_name
          );
          toast.success(`Update added successfully.`);
          clearFormAndClose();
          props.getMission(props.missionId, 'detail');
        } else {
          toast.error("Oops! Looks like we couldn't process your request.");
        }
        sendMail({
          templateName: 'newMissionUpdate',
          sendTo: 'ngo.partnerships@mettasocial.com',
          subject: 'Mission Edit Request Received',
          templateData: {
            mission_name: props.mission?.mission_title
          }
        });
      })
      .catch(error => {
        toast.error("Oops! Looks like we couldn't process your request.");
      });
  };

  useEffect(() => {}, [updateType, fundsUtilized, media, story]);

  return (
    <>
      <div className="fs-20 text-1B1C1D fw-500 px-4 mt-4 mb-2">
        {props.title}
      </div>
      <div className="p-4">
        <h4 className="fs-16">
          These updates will be visible to your Total Active supporters only.
        </h4>
        <form
          method="post"
          id="add-update"
          className="form-row"
          onSubmit={onSave}>
          <div className="row px-2">
            <div className="form-group col-5 p-0 mb-3">
              <InputSelect
                required={true}
                labelText="Update Type"
                id="update_type"
                name="update_type"
                options={uploadTypeList}
                fieldValue={updateType.value}
                fieldError={updateType.error}
                onHandleChange={e => onHandleChange(e)}
                dataType="num"
                wrapperClassName={'pr-4'}
                permissions={props.permissions}
              />
            </div>
            <div className="form-group col-5 p-0 mb-3">
              <InputText
                required={true}
                type={'string'}
                labelText="Funds Utilised"
                id="funds_utilised"
                name="funds_utilised"
                fieldError={fundsUtilized.error}
                fieldValue={fundsUtilized.value}
                PlaceHolder={'Maximum Length is 10 digits'}
                wrapperClassName={'col-12 pl-0 pr-0'}
                maxLength={14}
                disableInput={false}
                onHandleChange={onHandleChange}
                onHandleBlur={onHandleChange}
                permissions={props.permissions}
              />
            </div>
            <div className="col-2"></div>
            <div className="col-12 px-0 mb-3">
              <FileUpdateInput
                label={'Add Media Files'}
                id={'media'}
                name={'media'}
                accept={
                  '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG, .gif,.GIF,.mp4,.MP4,.avi ,.AVI,.mov,.MOV,.mpeg,.MPEG,.mpg,.MPG,.pdf,.PDF '
                }
                fileSizeLimit={25}
                type={'file'}
                previewDoc={true}
                showrequired={false}
                value={media.value}
                disabled={false}
                refId={props.userProfile.sub}
                permissions={props.permissions}
              />
              <p className={`text-danger fs-10 mb-0 media-error d-none`}></p>
            </div>
            <InputRichTextEditor
              required={true}
              labelText="Story"
              disableInput={false}
              maxLength={20000}
              PlaceHolder={'Maximum Length is 20,000 Characters'}
              id="story"
              name="story"
              docType="campaign_image"
              refId={props.missionId}
              fieldError={story.error}
              fieldValue={story.value}
              wrapperClassName={'col-12 pl-0 pr-0 mb-0'}
              // onChangeCallback={this.setFormValidStatus}
              onHandleChange={setRTEValueAndError}
              // onHandleBlur={this.onHandleBlur}
              permissions={props.permissions}
            />
            <div className="col-12 mt-5 text-right">
              <span
                className="fs-13 mr-4 fs-14 dm-medium text-0085FF cursor-pointer p-2"
                onClick={() => clearFormAndClose()}>
                Cancel
              </span>
              <button
                type="submit"
                className={`btn btn-primary btn-lg fs-14 px-4 dm-bold`}>
                Add Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddUpdate;
