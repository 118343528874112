import React from 'react';
import PageContentWrapper from '../components/pageContentWrapper';
import PageContent from '../components/pageContent';
import Header from '../components/header';
import StartModifyProject from '../components/campaigns/proposedProjectBudget';
import ProjectActivities from '../components/campaigns/proposedProjectActivities';
import ProjectIndicators from '../components/campaigns/proposedProjectIndicators';
import { Common } from '../constants';

class ProjectProposalScreen extends React.Component {
  getProjectMainScreenByPathName = ({ id }) => {
    if (
      this.props.location.pathname.includes(
        '/ngo/projectProposal/detailsOverview'
      )
    ) {
      return <StartModifyProject id={id} history={this.props.history} />;
    }
    if (
      this.props.location.pathname.includes(
        '/ngo/projectProposal/activitiesPlan'
      )
    ) {
      return <ProjectActivities id={id} />;
    }
    if (
      this.props.location.pathname.includes(
        '/ngo/projectProposal/impactIndicators'
      )
    ) {
      return <ProjectIndicators id={id} history={this.props.history} />;
    }
  };

  render() {
    let { id } = this.props.match.params;
    return (
      <React.Fragment>
        <Header pageTitle={Common.NGO.PAGE_TITLE.PROJECT} />
        <PageContentWrapper>
          <PageContent
            mainScreen={this.getProjectMainScreenByPathName({ id })}
          />
        </PageContentWrapper>
      </React.Fragment>
    );
  }
}
export default ProjectProposalScreen;
