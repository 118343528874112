/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps*/
/* global jQuery */
import React, { useEffect, useRef } from 'react';
import getValueAtPath from '../../helpers/getValueAtPath';

export default function MultiSelect(props) {
  const { uniqueIdentifierForInput, input, value, changeHandler } = props;
  const {
    dropdownType,
    label,
    required,
    options,
    disabled,
    ...restInput
  } = input;

  // console.log('options', label, '--', options);

  const optionsList = getValueAtPath(props.state, options.key);

  const currentRef = useRef();
  const currentVal = useRef(value);

  const handleChange = () => {
    const newVal = jQuery(currentRef.current).val();
    currentVal.current = newVal;
    changeHandler && changeHandler(newVal);
    if (props.fieldUpdated && props.stepIndex >= 0)
      props.fieldUpdated(props.stepIndex);
  };
  const handleFieldUpdated = e => {
    props.fieldUpdated(props.stepIndex);
    if (props.masterfield) {
      props.visibilityFunc(props.masterfield, e.target.value);
    }
  };
  if (dropdownType === 'single') {
    restInput.defaultValue = currentVal.current;
  } else {
    // restInput.value = currentVal.current;
    restInput.value = value;
  }
  useEffect(() => {
    if (dropdownType !== 'single') {
      jQuery(currentRef.current).select2({
        width: '100%',
        height: '36px',
        tags: false,
        placeholder: 'Please Select',
        allowClear: false,
        disabled: disabled
      });
      jQuery(currentRef.current).on('change', handleChange);
      return () => {
        jQuery(currentRef.current).off('change', handleChange);
      };
    }
  }, [props]);

  return (
    <>
      <label htmlFor={uniqueIdentifierForInput}>
        {label}
        <span className="color-FF2300">
          {required ||
          (input.validation && input.validation === 'required') ||
          input.showrequired ||
          input.preOnboardRequiredField
            ? ' * '
            : ''}
        </span>
      </label>
      <select
        ref={currentRef}
        id={uniqueIdentifierForInput}
        multiple={dropdownType === 'single' ? '' : 'multiple'}
        className="form-control"
        {...restInput}
        disabled={
          disabled || !optionsList || !optionsList.length || props.disabled
        }
        name={uniqueIdentifierForInput}
        onChange={e =>
          props.fieldUpdated && props.stepIndex >= 0
            ? handleFieldUpdated(e)
            : false
        }
        required={props.input.required}>
        {dropdownType === 'single' && <option>Please Select</option>}
        {!options.key &&
          options.map((opt, optindex) => {
            return (
              <option
                value={opt.value}
                key={`${uniqueIdentifierForInput}-${optindex}`}>
                {opt.text}
              </option>
            );
          })}
        {options.key &&
          (optionsList || []).map((opt, optindex) => {
            return (
              <option
                value={opt[options.valuekey]}
                key={`${uniqueIdentifierForInput}-${optindex}`}>
                {opt[options.displaykey]}
              </option>
            );
          })}
      </select>
    </>
  );
}
