import React, { useState, useEffect } from 'react';
import { EndPoint } from '../../constants';
import HttpRequest from '../../helpers/http-request';

const NgoConditionalMettaVerified = props => {
  const [
    conditionalAssociationDetails,
    setConditionalAssociationDetails
  ] = useState([]);
  const [corpArr, setCorpArr] = useState([]);

  let ngoId = props.state.ngo.ngoProfile.ngo_id;

  useEffect(() => {
    if (ngoId) {
      let instance = HttpRequest.getInstance('HIROKU');
      instance
        .get(
          EndPoint.getApiUrl(
            'NGO',
            'ADMIN_ASSOCIATION_DETAILS',
            'HIROKU'
          ).replace('{ngo_id}', ngoId)
        )
        .then(res => setConditionalAssociationDetails(res.data.result));
    }
  }, [ngoId]);

  useEffect(() => {
    const data = conditionalAssociationDetails.filter(
      x => x.status_name === 'Approved NGO'
    );
    if (data) {
      let obj = {};
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let cur = data[i];
        if (obj[cur['corp_id']] === undefined) {
          obj[cur['corp_id']] = 1;
          let curr = cur;
          curr['product_name'] = [`${curr['product_name']}`];
          arr.push(curr);
        } else {
          obj[cur['corp_id']] += 1;
          let curr = cur;
          let X = arr.filter((el, i) => {
            return el['corp_id'] === cur['corp_id'];
          });
          X[0]['product_name'].push(`${curr['product_name']}`);
        }
      }
      setCorpArr(arr);
    }
  }, [conditionalAssociationDetails]);

  return (
    <>
      <div className="p-3 mt-2 bg-white overflow-scroll-35vh ">
        <div className=" px-0 bg-F2F9FF h-auto py-2">
          <div className="row mt-4">
            <p className="col-8 fs-14 fw-500">Approvals</p>
          </div>
          <hr className="text-7e9fc2 w-100" />
          <div className="ml-3">
            {corpArr.map(data => {
              return (
                <>
                  <p className="text-005492  fs-16">{data.corp_name}</p>
                  <p>
                    {data?.product_name.length === 1 &&
                      data?.product_name[0] === 'iGiv_XN' && (
                        <span className="approveWP fw-500 py-1 px-2">
                          iGiv-XN
                        </span>
                      )}
                    {data?.product_name.length === 1 &&
                      data?.product_name[0] === 'Socio-XN' && (
                        <span className="approveSN fw-500 py-1 px-2">
                          Socio-XN
                        </span>
                      )}
                    {data.product_name.length === 2 &&
                      (data?.product_name[0] === 'Socio-XN' ||
                        data?.product_name[1] === 'iGiv_XN' ||
                        data?.product_name[0] === 'iGiv_XN' ||
                        data?.product_name[1] === 'Socio-XN') && (
                        <>
                          {' '}
                          <span className="approveSN fw-500 py-1 px-2 mr-2">
                            Socio-XN
                          </span>
                          <span className="approveWP fw-500 py-1 px-2">
                            iGiv-XN
                          </span>
                        </>
                      )}
                  </p>
                  <hr className="text-7e9fc2 w-100" />
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default NgoConditionalMettaVerified;
