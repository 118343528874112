import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Header from '../../../components/header';
import PageContentWrapper from '../../../components/pageContentWrapper';
import './dashboard.css';
import allocatedGraphFunds from '../../../assets/images/Allocated-Funds.svg';
import balancedFunds from '../../../assets/images/Balance-Funds.svg';
import consumedFunds from '../../../assets/images/funds-consumed.svg';
import disbursedFunds from '../../../assets/images/Disbursed-Funds.svg';
import inHandFunds from '../../../assets/images/in-hand-funds.svg';
//import backArrow from '../../../assets/images/arrow-back.svg';
//import arrowRight from '../../../assets/images/arrow-right.svg';
import AllActivites from '../../../components/newDesign/allActivites';
import GoalsIndicators from '../../../components/newDesign/GoalsIndicators';
import FundUtilizations from '../../../components/newDesign/fundUtilization';
import Beneficiaries from '../../../components/newDesign/beneficiaries';
import TimelineUpdates from '../../../components/newDesign/timelineUpdates';
// import DonutFundsChart from '../../../components/donutChart/donutFundsChart';
import Modal from '../../../components/newDesign/Modals/Modal';
import FileInputMultipleNew from '../../../components/fileInputMultipleNew';
import TextArea from '../../../components/textArea';
import { convertFormDataToJson } from '../../../helpers/profileHelpers';
import DashboardDonutCharts from './donutCharts';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateFormatMMDDYYYY } from '../../../components/component/dateFormat';
import {
  getAllProjects,
  getProjectActivities,
  getGoalsIndicators,
  getProjectExpenses,
  createOrModifyActivities,
  modifyProjectActivities,
  getMNEBeneficiariesDynamic,
  updateActivity,
  creategoalHistory,
  updateProject,
  getGoalHistory,
  getTimeline,
  createTimeline,
  addExpense,
  deleteExpense,
  getExpenseCategory,
  getActualAndPlannedBudget,
  getAllExpenseSubCategory,
  getFundInformation,
  getExpenseType,
  selectProjectBeneficiariesType
} from '../../../actions/mneDashboard.actions';
import { InputTextArea } from '../../../components/FormElements';
import { getNGOCorpForPublished } from '../../../actions/ngo.actions';

import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { convertInCommaFormat } from '../../../helpers/commaSeparatedInput';
import DynamicBeneficiaries from '../../../components/newDesign/dynamicBeneficiaries';
import SelectBeneficiaryType from '../../../components/newDesign/selectBeneficiaryType';
import { isAuthorized } from '../../../helpers/auth-roles';
import { Routes } from '../../../constants/path.constants';
import { getPathMatch } from '../../../constants/role.constants';
import { getUserPagePolicy } from '../../../components/securedHOC';
dayjs.extend(customParseFormat);
// var totalDisbursedFund = 0;
// var fundProposed = 0;
// var fundRequestedForProject = 0;
class MNESingleDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'Dashboard',
      showModal: false,
      showViewMoreModal: false,
      utilizedFund: [],
      requestedFundForProject: [],
      projectBeneficiaryType: '',
      activityList: [],
      allProjectData: {},
      goals: [],
      defaultGoals: [],
      expenses: [],
      fundsUtilization: [],
      consumedFunds: [],
      allocatedGraphFunds: [],
      currentProjectData: {},
      csvData: [],
      toFilterExpense: [],
      timelines: [],
      fundInformation: {},
      totalInHandFund: 0,
      expenseTypes: [],
      list: '',
      editActivity: true,
      activeIdx: 2,
      totalDisbursedFund: 0,
      fundProposed: 0,
      Tabs: [
        'Dashboard',
        'Indicators',
        'Activities',
        'Finance',
        'Timeline & Updates',
        'Beneficiaries'
      ],
      addGoal: {
        isValid: false,
        value: '',
        error: ''
      },
      projectGoal: null,
      editGoal: true,
      expenseCategoryList: [],
      // subExpenseCategoryList:[],
      expenseDescriptionList: [],
      allExpenseSubCategoryList: [],
      proposedExpenseCategoriesData: [],
      dynamicBeneficiaries: [],
      indicatorsPermission: true,
      activitiesPermission: true,
      financePermission: true,
      timelineUpdatesPermission: true,
      beneficiariesPermission: true
    };
    this.input = {
      label: 'Comments',
      rows: '4',
      validation: 'required',
      maxLength: 3000
    };
  }

  componentDidMount() {
    this.getAllProjects(window.location.pathname.split('/')[3]);
    this.getProjectActivities(window.location.pathname.split('/')[3]);
    this.getMNEBeneficiariesDynamic(window.location.pathname.split('/')[3]);
    this.getGoalsIndicators();
    this.getTimeline();
    this.getExpenseType();
    this.getAllExpenseSubCategory();
    this.setPermission();
  }
  calculateDays = (campaignStartDate, campaignEndDate) => {
    let date_obj1 = dayjs(campaignStartDate);
    let date_obj2 = dayjs(campaignEndDate);
    return (this.dateDiff = date_obj2.diff(date_obj1, 'days'));
  };
  // comgetNGOCorpForPublished = ngoId => {
  //   this.props
  //     .getNGOCorpForPublished(ngoId)
  //     .then(res => {
  //       // let corpList = res?.result?.final_cond_list_for_campaign.filter(
  //       //   obj => obj.id === '169ee098-2bb3-4fd2-a8a6-6fc09e5a6f95'
  //       // );
  //       // if (corpList.length === 0) {
  //       //   this.setselectProjectBeneficiariesType('Manual');
  //       // } else {
  //       //   this.setselectProjectBeneficiariesType('Dynamic');
  //       // }
  //     })
  //     .catch(e => {});
  // };
  setselectProjectBeneficiariesType = type => {
    let payload = {
      project_id: parseInt(window.location.pathname.split('/')[3]),
      is_beneficiary_dynamic: type === 'Manual' ? false : true
    };
    this.props
      .selectProjectBeneficiariesType(payload)
      .then(res => {
        this.setState({ projectBeneficiaryType: type });
      })
      .catch(error => console.error(error));
  };

  getAllProjects = async campaignId => {
    if (campaignId) {
      var total = 0;
      this.props.getFundInformation(this.props.userProfile.sub).then(res => {
        if (res?.length > 0) {
          const filtered = res.filter(itm => {
            return itm.campaign_id === parseInt(campaignId);
          });
          if (filtered?.length > 0) {
            total =
              total +
              parseInt(
                filtered[0].sanctioned !== 'None' ? filtered[0].sanctioned : 0
              );
          }
          // totalDisbursedFund = total;
          this.setState({
            totalDisbursedFund: total,
            totalInHandFund: total - this.state.fundProposed
          });
        }
      });

      setTimeout(() => {
        this.props
          .getAllProjects(campaignId)
          .then(async res => {
            var filteredCampaign = res.list.filter(itmList => {
              return itmList.id === parseInt(campaignId);
            });
            this.setState({
              allProjectData: res,
              requestedFundForProject: filteredCampaign[0]?.allocated_funds
            });
            let list = this.state.allProjectData.list;
            const campaign = list?.filter(
              itm => itm.id === parseInt(window.location.pathname.split('/')[3])
            )[0];

            if (campaign !== undefined) {
              if (campaign.is_beneficiary_dynamic === false) {
                this.setState({ projectBeneficiaryType: 'Manual' });
              } else if (campaign.is_beneficiary_dynamic === true) {
                this.setState({ projectBeneficiaryType: 'Dynamic' });
              }
            }

            this.setState({
              // fundInformation: res.result.fund_information,
              allocatedGraphFunds: [
                {
                  name: 'Disbursed Funds',
                  // value: res.result.fund_information.disbursed_funds
                  value: this.state.totalDisbursedFund
                },
                {
                  name: 'Yet to be Disbursed',
                  // value: res.result.fund_information.balance_funds
                  value:
                    this.state.requestedFundForProject -
                    this.state.totalDisbursedFund
                }
              ],
              utilizedFund: [
                {
                  name: 'Balance Funds',
                  // value: parseInt(res.result.fund_information.balance_funds),
                  value:
                    this.state.totalDisbursedFund - this.state.fundProposed,
                  fill: '#E47E09'
                },
                {
                  name: 'Disbursed Funds',
                  value: this.state.totalDisbursedFund,
                  // value: res.result.fund_information.disbursed_funds
                  //   ? parseInt(res.result.fund_information.disbursed_funds)
                  //   : 0,
                  fill: '#DFCF03'
                }
              ],
              fundRequestedForProject: [
                {
                  name: 'Allocated Funds',
                  // value: parseInt(res.result.fund_information.allocated_funds),
                  value: this.state.requestedFundForProject,
                  fill: '#007997'
                }
              ]
            });

            res.list.map(item => {
              if (item.id === Number(campaignId)) {
                const obj = {
                  value: item.project_goal,
                  error: ''
                };
                this.setState({
                  list: item.name,
                  currentProjectData: item,
                  addGoal: obj,
                  editGoal: !obj.value || obj.value === '' ? true : false
                });
              }
            });
          })
          .catch(async err => {
            toast.error(
              err.error
                ? err.error
                : 'Uh oh! Some unexpected error has occured while fetching this Project! Please try again in sometime.'
            );
          });
      }, 500);
    }
  };

  getProjectActivities = campaignId => {
    let date = new Date();
    let activityArr = [];
    this.props
      .getProjectActivities(campaignId)
      .then(res => {
        res.map((item, index) => {
          let days = this.calculateDays(
            item.actual_end_date !== null
              ? item.actual_end_date
              : item.planned_end_date,
            date
          );

          // if (days > 0) {
          //   status = 'Delayed';
          // } else if (days < 0) status = 'Upcoming';
          // else if (days === 0) status = 'In Progress';

          let obj = {
            id: item.id,
            milestone: item.milestone,
            quarter: item.quarter,
            phase: item.phase_id,
            activity: item.activity,
            actual_start_date: item.actual_start_date
              ? item.actual_start_date
              : item.planned_start_date,
            actual_end_date: item.actual_end_date
              ? item.actual_end_date
              : item.planned_end_date,
            planned_start_date: item.planned_start_date,
            planned_end_date: item.planned_end_date,
            status: item.status === 'Yet To Start' ? 'Upcoming' : item.status,
            delayedByDays: days,
            attachment: item.attachment
          };
          activityArr.push(obj);
          return false;
        });

        this.setState({ activityList: activityArr });
      })
      .catch(async err => {});
    this.props
      .getExpenseCategory()
      .then(res => {
        this.setState({ expenseCategoryList: res.result });
        this.getProjectExpenses();
      })
      .catch(async err => {});

    this.getProposedExpensesCategories(window.location.href.split('/').pop());

    //ank-change api function name for expense description once the api is ready
    // this.props
    //   .getExpenseCategory()
    //   .then(res => {
    //     this.setState({ expenseDescriptionList: res });
    //   })
    //   .catch(async err => {});
    //ends
  };
  getMNEBeneficiariesDynamic = campaignId => {
    this.props
      .getMNEBeneficiariesDynamic(campaignId)
      .then(res => {
        this.setState({ dynamicBeneficiaries: res });
      })
      .catch(err => {
        console.error(err);
      });
  };

  getProposedExpensesCategories = campaignId => {
    this.props
      .getActualAndPlannedBudget(campaignId)
      .then(res => {
        if (res.code === '200') {
          this.setState({ proposedExpenseCategoriesData: res?.result });
        }
      })
      .catch(async err => {});
  };

  getExpenseType() {
    this.props
      .getExpenseType()
      .then(res => {
        this.setState({ expenseTypes: res.result });
      })
      .catch(async err => {});
  }
  getAllExpenseSubCategory() {
    this.props
      .getAllExpenseSubCategory()
      .then(res => {
        this.setState({ allExpenseSubCategoryList: res.result });
      })
      .catch(async err => {});
  }
  getRole() {
    const resAccessArr = this.props.userProfile.resource_access;
    if (resAccessArr) {
      const appEnv = process.env.REACT_APP_ENV;
      const resAccessRoles = resAccessArr[appEnv + 'mettasocial-npo'].roles;
      const superAdmin = resAccessRoles.filter(x =>
        x.toLowerCase().includes('superadmin')
      );
      const admin = resAccessRoles.filter(x =>
        x.toLowerCase().includes('admin')
      );
      const manager = resAccessRoles.filter(x =>
        x.toLowerCase().includes('manager')
      );
      const finance = resAccessRoles.filter(x =>
        x.toLowerCase().includes('finance')
      );
      if (superAdmin > 0) {
        return superAdmin[0];
      } else if (admin.length > 0) {
        return admin[0];
      } else if (manager.length > 0) {
        return manager[0];
      } else if (finance.length > 0) {
        return finance[0];
      }
    }
  }

  setPermission() {
    let indicatorsPermission = {
      objFound: false,
      resource_feature: 'Update Actual values against Indicator',
      value: true
    };
    let activitiesPermission = {
      objFound: false,
      resource_feature: 'Update Activities start and end dates',
      value: true
    };
    let financePermission = {
      objFound: false,
      resource_feature: 'Manage Expenses',
      value: true
    };
    let timelineUpdatesPermission = {
      objFound: false,
      resource_feature: 'Update Timeline',
      value: true
    };
    let beneficiariesPermission = {
      objFound: false,
      resource_feature: 'Beneficiaries',
      value: true
    };
    const pageObjArr = getPathMatch(Routes.NGO.MNESINGLEDASHBOARD);
    const policies = JSON.parse(window.localStorage.getItem('userPolicies'));
    let policy = getUserPagePolicy(pageObjArr, policies);
    const role = this.getRole();

    policy = policy.filter(x =>
      x.role.toLowerCase().includes(role.toLowerCase())
    );
    policy &&
      policy.map((pageObj, index) => {
        if (
          pageObj.resource_feature === indicatorsPermission.resource_feature &&
          pageObj.permissions === 'R' &&
          !indicatorsPermission.objFound
        ) {
          indicatorsPermission.value = false;
          indicatorsPermission.objFound = true;
        }
        if (
          pageObj.resource_feature === activitiesPermission.resource_feature &&
          pageObj.permissions === 'R' &&
          !activitiesPermission.objFound
        ) {
          activitiesPermission.value = false;
          activitiesPermission.objFound = true;
        }
        if (
          pageObj.resource_feature === financePermission.resource_feature &&
          pageObj.permissions === 'R' &&
          !financePermission.objFound
        ) {
          financePermission.value = false;
          financePermission.objFound = true;
        }
        if (
          pageObj.resource_feature ===
            timelineUpdatesPermission.resource_feature &&
          pageObj.permissions === 'R' &&
          !timelineUpdatesPermission.objFound
        ) {
          timelineUpdatesPermission.value = false;
          timelineUpdatesPermission.objFound = true;
        }
        if (
          pageObj.resource_feature ===
            beneficiariesPermission.resource_feature &&
          pageObj.permissions === 'R' &&
          !beneficiariesPermission.objFound
        ) {
          beneficiariesPermission.value = false;
          beneficiariesPermission.objFound = true;
        }
        return false;
      });
    this.setState({
      indicatorsPermission: indicatorsPermission.value,
      activitiesPermission: activitiesPermission.value,
      financePermission: financePermission.value,
      timelineUpdatesPermission: timelineUpdatesPermission.value,
      beneficiariesPermission: beneficiariesPermission.value
    });
  }
  getGoalsIndicators = () => {
    this.props
      .getGoalsIndicators(window.location.pathname.split('/')[3])
      .then(res => {
        let defaultGoalsArr = [];
        res.map(item => {
          defaultGoalsArr.push({ achived_target: item.achived_target });
          item['error'] = {
            achievedTarget: '',
            indicator_date: ''
          };
          return false;
        });
        this.setState({
          goals: res,
          defaultGoals: defaultGoalsArr
        });
      })
      .catch(async err => {});
  };
  getProjectExpenses = () => {
    this.props
      .getProjectExpenses(window.location.pathname.split('/')[3])
      .then(res => {
        let expense = 0;
        res.result.project_expenses_list.forEach(function(value, key) {
          if (value.actual_amount !== null && value.actual_amount !== 0) {
            expense = expense + parseInt(value.actual_amount);
          }
        });
        if (res.result.project_expenses_list?.length > 0) {
          this.setState({ fundProposed: expense });
        }

        let CSVArr = [];
        res.result.project_expenses_list.map(item => {
          var expenseCategoryName = this.state.expenseCategoryList?.find(
            itm => itm.id === item.expense_category_id
          );
          if (item.actual_amount !== null && item.actual_amount !== 0) {
            CSVArr.push({
              'Expense Category': expenseCategoryName.name,
              Details: item.expense_description,
              'Expense Type': item.expense_type,
              // 'Expense Sub-Category' : item.expense_sub_Category,
              Amount: item.actual_amount,
              'Expense Date': dateFormatMMDDYYYY(item.expense_date),
              // 'Created Date': item.created_date,
              // Id: item.id,
              Receipt: item.reciept
            });
          }
        });
        let arr = [];

        res.result.project_expenses_list.map(item => {
          if (this.state.expenseCategoryList.length) {
            var expenseCategoryName = this.state.expenseCategoryList?.find(
              record => record.id === item.expense_category_id
            );
          }

          const itemIndex = arr.findIndex(
            x => x.name === expenseCategoryName.name
          );
          if (itemIndex > -1) {
            arr[itemIndex].value = arr[itemIndex].value + item.actual_amount;
          } else {
            arr.push({
              name: expenseCategoryName.name,
              value: item.actual_amount
            });
          }
          return false;
        });

        let sum = 0;
        res.result.project_expenses_list.forEach(item => {
          sum += item.actual_amount;
        });

        let disburbursedFundsArr = [
          { name: 'Consumed Funds', value: sum },
          {
            name: 'In-Hand Funds',
            value:
              this.state.totalDisbursedFund - sum > 0
                ? this.state.totalDisbursedFund - sum
                : 0
            // value:
            //   this.state.fundInformation.disbursed_funds - sum > 0
            //     ? res.fund_information.disbursed_funds - sum
            //     : 0
          }
        ];

        const sortedExpenses = res.result.project_expenses_list.sort((a, b) => {
          if (a?.id > b?.id) {
            return 1;
          }
          if (a?.id < b?.id) {
            return -1;
          }
          return 0;
        });
        this.setState({
          expenses: sortedExpenses,
          toFilterExpense: res?.result?.data,
          csvData: CSVArr,
          fundsUtilization: arr !== null ? arr : '',
          consumedFunds: disburbursedFundsArr
        });
      })
      .catch(async err => {});
  };

  getProjectExpensesByDate = (fromDate, toDate) => {
    let filteredExpenses = this.state.toFilterExpense.filter(x =>
      dayjs(x.created_date).isBetween(
        new Date(fromDate).toISOString().split('T')[0],
        new Date(toDate).toISOString().split('T')[0]
      )
    );

    let CSVArr = [];
    filteredExpenses.map(item => {
      var expenseCategoryName = this.state.expenseCategoryList?.find(
        item => item.id === item.expense_category_id
      );
      CSVArr.push({
        Amount: item.amount,
        CampaignId: item.campaign_id,
        'Expense Category': expenseCategoryName,
        'Created Date': item.created_date,
        Description: item.expense_description,
        Date: item.expense_date,
        Receipt: item.reciept
      });
    });

    this.setState({ expenses: filteredExpenses, csvData: CSVArr });
  };
  getTimeline = () => {
    this.props
      .getTimeline(window.location.pathname.split('/')[3])
      .then(res => {
        this.setState({ timelines: res });
      })
      .catch(async err => {});
  };
  // getExpenseCategories = () => {
  //   this.props
  //     .getExpenseCategory()
  //     .then(res => {
  //
  //       this.setState({ expenseCategoryList: res });
  //     })
  //     .catch(async err => {});
  // };
  setGoalList = list => {
    this.setState({ goals: list });
  };

  validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';
    switch (name) {
      case 'addGoal':
        if (value && value.toString().trim().length === 0)
          msg = 'Goal required';
        if (value.length < 4) msg = 'Minimum 4 characters required';
        break;
      default:
    }
    return { isFieldValid, msg };
  };
  onSetValueAndError = event => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;
    const errorResult = this.validate(id, value);
    const obj = {
      isValid: errorResult.isFieldValid,
      error: errorResult.msg,
      value
    };
    this.setState({ addGoal: obj });
  };
  saveGoal = e => {
    e.preventDefault();
    const obj = {
      project_goal: this.state.addGoal.value,
      campaign_id: parseInt(window.location.pathname.split('/')[3]),
      is_project_complete: false
    };
    this.props
      .updateProject(obj)
      .then(res => {
        toast.success('Goal updated successfully`');
        this.setState({ editGoal: false });
      })
      .catch(err => toast.error('Error in updating goal'));
  };

  handlePrint = () => {
    if (window) {
      window.print();
    }
  };
  validate = (name, value) => {
    let error = '';
    const elements = document.getElementsByClassName(`${name}-error`);
    if (name === 'comment') {
      if (value.trim() === '') error = `Please fill in this field`;
    } else if (name === 'fundUtilisation') {
      if (value.trim() === '')
        error = `Please upload the necessary document(s)`;
    }
    if (elements.length > 0) {
      if (error === '') {
        elements[0].classList.remove('d-none');
      } else {
        elements[0].classList.remove('d-none');
      }
      elements[0].innerHTML = error;
    }
    return error === '' ? true : false;
  };
  onClick = e => {
    e.preventDefault();
    let isValid = true;
    let data = convertFormDataToJson(new FormData(e.target));
    for (let key in data) {
      const valid = this.validate(key, data[key]);
      if (isValid && !valid) isValid = false;
    }
    if (!isValid) {
      return toast.error(
        'Oops! Error(s) found. Please fix the Error(s) and save the data.'
      );
    } else {
      const obj = {
        comment: data.comment,
        files: data.fundUtilisation.includes(',')
          ? data.fundUtilisation.split(',')
          : [data.fundUtilisation],
        is_project_complete: true,
        campaign_id: parseInt(window.location.pathname.split('/')[3])
      };
      this.props
        .updateProject(obj)
        .then(res => {
          toast.success('Project completed successfully');
        })
        .catch(err => toast.error('Error in updating project'));
    }
    setTimeout(() => {
      let campaignId = parseInt(window.location.pathname.split('/')[3]);
      window.location.href = '/ngo/mneSingleProject/' + campaignId;
    }, 500);
  };
  handleOpenCloseModal = () => {
    let body = document.body;
    if (this.state.showModal) {
      this.setState({ showModal: false });
      body.style.overflow = 'auto';
    } else {
      this.setState({ showModal: true });
      body.style.overflow = 'hidden';
    }
  };

  handleOpenCloseViewMoreModal = () => {
    let body = document.body;
    if (this.state.showViewMoreModal) {
      this.setState({ showViewMoreModal: false });
      body.style.overflow = 'auto';
    } else {
      this.setState({ showViewMoreModal: true });
      body.style.overflow = 'hidden';
    }
  };

  showCampaignProposal = e => {
    let campaignId = window.location.pathname.split('/')[3];
    window.location.href = '/ngo/project/detail/' + campaignId + '#mne';
    // "/ngo/mneSingleProject/" + campaignId;
  };
  setBenefType = value => {
    this.setState({ projectBeneficiaryType: value });
    this.getAllProjects(window.location.pathname.split('/')[3]);
  };

  render() {
    const permissions = this.props.permissions;
    const isUserAuthorized = isAuthorized(this.props.permissions);
    const isImpersonator = window.location.href.includes('email=');
    return (
      <React.Fragment>
        <Header pageTitle={this.state.list} />

        <PageContentWrapper>
          <div className="mt-4 mx-4 d-flex w-100 justify-content-start align-items-center">
            {/*<Link
              className="text-dark fs-14 cursor-pointer mr-4"
              to="/ngo/ngoDashboard">
              <img
                src={backArrow}
                alt="arrow-right"
                width={15}
                height={15}
                className="mx-2"
              />
              {` Back`}
            </Link>*/}
            <Link
              className="text-1FA4FA fs-14 cursor-pointer"
              to="/ngo/ngoDashboard">
              {`Dashboard `}&nbsp;{' > '}
            </Link>
            {/*<img
              src={arrowRight}
              alt="arrow-right"
              width={18}
              height={18}
              className="mx-2"
            />*/}
            <div className="text-1FA4FA fs-14">
              &nbsp;{`Monitoring & Evaluation`}
            </div>
            {/*<img
              src={arrowRight}
              alt="arrow-right"
              width={18}
              height={18}
              className="mx-2"
            />
            <div className="monitoringText  text-overflow-ellipsis fs-14">
              {this.state.list}
             </div>*/}
          </div>
          <div className="d-flex justify-content-between align-items-center mx-4 mt-4">
            <div className="fs-22 campaignName w-50">{this.state.list}</div>
            {
              // this.state.tab === 'Dashboard' &&
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-secondary cursor-pointer btn-lg fs-13 mr-4"
                  onClick={e => this.showCampaignProposal(e)}>
                  View Proposal
                </button>
                {(isUserAuthorized || isImpersonator) &&
                  (this.state.currentProjectData?.is_project_complete !==
                  true ? (
                    <button
                      className="btn btn-primary cursor-pointer btn-lg fs-13 mr-4"
                      onClick={this.handleOpenCloseModal}>
                      Mark Project as Complete
                    </button>
                  ) : (
                    <span className="fs-18 color-81BC30 mx-4">
                      {' '}
                      Project Completed
                    </span>
                  ))}
                {/*<button
                  className="btn btn-primary btn-lg prompt fs-13 d-flex align-items-center"
                  onClick={this.handlePrint}>
                  <i className="fas fa-solid fa-upload fx-2 mr-3"></i>
                  <span className="fs-13 text-white prompt">
                    View Utilization Report
                  </span>
                </button>*/}
              </div>
            }
          </div>
          {this.state.editGoal ? (
            <div className="my-4">
              <h3 className="fs-18 col-12">Add Goal</h3>
              <InputTextArea
                required={true}
                id="addGoal"
                name="addGoal"
                maxLength={500}
                labelText=""
                PlaceHolder={'Maximum Length is 500 Characters'}
                fieldValue={this.state.addGoal.value}
                fieldError={this.state.addGoal.error}
                onHandleChange={this.onSetValueAndError}
                onHandleBlur={this.onSetValueAndError}
                className="mb-3"
                wrapperClassName={'col-12 mb-3'}
                permissions={permissions}
              />
              {(isUserAuthorized || isImpersonator) && (
                <div className="col-12 text-right">
                  <button
                    className="btn btn-primary btn-lg col-1"
                    onClick={e => this.saveGoal(e)}>
                    Save
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="my-4">
              <h4 className="fs-18 fw-400 col-12">
                Goal: {this.state.addGoal.value.substring(0, 300)}
                <span
                  className="color-1FA4FA fs-14 ml-4 cursor-pointer"
                  onClick={this.handleOpenCloseViewMoreModal}>
                  ...View more
                </span>
                {this.state.currentProjectData?.is_project_complete !== true &&
                  (isUserAuthorized || isImpersonator) && (
                    <span
                      className="color-1FA4FA fs-14 cursor-pointer float-right"
                      style={{ marginRight: '20px' }}
                      onClick={() => this.setState({ editGoal: true })}>
                      Edit Goal
                    </span>
                  )}
              </h4>
            </div>
          )}
          <>
            <ul className="nav nav-tabs text-dark pl-3 pr-3 sticky-tabs-mne mt-4">
              {this.state.Tabs?.map((tab, index) => (
                <li
                  className="nav-item bg-white mx-1 pr-0"
                  key={'tab ' + (index + 1)}>
                  <a
                    className={`nav-link font-weight-normal lead text-dark fs-18 ${
                      this.state.tab === tab ? 'active' : ''
                    }`}
                    data-toggle="tab"
                    href="/"
                    onClick={e => {
                      e.preventDefault();
                      window.scrollTo(0, 0);
                      this.setState({ tab: tab });
                    }}>
                    {tab}
                  </a>
                </li>
              ))}
            </ul>
          </>
          {this.state.tab === 'Dashboard' && (
            <div className="my-4 font-dmSans">
              <div className="row">
                <div className="col-12">
                  <div className="p-4 bg-white">
                    <h3 className="color-344256 fs-20 mb-2">Budget</h3>
                    <hr align="left" />
                    <div className="row">
                      <div className="col-6">
                        <h4 className="fs-16 font-weight-bold">Funds</h4>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="w-50 text-center">
                            <DashboardDonutCharts
                              size={'75%'}
                              dataArr={this.state.allocatedGraphFunds}
                              type={'semi'}
                              label={'Allocated Funds'}
                              labelValue={this.state.requestedFundForProject}
                              fontSize={'12px'}
                              labelValuePrepend={'₹ '}
                              colors={['#E47E09', '#007997']}
                              width="95%"></DashboardDonutCharts>
                          </div>
                          <div>
                            <h5 className="fs-14 font-weight-bold mb-3">
                              Allocated Funds: ₹{' '}
                              {new Intl.NumberFormat('en-IN').format(
                                this.state.requestedFundForProject
                              )}
                              {/* {convertInCommaFormat(
                                this.state.fundInformation.allocated_funds
                              )} */}
                            </h5>
                            <span className="d-flex justify-content-between mb-2">
                              <span>
                                <span className="chart-bullet d-inline-block bg-E47E09 mr-2 fs-12"></span>
                                Disbursed Funds
                              </span>
                              <span className="ml-3">
                                ₹{' '}
                                {new Intl.NumberFormat('en-IN').format(
                                  this.state.totalDisbursedFund
                                )}
                                {/* {convertInCommaFormat(
                                  this.state.allocatedGraphFunds[0]?.value
                                )} */}
                              </span>
                            </span>
                            <span className="d-flex justify-content-between mb-2">
                              <span>
                                <span className="chart-bullet d-inline-block bg-007997 mr-2 fs-12"></span>
                                Yet to be Disbursed
                              </span>
                              <span className="ml-3">
                                {/* { (requestedFundForProject - totalDisbursedFund) < 0 ?  0 : 0
                                new Intl.NumberFormat('en-IN').format(
                                  requestedFundForProject - totalDisbursedFund 
                                )
                                } */}
                                ₹{' '}
                                {this.state.requestedFundForProject -
                                  this.state.totalDisbursedFund ===
                                0
                                  ? 0
                                  : convertInCommaFormat(
                                      // this.state.allocatedGraphFunds[1]?.value
                                      this.state.requestedFundForProject -
                                        this.state.totalDisbursedFund
                                    )}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="w-50 text-center">
                            <DashboardDonutCharts
                              size={'75%'}
                              dataArr={this.state.consumedFunds}
                              type={'semi'}
                              label={'Disbursed Funds'}
                              labelValue={this.state.totalDisbursedFund}
                              fontSize={'12px'}
                              colors={['#DFCF03', '#89BB2A']}
                              labelValuePrepend={'₹ '}
                              width="95%"></DashboardDonutCharts>
                          </div>
                          <div>
                            <h5 className="fs-14 font-weight-bold mb-3">
                              Disbursed Funds: ₹{' '}
                              {convertInCommaFormat(
                                this.state.totalDisbursedFund
                              )}
                              {/* {this.state.fundInformation.disbursed_funds} */}
                            </h5>
                            <span className="d-flex justify-content-between mb-2">
                              <span>
                                <span className="chart-bullet d-inline-block bg-DFCF03 mr-2 fs-12"></span>
                                Utilised Funds
                              </span>
                              <span className="ml-3">
                                ₹
                                {' ' +
                                  new Intl.NumberFormat('en-IN').format(
                                    this.state.fundProposed
                                  )}
                                {/* {this.state.consumedFunds[0]?.value} */}
                              </span>
                            </span>
                            <span className="d-flex justify-content-between mb-2">
                              <span>
                                <span className="chart-bullet d-inline-block bg-89BB2A mr-2 fs-12"></span>
                                In-Hand Funds
                              </span>
                              <span className="ml-3">
                                ₹{' '}
                                {new Intl.NumberFormat('en-IN').format(
                                  this.state.totalDisbursedFund -
                                    this.state.fundProposed
                                )}
                                {/* {this.state.consumedFunds[1]?.value === 0
                                  ? this.state.consumedFunds[0]?.value
                                  : this.state.consumedFunds[1]?.value} */}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <h4 className="fs-16 font-weight-bold">
                          Funds Utilisation
                        </h4>
                        <div className="d-flex justify-content-between align-items-center h-100">
                          <div className="w-100 text-center dashboard-legends">
                            {this.state.fundsUtilization.length > 0 ? (
                              <DashboardDonutCharts
                                size={'70%'}
                                dataArr={this.state.fundsUtilization}
                                fontSize={'12px'}
                                labelValuePrepend={'₹ '}
                                donutName={'Funds Utilisation'}
                                colors={[
                                  '#89BB2A',
                                  '#FBBD08',
                                  '#F2711C',
                                  '#005492',
                                  '#84B72A',
                                  '#F7DC08',
                                  '#A3711C',
                                  '#AC05492'
                                ]}
                                width="75%"></DashboardDonutCharts>
                            ) : (
                              <h4 className="fs-16 font-weight-bold">
                                No Data Found
                              </h4>
                            )}
                          </div>
                          {/* <div> */}
                          {/* {this.state.fundsUtilization?.map(
                              (funds, index) => (
                                <span
                                  className="d-flex justify-content-between mb-2"
                                  key={index}>
                                  <span>
                                    <span className="chart-bullet d-inline-block bg-89BB2A mr-2 fs-12"></span>
                                    {funds.name}
                                  </span>
                                  <span className="ml-2">₹ {funds.value}</span>
                                </span>
                              )
                            )} */}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="my-4 fundBox">
                      <div className="row">
                        <div className="funds-card w-20 d-flex my-3">
                          <img
                            src={allocatedGraphFunds}
                            alt="fundRequestedForProjects"
                            width={50}
                            height={50}
                          />
                          <div className="d-flex flex-column mx-3 justify-content-between">
                            <p className="text-344256 m-0">Allocated Funds</p>
                            <h3 className="text-344256 m-0">
                              ₹{' '}
                              {new Intl.NumberFormat('en-IN').format(
                                // this.state.requestedFundForProject
                                this.state.requestedFundForProject
                              )}
                              {/* {convertInCommaFormat(
                                this.state.fundInformation.allocated_funds
                              )} */}
                            </h3>
                          </div>
                        </div>
                        <div className="funds-card w-20 d-flex my-3">
                          <img
                            src={disbursedFunds}
                            alt="disbursedFunds"
                            width={50}
                            height={50}
                          />
                          <div className="d-flex flex-column mx-3 justify-content-between">
                            <p className="text-344256 m-0">Disbursed Funds</p>
                            <h3 className="text-344256 m-0">
                              ₹{' '}
                              {new Intl.NumberFormat('en-IN').format(
                                this.state.totalDisbursedFund
                              )}
                              {/* {this.state.fundInformation.disbursed_funds
                                ? convertInCommaFormat(
                                    this.state.fundInformation.disbursed_funds
                                  )
                                : 0} */}
                            </h3>
                          </div>
                        </div>
                        <div className="funds-card w-20 d-flex my-3">
                          <img
                            src={balancedFunds}
                            alt="balancedFunds"
                            width={50}
                            height={50}
                          />
                          <div className="d-flex flex-column mx-3 justify-content-between">
                            <p className="text-344256 m-0">
                              Yet to be Disbursed
                            </p>
                            <h3 className="text-344256 m-0">
                              ₹{' '}
                              {new Intl.NumberFormat('en-IN').format(
                                this.state.requestedFundForProject -
                                  this.state.totalDisbursedFund
                              )}
                              {/* {this.state.fundInformation.balance_funds
                                ? convertInCommaFormat(
                                    this.state.fundInformation.balance_funds
                                  )
                                : 0} */}
                            </h3>
                          </div>
                        </div>
                        <div className="funds-card w-20 d-flex my-3">
                          <img
                            src={consumedFunds}
                            alt="Consumed Funds"
                            width={50}
                            height={50}
                          />
                          <div className="d-flex flex-column mx-3 justify-content-between">
                            <p className="text-344256 m-0">Utilised Funds</p>
                            <h3 className="text-344256 m-0">
                              ₹{' '}
                              {new Intl.NumberFormat('en-IN').format(
                                this.state.fundProposed
                              )}
                              {/* {this.state.consumedFunds[0]?.value
                                ? convertInCommaFormat(
                                    this.state.consumedFunds[0]?.value
                                  )
                                : 0} */}
                            </h3>
                          </div>
                        </div>
                        <div className="funds-card w-20 d-flex my-3">
                          <img
                            src={inHandFunds}
                            alt="Consumed Funds"
                            width={50}
                            height={50}
                          />
                          <div className="d-flex flex-column mx-3 justify-content-between">
                            <p className="text-344256 m-0">In-Hand Funds</p>
                            <h3 className="text-344256 m-0">
                              ₹{' '}
                              {new Intl.NumberFormat('en-IN').format(
                                this.state.totalDisbursedFund -
                                  this.state.fundProposed
                              )}
                              {/* {this.state.fundInformation.disbursed_funds &&
                              this.state.consumedFunds[0]
                                ? convertInCommaFormat(
                                    this.state.fundInformation.disbursed_funds -
                                      this.state.consumedFunds[0]?.value
                                  )
                                : 0} */}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <AllActivites activityList={this.state.activityList} />
              </div>
            </div>
          )}
          {this.state.tab === 'Indicators' && (
            <GoalsIndicators
              goalsList={this.state.goals}
              defaultGoals={this.state.defaultGoals}
              creategoalHistory={this.props.creategoalHistory}
              getGoalHistory={this.props.getGoalHistory}
              setGoalList={this.setGoalList}
              getGoals={this.getGoalsIndicators}
              permissions={this.props.permissions}
              isUserAuthorized={this.state.indicatorsPermission}
            />
          )}
          {this.state.tab === 'Finance' && (
            <FundUtilizations
              totalDisbursedFund={this.state.totalDisbursedFund}
              fundRequestedForProject={this.state.requestedFundForProject}
              fundProposed={this.state.fundProposed}
              expenseList={this.state.expenses}
              expenseLists={this.state.expenseTypes}
              csvData={this.state.csvData}
              fundInformation={this.state.fundInformation}
              consumedFunds={this.state.consumedFunds}
              allocatedGraphFunds={this.state.allocatedGraphFunds}
              addExpense={this.props.addExpense}
              deleteExpense={this.props.deleteExpense}
              expenseCategoryList={this.state.expenseCategoryList}
              expenseDescriptionList={this.state.expenseDescriptionList}
              getProjectExpenses={this.getProjectExpenses}
              getProjectExpensesByDate={this.getProjectExpensesByDate}
              proposedExpenseCategoriesData={
                this.state.proposedExpenseCategoriesData
              }
              getProposedExpensesCategories={this.getProposedExpensesCategories}
              getAllProjects={this.getAllProjects}
              allExpenseSubCategoryList={this.state.allExpenseSubCategoryList}
              permissions={this.props.permissions}
              isUserAuthorized={this.state.financePermission}
            />
          )}
          {this.state.tab === 'Activities' && (
            <AllActivites
              tabActivity={this.state.editActivity}
              activityList={this.state.activityList}
              createOrModifyActivities={this.props.createOrModifyActivities}
              updateActivity={this.props.updateActivity}
              modifyProjectActivities={this.props.modifyProjectActivities}
              getProjectActivities={this.getProjectActivities}
              permissions={this.props.permissions}
              isUserAuthorized={this.state.activitiesPermission}
            />
          )}
          {this.state.tab === 'Timeline & Updates' && (
            <TimelineUpdates
              timelines={this.state.timelines}
              createTimeline={this.props.createTimeline}
              userProfile={this.props.userProfile}
              getTimeline={this.getTimeline}
              permissions={this.props.permissions}
              isUserAuthorized={this.state.timelineUpdatesPermission}
            />
          )}
          {this.state.tab === 'Beneficiaries' && (
            <>
              {(this.state.projectBeneficiaryType === '' ||
                this.state.projectBeneficiaryType === null) && (
                <SelectBeneficiaryType
                  id={window.location.pathname.split('/')[3]}
                  setType={value => this.setBenefType(value)}
                  permissions={this.props.permissions}
                  isUserAuthorized={this.state.beneficiariesPermission}
                />
              )}
              {this.state.projectBeneficiaryType === 'Dynamic' && (
                <DynamicBeneficiaries
                  id={window.location.pathname.split('/')[3]}
                  dynamicBeneficiaries={this.state.dynamicBeneficiaries}
                  permissions={this.props.permissions}
                  isUserAuthorized={this.state.beneficiariesPermission}
                />
              )}
              {this.state.projectBeneficiaryType === 'Manual' && (
                <Beneficiaries
                  goalsList={this.state.goals}
                  defaultGoals={this.state.defaultGoals}
                  creategoalHistory={this.props.creategoalHistory}
                  getGoalHistory={this.props.getGoalHistory}
                  setGoalList={this.setGoalList}
                  getGoals={this.getGoalsIndicators}
                  permissions={this.props.permissions}
                  isUserAuthorized={this.state.beneficiariesPermission}
                />
              )}
            </>
          )}
        </PageContentWrapper>
        <Modal
          show={this.state.showModal}
          title={'Mark Project as Complete'}
          handleCloseModal={this.handleOpenCloseModal}
          sizeClass={'modal-md'}>
          <form
            method="post"
            id="mark-project-complete"
            className="form-row"
            onSubmit={this.onClick}>
            <div className="form-group col-12">
              <FileInputMultipleNew
                label={'Please upload the Fund utilisation certificate.'}
                id={'fundUtilisation'}
                name={'fundUtilisation'}
                accept={'.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF'}
                fileSizeLimit={25}
                type={'file'}
                previewDoc={true}
                showrequired={true}
                value={[]}
                disabled={false}
                refId={this.props.userProfile.sub}
              />
              <p
                className={`text-danger fs-10 mb-0 fundUtilisation-error d-none`}></p>
            </div>
            <div className="form-group col-12">
              <TextArea
                uniqueIdentifierForInput={'comment'}
                input={this.input}
                savedValue={''}
                disabled={false}></TextArea>
              <label className="fs-10">Max 3000 characters</label>
              <p className={`text-danger fs-10 mb-0 comment-error d-none`}></p>
            </div>
            <div className="form-group col-md-12 d-flex justify-content-center mb-0">
              <button type="submit" className="btn btn-primary btn-lg col-5">
                Mark as Complete
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          show={this.state.showViewMoreModal}
          title={'Goal'}
          handleCloseModal={this.handleOpenCloseViewMoreModal}
          sizeClass={'modal-lg'}>
          <div className="fs-16 overflow p-4">
            <p>{this.state.addGoal.value}</p>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  userProfile: state.login.userInfo
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllProjects,
      getProjectActivities,
      getGoalsIndicators,
      getProjectExpenses,
      createOrModifyActivities,
      modifyProjectActivities,
      updateActivity,
      creategoalHistory,
      updateProject,
      getGoalHistory,
      getTimeline,
      createTimeline,
      addExpense,
      deleteExpense,
      getExpenseCategory,
      getActualAndPlannedBudget,
      getFundInformation,
      getExpenseType,
      getAllExpenseSubCategory,
      getNGOCorpForPublished,
      selectProjectBeneficiariesType,
      getMNEBeneficiariesDynamic
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(MNESingleDashboard);
