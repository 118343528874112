import React from 'react';
import Chart from 'react-apexcharts';

const DonutChartFunds = props => {
  let chartBlockSize = props.size;
  let labelArrBudget = [];
  let series = [];
  if (props.utilizedFund) {
    for (var i = 0; i < props.utilizedFund.length; i++) {
      labelArrBudget[i] = props.utilizedFund[i].name + ' : ' + '₹';
      new Intl.NumberFormat('en-IN').format(props.utilizedFund[i].value);
      series[i] = props.utilizedFund[i].value;
    }
  }
  const options = {
    labels: labelArrBudget,
    colors: props.colorCode,
    dataLabels: {
      enabled: false
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return '';
        },
        title: {
          formatter: function(labelArrBudget) {
            return labelArrBudget;
          }
        }
      },
      style: {
        fontSize: '12px',
        fontFamily: undefined,
        color: 'white'
      }
    },
    legend: {
      show: false
    },

    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          show: true,
          size: chartBlockSize,
          labels: {
            show: true,
            value: {
              show: true,
              offsetY: props.singleProject ? 8 : -8
            },
            total: {
              show: true,
              label: 'Allocated Funds',
              showAlways: true,
              fontSize: props.singleProject ? '14px' : '10px',
              color: '#344256',
              formatter: function() {
                return (
                  '₹ ' +
                  new Intl.NumberFormat('en-IN').format(
                    Math.round(props.allocatedFund[0].value * 100) / 100
                  )
                );
              }
            }
          }
        }
      }
    }
  };
  return (
    <Chart options={options} series={series} type="donut" width={'100%'} />
  );
};

export default DonutChartFunds;
