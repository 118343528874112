import { Action } from '../constants';
const initialState = {
  roles: null,
  userWithRoles: null,
  addUser: null,
  updateUser: null,
  deleteUser: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.ROLES_LIST_SUCCESS:
      return {
        ...state,
        roles: action.payload
      };
    case Action.USERS_WITH_ROLES_LIST_SUCCESS:
      return {
        ...state,
        userWithRoles: action.payload
      };
    case Action.ADD_USER_SUCCESS:
      return {
        ...state,
        addUser: action.payload
      };
    case Action.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUser: action.payload
      };
    case Action.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUser: action.payload
      };
    default:
      return state;
  }
};
