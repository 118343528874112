import React from 'react';
import { connect } from 'react-redux';
import { Common } from '../../constants';
import Header from '../../components/header';
import PageContentWrapper from '../../components/pageContentWrapper';
import PageContent from '../../components/pageContent';
import DonationReport from '../../components/donationReportList';
import { bindActionCreators } from 'redux';
import {
  getDonationReportList,
  getDonationReportExport,
  getDonationReportExport10Bd,
  sendReceiptToAllRecords
} from '../../actions/donation.report';
import {
  getDonationCorpList,
  getDonationMissionList,
  getDonationReceiptUploadedData,
  postDonationReceiptUploadedData
} from '../../actions';

function DonationReportScreen(props) {
  function getPageTitle() {
    return Common.NGO.PAGE_TITLE.DONATION_REPORTS;
  }
  function getDonationReport() {
    return (
      <div className="corporate-list">
        <DonationReport {...props} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Header pageTitle={getPageTitle()} />
      <PageContentWrapper>
        <PageContent mainScreen={getDonationReport()}></PageContent>
      </PageContentWrapper>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  state: state,
  donationList: state.donation_list.donationList
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDonationReportList,
      getDonationReportExport,
      getDonationReportExport10Bd,
      getDonationCorpList,
      getDonationMissionList,
      getDonationReceiptUploadedData,
      postDonationReceiptUploadedData,
      sendReceiptToAllRecords
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonationReportScreen);
