import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectProjectBeneficiariesType } from '../../actions';

export default function SelectBeneficiaryType({
  id,
  setType,
  permissions,
  isUserAuthorized
}) {
  const dispatch = useDispatch();
  const [templateType, setTemplateType] = useState(
    'Manual: I want to add beneficiaries'
  );
  const getActiveClass = label => {
    if (templateType === label) {
      return 'activeType';
    }
  };
  const handleRadioButtonChange = label => {
    if (templateType === label) {
      setTemplateType('');
    } else {
      setTemplateType(label);
    }
  };
  const handleClick = () => {
    const payload = {
      project_id: parseInt(id),
      is_beneficiary_dynamic:
        templateType === 'Manual: I want to add beneficiaries' ? false : true
    };
    if (templateType === 'Manual: I want to add beneficiaries') {
      setType('Manual');
    } else {
      setType('Dynamic');
    }

    dispatch(selectProjectBeneficiariesType(payload));
  };

  return (
    <div>
      <div className="container-fluid mt-4  px-0">
        <div className="mx-3 my-4 bg-white p-4">
          <div className="">
            <p className="fs-20 fw-600">Beneficiaries</p>
            <p className="text-1B1C1D fs-14">
              To record your data for beneficiaries, select one of the following
              ways you would prefer.
            </p>
          </div>
          <div className="row px-0 my-4">
            <div
              className={`col-4 pl-0 border rounded p-3 ${getActiveClass(
                'Manual: I want to add beneficiaries'
              )}`}
              onClick={() =>
                handleRadioButtonChange('Manual: I want to add beneficiaries')
              }>
              <div className="d-flex">
                <label className="custom-radio mr-3">
                  <input
                    type="radio"
                    value="Manual: I want to add beneficiaries"
                    checked={
                      templateType === 'Manual: I want to add beneficiaries'
                    }
                    onChange={() =>
                      handleRadioButtonChange(
                        'Manual: I want to add beneficiaries'
                      )
                    }
                    disabled={false}
                    permissions={permissions}
                  />
                </label>
                <div>
                  <p className="mb-0 text-1B1C1D fs-16 fw-500">
                    {'Manual: I want to add beneficiaries'}
                  </p>
                  <span className="text-969696">
                    Add or bulk upload beneficiaries in standard beneficiary
                    table
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`col-4 ml-5 border rounded p-3 d-flex ${getActiveClass(
                'Dynamic: I want to collect beneficiaries data by On-ground survey'
              )}`}
              onClick={() =>
                handleRadioButtonChange(
                  'Dynamic: I want to collect beneficiaries data by On-ground survey'
                )
              }>
              <div className="d-flex">
                <label className="custom-radio mr-3">
                  <input
                    type="radio"
                    value="Dynamic: I want to collect beneficiaries data by On-ground survey"
                    checked={
                      templateType ===
                      'Dynamic: I want to collect beneficiaries data by On-ground survey'
                    }
                    onChange={() =>
                      handleRadioButtonChange(
                        'Dynamic: I want to collect beneficiaries data by On-ground survey'
                      )
                    }
                    disabled={false}
                  />
                </label>
                <div>
                  <p className="mb-0 text-1B1C1D fs-16 fw-500">
                    {' '}
                    {
                      'Dynamic: I want to collect beneficiaries data by On-ground survey'
                    }
                  </p>
                  <span className="text-969696">
                    Create a form and assign to the implementation partners for
                    on-ground data collection
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {isUserAuthorized && (
              <button
                onClick={handleClick}
                className="btn btn-primary btn-lg"
                disabled={templateType === ''}>
                Save & Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
