import { Action } from '../constants';
const initialState = {
  campaignInfo: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.CAMPAIGN_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignInfo: action.payload
      };

    case Action.CAMPAIGN_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };

    case Action.CAMPAIGN_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.CAMPAIGN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createModifySuccessResponse: action.payload
      };

    case Action.CAMPAIGN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        createModifyErrorResponse: action.payload
      };
    //NGO - Campaign Edit screen
    case Action.CAMPAIGN_EDIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.CAMPAIGN_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        editSuccessResponse: action.payload,
        editErrorResponse: { code: action.payload.status }
      };
    case Action.CAMPAIGN_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        editErrorResponse: action.payload,
        editSuccessResponse: { code: action.payload.status }
      };

    //NGO - Campaign Edit screen
    case Action.CAMPAIGN_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.CAMPAIGN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccessResponse: action.payload,
        deleteErrorResponse: { code: action.payload.status }
      };
    case Action.CAMPAIGN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        deleteErrorResponse: action.payload,
        deleteSuccessResponse: { code: action.payload.status }
      };

    case Action.CAMPAIGN_RESET_RESPONSE:
      return {
        ...state,
        editErrorResponse: { code: null },
        editSuccessResponse: { code: null },
        createModifySuccessResponse: { code: null },
        createModifyErrorResponse: { code: null },
        detailSuccessResponse: { code: null },
        detailErrorResponse: { code: null }
      };
    //NGO - Campaign Detail screen
    case Action.CAMPAIGN_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.CAMPAIGN_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detailSuccessResponse: action.payload
      };
    case Action.CAMPAIGN_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        detailErrorResponse: action.payload
      };
    //NGO - Campaign Detail screen
    case Action.CAMPAIGN_PREVIEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.CAMPAIGN_PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        previewSuccessResponse: action.payload
      };
    case Action.CAMPAIGN_PREVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        previewErrorResponse: action.payload
      };
    default:
      return state;
  }
};
