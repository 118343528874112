export const contactFormData = [
  {
    title: 'Contact Details',
    wrapperKey: 'contact_details',
    subTitle: 'contact_details',
    step: 'step_1',
    contact_details_fields: [
      {
        field: 0,
        label: 'Full Name',
        inputtype: 'text',
        type: 'text',
        // required: true,
        placeholder: 'John Smith',
        // maxLength: 100,
        // minLength: 3,
        preOnboardRequiredField: true,
        // pattern: '^[a-zA-Z][a-zA-Z ]*',
        // title: 'Full name should contain only characters'
        uniqueIdentifierKey: 'full_name'
      },
      {
        field: 0,
        label: 'Name of NGO',
        inputtype: 'text',
        type: 'text',
        placeholder: 'John-NGO',
        // required: true,
        // minLength: 3,
        // maxLength: 100,
        title: 'NGO name should contain characters with min length 3',
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'name_of_ngo'
      },
      {
        field: 0,
        label: 'Registration Date',
        inputtype: 'text',
        type: 'date',
        // required: true,
        placeholder: 'Select',
        maxLength: 4,
        minLength: 4,
        min: '1900-01-01',
        max:
          String(new Date().getFullYear()) +
          '-' +
          String(new Date().getMonth() + 1).padStart(2, '0') +
          '-' +
          String(new Date().getDate()).padStart(2, '0'),
        title: 'Registration year range should be 1900 to current year ',
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'registration_date'
      },
      {
        field: 0,
        label: 'Mobile No',
        inputtype: 'text',
        type: 'tel',
        placeholder: '8787878787',
        minLength: '10',
        maxLength: '10',
        // pattern: '[0-9]{10}$',
        // title: 'Mobile no. should contain only 10 digits',
        // required: true,
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'mobile_no'
      },
      {
        // field: 1,
        field: 0,
        label: 'Email Address',
        inputtype: 'emailText',
        type: 'emailText',
        placeholder: 'johnsmith@domain.com',
        // required: true,
        name: 'email_address',
        title: 'Email should be in "name@example.com" format',
        clickabletext: 'Request OTP',
        clickablefunction: 'verifyEmail',
        resetText: 'Change Email',
        resetFunction: 'resetEmail',
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'email_address'
      },
      {
        // field: 2,
        field: 0,
        label: 'OTP',
        inputtype: 'otpText',
        type: 'otpText',
        placeholder: 'OTP',
        required: true,
        name: 'OTP',
        maxLength: 4,
        minLength: 4,
        pattern: '^[0-9]{4,4}$',
        title: 'OTP should contain only 4 digits',
        clickabletext: 'Resend OTP',
        clickablefunction: 'resendOtp',
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'otp'
      }
    ]
  }
];

export const aboutFormData = [
  {
    title: 'About NGO',
    wrapperKey: 'about_ngo',
    subTitle: 'about_ngo',
    apiUrl: '',
    step: 'step_2',
    about_ngo_fields: [
      {
        label: 'NGO Objective',
        inputtype: 'textarea',
        rows: '6',
        spanfull: true,
        type: 'text',
        // required: true,
        // maxLength: '2000',
        // minLength: '3',
        placeholder: 'Max 2000 characters',
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'ngo_objective'
        // title: 'Max length reached'
      },
      {
        label: 'PAN Card No',
        inputtype: 'text',
        type: 'text',
        placeholder: 'e.g AAAAA8888A',
        minLength: '10',
        maxLength: '10',
        // pattern: '[A-Z]{5}[0-9]{4}[A-Z]{1}',
        // title:
        //   'Pancard no. should be in format ABCDE1234F (All capital letters) ',
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'pan_card_no'
        // required: true
      },
      {
        label: 'Legal Structure',
        inputtype: 'multiselect',
        // required: true,
        dropdownType: 'single',
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'legal_structure',
        options: {
          key: 'legalStructureDropdown.legalStructure',
          valuekey: 'id',
          displaykey: 'name'
        }
      },
      {
        label: 'Primary Cause Areas',
        inputtype: 'multiselect',
        spanfull: true,
        // required: true,
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'primary_cause_areas',
        options: {
          key: 'common.masterInfo.cause_categories',
          valuekey: 'id',
          displaykey: 'name',
          preOnboardRequiredField: true
        }
      },
      {
        label: 'Secondary Cause Areas',
        inputtype: 'multiselect',
        spanfull: true,
        // required: true,
        preOnboardRequiredField: true,
        uniqueIdentifierKey: 'secondary_cause_areas',
        options: {
          key: 'common.secondaryCauses',
          valuekey: 'id',
          displaykey: 'name',
          preOnboardRequiredField: true
        }
      }
    ]
  }
];

export const document_req = [
  {
    title: 'Documents Checklist',
    wrapperKey: 'documents_checklist',
    subTitle: 'documents_checklist',
    apiUrl: '',
    step: 'step_3',
    required_doc: [
      {
        label: 'Do you have 12A',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'legal_compliance',
        uniqueIdentifierKey: 'do_you_have_12a'
      },
      {
        label: 'Do you have 80G',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'legal_compliance',
        uniqueIdentifierKey: 'do_you_have_80g'
      },
      {
        label: 'Are you registered for CSR 1',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'legal_compliance',
        uniqueIdentifierKey: 'are_you_registered_for_csr_1'
      },
      {
        label: 'Are you registered on NGO Darpan',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'legal_compliance',
        uniqueIdentifierKey: 'are_you_registered_on_ngo_darpan'
      },
      {
        label: 'Do you have a FCRA Certificate',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'financial_capacity',
        subWrapperKey: 'finance',
        uniqueIdentifierKey: 'is_fcra_certified'
      },
      {
        label: 'Do you have Registration Certificate as per registration type',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'legal_compliance',
        uniqueIdentifierKey:
          'do_you_have_registration_certificate_as_per_registration_type'
      },

      {
        label: 'Do you have Latest Income and Expenditure Statement',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'financial_capacity',
        subWrapperKey: 'finance',
        uniqueIdentifierKey:
          'do_you_have_latest_income_and_expenditure_statement'
      },
      {
        label: 'Do you have Balance Sheet for last 3 years',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'finance',
        uniqueIdentifierKey: 'do_you_have_balance_sheet_for_last_3_years'
      },
      {
        label: 'Are you certified by Guide Star India',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'institution_capacity',
        subWrapperKey: 'institution_cap',
        uniqueIdentifierKey: 'is_certified_guidestar_in_india'
      },
      {
        label: 'Are you certified by Credibility Alliance',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'institution_capacity',
        subWrapperKey: 'institution_cap',
        uniqueIdentifierKey: 'is_certified_credibility_alliance'
      },
      // {
      //   label:
      //     "Do you have Certificate of Incorporation (from the Regional Director)",
      //   inputtype: "label",
      //   type: "checkbox",
      //   spanfull: true,
      //   required: true,
      //   wrapperKey: "legal_compliance",
      //   //update
      //   uniqueIdentifierKey: "please_upload_certificate_of_incorporation"
      // },
      {
        label: 'Is there any litigation against your organisation',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'social_activity',
        uniqueIdentifierKey: 'is_there_any_litigation_against_your_organisation'
      },
      {
        label:
          'Has your organisation been blacklisted or banned by the government',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'social_activity',
        uniqueIdentifierKey:
          'has_your_organisation_been_blacklisted_or_banned_by_the_government'
      },
      {
        label:
          'Are any founders/directors/trustees/office bearers a part of any political party',
        inputtype: 'checkbox',
        type: 'checkbox',
        spanfull: true,
        required: true,
        wrapperKey: 'legal_compliance',
        uniqueIdentifierKey:
          'are_any_founders_directors_trustees_office_bearers_a_part_of_any_political_party'
      }
    ]
  }
];
