import React, { useEffect, useState } from 'react';

const LocationTooltip = ({ locations }) => {
  const [locationString, setLocationString] = useState('');
  useEffect(() => {
    let str = '';
    let str2 = '';
    function makeString() {
      for (let i = 0; i < locations.length; i++) {
        if (i < locations.length - 1) {
          if (
            str.length +
              locations[i].city.length +
              locations[i].state.length +
              5 <
            50
          ) {
            str += `${locations[i].city}, ${locations[i].state} | `;
          } else {
            str2 += `${locations[i].city}, ${locations[i].state} | `;
          }
        } else {
          if (
            str.length +
              locations[i].city.length +
              locations[i].state.length +
              5 <
            50
          ) {
            str += `${locations[i].city}, ${locations[i].state} | `;
          } else {
            str2 += `${locations[i].city}, ${locations[i].state} `;
          }
        }
      }
      //   console.log(str, 'str');
      //   console.log(str.slice(0, 50), 'str 50');
      //   console.log(str.slice(50, str.length), 'str last 50');
      setLocationString(str2);
    }
    makeString();
  }, []);
  return <div>{locationString}</div>;
};

export default LocationTooltip;
