import React from 'react';
import dashboardEmpty from '../assets/images/dashboradEmpty.svg';
import campaignEmpty from '../assets/images/campaignEmpty.svg';
import corporatesEmpty from '../assets/images/corporatesEmpty.svg';
import transactionEmpty from '../assets/images/transactionEmpty.svg';

const EmptySideScreenState = props => {
  var title = '';
  var subtitle = '';
  var imgs;
  var isButton = false;
  if (props.pageName === 'dashboard') {
    title = 'Start with create a Project';
    subtitle =
      'You have to create and start raising funds for at-least one Project to use the dashboard';
    imgs = dashboardEmpty;
    isButton = true;
  } else if (props.pageName === 'concept_note') {
    title = 'Create your first Project';
    subtitle =
      'Get started by creating a new Project to create impact at scale';
    imgs = campaignEmpty;
    isButton = true;
  } else if (props.pageName === 'corporate') {
    title = `Sorry! We couldn't find any corporates`;
    subtitle = `We couldn't find any corporate matching your search/filter criteria. Please try searching other terms OR changing filters.`;
    imgs = corporatesEmpty;
    isButton = false;
  } else if (props.pageName === 'transaction') {
    title = `No Transactions`;
    subtitle = `Transactions (Project Funds) will appear here`;
    imgs = transactionEmpty;
    isButton = false;
  } else if (props.pageName === 'mission') {
    title = `Create your first Mission`;
    subtitle = `Get started by creating a new mission to create impact at scale`;
    imgs = campaignEmpty;
    isButton = false;
  } else {
    title = `Your Profile is Under Review`;
    subtitle = `Our Team is reviewing your profile and we will get back to you soon`;
    imgs = campaignEmpty;
    isButton = false;
  }

  return (
    <div className="text-center p-5 w-100 d-flex flex-column align-items-center justify-content-center h-65">
      <img src={imgs} alt="EmptyStateImg" width="300" />
      <div className="text-1B1C1D fs-18 pt-4 font-weight-normal">{title}</div>
      <div className="w-350 text-626262 fs-14 pt-4 text-break ">{subtitle}</div>
      {isButton && !props.hide && (
        <a
          href={process.env.REACT_APP_NEW_CREATE_PROJECT}
          className="btn btn-primary text-white mt-5 h-40 w-150 fs-14"
          style={{ lineHeight: '28px' }}>
          {' '}
          <i className="fas fa-plus-square pr-2"></i> Project
        </a>
      )}
      {props.pageName === 'mission' && !props.hide && (
        <a
          href="/ngo/mission/create"
          className="btn btn-primary text-white mt-5 h-40 w-30 fs-14"
          style={{ lineHeight: '28px' }}>
          {' '}
          <i className="fas fa-plus-square pr-2"></i> Add Mission
        </a>
      )}
    </div>
  );
};

export default EmptySideScreenState;
