import React, { useEffect } from 'react';
const $ = window.$;
const ProjectTitleTrans = ({ title }) => {
  useEffect(() => {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);
  const getTitle = () => {
    if (title?.length > 40) {
      return (
        <div
          data-toggle="tooltip"
          data-placement="top"
          data-original-title={title}>
          {title?.slice(0, 40) + '...'}
        </div>
      );
    } else return <div>{title}</div>;
  };
  return getTitle();
};
export default ProjectTitleTrans;
