import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSecondaryObjectiveList } from '../actions/common.actions';
import MultiSelect from './multiSelect';
import Text from './text';
import TextArea from './textArea';
import getDeviceType from '../helpers/getDeviceResolution';
export default function OnBoardingSingleField({
  input,
  disabled,
  state,
  errorMsg,
  errorMsgForEmail,
  clickabletext,
  clickablefunction,
  triggerFunction,
  resetFunction,
  resetText,
  timer,
  step
}) {
  const mystyle = {
    fontFamily: 'DM Sans',
    fontWeight: 'Bold'
  };
  const isMobile = getDeviceType();
  const dispatch = useDispatch();
  const [cbChecked, setCbChecked] = useState(true);
  let uniqueIdentifierForInput;

  const getSecondaryCausesList = primaryCauses => {
    dispatch(getSecondaryObjectiveList(primaryCauses));
  };
  if (step === 'step_3') {
    uniqueIdentifierForInput = input.uniqueIdentifierKey;
  } else {
    uniqueIdentifierForInput = input.uniqueIdentifierKey;
    // uniqueIdentifierForInput = input.label.replace(/\//g, ' ');
    // uniqueIdentifierForInput = uniqueIdentifierForInput.replace(
    //   /[^a-zA-Z0-9 ]/g,
    //   ''
    // );
    //   uniqueIdentifierForInput = uniqueIdentifierForInput
    //     .replace(/\s/g, '_')
    //     .toLowerCase();
  }
  let savedValue = null;
  function handleChange(e) {
    if (e.target.checked) {
      setCbChecked(true);
    } else {
      setCbChecked(false);
    }
  }

  function errorMsgOnChange() {}
  useEffect(() => {
    if (
      uniqueIdentifierForInput === 'primary_cause_areas' &&
      savedValue &&
      savedValue.length > 0
    ) {
      getSecondaryCausesList(savedValue);
    }
    input.disabled = false;
  }, []);

  let primaryCauseChangeHandler = null;
  if (uniqueIdentifierForInput === 'primary_cause_areas') {
    primaryCauseChangeHandler = getSecondaryCausesList;
  }
  if (disabled) {
    input.disabled = false;
  }

  return (
    <>
      {input.inputtype === 'text' && (
        <>
          <Text
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            savedValue={savedValue}
            disabled={disabled}
            preOnboard={true}
            errorMsgOnChange={errorMsgOnChange}
          />
          {errorMsg.length > 0 &&
            errorMsg.map(data => {
              return (
                uniqueIdentifierForInput === data.label && (
                  <div
                    className={` ${
                      isMobile ? 'fs-36' : 'fs-16'
                    } mt-2 color-FF0000`}>
                    {data.msg}
                  </div>
                )
              );
            })}
          {/* {filtered = errorMsg.filter(errorMsg => {
            console.error(filtered);
            return errorMsg.label === uniqueIdentifierForInput;
          })} */}
          {/* {errorMsg !== undefined &&
            uniqueIdentifierForInput === filtered.label && (
              <div className="mt-1 color-FF0000">{filtered.msg}</div>
            )} */}
        </>
      )}
      {input.inputtype === 'textarea' && (
        <>
          <TextArea
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            savedValue={savedValue}
            disabled={disabled}
            preOnboard={true}></TextArea>
          {errorMsg.length > 0 &&
            errorMsg.map(data => {
              return (
                uniqueIdentifierForInput === data.label && (
                  <div
                    className={` ${
                      isMobile ? 'fs-36 fw-500' : 'fs-16'
                    } mt-2 color-FF0000`}>
                    {data.msg}
                  </div>
                )
              );
            })}
          {/* {filtered !== undefined &&
            uniqueIdentifierForInput === filtered.label && (
              <div className="mt-1 color-FF0000">
                <span className={isMobile ? 'fs-36' : ''}>{filtered[0].msg}</span>
              </div>
            )} */}
        </>
      )}
      {input.inputtype === 'emailText' && (
        <>
          <Text
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            value={savedValue}
            disabled={disabled}
          />
          {errorMsgForEmail !== undefined &&
            uniqueIdentifierForInput === errorMsgForEmail.label && (
              <div className="mt-1 color-FF0000">
                <span className={isMobile ? 'fs-36' : ''}>
                  {errorMsgForEmail.msg}
                </span>
              </div>
            )}
        </>
      )}

      {input.inputtype === 'otpText' && (
        <>
          <Text
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            savedValue={savedValue}
            disabled={disabled}
            triggerFunction={triggerFunction}></Text>
          {errorMsg !== undefined &&
            uniqueIdentifierForInput === errorMsg.label && (
              <div
                className={
                  isMobile ? 'mt-1 color-FF0000 fs-36' : 'mt-1 color-FF0000'
                }>{`Resend OTP in ${timer} seconds`}</div>
            )}
        </>
      )}
      <div className="row">
        {clickablefunction && (
          <div className="mt-3 fs-12 col-md-6">
            <button
              type="button"
              className={
                isMobile ? 'btn btn-link fs-28 fw-500' : 'btn btn-link'
              }
              onClick={clickablefunction}
              id={clickabletext}
              style={mystyle}>
              <span
                className={`${isMobile ? 'fs-36 fw-600' : ''} color-0193f4b3`}>
                {clickabletext}
              </span>
            </button>
          </div>
        )}
        {resetFunction && input.inputtype === 'emailText' && (
          <div className="mt-3 fs-12 col-md-6">
            <button
              type="button"
              className={
                isMobile ? 'btn btn-link fs-28 fw-500' : 'btn btn-link'
              }
              onClick={resetFunction}
              id={resetText}
              style={mystyle}>
              <span
                className={`${isMobile ? 'fs-36 fw-600' : ''} color-0193f4b3`}>
                {resetText}
              </span>
            </button>
          </div>
        )}
      </div>
      {input.inputtype === 'multiselect' && (
        <>
          <MultiSelect
            uniqueIdentifierForInput={uniqueIdentifierForInput}
            input={input}
            state={state}
            value={savedValue}
            changeHandler={primaryCauseChangeHandler}
          />
          {errorMsg.length > 0 &&
            errorMsg.map(data => {
              return (
                uniqueIdentifierForInput === data.label && (
                  <div
                    className={` ${
                      isMobile ? 'fs-36' : 'fs-16'
                    } mt-2 color-FF0000`}>
                    {data.msg}
                  </div>
                )
              );
            })}
          {/* {errorMsg !== undefined &&
            uniqueIdentifierForInput === errorMsg.label && (
              <div className="mt-1 color-FF0000">{errorMsg.msg}</div>
            )} */}
        </>
      )}
      {/* switch button */}
      {input.inputtype === 'checkbox' && (
        <>
          <div className={isMobile ? 'col-md-12 mt-5' : 'col-md-8 mt-2'}>
            <li
              style={{ listStyleType: 'none' }}
              className={
                isMobile ? 'fs-42 montserrat' : 'fs-14 ml-20 montserrat'
              }>
              {input.label}
              {' ?'}
            </li>
          </div>
          <div className={isMobile ? '' : 'col-md-4 switch-btn'}>
            {' '}
            <label className={isMobile ? 'switch' : 'switch float-right ml'}>
              <span
                className={` ${
                  isMobile ? 'switch-text-mobile' : 'switch-text'
                }  mt-1 fw-600 pt-2`}>
                {' '}
                {cbChecked ? 'Yes' : 'No'}
              </span>
              <input
                name={uniqueIdentifierForInput}
                value={cbChecked}
                type="checkbox"
                defaultValue={false}
                id={uniqueIdentifierForInput}
                onChange={e => handleChange(e)}
                checked={cbChecked}
              />
              <span className="onSlider round"></span>
            </label>
          </div>
        </>
      )}
    </>
  );
}
