import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useSelector } from 'react-redux';
const DonutChart = ({ data, colors, text, isCurrency, currencySymb }) => {
  const isSidebar = useSelector(state => state.sidebarStatus);
  const chartRef = useRef(null);
  const radius = useRef(0);
  const simplifyNumber = number => {
    const suffixes = ['', 'K', 'M', 'B', 'T'];

    const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);
    const simplifiedValue = (number / Math.pow(10, magnitude * 3)).toFixed(0);

    return simplifiedValue + suffixes[magnitude];
  };
  const coammaSapertaedNumber = number => {
    return new Intl.NumberFormat('en-IN').format(number);
  };

  useEffect(() => {
    if (chartRef.current) {
      d3.select(chartRef.current)
        .select('svg')
        .remove();
      const chartWidth = chartRef.current.clientWidth;
      const chartHeight = chartWidth;

      const totalValue = d3.sum(data, d => d.value);

      const pie = d3
        .pie()
        .sort(null)
        .value(d => d.value);

      radius.current = chartHeight / 5;

      const arc = d3
        .arc()
        .innerRadius(radius.current)
        .outerRadius(radius.current + 30);

      const svg = d3
        .select(chartRef.current)
        .append('svg')
        .attr('width', chartWidth)
        .attr('height', chartHeight);

      const g = svg
        .append('g')
        .attr('transform', `translate(${chartWidth / 2},${chartHeight / 2})`);

      const displayData =
        data.length === 0 ? [{ label: 'No data', value: 0 }] : data;

      const arcs = g
        .selectAll('.arc')
        .data(pie(displayData))
        .enter();

      const tooltip = d3
        .select(chartRef.current)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);

      arcs
        .append('path')
        .attr('class', 'arc')
        .attr('fill', (d, i) => colors[i % colors.length])
        .style('cursor', 'pointer')
        .on('mouseover', function(event, d) {
          const currentData = d.data;
          const [x, y] = d3.pointer(event, chartRef.current);

          tooltip
            .transition()
            .duration(200)
            .style('opacity', 1);
          tooltip
            .html(
              `${currentData.label} : ${
                isCurrency ? `${currencySymb}` : ''
              }${coammaSapertaedNumber(currentData.value)}`
            )
            .style('left', `${x + 20}px`)
            .style('top', `${y - 25}px`)
            .style('padding', '5px')
            .style('box-shadow', '2px 2px 4px rgba(0, 0, 0, 0.5)')
            .style('background', 'white')
            .style('border-radius', '5px')
            .style('font-size', '14px')
            .style('font-weight', '500');
        })
        .on('mouseout', () => {
          tooltip
            .transition()
            .duration(500)
            .style('opacity', 0);
        })
        .transition()
        .duration(1500)
        .attrTween('d', d => {
          const i = d3.interpolate(d.startAngle, d.endAngle);
          return t => {
            d.endAngle = i(t);
            return arc(d);
          };
        });
      arcs
        .append('text')
        .attr('class', 'label')
        .attr('transform', d => {
          const pos = radius.current + 60;
          const midAngle = (d.startAngle + d.endAngle) / 2;
          const xPosition = pos * Math.sin(midAngle);
          const yPosition = -pos * Math.cos(midAngle);
          return `translate(${xPosition}, ${yPosition})`;
        })
        .attr('dy', '-0.35em')
        .attr('text-anchor', 'middle')
        .style('font-size', '12px')
        .style('font-weight', '500')
        .style('position', 'relative')
        .text(d => {
          let percentage =
            d.data.value > 0 ? (d.data.value / totalValue) * 100 : 0;
          return d.data.value > 0 ? `${percentage.toFixed(0)}%` : '';
        });

      if (text) {
        const totalValueText =
          data.length > 0 ? simplifyNumber(totalValue) : 'No data';
        g.append('text')
          .attr('class', 'total-value')
          .attr('text-anchor', 'middle')
          .style('font-size', '16px')
          .style('font-weight', '500')
          .text(`${text}: ${totalValueText}`);
      }
    }
  }, [data, isSidebar, currencySymb]);

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

export default DonutChart;
