import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/date-picker.css';
import CalenderIcon from '../assets/icons/calender_icon.svg';

export default function YearInput(props) {
  const { uniqueIdentifierForInput, input, savedValue } = props;
  const [date, setDate] = useState();
  let maxYear = null;
  let minYear = null;
  let disabled = props.disabled;

  if (input.maxyear && input.maxyear === 'current') {
    maxYear = new Date();
  } else if (input.maxyear) {
    maxYear = new Date(input.maxyear);
  }
  if (input.minyear && input.minyear === 'current') {
    minYear = new Date();
  } else if (input.minyear) {
    minYear = new Date(input.minyear);
  }

  const initValues = () => {
    const yearInput = document.getElementsByClassName(uniqueIdentifierForInput);
    for (let i = 0; i < yearInput.length; i++) {
      yearInput[i].setAttribute('id', uniqueIdentifierForInput);
      yearInput[i].setAttribute('name', uniqueIdentifierForInput);
    }
    if (savedValue) {
      setDate(new Date(`${savedValue}/01/01`));
    }
  };

  const setYear = date => {
    setDate(date);
    if (props.fieldUpdated) props.fieldUpdated(props.stepIndex);
  };

  useEffect(() => {
    initValues();
  }, [savedValue]);

  return (
    <>
      <label htmlFor={uniqueIdentifierForInput}>
        {input?.label}
        {input?.required ||
        (input?.validation && input?.validation === 'required') ||
        input.showrequired ? (
          <span className="color-FF2300">&nbsp;* </span>
        ) : (
          ''
        )}
      </label>
      <div className="year-picker-custom d-flex align-items-center position-relative ">
        <DatePicker
          selected={date}
          onChange={date => setYear(date)}
          showYearPicker
          dateFormat="yyyy"
          placeholderText="2022"
          disabledKeyboardNavigation
          maxDate={maxYear}
          minDate={minYear}
          disabled={disabled}
          className={`form-control w-100 position-relative  ${uniqueIdentifierForInput}`}
          calendarClassName="year-calendar-custom"
          onChangeRaw={e => e.preventDefault()}>
          {' '}
        </DatePicker>

        <div className="year-picker-icon d-flex justify-content-center align-items-center position-absolute">
          <img src={CalenderIcon} alt="calender" />
        </div>
      </div>
    </>
  );
}
