import React from 'react';
import infoIcon from '../../assets/icons/info-icon.svg';

const CheckboxList = ({
  options,
  itemsInEachCol,
  type,
  onChange,
  selectedItems
}) => {
  const setChecked = id => {
    const checkStatus = selectedItems?.filter(x => x === id);

    if (!checkStatus?.length) {
      const newArr = [...selectedItems, id];
      onChange(newArr);
    } else {
      const newArr = selectedItems.filter(x => x !== id);
      onChange(newArr);
    }
  };

  const renderToolTip = toolItem => {
    if (type === 'policy_list' && toolItem) {
      return (
        // <i
        //   className="fas fa-info-circle text-info ml-2 p-2 tooltip"
        //   data-toggle="tooltip"
        //   data-placement="top"
        //   title={toolItem}
        // />

        <i className="tooltipss ml-2 p-2">
          <img src={infoIcon} alt="info-icon" width={15} />
          <span className="tooltip-text tooltipss-dark-Profile ">
            {toolItem}
          </span>
        </i>
      );
    }
    return null;
  };
  const getCheckboxItem = _option => {
    const checked = selectedItems?.filter(x => x === _option.id);
    return (
      <>
        <input
          type="checkbox"
          className="form-check-input tnc-checkbox"
          style={{ minHeight: '10px' }}
          id={`${_option.name}-${_option.id}`}
          // title="cause_category"
          name="primary-cause-checkbox"
          checked={!!checked?.length}
          onChange={() => setChecked(_option.id)}
        />
        <label
          className="form-check-label fs-14 text-1C1C1C ml-3 mt-1"
          htmlFor="checkboxSelection">
          {_option.name}
        </label>
        {renderToolTip(_option.help_text || '')}
      </>
    );
  };

  // const columnStyle = {
  //   flex: '1 1 auto',
  //   overflowY: 'auto',
  //   maxHeight: '300px'
  // };

  const columns = [];
  const numCols = Math.ceil(options?.length / itemsInEachCol);

  for (let i = 0; i < numCols; i++) {
    columns.push(
      <div key={i} className="col-md-12">
        {options &&
          options
            .slice(i * itemsInEachCol, (i + 1) * itemsInEachCol)
            .map(option => (
              <div key={option.id} className="form-check py-2">
                {getCheckboxItem(option)}
              </div>
            ))}
      </div>
    );
  }

  return <div className="row">{columns}</div>;
};

export default CheckboxList;
