import React, { useEffect, useRef } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DatePicker from 'react-datepicker';
import { isAuthorized } from '../helpers/auth-roles';
import CalenderIcon from '../assets/icons/calender_icon.svg';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/date-picker.css';
// import infoIcon from '../assets/icons/info-icon.svg';

import { DateUtils } from 'react-day-picker';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
/* global jQuery */

const createFieldErrorHtml = (id, fieldError) => {
  return (
    <div className={`${fieldError === '' ? 'd-none' : ''}`}>
      <small id={id} className="text-danger">
        {fieldError}
      </small>
    </div>
  );
};

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}
export const InputTextArea = props => {
  return (
    <div
      className={`form-group  ${
        props.required ? 'required' : ''
      } ${props.wrapperClassName || ''}`}>
      {props.labelText !== '' && (
        <label className={`${props.labelClassName || ''}`} htmlFor={props.id}>
          {props.labelText}{' '}
          {props.required ? <span className="color-FF2300">*</span> : ' '}
        </label>
      )}
      <textarea
        className={`form-control ${props.className || ''}`}
        id={props.id}
        name={props.name}
        value={props.fieldValue}
        disabled={
          props.disableInput ||
          (props.permissions && !isAuthorized(props.permissions))
        }
        maxLength={props.maxLength}
        placeholder={props.PlaceHolder}
        rows={props.rows ? props.rows : '5'}
        onChange={(event, newValue) =>
          props.onHandleChange
            ? props.onHandleChange(
                event,
                props.onChangeCallback,
                props.dataType || '',
                null,
                newValue
              )
            : () => {}
        }
        onBlur={event =>
          props.onHandleBlur
            ? props.onHandleBlur(
                event,
                props.onChangeCallback,
                props.dataType || ''
              )
            : () => {}
        }
      />
      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};

export const InputText = props => {
  const renderHelperText = () => {
    if (props.isPraposalModification) {
      if (props.name === 'fundAmount') {
        return (
          <p>{`Corporate has requested to fund Rs. ${props.praposalModificationObject.fund_amount}`}</p>
        );
      } else if (props.name === 'beneficiaries') {
        return (
          <p>{`Corporate has requested to impact ${props.praposalModificationObject.no_of_beneficiaries} beneficiaries`}</p>
        );
      }
    }
    return null;
  };

  const getDisbaled = () => {
    if (
      props.isPraposalModification &&
      props.praposalModificationObject.no_of_beneficiaries
    ) {
      return false;
    }
    if (props.disableInput) {
      return true;
    }
  };
  return (
    <div
      className={`form-group ${
        props.required ? 'required' : ''
      }  ${props.wrapperClassName || ''}`}>
      {props.labelText !== '' && (
        <label className={`${props.labelClassName || ''}`} htmlFor={props.id}>
          {props.labelText}{' '}
          {props.required ? <span className="color-FF2300">*</span> : ' '}
        </label>
      )}
      <input
        maxLength={props.maxLength || ''}
        type={props.type}
        id={props.id}
        disabled={
          getDisbaled() ||
          (props.permissions && !isAuthorized(props.permissions))
        }
        name={props.name}
        placeholder={props.PlaceHolder}
        autoComplete={props.autoComplete}
        className={`form-control ${
          props.className ? props.className.split(' ').join(' ') : ''
        } ${props.fieldError === '' ? '' : ''}`}
        onChange={(event, newValue) =>
          props.onHandleChange
            ? props.onHandleChange(
                event,
                props.onChangeCallback,
                props.dataType || '',
                null,
                newValue
              )
            : () => {}
        }
        onBlur={event =>
          props.onHandleBlur
            ? props.onHandleBlur(
                event,
                props.onChangeCallback,
                props.dataType || ''
              )
            : () => {}
        }
        value={props.fieldValue}
      />
      {renderHelperText()}
      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};
export const InputRadio = props => {
  useEffect(() => {}, [props]);
  return (
    <label className="custom-radio">
      <span className="fs-12">{props.label}</span>
      <input
        type={props.type}
        id={props.id}
        disabled={
          props.disableInput ||
          (props.permissions && !isAuthorized(props.permissions))
        }
        name={props.name}
        onChange={(event, newValue) =>
          props.onHandleChange
            ? props.onHandleChange(
                event,
                props.onChangeCallback,
                props.dataType || '',
                null,
                newValue
              )
            : () => {}
        }
        onBlur={event =>
          props.onHandleBlur
            ? props.onHandleBlur(
                event,
                props.onChangeCallback,
                props.dataType || ''
              )
            : () => {}
        }
        value={props.value}
        defaultChecked={props.checked}
        checked={props.checked}
      />

      <span className="checkmark"></span>
    </label>
  );
};

export const InputDaypicker = props => {
  return (
    <div
      className={`form-group ${props.required ? 'required' : ''}  ${
        props.wrapperClassName ? props.wrapperClassName : ''
      } `}>
      {props.labelText !== '' && (
        <label className={`${props.labelClassName || ''}`} htmlFor={props.id}>
          {props.labelText}{' '}
          {props.required ? <span className="color-FF2300">*</span> : ' '}
        </label>
      )}
      <DayPickerInput
        value={(props.fieldValue && new Date(props.fieldValue)) || ''}
        id={props.id}
        name={props.name}
        // placeholder="DD/MM/YYYY"
        // format="DD/MM/YYYY"

        formatDate={formatDate}
        format={'dd/MM/yyyy'}
        parseDate={parseDate}
        placeholder={'DD/MM/YYYY'}
        classNames={{
          container: 'w-100',
          overlay: 'DayPickerInput-Overlay',
          overlayWrapper: 'DayPickerInput-OverlayWrapper'
        }}
        inputProps={{
          disabled:
            props.disableInput ||
            (props.permissions && !isAuthorized(props.permissions)),
          className: `form-control w-100 ${
            props.className ? props.className : ''
          }`
        }}
        onDayChange={selectedDay => {
          const event = {
            target: {
              id: props.id,
              value: selectedDay ? selectedDay.toLocaleDateString('en-US') : ''
            }
          };
          props.onHandleChange &&
            props.onHandleChange(event, props.onChangeCallback, '');
        }}
        dayPickerProps={{
          selectedDays: props.value ? [new Date(props.fieldValue)] : null,
          disabledDays: {
            daysOfWeek: props.daysOfWeek || [0, 6],
            after: null || props.disableAfter,
            before: null || props.disableBefore
          }
        }}
      />
      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};

export const InputYearPicker = props => {
  let maxYear = null;
  let minYear = null;

  if (props.maxYear && props.maxYear === 'current') {
    maxYear = new Date();
  } else if (props.maxYear) {
    maxYear = new Date(props.maxYear);
  }
  if (props.minYear && props.minYear === 'current') {
    minYear = new Date();
  } else if (props.minYear) {
    minYear = new Date(props.minYear);
  }

  return (
    <div
      className={`form-group ${props.required ? 'required' : ''}  ${
        props.wrapperClassName ? props.wrapperClassName : ''
      } `}>
      {props.label !== '' && (
        <label className={`${props.labelClassName || ''}`} htmlFor={props.id}>
          {props.label}{' '}
          {props.required ? <span className="color-FF2300">*</span> : ' '}
        </label>
      )}
      <div className="year-picker-custom d-flex position-relative">
        <DatePicker
          selected={props.value}
          onChange={date => {
            const event = {
              target: {
                id: props.id,
                value: date
              }
            };
            props.onHandleChange &&
              props.onHandleChange(event, props.onChangeCallback, '');
          }}
          showYearPicker
          dateFormat="yyyy"
          placeholderText={new Date().getFullYear()}
          disabledKeyboardNavigation
          maxDate={maxYear}
          minDate={minYear}
          disabled={
            props.disabled ||
            (props.permissions && !isAuthorized(props.permissions))
          }
          className={`form-control w-100 position-relative`}
          calendarClassName="year-calendar-custom"
          onChangeRaw={e => e.preventDefault()}></DatePicker>
        <div className="year-picker-icon d-flex justify-content-center align-items-center position-absolute">
          <img src={CalenderIcon} alt="calender" />
        </div>
      </div>
      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};

export const InputSelect = props => {
  const getSelectBox = mProps => {
    const defaultOption = mProps.isDefault ? (
      <option key={mProps.id + '-0'} value={null}>
        Please select
      </option>
    ) : (
      ''
    );

    const getOptions = options => {
      if (options) {
        return options.map(item => {
          return (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          );
        });
      } else {
        return null;
      }
    };

    return (
      <select
        className={`form-control form-select-invalid ${
          mProps.className ? mProps.className.split(' ').join(' ') : ''
        }${mProps.fieldError === '' ? '' : ''} ${
          props.disableInput ? 'disabled' : ''
        }`}
        id={mProps.id}
        name={mProps.name}
        disabled={
          props.disableInput ||
          (props.permissions && !isAuthorized(props.permissions))
        }
        value={mProps.fieldValue}
        onChange={(event, newValue) =>
          mProps.onHandleChange(
            event,
            mProps.onChangeCallback,
            mProps.dataType,
            mProps.childCallBack,
            newValue
          )
        }
        onBlur={event =>
          props.onHandleBlur
            ? props.onHandleBlur(
                event,
                props.onChangeCallback,
                props.dataType || ''
              )
            : () => {}
        }>
        {defaultOption}
        {getOptions(mProps.options)}
      </select>
    );
  };

  return (
    <div
      className={`form-group ${
        props.required ? 'required' : ''
      } ${props.wrapperClassName || ''}`}>
      {props.labelText !== '' && (
        <>
          <label className={`${props.labelClassName || ''}`} htmlFor={props.id}>
            {props.labelText}{' '}
            {/* {props.required ? <span className="color-FF2300">*</span> : ' '} */}
          </label>

          {/* {props.infoIcon && (
            <span>
              <i className="tooltipss mt-5">
                <img src={infoIcon} alt="info-icon" width={15} />
                <span className="tooltip-text tooltipss-dark">
                  {props.infoText}
                </span>
              </i>
            </span>
          )} */}
          {/* <span>
            <i className="mt-5">
              <img src={infoIcon} alt="info-icon" width={15} />
            </i>
          </span> */}
        </>
      )}
      {props.extraInfoAboutField && (
        <i
          className="fas fa-info-circle text-info ml-2"
          data-toggle="tooltip"
          data-placement="top"
          title={props.extraInfoAboutField}></i>
      )}
      {getSelectBox({
        id: props.id || 'select-id',
        name: props.name || 'select-name',
        className: props.className || '',
        options: props.options || [],
        isDefault: props.isDefault || true,
        fieldValue: props.fieldValue || '',
        fieldError: props.fieldError || '',
        onChangeCallback: props.onChangeCallback,
        childCallBack: props.childCallBack || null,
        dataType: props.dataType || '',
        onHandleChange: props.onHandleChange || null,
        onHandleBlur: props.onHandleBlur || null
      })}
      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};

export const InputMultiSelect = props => {
  const optionsList = props.options;
  const currentRef = useRef();
  const currentVal = useRef(props.fieldValue);

  const handleChange = event => {
    const newVal = jQuery(currentRef.current).val();
    currentVal.current = newVal;
    const newEvent = {
      target: {
        id: event.target.id,
        value: newVal
      }
    };
    props.onHandleChange &&
      props.onHandleChange(
        newEvent,
        props.onChangeCallback,
        props.dataType,
        props.childCallBack,
        newVal
      );
  };

  useEffect(() => {
    jQuery(currentRef.current).select2({
      width: '100%',
      height: '30px',
      tags: false,
      placeholder: 'Please Select',
      allowClear: false,
      disabled: props.disableInput
    });
    jQuery(currentRef.current).on('change', handleChange);
    return () => {
      jQuery(currentRef.current).off('change', handleChange);
    };
  }, [props]);

  return (
    <div
      className={`form-group ${
        props.required ? 'required' : ''
      } ${props.wrapperClassName || ''}`}>
      {props.labelText !== '' && (
        <label className={`${props.labelClassName || ''}`} htmlFor={props.id}>
          {props.labelText}{' '}
          {props.required ? <span className="color-FF2300">*</span> : ' '}
        </label>
      )}
      <select
        ref={currentRef}
        id={props.id}
        multiple={'multiple'}
        className="form-control"
        disabled={
          props.disableInput ||
          (props.permissions && !isAuthorized(props.permissions))
        }
        name={props.name}
        value={props.fieldValue}
        required={props.required}>
        <option></option>
        {(optionsList || []).map((opt, optIndex) => {
          return (
            <option value={opt.id} key={`${optIndex}-${opt.id}-${opt.name}`}>
              {opt.name}
            </option>
          );
        })}
      </select>

      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};

export const InputSelectAndAdd = props => {
  const optionsList = props.options;
  const currentRef = useRef();
  const currentVal = useRef(props.fieldValue);

  const handleChange = event => {
    const newVal = jQuery(currentRef.current).val();
    currentVal.current = newVal;
    const newEvent = {
      target: {
        id: event.target.id,
        value: newVal
      }
    };
    props.onHandleChange &&
      props.onHandleChange(
        newEvent,
        props.onChangeCallback,
        props.dataType,
        props.childCallBack,
        newVal
      );
  };

  useEffect(() => {
    jQuery(currentRef.current).select2({
      width: '100%',
      height: '30px',
      tags: false,
      placeholder: 'Please Select',
      allowClear: false,
      disabled: props.disableInput
    });
    jQuery(currentRef.current).on('change', handleChange);
    return () => {
      jQuery(currentRef.current).off('change', handleChange);
    };
  }, [props]);

  return (
    <div
      className={`form-group addtolist ${
        props.required ? 'required' : ''
      } ${props.wrapperClassName || ''}`}>
      {props.labelText !== '' && (
        <label className={`${props.labelClassName || ''}`} htmlFor={props.id}>
          {props.labelText}{' '}
          {props.required ? <span className="color-FF2300">*</span> : ' '}
        </label>
      )}

      <select
        ref={currentRef}
        id={props.id}
        multiple={'multiple'}
        className="form-control"
        disabled={
          props.disableInput ||
          (props.permissions && !isAuthorized(props.permissions))
        }
        name={props.name}
        value={props.fieldValue}
        required={props.required}>
        <option></option>
        {(optionsList || []).map((opt, optIndex) => {
          return (
            <>
              <option value={opt.id} key={`${optIndex}-${opt.id}-${opt.name}`}>
                {opt.name}
              </option>
            </>
          );
        })}
      </select>
      {/* {optionsList.length === 0 && (
        <div style={{ border: '1px solid grey' }} className="p-4">
          <span>
            No result found. cann to the list ?{' '}
            {jQuery(currentRef.current).val()}
          </span>
          <button className="float-right btn btn-primary p-2">Add</button>
        </div>
      )} */}

      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};

export const InputCheckBox = props => {
  return (
    <div className={`${props.wrapperClassName || ''}`}>
      <input
        id={props.id}
        disabled={
          props.disableInput ||
          (props.permissions && !isAuthorized(props.permissions))
        }
        className={`${props.className || ''}`}
        type="checkbox"
        checked={props.value}
        name={props.name}
        onChange={event =>
          props.onHandleChange
            ? props.onHandleChange(
                event,
                props.onChangeCallback,
                props.dataType || '',
                null,
                event.target.checked
              )
            : () => {}
        }
      />
      <label className="col-11 tandc text-muted">
        {`I agree to Metta Social's Terms of Use, Privacy Policy and Plans & Pricing. I acknowledge and confirm that the information provided above is true and correct to the best of my knowledge and belief and I agree to be liable if any of the above information is found to be false or misleading. I hereby give my consent for sharing it with regulatory authorities or disclosing it as may be required by law.*`}
      </label>
      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};

export const InputSwitch = props => {
  return (
    <div className={`${props.wrapperClassName || ''}`}>
      <label className={'switch '}>
        <input
          id={props.id}
          disabled={
            props.disableInput ||
            (props.permissions && !isAuthorized(props.permissions))
          }
          className={`${props.className || ''}`}
          type="checkbox"
          defaultValue={props.defaultValue}
          checked={props.value}
          name={props.name}
          onChange={event =>
            props.onHandleChange
              ? props.onHandleChange(
                  event,
                  props.onChangeCallback,
                  props.dataType || 'boolean',
                  null,
                  event.target.checked
                )
              : () => {}
          }
        />
        <span className="onSlider round"></span>
      </label>
      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};

//ank

export const InputSelectSplit = props => {
  const renderHelperText = () => {
    if (props.isPraposalModification) {
      if (props.name === 'fundAmount') {
        return (
          <p>{`Corporate has requested to fund Rs. ${props.praposalModificationObject.fund_amount}`}</p>
        );
      } else if (props.name === 'beneficiaries') {
        return (
          <p>{`Corporate has requested to impact ${props.praposalModificationObject.no_of_beneficiaries} beneficiaries`}</p>
        );
      }
    }
    return null;
  };

  const getSelectBox = mProps => {
    // const defaultOption = mProps.isDefault ? (
    //   <option key={mProps.id + '-0'} value={null}>
    //     Please select
    //   </option>
    // ) : (
    //   ''
    // );

    // const getOptions = options => {
    //   if (options) {
    //     return options.map(item => {
    //       return (
    //         <option key={item.id} value={item.id}>
    //           {item.name}
    //         </option>
    //       );
    //     });
    //   } else {
    //     return null;
    //   }
    // };
    const getDisabled = () => {
      if (
        props.isPraposalModification &&
        props.praposalModificationObject.fund_amount
      ) {
        return false;
      }
      if (props.disableInput) {
        return true;
      }
    };
    return (
      <div className="input-group">
        <div className="input-group-btn">
          <select className="form-control" id="currency" disabled>
            <option selected>INR</option>
            <option>USD</option>
          </select>
        </div>
        <input
          maxLength={props.maxLength || ''}
          type={props.type}
          id={props.id}
          disabled={
            getDisabled() ||
            (props.permissions && !isAuthorized(props.permissions))
          }
          name={props.name}
          placeholder={props.PlaceHolder}
          autoComplete={props.autoComplete}
          className={`form-control ${
            props.className ? props.className.split(' ').join(' ') : ''
          } ${props.fieldError === '' ? '' : ''}`}
          onChange={(event, newValue) =>
            props.onHandleChange
              ? props.onHandleChange(
                  event,
                  props.onChangeCallback,
                  props.dataType || '',
                  null,
                  newValue
                )
              : () => {}
          }
          onBlur={event =>
            props.onHandleBlur
              ? props.onHandleBlur(
                  event,
                  props.onChangeCallback,
                  props.dataType || ''
                )
              : () => {}
          }
          value={props.fieldValue}
        />
      </div>
    );
  };

  return (
    <div
      className={`form-group ${
        props.required ? 'required' : ''
      } ${props.wrapperClassName || ''}`}>
      {props.labelText !== '' && (
        <label className={`${props.labelClassName || ''}`} htmlFor={props.id}>
          {props.labelText}{' '}
          {props.required ? <span className="color-FF2300">*</span> : ' '}
        </label>
      )}
      {props.extraInfoAboutField && (
        <i
          className="fas fa-info-circle text-info ml-2"
          data-toggle="tooltip"
          data-placement="top"
          title={props.extraInfoAboutField}></i>
      )}
      {getSelectBox({
        id: props.id || 'select-id',
        name: props.name || 'select-name',
        className: props.className || '',
        options: props.options || [],
        isDefault: props.isDefault || true,
        fieldValue: props.fieldValue || '',
        fieldError: props.fieldError || '',
        onChangeCallback: props.onChangeCallback,
        childCallBack: props.childCallBack || null,
        dataType: props.dataType || '',
        onHandleChange: props.onHandleChange || null,
        onHandleBlur: props.onHandleBlur || null
      })}
      <div> {renderHelperText()}</div>
      {createFieldErrorHtml('error-' + props.id, props.fieldError)}
    </div>
  );
};
