import React, { useEffect } from 'react';
import { getParsedDateDDMMYYYY } from '../../formatsForDate';
const $ = window.$;
function DonarDetailsTable(props) {
  useEffect(() => {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);
  const getValue = (value, field) => {
    if (field === 'amount' && value !== null) {
      return '₹ ' + value;
    } else if (value !== null) {
      return value;
    } else {
      return '--';
    }
  };
  const downloadCsv = csvData => {
    const url = window.URL.createObjectURL(
      new Blob([csvData], { type: 'text/csv;charset=utf-8,' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '10BD_Report');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const getExportDataButton10Bd = () => {
    if (
      props.donationReportListCount > 0 &&
      props.policy?.length > 0 &&
      props.reportName === 'Generate 10BD'
    ) {
      return (
        <>
          <span className="fs-12 text-7B8980 mt-2">Disclaimer</span>
          <i
            className="fas fa-info-circle text-7B8980 mx-3 cursor-pointer mt-07rm"
            data-toggle="tooltip"
            data-placement="top"
            title={
              'This file is automatically generated by our system. Please ensure to review its contents manually to confirm accuracy before proceeding.'
            }></i>

          <button
            className='className="btn btn-primary position-relative cursor-pointer btn-lg mr-2 px-4 fs-14 dm-bold '
            data-toggle="tooltip"
            data-placement="top"
            title={
              'Exported CSV will only include Donor Name, PAN, Amount and address of the donor'
            }
            onClick={() =>
              downloadCsv(
                props.exportReportData10Bd ? props.exportReportData10Bd : []
              )
            }>
            Download CSV For 10BD
          </button>
        </>
      );
    }
  };
  return (
    <>
      {props.donationReportListCount === 0 && (
        <div className="text-center mt-8">
          <img
            className="pl-2"
            src="/images/donationEmptyState.svg"
            alt=""
            width={300}
          />
          <div className="fs-18 my-2 text-1C1C1C">
            Sorry! We couldn’t find any Records
          </div>
          <div className="fs-14 text-626262 mb-5">
            We didn’t not finds any records matching your requirements. Please
            try other filters
          </div>
        </div>
      )}

      {props.donationReportListCount > 0 && (
        <>
          <div className="row mb-4 ml-1-neg">
            <div className="col-6 mt-4">
              {props.donationReportListCount > 0 && (
                <div className="fs-18 text-1C1C1C fw-400 mb-4">
                  {props.donationReportListCount}
                  {' Records'}
                </div>
              )}
            </div>
            <div className="col-6">
              <div className="d-flex float-right my-3">
                {getExportDataButton10Bd()}
              </div>
            </div>
          </div>
        </>
      )}
      {props.donationReportListCount > 0 && (
        <div className="bg-white">
          <div className="overflow-scroll-70vh">
            <table
              className="table table-borders table-hover fs-14  w-100"
              id="table-to-xls">
              <thead className={'table-thead text-uppercase'}>
                <tr className={'text-left table-th h-lh-45'}>
                  <th scope="col" className="px-2 py-2 fs-12 w-5 px-4">
                    <b>SR NO</b>
                  </th>
                  <th scope="col" className="px-1 py-2 fs-12 w-15">
                    <b>DONOR NAME</b>
                  </th>
                  <th scope="col" className="px-1 py-2 fs-12 w-20 ">
                    <b>NAME OF MISSION</b>
                  </th>
                  <th scope="col" className="px-1 py-2 fs-12 w-10">
                    <b>PAN NUMBER</b>
                  </th>
                  <th scope="col" className="px-1 py-2 fs-12 w-20">
                    <b>ADDRESS</b>
                  </th>
                  <th className="fs-12 px-1 py-2 w-15">
                    <b>DONATION DATE</b>
                    <img
                      className="pl-2 cursor-pointer"
                      src="/images/shuffle.svg"
                      alt=""
                      width={15}
                      onClick={() => props.sortByColumn('transaction_date')}
                    />
                  </th>
                  <th className="fs-12 px-1 py-2 w-20">
                    <b> DONATION AMOUNT </b>
                    <img
                      className="pl-2 cursor-pointer"
                      src="/images/shuffle.svg"
                      alt=""
                      width={15}
                      onClick={() => props.sortByColumn('amount')}
                    />
                  </th>
                </tr>
              </thead>

              {props.donationReportList?.map((item, index) => {
                return (
                  <>
                    <tbody>
                      <tr className={'text-center'} key={`donation`}>
                        <td className="table-td px-2 py-3 px-4">
                          {' '}
                          {index + props.serialNumber}
                        </td>
                        <td className="table-td px-2 py-3">
                          {getValue(item?.employee_name, '')}
                        </td>
                        <td className="table-td px-2 py-3 text-007bff">
                          {getValue(item?.mission_name, '')}
                        </td>

                        <td className="table-td px-2 py-3">
                          {getValue(item?.pan_number, '')}
                        </td>

                        <td className="table-td px-2 py-3">
                          {getValue(item?.employee_address, '')}
                        </td>

                        <td className="table-td px-1 py-2">
                          {getValue(
                            getParsedDateDDMMYYYY(item?.transaction_date),
                            ''
                          )}
                        </td>
                        <td className="table-td px-1 py-2">
                          {getValue(item?.amount, 'amount')}
                        </td>
                      </tr>
                    </tbody>
                  </>
                );
              })}
            </table>
          </div>
        </div>
      )}
    </>
  );
}
export default DonarDetailsTable;
