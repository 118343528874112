/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps*/
import { EndPoint, Common } from '../constants';
import HttpRequest from '../helpers/http-request';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { onStepCompletion } from '../actions/completeRegistration.action';
import completeRegistrationData, {
  getChiefFunctionariesData,
  getAuditorData,
  getDonorField,
  getFundsMobilizedField,
  getFinancialsData
} from '../constants/completeRegistrationData';
import {
  convertFormDataToJson,
  isSectionSaved
} from '../helpers/profileHelpers';
import Loader from './loader';
import ProfileSidebar from './profileSidebar';
import ProfileSingleField from './profileSingleField';
import ModalSide from './modalSide';
import Comments from './modals/comments';
import { getNumber } from '../helpers/commaSeparatedInput';
import { checkIfStepSaved } from '../helpers/util';
import FundMobilisationPastData from './fundMobilisationPastDataModal';
import MultiSelect from './multifilter';
//import { StateList } from '../constants/stateAndDistricts';
var resolvedSectionCmt = false;
var resolvedSubSectionCmt = false;
export default function ProfileContent(props) {
  const userId = props.state.ngo.ngoProfile.ngo_id;
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  const [disabled, setDisabled] = useState();
  const [visible, setVisible] = useState(false);
  const [members, setMembers] = useState([]);
  const [deletedMembers, setDeletedMembers] = useState([]);
  const [allNgoComments, setallNgoComments] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedDist, setSelectedDist] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [selectedPoliciesItem, setSelectedPoliciesItem] = useState([]);
  const [stateError, setStateError] = useState(false);
  const [distError, setDistError] = useState(false);
  const [missState, setMissState] = useState([]);
  const [polError, setPolError] = useState(false);
  // const [membersDataMapped, setMembersDataMapped] = useState(false);
  const [stepStatuses, setStepStatuses] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);
  const [stepEditStatuses, setStepEditStatuses] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);
  const invalidDomainsArr = [
    'trash-mail',
    'guerrillamail',
    'dispostable',
    'mailinator',
    'yopmail',
    'sortsml',
    'mailcatch',
    'mintemail',
    'maildrop'
  ];
  const [showSideModal, setShowSideModal] = useState(false);
  const [sideModalTitle, setSideModalTitle] = useState(false);
  const [sectionId, setSectionId] = useState(0);
  const [subSectionId, setSubSectionId] = useState(0);
  const [auditors, setAuditors] = useState([]);
  const [deletedAuditors, setDeletedAuditors] = useState([]);
  // const [auditorsDataMapped, setAuditorsDataMapped] = useState(false);
  const [topDonors, setTopDonors] = useState([]);
  const [fundsMobilized, setFundsMobilized] = useState([]);
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [fundMobilisationPastData, setFundMobilisationPastData] = useState(
    false
  );

  const [isFcraCertificateFalse, setIsFcraCertificateFalse] = useState(false);
  const setFCRATrue = () => {
    setIsFcraCertificateFalse(true);
  };
  const setFCRAFalse = () => {
    setIsFcraCertificateFalse(false);
  };

  useEffect(() => {
    if (
      props.state.register.ngoSavedData?.existence?.ngo_area_of_operation
        ?.length
    ) {
      const getUniqueIds = arr => {
        const stateIds = new Set();
        const districtIds = new Set();

        arr.forEach(({ state_id, district_id }) => {
          stateIds.add(state_id);
          districtIds.add(district_id);
        });

        return {
          stateIds: [...stateIds],
          districtIds: [...districtIds]
        };
      };
      const { districtIds, stateIds } = getUniqueIds(
        props.state.register.ngoSavedData?.existence?.ngo_area_of_operation
      );
      locationUpdatedForArea('area_state', stateIds);
      locationUpdatedForArea('area_districts', districtIds);

      // setSelectedState(stateIds);
      // setSelectedDist(districtIds);
    }
  }, [props.state.register.ngoSavedData?.existence?.ngo_area_of_operation]);

  useEffect(() => {
    if (
      props.state.register.ngoSavedData?.institutional_capacity?.organorgram
        ?.policy_list?.length &&
      props.state.common?.masterInfo?.policies.length
    ) {
      const getPolIds = props.state.register.ngoSavedData?.institutional_capacity?.organorgram?.policy_list?.map(
        polItem => {
          return {
            id: polItem.policy_id,
            name: polItem.policy_id,
            document_url: polItem.document_url
          };
        }
      );

      if (getPolIds?.length) {
        const uniquePolicies = [
          ...new Set(getPolIds.map(policy => policy.id))
        ].map(id => getPolIds.find(policy => policy.id === id));

        prePoliciesUpdate('policies', uniquePolicies);
      }
    }
  }, [
    props.state.register.ngoSavedData?.institutional_capacity?.organorgram
      ?.policy_list,
    props.state.common?.masterInfo?.policies
  ]);

  useEffect(() => {
    if (
      props.state.register.ngoSavedData?.financial_capacity?.finance
        ?.is_fcra_certified
    ) {
      setFCRATrue();
    }
  }, [
    props.state.register.ngoSavedData?.financial_capacity?.finance
      ?.is_fcra_certified
  ]);

  const isImpersonation = window.location.href.includes('email=');
  var resolveCmtsArr = [];
  var modificationRequired;
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 600) {
      setVisible(true);
    } else if (scrolled <= 600) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  const validate = (type, value) => {
    let error = '';
    if (type === 'name' || type === 'member_name' || type === 'auditor_name') {
      if (!value || value.trim() === '') {
        error = 'Please enter a valid name';
      } else if (value.length < 3) {
        error = 'Please enter Minimum 3 Characters';
      } else if (value.length > 100) {
        error =
          'You have reached your maximum limit (100) of characters allowed.';
      } else {
        let pattern = /^[a-zA-Z\s\']+$/;
        if (!pattern.test(value)) error = 'Please enter Alphabets only';
      }
    } else if (type === 'designation') {
      let pattern = /^[a-zA-Z][a-zA-Z\s]*[a-zA-Z\s]$/;
      if (!value || value.trim() === '') {
        error = 'Designation is required';
      } else if (!pattern.test(value)) {
        error = 'Must contain only alphabets';
      } else if (value.length > 100) {
        error = 'Designation should be at most 100 characters';
      }
    } else if (type === 'bank_name') {
      const pattern = /^[a-zA-Z][a-zA-Z\-.)(&\s]+[a-zA-Z]*$/;
      if (!value || value.trim() === '') {
        error = 'Bank name is required';
      } else if (value.length > 200) {
        error = 'Bank name should be at most 200 characters';
      } else if (!pattern.test(value)) {
        error =
          'Bank name must contain only letters, whitespace and special characters like ().,&';
      }
    } else if (type === 'phone_number') {
      if (value && value.trim() !== '') {
        let pattern = /^[0-9]+$/;
        if (!pattern.test(value) || parseInt(value) < 1000000000)
          error = 'Please enter valid phone number';
      }
    } else if (type === 'email') {
      if (!value || value === '') {
        error = 'Email ID is required';
      } else {
        const specialCharAtStart = /^[^a-zA-Z0-9]+/;
        const specialCharAtEnd = /[^a-zA-Z0-9]+$/;
        const emailPart1Pattern = /^[a-zA-Z0-9]+([.!#$%&'*+\-/=?^_`{|]{0,1}[a-zA-Z0-9]+)*$/;
        const emailPart1 = value.replace(/\s/g, '').split('@')[0];
        const emailPart2 = value.replace(/\s/g, '').split('@')[1];
        const patternValue = !emailPart1Pattern.test(emailPart1);
        if (patternValue) {
          // recipient part
          const specialCharNotPattern = /[^a-zA-Z0-9.!#$%&'*+\-/=?^_`{|]+/;
          const dupSpecialCharPattern = /[.!#$%&'*+\-/=?^_`{|]{2,}/;
          if (emailPart1.match(specialCharNotPattern)) {
            error = "Only .!#$%&'*+-/=?^_`{| special characters are allowed";
          } else if (emailPart1.match(dupSpecialCharPattern)) {
            error =
              "Recipient's name must not contain consequent special characters";
          } else if (specialCharAtStart.test(emailPart1)) {
            error =
              "Recipient's name must not contain special character(s) at the beginning";
          } else if (specialCharAtEnd.test(emailPart1)) {
            error =
              "Recipient's name must not contain special character(s) at the end";
          }
        } else if (emailPart1.length > 64) {
          error = "Recipient's name must be at most 64 characters";
        }
        // domain & top domain validation
        const invalidCharsPattern = /[^a-zA-z0-9.-]+/g;
        const topDomainPattern = /^[.a-zA-z]{3,4}$/;
        const emailPart2Arr = emailPart2.split('.');
        const topDomain = `.${emailPart2Arr[emailPart2Arr.length - 1]}`;
        const domain = emailPart2Arr
          .join()
          .replace(/,/g, '.')
          .replace(topDomain, '');
        if (domain.length > 253) {
          error = 'Domain name must be at most 253 characters';
        } else if (domain.match(invalidCharsPattern)) {
          error =
            'Domain name must contain only letters, numbers, and special characters like (. -)';
        } else if (!topDomainPattern.test(topDomain)) {
          error = `Invalid domain '${topDomain}'`;
        }
        const invalidDomains = invalidDomainsArr.filter(x => value.includes(x));
        if (invalidDomains.length > 0) {
          error = `Invalid domain '${domain}'`;
        }
      }
    } else if (type === 'ifsc_code') {
      const pattern = /^[A-Z]{4}0[A-Z0-9]{6}$$/;
      if (!value || value.trim() === '') {
        error = 'IFSC code is required';
      } else if (!pattern.test(value)) {
        error =
          'IFSC code must contain only uppercase letters and numbers in the format: ABCD0123456';
      }
    } else if (type === 'educational_background') {
      if (!value || (value && value.trim() === '')) {
        error = 'You Need To choose an educational background';
      }
    } else if (type === 'pancard' || type === 'aadhar_card') {
      if (!value || (value && value.trim() === '')) {
        error = `${type === 'pancard' ? 'PAN Card' : 'Aadhar card '} required`;
      }
    }
    return error;
  };

  const validateOrgHierarchy = data => {
    let errorExists = false;
    const membersArr = members.slice();
    membersArr.map((member, index) => {
      member.member.map(field => {
        const key = field.key;
        const value = !Array.isArray(data[key]) ? data[key] : data[key][index];
        field.error = validate(field.key, value);
        if (field.error !== '') errorExists = true;
        return false;
      });
      return false;
    });
    setMembers(membersArr);
    return errorExists ? false : true;
  };

  const validateAuditors = data => {
    let errorExists = false;
    const auditorsArr = auditors.slice();
    auditorsArr.map((auditor, index) => {
      auditor.auditor.map(field => {
        const key =
          field.key === 'auditor_name' ? 'auditor_name' : 'auditor_since';
        const value = !Array.isArray(data[key]) ? data[key] : data[key][index];
        let error = '';
        if (field.key === 'auditor_name') {
          if (!value || value.trim() === '') {
            error = 'Please enter a valid auditor name';
          } else if (value.length > 100) {
            error =
              'You have reached your maximum limit (100) of characters allowed';
          } else {
            let pattern = /^[a-zA-Z\s\']+$/;
            if (!pattern.test(value)) error = 'Please enter Alphabets only';
          }
        } else {
          if (!value || (value && value.trim() === '')) {
            error = `Auditor since is required`;
          } else if (field.minyear || field.maxyear) {
            const numValue = Number(value);
            const today = new Date();
            if (numValue < field.minyear) {
              error = `Year cannot be less than ${field.minyear}`;
            } else if (
              field.maxyear === 'current' &&
              numValue > Number(today.getFullYear())
            ) {
              error = `Year cannot be greater than ${today.getFullYear()}`;
            }
          }
        }
        field.error = error;
        if (error !== '') errorExists = true;
        return false;
      });
      return false;
    });
    setAuditors(auditorsArr);
    return errorExists ? false : true;
  };

  const validateDonors = data => {
    let errorExists = false;
    const donors = topDonors.slice();
    donors.map((donor, index) => {
      const key = `names_of_top_3_donors_in_the_last_3_years_(donor_${index +
        1})`;
      const value = !Array.isArray(data[key]) ? data[key] : data[key][index];
      let error = '';
      if (!value || value.trim() === '') {
        error = 'Please enter a valid donor name';
      } else if (value.length < 2) {
        error = 'Please enter Minimum 2 Characters';
      } else if (value.length > 100) {
        error =
          'You have reached your maximum limit (100) of characters allowed';
      } else {
        let pattern = /^[a-zA-Z\s\']+$/;
        if (!pattern.test(value)) error = 'Please enter Alphabets only';
      }
      donor.error = error;
      if (error !== '') errorExists = true;
      return false;
    });
    setTopDonors(donors);
    return errorExists ? false : true;
  };

  const validateArea = () => {
    let errorExists = false;
    if (selectedState?.length === 0 || selectedDist?.length === 0) {
      if (selectedState?.length === 0) {
        setStateError(true);
        errorExists = true;
      }

      if (selectedDist?.length === 0) {
        setDistError(true);
        errorExists = true;
      }
    } else {
      const getStateWithaoutDist = getUniqueStateIds(
        selectedDist,
        props.state.common?.listDistricts,
        selectedState,
        props.state.common?.listStates
      );
      if (getStateWithaoutDist.length > 0) {
        setDistError(true);
        setMissState(getStateWithaoutDist);
        errorExists = true;
      }
    }
    return !errorExists;
  };

  const getUniqueStateIds = (
    distIdArr,
    allDistArrwithState,
    selState,
    allState
  ) => {
    const selectedDistArr = allDistArrwithState.filter(dist =>
      distIdArr.includes(dist.id)
    );
    const uniqueStateIds = [
      ...new Set(selectedDistArr.map(dist => dist.state_id))
    ];
    const missingStateIds = selState.filter(id => !uniqueStateIds.includes(id));

    const stateNames = [];
    for (const stateId of missingStateIds) {
      const state = allState.find(obj => obj.id === stateId);
      if (state) {
        stateNames.push(state.name);
      }
    }
    return stateNames;
  };

  const validatePolicy = data => {
    let errorExists = false;
    const formPolicies = getPolicyArr(data);
    const extractNumberFromString = str => {
      const match = str.match(/\d+/);
      return match ? parseInt(match[0]) : null;
    };

    const getDocumentByPid = (pid, documents) => {
      const document = documents.find(document => document.pid === pid);
      if (!document) {
        return '';
      }
      return document.document_url;
    };

    if (selectedPolicies.length && formPolicies.length) {
      const validStep = selectedPolicies?.map(pol => {
        const getPolId = extractNumberFromString(pol.jsonfield);
        const docUrl = getDocumentByPid(getPolId, formPolicies);
        if (!docUrl) {
          pol.error = `Please upload ${pol.label} document..`;
          errorExists = true;
        } else {
          pol.error = '';
        }
        return pol;
      });

      setSelectedPolicies([...validStep]);

      return errorExists ? false : true;
    } else {
      setPolError(true);
      return false;
    }
  };

  const getUrlArray = value => {
    if (!value || (value && value === '')) return [];
    if (value.includes(',')) {
      const fileURLArr = value.split(',');
      return fileURLArr;
    } else if (Array.isArray(value)) {
      return value;
    } else {
      return [value];
    }
  };
  const validateField = (value, field, uniqueIdentifierForInput) => {
    let error = '';
    if (value !== null || value !== undefined) {
      if (
        field.inputtype === 'text' ||
        field.inputtype === 'textNumber' ||
        field.inputtype === 'masterTextFields'
      ) {
        if (value.trim() === '') {
          error = `Please enter a valid ${field.label.toLowerCase()}`;
        }
        if (value === '0') {
          error = `Value should not be 0`;
        } else if (field.type === 'text' && field.validationtype !== 'url') {
          const trimValue = value.trim();
          if (trimValue === '')
            error = `Please enter a valid ${field.label.toLowerCase()}`;
          if (
            trimValue.length < field.lengthMin &&
            !(field.maxLength === field.lengthMin)
          )
            error = `Please enter Minimum ${field.lengthMin} Characters`;
          if (
            trimValue.length < field.lengthMin &&
            field.maxLength === field.lengthMin
          )
            error = `Value must be ${field.lengthMin} characters long`;
          if (field.validationpattern) {
            if (!field.validationpattern.test(value)) {
              if (field.validpatternformat)
                error = `Invalid value! Valid value example: ${field.validpatternformat}`;
              if (field.validpatternmessage) error = field.validpatternmessage;
            }
          }
        } else if (field.type === 'number') {
          const numValue = Number(value);
          if (field.maxValue && numValue > field.maxValue) {
            error = `Value can be up to ${field.maxValue}`;
          } else if (field.minValue && numValue < field.minValue) {
            error = `Minimum value Needs To be at least ${
              field.minValue >= 1 ? field.minValue : field.minValue.toFixed(8)
            }`;
          } else if (numValue < 0) {
            error = `Minimum value Needs To be at least 0`;
          } else if (field.minyear || field.maxyear) {
            const today = new Date();
            if (numValue < field.minyear) {
              error = `Year cannot be less than ${field.minyear}`;
            } else if (
              field.maxyear === 'current' &&
              numValue > Number(today.getFullYear())
            ) {
              error = `Year cannot be greater than ${today.getFullYear()}`;
            }
          }
          if (field.validationpattern) {
            if (!field.validationpattern.test(value)) {
              if (field.validpatternformat)
                error = `Invalid value! Valid value example: ${field.validpatternformat}`;
              if (field.validpatternmessage) error = field.validpatternmessage;
            }
          }
        } else if (field.type === 'email') {
          let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
          if (!pattern.test(value))
            error = 'Invalid email address/Incorrect format';
        } else if (field.type === 'url' || field.validationtype === 'url') {
          let pattern = /^https?:\/\/(?:(www\.|[a-zA-Z0-9-]+\.)|(?!www))([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.)+[a-zA-Z]{2,3}|^(www\.|[a-zA-Z0-9-]+\.)([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.)+[a-zA-Z]{2,3}/;
          if (!pattern.test(value))
            error = `Please specify a valid domain address`;
        } else if (field.type === 'tel') {
          if (field.maxValue && value.length < field.maxLength) {
            error = `Value must be equal to ${field.maxValue} digits`;
          } else if (Number(value) < 1000000000) {
            error = `Enter a valid phone number`;
          }
        }
      } else if (field.inputtype === 'textarea') {
        if (!value || (value && value.trim() === ''))
          error = `Please fill in this field`;
      } else if (
        field.inputtype === 'file' ||
        field.inputtype === 'multiplefilesnew'
      ) {
        if (
          (typeof value === 'string' && value.trim() === '') ||
          (Array.isArray(value) && value.length === 0)
        )
          error = `Please upload the necessary document(s)`;
      } else if (field.inputtype === 'multiselect') {
        if (Array.isArray(value)) {
          if (value.length === 0)
            error = `You Need To choose at least One ${field.label.toLowerCase()}`;
        } else {
          if (
            !value ||
            (value && (value.trim() === '' || value === 'Please Select'))
          )
            error = `You Need To choose at least One ${field.label.toLowerCase()}`;
        }
      } else if (
        field.inputtype === 'date' ||
        field.inputtype === 'yearSelector'
      ) {
        if (value.trim() === '' || value.trim().includes('00:00:00.0000')) {
          error = `Please select a date`;
        } else if (field.type === 'date' && field.maxToday) {
          const today = new Date();
          const selectedDate = new Date(getFormattedDate(value));
          if (today.getTime() < selectedDate.getTime()) {
            error = 'Date cannot be in the future';
          }
        }
      } else if (field.inputtype === 'radio') {
        if (value === '' || value === null || value === undefined) {
          error = 'Please select a value';
        }
      }
    } else {
      error = `Please fill in this field`;
    }

    const elements = document.getElementsByClassName(
      `${uniqueIdentifierForInput}-error`
    );
    if (elements.length > 0) {
      if (error === '') {
        elements[0].classList.remove('d-none');
      } else {
        elements[0].classList.remove('d-none');
      }
      elements[0].innerHTML = error;
    }
    return error === '' ? true : false;
  };
  const validateStep = (data, dataStep) => {
    let isValid = true;
    const stepIndex = parseInt(dataStep) - 1;
    const stepData = completeRegistrationData[stepIndex];
    stepData.fields.map((field, index) => {
      // let uniqueIdentifierForInput = field.label.replace(/\s/g, '_');
      // uniqueIdentifierForInput = uniqueIdentifierForInput
      //   .replace(/[?.]/g, '')
      //   .toLowerCase();
      // console.log(field.jsonfield, uniqueIdentifierForInput)
      const value = data[field.jsonfield];
      if (
        (field.validation === 'required' && !field.dependenton) ||
        (((!field.validation && field.validationpattern) ||
          field.minValue ||
          field.validationtype === 'url') &&
          value &&
          value.trim() !== '')
      ) {
        const valid = validateField(value, field, field.jsonfield);
        if (isValid && !valid) isValid = false;
      } else if (field.dependenton) {
        const parentFieldValue = data[field.dependenton];
        let checkValidity = false;
        if (!field.dependencyvalue) {
          if (data[field.dependenton]) checkValidity = true;
        } else {
          if (field.dependencyvalue.includes(',')) {
            const dependencyValueArr = field.dependencyvalue.split(',');
            dependencyValueArr.map(value => {
              if (parentFieldValue === value) checkValidity = true;
              return false;
            });
          } else {
            if (parentFieldValue === field.dependencyvalue)
              checkValidity = true;
          }
        }
        if (checkValidity) {
          const valid = validateField(value, field, field.jsonfield);
          if (isValid && !valid) isValid = false;
        }
      }
      return false;
    });
    return isValid;
  };
  const getFormattedDate = value => {
    const dateSplitArr = value.split('/');
    let date = null;
    if (dateSplitArr[0].length === 2) {
      date = new Date(
        Number(dateSplitArr[2]),
        Number(dateSplitArr[1]) - 1,
        Number(dateSplitArr[0])
      );
    } else {
      date = new Date(
        Number(dateSplitArr[0]),
        Number(dateSplitArr[1]) - 1,
        Number(dateSplitArr[2])
      );
    }
    let dateString = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    dateString = dateString + ' 00:00:00.0000';
    return dateString;
  };

  const getPolicyArr = data => {
    const policiesData = Object.keys(data)?.filter(key =>
      key.startsWith('policy')
    );

    if (policiesData?.length) {
      const extractNumberFromString = str => {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0]) : null;
      };

      const gerArraysOfPolicies = policiesData.map(polData => {
        const val = data[polData];
        return {
          document_url: val,
          policy_id: extractNumberFromString(polData),
          pid: extractNumberFromString(polData),
          is_deleted: false
        };
      });

      //return gerArraysOfPolicies.filter(item => item.document_url);
      return gerArraysOfPolicies;
    }
    return [];
  };

  const getYear = data => {
    let year = '';
    for (const key in data) {
      if (
        key.includes('organization_external_audit_report_for_fy_') &&
        year === ''
      ) {
        year = key.slice(-4);
      }
    }
    return year;
  };

  const onClick = e => {
    toast.dismiss();
    e.preventDefault();
    // if (!window.location.href.includes('email=')) {
    //   if (
    //     !(
    //       props.state.register.ngoSavedData.l1_status === 'new' ||
    //       props.state.register.ngoSavedData.l1_status === 'l1_rejected' ||
    //       props.state.register.ngoSavedData.l1_status ===
    //         'modification_required' ||
    //       props.state.register.ngoSavedData.l2_status ===
    //         'modification_required'
    //     )
    //   ) {
    //     return false;
    //   }
    // }
    setDisableSaveBtn(true);
    let data = convertFormDataToJson(new FormData(e.target));

    let dataStep = parseInt(e.target.getAttribute('data-step'));

    let isValid = validateStep(data, dataStep);
    const donorsIsValid =
      e.target.getAttribute('data-step') === '4' ? validateDonors(data) : false;

    const auditorsIsValid =
      e.target.getAttribute('data-step') === '4'
        ? validateAuditors(data)
        : false;
    const hierarchyIsValid =
      e.target.getAttribute('data-step') === '4'
        ? validateOrgHierarchy(data)
        : false;
    const fundsMobilizedIsValid =
      e.target.getAttribute('data-step') === '4'
        ? validateFundsMobilized(data)
        : false;
    if (!isValid) {
      setDisableSaveBtn(false);
      return toast.error(
        'Oops! Error(s) found. Please fix the Error(s) and save the data.'
      );
    }

    if (
      donorsIsValid ||
      auditorsIsValid ||
      hierarchyIsValid ||
      fundsMobilizedIsValid
    ) {
      setDisableSaveBtn(false);
    }
    let isSaved = checkIfStepSaved(
      props.state.register.ngoSavedData,
      e.target.getAttribute('data-step')
    );

    if (e.target.getAttribute('data-step') === '1') {
      const areaOfOperationValid =
        e.target.getAttribute('data-step') === '1' ? validateArea(data) : false;
      if (!areaOfOperationValid) {
        setDisableSaveBtn(false);
        return toast.error(
          'Please select and click on Apply in Area of operations and save the data.'
        );
      }

      data.country = 101;
      data.city = cityId;
      data.state = stateId;
      if (selectedState.length) {
        // data.areaOperationState = selectedState;
        if (selectedDist.length) {
          data.area_of_operation = selectedDist;
        }
      }

      if (!data.full_name) data.full_name = props.state.login.userInfo.name;
      if (!data.contact_number)
        data.contact_number = props.state.login.userInfo.contact_number;
      if (!data.email) data.email = props.state.login.userInfo.email;

      props
        .existence(
          {
            at_step: e.target.getAttribute('data-step'),
            id: `${userId || props.state.register.ngoSavedData.id}`,
            [e.target.getAttribute('data-wrapper-key')]: data
          },
          'put'
        )
        .then(res => {
          setDisableSaveBtn(false);
          if (res.data.status !== 500) {
            toast.success('Data saved!', {
              autoClose: 2000,
              toastId: 'profile_data_saved'
            });
            if (dataStep !== 7) {
              openCollapseSection(dataStep - 1, dataStep);
            } else {
              openCollapseSection(dataStep - 1);
            }
            props.getNgoProfile(props.state.login.userInfo);
          }
        });
    }
    if (e.target.getAttribute('data-step') === '2') {
      data[`certificate_80g`] = getUrlArray(data[`certificate_80g`]);
      data[`certificate_12a`] = getUrlArray(data[`certificate_12a`]);
      data[`moa`] = getUrlArray(data[`moa`]);
      data[`article_of_assc`] = getUrlArray(data[`article_of_assc`]);
      data[`date_of_incorp`] = getFormattedDate(data[`date_of_incorp`]);

      props
        .legal_compliance(
          {
            at_step: e.target.getAttribute('data-step'),
            id: `${userId || props.state.register.ngoSavedData.id}`,
            [e.target.getAttribute('data-wrapper-key')]: data
          },
          isSaved ? 'put' : 'post'
        )
        .then(res => {
          setDisableSaveBtn(false);
          if (res.data.status !== 500) {
            toast.success('Data saved!', {
              autoClose: 2000,
              toastId: 'profile_data_saved'
            });
            if (dataStep !== 7) {
              openCollapseSection(dataStep - 1, dataStep);
            } else {
              openCollapseSection(dataStep - 1);
            }
            props.getNgoProfile(props.state.login.userInfo);
          }
        });
    }

    if (e.target.getAttribute('data-step') === '3') {
      let todayDate = new Date();
      let incrementor = 0;
      let yearIncrementor = todayDate.getMonth() < 8 ? 2 : 1;
      const fullYear = todayDate.getFullYear();
      let balanceSheetArr = [];
      let externalAuditArr = [];
      for (var i = 1; i <= 3; i++) {
        let externalAuditYear = '';
        if (
          props.state.register.ngoSavedData.financial_capacity
            .external_audit_report.length > 0
        ) {
          const reportArr =
            props.state.register.ngoSavedData.financial_capacity
              .external_audit_report;
          externalAuditYear =
            reportArr[0]?.year === 0 ? reportArr[1]?.year : reportArr[0]?.year;
        } else {
          externalAuditYear = getYear(data);
        }
        const balanceSheetObj = {
          report: getUrlArray(
            data[
              `balance_sheet_for_fy_${fullYear - yearIncrementor}-${fullYear -
                i}`
            ]
          ),
          year: fullYear - yearIncrementor
        };

        const externalAuditObj = {
          report: getUrlArray(
            // data[
            //   `organization_external_audit_report_for_fy_${fullYear -
            //     yearIncrementor}-${fullYear - i}`
            // ]
            data[
              `organization_external_audit_report_for_fy_${externalAuditYear -
                i}-${externalAuditYear - incrementor}`
            ]
          ),
          year: fullYear - yearIncrementor
        };
        if (balanceSheetObj.report !== '')
          balanceSheetArr.push(balanceSheetObj);
        if (externalAuditObj.report !== '')
          externalAuditArr.push(externalAuditObj);
        delete data[
          `balance_sheet_for_fy_${fullYear - yearIncrementor}-${fullYear - i}`
        ];
        delete data[
          `organization_external_audit_report_for_fy_${fullYear -
            yearIncrementor}-${fullYear - i}`
        ];
        ++yearIncrementor;
        ++incrementor;
      }
      if (balanceSheetArr.length > 0) data.balance_sheet = balanceSheetArr;
      if (externalAuditArr.length > 0)
        data.external_audit_report = externalAuditArr;

      if (data.is_fcra_certified === true) {
        data.annual_return_filed_for_fcra = getUrlArray(
          data.annual_return_filed_for_fcra
        );
        data.fcra_balance_sheet = getUrlArray(data.fcra_balance_sheet);
        data.fcra_expiry_date = getFormattedDate(data.fcra_expiry_date);
        const purposeArr = data.purpose_under_which_fcra_license_is_guaranteed;
        data.purpose_under_which_fcra_license_is_guaranteed =
          purposeArr && purposeArr.length > 0
            ? purposeArr.map(Number)
            : purposeArr;
      } else {
        delete data.fcra_expiry_date;
        delete data.annual_return_filed_for_fcra;
        delete data.purpose_under_which_fcra_license_is_guaranteed;
        delete data.fcra_balance_sheet;
      }

      if (
        typeof data.last_year_of_filing_return_with_charity_commissioner ===
        'string'
      ) {
        if (data.last_year_of_filing_return_with_charity_commissioner === '') {
          delete data.last_year_of_filing_return_with_charity_commissioner;
        } else
          data.last_year_of_filing_return_with_charity_commissioner = Number(
            data.last_year_of_filing_return_with_charity_commissioner
          );
      }
      if (
        typeof data.last_year_of_filing_return_with_registrar_of_companies ===
        'string'
      ) {
        if (
          data.last_year_of_filing_return_with_registrar_of_companies === ''
        ) {
          delete data.last_year_of_filing_return_with_registrar_of_companies;
        } else {
          data.last_year_of_filing_return_with_registrar_of_companies = Number(
            data.last_year_of_filing_return_with_registrar_of_companies
          );
        }
      }
      if (
        typeof data.last_year_of_filing_return_with_registrar_of_societies ===
        'string'
      ) {
        if (
          data.last_year_of_filing_return_with_registrar_of_societies === ''
        ) {
          delete data.last_year_of_filing_return_with_registrar_of_societies;
        } else
          data.last_year_of_filing_return_with_registrar_of_societies = parseInt(
            data.last_year_of_filing_return_with_registrar_of_societies
          );
      }
      data.audited_financial_stmt = getUrlArray(data.audited_financial_stmt);
      data.budget_for_last_project_report = getUrlArray(
        data.budget_for_last_project_report
      );
      data.income_and_expenditure_statement = getUrlArray(
        data.income_and_expenditure_statement
      );
      data.quaterly_itr = getUrlArray(data.quaterly_itr);
      data.latest_income_and_expenditure_statement = getUrlArray(
        data.latest_income_and_expenditure_statement
      );
      data.fcra_purpose_id = getUrlArray(data.fcra_purpose_id).map(value =>
        Number(value)
      );
      data.annual_return_fcra = getUrlArray(data.annual_return_fcra);
      data.year_file_return_with_charity_of_comissioner =
        data.year_file_return_with_charity_of_comissioner === ''
          ? null
          : parseInt(data.year_file_return_with_charity_of_comissioner);
      data.year_file_return_with_registrar_of_companies =
        data.year_file_return_with_registrar_of_companies === ''
          ? null
          : parseInt(data.year_file_return_with_registrar_of_companies);
      delete data.legal_structure;
      props
        .financial_capacity(
          {
            at_step: e.target.getAttribute('data-step'),
            id: `${userId || props.state.register.ngoSavedData.id}`,
            [e.target.getAttribute('data-wrapper-key')]: data
          },
          isSaved ? 'put' : 'post'
        )
        .then(res => {
          setDisableSaveBtn(false);
          if (res.data.status !== 500) {
            toast.success('Data saved!', {
              autoClose: 2000,
              toastId: 'profile_data_saved'
            });
            if (dataStep !== 7) {
              openCollapseSection(dataStep - 1, dataStep);
            } else {
              openCollapseSection(dataStep - 1);
            }
            props.getNgoProfile(props.state.login.userInfo);
          }
        });
    }

    if (e.target.getAttribute('data-step') === '4') {
      const policyIsValid =
        e.target.getAttribute('data-step') === '4'
          ? validatePolicy(data)
          : false;

      if (!policyIsValid) {
        return toast.error(
          'Error(s) found! Please click on Apply for Policies defined and followed in your organisation'
        );
      }
      if (
        fundsMobilizedIsValid &&
        donorsIsValid &&
        auditorsIsValid &&
        hierarchyIsValid &&
        policyIsValid
      ) {
        const boardMembers = [];
        if (members.length > 1) {
          for (let i = 0; i < members.length; i++) {
            const object = {
              member_name: data.member_name[i],
              designation: data.designation[i],
              email: data.email[i],
              photo: data.photo ? data.photo[i] : null,
              pancard: data.pancard[i],
              educational_background: data.educational_background[i],
              aadhar_card: data.aadhar_card[i],
              phone_number: data.phone_number[i]
            };
            if (members[i].member[0].id) {
              object['member_id'] = members[i].member[0].id;
            }
            boardMembers.push(object);
          }
        } else {
          const object = {
            member_name: data.member_name,
            designation: data.designation,
            email: data.email,
            photo: data.photo ? data.photo : null,
            pancard: data.pancard,
            educational_background: data.educational_background,
            aadhar_card: data.aadhar_card,
            phone_number: data.phone_number
          };
          if (members[0].member[0].id) {
            object['member_id'] = members[0].member[0].id;
          }
          boardMembers.push(object);
        }
        if (deletedMembers.length > 0) {
          deletedMembers.map((member, index) => {
            const object = {
              member_id: member.member_id,
              member_name: member.member_name,
              designation: member.designation,
              email: member.email,
              photo: member.photo ? member.photo : null,
              pancard: member.pan_card,
              educational_background: member.educational_background,
              aadhar_card: member.aadhar_card,
              phone_number: member.phone_number,
              is_delete: true
            };
            boardMembers.push(object);
            return false;
          });
        }
        delete data.member_name;
        delete data.designation;
        delete data.email;
        delete data.photo;
        delete data.pan_card;
        delete data.aadhar_card;
        delete data.phone_number;
        data.ngo_board_members = boardMembers;

        const auditorsArr = [];
        if (auditors.length > 1) {
          for (let i = 0; i < auditors.length; i++) {
            const object = {
              auditor_name: data.auditor_name[i],
              auditor_since: Number(data.auditor_since[i])
            };
            if (auditors[i].auditor[0].id) {
              object['auditor_id'] = auditors[i].auditor[0].id;
            }
            auditorsArr.push(object);
          }
        } else {
          const object = {
            auditor_name: data.auditor_name,
            auditor_since: Number(data.auditor_since)
          };
          if (auditors[0].auditor[0].id) {
            object['auditor_id'] = auditors[0].auditor[0].id;
          }
          auditorsArr.push(object);
        }
        if (deletedAuditors.length > 0) {
          deletedAuditors.map((auditor, index) => {
            const object = {
              auditor_id: auditor.auditor_id,
              auditor_name: auditor.auditor_name,
              auditor_since: auditor.auditor_since,
              is_delete: true
            };
            auditorsArr.push(object);
            return false;
          });
        }
        delete data.auditor_name;
        delete data.auditor_since;
        data.auditors = auditorsArr;

        const topDonorsArray = [];
        for (let i = 0; i < topDonors.length; i++) {
          topDonorsArray.push(
            data[`names_of_top_3_donors_in_the_last_3_years_(donor_${i + 1})`]
          );
        }
        delete data[`names_of_top_3_donors_in_the_last_3_years_(donor_1)`];
        delete data[`names_of_top_3_donors_in_the_last_3_years_(donor_2)`];
        delete data[`names_of_top_3_donors_in_the_last_3_years_(donor_3)`];
        data.top_3_donors = topDonorsArray;

        const fundsMobilizedArr = [];
        for (let i = 0; i < fundsMobilized.length; i++) {
          const value =
            data[`funds_mobilised_in_last_year_${fundsMobilized[i].year}`];
          fundsMobilizedArr.push({
            year: fundsMobilized[i].year,
            funds_mobilised:
              value !== ''
                ? getNumber(
                    data[
                      `funds_mobilised_in_last_year_${fundsMobilized[i].year}`
                    ]
                  )
                : 0
          });
        }
        delete data[`funds_mobilised_in_last_year_${fundsMobilized[0].year}`];
        delete data[`funds_mobilised_in_last_year_${fundsMobilized[1].year}`];
        delete data[`funds_mobilised_in_last_year_${fundsMobilized[2].year}`];

        data.funds_mobilised_arr = fundsMobilizedArr;
      } else {
        return toast.error(
          'Error(s) found! Correct the error(s) and save the data'
        );
      }
      // console.log(data['policy_list']);
      // data[`policy_list`] = getUrlArrayPloicies(data[`policy_list`]);
      // console.log(data['policy_list']);
      if (data[`any_directors_have_resigned`]) {
        // data[`year_of_directors_resignation`] = Number(
        //   data[`year_of_directors_resignation`]
        data[`year_of_directors_resignation`] = getNumber(
          data[`year_of_directors_resignation`]
        );
      } else {
        delete data[`year_of_directors_resignation`];
      }
      if (data[`any_auditors_have_resigned`]) {
        // data[`year_of_auditors_resignation`] = Number(
        //   data[`year_of_auditors_resignation`]
        data[`year_of_auditors_resignation`] = getNumber(
          data[`year_of_auditors_resignation`]
        );
      } else {
        delete data[`year_of_auditors_resignation`];
      }
      if (data[`emp_count`]) {
        // data[`emp_count`] = Number(data[`emp_count`]);
        data[`emp_count`] = getNumber(data[`emp_count`]);
      } else {
        delete data[`emp_count`];
      }
      if (data[`full_time_staff_count`]) {
        // data[`full_time_staff_count`] = Number(data[`full_time_staff_count`]);
        data[`full_time_staff_count`] = getNumber(
          data[`full_time_staff_count`]
        );
      } else {
        delete data[`full_time_staff_count`];
      }
      if (data[`contractual_staff_count`]) {
        // data[`contractual_staff_count`] = Number(
        //   data[`contractual_staff_count`]
        data[`contractual_staff_count`] = getNumber(
          data[`contractual_staff_count`]
        );
      } else {
        delete data[`contractual_staff_count`];
      }
      if (data[`volunteer_staff_count`]) {
        // data[`volunteer_staff_count`] = Number(data[`volunteer_staff_count`]);
        data[`volunteer_staff_count`] = getNumber(
          data[`volunteer_staff_count`]
        );
      } else {
        delete data[`volunteer_staff_count`];
      }
      delete data.educational_background;

      data.policies = getPolicyArr(data);

      props
        .institutional_capacity(
          {
            at_step: e.target.getAttribute('data-step'),
            id: `${userId || props.state.register.ngoSavedData.id}`,
            [e.target.getAttribute('data-wrapper-key')]: data
          },
          isSaved ? 'put' : 'post'
        )
        .then(res => {
          setDisableSaveBtn(false);
          if (res?.data?.status !== 500) {
            toast.success('Data saved!', {
              autoClose: 2000,
              toastId: 'profile_data_saved'
            });
            if (dataStep !== 7) {
              openCollapseSection(dataStep - 1, dataStep);
            } else {
              openCollapseSection(dataStep - 1);
            }
            props.getNgoProfile(props.state.login.userInfo);
          }
        });
    }

    if (e.target.getAttribute('data-step') === '5') {
      data['number_of_projects_till_date'] = getNumber(
        data['number_of_projects_till_date']
      );
      data['average_budget_per_project'] = getNumber(
        data['average_budget_per_project']
      );
      data['number_of_beneficiaries_impacted_till_date'] = getNumber(
        data['number_of_beneficiaries_impacted_till_date']
      );
      data[`achievement_rewards`] = getUrlArray(data[`achievement_rewards`]);
      data[`how_do_you_measure_your_organisation_social_impact`] = getUrlArray(
        data[`how_do_you_measure_your_organisation_social_impact`]
      );
      data[`project_impact_report_for_recent_projects`] = getUrlArray(
        data[`project_impact_report_for_recent_projects`]
      );
      data[`additional_project`] = getUrlArray(data[`additional_project`]);
      data[`assessment_document`] = getUrlArray(data[`assessment_document`]);

      props
        .assessment(
          {
            at_step: e.target.getAttribute('data-step'),
            id: `${userId || props.state.register.ngoSavedData.id}`,
            [e.target.getAttribute('data-wrapper-key')]: data
          },
          isSaved ? 'put' : 'post'
        )
        .then(res => {
          setDisableSaveBtn(false);
          if (res.data.status !== 500) {
            toast.success('Data saved!', {
              autoClose: 2000,
              toastId: 'profile_data_saved'
            });
            if (dataStep !== 7) {
              openCollapseSection(dataStep - 1, dataStep);
            } else {
              openCollapseSection(dataStep - 1);
            }
            props.getNgoProfile(props.state.login.userInfo);
          }
        });
    }

    if (e.target.getAttribute('data-step') === '6') {
      // data[`website_review`] = [data[`website_review`]];
      data[`campaign_details_documents`] = getUrlArray(
        data[`campaign_details_documents`]
      );

      props
        .social_activity(
          {
            at_step: e.target.getAttribute('data-step'),
            id: `${userId}`,
            [e.target.getAttribute('data-wrapper-key')]: data
          },
          isSaved ? 'put' : 'post'
        )
        .then(res => {
          setDisableSaveBtn(false);
          if (res.data.status !== 500) {
            toast.success('Data saved!', {
              autoClose: 2000,
              toastId: 'profile_data_saved'
            });
            if (dataStep !== 7) {
              openCollapseSection(dataStep - 1, dataStep);
            } else {
              openCollapseSection(dataStep - 1);
            }
            props.getNgoProfile(props.state.login.userInfo);
          }
        });
    }
    if (e.target.getAttribute('data-step') === '7') {
      // data[`website_review`] = [data[`website_review`]];
      data[`campaign_details_documents`] = getUrlArray(
        data[`campaign_details_documents`]
      );

      props.consent().then(res => {
        setDisableSaveBtn(false);
        if (res.data.status !== 500) {
          toast.success('Data saved!', {
            autoClose: 2000,
            toastId: 'profile_data_saved'
          });
          if (dataStep !== 7) {
            openCollapseSection(dataStep - 1, dataStep);
          } else {
            openCollapseSection(dataStep - 1);
          }
          props.getNgoProfile(props.state.login.userInfo);
        }
      });
    }

    setDisableSaveBtn(false);
    return false;
  };
  const initializeChiefFunctionariesData = () => {
    if (
      Object.keys(props.state.register.ngoSavedData).length === 0 ||
      (Object.keys(props.state.register.ngoSavedData).length > 0 &&
        props.state.register.ngoSavedData.institutional_capacity
          .ngo_board_members.length === 0)
    ) {
      let membersArr = [];
      membersArr.push({
        member: getChiefFunctionariesData()
      });
      setMembers(membersArr);
    } else {
      // setMembersDataMapped(true);
      const ngoBoardMembers =
        props.state.register.ngoSavedData.institutional_capacity
          .ngo_board_members;
      if (ngoBoardMembers) {
        let membersArr = [];
        ngoBoardMembers.map(member => {
          let objArr = getChiefFunctionariesData(member.id);
          membersArr.push({
            member: objArr
          });
          return false;
        });
        setMembers(membersArr);
      }
    }
    return false;
  };
  const addMember = () => {
    if (members.length < 10) {
      let membersArr = members.slice();
      membersArr.push({
        member: getChiefFunctionariesData()
      });
      setMembers(membersArr);
    }
  };
  const deleteMember = index => {
    const formElements = document.getElementById('step-form-3').elements;
    let objArr = [];
    let counter = 0;
    let obj = {};
    for (var i = 0; i < formElements.length; i++) {
      let item = formElements.item(i);
      if (
        item.name !== '' &&
        (item.name === 'member_name' ||
          item.name === 'designation' ||
          // item.name === 'photo' ||
          item.name === 'phone_number' ||
          item.name === 'email' ||
          item.name === 'pancard' ||
          item.name === 'aadhar_card' ||
          item.name === 'educational_background')
      ) {
        obj[item.name] = item.value;
        counter++;
      }
      if (counter === 7) {
        objArr.push(obj);
        obj = {};
        counter = 0;
      }
    }
    if (members[index].member[0].id) {
      let deletedMembersArr = deletedMembers.slice();
      deletedMembersArr.push({
        ...objArr[index],
        member_id: members[index].member[0].id
      });
      setDeletedMembers(deletedMembersArr);
    }
    objArr.splice(index, 1);
    let ObjPosCounter = 0;
    for (var b = 0; b < formElements.length; b++) {
      let item = formElements.item(b);
      if (
        item &&
        item.name !== '' &&
        ObjPosCounter < objArr.length &&
        (item.name === 'member_name' ||
          item.name === 'designation' ||
          // item.name === 'photo' ||
          item.name === 'phone_number' ||
          item.name === 'email' ||
          item.name === 'pancard' ||
          item.name === 'aadhar_card' ||
          item.name === 'educational_background')
      ) {
        item.value = item.name ? objArr[ObjPosCounter][item.name] : '';
        counter++;
      }
      if (counter === 7) {
        counter = 0;
        ObjPosCounter++;
      }
    }
    if (members.length >= 2) {
      let membersArr = members.slice();
      membersArr.splice(index, 1);
      setMembers(membersArr);
    }
    fieldUpdated(3);
  };
  const checkStepStatus = () => {
    let statusArr = [];
    for (let index = 0; index < 7; index++) {
      const value = isSectionSaved(props.state.register.ngoSavedData, index);
      statusArr.push(value);
    }
    setStepStatuses(statusArr);
  };
  const initAuditorsData = () => {
    if (
      Object.keys(props.state.register.ngoSavedData).length === 0 ||
      (Object.keys(props.state.register.ngoSavedData).length > 0 &&
        props.state.register.ngoSavedData.institutional_capacity.auditors
          .length === 0)
    ) {
      let auditorsArr = [];
      auditorsArr.push({
        auditor: getAuditorData()
      });
      setAuditors(auditorsArr);
    } else {
      // setAuditorsDataMapped(true);
      const auditorsData =
        props.state.register.ngoSavedData.institutional_capacity.auditors;
      if (auditorsData) {
        let auditorsArr = [];
        auditorsData.map(auditor => {
          let objArr = getAuditorData(auditor.id);
          auditorsArr.push({
            auditor: objArr
          });
          return false;
        });
        setAuditors(auditorsArr);
      }
    }
    return false;
  };
  const addAuditor = () => {
    if (auditors.length <= 4) {
      let auditorsArr = auditors.slice();
      auditorsArr.push({
        auditor: getAuditorData()
      });
      setAuditors(auditorsArr);
    }
  };
  const deleteAuditor = index => {
    const formElements = document.getElementById('step-form-3').elements;
    let objArr = [];
    let counter = 0;
    let obj = {};
    for (var i = 0; i < formElements.length; i++) {
      let item = formElements.item(i);
      if (
        item.name !== '' &&
        (item.name === 'auditor_name' || item.name === 'auditor_since')
      ) {
        obj[item.name] = item.value;
        counter++;
      }
      if (counter === 2) {
        objArr.push(obj);
        obj = {};
        counter = 0;
      }
    }
    if (auditors[index].auditor[0].id) {
      let deletedAuditorsArr = deletedAuditors.slice();
      deletedAuditorsArr.push({
        ...objArr[index],
        auditor_id: auditors[index].auditor[0].id
      });
      setDeletedAuditors(deletedAuditorsArr);
    }
    objArr.splice(index, 1);
    let ObjPosCounter = 0;
    for (var b = 0; b < formElements.length; b++) {
      let item = formElements.item(b);
      if (
        item &&
        item.name !== '' &&
        ObjPosCounter < objArr.length &&
        (item.name === 'auditor_name' || item.name === 'auditor_since')
      ) {
        item.value = item.name ? objArr[ObjPosCounter][item.name] : '';
        counter++;
      }
      if (counter === 2) {
        counter = 0;
        ObjPosCounter++;
      }
    }
    if (auditors.length >= 2) {
      let auditorsArr = auditors.slice();
      auditorsArr.splice(index, 1);
      setAuditors(auditorsArr);
    }
    fieldUpdated(3);
  };
  const initTopDonorData = () => {
    let topDataArray = [];
    for (let index = 0; index < 3; index++) {
      topDataArray.push(getDonorField(index));
    }
    setTopDonors(topDataArray);
  };
  const initFundsMobilized = () => {
    let fundsMobilizedArray = [];
    const today = new Date();
    for (let index = 0; index < 3; index++) {
      fundsMobilizedArray.push(
        getFundsMobilizedField(index, Number(today.getFullYear()) - (index + 1))
      );
    }

    setFundsMobilized(fundsMobilizedArray);
  };
  const validateFundsMobilized = data => {
    let errorExists = false;
    const fundsMobilizedArr = fundsMobilized.slice();
    fundsMobilizedArr.map((funds, index) => {
      const key = `funds_mobilised_in_last_year_${funds.year}`;
      const value = !Array.isArray(data[key]) ? data[key] : data[key][index];
      let error = '';
      if (!value || value.trim() === '') {
        error = 'Please enter a valid fund amount';
      } else if (value && Number(value) < 0) {
        error = 'Minimum value Needs To be at least 0';
      } else if (value && Number(value) > 1000000000) {
        error = 'Maximum value Needs To up to 100,00,00,000';
      }
      funds.error = error;
      if (error !== '') errorExists = true;
      return false;
    });
    setFundsMobilized(fundsMobilizedArr);
    return errorExists ? false : true;
  };
  const showHideSideModal = e => {
    e.preventDefault();
    e.stopPropagation();
    if (showSideModal) {
      setShowSideModal(false);
    } else {
      setShowSideModal(true);
    }
  };
  const setModalTitle = (stepIndex, e, subSectionId, index) => {
    setSectionId(stepIndex + 1);
    if (subSectionId) setSubSectionId(subSectionId);
    setSideModalTitle(
      `Step ${stepIndex + 1}: ${completeRegistrationData[stepIndex].title} ${
        index > -1
          ? `> ${completeRegistrationData[stepIndex].fields[index].heading}`
          : ' '
      }`
    );
    showHideSideModal(e);
  };
  const checkStateCityValues = () => {
    if (
      props.state.register.ngoSavedData &&
      props.state.register.ngoSavedData.existence &&
      props.state.register.ngoSavedData.existence.state &&
      props.state.register.ngoSavedData.existence.city &&
      !stateId &&
      !cityId
    ) {
      locationUpdated(
        props.state.register.ngoSavedData.existence.state,
        props.state.register.ngoSavedData.existence.city
      );
    }
  };
  const checkAndSetStepEditStatus = commentsData => {
    if (commentsData && commentsData.length > 0) {
      let stepEditStatusesArr = stepEditStatuses.slice();
      const savedReqModObject = JSON.parse(
        window.localStorage.getItem('profileRequestMod')
      );
      let editRequested = false;
      const profileReqModStatus =
        props.state.register.ngoSavedData.l1_status ===
          'modification_required' ||
        props.state.register.ngoSavedData.l2_status === 'modification_required';

      commentsData.map(comment => {
        if (
          !stepEditStatusesArr[comment.section_id - 1] &&
          comment.is_external &&
          comment.is_deleted === false &&
          !comment.is_resolved
        ) {
          stepEditStatusesArr[comment.section_id - 1] = true;
          editRequested = true;
        } else if (
          profileReqModStatus &&
          comment.is_deleted === false &&
          comment.is_external &&
          savedReqModObject &&
          savedReqModObject.stepEditStatuses[comment.section_id - 1]
        ) {
          stepEditStatusesArr[comment.section_id - 1] = true;
          editRequested = true;
        }
        return false;
      });

      if (editRequested && profileReqModStatus) {
        const obj = {
          profileId: props.state.register.ngoSavedData.id,
          stepEditStatuses: stepEditStatusesArr
        };
        window.localStorage.setItem('profileRequestMod', JSON.stringify(obj));
      } else if (savedReqModObject && !profileReqModStatus) {
        window.localStorage.removeItem('profileRequestMod');
      }
      setStepEditStatuses(stepEditStatusesArr);
    } else {
      setStepEditStatuses([true, true, true, true, true, true, true]);
    }
  };

  useEffect(() => {
    const masterDataOptions = Common.NGO.MASTER_OPTIONS;

    // getLegal Structure
    props.getLegalStructure();
    props.getAllStatesForArea();
    props.getFcraPurpose();
    props.getEducationalBackground();
    props.getTypesOfAccount();
    props.getFullTimeEmployees();
    props.getGuideStarCertificationLevel();
    props.getMasterData([
      masterDataOptions.PRIMARY_BENEFICIARIES,
      masterDataOptions.CAUSE_CATEGORY,
      masterDataOptions.PRIMARY_FUNDING_SOURCES,
      masterDataOptions.NESTED_CAUSE_CATEGORY
    ]);
    props.getSavedNGOData(props.state.ngo.ngoProfile.ngo_id);

    // props.updateNgoId(props.state.ngo.ngoProfile.ngo_id);
    let instance = HttpRequest.getInstance('HIROKU');

    instance
      .post(EndPoint.getApiUrl('NGO', 'NGO_PROFILE', 'HIROKU'))
      .then(response => {
        if (props?.state?.login?.userInfo?.sub) {
          props.getSavedNGOData(props?.state?.login?.userInfo?.sub);
        }
      });
  }, []);

  useEffect(() => {
    initializeChiefFunctionariesData();
    initAuditorsData();
    initTopDonorData();
    initFundsMobilized();
    checkStepStatus();

    if (props.state.register.ngoSavedData.id)
      props
        .getCommentsByNgoId(props.state.register.ngoSavedData.id)
        .then(res => {
          let commentsArr = [];
          if (Array.isArray(res?.result) || Array.isArray(res)) {
            commentsArr = Array.isArray(res?.result) ? res?.result : res;
          }
          setallNgoComments(commentsArr);
          checkAndSetStepEditStatus(commentsArr);
        });
    checkStateCityValues();
  }, [props.state.register.ngoSavedData]);

  useEffect(() => {}, [stepStatuses, members, auditors]);

  const openCollapseSection = (closeIndex, openIndex) => {
    const closeButton = document.querySelectorAll(
      `[data-target="#step-${closeIndex}"]`
    );
    const openButton = openIndex
      ? document.querySelectorAll(`[data-target="#step-${openIndex}"]`)
      : null;
    if (closeButton) {
      if (closeButton[0]) closeButton[0].click();
    }
    if (openButton) {
      if (openButton[0]) {
        openButton[0].click();
        openButton[0].scrollIntoView();
        const y = openButton[0].top + window.scrollY;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }
    }
  };
  const fieldUpdated = stepIndex => {
    let stepStatusData = stepStatuses.slice();
    stepStatusData[stepIndex] = false;
    setStepStatuses(stepStatusData);
  };

  const policyFieldUpdate = (policyId, fileUrl) => {
    const removeError = selectedPolicies.map(polObj => {
      if (polObj.jsonfield === policyId) {
        if (fileUrl) polObj.document_url = fileUrl;
        if (polObj.error) polObj.error = '';
      }
      return polObj;
    });
    setSelectedPolicies([...removeError]);
  };

  const locationUpdated = (stateValue, cityValue) => {
    setStateId(stateValue);
    setCityId(cityValue);
  };

  const locationUpdatedForArea = (type, stateValue) => {
    if (type === 'area_state') {
      if (stateValue.length > 0) {
        setMissState([]);
        setSelectedState(stateValue);

        const dataForDist = {
          state_id: stateValue
        };
        props.getAllDistByStateForArea(dataForDist);
      } else {
        setSelectedState([]);
        setSelectedDist([]);
      }
    } else if (type === 'area_districts') {
      setSelectedDist(stateValue);
    } else {
      policiesUpdate(type, stateValue);
    }
  };

  const policiesUpdate = (type, stateValue) => {
    if (
      props.state.register.ngoSavedData?.institutional_capacity?.organorgram
        ?.policy_list?.length ||
      props.state.common?.masterInfo?.policies.length
    ) {
      const getPolIds = props.state.register.ngoSavedData?.institutional_capacity?.organorgram?.policy_list?.map(
        polItem => {
          return {
            id: polItem.policy_id,
            name: polItem.policy,
            document_url: polItem.document_url
          };
        }
      );

      if (getPolIds?.length) {
        const uniquePolicies = [
          ...new Set(getPolIds.map(policy => policy.id))
        ].map(id => getPolIds.find(policy => policy.id === id));

        const filterArray = (arr1, arr2) => {
          const extractNumberFromString = str => {
            const match = str.match(/\d+/);
            return match ? parseInt(match[0]) : null;
          };

          const idSet = new Set(arr1);
          const result = [];
          for (const id of idSet) {
            const obj = arr2.find(o => o.id === id);
            if (obj) {
              result.push(obj);
            } else {
              const docUrl = selectedPolicies?.filter(
                obj => extractNumberFromString(obj.jsonfield) === id
              );

              result.push({
                id: id,
                name: id,
                document_url: docUrl?.length ? docUrl[0]?.document_url : ''
              });
            }
          }
          return result;
        };

        prePoliciesUpdate('policies', filterArray(stateValue, uniquePolicies));
      } else {
        const filterArray = (arr1, arr2) => {
          const extractNumberFromString = str => {
            const match = str.match(/\d+/);
            return match ? parseInt(match[0]) : null;
          };

          const idSet = new Set(arr1);
          const result = [];
          for (const id of idSet) {
            const obj = arr2.find(o => o.id === id);
            if (obj) {
              result.push(obj);
            } else {
              const docUrl = selectedPolicies?.filter(
                obj => extractNumberFromString(obj.jsonfield) === id
              );
              result.push({
                id: id,
                name: id,
                document_url: docUrl?.length ? docUrl[0]?.document_url : ''
              });
            }
          }
          return result;
        };

        prePoliciesUpdate('policies', filterArray(stateValue, []));
      }
    }
  };

  const prePoliciesUpdate = (type, stateValue) => {
    if (stateValue.length) {
      const commonObjects = props.state.common?.masterInfo?.policies?.filter(
        obj1 => stateValue.some(obj2 => obj2.id === obj1.id)
      );
      const getOnlyIds = stateValue?.map(item => item.id);
      const newObj = commonObjects.map(policyItem => {
        const docUrl = stateValue?.filter(obj => obj.id === policyItem.id);

        return {
          subWrapperKey: 'institution_cap',
          label: policyItem.policy,
          accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
          inputtype: 'file',
          fileSizeLimit: 25,
          type: 'file',
          previewDoc: true,
          showrequired: true,
          // dependenton: 'policies_defined_and_followed_in_your_organisation',
          dependencywrapperkey: 'institutional_capacity',
          dependencysubwrapperkey: 'institution_cap',
          document_url: docUrl?.length ? docUrl[0]?.document_url : '',
          jsonfield: `policy-${policyItem.id}-${policyItem.policy
            .toLowerCase()
            .replace(/[^\w\s]/gi, '')
            .replace(/\s+/g, '_')
            .trim()}`
        };
      });

      setSelectedPolicies([...newObj]);
      setSelectedPoliciesItem([...getOnlyIds]);
    } else {
      setSelectedPolicies([]);
      setSelectedPoliciesItem([]);
    }
  };

  const renderPolicies = (type, stepIndex, mainIndex) => {
    if (type === 'policy_list') {
      const newObj = selectedPolicies.map((policiesObj, index) => {
        return (
          <ProfileSingleField
            input={policiesObj}
            key={`${stepIndex}-${mainIndex}-${index}`}
            stepIndex={stepIndex}
            disabled={disabled}
            state={props.state}
            fieldUpdated={fieldUpdated}
            policyFieldUpdate={policyFieldUpdate}
            locationUpdated={locationUpdated}
            setFCRATrue={setFCRATrue}
            setFCRAFalse={setFCRAFalse}
            dynamicType={'policies'}
            policiesValue={policiesObj?.document_url || ''}
          />
        );
      });
      return newObj;
    } else {
      return null;
    }
  };

  const getActiveReviewCount = (index, type) => {
    let count = 0;
    if (allNgoComments && allNgoComments.length > 0) {
      let comments = [];
      if (type && type === 'active') {
        comments = allNgoComments.filter(
          comment =>
            index === comment.section_id - 1 &&
            comment.is_external &&
            !comment.is_resolved &&
            comment.is_deleted === false
        );
      } else {
        comments = allNgoComments.filter(
          comment =>
            index === comment.section_id - 1 &&
            comment.is_external &&
            comment.is_deleted === false
        );
      }
      count = comments.length;
      if (type && type === 'active') {
        if (count === 0) {
          resolvedSectionCmt = true;
        } else {
          resolvedSectionCmt = false;
        }
      }
    }
    return count;
  };

  const getResolvedComments = (stepIndex, subSectionIndex) => {
    let comments = allNgoComments.filter(
      comment =>
        stepIndex === comment.section_id - 1 &&
        subSectionIndex === comment.sub_section_id &&
        comment.is_external &&
        comment.is_resolved
    );
    return comments;
  };
  const getActiveSubSectionReviewCount = (stepIndex, subSectionIndex) => {
    let count = 0;
    let comments;
    if (allNgoComments && allNgoComments.length > 0) {
      comments = allNgoComments.filter(
        comment =>
          stepIndex === comment.section_id - 1 &&
          subSectionIndex === comment.sub_section_id &&
          comment.is_external &&
          !comment.is_resolved &&
          comment.is_deleted === false
      );
      if (comments?.length === 0) {
        comments = getResolvedComments(stepIndex, subSectionIndex);
        count = comments.length;
        if (count === 0) {
          resolvedSubSectionCmt = false;
        } else {
          resolvedSubSectionCmt = true;
        }
      } else {
        count = comments.length;
        if (count === 0) {
          resolvedSubSectionCmt = true;
        } else {
          resolvedSubSectionCmt = false;
        }
      }
    }
    return count;
  };

  if (!Object.entries(props.state.register.ngoSavedData).length) {
    return <Loader />;
  }

  const renderFinancialData = (stepIndex, index) => {
    const finField = getFinancialsData(
      props?.state?.register?.ngoSavedData?.legal_compliance?.date_of_incorp
    );

    const finUi = finField?.map(finItem => {
      return (
        <ProfileSingleField
          input={finItem}
          key={`${stepIndex}-${index}`}
          stepIndex={stepIndex}
          disabled={disabled}
          state={props.state}
          fieldUpdated={fieldUpdated}
          locationUpdated={locationUpdated}
          setFCRATrue={setFCRATrue}
          setFCRAFalse={setFCRAFalse}
        />
      );
    });
    return finUi;
  };

  const showFundMobilisedModal = () => {
    setFundMobilisationPastData(true);
  };
  return (
    <div className="d-flex flex-column w-100 complete-registration-form pr-4 pt-4">
      <div className="row">
        <div className="d-flex flex-column col-9 pl-0">
          <div className="pl-4 pr-4 ">
            {completeRegistrationData.map((stepData, stepIndex) => {
              const reviewCount = getActiveReviewCount(stepIndex, 'active');
              const totalReviewsCount = getActiveReviewCount(stepIndex);
              resolveCmtsArr.push(reviewCount);
              modificationRequired =
                props.state.register.ngoSavedData.l1_status ===
                  'modification_required' ||
                props.state.register.ngoSavedData.l2_status ===
                  'modification_required';
              return (
                <section
                  className="mb-4"
                  key={stepIndex}
                  id={`step-container-${stepIndex + 1}`}>
                  <button
                    className="btn btn-lg w-100 text-left collapsed display-4 accordion-button px-4 position-relative"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#step-${stepIndex}`}
                    aria-expanded="false"
                    aria-controls="collapseExample">
                    <p className="subtitle mb-0">{stepData.subTitle}</p>
                    <p className="mb-0  font-size">{stepData.title}</p>
                    {(reviewCount > 0 || stepEditStatuses[stepIndex]) &&
                      modificationRequired && (
                        <span
                          className={` ${
                            resolvedSectionCmt === true
                              ? 'green-dot'
                              : 'alert-dot'
                          } step-alert-dot position-absolute`}></span>
                      )}
                    <i className="fas fa-chevron-up step-toggle-icon position-absolute "></i>
                  </button>
                  <div className="collapse font-size" id={`step-${stepIndex}`}>
                    <div className="card card-body px-2">
                      <form
                        method="post"
                        className="form-row"
                        id={`step-form-${stepIndex}`}
                        onSubmit={onClick}
                        action={stepData.apiUrl}
                        data-step={`${stepIndex + 1}`}
                        data-wrapper-key={stepData.wrapperKey}>
                        {stepData.fields.map((input, index) => {
                          let subSectionReviewCount = null;
                          if (input.subSection)
                            subSectionReviewCount = getActiveSubSectionReviewCount(
                              stepIndex,
                              input.subSection
                            );
                          return (
                            <>
                              {input.heading && (
                                // <div className="d-flex w-100 mx-0 px-4 py-3 justify-content-end my-4 position-relative"
                                // >

                                <div
                                  className="d-flex justify-content-end  position-relative mb-4 t-17 w-100 mx-4"
                                  style={{
                                    zIndex: '1'
                                  }}>
                                  {(props.state.register.ngoSavedData
                                    .l1_status === 'modification_required' ||
                                    props.state.register.ngoSavedData
                                      .l2_status === 'modification_required') &&
                                    stepIndex !== 6 &&
                                    !stepData.hasSubSection &&
                                    index === 0 && (
                                      <span
                                        className="color-1FA4FA fs-14 cursor-pointer "
                                        onClick={e =>
                                          setModalTitle(stepIndex, e)
                                        }>
                                        {totalReviewsCount > 0
                                          ? totalReviewsCount
                                          : 0}{' '}
                                        Review(s){' '}
                                        {reviewCount > 0 && (
                                          <span
                                            className={`d-inline-block ${
                                              resolvedSectionCmt
                                                ? 'green-dot'
                                                : 'alert-dot'
                                            } ml-3 mb-1`}></span>
                                        )}
                                      </span>
                                    )}

                                  {input.subSection &&
                                    // input.heading !==
                                    //   'FCRA Bank Account Details' &&
                                    (props.state.register.ngoSavedData
                                      .l1_status === 'apporved' ||
                                      props.state.register.ngoSavedData
                                        .l1_status ===
                                        'modification_required' ||
                                      props.state.register.ngoSavedData
                                        .l2_status ===
                                        'modification_required') && (
                                      <>
                                        <span
                                          className="color-1FA4FA fs-14  cursor-pointer d-flex justify-content-end "
                                          onClick={e =>
                                            setModalTitle(
                                              stepIndex,
                                              e,
                                              input.subSection,
                                              index
                                            )
                                          }>
                                          {subSectionReviewCount > 0
                                            ? subSectionReviewCount
                                            : 0}{' '}
                                          Review(s) {input.step}
                                          {subSectionReviewCount > 0 &&
                                            modificationRequired && (
                                              <span
                                                className={`d-inline-block ${
                                                  resolvedSubSectionCmt
                                                    ? 'green-dot'
                                                    : 'alert-dot'
                                                }  ml-3 mb-1`}></span>
                                            )}
                                        </span>
                                      </>
                                    )}
                                  {input.subSection === 4 &&
                                    input.heading ===
                                      'FCRA Bank Account Details' &&
                                    isFcraCertificateFalse &&
                                    (props.state.register.ngoSavedData
                                      .l1_status === 'apporved' ||
                                      props.state.register.ngoSavedData
                                        .l1_status ===
                                        'modification_required') && (
                                      <>
                                        <span
                                          // className="color-1FA4FA fs-14 cursor-pointer  justify-content-end d-flex w-100 mx-0 px-4 py-3 justify-content-end my-4 position-relative "
                                          className="color-1FA4FA fs-14  cursor-pointer d-flex justify-content-end "
                                          onClick={e =>
                                            setModalTitle(
                                              stepIndex,
                                              e,
                                              input.subSection,
                                              index
                                            )
                                          }>
                                          {subSectionReviewCount > 0
                                            ? subSectionReviewCount
                                            : 0}{' '}
                                          Review(s){' '}
                                          {subSectionReviewCount > 0 &&
                                            modificationRequired && (
                                              <span
                                                className={`d-inline-block ${
                                                  resolvedSubSectionCmt
                                                    ? 'green-dot'
                                                    : 'alert-dot'
                                                }  ml-3 mb-1`}></span>
                                            )}
                                        </span>
                                      </>
                                    )}
                                </div>
                              )}
                              {index > 0 && input.newLine && (
                                <div
                                  className={`form-group col-sm-12 pl-4 pr-4 mb-0`}></div>
                              )}
                              {input.inputtype !== 'board members' &&
                                input.inputtype !== 'auditors' &&
                                input.inputtype !== 'top donors' &&
                                input.inputtype !== 'funds mobilized' &&
                                input.inputtype !==
                                  'areaOfOperationMultiselect' &&
                                input.inputtype !== 'newFinancefields' && (
                                  <ProfileSingleField
                                    input={input}
                                    key={`${stepIndex}-${index}`}
                                    stepIndex={stepIndex}
                                    disabled={disabled}
                                    state={props.state}
                                    fieldUpdated={fieldUpdated}
                                    locationUpdated={locationUpdated}
                                    setFCRATrue={setFCRATrue}
                                    setFCRAFalse={setFCRAFalse}
                                  />
                                )}

                              {input.inputtype === 'newFinancefields' &&
                                renderFinancialData(stepIndex, index)}

                              {input.inputtype ===
                                'areaOfOperationMultiselect' && (
                                <>
                                  <MultiSelect
                                    input={input}
                                    key={`${stepIndex}-${index}`}
                                    stepIndex={stepIndex}
                                    disabled={disabled}
                                    areaOptions={
                                      input.jsonfield === 'area_state'
                                        ? props.state.common?.listStates
                                        : input.jsonfield === 'area_districts'
                                        ? props.state.common?.listDistricts
                                        : props.state.common?.masterInfo?.policies?.map(
                                            item => {
                                              return {
                                                id: item.id,
                                                name: item.policy,
                                                help_text: item?.help_text || ''
                                              };
                                            }
                                          )
                                    }
                                    selectedData={
                                      input.jsonfield === 'area_state'
                                        ? selectedState
                                        : input.jsonfield === 'area_districts'
                                        ? selectedDist
                                        : input.jsonfield === 'policy_list'
                                        ? selectedPoliciesItem
                                        : []
                                    }
                                    fieldUpdated={fieldUpdated}
                                    locationUpdated={locationUpdatedForArea}
                                    isError={
                                      input.jsonfield === 'area_state'
                                        ? stateError
                                        : input.jsonfield === 'area_districts'
                                        ? distError
                                        : input.jsonfield === 'policy_list'
                                        ? polError
                                        : false
                                    }
                                    missingState={missState}
                                  />

                                  {renderPolicies(
                                    input.jsonfield,
                                    stepIndex,
                                    index
                                  )}
                                </>
                              )}

                              {input.inputtype === 'board members' && (
                                <>
                                  {members.map((memberObj, memberIndex) => {
                                    return memberObj.member.map(
                                      (memberInput, index) => {
                                        return (
                                          <>
                                            {index === 0 && (
                                              <div
                                                className={`w-100 d-flex justify-content-between mb-3 px-4 ${
                                                  memberIndex === 0
                                                    ? ' '
                                                    : 'mt-4'
                                                }`}>
                                                <span className="fs-14">
                                                  Member/Trustee/Director #
                                                  {memberIndex + 1}
                                                </span>
                                                <i
                                                  className={`fas fa-trash ml-0 cursor-pointer ${
                                                    memberIndex === 0
                                                      ? 'd-none'
                                                      : ' '
                                                  }`}
                                                  onClick={() =>
                                                    deleteMember(memberIndex)
                                                  }></i>
                                              </div>
                                            )}
                                            {index > 0 &&
                                              memberInput.newLine && (
                                                <div
                                                  className={`form-group col-sm-12 pl-4 pr-4`}></div>
                                              )}
                                            <ProfileSingleField
                                              input={memberInput}
                                              key={`${stepIndex}-${memberIndex}-${index}`}
                                              stepIndex={stepIndex}
                                              disabled={disabled}
                                              state={props.state}
                                              fieldUpdated={fieldUpdated}
                                              dynamicType={'member'}
                                            />
                                          </>
                                        );
                                      }
                                    );
                                  })}
                                  {members.length >= 10 ? null : (
                                    <div className="w-100 text-center mt-2 mb-5">
                                      <button
                                        type="button"
                                        className="form-control btn  save-button btn-sm  btn-addmore"
                                        disabled={
                                          props.state.register.ngoSavedData
                                            .l1_status === 'submitted'
                                            ? true
                                            : disabled
                                        }
                                        onClick={() => addMember()}>
                                        Add More
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                              {input.inputtype === 'auditors' && (
                                <>
                                  {auditors.map((auditor, auditorIndex) => {
                                    return auditor.auditor.map(
                                      (auditorField, audIndex) => {
                                        return (
                                          <>
                                            {audIndex === 0 && (
                                              <div
                                                className={`w-100 d-flex justify-content-between mb-3 px-4 ${
                                                  auditorIndex === 0
                                                    ? ''
                                                    : 'mt-4'
                                                }`}>
                                                <span className="fs-14">
                                                  Current Auditor #
                                                  {auditorIndex + 1}
                                                </span>
                                                <i
                                                  className={`fas fa-trash mr-2 cursor-pointer ${
                                                    auditorIndex === 0
                                                      ? 'd-none'
                                                      : ' '
                                                  }`}
                                                  onClick={() =>
                                                    deleteAuditor(auditorIndex)
                                                  }></i>
                                              </div>
                                            )}
                                            <ProfileSingleField
                                              input={auditorField}
                                              key={`${stepIndex}-${audIndex}`}
                                              stepIndex={stepIndex}
                                              disabled={disabled}
                                              state={props.state}
                                              fieldUpdated={fieldUpdated}
                                              dynamicType={'auditor'}
                                            />
                                          </>
                                        );
                                      }
                                    );
                                  })}
                                  {auditors.length >= 5 ? null : (
                                    <div className="w-100 text-center mt-2 mb-5">
                                      <button
                                        type="button"
                                        className="form-control btn  save-button btn-sm btn-addmore"
                                        disabled={
                                          props.state.register.ngoSavedData
                                            .l1_status === 'submitted'
                                            ? true
                                            : disabled
                                        }
                                        onClick={() => addAuditor()}>
                                        Add More
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                              {(input.inputtype === 'top donors' ||
                                input.inputtype === 'funds mobilized') && (
                                <>
                                  {input.inputtype === 'top donors' &&
                                    topDonors.map((donor, donorIndex) => {
                                      return (
                                        <>
                                          <ProfileSingleField
                                            input={donor}
                                            key={`${stepIndex}-${donorIndex}`}
                                            stepIndex={stepIndex}
                                            disabled={disabled}
                                            state={props.state}
                                            fieldUpdated={fieldUpdated}
                                            dynamicType={'topDonor'}
                                          />
                                        </>
                                      );
                                    })}
                                  {input.inputtype === 'funds mobilized' &&
                                    fundsMobilized.map((funds, fundsIndex) => {
                                      return (
                                        <>
                                          <ProfileSingleField
                                            input={funds}
                                            key={`${stepIndex}-${fundsIndex}`}
                                            stepIndex={stepIndex}
                                            disabled={disabled}
                                            state={props.state}
                                            fieldUpdated={fieldUpdated}
                                            dynamicType={'fundsMobilized'}
                                          />
                                        </>
                                      );
                                    })}

                                  {(props.state.register.ngoSavedData
                                    ?.funds_mobilised_arr?.length > 2 &&
                                    currentYear - 1 !==
                                      props.state.register.ngoSavedData
                                        ?.funds_mobilised_arr[0]?.year &&
                                    input.inputtype === 'funds mobilized') ||
                                  (props.state.register.ngoSavedData
                                    ?.funds_mobilised_arr?.length > 3 &&
                                    currentYear - 1 ===
                                      props.state.register.ngoSavedData
                                        ?.funds_mobilised_arr[0]?.year &&
                                    input.inputtype === 'funds mobilized') ? (
                                    <div
                                      className="mt-3rm ml-4 text-007bff cursor-pointer"
                                      onClick={() => showFundMobilisedModal()}>
                                      View funds mobilisation history
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}

                        <div className="form-group col-md-12 d-flex justify-content-center pt-4 pb-4 mb-0">
                          {!isImpersonation && (
                            <button
                              type="submit"
                              className="form-control btn btn-primary save-button btn-sm"
                              disabled={
                                stepStatuses[stepIndex] ||
                                !stepEditStatuses[stepIndex] ||
                                !(
                                  props.state.register.ngoSavedData
                                    .l1_status === 'new' ||
                                  props.state.register.ngoSavedData
                                    .l1_status === 'modification_required' ||
                                  props.state.register.ngoSavedData
                                    .l2_status === 'modification_required'
                                ) ||
                                disableSaveBtn
                              }>
                              Save
                            </button>
                          )}
                          {isImpersonation && (
                            <button
                              type="submit"
                              className="form-control btn btn-primary save-button btn-sm">
                              Save
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              );
            })}
          </div>
          <a
            href="#top"
            aria-label="back to top"
            title="back to top"
            id="back-to-top">
            {/* <i
              
              className="fa fa-2x fa-long-arrow-alt-up"
              style={{ display: visible ? 'inline' : 'none' }}
              aria-hidden="true"></i> */}
            <i
              onClick={scrollToTop}
              style={{
                display: visible ? 'inline' : 'none',
                height: '35px'
              }}
              className="fa fa-2x fa-arrow-up"></i>
          </a>
        </div>
        <FundMobilisationPastData
          showModal={fundMobilisationPastData}
          setShowModal={setFundMobilisationPastData}
          fundsMobilized={
            props.state.register.ngoSavedData?.funds_mobilised_arr
          }
        />
        <ProfileSidebar
          state={props.state}
          getNgoProfile={props.getNgoProfile}
          onStepCompletion={onStepCompletion}
          disableSaveButton={setDisabled}
          allNgoComments={allNgoComments}
          resolveCmtsArr={resolveCmtsArr}
          stepEditStatuses={stepEditStatuses}
          modificationRequired={modificationRequired}
        />
      </div>
      <ModalSide
        showModal={showSideModal}
        handleCloseModal={showHideSideModal}
        className={'side-modal-sm'}
        title={sideModalTitle}>
        <Comments
          sectionId={sectionId}
          subSectionId={subSectionId}
          allNgoComments={allNgoComments}
          sub={props.state.ngo.ngoProfile.ngo_id}
          setallNgoComments={setallNgoComments}
          getSavedNGOData={props.getSavedNGOData}
          postCommentsById={props.postCommentsById}
          getCommentsByNgoId={props.getCommentsByNgoId}
          ngoId={props.state.register.ngoSavedData.id}></Comments>
      </ModalSide>
    </div>
  );
}

//build trigger
