import { Action } from '../constants';
const initialState = {
  accountTypes: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.TYPE_OF_ACCOUNT_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: true,
        accountTypes: action.payload
      };
    default:
      return state;
  }
};
