import React from 'react';
import Modal from '../newDesign/Modals/Modal';
import thankyouIcon from '../../assets/images/thankyou.png';
import successCheckIcon from '../../assets/icons/successCheckIcon.png';
import underReviewIcon from '../../assets/icons/underReviewIcon.svg';
import rejectedIcon from '../../assets/icons/Rejected.svg';
import { convertToDayMonthYearFormat } from '../component/dateFormat';
import loudspeaker from '../../assets/icons/loudspeaker.png';
import { logout } from '../../helpers/keycloak';
class PopupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  redirectUrl(e) {
    window.location.href = 'https://www.mettasocial.com/';
  }
  preRegRedirectUrl(e) {
    window.location.href = 'ngo/preRegRedirect';
  }
  render() {
    return (
      <>
        {/* Submit Campaign 1 : Modal-1 */}
        <Modal
          show={this.props.filledSubmitPopup}
          title={'Submit Project?'}
          handleCloseModal={() =>
            this.props.closePopupCampaignSubmission('filled')
          }
          sizeClass="modal-md">
          <p className="mt-2 mb-5 fs-16 font-weight-normal">
            Are you sure you want to submit{' '}
            <span className="font-weight-bold">
              {' '}
              {this.props.campaignName}{' '}
            </span>{' '}
            Project?
          </p>
          <div className="modal-footer " style={{ borderTop: 'none' }}>
            <button
              className="btn h-40 w-95 text-primary p-1 fs-14"
              onClick={() => {
                this.props.closePopupCampaignSubmission('filled');
              }}>
              Cancel
            </button>
            <button
              className="fs-14 btn btn-primary btn-lg fs-14 dm-bold ml-3"
              // onClick={() => {
              //   this.props.history.push('/ngo/campaigns');
              // }}
              onClick={() =>
                this.props.onSubmit(this.props.currentCampaignStatus)
              }>
              Submit
            </button>
          </div>
        </Modal>

        {/* Submit Campaign 2 : Modal-2 */}
        <Modal
          show={this.props.unFilledSubmitPopup}
          title={'Submit Project?'}
          handleCloseModal={() =>
            this.props.closePopupCampaignSubmission('unfilled')
          }
          sizeClass="modal-md">
          <p className="mt-2 mb-5 fs-16" style={{ color: '#666565' }}>
            You are about to submit your Ptoject{' '}
            <span className="font-weight-bold">
              {' '}
              {this.props.campaignName}{' '}
            </span>{' '}
            without filling up the project proposal. Are you sure you want to
            submit Project ?
          </p>
          <div className="modal-footer " style={{ borderTop: 'none' }}>
            <button
              className="btn h-40 w-95 text-primary p-1 fs-14"
              onClick={() => {
                this.props.closePopupCampaignSubmission('unfilled');
              }}>
              Cancel
            </button>
            <button
              className="btn btn-primary btn-lg fs-14 dm-bold ml-3"
              onClick={() =>
                this.props.onSubmit(this.props.currentCampaignStatus)
              }>
              Submit
            </button>
          </div>
        </Modal>

        {/* Campaign Succefully Submitted : Modal-3 */}
        <Modal
          show={this.props.submitted}
          title={'Project Submitted Successfully'}
          handleCloseModal={() => this.props.closeSuccessModal()}
          sizeClass={'modal-md'}>
          <div>
            <span className="fs-16 " style={{ color: '#666565' }}>
              Your Project <span className="font-weight-bold text-dark"> </span>{' '}
              has been submitted successfully and will be reviewed by our team.
            </span>
            <div className="d-flex justify-content-center">
              <span>
                <img
                  className="my-4"
                  src={thankyouIcon}
                  width={115}
                  height={129}
                  alt="corporate"></img>
              </span>
            </div>
            <span className="fs-16" style={{ color: '#666565' }}>
              You can create more campaigns to reach create impact at scale.
            </span>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-4">
            <span
              className="color-1FA4FA fs-16 p-3 mr-5 cursor-pointer"
              // onClick={() => this.handleCloseModal()}
              onClick={() => this.props.closeSuccessModal()}>
              Close
            </span>
          </div>
        </Modal>

        {/* Cancel Porposal : Modal-4 */}
        <Modal
          show={this.props.showCancelPopup}
          title={
            this.props.tabName === 'ConceptNoteDetails'
              ? 'Cancel Create Project?'
              : 'Cancel Proposal?'
          }
          handleCloseModal={() => this.props.handleCancelPopup(false)}
          sizeClass="modal-md">
          <p className="mt-2 mb-5 fs-16" style={{ color: '#666565' }}>
            All of the data filled in this section will be lost if not saved.{' '}
            <br /> Please save the data before closing.
            <p>
              {this.props.tabName === 'ConceptNoteDetails'
                ? 'Are you sure you want to cancel?'
                : 'Are you sure you want to cancel the filled proposal details?'}
            </p>
          </p>
          <div className="modal-footer " style={{ borderTop: 'none' }}>
            <button
              className="btn h-40 w-95 text-primary p-1 fs-14 mr-4"
              onClick={() => {
                this.props.handleCancelPopup(false);
              }}>
              {this.props.tabName === 'ConceptNoteDetails' ? 'Close' : 'Cancel'}
            </button>
            <button
              className="btn btn-primary btn-lg fs-14 dm-bold ml-3"
              onClick={() => this.props.removeUnsavedData()}>
              {'Confirm'}
            </button>
          </div>
        </Modal>
        {/********************************Pre-Registration Popups**************************************/}
        {/* Leave Page popup : Modal-5 */}
        <Modal
          show={this.props.preRegCancel}
          title={''}
          hideCloseX={true}
          handleCloseModal={() => this.props.handlePreRegCancelPopup(false)}
          sizeClass={'modal-md'}>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <span>
                <img
                  className="mt-3"
                  src={loudspeaker}
                  width={50}
                  // height={129}
                  alt="corporate"></img>
              </span>
            </div>
            <div className="fs-20 dm-medium text-212529 mt-4">Wait!</div>
            <div className="fs-16 dm-medium mt-4" style={{ color: '#81bc30' }}>
              You are too close to start impacting millions of lives.
            </div>
            <div className="dm-regular fs-14 text-212529 mt-1">
              Are you sure you want leave pre-registration without submitting?
            </div>
          </div>
          <div className="text-center mt-3rm">
            <button
              className={`btn btn-light btn-lg w-auto ht-36 fs-14 dm-medium ml-2`}
              // onClick={() => this.props.handlePreRegCancelPopup(false)}
              onClick={e => this.redirectUrl(e)}>
              Leave Page
            </button>
            <button
              className={`btn btn-primary btn-lg w-auto ht-36 fs-14 dm-medium ml-2`}
              onClick={() => this.props.handlePreRegCancelPopup(false)}>
              Continue Editing
            </button>
          </div>
        </Modal>

        {/* Leave Page popup : Modal-5 */}
        <Modal
          show={this.props.preRegSubmit}
          title={''}
          hideCloseX={true}
          handleCloseModal={() => this.props.handlePreRegSubmitPopup(false)}
          sizeClass={'modal-md'}>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <span>
                <img
                  className="mt-3"
                  src={successCheckIcon}
                  width={100}
                  // height={129}
                  alt="corporate"></img>
              </span>
            </div>
            <div className="fs-18 dm-medium text-212529 mt-4">
              Pre-Registration Submitted
            </div>
            <div className="dm-regular fs-14 text-212529 mt-1">
              Thank you for submitting your interest in Metta Social. Our team
              will review your details and get back to you in 3 days.
            </div>
          </div>
          <div className="text-center mt-3rm">
            <button
              className={`btn btn-primary btn-lg w-auto ht-36 fs-14 dm-medium ml-2`}
              // onClick={() => this.props.handlePreRegSubmitPopup(false)}
              onClick={e => this.preRegRedirectUrl(e)}>
              Great!
            </button>
          </div>
        </Modal>

        {/* Under Review popup : Modal-5 */}
        <Modal
          show={this.props.preRegUnderReview}
          title={''}
          fixedPopup={true}
          hideCloseX={true}
          // handleCloseModal={() => this.props.handlePreRegSubmitPopup(false)}
          sizeClass={'modal-md'}>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <span>
                <img
                  className="mt-3"
                  src={underReviewIcon}
                  width={115}
                  height={129}
                  alt="corporate"></img>
              </span>
            </div>
            <div className="dm-medium fs-18 text-212529 mt-4">
              Your Profile is under review
            </div>
            <div className="dm-regular fs-14 text-212529 mt-4">
              Your pre-registration profile is under review. Generally it will
              take us 3-4 working days to review a profile. You will get an
              email notification once the review is complete.
            </div>
          </div>
          <div className="text-center mt-3rm">
            <button
              className={`btn btn-primary btn-lg w-auto ht-36 fs-14 dm-medium ml-2`}
              onClick={e => this.preRegRedirectUrl(e)}>
              Close
            </button>
          </div>
        </Modal>

        {/* Under Review popup : Modal-5 */}
        <Modal
          show={this.props.preRegReject}
          title={''}
          fixedPopup={true}
          hideCloseX={true}
          // handleCloseModal={() => this.props.handlePreRegSubmitPopup(false)}
          sizeClass={'modal-md'}>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <span>
                <img
                  className="mt-3"
                  src={rejectedIcon}
                  width={115}
                  height={129}
                  alt="corporate"></img>
              </span>
            </div>
            <div className="dm-medium fs-18 text-212529 mt-4">
              Your Profile is Rejected
            </div>
            <div className="dm-regular fs-14 text-212529 mt-4">
              {`Your profile was not in compliant with the terms of Metta Social. But don't worry, you can retry logging in and submit your updated documents after `}
              <b>{convertToDayMonthYearFormat(this.props.ngoRejectedDate)}</b>
            </div>
          </div>
          <div className="text-center mt-3rm">
            <button
              className={`btn btn-primary btn-lg w-auto ht-36 fs-14 dm-medium ml-2`}
              onClick={logout}>
              OK
            </button>
          </div>
        </Modal>

        <Modal
          show={this.props.completePreRegForm}
          title={''}
          fixedPopup={true}
          hideCloseX={true}
          // handleCloseModal={() => this.props.handlePreRegSubmitPopup(false)}
          sizeClass={'modal-md'}>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <span>
                <img
                  className="mt-3"
                  src={rejectedIcon}
                  width={115}
                  height={129}
                  alt="corporate"></img>
              </span>
            </div>
            <div className="dm-medium fs-18 text-212529 mt-4 mb-4">
              Please submit your pre-registration form to proceed further with
              the NGO registration process.
            </div>
          </div>

          <div className="text-center mt-3rm mb-4">
            <button
              className={`btn btn-primary btn-lg w-auto ht-36 fs-14 dm-medium ml-2`}
              onClick={e => this.preRegRedirectUrl(e)}>
              Go to Pre-Registration Form
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default PopupModal;
