import React, { useState } from 'react';
import OTPInput from '../components/otpScreen';
import Modal from './newDesign/Modals/Modal';
import { toast } from 'react-hot-toast';
import { sendMail } from '../helpers/emailSender';

export const OtpModal = ({
  selectedEmail,
  selectedName,
  time,
  otp,
  timerEnd, // resend timer
  setShowOtpModal,
  superAdminEmail,
  updateUser,
  onCancel,
  setDataToChild,
  generateOTP,
  isResponse,
  isApiCall,
  isRunning
}) => {
  const [clearInput, setClearInput] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [inputOTP, setInputOTP] = useState('');
  const [showTransferModalSuccess, setShowTransferModalSuccess] = useState(
    false
  );
  const disabledButton = () => {
    if (
      otpError === 'Invalid OTP' ||
      otpError === `OTP can't be blank` ||
      otpError === 'OTP must contain 4 digits' ||
      otpError === 'OTP must contain only numbers'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleTransferParent = () => {
    setShowTransferModalSuccess(true);
    setDataToChild();
  };

  const handleVerify = () => {
    if (otp === inputOTP) {
      // validateOTP();
      toast.success('OTP has been verified');
      if (otpError === '') {
        updateUser();
        handleTransferParent();
      }
    } else {
      validateOTP();
    }
    setClearInput(false);
  };

  const validateOTP = () => {
    let error = '';
    // let otp = '';
    // for (let index = 0; index < inputOTP.length; index++) {
    //   let input = inputOTP[index];
    //   otp += input;
    // }
    let pattern = /^[0-9]+$/;
    if (inputOTP.length === 0) {
      error = "OTP can't be blank";
    } else if (inputOTP.length !== 4) {
      error = 'OTP must contain 4 digits';
    } else if (!pattern.test(inputOTP)) {
      error = 'OTP must contain only numbers';
    } else {
      error = 'Invalid OTP';
    }

    setOtpError(error);
    // if (error === '') {
    //   return false;
    // } else {
    //   return true;
    // }
  };

  const handleResendotp = async () => {
    setInputOTP('');
    setClearInput(true);
    setOtpError('');
    const newOTP = await generateOTP();
    sendMail({
      templateName: 'auditTrailOTPNewDesign',
      sendTo: 'rohan.tekade@mettasocial.com',
      subject: 'Verify OTP for Role Transfer',
      templateData: {
        otp: newOTP
      }
    });
  };

  const getResendotp = () => {
    return (
      <p className="text-primary cursor-pointer" onClick={handleResendotp}>
        Resend OTP{' '}
      </p>
    );
  };
  // function maskEmail(email) {
  //   if (email) {
  //     const parts = email?.split('@');
  //     let maskedUsername = '';

  //     if (parts[0].length <= 2) {
  //       // If the username has 2 or fewer characters, keep the first character and add asterisks
  //       maskedUsername = `${parts[0].charAt(0)}${'*'.repeat(
  //         parts[0].length - 1
  //       )}`;
  //     } else {
  //       // If the username has more than 2 characters, keep the first and last character, mask the rest
  //       maskedUsername = `${parts[0].charAt(0)}${'*'.repeat(
  //         parts[0].length - 2
  //       )}${parts[0].charAt(parts[0].length - 1)}`;
  //     }

  //     const maskedEmail = `${maskedUsername}@${parts[1]}`;
  //     return maskedEmail;
  //   }
  // }

  const handleCloseOTPModal = () => {
    onCancel();
    setInputOTP('');
    setClearInput(true);
    setOtpError('');
    setShowOtpModal(false);
  };

  const handleCloseTransferModalSuccess = () => {
    setShowTransferModalSuccess(false);
    window.location.reload();
  };

  return (
    <div className="my-4">
      <p className="text-1C1C1C text-wrap pledge_modal_mission_title my-2 my-3 fs-14 mx-3">
        You are about to transfer ownership to following user
      </p>
      <div className="border border-primary bg-F0F8FF my-2 fs-14 mx-3">
        <p className="fw-500 m-3">{selectedName}</p>
        <p className="m-3">{selectedEmail}</p>
      </div>
      <div className="d-flex flex-column justify-content-center">
        <p className={'text-center mt-3 fs-14'}>Enter OTP received on</p>
        <p className="text-center fw-500 fs-14">{superAdminEmail}</p>
        <div className="d-flex justify-content-center mb-3">
          <OTPInput
            length={4}
            autoFocus
            onChangeOTP={inpOtp => {
              if (otp.length < 5) {
                setInputOTP(inpOtp);
              }
              if (otp.length <= 4) {
                setOtpError('');
              }
            }}
            clearInput={clearInput}
          />
        </div>
        {otpError !== '' && (
          <label className="fs-12 text-danger text-center mb-2">
            {otpError}
          </label>
        )}
      </div>

      {isRunning === true ? (
        <p className="text-center mt-3">
          Time remaining
          <span className="text-danger ml-2 ">
            {Math.floor(time / 60)}:{(time % 60).toString().padStart(2, '0')}
          </span>
        </p>
      ) : (
        <div className="text-center">{getResendotp()}</div>
      )}
      <div className="d-flex justify-content-center mb-3 mt-3">
        <div
          className="col-2"
          data-bs-dismiss="modal"
          data-bs-target="#cancelModal"
          data-bs-toggle="modal">
          <button
            type="button"
            className="btn btn-light px-3 py-2"
            onClick={handleCloseOTPModal}>
            Cancel
          </button>
        </div>
        {otp ? (
          <>
            <div className="col-4">
              <button
                type="button"
                className="btn btn-primary px-2 py-2 w-100"
                onClick={handleVerify}
                disabled={disabledButton() || isApiCall}>
                {isApiCall ? 'Verifying...' : 'Verify and Transfer'}
              </button>
            </div>
          </>
        ) : null}
      </div>
      {isResponse && (
        <Modal
          show={showTransferModalSuccess}
          title="Role Transfer Successful..!"
          handleCloseModal={handleCloseTransferModalSuccess}
          sizeClass={'modal-md'}>
          <div className="my-4">
            <p className="text-1C1C1C text-wrap pledge_modal_mission_title my-2 my-md-3 fs-12 mx-3 mx-md-4">
              You have successfully transferred Admin Ownership to the following
              user
            </p>
            <div className="border border-primary bg-F0F8FF my-2 fs-14 mx-3 mx-md-4">
              <p className="fw-500 m-3">{selectedName}</p>
              <p className="fw-500 m-3">{selectedEmail}</p>
            </div>
            <p className="text-1C1C1C text-wrap pledge_modal_mission_title my-2 my-md-3 fs-12 mx-3 mx-md-4">
              You will be redirected to role management and assigned an Admin
              role.
            </p>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light px-4 mr-3"
                onClick={handleCloseTransferModalSuccess}>
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
