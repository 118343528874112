import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PageContentWrapper from '../components/pageContentWrapper';
import PageContent from '../components/pageContent';
import Header from '../components/header';
import { Link } from 'react-router-dom';
import CreateMission from '../components/mission/create';
import MissionsList from '../components/mission/list';
import MissionDetails from '../components/mission/details';
import MissionPledgeSummary from '../components/mission/pledgeSummary';
import MissionStats from '../components/mission/stats';
import MissionEdit from '../components/mission/edit';
import { Common } from '../constants';
import '../styles/mission.css';
import { Routes } from '../constants/path.constants';
// import { isAuthorized } from '../helpers/auth-roles';

class MissionScreen extends React.Component {
  getMissionMainScreenByPathName = ({
    id,
    isCreateMission,
    isModifyMission,
    permissions
  }) => {
    const pathname = this.props.location.pathname;
    if (
      pathname.includes(Routes.NGO.CREATE_MISSION) ||
      pathname.includes(Routes.NGO.MODIFY_MISSION)
    ) {
      return (
        <CreateMission
          id={id}
          history={this.props.history}
          isCreateMission={isCreateMission}
          isModifyMission={isModifyMission}
          isNgoApproved={this.props.isNgoApproved}
          permissions={permissions}
          userInfo={this.props.userInfo}
        />
      );
    }
    if (pathname.includes(Routes.NGO.MISSION_DETAILS)) {
      return (
        <MissionDetails
          id={id}
          history={this.props.history}
          permissions={permissions}
          MissionDetails={this.props.MissionDetails}
        />
      );
    }
    if (pathname.includes(Routes.NGO.MISSION_LIST)) {
      return (
        <MissionsList history={this.props.history} permissions={permissions} />
      );
    }
    if (pathname.includes(Routes.NGO.MISSION_PLEDGE_SUMMARY)) {
      return (
        <MissionPledgeSummary
          id={id}
          history={this.props.history}
          permissions={permissions}
        />
      );
    }
    if (pathname.includes(Routes.NGO.MISSION_STATS)) {
      return (
        <MissionStats
          id={id}
          history={this.props.history}
          permissions={permissions}
        />
      );
    }
    if (pathname.includes(Routes.NGO.MISSION_EDIT)) {
      return (
        <MissionEdit
          id={id}
          history={this.props.history}
          permissions={permissions}
        />
      );
    }
  };

  getScreenTitle = id => {
    const pathname = this.props.location.pathname;
    if (pathname.includes(Routes.NGO.CREATE_MISSION)) {
      if (!id) {
        return Common.NGO.PAGE_TITLE.CREATE_MISSION;
      } else {
        return Common.NGO.PAGE_TITLE;
      }
    } else if (pathname.includes(Routes.NGO.MISSION_DETAILS)) {
      return Common.NGO.PAGE_TITLE.MISSION_DETAILS;
    } else if (pathname.includes(Routes.NGO.MISSION_PLEDGE_SUMMARY)) {
      return Common.NGO.PAGE_TITLE.MISSION_PLEDGE_SUMMARY;
    } else if (pathname.includes(Routes.NGO.MISSION_STATS)) {
      return Common.NGO.PAGE_TITLE.MISSION_STATS;
    } else if (pathname.includes(Routes.NGO.MISSION_EDIT)) {
      return Common.NGO.PAGE_TITLE.MISSION_EDIT;
    } else if (pathname.includes(Routes.NGO.MISSION_LIST)) {
      return 'All Missions';
    }
  };

  enableCreate = () => {
    const policies = JSON.parse(window.localStorage.getItem('userPolicies'));
    if (policies) {
      const financePolicies = policies.filter(x =>
        x.role.toLowerCase().includes('finance')
      );
      if (
        financePolicies &&
        financePolicies.length > 0 &&
        policies.length === financePolicies.length
      )
        return false;
    }
    return true;
  };

  render() {
    let { id } = this.props.match.params;
    let isCreateMission =
      this.props.location.pathname.indexOf('/ngo/mission/create') > -1 && !id;
    let isModifyMission =
      this.props.location.pathname.indexOf('/ngo/mission/modify') > -1 && !!id;

    let missionList = false;
    if (window.location.href.includes('/ngo/missions/list')) {
      missionList = true;
    }
    const isImpersonator = window.location.href.includes('email=');
    const permissions = this.props.permissions;
    return (
      <React.Fragment>
        <Header pageTitle={this.getScreenTitle(id)}>
          {!isCreateMission &&
            (this.enableCreate() || isImpersonator) &&
            missionList && (
              <li className="nav-item active">
                <Link
                  // to={`${process.env.REACT_APP_NEW_CREATE_PROJECT}`}
                  to="/ngo/mission/create"
                  className="btn btn-primary btn-lg mr-3"
                  exact="true">
                  <i className="fas fa-plus-square fa-1x mr-2 text-light"></i>
                  Create Mission
                </Link>
              </li>
            )}
        </Header>
        <PageContentWrapper>
          <PageContent
            mainScreen={this.getMissionMainScreenByPathName({
              id,
              isCreateMission,
              isModifyMission,
              permissions
            })}
          />
        </PageContentWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.login.userInfo,
  isNgoApproved: state.ngo.ngoProfile.isProfileApproved
});

export default connect(mapStateToProps, null)(MissionScreen);
