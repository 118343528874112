import { Action, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

export const getCorporateList = () => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');

  instance
    .get(EndPoint.getApiUrl('NGO', 'GET_ALL_CORPORATES', 'HIROKU'))
    .then(response => {
      dispatch({
        type: Action.CORPORATE_LIST_SUCCESS,
        payload: response.data.result
      });
    });
};

export const getSearchedCorporateList = (original_list, value) => dispatch => {
  let filteredList = '';
  filteredList = original_list.filter(item => {
    const lc = item.name.toLowerCase();
    const filter = value.toLowerCase();
    return lc.includes(filter);
  });

  dispatch({
    type: Action.SEARCH_LIST_SUCCESS,
    payload: filteredList
  });
};
