import { Action } from '../constants';
export const sidebarStatusChanged = sidebarStatus => ({
  type: Action.SET_SIDEBAR_STATUS,
  sidebarStatus
});
export const setSidebarStatus = sidebarStatus => dispatch => {
  return new Promise(res => {
    dispatch(sidebarStatusChanged(sidebarStatus));
    res();
  });
};
export const setSidebarStatus_With_NewCurrentPagePath = (
  sidebarStatus,
  location
) => dispatch => {
  let newStatus = JSON.parse(JSON.stringify(sidebarStatus));
  newStatus.currentPagePath = location.pathname;
  return new Promise(res => {
    dispatch(sidebarStatusChanged(newStatus));
    res();
  });
};
