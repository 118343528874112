export const reportListMettaVerified = [
  {
    id: 1,
    name: 'Donor Details'
  },
  {
    id: 2,
    name: 'Donor Receipts'
  },
  {
    id: 3,
    name: 'Generate 10BD'
  }
];
export const reportListConditional = [
  {
    id: 1,
    name: 'Donor Details'
  },
  {
    id: 2,
    name: 'Donor Receipts'
  }
];

export const reportDuration = [
  {
    name: 'FY',
    fy_start_month: 'Apr',
    fy_end_month: 'Mar'
  },
  {
    name: 'Q1',
    fy_start_month: 'Apr',
    fy_end_month: 'Jun'
  },
  {
    name: 'Q2',
    fy_start_month: 'Jul',
    fy_end_month: 'Sep'
  },
  {
    name: 'Q3',
    fy_start_month: 'Oct',
    fy_end_month: 'Dec'
  },
  {
    name: 'Q4',
    fy_start_month: 'Jan',
    fy_end_month: 'Mar'
  }
];
