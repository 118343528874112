import { toast } from 'react-hot-toast';
import { Action, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

export const onSurveyStepCompletion = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(EndPoint.getApiUrl('NGO', 'PRE_ONBOARDING', 'HIROKU'), data)
    .then(response => {
      dispatch({
        type: Action.NGO_PREONBOARDING_SAVED_DATA_SUCCESS,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      toast.error(
        'Uh-Oh! Looks like we are having server issues. Please Try Again'
      );
    });
};
export const onSurveyStepOne = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(EndPoint.getApiUrl('NGO', 'SURVEY_STEP_ONE', 'HIROKU'), data)
    .then(response => {
      dispatch({
        type: Action.NGO_PREONBOARDING_SAVED_DATA_SUCCESS,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      toast.error(
        'Uh-Oh! Looks like we are having server issues. Please Try Again'
      );
    });
};

export const savePreRegistrationDetails = (data, ngoId) => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(
      `${EndPoint.getApiUrl(
        'NGO',
        'PRE_REGISTRATION',
        'HIROKU'
      )}?ngo_id=${ngoId}`,
      data
    )
    .then(response => {
      dispatch({
        type: Action.NGO_PREONBOARDING_SAVED_DATA_SUCCESS,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      toast.error(
        'Uh-Oh! Looks like we are having server issues. Please Try Again'
      );
    });
};
export const onSurveyStepTwo = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(EndPoint.getApiUrl('NGO', 'SURVEY_STEP_TWO', 'HIROKU'), data)
    .then(response => {
      dispatch({
        type: Action.NGO_PREONBOARDING_SAVED_DATA_SUCCESS,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      toast.error(
        'Uh-Oh! Looks like we are having server issues. Please Try Again'
      );
    });
};

export const onSurveyStepThree = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(EndPoint.getApiUrl('NGO', 'SURVEY_STEP_THREE', 'HIROKU'), data)
    .then(response => {
      dispatch({
        type: Action.NGO_PREONBOARDING_SAVED_DATA_SUCCESS,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      toast.error(
        'Uh-Oh! Looks like we are having server issues. Please Try Again'
      );
    });
};

export const getSavedPreOnBoardingData = email => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  instance
    .get(
      `${EndPoint.getApiUrl('NGO', 'PRE_ONBOARDING', 'HIROKU')}?email=${email}`
    )
    .then(response => {
      dispatch({
        type: Action.NGO_PREONBOARDING_SAVED_DATA_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => {
      console.error(error.response);
    });
};
