// import AccountDetails from "../components/profile/accountDetails";

const getFormattedValue = value => {
  return value
    .replace(/\s/g, '_')
    .replace(/[?.]/g, '')
    .toLowerCase();
};

const completeWorkplaceGivingRegistrationData = [
  {
    title: 'Account Details',
    wrapperKey: 'existence',
    subTitle: 'Step 1',
    fields: [
      {
        heading: 'NGO Details',
        label: 'Contact Person Full Name',
        inputtype: 'text',
        type: 'text',
        maxLength: 100,
        lengthMin: 3,
        placeholder: 'John Doe',
        validationpattern: /[a-zA-Z\s]+$/,
        validpatternmessage: 'Please enter Alphabets only',
        valuekey: 'login.userInfo.name',
        impersonated: true,
        prefilled: true,
        jsonfield: 'full_name',
        disabled: true
      },
      {
        label: 'NGO Name',
        inputtype: 'text',
        type: 'text',
        validation: 'required',
        maxLength: 100,
        lengthMin: 3,
        placeholder: 'Name of your NGO',
        validationpattern: /[a-zA-Z0-9\s]+$/,
        validpatternmessage: 'Please enter Alphanumeric characters only',
        validationMsg: 'Max 100 characters',
        jsonfield: 'ngo_name'
      },
      {
        label: 'contact Number',
        inputtype: 'text',
        type: 'tel',
        placeholder: 'e.g., 8888448888 (10 Digit Number)',
        lengthMin: 10,
        maxLength: 10,
        valuekey: 'login.userInfo.contact_number',
        impersonated: true,
        prefilled: true,
        jsonfield: 'contact_number',
        disabled: true
      },
      {
        label: 'Email Address',
        inputtype: 'text',
        type: 'email',
        placeholder: 'john.doe@example.com',
        valuekey: 'login.userInfo.email',
        impersonated: true,
        prefilled: true,
        jsonfield: 'email',
        disabled: true
      },
      {
        label: 'NGO Logo',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG',
        inputtype: 'file',
        fileSizeLimit: 8,
        type: 'file',
        // previewDoc: true,
        validation: 'required',
        thumbnail: true,
        extraInfoAboutField:
          'Please ensure that the file type be a png, jpg, or jpeg only.',
        jsonfield: 'ngo_logo'
      },
      {
        label: 'NGO Website URL',
        inputtype: 'text',
        type: 'text',
        validationtype: 'url',
        placeholder: 'https://www.example.com/',
        jsonfield: 'website_url'
      },
      {
        label: 'Registration Address',
        inputtype: 'textarea',
        rows: '4',
        newLine: true,
        type: 'text',
        validation: 'required',
        spanfull: true,
        lengthMin: 30,
        maxLength: 255,
        validationMsg: 'Max 255 characters',
        jsonfield: 'registration_address'
      },
      {
        label: 'pincode',
        inputtype: 'masterTextFields',
        type: 'text',
        // required: true,,
        validation: 'required',
        placeholder: 'e.g., 754321 (6 Digit Number)',
        minValue: 100000,
        maxValue: 999999,
        maxLength: 6,
        // minLength: 6,
        lengthMin: 6,
        masterfield: 'pincode',
        validationMsg: 'Incorrect Pin code',
        jsonfield: 'pincode'
      },
      {
        label: 'State',
        inputtype: 'dependentTextField',
        type: 'text',
        placeholder: 'State not found',
        dependenton: 'pincode',
        dependencywrapperkey: 'existence',
        newLine: true,
        jsonfield: 'state'
      },
      {
        label: 'City',
        inputtype: 'dependentTextField',
        type: 'text',
        placeholder: 'City not found',
        validationType: 'website',
        dependenton: 'pincode',
        dependencywrapperkey: 'existence',
        jsonfield: 'city'
      },
      {
        label: 'Communication Address',
        inputtype: 'textarea',
        rows: '4',
        type: 'text',
        required: false,
        spanfull: true,
        lengthMin: 30,
        maxLength: 255,
        validationMsg: 'Max 255 characters',
        jsonfield: 'communication_address'
      },
      /// Areas of Operation
      {
        heading: 'Areas of Operation',
        label: 'State',
        inputtype: 'areaOfOperationMultiselect',
        // required: true,
        validation: 'required',
        validationMsg: 'Please Select and click on Apply for State(s)',
        options: {
          key: 'common.listStates',
          valuekey: 'id',
          displaykey: 'name'
        },
        jsonfield: 'area_state'
      },

      {
        label: 'District',
        inputtype: 'areaOfOperationMultiselect',
        // required: true,
        validationMsg: 'Please Select and click on Apply for District(s)',
        validation: 'required',
        options: {
          key: 'common.listDistricts',
          valuekey: 'id',
          displaykey: 'name'
        },
        jsonfield: 'area_districts'
      },
      /// End Areas of Operation

      // Organograme
      {
        subWrapperKey: 'organorgram',
        separator: true,
        heading: 'Organogram',
        label: `Mission & Vision statement aligned to organisation's principles`,
        inputtype: 'textarea',
        rows: '4',
        newLine: true,
        type: 'text',
        // required: true,
        validation: 'required',
        maxLength: 2000,
        validationMsg: 'Max 2000 characters',
        spanfull: true,
        jsonfield: 'mission'
      },
      {
        label: 'primary Cause Areas',
        inputtype: 'multiselect',
        // required: true,
        validation: 'required',
        options: {
          key: 'common.masterInfo.cause_categories',
          valuekey: 'id',
          displaykey: 'name'
        },
        jsonfield: 'primary_cause_areas'
      },
      {
        label: 'secondary Cause Areas',
        inputtype: 'multiselect',
        // required: true,
        validation: 'required',
        options: {
          key: 'common.secondaryCauses',
          valuekey: 'id',
          displaykey: 'name'
        },
        jsonfield: 'secondary_cause_areas'
      },
      {
        label: 'primary Beneficiaries',
        inputtype: 'multiselect',
        // required: true,
        validation: 'required',
        options: {
          key: 'common.masterInfo.primary_beneficiaries',
          valuekey: 'id',
          displaykey: 'name'
        },
        jsonfield: 'primary_beneficiaries'
      },
      {
        label: 'Sustainable Development Goals (SDG)',
        inputtype: 'multiselect',
        // required: true,
        validation: 'required',
        options: {
          key: 'common.masterInfo.sdg',
          valuekey: 'id',
          displaykey: 'name'
        },
        jsonfield: 'sustainable_development_goals'
      },
      // End Organograme

      {
        label: 'Twitter Page URL',
        inputtype: 'text',
        type: 'text',
        validationtype: 'url',
        prefilledUrl: 'https://www.twitter.com/',
        jsonfield: 'twitter_page_url'
      },
      {
        label: 'Facebook Page URL',
        inputtype: 'text',
        type: 'text',
        validationtype: 'url',
        prefilledUrl: 'https://www.facebook.com/',
        jsonfield: 'facebook_page_url'
      },
      {
        label: 'Linkedin Page URL',
        inputtype: 'text',
        type: 'text',
        validationtype: 'url',
        prefilledUrl: 'https://www.linkedin.com/',
        jsonfield: 'linkedin_page_url'
      },
      {
        label: 'Instagram Page URL',
        inputtype: 'text',
        type: 'text',
        validationtype: 'url',
        prefilledUrl: 'https://www.instagram.com/',
        jsonfield: 'instagram_page_url'
      }
    ]
  },
  {
    title: 'Legal Compliance',
    wrapperKey: 'legal_compliance',
    subTitle: 'Step 2',
    fields: [
      {
        separator: false,
        heading: 'Registration',
        label: 'Legal Structure',
        inputtype: 'multiselect',
        validation: 'required',
        dropdownType: 'single',
        jsonfield: 'legal_structure',

        options: {
          key: 'legalStructureDropdown.legalStructure',
          valuekey: 'id',
          displaykey: 'name'
        },
        masterfield: 'legal_structure'
      },
      {
        label: 'Society Registration Number',
        inputtype: 'text',
        placeholder: '',
        type: 'text',
        title: 'Must have 28 characters',
        showrequired: true,
        dependenton: 'legal_structure',
        dependencyvalue: '1',
        jsonfield: 'society_registration_number',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Society Registration Certificate',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        dependenton: 'legal_structure',
        dependencyvalue: '1',
        jsonfield: 'society_registration_certificate',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Society by laws',
        accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        dependenton: 'legal_structure',
        jsonfield: 'society_by_laws',
        dependencyvalue: '1',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Trust Registration Number',
        inputtype: 'text',
        placeholder: '',
        type: 'text',
        showrequired: true,
        dependenton: 'legal_structure',
        dependencyvalue: '2',
        jsonfield: 'trust_deed_registration_number',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Trust Registration Certificate',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        dependenton: 'legal_structure',
        dependencyvalue: '2',
        jsonfield: 'trust_registration_certificate',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Trust deed',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        dependenton: 'legal_structure',
        dependencyvalue: '2',
        jsonfield: 'trust_deed',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Registration Number',
        inputtype: 'text',
        type: 'text',
        showrequired: true,
        title: 'Must have 28 characters',
        dependenton: 'legal_structure',
        dependencyvalue: '3',
        jsonfield: 'registration_number',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Registration/Incorporation date',
        type: 'date',
        inputtype: 'date',
        dateFormat: 'YYYY-MM-DD hh:mm:ss',
        placeholder: `Select Date`,
        minDate: '1900-01-01',
        maxDate: 'now',
        validation: 'required',
        dependenton: 'legal_structure',
        dependencyvalue: '1,2,3',
        jsonfield: 'date_of_incorp',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Please upload Certificate of Incorporation',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        dependenton: 'legal_structure',
        dependencyvalue: '3',
        jsonfield: 'cert_of_incorporation',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Deed/Memorandum of Association',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'multiplefilesnew',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        validation: 'required',
        extraInfoAboutField:
          'These documents act as the foundation of your organisation and is essential to obtain registration/incorporation, as applicable',
        dependenton: 'legal_structure',
        dependencyvalue: '3',
        jsonfield: 'moa',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Article of Association',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'multiplefilesnew',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        validation: 'required',
        extraInfoAboutField:
          'These documents act as the foundation of your organisation and is essential to obtain registration/incorporation, as applicable',
        dependenton: 'legal_structure',
        dependencyvalue: '3',
        jsonfield: 'article_of_assc',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'Do you have 12A?',
        inputtype: 'radio',
        placeholder: '',
        masterfield: 'is_12a',
        newLine: true,
        jsonfield: 'is_12a',
        hidden: true
      },
      {
        label: 'Do you have 80g?',
        inputtype: 'radio',
        placeholder: '',
        jsonfield: 'is_80g',
        masterfield: 'is_80g',
        hidden: true
      },
      //not required for workplace giving
      // {
      //   label:
      //     'Do you have any open assessment orders challenging your 12A/12AA/12AB or 80G status',
      //   inputtype: 'radio',
      //   validation: 'required',
      //   placeholder: '',
      //   jsonfield: 'is_open_order_challenge',
      //   newLine: true
      // },
      {
        label: 'Please enter 12A/12AA/12AB Registration Number',
        inputtype: 'text',
        type: 'text',
        showrequired: true,
        placeholder:
          'e.g., ABC(D)PLC/12A/123/ABCDE1234F/IJK(E)-0/Vol 20XX-20XI',
        newLine: true,
        dependenton: 'is_12a',
        jsonfield: 'number_12a',
        dependencywrapperkey: 'legal_compliance'
      },
      {
        label: 'Please upload certificate for 12A/12AA/12AB',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'multiplefilesnew',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        extraInfoAboutField:
          'The 12A certificate allows your organisation to avail tax exemption on the surplus income.',
        dependenton: 'is_12a',
        jsonfield: 'certificate_12a',
        dependencywrapperkey: 'legal_compliance'
      },
      {
        label: 'Please enter 80G Number',
        inputtype: 'text',
        type: 'text',
        showrequired: true,
        placeholder:
          'e.g., ABC(D)PLC/80G/123/ABCDE1234F/IJK(E)-0/Vol 20XX-20XI',
        extraInfoAboutField:
          'The 80G Number granted to your NGO by the Income Tax Department.',
        dependenton: 'is_80g',
        jsonfield: 'number_80g',
        dependencywrapperkey: 'legal_compliance'
      },
      {
        label: 'Certificate 80G',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'multiplefilesnew',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        extraInfoAboutField:
          'The 80G certificate allows the donors to avail tax deduction as a percentage of the amount donated by them.',
        dependenton: 'is_80g',
        jsonfield: 'certificate_80g',
        dependencywrapperkey: 'legal_compliance'
      },
      //not required for workplace giving
      // {
      //   label: '35AC | 35 (1)(ii) | 35 (1)(iii) 35CCA',
      //   accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
      //   inputtype: 'file',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   newLine: true,
      //   thumbnail: true,
      //   jsonfield: 'certificate_35ac',
      //   extraInfoAboutField:
      //     'Section 35AC of the Income-tax Act permits deduction of whole of the amount of donation to a trust or fund or institution approved by the National Committee for carrying out any eligible project or scheme.'
      // },
      {
        label: 'Tax deduction/collection account number (TAN)',
        inputtype: 'text',
        type: 'text',
        placeholder: 'e.g., ABCD12345E',
        // minLength: 10,
        lengthMin: 10,
        maxLength: 10,
        validationpattern: /[A-Z]{4}[0-9]{5}[A-Z]{1}$/,
        validpatternformat: 'ABCD12345E',
        jsonfield: 'tan_num',
        // validation: 'required',
        newLine: true
      },
      {
        label: 'Tax Deduction Number document',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        jsonfield: 'tan_document',
        excludeFromStepCompletionCalculation: true
        // showrequired: true
        // newLine: true
      },
      {
        label: 'Income Tax PAN Number',
        inputtype: 'text',
        type: 'text',
        validation: 'required',
        placeholder: 'e.g., ABCD12345E',
        lengthMin: 10,
        maxLength: 10,
        validationpattern: /[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
        validpatternformat: 'ABCDE1234F',
        masterfield: 'pan_num',
        jsonfield: 'pan_num',
        validationMsg: '10 Digits PAN'
      },
      {
        label: 'PAN card',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        jsonfield: 'pan_doc',
        // required: true
        validation: 'required'
      },
      {
        label: 'Are you registered for CSR 1?',
        inputtype: 'radio',
        // required: true,
        validation: 'required',
        placeholder: '',
        newLine: true,
        jsonfield: 'is_csr1_registered',
        masterfield: 'is_csr1_registered'
      },
      {
        label: 'CSR Registration Number',
        type: 'text',
        inputtype: 'text',
        placeholder: `e.g., CSR12345678`,
        // dependencywrapperkey: 'trust_report',
        // validation: 'required',
        validationpattern: /[C]{1}[S]{1}[R]{1}[0-9]{8}$/,
        validpatternformat: 'CSR12345678',
        // title: 'CSR number format example: CSRXXXXXXXX ',
        // maxLength: 11,
        showrequired: true,
        lengthMin: 11,
        minLength: 11,
        dependenton: 'is_csr1_registered',
        jsonfield: 'csr_registration_number',
        dependencywrapperkey: 'legal_compliance',
        excludeFromStepCompletionCalculation: true
      },
      {
        label: 'CSR-1 Registration Certificate',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        dependenton: 'is_csr1_registered',
        jsonfield: 'csr1_registration_certificate',
        excludeFromStepCompletionCalculation: true,
        dependencywrapperkey: 'legal_compliance'
      },
      //not required for workplace giving
      // {
      //   label: 'Are you registered for NGO Darpan?',
      //   inputtype: 'radio',
      //   // required: true,
      //   validation: 'required',
      //   placeholder: '',
      //   newLine: true,
      //   jsonfield: 'is_ngo_darpan_registered',
      //   masterfield: 'is_ngo_darpan_registered'
      // },
      // {
      //   label: 'NGO Darpan Registration',
      //   type: 'text',
      //   inputtype: 'text',
      //   placeholder: `e.g., AB/2020/1234657`,
      //   validation: 'required',
      //   validationpattern: /[A-Z]{2}[\/]{1}[0-9]{4}[\/]{1}[0-9]{7}$/,
      //   validpatternformat: 'AB/2020/1234657',
      //   showrequired: true,
      //   lengthMin: 15,
      //   minLength: 15,
      //   jsonfield: 'ngo_darpan_id',
      //   dependenton: 'is_ngo_darpan_registered',
      //   dependencywrapperkey: 'legal_compliance'
      // },
      {
        label: 'GST Number',
        inputtype: 'masterTextFields',
        type: 'text',
        placeholder: 'e.g., 18AABCT3518Q1ZV',
        validationpattern: /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9A-Z]{1}$/,
        validpatternformat: '18AABCT3518Q1ZV',
        // minLength: 15,
        lengthMin: 15,
        maxLength: 15,
        jsonfield: 'gst_num',
        masterfield: 'gst_num'
      },
      {
        label: 'GST Certificate',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        jsonfield: 'gst_cert',
        previewDoc: true
      }
    ]
  },
  {
    title: 'Financial Capacity',
    wrapperKey: 'financial_capacity',
    subTitle: 'Step 3',
    hasSubSection: true,
    fields: [
      {
        subWrapperKey: 'finance',
        separator: false,
        heading: 'Financial Audit Report',
        subSection: 1,
        label: 'Audited Financial Statements',
        accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
        inputtype: 'multiplefilesnew',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        jsonfield: 'audited_financial_stmt',
        // required: true
        validation: 'required'
      },
      {
        subWrapperKey: 'finance',
        separator: false,
        inputtype: 'newFinancefields'
      },
      // {
      //   subWrapperKey: 'finance',
      //   separator: true,
      //   subSection: 1,
      //   // label:'Audited Financial Statements by Finance Team / Bank Account Statement',

      //   label:
      //     new Date().getMonth() >= 3
      //       ? `Organization external audit report for FY ${new Date().getFullYear() -
      //           1} - ${new Date().getFullYear()}`
      //       : `Organization external audit report for FY ${new Date().getFullYear() -
      //           2} - ${new Date().getFullYear() - 1}`,
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   jsonfield: getFormattedValue(
      //     new Date().getMonth() >= 3
      //       ? `Organization external audit report for FY ${new Date().getFullYear() -
      //           1} - ${new Date().getFullYear()}`
      //       : `Organization external audit report for FY ${new Date().getFullYear() -
      //           2} - ${new Date().getFullYear() - 1}`
      //   ),
      //   // required: true
      //   validation: 'required'
      // },
      // {
      //   subWrapperKey: 'finance',
      //   separator: true,
      //   subSection: 1,
      //   // label:'Audited Financial Statements by Finance Team / Bank Account Statement',

      //   label:
      //     new Date().getMonth() >= 3
      //       ? `Organization external audit report for FY ${new Date().getFullYear() -
      //           2} - ${new Date().getFullYear() - 1}`
      //       : `Organization external audit report for FY ${new Date().getFullYear() -
      //           3} - ${new Date().getFullYear() - 2}`,
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   jsonfield: getFormattedValue(
      //     new Date().getMonth() >= 3
      //       ? `Organization external audit report for FY ${new Date().getFullYear() -
      //           2} - ${new Date().getFullYear() - 1}`
      //       : `Organization external audit report for FY ${new Date().getFullYear() -
      //           3} - ${new Date().getFullYear() - 2}`
      //   )
      //   // required: true
      //   // validation: 'required'
      // },
      // {
      //   subWrapperKey: 'finance',
      //   separator: true,
      //   subSection: 1,
      //   // label:'Audited Financial Statements by Finance Team / Bank Account Statement',

      //   label:
      //     new Date().getMonth() >= 3
      //       ? `Organization external audit report for FY ${new Date().getFullYear() -
      //           3} - ${new Date().getFullYear() - 2}`
      //       : `Organization external audit report for FY ${new Date().getFullYear() -
      //           4} - ${new Date().getFullYear() - 3}`,
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   jsonfield: getFormattedValue(
      //     new Date().getMonth() >= 3
      //       ? `Organization external audit report for FY ${new Date().getFullYear() -
      //           3} - ${new Date().getFullYear() - 2}`
      //       : `Organization external audit report for FY ${new Date().getFullYear() -
      //           4} - ${new Date().getFullYear() - 3}`
      //   )
      //   // required: true
      //   // validation: 'required'
      // },
      // {
      //   subWrapperKey: 'finance',
      //   label:
      //     new Date().getMonth() >= 3
      //       ? `Balance Sheet for FY ${new Date().getFullYear() -
      //           1} - ${new Date().getFullYear()}`
      //       : `Balance Sheet for FY ${new Date().getFullYear() -
      //           2} - ${new Date().getFullYear() - 1}`,
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   // required: true
      //   validation: 'required',
      //   dependencywrapperkey: 'financial_capacity',
      //   jsonfield: getFormattedValue(
      //     new Date().getMonth() >= 3
      //       ? `Balance Sheet for FY ${new Date().getFullYear() -
      //           1} - ${new Date().getFullYear()}`
      //       : `Balance Sheet for FY ${new Date().getFullYear() -
      //           2} - ${new Date().getFullYear() - 1}`
      //   )
      // },
      // {
      //   subWrapperKey: 'finance',
      //   label:
      //     new Date().getMonth() >= 3
      //       ? `Balance Sheet for FY ${new Date().getFullYear() -
      //           2} - ${new Date().getFullYear() - 1}`
      //       : `Balance Sheet for FY ${new Date().getFullYear() -
      //           3} - ${new Date().getFullYear() - 2}`,
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   dependencywrapperkey: 'financial_capacity',
      //   jsonfield: getFormattedValue(
      //     new Date().getMonth() >= 3
      //       ? `Balance Sheet for FY ${new Date().getFullYear() -
      //           2} - ${new Date().getFullYear() - 1}`
      //       : `Balance Sheet for FY ${new Date().getFullYear() -
      //           3} - ${new Date().getFullYear() - 2}`
      //   )
      //   // required: true
      //   // validation: 'required'
      // },
      // {
      //   subWrapperKey: 'finance',
      //   label:
      //     new Date().getMonth() >= 3
      //       ? `Balance Sheet for FY ${new Date().getFullYear() -
      //           3} - ${new Date().getFullYear() - 2}`
      //       : `Balance Sheet for FY ${new Date().getFullYear() -
      //           4} - ${new Date().getFullYear() - 3}`,
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   dependencywrapperkey: 'financial_capacity',
      //   jsonfield: getFormattedValue(
      //     new Date().getMonth() >= 3
      //       ? `Balance Sheet for FY ${new Date().getFullYear() -
      //           3} - ${new Date().getFullYear() - 2}`
      //       : `Balance Sheet for FY ${new Date().getFullYear() -
      //           4} - ${new Date().getFullYear() - 3}`
      //   )
      //   // required: true
      //   // validation: 'required'
      // },
      // {
      //   subWrapperKey: 'finance',
      //   label: 'Organization external audit report (past three years)',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   // required: true
      //   validation: 'required'
      // },
      // {
      // https://app.clickup.com/t/85zrrjbq5
      //   subWrapperKey: 'finance',
      //   label:
      //     'Have you filed the e-form CSR1 with the Ministry of Corporate Affairs?',
      //   inputtype: 'radio',
      //   validation: 'required',
      //   masterfield: 'is_eform_csr1',
      //   newLine: true,
      //   jsonfield: 'is_eform_csr1'
      // },
      // {
      //   subWrapperKey: 'finance',
      //   label: 'e-form CSR1 filed with the Ministry of Corporate Affairs',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'file',
      //   fileSizeLimit: 25,
      //   validation: 'required',
      //   type: 'file',
      //   dependenton: 'is_eform_csr1',
      //   dependencywrapperkey: 'financial_capacity',
      //   dependencysubwrapperkey: 'finance',
      //   previewDoc: true,
      //   jsonfield: 'eform_csr1'
      // },

      //not required for workplace
      // {
      //   subWrapperKey: 'finance',
      //   label:
      //     'Have you submitted budget vs actual report for last implemented project?',
      //   inputtype: 'radio',
      //   // required: true,
      //   validation: 'required',
      //   masterfield: 'budget_for_last_project',
      //   newLine: true,
      //   jsonfield: 'budget_for_last_project'
      // },
      // {
      //   subWrapperKey: 'finance',
      //   label: 'budget vs actual report for last implemented project',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   validation: 'required',
      //   type: 'file',
      //   dependenton: 'budget_for_last_project',
      //   dependencywrapperkey: 'financial_capacity',
      //   dependencysubwrapperkey: 'finance',
      //   previewDoc: true,
      //   jsonfield: 'budget_for_last_project_report'
      // },

      // {
      //   subWrapperKey: 'finance',
      //   label: 'Latest Income and Expenditure Statement',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   validation: 'required',
      //   jsonfield: 'latest_income_and_expenditure_statement'
      // },

      // {
      //   subWrapperKey: 'itr',
      //   separator: true,
      //   heading: 'Income Tax',
      //   subSection: 2,
      //   label: 'State financial year ITR were filed',
      //   inputtype: 'yearSelector',
      //   type: 'date',
      //   minyear: '1950-01-01',
      //   maxyear: 'current',
      //   validation: 'required',
      //   jsonfield: 'state_itr_financial_year'
      // },
      // {
      //   subWrapperKey: 'itr',
      //   label: 'State financial year your accounts were audited',
      //   inputtype: 'yearSelector',
      //   type: 'date',
      //   minyear: '1950-01-01',
      //   maxyear: 'current',
      //   validation: 'required',
      //   jsonfield: 'audited_state_financial_year'
      // },
      // {
      //   subWrapperKey: 'itr',
      //   label: 'Last Financial Year ITR',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'file',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   // required: true,
      //   validation: 'required',
      //   multiple: true,
      //   jsonfield: 'last_financial_year_itr'
      // },
      // {
      //   subWrapperKey: 'itr',
      //   label: 'Quarterly ITR for current year',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   multiple: true,
      //   jsonfield: 'quaterly_itr'
      //   //accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   //inputtype: 'multiplefilesnew',
      //   //fileSizeLimit: 25,
      //   //type: 'file',
      //   //previewDoc: true
      //   // required: true
      //   // validation: 'required'
      // },
      // {
      //   subWrapperKey: 'itr',
      //   label: 'Do you need to file your return with Charity commissioner',
      //   inputtype: 'radio',
      //   placeholder: '',
      //   newLine: true,
      //   showrequired: true,
      //   masterfield: 'is_file_return_with_charity_of_comissioner',
      //   dependenton: 'legal_structure',
      //   dependencyvalue: '1,2',
      //   dependencywrapperkey: 'legal_compliance',
      //   jsonfield: 'is_file_return_with_charity_of_comissioner'
      // },
      // {
      //   subWrapperKey: 'itr',
      //   label: 'Last year of filing return with Charity commissioner',
      //   inputtype: 'yearSelector',
      //   type: 'date',
      //   minyear: '1900-01-01',
      //   maxyear: 'current',
      //   showrequired: true,
      //   dependenton: 'is_file_return_with_charity_of_comissioner',
      //   dependencywrapperkey: 'financial_capacity',
      //   dependencysubwrapperkey: 'itr',
      //   jsonfield: 'year_file_return_with_charity_of_comissioner'
      // },
      // {
      //   subWrapperKey: 'itr',
      //   label: 'Do you need to file your return with Registrar of Companies',
      //   inputtype: 'radio',
      //   placeholder: '',
      //   newLine: true,
      //   showrequired: true,
      //   masterfield: 'is_file_return_with_registrar_of_companies',
      //   dependenton: 'legal_structure',
      //   dependencyvalue: '3',
      //   dependencywrapperkey: 'legal_compliance',
      //   jsonfield: 'is_file_return_with_registrar_of_companies'
      // },
      // {
      //   subWrapperKey: 'itr',
      //   label: 'Last year of filing return with Registrar of Companies',
      //   inputtype: 'yearSelector',
      //   type: 'date',
      //   minyear: '1900-01-01',
      //   maxyear: 'current',
      //   showrequired: true,
      //   dependenton: 'is_file_return_with_registrar_of_companies',
      //   dependencywrapperkey: 'financial_capacity',
      //   dependencysubwrapperkey: 'itr',
      //   jsonfield: 'year_file_return_with_registrar_of_companies'
      // },
      {
        separator: true,
        heading: 'NGO Bank Account Details',
        subSection: 3,
        subWrapperKey: 'bank_details',
        label: 'Bank Name',
        inputtype: 'text',
        type: 'text',
        validation: 'required',
        maxLength: 200,
        placeholder: 'e.g., ABCD Pvt. Ltd.',
        jsonfield: 'bank_name'
      },
      {
        subWrapperKey: 'bank_details',
        label: 'Account Holder Name',
        inputtype: 'text',
        type: 'text',
        validation: 'required',
        maxLength: 100,
        placeholder: 'e.g., John Doe',
        jsonfield: 'account_holder_name'
      },
      {
        subWrapperKey: 'bank_details',
        label: 'Account Number',
        type: 'text',
        inputtype: 'text',
        validation: 'required',
        lengthMin: 6,
        maxLength: 18,
        // pattern: '[0-9]{12,16}',
        validationpattern: /[0-9]{6,18}$/,
        validpatternmessage: 'Please enter a valid account number',
        placeholder: 'Only Numbers (6-18 digits)',
        jsonfield: 'account_number'
      },
      {
        subWrapperKey: 'bank_details',
        label: 'Account Type',
        inputtype: 'multiselect',
        dropdownType: 'single',
        options: {
          key: 'accountTypeDropDown.accountTypes',
          valuekey: 'id',
          displaykey: 'name'
        },
        validation: 'required',
        jsonfield: 'account_type'
      },
      {
        subWrapperKey: 'bank_details',
        label: 'IFSC Code',
        inputtype: 'text',
        type: 'text',
        // required: true,
        validation: 'required',
        validationpattern: /[A-Z0-9]$/,
        validpatternformat: 'ABCD0123456',
        maxLength: 11,
        placeholder: 'e.g., ABCD0123456',
        jsonfield: 'ifsc_code'
      },

      {
        separator: true,
        heading: 'FCRA',
        subSection: 4,
        subWrapperKey: 'finance',
        label: 'Do you have a FCRA certificate?',
        inputtype: 'radio',
        validation: 'required',
        placeholder: '',
        masterfield: 'is_fcra_certified',
        preonboarding: true,
        jsonfield: 'is_fcra_certified'
      },
      {
        subWrapperKey: 'finance',
        label: 'Purpose under which FCRA License is Guaranteed',
        inputtype: 'multiselect',
        // dropdownType: 'single',
        options: {
          key: 'common.fcraPurpose',
          valuekey: 'id',
          displaykey: 'name'
        },
        showrequired: true,
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        newLine: true,
        jsonfield: 'fcra_purpose_id',
        excludeFromStepCompletionCalculation: true
      },
      {
        separator: true,
        heading: 'FCRA Details',
        subWrapperKey: 'finance',
        label: 'FCRA Certificate',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        multiple: true,
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        jsonfield: 'fcra_certificate',
        excludeFromStepCompletionCalculation: true
      },
      {
        subWrapperKey: 'finance',
        label: 'FCRA Expiry Date',
        type: 'date',
        inputtype: 'date',
        // dateFormat: 'YYYY-MM-DD hh:mm:ss',
        minDate: String(new Date().getFullYear() - 5) + '-' + '01-01',
        maxDate: String(new Date().getFullYear() + 5) + '-' + '12-31',

        placeholder: `Select Date`,
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        showrequired: true,
        jsonfield: 'fcra_expiry_date',
        excludeFromStepCompletionCalculation: true
      },
      {
        subWrapperKey: 'finance',
        label: 'FCRA Registration Number',
        inputtype: 'text',
        placeholder: '',
        type: 'text',
        maxLength: 9,
        lengthMin: 9,
        showrequired: true,
        validationpattern: /[0-9]{9}$/,
        validpatternformat: '123456789',
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        jsonfield: 'fcra_registration_number',
        excludeFromStepCompletionCalculation: true
      },
      //not required for workplace giving
      // {
      //   subWrapperKey: 'finance',
      //   label: 'Annual Return Filed for FCRA',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   showrequired: true,
      //   multiple: true,
      //   dependenton: 'is_fcra_certified',
      //   dependencywrapperkey: 'financial_capacity',
      //   dependencysubwrapperkey: 'finance',
      //   jsonfield: 'annual_return_fcra'
      // },
      {
        subWrapperKey: 'finance',
        label: 'Audited Financial Statement for FCRA Account',
        accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
        inputtype: 'multiplefilesnew',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        multiple: true,
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        jsonfield: 'fcra_balance_sheet',
        excludeFromStepCompletionCalculation: true
      },

      {
        separator: true,
        heading: 'FCRA Bank Account Details',
        subSection: 5,
        subWrapperKey: 'bank_details',
        label: 'FCRA Bank Name',
        inputtype: 'text',
        type: 'text',
        maxLength: 200,
        placeholder: 'e.g., ABCD Pvt. Ltd.',
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        showrequired: true,
        newLine: true,
        prefilled: true,
        jsonfield: 'fcra_bank_name',
        excludeFromStepCompletionCalculation: true
      },
      {
        subWrapperKey: 'bank_details',
        label: 'FCRA Account Holder Name',
        inputtype: 'text',
        type: 'text',
        maxLength: 100,
        placeholder: 'e.g., John Doe',
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        showrequired: true,
        jsonfield: 'fcra_account_holder_name',
        excludeFromStepCompletionCalculation: true
      },
      {
        subWrapperKey: 'bank_details',
        label: 'FCRA Account Number',
        type: 'text',
        inputtype: 'text',
        // minLength: 6,
        lengthMin: 6,
        maxLength: 18,
        // pattern: '[0-9]{12,16}',
        validationpattern: /[0-9]{6,18}$/,
        validpatternmessage: 'Please enter a valid FCRA account number',
        placeholder: 'Only Numbers (6-18 digits)',
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        showrequired: true,
        jsonfield: 'fcra_account_number',
        excludeFromStepCompletionCalculation: true
      },
      {
        subWrapperKey: 'bank_details',
        label: 'FCRA Account Type',
        inputtype: 'multiselect',
        dropdownType: 'single',
        options: {
          key: 'accountTypeDropDown.accountTypes',
          valuekey: 'id',
          displaykey: 'name'
        },
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        showrequired: true,
        jsonfield: 'fcra_account_type',
        excludeFromStepCompletionCalculation: true
      },
      {
        subWrapperKey: 'bank_details',
        label: 'FCRA IFSC Code',
        inputtype: 'text',
        type: 'text',
        maxLength: 11,
        validationpattern: /[A-Z0-9]$/,
        // validationpattern: /[A-Z]{4}[0]{1}[0-9]{6}$/,
        validpatternformat: 'ABCD0123456',
        dependenton: 'is_fcra_certified',
        dependencywrapperkey: 'financial_capacity',
        dependencysubwrapperkey: 'finance',
        placeholder: 'e.g., ABCD0123456',
        showrequired: true,
        prefilled: true,
        jsonfield: 'fcra_ifsc_code',
        excludeFromStepCompletionCalculation: true
      }
    ]
  },
  {
    title: 'Governance & Institutional Capacity',
    wrapperKey: 'institutional_capacity',
    subTitle: 'Step 4',
    hasSubSection: true,
    fields: [
      // {
      //   separator: false,
      //   heading: 'Funds Mobilisation',
      //   subSection: 1,
      //   subWrapperKey: 'institution_cap',
      //   label: 'Funds Mobilised in Last 3 Years',
      //   labelAppend: ` ${String.fromCharCode(0x20b9)}`,
      //   inputtype: 'funds mobilized',
      //   placeholder: `Enter in ${String.fromCharCode(0x20b9)}`
      // },

      //not required for workplace giving
      // {
      //   subWrapperKey: 'institution_cap',
      //   separator: false,
      //   heading: 'Funds Mobilisation',
      //   subSection: 1,
      //   label: 'Funds Mobilised in Last 3 Years'
      // },
      // {
      //   subWrapperKey: 'institution_cap',
      //   heading: 'Funds Mobilisation',
      //   // subSection: 1,
      //   label: 'Funds Mobilised in Last 3 Years',
      //   labelAppend: ` ${String.fromCharCode(0x20b9)}`,
      //   inputtype: 'funds mobilized',
      //   // inputtype: 'textNumber',
      //   // type: 'text',
      //   placeholder: `Enter in ${String.fromCharCode(0x20b9)}`
      // },
      // {
      //   subWrapperKey: 'institution_cap',
      //   label: 'primary Funding Source',
      //   inputtype: 'multiselect',
      //   // required: true,
      //   validation: 'required',
      //   options: {
      //     key: 'common.masterInfo.primary_funding_sources',
      //     valuekey: 'id',
      //     displaykey: 'name'
      //   },
      //   jsonfield: 'primary_funding_source'
      // },
      // {
      //   separator: true,
      //   heading: 'Names of Top 3 donors in the last 3 years (cumulatively)',
      //   label: '',
      //   inputtype: 'top donors'
      // },

      // {
      //   separator: true,
      //   heading: 'Accreditation',
      //   subSection: 2,
      //   subWrapperKey: 'institution_cap',
      //   label: 'NGO Accreditation',
      //   accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
      //   inputtype: 'file',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   jsonfield: 'accreditation'
      // },
      {
        heading: 'Accreditation',
        subSection: 3,
        separator: false,
        subWrapperKey: 'institution_cap',
        label: 'are you certified by Guide Star India',
        inputtype: 'radio',
        placeholder: '',
        masterfield: 'is_certified_guidestar_in_india',
        validation: 'required',
        newLine: true,
        preonboarding: true,
        jsonfield: 'is_certified_guidestar_in_india'
      },
      {
        subWrapperKey: 'institution_cap',
        label: 'Guidestar Affiliation',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        dependenton: 'is_certified_guidestar_in_india',
        dependencywrapperkey: 'institutional_capacity',
        dependencysubwrapperkey: 'institution_cap',
        jsonfield: 'guidestar_certificate',
        excludeFromStepCompletionCalculation: true
      },
      {
        subWrapperKey: 'institution_cap',
        label: 'are you certified by Credibility Alliance',
        inputtype: 'radio',
        placeholder: '',
        masterfield: 'is_certified_credibility_alliance',
        validation: 'required',
        newLine: true,
        preonboarding: true,
        jsonfield: 'is_certified_credibility_alliance'
      },
      {
        subWrapperKey: 'institution_cap',
        label: 'Credibility Alliance Certificate',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        showrequired: true,
        dependenton: 'is_certified_credibility_alliance',
        dependencywrapperkey: 'institutional_capacity',
        dependencysubwrapperkey: 'institution_cap',
        jsonfield: 'credibility_alliance_certificate',
        excludeFromStepCompletionCalculation: true
      }
      //not required for workplace giving
      // {
      //   subWrapperKey: 'institution_cap',
      //   separator: true,
      //   heading: 'Internal Capacity',
      //   subSection: 3,
      //   label: 'Staff Members',
      //   inputtype: 'textNumber',
      //   placeholder: 'No. of staff members',
      //   type: 'text',
      //   minValue: 1,
      //   maxValue: 1000000,
      //   maxLength: 7,
      //   lengthMin: 1,
      //   jsonfield: 'emp_count'
      //   // minLength: 1
      // },
      // {
      //   subWrapperKey: 'institution_cap',
      //   label: 'Full Time Members',
      //   inputtype: 'textNumber',
      //   placeholder: 'No. of full time members',
      //   type: 'text',
      //   minValue: 1,
      //   maxValue: 1000000,
      //   maxLength: 7,
      //   lengthMin: 1,
      //   jsonfield: 'full_time_staff_count'
      //   // minLength: 1
      // },
      // {
      //   subWrapperKey: 'institution_cap',
      //   label: 'Contractual Members',
      //   inputtype: 'textNumber',
      //   placeholder: 'No. of contractual members',
      //   type: 'text',
      //   minValue: 1,
      //   maxValue: 1000000,
      //   maxLength: 7,
      //   lengthMin: 1,
      //   jsonfield: 'contractual_staff_count'
      //   // minLength: 1
      // },
      // {
      //   subWrapperKey: 'institution_cap',
      //   label: 'Average number of volunteers engaged through the year',
      //   inputtype: 'textNumber',
      //   placeholder: 'No. of volunteers',
      //   type: 'text',
      //   minValue: 1,
      //   maxValue: 1000000,
      //   maxLength: 4,
      //   lengthMin: 1,
      //   jsonfield: 'volunteer_staff_count'
      //   // minLength: 1
      // },
      // {
      //   subWrapperKey: 'institution_cap',
      //   label: 'Do you employ/engage Professional Chartered Accountants?',
      //   inputtype: 'radio',
      //   // required: true,
      //   validation: 'required',
      //   placeholder: '',
      //   jsonfield: 'is_ca_employed'
      // },
      // {
      //   separator: true,
      //   heading: 'Board Members/Trustees/Directors',
      //   label: '',
      //   inputtype: 'board members'
      // },
      // {
      //   separatortop: true,
      //   subWrapperKey: 'institution_cap',
      //   label: 'Any Members/Trustee/Directors have Resigned',
      //   inputtype: 'radio',
      //   placeholder: '',
      //   newLine: true,
      //   validation: 'required',
      //   masterfield: 'any_directors_have_resigned',
      //   jsonfield: 'any_directors_have_resigned'
      // },
      // {
      //   subWrapperKey: 'institution_cap',
      //   label: 'Year of Members/Trustee/Directors Resignation',
      //   inputtype: 'yearSelector',
      //   minyear: '1950-01-01',
      //   maxyear: 'current',
      //   showrequired: true,
      //   placeholder: 'e.g., 2020',
      //   dependenton: 'any_directors_have_resigned',
      //   dependencywrapperkey: 'institutional_capacity',
      //   dependencysubwrapperkey: 'institution_cap',
      //   jsonfield: 'year_of_directors_resignation'
      // },
      // {
      //   label: 'No of Board meetings held last year',
      //   inputtype: 'textNumber',
      //   type: 'text',
      //   maxLength: 100,
      //   minValue: 1,
      //   maxValue: 1000,
      //   placeholder: 'Please enter a value between 1 to 1000',
      //   validation: 'required',
      //   subWrapperKey: 'institution_cap',
      //   newLine: true,
      //   jsonfield: 'no_of_board_meetings'
      // },
      // {
      //   label:
      //     'Are any founders/directors/trustees/office bearers a part of any political party',
      //   inputtype: 'radio',
      //   placeholder: '',
      //   subWrapperKey: 'institution_cap',
      //   validation: 'required',
      //   preonboarding: true,
      //   jsonfield: 'are_directors_related_political_party'
      // },
      // {
      //   separator: true,
      //   heading: 'Auditors',
      //   label: '',
      //   inputtype: 'auditors'
      // },
      // {
      //   separatortop: true,
      //   subWrapperKey: 'institution_cap',
      //   label: `Policies defined and followed in your organisation`,
      //   inputtype: 'areaOfOperationMultiselect',
      //   required: true,
      //   validation: 'required',
      //   options: {
      //     key: 'common.masterInfo.policies',
      //     valuekey: 'id',
      //     displaykey: 'name'
      //   },
      //   jsonfield: 'policies_defined_and_followed_in_your_organisation'
      // },
      // {
      //   separatortop: true,
      //   subWrapperKey: 'institution_cap',
      //   label: 'Any Auditors have Resigned',
      //   inputtype: 'radio',
      //   placeholder: '',
      //   newLine: true,
      //   validation: 'required',
      //   masterfield: 'any_auditors_have_resigned',
      //   jsonfield: 'any_auditors_have_resigned'
      // },
      // {
      //   subWrapperKey: 'institution_cap',
      //   label: 'Year of auditors resignation',
      //   inputtype: 'yearSelector',
      //   type: 'date',
      //   minyear: '1950-01-01',
      //   maxyear: 'current',
      //   showrequired: true,
      //   dependenton: 'any_auditors_have_resigned',
      //   dependencywrapperkey: 'institutional_capacity',
      //   dependencysubwrapperkey: 'institution_cap',
      //   jsonfield: 'year_of_auditors_resignation'
      // },
      // {
      //   subWrapperKey: 'organorgram',
      //   label: 'Do you have Internal Finance/Accounts Team',
      //   inputtype: 'radio',
      //   placeholder: '',
      //   newLine: true,
      //   validation: 'required',
      //   jsonfield: 'is_finance_team'
      // }
      // // {
      //   subWrapperKey: 'institution_cap',
      //   label: `Policies defined and followed in your organisation`,
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'file',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   multiple: true,
      //   newLine: true,
      //   jsonfield: 'policies_defined_and_followed_in_your_organisation'
      // },
    ]
  },
  {
    title: 'Assessment Report',
    wrapperKey: 'impact_assessment',
    subTitle: 'Step 5',
    hasSubSection: true,
    fields: [
      // {
      //   separator: false,
      //   heading: 'Projects',
      //   subSection: 1,
      //   label: 'Number of Projects till date',
      //   inputtype: 'textNumber',
      //   type: 'text',
      //   minValue: 0,
      //   maxValue: 100000000,
      //   maxLength: 14,
      //   lengthMin: 1,
      //   jsonfield: 'number_of_projects_till_date',
      //   placeholder: 'Please enter a value between 1 to 100000000',
      //   validation: 'required'
      // },
      // {
      //   label: 'Average Budget Per Project',
      //   inputtype: 'textNumber',
      //   type: 'text',
      //   step: 'any',
      //   minValue: 1,
      //   maxValue: 9999999999,
      //   lengthMin: 1,
      //   maxLength: 10,
      //   jsonfield: 'average_budget_per_project',
      //   placeholder: 'Please enter the amount',
      //   // minLength: 1,
      //   validation: 'required'
      //   // required: true
      // },
      // {
      //   label: 'Number of Beneficiaries Impacted Till Date',
      //   inputtype: 'textNumber',
      //   type: 'text',
      //   minValue: 1,
      //   maxValue: 100000000,
      //   maxLength: 10,
      //   lengthMin: 1,
      //   jsonfield: 'number_of_beneficiaries_impacted_till_date',
      //   placeholder: 'Please enter a value between 1 to 100000000',
      //   validation: 'required'
      // },
      // {
      //   separator: true,
      //   heading: 'Report & Awards',
      //   subSection: 2,
      //   label: `How do you measure your organisation social impact?`,
      //   inputtype: 'radioArray',
      //   values: [
      //     'Internal valuation system',
      //     'Through 3rd party',
      //     'Monthly meetings',
      //     'Do not evaluate currently'
      //   ],
      //   newLine: true,
      //   spanfull: true
      // },
      // {
      //   separator: true,
      //   heading: 'Report & Awards',
      //   subSection: 2,
      //   label: `How do you measure your organisation social impact?`,
      //   inputtype: 'checkboxGroup',
      //   values: [
      //     'Internal valuation system',
      //     'Through 3rd party',
      //     'Monthly meetings',
      //     'Do not evaluate currently',
      //     'Other'
      //   ],
      //   newLine: true,
      //   jsonfield: 'how_do_you_measure_your_organisation_social_impact',
      //   spanfull: true
      // },

      {
        heading: 'Report & Awards',
        separator: false,
        subSection: 2,
        label: 'NGO Annual Report',
        accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
        inputtype: 'file',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        validation: 'required',
        jsonfield: 'ngo_annual_report',
        extraInfoAboutField:
          'The Annual Report showcases information primarily regarding the Governance, Programmes and Finance & Compliances during the preceding year to your stakeholders.'
      }
      // {
      //   label: 'NGO Significant Achievement & Awards',
      //   accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   multiple: true,
      //   jsonfield: 'achievement_rewards'
      // },
      // {
      //   // label: 'Impact Project Report of Last 2 Years',
      //   label: 'Project Impact Report for Recent Projects',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   validation: 'required',
      //   jsonfield: 'project_impact_report_for_recent_projects',
      //   multiple: true
      // },
      // {
      //   label:
      //     'Additional project/program documents that you would like to upload?',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   jsonfield: 'additional_project',
      //   previewDoc: true
      // },

      // {
      //   separator: true,
      //   heading: 'Programmatic Response Capacity',
      //   subSection: 3,
      //   label: 'Do you conduct needs assessment for projects?',
      //   inputtype: 'radio',
      //   validation: 'required',
      //   placeholder: '',
      //   newLine: true,
      //   jsonfield: 'do_you_conduct_needs_assessment_for_projects',
      //   masterfield: 'do_you_conduct_needs_assessment_for_projects'
      // },
      // {
      //   label: 'Assessment Document',
      //   accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
      //   inputtype: 'multiplefilesnew',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   dependenton: 'do_you_conduct_needs_assessment_for_projects',
      //   dependencywrapperkey: 'impact_assessment',
      //   jsonfield: 'assessment_document',
      //   showrequired: true
      // }
    ]
  },
  {
    title: 'Social Activity',
    wrapperKey: 'social_activity',
    subTitle: 'Step 6',
    sectionNonMandatory: true,
    fields: [
      {
        separator: false,
        heading: 'Background & Check report',
        label: 'is there any litigation against your organisation?',
        inputtype: 'radio',
        preonboarding: true,
        jsonfield: 'is_litigation_against_org',
        validation: 'required'
      },
      {
        label: 'Organisation been blacklisted or barred by govt',
        inputtype: 'radio',
        preonboarding: true,
        jsonfield: 'is_org_blacklisted',
        validation: 'required'
      },
      {
        label: 'Association with religious organisation OR political party',
        inputtype: 'radio',
        preonboarding: true,
        jsonfield: 'is_org_associated_political_party',
        validation: 'required'
      },
      {
        label: 'Has anybody in your organisation been convicted?',
        inputtype: 'radio',
        preonboarding: true,
        jsonfield: 'is_anybody_has_been_convicted_in_your_org',
        validation: 'required'
      }

      // {
      //   label: 'Links of Blogs and Articles',
      //   inputtype: 'textarea',
      //   rows: '4',
      //   newLine: true,
      //   type: 'text',
      //   validation: 'required',
      //   maxLength: 3000,
      //   placeholder: 'You may add links to any of the blogs and articles',
      //   validationMsg: 'Max 3000 characters',
      //   jsonfield: 'blogs',
      //   spanfull: true
      // },
      // {
      //   label: 'Names of Past Successful Campaigns',
      //   inputtype: 'textarea',
      //   rows: '4',
      //   newLine: true,
      //   type: 'text',
      //   validation: 'required',
      //   maxLength: 3000,
      //   placeholder:
      //     'You may add links related to your previous projects/campaigns',
      //   validationMsg: 'Max 3000 characters',
      //   jsonfield: 'campaign_details',
      //   spanfull: true
      // },
      // {
      //   label: 'Campaign Details Documents',
      //   uploadDocument: true,
      //   inputtype: 'multiplefilesnew',
      //   accept: '.pdf,.PDF',
      //   fileSizeLimit: 25,
      //   type: 'file',
      //   previewDoc: true,
      //   jsonfield: 'campaign_details_documents'
      // }
    ]
  },
  {
    title: 'Consent',
    wrapperKey: 'consent',
    subTitle: 'Step 7',
    fields: [
      {
        label: 'Agree to',
        inputtype: 'tncCheckBox',
        jsonfield: 'tnc_agreed',
        excludeFromStepCompletionCalculation: true,
        spanfull: true,
        required: true
        // validation: 'required'
      }
    ]
  }
];

export default completeWorkplaceGivingRegistrationData;

export function getChiefFunctionariesData(id) {
  return [
    {
      label: 'Member Name',
      type: 'text',
      inputtype: 'text',
      validation: 'required',
      // required: true,
      // minLength: 6,
      lengthMin: 3,
      maxLength: 100,
      key: 'member_name',
      id: id ? id : null,
      placeholder: 'Only characters',
      error: '',
      jsonfield: 'member_name'
    },
    {
      label: 'Designation',
      type: 'text',
      inputtype: 'text',
      validation: 'required',
      // required: true,
      // minLength: 6,
      lengthMin: 2,
      maxLength: 100,
      key: 'designation',
      id: id ? id : null,
      placeholder: 'Only characters',
      error: '',
      jsonfield: 'designation'
    },
    /* {
      label: 'Photo',
      accept: '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG',
      inputtype: 'file',
      type: 'file',
      required: false,
      key: 'photo',
      previewDoc: true,
      id: id ? id : null
    }, */
    {
      label: 'Phone Number',
      inputtype: 'text',
      type: 'text',
      placeholder: '8888448888 (10 Digit Number)',
      lengthMin: 10,
      // minLength: 10,
      maxLength: 10,
      required: false,
      key: 'phone_number',
      id: id ? id : null,
      error: '',
      jsonfield: 'phone_number'
    },
    {
      label: 'Email',
      inputtype: 'text',
      type: 'email',
      validation: 'required',
      // required: true,
      placeholder: 'john.doe@example.com',
      key: 'email',
      id: id ? id : null,
      error: '',
      jsonfield: 'email'
    },
    {
      label: 'Educational Background',
      type: 'text',
      inputtype: 'multiselect',
      dropdownType: 'single',
      validation: 'required',
      // required: true,
      options: {
        key: 'legalStructureDropdown.educationalBackground',
        valuekey: 'id',
        displaykey: 'name'
      },
      placeholder: 'Please select',
      key: 'educational_background',
      id: id ? id : null,
      error: '',
      jsonfield: 'educational_background'
    },
    {
      label: 'PAN Card',
      accept:
        '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF,application/pdf',
      inputtype: 'file',
      type: 'file',
      validation: 'required',
      // required: true,
      key: 'pancard',
      newLine: true,
      previewDoc: true,
      id: id ? id : null,
      error: '',
      jsonfield: 'pancard'
    },
    {
      label: 'AADHAR Card',
      accept:
        '.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,.pdf,.PDF,application/pdf',
      inputtype: 'file',
      type: 'file',
      validation: 'required',
      // required: true,
      key: 'aadhar_card',
      previewDoc: true,
      id: id ? id : null,
      error: '',
      jsonfield: 'aadhar_card'
    }
  ];
}

export function getAuditorData(id) {
  return [
    {
      label: 'Auditor Name',
      type: 'text',
      inputtype: 'text',
      // required: true,
      validation: 'required',
      // minLength: 6,
      lengthMin: 6,
      maxLength: 50,
      key: 'auditor_name',
      id: id ? id : null,
      placeholder: 'Only characters',
      error: '',
      jsonfield: 'auditor_name'
    },
    {
      label: 'Auditor Since',
      inputtype: 'yearSelector',
      type: 'date',
      minyear: '1950-01-01',
      maxyear: 'current',
      validation: 'required',
      key: 'auditor_since',
      id: id ? id : null,
      error: '',
      jsonfield: 'auditor_since'
    }
  ];
}

export function getDonorField(index) {
  const fieldObject = {
    label: `Names of Top 3 donors in the last 3 years (Donor ${index + 1})`,
    type: 'text',
    inputtype: 'text',
    validation: 'required',
    lengthMin: 6,
    maxLength: 40,
    key: 'top_3_donors',
    index: index,
    placeholder: 'Only characters (Max 40 characters)',
    error: '',
    jsonfield: `names_of_top_3_donors_in_the_last_3_years_(donor_${index + 1})`
  };
  return fieldObject;
}

export function getFundsMobilizedField(index, year) {
  const fieldObject = {
    label: `Funds Mobilised in Last Year ${year}`,
    type: 'text',
    inputtype: 'textNumber',
    step: 'any',
    validation: 'required',
    lengthMin: 1,
    maxLength: 14,
    minValue: 1,
    maxValue: 100000000,
    key: 'fund_mobilized',
    placeholder: 'Please enter the amount',
    index: index,
    year: year,
    error: '',
    jsonfield: `funds_mobilised_in_last_year_${year}`
  };
  return fieldObject;
}
export function getFinancialsData(reg_date) {
  if (reg_date === 'None') return [];
  let checkDate = new Date(reg_date);
  let currentDate = new Date();
  let timeDiff = Math.abs(currentDate.getTime() - checkDate.getTime());
  let oneYear = 1000 * 60 * 60 * 24 * 365;

  const yearNow = new Date().getFullYear();
  const todayDate = new Date();
  var financialYear = todayDate.getMonth() < 8 ? yearNow - 1 : yearNow;

  if (timeDiff < oneYear * 2) {
    return [];
  } else {
    const financialYears = [];
    for (let i = 2; i <= 4; i++) {
      const yearStart = new Date(currentDate.getFullYear() - i, 3, 1);
      const yearEnd = new Date(currentDate.getFullYear() - i + 1, 2, 31);
      if (yearEnd.getTime() > checkDate.getTime()) {
        financialYears.push(
          yearStart.getFullYear() + '-' + yearEnd.getFullYear().toString()
        );
      }
    }
    return financialYears?.map((item_year, index) => {
      return {
        subWrapperKey: 'finance',
        separator: true,
        subSection: 1,

        label: `Organization external audit report for FY ${financialYear -
          (index + 1)}
          -
          ${financialYear - index}`,

        accept: '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX',
        inputtype: 'multiplefilesnew',
        fileSizeLimit: 25,
        type: 'file',
        previewDoc: true,
        jsonfield: getFormattedValue(
          `Organization external audit report for FY ${item_year}`
        ),
        // required: true
        validation: 'required'
      };
    });
  }
}
