import { EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

const UploadService = {
  upload: function(data, refId, fileType, uploadProgressTracker) {
    let instance = HttpRequest.getInstance('HIROKU');
    let userId = JSON.parse(localStorage.getItem('ngoId'));
    let uploadToProd = process.env.UPLOAD_TO_PROD;
    if (!uploadToProd) {
      uploadToProd = false;
    }
    const file = data.get('file');
    const fileFormatStartIndex = file.name.lastIndexOf('.');
    let fileName = '';
    if (fileFormatStartIndex > 20) {
      fileName =
        file.name
          .substr(0, 20)
          .replace(/\./g, '')
          .replace(/\s/g, '_') + file.name.substr(fileFormatStartIndex);
    } else {
      fileName =
        file.name
          .substr(0, fileFormatStartIndex)
          .replace(/\./g, '')
          .replace(/\s/g, '_') + file.name.substr(fileFormatStartIndex);
    }

    let signedFormData = new FormData();
    signedFormData.append('docType', data.get('docType'));
    signedFormData.append('refId', data.get('refId'));
    signedFormData.append('filename', fileName);
    signedFormData.append('contentType', file.type);
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('upload_to_prod', uploadToProd);
    if (userId) {
      formData.append('user_id', userId);
    } else {
      formData.append('used_id', 'bc6f4b08-f48c-4e42-ab86-c8aeb0357ca9');
    }
    return new Promise((resolve, reject) => {
      instance
        .post(EndPoint.getApiUrl('NGO', 'FILE_UPLOAD', 'UPLOAD'), formData, {
          headers: {
            accept: 'application/json',
            'Content-Type': 'multipart/form-data'
          }
        })

        .then(response => {
          resolve({
            data: {
              fileName: response.data.data.objects[0].file_name,
              fileUrl: response.data.data.objects[0].uploaded_file_url
            }
          });
        })
        .catch(() => {
          reject('Uh-Oh! Looks like we are having server issues..');
        });
    });
  }
};

export default UploadService;

// import axios from 'axios';
// import { EndPoint } from '../constants';
// import HttpRequest from '../helpers/http-request';

// const UploadService = {
//   upload: function(data, refId, fileType, uploadProgressTracker) {
//     let instance = HttpRequest.getInstance('HIROKU');
//     const file = data.get('file');
//     let finalFileUrl = '';
//     const fileFormatStartIndex = file.name.lastIndexOf('.');
//     let fileName = '';
//     if (fileFormatStartIndex > 20) {
//       fileName =
//         file.name
//           .substr(0, 20)
//           .replace(/\./g, '')
//           .replace(/\s/g, '_') + file.name.substr(fileFormatStartIndex);
//     } else {
//       fileName =
//         file.name
//           .substr(0, fileFormatStartIndex)
//           .replace(/\./g, '')
//           .replace(/\s/g, '_') + file.name.substr(fileFormatStartIndex);
//     }
//     if (file.size > 1024 * 1024 * 1) {
//       let signedFormData = new FormData();
//       signedFormData.append('docType', data.get('docType'));
//       signedFormData.append('refId', data.get('refId'));
//       signedFormData.append('filename', fileName);
//       signedFormData.append('contentType', file.type);
//       return new Promise((resolve, reject) => {
//         instance
//           .post(
//             EndPoint.getApiUrl('NGO', 'UPLOAD_SIGNED_URL', 'S3'),
//             signedFormData,
//             {
//               headers: { 'Content-Type': 'multipart/form-data' }
//             }
//           )
//           .then(apires => {
//             const s3FormData = new FormData();
//             finalFileUrl = apires.data.fileUrl;
//             s3FormData.append(
//               'Policy',
//               apires.data.fileUploadData.fields.Policy
//             );
//             s3FormData.append(
//               'X-Amz-Algorithm',
//               apires.data.fileUploadData.fields['X-Amz-Algorithm']
//             );
//             s3FormData.append(
//               'X-Amz-Credential',
//               apires.data.fileUploadData.fields['X-Amz-Credential']
//             );
//             s3FormData.append(
//               'X-Amz-Date',
//               apires.data.fileUploadData.fields['X-Amz-Date']
//             );
//             s3FormData.append(
//               'X-Amz-Signature',
//               apires.data.fileUploadData.fields['X-Amz-Signature']
//             );
//             s3FormData.append('acl', apires.data.fileUploadData.acl);
//             s3FormData.append(
//               'bucket',
//               apires.data.fileUploadData.fields.bucket
//             );
//             s3FormData.append('content-type', file.type);
//             s3FormData.append('key', apires.data.fileUploadData.key);
//             s3FormData.append('file', file);
//             return axios.post(
//               'https://mettasocial-user-content.s3.amazonaws.com',
//               s3FormData,
//               {
//                 headers: { 'Content-Type': 'multipart/form-data' },
//                 onUploadProgress: uploadProgressTracker
//               }
//             );
//           })
//           .then(() => {
//             resolve({
//               data: {
//                 fileUrl: finalFileUrl
//               }
//             });
//           })
//           .catch(() => {
//             reject('Uh-Oh! Looks like we are having server issues..');
//           });
//       });
//     }
//     let urlType = 'UPLOAD_MEDIA';

//     switch (fileType) {
//       case 'video':
//         urlType = 'UPLOAD_VIDEO';
//         break;

//       case 'image':
//       case 'doc':
//       default:
//         urlType = 'UPLOAD_MEDIA';
//     }
//     data.append('refId', refId);
//     data.append('refId', fileName);
//     return instance.post(EndPoint.getApiUrl('NGO', urlType, 'S3'), data, {
//       headers: { 'Content-Type': 'multipart/form-data' },
//       onUploadProgress: uploadProgressTracker
//     });
//   }
// };

// export default UploadService;
