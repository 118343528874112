import { toast } from 'react-hot-toast';
import { Action, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

export const raiseTicket = data => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return instance
    .post(EndPoint.getApiUrl('NGO', 'SUPPORT', 'HIROKU'), data)
    .then(response => {
      dispatch({
        type: Action.NGO_SUPPORT_SAVED_DATA_SUCCESS,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      toast.error(
        'Uh-Oh! Looks like we are having server issues. Please Try Again'
      );
    });
};

export const getSupporttickets = () => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return new Promise((resolve, reject) => {
    instance
      .get(`${EndPoint.getApiUrl('NGO', 'SUPPORT', 'HIROKU')}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getSupportTypes = () => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return new Promise((resolve, reject) => {
    instance
      .get(`${EndPoint.getApiUrl('NGO', 'SUPPORTTYPES', 'HIROKU')}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getTicketDetails = caseId => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return new Promise((resolve, reject) => {
    let apiUrl = EndPoint.getApiUrl('NGO', 'TICKET_DETAILS', 'HIROKU');
    apiUrl = apiUrl.replace('{caseId}', caseId);
    instance
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
