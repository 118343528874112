import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  getCampaign,
  getActivitiesById,
  createOrModifyActivities
} from '../../actions';
import { Link } from 'react-router-dom';
import {
  InputDaypicker,
  // InputSelect,
  InputTextArea,
  InputSwitch
} from '../FormElements';

class ProjectActivities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: this.props.id,
      campaignName: '',
      // noOfPhases: 1,
      phaseDetailsArr: [],
      isSaved: false,
      campaignData: null,
      deletedActivitiesArr: []
    };
    this.dateInput = {
      label: 'Select a year',
      type: 'date',
      minyear: '2017-01-01',

      validation: 'required'
    };
    this.quarterList = [1, 2, 3, 4];
  }

  componentDidMount() {
    this.props.getCampaign(this.props.id, 'edit');
    // this.props.getActivitiesById(this.props.id);
    // this.addPhase();
  }

  getQuarterStr = date => {
    return (
      'Q' + (Math.ceil((date.getMonth() + 1) / 3) + ' ' + date.getFullYear())
    );
  };

  getQuarterList = (startDate, endDate) => {
    if (startDate > endDate) {
      let t = endDate;
      endDate = startDate;
      startDate = t;
    }

    startDate = new Date(startDate);
    let startQuarter = this.getQuarterStr(startDate);
    let endQuarter = this.getQuarterStr(endDate);
    let result = [startQuarter];
    while (startQuarter !== endQuarter) {
      startDate.setMonth(startDate.getMonth() + 3);
      startQuarter = this.getQuarterStr(startDate);
      result.push(startQuarter);
    }

    return result;
  };

  initActivitiesData = (actualProjectStartDate, actualProjectEndDate) => {
    this.props.getActivitiesById(this.props.id);
    const startDate = new Date(actualProjectStartDate);
    const endDate = new Date(actualProjectEndDate);
    const quartersList = this.getQuarterList(startDate, endDate);
    let finalQuartersArr = [];
    quartersList.map(quarter => {
      finalQuartersArr.push(this.getQuarterObject(quarter));
      return false;
    });
    this.setState({ phaseDetailsArr: finalQuartersArr });
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (
      nextprops.editSuccessResponse &&
      nextprops.editSuccessResponse.code === 200 &&
      this.state.campaignData === null
    ) {
      this.setState({
        campaignName: nextprops.editSuccessResponse.data.title,
        campaignData: nextprops.editSuccessResponse.data
      });
      this.initActivitiesData(
        nextprops.editSuccessResponse.data.actual_project_start_date,
        nextprops.editSuccessResponse.data.actual_project_end_date
      );
      // this.props.getActivitiesById(this.props.id);
    }
    if (
      nextprops.activitiesDetailsSuccessResponse &&
      nextprops.activitiesDetailsSuccessResponse.code === 200
    ) {
      if (
        nextprops.activitiesDetailsSuccessResponse.data.length > 0 &&
        this.state.phaseDetailsArr.length
      )
        this.setState({ isSaved: true });
      if (
        nextprops.activitiesDetailsSuccessResponse.data.length > 0 &&
        this.state.phaseDetailsArr.length
      ) {
        let quarterArr = this.state.phaseDetailsArr.slice();
        const activitiesArr = nextprops.activitiesDetailsSuccessResponse.data;
        activitiesArr.sort((a, b) => {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;
        });
        activitiesArr.map((activity, index) => {
          const arrIndex = quarterArr.findIndex(
            x => x.quarter === activity.quarter
          );
          if (arrIndex > -1) {
            const obj = {
              id: activity.id,
              activity: activity.activity,
              planned_start_date: activity.planned_start_date,
              planned_end_date: activity.planned_end_date,
              milestone: activity.milestone,
              attachment: activity.attachment
            };
            const activityIndex = quarterArr[
              arrIndex
            ].activity_details.findIndex(x => x.id === activity.id);
            if (
              activityIndex === -1 &&
              quarterArr[arrIndex].activity_details.length === 1 &&
              !quarterArr[arrIndex].activity_details[0].id
            ) {
              quarterArr[arrIndex].activity_details[0] = obj;
            } else if (activityIndex === -1) {
              const activityDescIndex = quarterArr[
                arrIndex
              ].activity_details.findIndex(
                x => x.activity.value === activity.activity
              );
              if (activityDescIndex > -1) {
                quarterArr[arrIndex].activity_details[activityDescIndex] = obj;
              } else {
                quarterArr[arrIndex].activity_details.push(obj);
              }
            } else if (activityIndex > -1) {
              quarterArr[arrIndex].activity_details[activityIndex] = obj;
            }
          }
          return false;
        });
        this.setPhaseDetailsArr(quarterArr);
      }
    }
  }

  getQuarter = () => {
    const date = new Date();
    return `Q1 ${date
      .getFullYear()
      .toString()
      .substr(-2)}`;
  };

  setPhaseDetailsArr(phaseArr) {
    let tempPhaseDetailsArr = [];
    phaseArr.map(activity => {
      const phase_obj = {
        // phase_objective: this.createFieldObject(
        //   activity.id,
        //   'phase_objective',
        //   activity.phase_description
        // ),
        id: activity.id || null,
        quarter: activity.quarter || this.getQuarter(),
        activity_details: []
      };
      if (
        activity.activity_details.length === 1 &&
        !activity.activity_details[0].id
      ) {
        phase_obj.activity_details = activity.activity_details;
      } else {
        activity.activity_details.map(item => {
          const activity_obj = {
            id: item.id,
            activity: this.createFieldObject(
              item.id,
              'activity',
              item.activity
            ),
            planned_start_date: this.createFieldObject(
              item.id,
              'planned_start_date',
              item.planned_start_date
            ),
            planned_end_date: this.createFieldObject(
              item.id,
              'planned_end_date',
              item.planned_end_date
            ),
            milestone: this.createFieldObject(
              item.id,
              'milestone',
              item.milestone
            ),
            attachment: this.createFieldObject(
              item.id,
              'attachment',
              item.attachment
            )
          };
          phase_obj.activity_details.push(activity_obj);
          return false;
        });
      }
      tempPhaseDetailsArr.push(phase_obj);
      return false;
    });
    this.setState({
      phaseDetailsArr: tempPhaseDetailsArr
      // noOfPhases: tempPhaseDetailsArr.length
    });
  }
  createFieldObject = (id, name, value) => {
    return {
      id: id,
      idLabel: name,
      name: name,
      value: value !== undefined ? value : '',
      error: ''
    };
  };

  getQuarterObject = quarterName => {
    return {
      quarter: quarterName,
      activity_details: [
        {
          id: null,
          activity: this.createFieldObject(null, `activity`),
          planned_start_date: this.createFieldObject(
            null,
            `planned_start_date`
          ),
          planned_end_date: this.createFieldObject(null, `planned_end_date`),
          milestone: this.createFieldObject(null, `milestone`, false),
          attachment: this.createFieldObject(null, `attachment`)
        }
      ]
    };
  };

  addActivity = p_index => {
    let phaseDetailsArr = this.state.phaseDetailsArr.slice();
    const obj = {
      id: null,
      activity: this.createFieldObject(null, `activity`),
      planned_start_date: this.createFieldObject(null, `planned_start_date`),
      planned_end_date: this.createFieldObject(null, `planned_end_date`),
      milestone: this.createFieldObject(null, `milestone`, false),
      attachment: this.createFieldObject(null, `attachment`)
    };
    phaseDetailsArr[p_index].activity_details.push(obj);
    this.setState({ phaseDetailsArr: phaseDetailsArr });
  };
  deleteActivity = (p_index, a_index) => {
    let phaseDetailsArr = this.state.phaseDetailsArr.slice();
    const delObj = phaseDetailsArr[p_index].activity_details[a_index];
    if (delObj.id) {
      this.state.deletedActivitiesArr.push(delObj);
    }
    phaseDetailsArr[p_index].activity_details.splice(a_index, 1);
    this.setState({ phaseDetailsArr: phaseDetailsArr });
  };

  eventToValue = (e, trimResults, toNumber) => {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      if (value !== 'true' || value !== 'false') {
        value = Number(value);
      }
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  };
  Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    }
  };
  validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';
    switch (name) {
      case 'phase_objective':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter the objective/goal';
        break;
      case 'activity':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter the activity';
        break;
      case 'planned_start_date':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Planned start date required';
        break;
      case 'planned_end_date':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Planned end date required';
        break;
      default:
    }
    return { isFieldValid, msg };
  };
  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  setFormErrorsAndValue = (event, callback, type) => {
    const idArr = event.target.id.split('__');
    let fieldId = idArr[0];
    let phaseId = parseInt(idArr[1]);
    let activityId = idArr.length === 3 ? parseInt(idArr[2]) : null;
    const { target } = event;
    let { value = '' } = target;

    if (!type) {
      value = this.eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = this.restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    const errorResult = this.validate(fieldId, value);
    let phaseDetailsArr = this.state.phaseDetailsArr.slice();
    if (phaseId && !activityId) {
      phaseDetailsArr[phaseId - 1][fieldId].value = value;
      phaseDetailsArr[phaseId - 1][fieldId].error = errorResult.msg;
    } else if (phaseId && activityId) {
      phaseDetailsArr[phaseId - 1].activity_details[activityId - 1][
        fieldId
      ].value = value;
      phaseDetailsArr[phaseId - 1].activity_details[activityId - 1][
        fieldId
      ].error = errorResult.msg;
    }
    this.setState({ phaseDetailsArr: phaseDetailsArr });
  };
  onHandleChangeOrBlur = (event, callback, type) => {
    this.setFormErrorsAndValue(event, callback, type);
  };

  onUploadSuceess = (fileUrl, event) => {
    let phaseDetailsArr = this.state.phaseDetailsArr.slice();
    const idArr = event.target.id.split('__');
    let fieldId = idArr[0];
    let phaseId = parseInt(idArr[1]);
    let activityId = parseInt(idArr[2]);
    phaseDetailsArr[phaseId - 1].activity_details[activityId - 1][
      fieldId
    ].value = fileUrl;
    this.setState({ phaseDetailsArr: phaseDetailsArr });
  };

  checkErrorMessage = phase_index => {
    let errorExists = false;
    let tempPhaseDetailsArr = this.state.phaseDetailsArr.slice();
    this.state.phaseDetailsArr.map((phase, p_index) => {
      if (p_index === phase_index) {
        phase.activity_details.map((activity, a_index) => {
          for (const key in activity) {
            if (key !== 'id') {
              const errorResult = this.validate(key, activity[key].value);
              if (key === 'planned_start_date' && activity[key].value !== '') {
                if (Date.parse(activity[key].value) <= new Date()) {
                  errorResult.msg = 'Start date must be greater than today';
                }
              }
              if (key === 'planned_end_date' && activity[key].value !== '') {
                if (
                  Date.parse(activity[key].value) <=
                  new Date(activity['planned_start_date'].value)
                ) {
                  errorResult.msg = 'End date must be greater than Start date';
                } else if (Date.parse(activity[key].value) <= new Date()) {
                  errorResult.msg = 'End date must be greater than today';
                }
              }
              if (errorResult.msg !== '') {
                phase.activity_details[a_index][key].error = errorResult.msg;
                if (!errorExists) errorExists = true;
              }
            }
          }
          return false;
        });
      }
      return false;
    });
    if (errorExists) this.setState({ indicatorsArr: tempPhaseDetailsArr });
    return errorExists;
  };
  saveActivity = p_index => {
    const errorExists = this.checkErrorMessage(p_index);
    if (errorExists) return false;
    const payloadObj = {
      // phase_objective: this.state.phaseDetailsArr[p_index].phase_objective
      //  .value,
      // phase: p_index + 1,
      quarter: this.state.phaseDetailsArr[p_index].quarter,
      campaign_id: this.props.id,
      activity_details: []
    };
    let activitiesArr = [];
    this.state.phaseDetailsArr[p_index].activity_details.map(
      (activity, index) => {
        const object = {
          activity: activity.activity.value,
          planned_start_date: activity.planned_start_date.value,
          planned_end_date: activity.planned_end_date.value,
          milestone: activity.milestone.value,
          attachment: activity.attachment.value
        };
        if (activity.activity.id) object['activity_id'] = activity.activity.id;
        activitiesArr.push(object);
        return false;
      }
    );
    if (this.state.deletedActivitiesArr.length > 0) {
      this.state.deletedActivitiesArr.map(activity => {
        const object = {
          activity_id: activity.id,
          activity: activity.activity.value,
          planned_start_date: activity.planned_start_date.value,
          planned_end_date: activity.planned_end_date.value,
          milestone: activity.milestone.value,
          attachment: activity.attachment.value,
          is_deleted: true
        };
        activitiesArr.push(object);
        return false;
      });
    }
    payloadObj.activity_details = [...activitiesArr];
    this.props
      .createOrModifyActivities(payloadObj)
      .then(response => {
        this.props.getActivitiesById(this.props.id);
        toast.success('Data Saved successfully!');
        this.setState({ deletedActivitiesArr: [] });
      })
      .catch(error => {
        toast.error('Uh-Oh! Looks like we are having server issues.');
        console.error(error);
      });
  };
  render() {
    let disabledBefore = null;
    let disabledAfter = null;

    if (this.state.campaignData) {
      disabledBefore = new Date(
        this.state.campaignData.actual_project_start_date
      );
      disabledAfter = new Date(this.state.campaignData.actual_project_end_date);
    }

    return (
      <div className="row p-4 create-campaign">
        <div className={`row`}>
          <div className="col-12">
            <h4 className="d-flex align-items-center mb-5 pb-4">
              <Link to={'/ngo/project/detailsOverview/' + this.props.id}>
                <i className="fas fa-angle-left fs-36 color-888686 cursor-pointer"></i>
              </Link>
              <Link to={'/ngo/project/detailsOverview/' + this.props.id}>
                <span className="d-inline-block mr-4 color-888686 font-weight-bold pl-4 cursor-pointer">
                  Back
                </span>
              </Link>
              <span className="d-inline-block font-weight-bolder">
                {this.state.campaignName !== '' ? this.state.campaignName : '-'}
              </span>
            </h4>
            <div className="d-flex justify-content-center mb-5">
              <span
                className="fs-16 position-relative project-bullet project-tab-title project-bullet-active
									project-top-border-active text-center project-top-border px-4">
                Project Details Overview
              </span>
              <span
                className="fs-16 font-weight-bold position-relative project-bullet project-bullet-active
									project-tab-title text-center project-top-border px-4">
                Project Activities Plan
              </span>
              <span className="fs-16 position-relative project-bullet project-tab-title text-center px-4">
                Project Impact Indicators
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className={`col-12 p-4 bg-white`}>
              <div className="row justify-content-between mb-4">
                <h3 className="fs-16 font-weight-bold">
                  Project Activities Plan
                </h3>
              </div>
              <hr className="section-divider w-100 mb-4" />
              {this.state.phaseDetailsArr.map((phase, phase_index) => {
                const saved = phase.id ? true : false;
                return (
                  <>
                    {phase_index > 0 && (
                      <hr className="section-divider w-100 mb-4" />
                    )}
                    <div className="row">
                      <div className="col-12 px-0">
                        <h3 className="fs-16 font-weight-bold mb-4">
                          Quarter {phase.quarter}
                        </h3>
                        {/* <InputTextArea
                          required={true}
                          labelText={'Quarter objective/goal'}
                          id={`${phase.phase_objective.idLabel}__${phase_index +
                            1}`}
                          name={`${phase.phase_objective.name}__${phase_index +
                            1}`}
                          maxLength={1500}
                          PlaceHolder={'Maximum  Length is 1500 Characters'}
                          fieldError={phase.phase_objective.error}
                          onHandleChange={this.onHandleChangeOrBlur}
                          onHandleBlur={this.onHandleChangeOrBlur}
                          fieldValue={phase.phase_objective.value}
                          className="campaign_details campaign-textarea"
                          disableInput={saved}
                          wrapperClassName="w-100"
                        /> */}
                      </div>
                    </div>
                    <div className="row  py-2">
                      <div className="col-6 pl-5 pr-2 bg-B1E0FF">
                        <span className="fs-16 font-weight-bold px-2">
                          Activities
                        </span>
                      </div>
                      <div className="col-2 px-2 bg-B1E0FF">
                        <span className="fs-16 font-weight-bold px-2">
                          Planned Start Date
                        </span>
                      </div>
                      <div className="col-2 px-2 bg-B1E0FF">
                        <span className="fs-16 font-weight-bold px-2">
                          Planned End date
                        </span>
                      </div>
                      <div className="col-1 pl-2  text-center bg-red">
                        <span className="fs-16 font-weight-bold  text-center px-2">
                          Milestone
                        </span>
                      </div>
                      <div className="col-1 pl-2 pr-0"></div>
                    </div>
                    {phase.activity_details.map((activity, a_index) => {
                      return (
                        <>
                          <div className="row" key={phase_index + a_index}>
                            <InputTextArea
                              required={true}
                              labelText={''}
                              id={`${activity.activity.idLabel}__${phase_index +
                                1}__${a_index + 1}`}
                              name={`${activity.activity.name}__${phase_index +
                                1}__${a_index + 1}`}
                              maxLength={500}
                              rows={3}
                              PlaceHolder={'Maximum  Length is 500 Characters'}
                              fieldError={activity.activity.error}
                              onHandleChange={this.onHandleChangeOrBlur}
                              onHandleBlur={this.onHandleChangeOrBlur}
                              fieldValue={activity.activity.value}
                              className="campaign_details campaign-textarea activity_border-radius"
                              disableInput={saved}
                              wrapperClassName="col-6 w-100 pl-4 pr-2 pt-3"
                            />
                            <InputDaypicker
                              required={true}
                              labelText={''}
                              id={`${
                                activity.planned_start_date.idLabel
                              }__${phase_index + 1}__${a_index + 1}`}
                              name={`${
                                activity.planned_start_date.name
                              }__${phase_index + 1}__${a_index + 1}`}
                              fieldError={activity.planned_start_date.error}
                              onHandleChange={this.onHandleChangeOrBlur}
                              onHandleBlur={this.onHandleChangeOrBlur}
                              fieldValue={activity.planned_start_date.value}
                              daysOfWeek={[]}
                              wrapperClassName="col-2 w-100 px-2 pt-3"
                              disableInput={saved}
                              className="activity_border-radius"
                              disableAfter={disabledAfter}
                              disableBefore={disabledBefore}
                            />
                            <InputDaypicker
                              required={true}
                              labelText={''}
                              id={`${
                                activity.planned_end_date.idLabel
                              }__${phase_index + 1}__${a_index + 1}`}
                              name={`${
                                activity.planned_end_date.name
                              }__${phase_index + 1}__${a_index + 1}`}
                              fieldError={activity.planned_end_date.error}
                              onHandleChange={this.onHandleChangeOrBlur}
                              onHandleBlur={this.onHandleChangeOrBlur}
                              fieldValue={activity.planned_end_date.value}
                              daysOfWeek={[]}
                              wrapperClassName="col-2 w-100 px-2 pt-3"
                              disableInput={saved}
                              className="activity_border-radius"
                              disableAfter={disabledAfter}
                              disableBefore={disabledBefore}
                            />
                            <div className="col-1 px-2 text-center ">
                              <InputSwitch
                                id={`${
                                  activity.milestone.idLabel
                                }__${phase_index + 1}__${a_index + 1}`}
                                name={`${
                                  activity.milestone.name
                                }__${phase_index + 1}__${a_index + 1}`}
                                defaultValue={activity.milestone.value}
                                value={activity.milestone.value}
                                wrapperClassName="d-inline-block mt-4"
                                onHandleChange={this.onHandleChangeOrBlur}
                                disableInput={saved}
                              />
                            </div>
                            <div className="col-1 d-flex">
                              {/* <div className="px-4 mt-4">
                                <label
                                  className="mb-0 mr-4"
                                  htmlFor={`${
                                    activity.attachment.idLabel
                                  }__${phase_index + 1}__${a_index + 1}`}>
                                  <i
                                    className="fas fa-paperclip"
                                    style={{
                                      fontSize: '24px',
                                      color: '#31AEFD'
                                    }}></i>
                                </label>
                                {activity.attachment.value && (
                                  <a
                                    href={activity.attachment.value}
                                    rel="noreferrer noopener"
                                    target="_blank">
                                    <i
                                      className="fas fa-eye"
                                      style={{
                                        fontSize: '24px',
                                        color: '#1FA4FA'
                                      }}></i>
                                  </a>
                                )}
                                <FileInput
                                  required={false}
                                  labelText={''}
                                  id={`${
                                    activity.attachment.idLabel
                                  }__${phase_index + 1}__${a_index + 1}`}
                                  placeholder="Resolution in between 600x400 and 4096x4096"
                                  name={`${
                                    activity.attachment.name
                                  }__${phase_index + 1}__${a_index + 1}`}
                                  refId={this.state.campaignId}
                                  fileSizeLimit={10}
                                  validateFile={imageResolutionValidator}
                                  value={activity.attachment.value}
                                  accept="image/*"
                                  disabled={saved}
                                  docType="report_image"
                                  className="d-none"
                                  onSuccess={this.onUploadSuceess}
                                  extraInfoAboutField={
                                    'This field is use to upload an image related to your activity'
                                  }
                                />
                              </div>*/}
                              {a_index !== 0 && !saved && (
                                <i
                                  className="fas fa-trash text-0085FF fs-19 mt-4 pt-1"
                                  onClick={() =>
                                    this.deleteActivity(phase_index, a_index)
                                  }></i>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                    {
                      /* !saved && ( */
                      <div className="row mx-4">
                        <div className="col-12 pl-0 mt-2 mb-3 d-flex">
                          <button
                            type="button"
                            onClick={() => this.addActivity(phase_index)}
                            className="btn btn-custom-outline-0193F4 btn-custom"
                            style={{ backGroundColor: '#31AEFD' }}
                            disabled={phase.activity_details.length === 5}>
                            Add Another Row
                          </button>
                          <button
                            type="button"
                            onClick={() => this.saveActivity(phase_index)}
                            className="btn btn-activityPlan btn-custom ml-3"
                            disabled={phase.activity_details.length > 5}>
                            Save
                          </button>
                        </div>
                      </div>
                      /* ) */
                    }
                  </>
                );
              })}
              <hr className="section-divider w-100" />
              <div className="row mt-5 pt-5">
                <div className="col-12 pl-0 mb-3 d-flex justify-content-center">
                  <Link to={'/ngo/project/detailsOverview/' + this.props.id}>
                    <button
                      type="button"
                      className="btn btn-custom-outline-0193F4 btn-custom">
                      Previous
                    </button>
                  </Link>
                  {this.state.isSaved && (
                    <Link to={'/ngo/project/impactIndicators/' + this.props.id}>
                      <button
                        type="button"
                        className="btn btn-activityPlan btn-custom ml-3">
                        Next
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  editSuccessResponse: state.campaign.editSuccessResponse,
  editErrorResponse: state.campaign.editErrorResponse,
  userInfo: state.login.userInfo,
  activitiesDetailsSuccessResponse: state.mne.activitiesDetailsSuccessResponse
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getCampaign, createOrModifyActivities, getActivitiesById },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectActivities);
