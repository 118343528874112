import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

class PageContent extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const mainScreen = this.props.mainScreen;
    const sideBarRight = this.props.sideBarRight;
    let ngoContainer;
    if (this.props.userInfo.user_type === 1) {
      ngoContainer = <>{mainScreen}</>;
    } else {
      ngoContainer = (
        <>
          {mainScreen}
          {sideBarRight}
        </>
      );
    }
    return <React.Fragment>{ngoContainer}</React.Fragment>;
  }
}
const mapStateToProps = state => ({
  userInfo: state.login.userInfo
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
