import React from 'react';
import PageContent from './pageContent';
import PageContentWrapper from './pageContentWrapper';
import Header from '../components/header';
import AuthorizedAccessOnly from '../assets/icons/authorized-access-only.svg';
const Unauthorized = props => {
  const getComponent = () => {
    return (
      <div className="loader-wrapper">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={AuthorizedAccessOnly}
            width="142px"
            height="142px"
            alt="Authorized access only"
            className="mb-4"
          />
          <h4 className="text-center text-1B1C1D my-4 fw-400">
            Only authorised profiles can access this page
          </h4>
          <p className="text-center text-626262 fs-14 w-75">
            It seems that you are not authorised to view this page. The content
            you are trying to access is restricted and requires proper
            authorisation.
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header pageTitle={'Unauthorized access'} />
      <PageContentWrapper>
        <PageContent mainScreen={getComponent()} />
      </PageContentWrapper>
    </>
  );
};

export default Unauthorized;
