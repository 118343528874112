import { Action } from '../constants';
const initialState = {
  ngoProfile: '',
  ngoDashboardData: '',
  ngoComments: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.NGO_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.NGO_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        ngoProfile: action.payload
      };
    case Action.NGO_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        ngoDashboardData: action.payload
      };
    case Action.NGO_PROFILE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case Action.NGO_COMMENTS:
      return {
        ...state,
        loading: false,
        ngoComments: action.payload
      };
    default:
      return state;
  }
};
