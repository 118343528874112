/* global jQuery */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps*/
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { forEach } from 'lodash';
import React, { useEffect, useRef } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  getCorpEmail,
  shareCampaign
} from '../../actions/completeRegistration.action';
import {
  getActivitiesById,
  getProjectExpensesById,
  getGoalsIndicators,
  getExpenseCategory
} from '../../actions';
import { EndPoint } from '../../constants';
// import { Common } from '../../constants/common.constants';
import { sendMail } from '../../helpers/emailSender';
import HttpRequest from '../../helpers/http-request';
import { getCloudinaryUrlForImage } from '../../helpers/util';
import CampaignService from '../../services/campaign.service';
import Loader from '../loader';
import Modal from '../newDesign/Modals/Modal';
import Back from '../../assets/icons/arrow-back.svg';
import docIcon from '../../assets/images/doc.png';
import pdfIcon from '../../assets/images/pdf.png';
import ProposedProject from './proposedProject';
import dumy_logo from '../../assets/icons/NgoLogo.png';
dayjs.extend(customParseFormat);

function MultiSelect(props) {
  const currentRef = useRef();
  const currentVal = useRef();
  const handleChange = () => {
    const newVal = jQuery(currentRef.current).val();
    currentVal.current = newVal;
  };
  useEffect(() => {
    jQuery(currentRef.current).select2({
      width: '100%',
      height: '36px',
      tags: false,
      placeholder: 'Please Select',
      allowClear: false
    });
    jQuery(currentRef.current).on('change', handleChange);
    return () => {
      jQuery(currentRef.current).off('change', handleChange);
    };
  }, [props]);

  // let obj = {
  //   id: item.id,
  //   milestone: item.milestone,
  //   quarter: item.quarter,
  //   phase: item.phase_id,
  //   activity: item.activity,
  //   actual_start_date: item.actual_start_date,
  //   actual_end_date: item.actual_end_date,
  //   status: item.status === 'Yet To Start' ? 'Upcoming' : item.status,
  //   delayedByDays: days,
  //   attachment: item.attachment
  // };
  // activityArr.push(obj);

  return (
    <>
      <label className="control-label" htmlFor="corporatesList">
        Select Corporates *
      </label>
      <select
        ref={currentRef}
        className="form-control"
        id="corporate"
        multiple="true"
        name="corporateId">
        {props.state.listOfCorporates.map(corp => (
          <option value={`${corp.email}`} key={corp.id}>
            {corp.label}
          </option>
        ))}
      </select>
    </>
  );
}
class CampaignDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignDetailReceived: false,
      detailErrorResponse: false,
      expenseCategoryList: [],
      campaignId: null,
      projectFinances: [],
      projectIndicators: [],
      projectActivities: [],
      campaignDetails: {
        image: '',
        ngoName: '',
        ngoEmail: '',
        title: '',
        fund_amount: '',
        campaignEndDate: '',
        no_of_beneficiaries: '',
        target_beneficiary: '',
        duration: '',
        status: '',
        displayStatus: '',
        cause_category: '',
        sub_cause_category: '',
        details: '',
        supported_by: '',
        location: '',
        city: '',
        state: '',
        country: '',
        cause_category_slug: '',
        video: '',
        proposal_doc: '',
        needs_assessment: '',
        impact: '',
        objective: '',
        projectStartDate: '',
        projectEndDate: '',
        campaignStartDate: '',
        project_summary: '',
        project_goal: '',
        project_objective: '',
        sub_cause_category_slug: '',
        primary_beneficiary_slug: '',
        cause_area: '',
        sub_cause_area: '',
        campaign_type_id: '',
        image_url: '',
        problem_statement: '',
        nature_of_solution: '',
        is_new_project: '',
        project_period: '',
        beneficiary_cost: '',
        survey_document: '',
        methodology: '',
        measurable_outcome: '',
        monitoring_and_reporting: '',
        project_sustainability: '',
        budget: '',
        locations: '',
        project_address: '',
        reject_reason: '',
        sdg: '',
        sdg_slug: [],
        sdg_icon: [],
        ngoLogo: ''
      },
      listOfCorporates: [],
      showShareModal: false,
      showModal: false,
      filePreview: null,
      fileType: null,
      docFile: null
    };
    this.fileName = '';
    this.File = '';
    this.dateDiff = '';
  }
  setCampaignDetail = campaignInfo => {
    let campaignDetails = this.state.campaignDetails;
    forEach(campaignDetails, (obj, key) => {
      campaignDetails[key] = campaignInfo[key] || '';
    });
    this.setState({
      ...this.state,
      campaignDetails: campaignDetails
    });
  };

  getListOfCorporates = () => {
    let corp = [];
    let instance = HttpRequest.getInstance('HIROKU');
    instance
      .get(EndPoint.getApiUrl('NGO', 'GET_ALL_CORPORATES', 'HIROKU'))
      .then(resp => {
        for (let i = 0; i < resp.data.length; i++) {
          corp.push({
            email: resp.data[i].email,
            value: resp.data[i].name,
            label: resp.data[i].name,
            id: resp.data[i].id
          });
        }
        this.setState({
          listOfCorporates: corp
        });
      });
  };

  componentDidMount() {
    this.setState({
      projectFinances: [],
      projectIndicators: [],
      projectActivities: []
    });
    if (this.props.campaignId) {
      CampaignService.getCampaign(this.props.campaignId, 'detail')
        .then(response => {
          this.setState({ campaignDetailReceived: true });
          if (
            response.data.code === '200' &&
            response.data.result.campaign_id
          ) {
            this.setCampaignDetail(response.data.result);
            this.setState({
              detailErrorResponse: false
            });
          } else {
            this.setState({
              detailErrorResponse: true
            });
          }
        })
        .catch(() => {
          this.setState({
            detailErrorResponse: true,
            campaignDetailReceived: true
          });
        });
      this.getListOfCorporates();
      this.getExpenseCategory();
      this.props.getActivitiesById(this.props.campaignId);
      this.props.getProjectExpensesById(this.props.campaignId);
      this.props.getGoalsIndicators(this.props.campaignId);
    } else {
      this.setState({
        showCampaignDetail: false,
        detailErrorResponse: true,
        campaignDetailReceived: true
      });
    }
  }

  getExpenseCategory() {
    this.props
      .getExpenseCategory()
      .then(res => {
        this.setState({ expenseCategoryList: res.result });
      })
      .catch(async err => {});
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.activitiesDetailsSuccessResponse) {
      let projectActivities = nextprops.activitiesDetailsSuccessResponse;
      this.setState({ projectActivities: projectActivities });
    }
    if (nextprops.financeDetailsSuccessResponse?.project_proposed_budget_list) {
      let projectFinances =
        nextprops.financeDetailsSuccessResponse?.project_proposed_budget_list;
      this.setState({ projectFinances: projectFinances });
    }
    if (nextprops.indicatorsDetailsSuccessResponse) {
      let projectIndicators = nextprops.indicatorsDetailsSuccessResponse;
      this.setState({ projectIndicators: projectIndicators });
    }
  }

  getStatusByCode(statusCode) {
    let status = '';
    status = statusCode
      ? statusCode.toLocaleLowerCase().replace(/_/gi, ' ')
      : '';
    status = status ? status[0].toUpperCase() + status.slice(1) : '';
    return status;
  }

  shareCampaign = async e => {
    e.persist();
    e.preventDefault();
    let object = {};
    new FormData(e.target).forEach(function(value, key) {
      if (!Reflect.has(object, key)) {
        if (key === 'corporateId') {
          object[key] = [];
          object[key].push(value);
          return;
        }
        object[key] = value;
        return;
      }
      if (!Array.isArray(object[key])) {
        object[key] = [object[key]];
      }
      object[key].push(value);
    });
    // let data = { ids: object.corporateId };
    object.corporateId.forEach(email => {
      sendMail({
        templateName: 'shareCampaign',
        sendTo: email,
        moduleName: 'mettasocial-platform',
        subject: `Check out ${object.campaignName} Project ${object.ngoName} that shared with you.`,
        templateData: {
          campaignName: object.campaignName,
          ngoName: object.ngoName,
          proposalDoc: object.docUrl,
          linkText: 'View Project',
          link: `https://${process.env.REACT_APP_ENV}corp.mettasocial.com/guest/campaignDetail/${object.campaignId}`
        }
      });
    });
    e.target.reset();
    toast.success('Project Shared Successfully', {
      autoClose: 2000,
      toastId: 'share_campaign_modal'
    });
  };
  handleOpenShareModal = () => {
    this.setState({ showShareModal: true });
  };
  handleCloseShareModal = e => {
    e.preventDefault();
    this.setState({ showShareModal: false });
  };
  renderShareModal = () => (
    <Modal
      show={this.state.showShareModal}
      title={`Share ${this.state.campaignDetails.title} with corporate`}
      handleCloseModal={this.handleCloseShareModal}
      sizeClass="modal-lg">
      <div className="row">
        <form className="col-12" onSubmit={this.shareCampaign}>
          <input
            type="hidden"
            name="campaignId"
            value={`${this.props.campaignId}`}
          />
          <input
            type="hidden"
            name="campaignName"
            value={`${this.state.campaignDetails.title}`}
          />
          <input
            type="hidden"
            name="ngoName"
            value={`${this.state.campaignDetails.ngoName}`}
          />
          <div className="form-group">
            <MultiSelect
              uniqueIdentifierForInput={'corp_list'}
              state={this.state}
            />
          </div>
          {/* <div className="form-group">
            <FileInput
              id="proposalDoc"
              label="Select proposal document"
              name="docUrl"
              accept="application/pdf,application/vnd.ms-powerpoint,image/*,video/*"
              refId={this.props.campaignId}
              placeholder="Please select a project proposal document"
              required
            />
          </div> */}
          <div className="row justify-content-end">
            <button
              type="button"
              className="btn btn-danger mr-2"
              data-dismiss="modal">
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Share
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );

  calculateDays = (campaignStartDate, campaignEndDate) => {
    let date_obj1 = dayjs(campaignStartDate);
    let date_obj2 = dayjs(campaignEndDate);
    return (this.dateDiff = date_obj2.diff(date_obj1, 'days') + ' Days');
  };

  createMarkup(value) {
    return { __html: value };
  }

  getFileType = url => {
    const value = this.getFileName(url);
    if (
      value.includes('doc') ||
      value.includes('DOC') ||
      value.includes('docx') ||
      value.includes('DOCX') ||
      value.includes('xls') ||
      value.includes('XLS') ||
      value.includes('xlsx') ||
      value.includes('XLSX')
    ) {
      return 'doc';
    } else if (value.includes('pdf') || value.includes('PDF')) {
      return 'pdf';
    }
  };

  getFileName = url => {
    if (url.split('/')[5] === 'undefined' || !url.split('/')[5].includes('.')) {
      return url.split('/')[7];
    } else {
      return url.split('/')[5];
    }
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      filePreview: null,
      fileType: null,
      docFile: null
    });
    let body = document.body;
    body.style.overflow = 'auto';
  };

  showFilePreview(url) {
    const fileType = this.getFileType(url);
    this.setState({
      showModal: true,
      filePreview: url
    });
    if (
      fileType === 'doc' ||
      fileType === 'DOC' ||
      fileType === 'docx' ||
      fileType === 'DOCX' ||
      fileType === 'xls' ||
      fileType === 'XLS' ||
      fileType === 'xlsx' ||
      fileType === 'XLSX'
    ) {
      this.setState({
        docFile: process.env.REACT_APP_DOC_FILE_PREVIEW_URL + url,
        fileType: 'doc'
      });
    }
    let body = document.body;
    body.style.overflow = 'hidden';
  }

  render() {
    const {
      // duration,
      campaignStartDate,
      campaignEndDate
      // projectStartDate,
      // projectEndDate
    } = this.state.campaignDetails;
    this.calculateDays(campaignStartDate, campaignEndDate);
    if (this.props.isNgoApproved === false) {
      toast.error('Please complete your profile first');
      return <Redirect to="/ngo/profile" />;
    }
    if (!this.state.campaignDetailReceived) {
      return <Loader loaderText="Loading.." />;
    }
    if (this.state.detailErrorResponse) {
      return (
        <Loader loaderText="Uh-Oh! Looks like we are having server issues...." />
      );
    }
    return (
      <>
        {this.state.showShareModal && this.renderShareModal()}
        <div className="col-12 p-4 campaign-details">
          <Link
            className="ml-2 fs-14 mb-3  text-decoration-none"
            to={
              window.location.href.includes('#mne')
                ? `/ngo/mneSingleProject/${this.props.campaignId}`
                : window.location.href.includes('email')
                ? `/ngo/project/list?email=` +
                  window.location.href.split('email=')[1]
                : `/ngo/project/list`
            }>
            <img src={Back} alt="back" style={{ marginTop: '-5px' }} />
            <span className="color-888686 fs-18 mt-3">{` Back`}</span>
          </Link>
          <div className="row pl-2 pr-2 mt-4">
            <div className="col-10 bg-white card-box-shadow p-0 mb-5 rounded-8 mx-auto">
              <div className="row rounded position-relative bg-white rounded-8">
                <div className="img-16x9 position-relative rounded-top-8 loading-bg">
                  {!this.state.campaignDetails.video && (
                    <img
                      src={
                        getCloudinaryUrlForImage(
                          this.state.campaignDetails.image_url,
                          500
                        ) ||
                        getCloudinaryUrlForImage(
                          'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                          500
                        )
                      }
                      data-src={
                        this.state.campaignDetails.image ||
                        'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                      }
                      onError={e =>
                        (e.target.src = e.target.getAttribute('data-src'))
                      }
                      alt={this.state.campaignDetails.title}
                      className="abs-full rounded-top-8"
                    />
                  )}
                  {this.state.campaignDetails.video && (
                    <video
                      src={this.state.campaignDetails.video}
                      className="abs-full rounded-top-8"
                      controls
                      muted
                      poster={
                        getCloudinaryUrlForImage(
                          this.state.campaignDetails.image,
                          500
                        ) ||
                        getCloudinaryUrlForImage(
                          'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                          500
                        )
                      }
                      autoPlay></video>
                  )}
                </div>
                {/* <button
                  className="btn btn-link bg-dark abs-top-right text-white rounded-cross-nw mblm-hl"
                  title="Share with corporates"
                  data-toggle="modal"
                  data-target="#shareModal"
                  onClick={this.handleOpenShareModal}>
                  Share <i className="fas fa-share"></i>
                </button> */}
                <div className="p-4 row mt-3 ">
                  <p className="h1 col-12 mb-4 p-0 d-flex justify-content-between align-items-center ml-3">
                    <span className="h2 row pr-3 mt-4 text-bold text-1C1C1C">
                      {this.state.campaignDetails.title}
                    </span>
                    {this.state.campaignDetails.reject_reason && (
                      <span className="color-FF2300 bg-FFE8E4 fs-16 6 px-3 py-2 h-30 rounded-b-3">
                        Rejected
                      </span>
                    )}
                    {this.state.campaignDetails.status ===
                      'WAITING FOR FUNDING' && (
                      <span
                        className="fs-16 px-3 py-2 h-35 w-45 text-center rounded-b-3 position-relative"
                        style={{
                          color: '#21BA45',
                          backgroundColor: '#DEF7E4',
                          width: '184px',
                          right: '23px'
                        }}>
                        AWAITING FUNDS
                      </span>
                    )}
                  </p>
                  <div className="font-weight-bold text-344256 fs-14 row ml-3">
                    {this.state.campaignDetails.ngoLogo &&
                    this.state.campaignDetails.ngoLogo ? (
                      <img
                        src={this.state.campaignDetails.ngoLogo}
                        height={43}
                        width={43}
                        className="img-logo mr-2"
                        alt=""
                      />
                    ) : (
                      <img
                        src={dumy_logo}
                        height={43}
                        width={43}
                        className="img-logo mr-2 hello"
                        alt=""
                      />
                    )}
                    <span className="mt-3">
                      {' '}
                      {this.state.campaignDetails.ngoName}{' '}
                    </span>
                  </div>

                  {this.state.campaignDetails.reject_reason && (
                    <div className="pb-4 pt-4">
                      <span className="fs-14 pb-2 text-969696">
                        Reason for rejection
                      </span>
                      <p className="fs-14 bg-FFE8E4 px-4 py-3 rounded-b-3">
                        {this.state.campaignDetails.reject_reason}
                      </p>
                    </div>
                  )}

                  {/* <div className="row"> */}
                  <span className="d-block text-969696 fs-14 ml-4 mt-4 mb-2">
                    Location
                  </span>
                  <span className="w-100 pr-3 mx-4 row">
                    {this.state.campaignDetails.locations &&
                    this.state.campaignDetails.locations.length > 0 ? (
                      this.state.campaignDetails.locations.map(
                        (location, key) => {
                          return (
                            <div
                              className="w-405 label-pastel mr-3 d-inline-block"
                              key={location.pincode}>
                              <p className="mt-2 word-break">
                                {location.address}
                              </p>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <p></p>
                    )}
                  </span>
                  {/* </div> */}
                  {/* //tabs code */}
                  <div className="row">
                    <div className="col-12 px-2">
                      <ProposedProject
                        financeList={this.state.projectFinances}
                        indicatorsList={this.state.projectIndicators}
                        activityList={this.state.projectActivities}
                        fundAmount={this.state.campaignDetails.fund_amount}
                        expenseCategoryList={this.state.expenseCategoryList}
                        viewType={'campDetails-detail'}
                      />
                    </div>
                  </div>
                  {/* <div className="row m-2">
                    {this.state.campaignDetails.locations &&
                    this.state.campaignDetails.locations.length > 0 ? (
                      this.state.campaignDetails.locations.map(
                        (location, key) => {
                          return (
                            <div
                              className="col-md-4 m-2 label-pastel"
                              key={location.pincode}>
                              <p className="mt-2">{"location.address"}</p>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <p></p>
                    )}
                  </div> */}
                  <div id="campDetails-detail" className="w-100">
                    <div className="values-container p-4 mx-3 mb-4">
                      <div className="d-flex flex-wrap">
                        <span className="w-33 pr-3 mb-3">
                          <span className="d-block mb-2 fs-14 text-969696">
                            Primary Cause Category
                          </span>
                          {/* {this.state.campaignDetails.cause_category_slug &&
                          this.state.campaignDetails.cause_category_slug
                            .length > 0 ? (
                            this.state.campaignDetails.cause_category_slug.map(
                              (cause, index) => {
                                return (
                                  <span
                                    key={index + cause}
                                    className="label-blue mr-3 mb-2 d-inline-block">
                                    {cause}
                                  </span>
                                );
                              }
                            )
                          ) : (
                            <span>{String.fromCharCode('0x2014')}</span>
                          )}  */}

                          {this.state.campaignDetails.cause_category_slug
                            ?.length > 0 && (
                            <label className="bg-E0F3FF label-blue  fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 ">
                              {
                                this.state.campaignDetails
                                  .cause_category_slug[0]
                              }
                            </label>
                          )}
                          {this.state.campaignDetails.cause_category_slug
                            .length > 1 ? (
                            <span className="tooltipss-campagin  text-0085FF">
                              {' '}
                              {`+ ${this.state.campaignDetails
                                .cause_category_slug.length - 1} More`}
                              <span className="tooltipss-sdg-text">
                                {this.state.campaignDetails.cause_category_slug
                                  .slice(1)
                                  .join(', ')}
                              </span>
                            </span>
                          ) : null}
                        </span>
                        <span className="w-33 pr-3 mb-3">
                          <span className="d-block mb-2 fs-14 text-969696">
                            Secondary Cause Category
                          </span>
                          {/* {this.state.campaignDetails.sub_cause_category_slug &&
                          this.state.campaignDetails.sub_cause_category_slug
                            .length > 0 ? (
                            this.state.campaignDetails.sub_cause_category_slug.map(
                              (cause, index) => {
                                return (
                                  <span
                                    key={index + cause}
                                    className="label-blue mr-3 mb-2 d-inline-block">
                                    {cause}
                                  </span>
                                );
                              }
                            )
                          ) : (
                            <span>{String.fromCharCode('0x2014')}</span>
                          )} */}
                          {this.state.campaignDetails.sub_cause_category_slug
                            ?.length > 0 && (
                            <label className="bg-E0F3FF label-blue fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 ">
                              {
                                this.state.campaignDetails
                                  .sub_cause_category_slug[0]
                              }
                            </label>
                          )}
                          {this.state.campaignDetails.cause_category_slug
                            .length > 1 ? (
                            <span className="tooltipss-campagin  text-0085FF">
                              {' '}
                              {`+ ${this.state.campaignDetails
                                .sub_cause_category_slug.length - 1} More`}
                              <span className="tooltipss-sdg-text">
                                {this.state.campaignDetails.sub_cause_category_slug
                                  .slice(1)
                                  .join(', ')}
                              </span>
                            </span>
                          ) : null}
                        </span>
                        <span className="w-33 mb-3">
                          <span className="d-block mb-1 fs-14 text-969696">
                            Requested Funding Amount
                          </span>
                          <span className="d-block font-weight-bold fs-15">
                            ₹{' '}
                            {new Intl.NumberFormat('en-IN').format(
                              this.state.campaignDetails.fund_amount
                            ) || String.fromCharCode('0x2014')}
                          </span>
                        </span>
                        <span className="w-33 pr-3 mb-3">
                          <span className="d-block mb-2 fs-14 text-969696">
                            Target Beneficiaries
                          </span>
                          {/* {this.state.campaignDetails
                            .primary_beneficiary_slug &&
                          this.state.campaignDetails.primary_beneficiary_slug
                            .length > 0 ? (
                            this.state.campaignDetails.primary_beneficiary_slug.map(
                              (beneficiary, index) => {
                                return (
                                  <span
                                    key={index + beneficiary}
                                    className="label-blue mr-3 mb-2 d-inline-block">
                                    {beneficiary}
                                  </span>
                                );
                              }
                            )
                          ) : (
                            <span>{String.fromCharCode('0x2014')}</span>
                          )} */}
                          {this.state.campaignDetails.primary_beneficiary_slug
                            ?.length > 0 && (
                            <label className="bg-E0F3FF label-blue fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 ">
                              {
                                this.state.campaignDetails
                                  .primary_beneficiary_slug[0]
                              }
                            </label>
                          )}
                          {this.state.campaignDetails.primary_beneficiary_slug
                            .length > 1 ? (
                            <span className="tooltipss-campagin  text-0085FF">
                              {' '}
                              {`+ ${this.state.campaignDetails
                                .primary_beneficiary_slug.length - 1} More`}
                              <span className="tooltipss-sdg-text">
                                {this.state.campaignDetails.primary_beneficiary_slug
                                  .slice(1)
                                  .join(', ')}
                              </span>
                            </span>
                          ) : null}
                        </span>
                        <span className="w-33 mb-3">
                          <span className="d-block mb-1 fs-14 text-969696">
                            Number of Beneficiaries
                          </span>
                          <span className="d-block font-weight-bold fs-15">
                            {new Intl.NumberFormat('en-IN').format(
                              this.state.campaignDetails.no_of_beneficiaries
                            ) || String.fromCharCode('0x2014')}
                          </span>
                        </span>
                        <span className="w-33 pr-3 mb-3">
                          <span className="d-block mb-2 fs-14 text-969696">
                            Cost per Beneficiary
                          </span>
                          <span className="d-block font-weight-bold fs-15">
                            ₹{' '}
                            {new Intl.NumberFormat('en-IN').format(
                              (
                                this.state.campaignDetails.fund_amount /
                                this.state.campaignDetails.no_of_beneficiaries
                              ).toFixed(2)
                            ) || String.fromCharCode('0x2014')}
                          </span>
                        </span>

                        <span className="w-33 mb-3">
                          <span className="d-block mb-1 fs-14 text-969696">
                            Proposed Period of the Project
                          </span>
                          <span className="d-block font-weight-bold fs-15">
                            {this.state.campaignDetails.project_period ||
                              String.fromCharCode('0x2014')}{' '}
                            {this.state.campaignDetails.project_period
                              ? 'Months'
                              : ''}
                          </span>
                        </span>
                        <span className="w-33 mb-3">
                          <span className="d-block mb-1 fs-14 text-969696">
                            Sustainable Development Goals (SDGs)
                          </span>
                          {this.state.campaignDetails.sdg &&
                          this.state.campaignDetails.sdg_icon.length > 0 ? (
                            this.state.campaignDetails.sdg_icon.map(
                              (sdg, index) => {
                                return (
                                  <span
                                    key={index + sdg}
                                    className="mr-3 mb-2 d-inline-block tooltipss-campagin">
                                    <img
                                      height={35}
                                      width={35}
                                      className=""
                                      src={sdg.icon}
                                      alt="SDG's Icon "
                                    />
                                    <span className="tooltipss-sdg-text">
                                      {sdg.name}
                                    </span>
                                    {/* <label className="bg-E0F3FF label-blue fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 ">
                                      {sdg}
                                    </label> */}
                                  </span>
                                );
                              }
                            )
                          ) : (
                            <span>{String.fromCharCode('0x2014')}</span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 p-0 mx-4 text-1C1C1C ">
                      {/* <div className="col-12 mb-3"> */}
                      {/* <dd className="h5 mt-4 prompt-regular fs-14 text-344256"> */}
                      {this.state.campaignDetails.is_new_project ? (
                        <label className="bg-E0F3FF label-blue  fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 mb-2">
                          This is a new project which is being executed for the
                          first time
                        </label>
                      ) : (
                        <label className="bg-E0F3FF label-blue  fs-12 text-005492 pl-3 pr-3 border-radius-3 h-30 mr-2 mb-2">
                          We have already implemented this project in the past
                        </label>
                      )}
                      {/* </dd> */}
                      {/* </div> */}
                      <h4 className="font-weight-bold mb-4 mt-2">
                        Project Goal
                      </h4>
                      {this.state.campaignDetails.project_goal ? (
                        <div
                          className="fs-14 mb-5 word-break w-90 text-1B1C1D w-90"
                          dangerouslySetInnerHTML={this.createMarkup(
                            this.state.campaignDetails.project_goal
                          )}></div>
                      ) : (
                        <span>{String.fromCharCode('0x2014')}</span>
                      )}
                      <h4 className="font-weight-bold mb-4">
                        Problem Objective
                      </h4>
                      {this.state.campaignDetails.project_objective ? (
                        <p className="fs-14 mb-5 word-break w-90 text-1B1C1D">
                          {this.state.campaignDetails.project_objective}
                        </p>
                      ) : (
                        <span className="d-block mb-5 pb-2 text-1B1C1D">
                          {String.fromCharCode('0x2014')}
                        </span>
                      )}
                      <h4 className="font-weight-bold mb-4">
                        Project Synopsis
                      </h4>
                      {this.state.campaignDetails.project_summary ? (
                        <div
                          className="fs-14 overflow-hidden word-break w-90 project_synopsis text-1B1C1D"
                          dangerouslySetInnerHTML={this.createMarkup(
                            this.state.campaignDetails.project_summary
                          )}></div>
                      ) : (
                        <span>{String.fromCharCode('0x2014')}</span>
                      )}
                    </div>
                    <div className="col-12  bg-white p-0 mb-5 rounded-8 mx-auto text-1C1C1C">
                      <div className="pl-3 row">
                        <div className="col-12 pl-1 mt-4">
                          <h4 className="font-weight-bold mb-4">
                            Problem Statement
                          </h4>
                          {this.state.campaignDetails.problem_statement ? (
                            <p className="fs-14 mb-5 word-break w-90 text-1B1C1D">
                              {this.state.campaignDetails.problem_statement}
                            </p>
                          ) : (
                            <span className="d-block mb-5 pb-2 text-1B1C1D">
                              {String.fromCharCode('0x2014')}
                            </span>
                          )}
                          <h4 className="font-weight-bold mb-4">
                            Models or ways to address the problem
                          </h4>
                          {this.state.campaignDetails.nature_of_solution ? (
                            <div
                              className="fs-14 mb-5 overflow-hidden project_synopsis text-1B1C1D"
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.campaignDetails.nature_of_solution
                              )}></div>
                          ) : (
                            <span className="d-block mb-5 pb-2 text-1B1C1D">
                              {String.fromCharCode('0x2014')}
                            </span>
                          )}

                          {/* <h4 className="font-weight-bold mb-4">
                            Project Execution
                          </h4>
                          {this.state.campaignDetails.is_new_project ? (
                            <div className="fs-14 mb-5">
                              This is a new project which is being executed for
                              the first time
                            </div>
                          ) : (
                            <div className="fs-14 mb-5">
                              We have already implemented this project in the
                              past
                            </div>
                          )} */}

                          {/* <h4 className="font-weight-bold mb-4">
                            Proposed Period of the project in months{' '}
                          </h4>
                          {this.state.campaignDetails.project_period ? (
                            <div
                              className="fs-14 mb-5"
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.campaignDetails.project_period
                              )}></div>
                          ) : (
                            <span className="d-block mb-5 pb-2">
                              {String.fromCharCode('0x2014')}
                            </span>
                          )}
                          {/* <h4 className="font-weight-bold mb-4">
                            Cost per Beneficiary (Project budget divided by
                            number of beneficiaries)
                          </h4>
                          {this.state.campaignDetails.beneficiary_cost ? (
                            <div
                              className="fs-14 mb-5"
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.campaignDetails.beneficiary_cost
                              )}></div>
                          ) : (
                            <span className="d-block mb-5 pb-2">
                              {String.fromCharCode('0x2014')}
                            </span>
                          )} */}
                        </div>
                        <hr className="separator-E0E0E0" />
                        <div className="col-12 p-0 mt-3">
                          <div className="d-flex">
                            <span className="w-33 pr-3 mb-3">
                              <span className="d-block mb-2 fs-18">
                                Baseline Survey Document
                              </span>
                              <span className="d-flex align-items-center">
                                {this.state.campaignDetails.survey_document ? (
                                  <>
                                    <img
                                      src={
                                        this.getFileType(
                                          this.state.campaignDetails
                                            .survey_document
                                        ) === 'doc'
                                          ? docIcon
                                          : pdfIcon
                                      }
                                      className="mr-2"
                                      alt="fileName"
                                      width={15}
                                      title="Need assessment file"
                                    />
                                    <span
                                      className="fs-14 color-1FA4FA cursor-pointer"
                                      onClick={() =>
                                        this.showFilePreview(
                                          this.state.campaignDetails
                                            .survey_document
                                        )
                                      }>
                                      {this.getFileName(
                                        this.state.campaignDetails
                                          .survey_document
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  String.fromCharCode('0x2014')
                                )}
                              </span>
                            </span>
                            <span className="w-33 pr-3 mb-3">
                              <span className="d-block mb-2 fs-18">
                                Needs Assessment Report
                              </span>
                              <span className="d-flex align-items-center">
                                {this.state.campaignDetails.needs_assessment ? (
                                  <>
                                    <img
                                      src={
                                        this.getFileType(
                                          this.state.campaignDetails
                                            .needs_assessment
                                        ) === 'doc'
                                          ? docIcon
                                          : pdfIcon
                                      }
                                      className="mr-2"
                                      alt="fileName"
                                      width={15}
                                      title="Need assessment file"
                                    />
                                    <span
                                      className="fs-14 color-1FA4FA cursor-pointer"
                                      onClick={() =>
                                        this.showFilePreview(
                                          this.state.campaignDetails
                                            .needs_assessment
                                        )
                                      }>
                                      {this.getFileName(
                                        this.state.campaignDetails
                                          .needs_assessment
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  String.fromCharCode('0x2014')
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* //tabs code */}
          {/* <div>

            <span>
              <ProposedProject activityList={this.state.activityList} />
            </span>
          </div> */}
        </div>
        <Modal
          show={this.state.showModal}
          title={'File Preview'}
          handleCloseModal={this.handleCloseModal}
          sizeClass={'modal-lg'}>
          <div>
            {this.state.fileType !== 'image' && this.state.fileType !== 'zip' && (
              <div style={{ height: '100vh' }}>
                <iframe
                  src={
                    this.state.fileType === 'doc'
                      ? this.state.docFile
                      : this.state.filePreview
                  }
                  title="file"
                  width="100%"
                  height="95%"></iframe>
              </div>
            )}
            {this.state.fileType === 'zip' && (
              <div style={{ height: '100vh', textAlign: 'center' }}>
                <a
                  href={this.state.fileUrlValue}
                  download
                  className="text-center">
                  <img
                    src={this.state.zipIcon}
                    alt="fileName"
                    width={200}
                    title={this.state.fileName}></img>
                  <div className="fs-16">Download zip files</div>
                </a>
              </div>
            )}
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => ({
  userInfo: state.login.userInfo,
  financeDetailsSuccessResponse: state.mne.financeDetailsSuccessResponse,
  activitiesDetailsSuccessResponse: state.mne.activitiesDetailsSuccessResponse,
  indicatorsDetailsSuccessResponse: state.mne.indicatorsDetailsSuccessResponse
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      shareCampaign,
      getCorpEmail,
      getActivitiesById,
      getProjectExpensesById,
      getGoalsIndicators,
      getExpenseCategory
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
