import React, { useEffect, useState } from 'react';
import {
  legalStructure,
  // conditionalAssociationDetails,
  EndPoint
} from '../../constants';
import HttpRequest from '../../helpers/http-request';
import underReviewGreenIcon from '../../assets/icons/underReviewGreenIcon.svg';
export default function NgoConditionalApprovedDetailPage(props) {
  const [
    conditionalAssociationDetails,
    setConditionalAssociationDetails
  ] = useState([]);
  const [corpArr, setCorpArr] = useState([]);
  const ngoSavedData = props?.state?.register?.ngoSavedData;

  useEffect(() => {
    props.getSavedNGOData(props?.state?.login?.userInfo?.sub);
  }, []);

  const legalStructureValue = legalStructure.find(
    x => x.id === ngoSavedData?.legal_compliance?.legal_structure
  );
  let ngoId = props.state.ngo.ngoProfile.ngo_id;

  useEffect(() => {
    if (ngoId) {
      let instance = HttpRequest.getInstance('HIROKU');
      instance
        .get(
          EndPoint.getApiUrl(
            'NGO',
            'ADMIN_ASSOCIATION_DETAILS',
            'HIROKU'
          ).replace('{ngo_id}', ngoId)
        )
        .then(res => {
          setConditionalAssociationDetails(res.data.result);
        });

      // .then(res)=> console.log(res)
    }
  }, [ngoId]);
  useEffect(() => {
    const data = conditionalAssociationDetails.filter(
      x => x.status_name === 'Approved NGO'
    );
    if (data) {
      let obj = {};
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let cur = data[i];
        if (obj[cur['corp_id']] === undefined) {
          obj[cur['corp_id']] = 1;
          let curr = cur;
          curr['product_name'] = [`${curr['product_name']}`];
          arr.push(curr);
        } else {
          obj[cur['corp_id']] += 1;
          let curr = cur;
          let X = arr.filter((el, i) => {
            return el['corp_id'] === cur['corp_id'];
          });
          X[0]['product_name'].push(`${curr['product_name']}`);
        }
      }
      setCorpArr(arr);
    }
  }, [conditionalAssociationDetails]);

  //   const uniqueStatusData = conditionalAssociationDetails.filter(
  //     (obj, index, self) =>
  //       self.findIndex((t) => t.status_name === obj.status_name) === index
  //   );

  return (
    // <>
    // <h1>ngo table</h1>
    // </>
    <div className="overflow-scroll mx-5">
      {/* Conditional Ngo Details STARTS */}
      <div className=" row p-4 rounded mt-4 bg-white ">
        <div className="col-9 pt-4">
          <div className="col-9 mx-auto">
            <div className="mb-3 ">
              <p className="fs-16 font-weight-bold ml-3"> NGO Details</p>
              {/* <p className="fse-16 ml-3 my-5"> NGO Details</p> */}
              <div className=" fs-14">
                <div className="row my-3">
                  <span className="text-muted col-4">NGO Name: </span>

                  <span className="col-8">
                    {ngoSavedData?.existence?.ngo_name
                      ? ngoSavedData?.existence?.ngo_name
                      : '--'}
                  </span>
                </div>
                <div className="row my-3">
                  <span className="text-muted col-4">Registration Type: </span>{' '}
                  <span className="col-8">
                    {ngoSavedData?.legal_compliance?.legal_structure ===
                    legalStructureValue?.id
                      ? legalStructureValue?.name
                      : '--'}
                  </span>
                </div>{' '}
                <div className="row my-3">
                  <span className="text-muted col-4">Registration Number:</span>
                  <span className="col-8">
                    {' '}
                    {ngoSavedData?.legal_compliance?.legal_structure !==
                      legalStructureValue?.id && '--'}
                    {ngoSavedData?.legal_compliance?.registration_number &&
                      ngoSavedData?.legal_compliance?.registration_number}
                    {ngoSavedData?.legal_compliance
                      ?.society_registration_number &&
                      ngoSavedData?.legal_compliance
                        ?.society_registration_number}
                    {ngoSavedData?.legal_compliance
                      ?.trust_deed_registration_number &&
                      ngoSavedData?.legal_compliance
                        ?.trust_deed_registration_number}
                  </span>
                </div>
                <div className="row my-3">
                  <span className="text-muted col-4">
                    Contact Person Full Name:{' '}
                  </span>{' '}
                  <span className="col-8">
                    {ngoSavedData?.existence?.full_name
                      ? ngoSavedData?.existence?.full_name
                      : '--'}
                  </span>
                </div>
                <div className="row my-3">
                  <span className="text-muted col-4">Email Address: </span>{' '}
                  <span className="col-8">
                    {ngoSavedData?.existence?.email
                      ? ngoSavedData?.existence?.email
                      : '--'}
                  </span>
                </div>
                <div className="row my-3">
                  <span className="text-muted col-4">Mobile No: </span>{' '}
                  <span className="col-8">
                    {ngoSavedData?.existence?.contact_number
                      ? ngoSavedData?.existence?.contact_number
                      : '--'}
                  </span>
                </div>
                <div className="row my-3">
                  <span className="text-muted col-4">Country: </span>{' '}
                  <span className="col-8">
                    {ngoSavedData?.country ? ngoSavedData?.country : '--'}
                  </span>
                </div>
                <div className="row my-3">
                  <span className="text-muted col-4">Language: </span>{' '}
                  <span className="col-8">
                    {ngoSavedData?.language ? ngoSavedData?.language : '--'}
                  </span>
                </div>
                <div className="row my-3">
                  <span className="text-muted col-4">Currency: </span>{' '}
                  <span className="col-8">
                    {ngoSavedData?.currency ? ngoSavedData?.currency : '--'}
                  </span>
                </div>
                <div className="row my-3">
                  <span className="text-muted col-4">Timezone: </span>{' '}
                  <span className="col-8">
                    {ngoSavedData?.timezone
                      ? 'UTC' + ngoSavedData?.timezone
                      : '--'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr className="text-7e9fc2 mt-4 w-100"></hr>
          {/* Conditional Ngo Details ENDS */}

          {/* Conditional Ngo Bank Account Details STARTS */}
          <div className="mb-5 col-9 mx-auto">
            <p className="fs-16 font-weight-bold ml-3 my-5">Account Details</p>
            <div className=" fs-14 justify-content-center">
              <div className="row my-3">
                <span className="text-muted col-4">Bank Name: </span>{' '}
                <span className="col-8">
                  {ngoSavedData?.financial_capacity?.bank_details?.bank_name}
                </span>
              </div>
              <div className="row my-3">
                <span className="text-muted col-4">
                  Name of Account Holder:{' '}
                </span>{' '}
                <span className="col-8">
                  {
                    ngoSavedData?.financial_capacity?.bank_details
                      ?.account_holder_name
                  }
                </span>
              </div>
              <div className="row my-3">
                <span className="text-muted col-4">Account Number: </span>{' '}
                <span className="col-8">
                  {
                    ngoSavedData?.financial_capacity?.bank_details
                      ?.account_number
                  }
                </span>
              </div>
              <div className="row my-3">
                <span className="text-muted col-4">IFSC: </span>{' '}
                <span className="col-8">
                  {ngoSavedData?.financial_capacity?.bank_details?.ifsc_code}
                </span>
              </div>
            </div>
          </div>
          {/* Conditional Ngo Bank Account Details ENDS */}
        </div>

        <div className="col-3 pt-4 px-1">
          <div className="d-flex justify-content-end">
            <p className="w-150 condApproved text-center p-1">
              Conditional Approved
            </p>
          </div>
          <div className="col-12 px-0 overflow-scroll-30vh  bg-F2F9FF py-2">
            <div className="row mt-4">
              <p className="col-8 fs-14 fw-500">Approvals</p>
            </div>
            <hr className="text-7e9fc2 w-100" />
            <div className="ml-3">
              {corpArr.map(data => {
                return (
                  <>
                    <p className="text-005492  fs-16">{data.corp_name}</p>
                    <p>
                      {data?.product_name?.length > 0 && (
                        <div className="">
                          {data?.product_name.includes('iGiv-XN') && (
                            <span className="approveWP fw-500 py-1 px-2 mr-2">
                              iGiv-XN
                            </span>
                          )}
                          {data?.product_name.includes('Socio-XN') && (
                            <span className="approveSN fw-500 py-1 px-2 mr-2">
                              Socio-XN
                            </span>
                          )}
                          {data?.product_name.includes('Engage-XN') && (
                            <span className="approveEN fw-500 py-1 px-2">
                              Engage-XN
                            </span>
                          )}
                        </div>
                      )}
                    </p>
                    <hr className="text-7e9fc2 w-100" />
                  </>
                );
              })}
            </div>
          </div>

          {ngoSavedData?.survey_status !== 'submitted' &&
            ngoSavedData?.survey_status !== 'approved' && (
              <div className="col-12 px-0 position-relative overflow-scroll-30vh  bg-F2F9FF py-2 mt-5">
                <>
                  <div className="row mt-4">
                    <p className="col-8 fs-16 fw-500">
                      Do you want to raise funds from HNI, UHNI, or CSR
                      corporates?
                    </p>
                  </div>

                  <div className="ml-3">
                    <>
                      <p className="text-muted fs-14">
                        Please provide us with following documentation we shall
                        complete your Due Diligence
                      </p>

                      {/* <hr className="text-7e9fc2 w-100" /> */}
                      <div className="text-center">
                        <button
                          type="submit"
                          onClick={() =>
                            (window.location.href =
                              '/ngo/preRegistration#mettasocial_preonboard')
                          }
                          className="btn btn-primary btn-lg fs-14 h5 mt-2 font-weight-bold text-center">
                          Upload Documents
                        </button>
                      </div>
                    </>
                  </div>
                </>
              </div>
            )}
          {ngoSavedData?.survey_status === 'submitted' && (
            <div className="col-12 px-0 position-relative  bg-F2F9FF mt-5">
              <div className="display-note">
                <img src={underReviewGreenIcon} alt="under review" />
                <div className=" ml-5">
                  <div className="text-212529 dm-bold fs-18">
                    Your Pre-registration Profile is under review
                  </div>
                  <p className="text-212529 dm-regular fs-16">
                    Generally it will take us 3-4 working days to review a
                    profile. You will get an email notification once the review
                    is complete.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
