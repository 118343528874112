import React from 'react';
const Tabs = props => {
  const { handlers, tabs, clickHandlerName, defaultSelectionValue } = props;
  const tabsHtml =
    tabs.length > 0 ? (
      <ul
        className="nav nav-tabs bg-white text-dark pl-4 pr-4 sticky-tabs tabs-campaigns"
        id="tabs">
        {tabs.map((tab, index) => {
          const isActive = defaultSelectionValue
            ? defaultSelectionValue === tab.code
            : index === 0;
          return (
            tab.label && (
              <li className="nav-item m-0 pr-0" key={'tab ' + (index + 1)}>
                <a
                  className={`nav-link font-weight-normal lead ${isActive &&
                    'active'}`}
                  data-toggle="tab"
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    window.scrollTo(0, 0);
                    return handlers[clickHandlerName](tab);
                  }}>
                  {tab.label} ({tab.count})
                </a>
              </li>
            )
          );
        })}
      </ul>
    ) : null;
  return tabsHtml;
};

export default Tabs;
