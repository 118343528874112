import React, { useRef } from 'react';
import { toast } from 'react-hot-toast';
import UploadService from '../services/upload.service';
import AuthHideShow from './authHideShow';

export default function MultipleFileUpload(props) {
  var uploadedFileArr = [];
  let {
    id,
    label,
    name,
    value,
    refId,
    preview,
    previewDoc,
    validateFile,
    required,
    validation,
    fileSizeLimit,
    onSuccess,
    docType,
    extraInfoAboutField,
    fieldKey,
    showrequired,
    policyFieldUpdate,
    permissions,
    setIsUploading,
    setFileCount,
    setUploadedFiles,
    uploadedFiles,
    ...restProps
  } = props;
  let fileUploadRef = useRef();

  const uploadFile = async event => {
    // let fileNameArr = fileName ? fileName : [];
    setFileCount(false);
    event.persist();
    try {
      var filesArr = uploadedFiles;
      // setUploadedFilesCount(filesArr?.length);
      for (let l = 0; l < event.target.files?.length; l++) {
        if (!event.target.files) {
          throw new Error('Some thing went wrong..');
        } else {
          let fileSizeInMB = event.target.files[l].size / (1024 * 1024);
          if (fileSizeInMB > 25) {
            filesArr = [];
            setUploadedFiles(filesArr);
            throw new Error(`File size can not be more than 25 MB`);
          } else if (event.target.files[l].name.split('.').length > 2) {
            throw new Error(
              'Incorrect file format. File name should not contain dots'
            );
          } else {
            if (filesArr?.length <= 5) {
              filesArr.push(event.target.files[l]);
            }
          }
        }
      }
      // Convert the File objects to the desired format
      // var formattedArr = filesArr?.map((file, index) => {
      //   if (index <= 4) {
      //     return {
      //       name: file.name,
      //       lastModified: file.lastModified,
      //       lastModifiedDate: file.lastModifiedDate,
      //       webkitRelativePath: file.webkitRelativePath,
      //       size: file.size
      //     };
      //   }
      // });

      setUploadedFiles(filesArr);
      if (filesArr?.length > 5) {
        filesArr = [];
        setUploadedFiles(filesArr);
        throw new Error(`Upto 5 files can be added`);
      }
    } catch (e) {
      event.target.value = '';
      toast.error(e.message);
      return false;
    }

    for (let i = 0; i < uploadedFiles?.length; i++) {
      let formData = new FormData();
      formData.append('file', uploadedFiles[i]);
      formData.append('mimeType', uploadedFiles[i].type);
      formData.append('docType', docType || 'campaign_image');
      formData.append('moduleName', 'ngo');
      formData.append('refId', refId);
      if (setIsUploading) props.setIsUploading(true);

      UploadService.upload(formData, props.userId, 'doc', '')
        .then(res => {
          if (i === uploadedFiles?.length - 1) {
            toast.success('File uploaded successfully.');
            setFileCount(true);
            if (props.setIsUploading) {
              props.setIsUploading(false);
            }
          }

          uploadedFileArr.push(res.data.fileUrl);
          onSuccess && onSuccess(uploadedFileArr, event);
        })
        .catch(err => {
          if (props.setIsUploading) props.setIsUploading(false);
          toast.error(
            'Uh-Oh! Looks like we are having server issues., please contact our support!'
          );
        });
    }
  };
  return (
    <>
      <div className={`${props.className ? props.className : ''}`}>
        <div
          className={`${
            previewDoc ? 'docurl ' : ''
          } input-group position-relative`}>
          <AuthHideShow permissions={permissions}>
            <input
              {...restProps}
              ref={fileUploadRef}
              className={`form-control d-none`}
              id={id}
              onClick={event => {
                event.target.value = null;
              }}
              onChange={uploadFile}
              type="file"
            />
          </AuthHideShow>
        </div>
      </div>
    </>
  );
}
