import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const NewLineChart = ({ data }) => {
  const chartRef = useRef();
  useEffect(() => {
    if (!data) return;

    const margin = { top: 20, right: 20, bottom: 50, left: 40 };
    const width = 900 - margin.left - margin.right; // Increase the width
    const height = 400 - margin.top - margin.bottom; // Keep the height constant

    const svg = d3
      .select(chartRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Create an array of all months
    const allMonths = monthNames.map((month, index) => ({
      month: month,
      monthInfo: index + 1
    }));

    const xScale = d3
      .scaleBand()
      .domain(allMonths.map(d => d.month))
      .range([0, width])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.active_supportors)])
      .nice()
      .range([height, 0]);

    const line = d3
      .line()
      .x(d => xScale(monthNames[d.monthInfo - 1]) + xScale.bandwidth() / 2)
      .y(d => yScale(d.active_supportors));
    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#26AC48')
      .attr('stroke-width', 2)
      .attr('d', line);
    svg
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr(
        'cx',
        d => xScale(monthNames[d.monthInfo - 1]) + xScale.bandwidth() / 2
      )
      .attr('cy', d => yScale(d.active_supportors))
      .attr('r', 4)
      .attr('fill', '#26AC48');

    svg
      .selectAll('text')
      .data(data)
      .enter()
      .append('text')
      .attr(
        'x',
        d => xScale(monthNames[d.monthInfo - 1]) + xScale.bandwidth() / 2
      )
      .attr('y', d => yScale(d.active_supportors) - 10)
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .style('font-weight', 'bold')
      .style('background-color', 'black')
      .style('color', 'white')
      .text(d => d.active_supportors);

    const xAxis = d3.axisBottom(xScale);
    svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);

    const yAxis = d3.axisLeft(yScale);
    svg
      .append('g')
      .attr('class', 'y-axis')
      .call(yAxis);
  }, [data]);

  return (
    <div className="line-chart-container">
      <svg ref={chartRef}></svg>
    </div>
  );
};

export default NewLineChart;
