import React, { useState } from 'react';

const FinancialYearPicker = props => {
  const { uniqueIdentifierForInput, input, savedValue } = props;

  const [selectedYear, setSelectedYear] = useState(savedValue);
  let optionRange = 11;
  const currentFinancialYear = new Date().getFullYear();

  let yearDiff = currentFinancialYear - 2023;
  optionRange = optionRange + yearDiff;
  var currentYear = currentFinancialYear - 1;

  if (new Date().getMonth() + 1 >= 1 && new Date().getMonth() + 1 <= 3) {
    currentYear = currentFinancialYear - 1;
  }
  if (new Date().getMonth() + 1 >= 4 && new Date().getMonth() + 1 <= 9) {
    currentYear = currentFinancialYear - 2;
  }
  if (new Date().getMonth() + 1 > 9) {
    currentYear = currentFinancialYear - 1;
  }

  const years = Array.from({ length: optionRange }, (_, index) => ({
    id: `${currentYear - index + 1}`,
    label: `FY ${currentYear - index} - ${currentYear - index + 1}`
  }));

  const handleYearChange = event => {
    setSelectedYear(event.target.value);
  };

  return (
    <>
      <label htmlFor={uniqueIdentifierForInput}>
        {input?.label}
        {input?.required ||
        (input?.validation && input?.validation === 'required') ||
        input?.showrequired ? (
          <span className="color-FF2300">&nbsp;* </span>
        ) : (
          ''
        )}
      </label>

      <div>
        <select
          id={uniqueIdentifierForInput}
          name={uniqueIdentifierForInput}
          disabled={props.disabled}
          value={selectedYear}
          onChange={handleYearChange}
          className={`form-control w-100 position-relative  ${uniqueIdentifierForInput}`}>
          <option value="" disabled selected>
            Please select a financial year
          </option>
          {years.map(year => (
            <option key={year.id} value={year.id}>
              {year.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default FinancialYearPicker;
