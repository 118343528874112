import React, { useEffect, useState } from 'react';
import '../../styles/modal-styles.css';
import iconDoc from '../../assets/images/doc.png';
import iconPdf from '../../assets/images/pdf.png';
import { toast } from 'react-hot-toast';
import Modal from '../newDesign/Modals/Modal';

const Comments = props => {
  const allComments = props.allNgoComments;
  const [show, showModal] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [disableResolve, setDisableResolve] = useState(false);
  let commentsArr = [];
  if (allComments && allComments.length > 0) {
    let commentsCopy = [];
    if (props.subSectionId) {
      commentsCopy =
        allComments &&
        allComments.filter(
          x =>
            x.section_id === props.sectionId &&
            x.sub_section_id === props.subSectionId
        );
    } else {
      commentsCopy =
        allComments &&
        allComments.filter(x => x.section_id === props.sectionId);
    }
    commentsCopy.sort((a, b) => {
      const timeStamp1 = new Date(a.created_on);
      const timeStamp2 = new Date(b.created_on);
      if (timeStamp1.getTime() < timeStamp2.getTime()) {
        return 1;
      }
      if (timeStamp1.getTime() > timeStamp2.getTime()) {
        return -1;
      }
      return 0;
    });
    if (commentsCopy?.length > 0) {
      commentsArr = commentsCopy?.filter(
        item => item.is_external === true && !item.is_deleted
      );
    }
  }

  function getFileType(file) {
    let extension = file?.split('.').pop();
    let fileValue;
    if (extension === 'docx' || extension === 'doc') {
      fileValue = iconDoc;
    } else if (extension === 'pdf') {
      fileValue = iconPdf;
    } else {
      fileValue = file;
    }
    return fileValue;
  }

  const ResolveComment = (e, id) => {
    e.preventDefault();
    setDisableResolve(true);
    const ngoId = props.ngoId;
    let payload = {
      comment_id: id,
      is_resolved: true,
      section_id: props.sectionId
    };
    props
      .postCommentsById(ngoId, payload)
      .then(res => {
        if (res.code === '200') {
          toast.success('Comment Resolved Successfully');
          let commentArray = props.allNgoComments.slice();
          const index = commentArray.findIndex(x => x.id === id);
          if (index > -1) {
            commentArray[index].is_resolved = true;
            props.setallNgoComments(commentArray);
            props.getSavedNGOData(props.sub);
          }
          showModal(false);
          setCommentId(null);
          setDisableResolve(false);
        }
      })
      .catch(error => {
        if (error)
          toast.error('Error while resolving. Please try again later.');
        setDisableResolve(false);
      });
  };

  const formatDatTime = value => {
    if (!value) return value;
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    let date = new Date(value.split('.')[0] + '+00:00')
      .toLocaleDateString('en-IN', options)
      .toUpperCase();
    return date;
  };

  function handleCloseModal() {
    setCommentId(null);
    showModal(false);
    let body = document.body;
    body.style.overflow = 'auto';
  }
  function showResolveCommentModal(e, id) {
    e.preventDefault();
    setCommentId(id);
    showModal(true);
    let body = document.body;
    body.style.overflow = 'hidden';
  }

  useEffect(() => {}, [commentId]);
  return (
    <>
      <div className="px-4">
        <h5 className="fs-16">
          {commentsArr ? commentsArr.length : 0} Reviews
        </h5>
        <p className="d-flex justify-content-between align-items-center bg-FFF2F0 px-3 py-1 rounded-b-5 mt-4">
          <i className="fa fa-info info-icon mr-4"></i>
          <i>
            Make sure you have addressed requested modifications before
            resolving. Note that you can still edit the sections and resolve
            them before final &lsquo;Submit&lsquo;
          </i>
        </p>
        {commentsArr &&
          commentsArr.map((comment, index) => {
            return (
              <div
                key={index + comment.created_on + comment.comment}
                className="ml-5 my-5 comment-container position-relative">
                <div className="mb-3">
                  <div
                    className={`comment-dot d-flex justify-content-between position-relative ${
                      comment.is_resolved === true
                        ? 'comment-dot-green'
                        : 'comment-dot-red'
                    }`}>
                    <span className="text-969696 fs-14">
                      {formatDatTime(comment?.created_on)}
                    </span>
                  </div>
                  <p className="comment-box p-3 mt-2 mb-4 fs-14">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: comment?.comment ? comment.comment : '----'
                      }}></span>
                  </p>
                  <div className="d-flex justify-content-between">
                    <div>
                      {comment?.attachments?.length > 0 &&
                        comment?.attachments?.map((file, i) => {
                          let fileValue = getFileType(file);
                          return (
                            <span key={i + fileValue} className="p-2">
                              <a
                                href={file}
                                target="_blank"
                                rel="noreferrer noopener">
                                {' '}
                                <img src={fileValue} alt="file" width={20} />
                              </a>
                            </span>
                          );
                        })}
                    </div>
                    {comment.is_resolved ? (
                      <label className="green-tag mb-0">Resolved</label>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success btn-md"
                        // onClick={e => ResolveComment(e, comment.id)}>
                        onClick={e => showResolveCommentModal(e, comment.id)}>
                        Resolve
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Modal
        show={show}
        title={'Resolve Comment?'}
        handleCloseModal={handleCloseModal}
        sizeClass={'modal-lg'}>
        <div>
          <h4 className="fs-18 font-weight-normal">
            Make sure you have updated and verified all the suggested changes.
          </h4>
          <p className="d-flex justify-content-between align-items-start bg-FFF2F0 p-4 rounded-b-5 mt-4">
            <i className="color-FF2300 info-icon-lg mr-4">¡</i>
            <i className="fs-16 color-FF2300">
              Make sure you saved the section changes before resolving all the
              comments. This section will not be editable once you resolve all
              comments.
            </i>
          </p>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-4">
          <span
            className="color-1FA4FA fs-16 p-3 mr-5 cursor-pointer"
            onClick={() => handleCloseModal()}>
            Cancel
          </span>
          <button
            type="button"
            className="btn btn-primary btn-lg col-md-3 font-weight-bold"
            onClick={e => ResolveComment(e, commentId)}
            disabled={disableResolve}>
            Resolve
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Comments;
