export const sortTableData = (
  sortBy,
  sortStateField,
  setDisplayData,
  data,
  setActiveSortField,
  setSortStateField
) => {
  if (sortBy === 'Amount') {
    if (sortStateField.amount === 'asc') {
      setDisplayData(
        data.sort((a, b) => {
          var x = a.amount;
          var y = b.amount;
          if (x === null && y === null) return 0;
          if (x === null) return 1;
          if (y === null) return -1;
          if (x === undefined && y === undefined) return 0;
          if (x === undefined) return 1;
          if (y === undefined) return -1;
          if (typeof x == 'number' && typeof y == 'number') return x - y;
        })
      );
      setSortStateField({ ...sortStateField, amount: 'dec' });
      setActiveSortField([`Amount`, 'asc']);
    } else {
      setDisplayData(
        data.sort((a, b) => {
          var x = a.amount;
          var y = b.amount;
          if (x === null && y === null) return 0;
          if (x === null) return 1;
          if (y === null) return -1;
          if (x === undefined && y === undefined) return 0;
          if (x === undefined) return 1;
          if (y === undefined) return -1;
          if (typeof x == 'number' && typeof y == 'number') return y - x;
        })
      );

      setSortStateField({ ...sortStateField, amount: 'asc' });
      setActiveSortField([`Amount`, 'dec']);
    }
  } else if (sortBy === 'Date') {
    const filterNgoRequestData = data.filter(
      item => item.transaction_date !== 'None'
    );
    const filterNonNgoRequestData = data.filter(
      item => item.transaction_date === 'None'
    );
    let newSortData = [];
    if (sortStateField.date === 'asc') {
      newSortData = filterNgoRequestData.sort(
        (a, b) => new Date(a.transaction_date) - new Date(b.transaction_date)
      );
      setDisplayData([...newSortData, ...filterNonNgoRequestData]);
      setActiveSortField(['Date', 'asc']);
      setSortStateField({
        ...sortStateField,
        date: 'dec'
      });
    } else {
      newSortData = filterNgoRequestData.sort(
        (a, b) => new Date(b.transaction_date) - new Date(a.transaction_date)
      );
      setDisplayData([...newSortData, ...filterNonNgoRequestData]);
      setSortStateField({
        ...sortStateField,
        date: 'asc'
      });
      setActiveSortField(['Date', 'dec']);
    }
  }
};
