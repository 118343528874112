import React from 'react';
import Chart from 'react-apexcharts';
import { convertInCommaFormat } from '../../helpers/commaSeparatedInput';

const DonutChart = props => {
  let chartBlockSize = props.size;
  let labelArrBudget = [];
  let series = [];
  if (props.categoryList) {
    for (var i = 0; i < props.categoryList.length; i++) {
      labelArrBudget[i] =
        props.categoryList[i].primary_category +
        ' : ' +
        convertInCommaFormat(props.categoryList[i].category_expense);
      series[i] = props.categoryList[i].category_expense;
    }
  }
  const options = {
    labels: labelArrBudget,
    colors: props.colorCode,
    dataLabels: {
      enabled: true
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return '';
        },
        title: {
          formatter: function(labelArrBudget) {
            return labelArrBudget;
          }
        }
      },
      style: {
        fontSize: '12px',
        fontFamily: undefined,
        color: 'white'
      }
    },
    legend: {
      show: false
    },

    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          show: true,
          size: chartBlockSize,
          labels: {
            show: true,
            value: {
              show: true,
              offsetY: -8
            },
            total: {
              show: true,
              label: props.categoryList?.length,
              showAlways: true,
              fontSize: '24px',
              color: '#344256',
              formatter: function() {
                return 'Categories';
              }
            }
          }
        }
      }
    }
  };
  return (
    <Chart options={options} series={series} type="donut" width={'120%'} />
  );
};

export default DonutChart;
