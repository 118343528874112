export default function getDeviceType() {
  var isMobile = true;
  if (window) {
    if (window.matchMedia('(max-width: 991px)').matches) {
      isMobile = true;
    } else {
      isMobile = false;
    }
  }
  return isMobile;
}
