import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import { Common, Routes } from '../constants';
import '../styles/simple-sidebar.css';
var funnelCondition = '';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: window.location.pathname
    };
  }

  setCurrentPath() {
    this.setState({
      currentPath: window.location.pathname
    });
  }

  checkPolicies = path => {
    let policies = JSON.parse(window.localStorage.getItem('userPolicies'));
    if (
      path !== Routes.NGO.ROLE_MANAGEMENT &&
      path !== Routes.NGO.DONATION_REPORT
    )
      return true;
    if (policies) {
      const filteredArr = policies.filter(
        x =>
          (x.resource_feature === 'Manage team member' ||
            x.resource_feature === 'Transfer Ownership' ||
            x.resource_feature === 'View Donation Reports') &&
          (x.role.toLowerCase().includes('superadmin') ||
            x.role.toLowerCase().includes('admin') ||
            x.role.toLowerCase().includes('fundraising'))
      );
      if (filteredArr.length > 0) {
        return true;
      }
      return false;
    }
  };

  render() {
    let isSideBars = this.props.sideBars;
    let isAdmin = this.props.userInfo
      ? this.props.userInfo.user_type === 1
      : false;

    let currentPath = window.location.pathname;
    return (
      <React.Fragment>
        <aside
          className={`bg-white no-print pl-0 pr-0 border-right sticky-sidebar bg-white vh-100 s-menu-item 
             ${isSideBars ? 'col-2' : 'aside-w'}
            `}
          style={{ zIndex: '9999' }}>
          <div className="list-group flex-column justify-content-between h-100">
            {window.location.href.includes('email') && (
              <div>
                {Common.NGO.IMPERSONATED_SIDE_BAR.map((v, k) => {
                  return v.onClickHandler ? (
                    (isAdmin &&
                      (v.isAdmin === true || v.isAdmin === undefined)) ||
                    (!isAdmin &&
                      (v.isAdmin === false || v.isAdmin === undefined)) ? (
                      <NavLink
                        key={'nav-' + k}
                        exact
                        to={v.path}
                        className="list-group-item list-group-item-action s-menu-item py-3"
                        activeClassName="active"
                        onClick={this.handlers[v.onClickHandler]}>
                        <p
                          className={`lead mb-0 row  ${
                            currentPath === v.path ? 'font-weight-bold' : ' '
                          } flex-nowrap`}>
                          <img
                            src={currentPath === v.path ? v.activeIcon : v.icon}
                            alt=" "
                            style={{
                              height: 30,
                              width: 30,
                              filter: 'brightness(1)'
                            }}
                            className={v.iconClass || ' '}
                          />
                          <span className="col-10">{v.label}</span>
                        </p>
                      </NavLink>
                    ) : null
                  ) : (isAdmin &&
                      (v.isAdmin === true || v.isAdmin === undefined)) ||
                    (!isAdmin &&
                      (v.isAdmin === false || v.isAdmin === undefined)) ? (
                    window.location.href.includes(v.name) ? (
                      <NavLink
                        key={'nav-' + k}
                        to={v.path}
                        onClick={() => {
                          this.setCurrentPath();
                        }}
                        exact
                        className={
                          'list-group-item list-group-item-action s-menu-item tooltipss'
                        }
                        activeClassName="active">
                        <p
                          className={`lead mb-0 row align-items-center ${
                            currentPath === v.path ? 'font-weight-bold' : ' '
                          } flex-nowrap`}>
                          <img
                            src={currentPath === v.path ? v.activeIcon : v.icon}
                            alt="sidebar icon"
                            style={{
                              height: 30,
                              width: 30,
                              filter: 'brightness(1)'
                            }}
                            className={v.iconClass || ' '}
                          />
                          {isAdmin && (
                            <span
                              className="col-10"
                              style={{
                                color:
                                  currentPath === v.path ? v.activeIcon : v.icon
                              }}>
                              {v.label}
                              {v.label === 'Profile' &&
                                this.props.isProfileComplete === 'new' &&
                                this.props.state.ngo.ngoProfile.l0_status ===
                                  'new' && (
                                  <i className="ml-2 fas h6 fa-circle text-danger anim-blink"></i>
                                )}
                            </span>
                          )}
                          {!isAdmin &&
                            v.icon === '/images/profile-grey.svg' &&
                            this.props.isProfileComplete === 'new' &&
                            this.props.state.ngo.ngoProfile.l0_status ===
                              'new' && (
                              <i
                                className="ml-2 fas h6 fa-circle text-danger anim-blink"
                                style={{
                                  position: 'relative',
                                  right: '10px',
                                  bottom: '5px'
                                }}></i>
                            )}
                        </p>
                        <span
                          className={`${
                            isAdmin ? 'tooltip-text-hide' : 'tooltip-text'
                          }`}>{`${isAdmin ? '' : v.label}`}</span>
                      </NavLink>
                    ) : (
                      ''
                    )
                  ) : null;
                })}
              </div>
            )}

            {this.props.state.ngo.ngoProfile.is_scn === false &&
              this.props.state.ngo.ngoProfile.is_wp === false &&
              this.props.state.ngo.ngoProfile.is_conditionally_approved ===
                true &&
              this.props.state.ngo.ngoProfile.is_mettasocial_preonboarded ===
                false && (
                <>
                  {!window.location.href.includes('email') && (
                    <div>
                      {Common.NGO.SIDE_BAR.map((v, k) => {
                        return (
                          <>
                            {v.label === 'Profile' &&
                              v.impersonate === true &&
                              v.isAdmin === false && (
                                <div className="d-flex mt-2 ml-2 ">
                                  <img
                                    src={
                                      currentPath === v.path
                                        ? v.activeIcon
                                        : v.icon
                                    }
                                    alt=" "
                                    style={{
                                      height: 35,
                                      width: 35,
                                      filter: 'brightness(1)'
                                    }}
                                    className={v.iconClass || ''}
                                  />
                                  {isSideBars && (
                                    <span className="d-flex fs-16 text-primary d-flex align-items-center ml-5 fw-500">
                                      <span className="ml-1">{v.label}</span>
                                    </span>
                                  )}
                                </div>
                              )}
                          </>
                        );
                      })}
                    </div>
                  )}
                </>
              )}

            {!window.location.href.includes('email') && (
              <div>
                {Common.NGO.SIDE_BAR.map((v, k) => {
                  if (
                    this.props.state.ngo.ngoProfile.is_scn &&
                    !this.props.state.ngo.ngoProfile.is_wp
                  ) {
                    funnelCondition =
                      this.props.state.ngo.ngoProfile.is_scn &&
                      !this.props.state.ngo.ngoProfile.is_wp &&
                      v.label !== 'Missions' &&
                      v.label !== 'Donation Reports';
                  } else if (
                    !this.props.state.ngo.ngoProfile.is_scn &&
                    this.props.state.ngo.ngoProfile.is_wp
                  ) {
                    funnelCondition =
                      !this.props.state.ngo.ngoProfile.is_scn &&
                      this.props.state.ngo.ngoProfile.is_wp &&
                      v.label !== 'Project';
                  } else {
                    funnelCondition =
                      this.props.state.ngo.ngoProfile.is_scn &&
                      this.props.state.ngo.ngoProfile.is_wp;
                  }

                  if (!this.checkPolicies(v.path)) return <></>;

                  return v.onClickHandler ? (
                    (isAdmin &&
                      (v.isAdmin === true || v.isAdmin === undefined)) ||
                    (!isAdmin &&
                      (v.isAdmin === false || v.isAdmin === undefined)) ? (
                      <NavLink
                        key={'nav-' + k}
                        exact
                        to={v.path}
                        className="list-group-item list-group-item-action s-menu-item py-3"
                        activeClassName="active"
                        onClick={this.handlers[v.onClickHandler]}>
                        <p
                          className={`lead mb-0 row ${
                            currentPath === v.path ? 'font-weight-bold' : ' '
                          } flex-nowrap`}>
                          <img
                            src={currentPath === v.path ? v.activeIcon : v.icon}
                            alt=" "
                            style={{
                              height: 30,
                              width: 30,
                              filter: 'brightness(1)'
                            }}
                            className={v.iconClass || ' '}
                          />

                          <span className="col-10">{v.label}</span>
                        </p>
                      </NavLink>
                    ) : null
                  ) : (isAdmin &&
                      (v.isAdmin === true || v.isAdmin === undefined)) ||
                    (!isAdmin &&
                      (v.isAdmin === false || v.isAdmin === undefined)) ? (
                    funnelCondition && (
                      <NavLink
                        key={'nav-' + k}
                        to={v.path}
                        onClick={() => {
                          this.setCurrentPath();
                        }}
                        exact
                        className="menu-options list-group-item list-group-item-action s-menu-item tooltipss"
                        activeClassName="active">
                        <p
                          className={`lead mb-0 row align-items-center ${
                            currentPath === v.path ? 'font-weight-bold' : ' '
                          } flex-nowrap`}>
                          <img
                            src={currentPath === v.path ? v.activeIcon : v.icon}
                            alt="sidebar icon"
                            style={{
                              height: 30,
                              width: 30,
                              filter: 'brightness(1)'
                            }}
                            className={v.iconClass || ' '}
                          />
                          {isSideBars && (
                            <span
                              className="col-10"
                              style={{
                                color:
                                  currentPath === v.path ? v.activeIcon : v.icon
                              }}>
                              {v.label}
                              {v.label === 'Profile' &&
                                this.props.isProfileComplete === 'new' &&
                                this.props.state.ngo.ngoProfile.l0_status ===
                                  'new' && (
                                  <i className="ml-2 fas h6 fa-circle text-danger anim-blink"></i>
                                )}
                            </span>
                          )}
                          {!isSideBars &&
                            v.icon === '/images/profile-grey.svg' &&
                            this.props.isProfileComplete === 'new' &&
                            this.props.state.ngo.ngoProfile.l0_status ===
                              'new' && (
                              <i
                                className="ml-2 fas h6 fa-circle text-danger anim-blink"
                                style={{
                                  position: 'relative',
                                  right: '10px',
                                  bottom: '5px'
                                }}></i>
                            )}
                        </p>
                        <span
                          className={`${
                            isSideBars ? 'tooltip-text-hide' : 'tooltip-text'
                          }`}>{`${isSideBars ? '' : v.label}`}</span>
                      </NavLink>
                    )
                  ) : null;
                })}
              </div>
            )}
          </div>
        </aside>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  state: state,
  userInfo: state.login.userInfo,
  isProfileComplete:
    state.ngo.ngoProfile && state.ngo.ngoProfile.isProfileComplete
});
export default connect(mapStateToProps, null)(Sidebar);
