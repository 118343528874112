import CampaignService from '../services/campaign.service';
import { toast } from 'react-hot-toast';
import { Action, EndPoint } from '../constants';

import HttpRequest from '../helpers/http-request';
export const getAllProjects = ngoId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getAllProjects(ngoId)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data.result);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getFundInformation = ngoId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getFundInformation(ngoId)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getProjectActivities = campaignId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getAllProjectActivites(campaignId)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getGoalsIndicators = campaignId => dispatch => {
  dispatch(indicatorsDetailsRequest());
  return new Promise((resolve, reject) => {
    CampaignService.getGoalsIndicators(campaignId)
      .then(res => {
        if (res.status === 200) {
          dispatch(indicatorsDetailsSuccess(res.data.result));
          resolve(res.data.result);
        }
      })
      .catch(err => {
        reject(err);
        if (err.response) dispatch(indicatorsDetailsFailure(err));
      });
  });
};

//mne beneficiaries
export const getMNEBeneficiaries = campaignId => dispatch => {
  dispatch(beneficiariesDetailsRequest());
  return new Promise((resolve, reject) => {
    CampaignService.getMNEBeneficiaries(campaignId)
      .then(res => {
        if (res.status === 200) {
          dispatch(beneficiariesDetailsSuccess(res.data.result));
          resolve(res.data.result);
        }
      })
      .catch(err => {
        reject(err);
        if (err.response) dispatch(beneficiariesDetailsFailure(err));
      });
  });
};
export const getMNEBeneficiariesDynamic = projectId => dispatch => {
  dispatch(beneficiariesDynamicRequest());
  return new Promise((resolve, reject) => {
    CampaignService.getMNEBeneficiariesDynamic(projectId)
      .then(res => {
        if (res.status === 200) {
          dispatch(beneficiariesDynamicSuccess(res.data.result));
          resolve(res.data.result);
        }
      })
      .catch(err => {
        reject(err);
        if (err.response) dispatch(beneficiariesDynamicFailure(err));
      });
  });
};
export const removeMNEBeneficiariesDynamic = beneficiaryId => dispatch => {
  dispatch(removeBeneficiariesDynamicRequest());
  return new Promise((resolve, reject) => {
    CampaignService.removeMNEBeneficiariesDynamic(beneficiaryId)
      .then(res => {
        if (res.status === 200) {
          dispatch(removeBeneficiariesDynamicSuccess(res.data));
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
        if (err.response) dispatch(removeBeneficiariesDynamicFailure(err));
      });
  });
};
export const selectProjectBeneficiariesType = data => dispatch => {
  dispatch(selectBeneficiariesTypeRequest());
  return CampaignService.selectProjectBeneficiariesType(data)
    .then(response => {
      if (response.status === 200) {
        dispatch(selectBeneficiariesTypeSuccess(response.data));
        return response;
      } else {
        dispatch(selectBeneficiariesTypeFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(selectBeneficiariesTypeFailure(error));
    });
};

export const getProjectExpenses = campaignId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getProjectExpenses(campaignId)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const financeDetailsRequest = () => {
  return {
    type: Action.FINANCE_DETAIL_REQUEST
  };
};
export const financeDetailsSuccess = finance => {
  return {
    type: Action.FINANCE_DETAILS_SUCCESS,
    payload: finance
  };
};
export const financeDetailsFailure = error => {
  return {
    type: Action.FINANCE_DETAILS_FAILURE,
    payload: error
  };
};
export const getProjectExpensesById = campaignId => dispatch => {
  dispatch(financeDetailsRequest());
  return CampaignService.getProjectExpenses(campaignId)
    .then(response => {
      if (response.status === 200 || response.status === 204) {
        dispatch(financeDetailsSuccess(response.data.result));
      }
    })
    .catch(error => {
      if (error.response) dispatch(financeDetailsFailure(error));
    });
};

export const addExpense = (data, campaignId) => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  return (
    instance
      .post(EndPoint.getApiUrl('NGO', 'EXPENSE_CREATE', 'HIROKU'), data)
      // .then(response => {
      //   dispatch(getProjectExpenses());
      // })
      .catch(() => {
        toast.error(
          'Uh-Oh! Looks like we are having server issues. Please Try Again'
        );
      })
  );
};
export const deleteExpense = expenseId => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'DELETE_EXPENSE_BY_ID', 'HIROKU');
  apiUrl = apiUrl.replace('{expense_id}', expenseId);
  return instance.post(apiUrl).catch(() => {
    toast.error(
      'Uh-Oh! Looks like we are having server issues. Please Try Again'
    );
  });
};
export const getActualAndPlannedBudget = campaignId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getActualAndPlannedBudget(campaignId)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getExpenseCategory = () => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getExpenseCategory()
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getExpenseType = () => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getExpenseType()
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getFrequencyListMasterData = () => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getFrequencyListMasterData()
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getUomListMasterData = () => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getUomListMasterData()
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getCampaignProposalStatus = campaignId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.campaignProposalStatus(parseInt(campaignId))
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getCampaignProposalModificationStatus = campaignId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.campaignProposalModificationStatus(parseInt(campaignId))
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const acceptCampaignProposalModification = campaignId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.campaignProposalModificationAccept(campaignId)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const startProject = campaignId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.startProject(parseInt(campaignId))
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const modifyProjectActivities = data => dispatch => {
  dispatch(activitiesCreateRequest());
  return CampaignService.modifyProjectActivities(data)
    .then(response => {
      if (response.status === 200 && response.data.code === 200) {
        dispatch(activitiesCreateSuccess(response.data));
        return response;
      } else {
        dispatch(activitiesCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(activitiesCreateFailure(error));
    });
};
export const createOrModifyActivities = data => dispatch => {
  dispatch(activitiesCreateRequest());
  return CampaignService.createOrModifyActivities(data)
    .then(response => {
      if (response.status === 200 && response.data.code === 200) {
        dispatch(activitiesCreateSuccess(response.data));
        return response;
      } else {
        dispatch(activitiesCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(activitiesCreateFailure(error));
    });
};
export const createOrModifyExpense = data => dispatch => {
  dispatch(expenseCreateRequest());
  return CampaignService.createOrModifyExpense(data)
    .then(response => {
      if (response.status === 200 && response.data.code === 200) {
        dispatch(expenseCreateSuccess(response.data));
        return response;
      } else {
        dispatch(expenseCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(expenseCreateFailure(error));
    });
};

export const updateActivity = data => dispatch => {
  return CampaignService.updateActivity(data)
    .then(response => {
      if (response.status === 200 && response.data.code === 200) {
        dispatch(activitiesCreateSuccess(response.data));
        return response;
      } else {
        dispatch(activitiesCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(activitiesCreateFailure(error));
    });
};

export const createOrModifyIndicators = data => dispatch => {
  dispatch(indicatorsCreateRequest());
  return CampaignService.createOrModifyIndicators(data)
    .then(response => {
      if (response.status === 200) {
        dispatch(indicatorsCreateSuccess(response.data));
        return response;
      } else {
        dispatch(activitiesCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(indicatorsCreateFailure(error));
    });
};
export const createOrModifyBeneficiaries = data => dispatch => {
  dispatch(indicatorsCreateRequest());

  return CampaignService.createOrModifyBeneficiaries(data)
    .then(response => {
      if (response.status === 200) {
        dispatch(beneficiariesCreateSuccess(response.data));
        return response;
      } else {
        dispatch(beneficiariesCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(beneficiariesCreateFailure(error));
    });
};
export const creategoalHistory = data => dispatch => {
  dispatch(indicatorsCreateRequest());
  return CampaignService.creategoalHistory(data)
    .then(response => {
      if (response.status === 200 && response.data.code === '200') {
        dispatch(indicatorsCreateSuccess(response.data));
        return response;
      } else {
        dispatch(activitiesCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(indicatorsCreateFailure(error));
    });
};
export const updateProject = data => dispatch => {
  return CampaignService.updateProject(data)
    .then(response => {
      if (response.status === 200 && response.data.code === 200) {
        // dispatch(indicatorsCreateSuccess(response.data));
        return response;
      } else {
        dispatch(activitiesCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(indicatorsCreateFailure(error));
    });
};

export const getGoalHistory = indicatorId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getGoalHistory(indicatorId)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getExpenseSubCategory = expenseId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getExpenseSubCategory(expenseId)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getAllExpenseSubCategory = () => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getAllExpenseSubCategory()
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getTimeline = campaignId => dispatch => {
  return new Promise((resolve, reject) => {
    CampaignService.getTimeline(campaignId)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const createTimeline = data => dispatch => {
  dispatch(indicatorsCreateRequest());
  return CampaignService.createTimeline(data)
    .then(response => {
      if (response.status === 200 && response.data.code === 200) {
        dispatch(indicatorsCreateSuccess(response.data));
        return response;
      } else {
        dispatch(activitiesCreateFailure(response.data));
      }
    })
    .catch(error => {
      dispatch(indicatorsCreateFailure(error));
    });
};

export const indicatorsCreateRequest = () => {
  return {
    type: Action.INDICATORS_CREATE_REQUEST
  };
};
export const indicatorsCreateSuccess = indicators => {
  return {
    type: Action.INDICATORS_CREATE_SUCCESS,
    payload: indicators
  };
};
export const indicatorsCreateFailure = error => {
  return {
    type: Action.INDICATORS_CREATE_FAILURE,
    payload: error
  };
};
export const indicatorsDetailsRequest = () => {
  return {
    type: Action.INDICATORS_DETAILS_REQUEST
  };
};
export const indicatorsDetailsSuccess = indicators => {
  return {
    type: Action.INDICATORS_DETAILS_SUCCESS,
    payload: indicators
  };
};
export const indicatorsDetailsFailure = error => {
  return {
    type: Action.INDICATORS_DETAILS_FAILURE,
    payload: error
  };
};

//BENEFICIARIES
export const beneficiariesCreateRequest = () => {
  return {
    type: Action.BENEFICIARIES_CREATE_REQUEST
  };
};
export const beneficiariesCreateSuccess = beneficiariesData => {
  return {
    type: Action.BENEFICIARIES_CREATE_SUCCESS,
    payload: beneficiariesData
  };
};
export const beneficiariesCreateFailure = error => {
  return {
    type: Action.BENEFICIARIES_CREATE_FAILURE,
    payload: error
  };
};
export const beneficiariesDetailsRequest = () => {
  return {
    type: Action.BENEFICIARIES_DETAILS_REQUEST
  };
};
export const beneficiariesDetailsSuccess = beneficiariesData => {
  return {
    type: Action.BENEFICIARIES_DETAILS_SUCCESS,
    payload: beneficiariesData
  };
};
export const beneficiariesDetailsFailure = error => {
  return {
    type: Action.BENEFICIARIES_DETAILS_FAILURE,
    payload: error
  };
};
export const beneficiariesDynamicRequest = () => {
  return {
    type: Action.BENEFICIARIES_DYNAMIC_REQUEST
  };
};
export const beneficiariesDynamicSuccess = beneficiariesData => {
  return {
    type: Action.BENEFICIARIES_DYNAMIC_SUCCESS,
    payload: beneficiariesData
  };
};
export const beneficiariesDynamicFailure = error => {
  return {
    type: Action.BENEFICIARIES_DYNAMIC_FAILURE,
    payload: error
  };
};
export const removeBeneficiariesDynamicRequest = () => {
  return {
    type: Action.REMOVE_BENEFICIARIES_DYNAMIC_REQUEST
  };
};
export const removeBeneficiariesDynamicSuccess = beneficiariesData => {
  return {
    type: Action.REMOVE_BENEFICIARIES_DYNAMIC_SUCCESS,
    payload: beneficiariesData
  };
};
export const removeBeneficiariesDynamicFailure = error => {
  return {
    type: Action.REMOVE_BENEFICIARIES_DYNAMIC_FAILURE,
    payload: error
  };
};
export const selectBeneficiariesTypeRequest = () => {
  return {
    type: Action.SELECT_BENEFICIARIES_TYPE_REQUEST
  };
};
export const selectBeneficiariesTypeSuccess = data => {
  return {
    type: Action.SELECT_BENEFICIARIES_TYPE_SUCCESS,
    payload: data
  };
};
export const selectBeneficiariesTypeFailure = data => {
  return {
    type: Action.SELECT_BENEFICIARIES_TYPE_FAILURE,
    payload: data
  };
};

//ACTIVITIES
export const activitiesCreateRequest = () => {
  return {
    type: Action.ACTIVITIES_CREATE_REQUEST
  };
};
export const activitiesCreateSuccess = activities => {
  return {
    type: Action.ACTIVITIES_CREATE_SUCCESS,
    payload: activities
  };
};
export const activitiesCreateFailure = activities => {
  return {
    type: Action.ACTIVITIES_CREATE_FAILURE,
    payload: activities
  };
};

//Expense code
export const expenseCreateRequest = () => {
  return {
    type: Action.EXPENSE_CREATE_REQUEST
  };
};
export const expenseCreateFailure = expense => {
  return {
    type: Action.EXPENSE_CREATE_FAILURE,
    payload: expense
  };
};
export const expenseCreateSuccess = expense => {
  return {
    type: Action.EXPENSE_CREATE_SUCCESS,
    payload: expense
  };
};

export const updateProjectFailure = activities => {
  return {
    type: Action.UPDATE_PROJECT_FAILURE,
    payload: activities
  };
};
