/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import LogoutSVG from '../assets/icons/logout.svg';
import { logout } from '../helpers/keycloak';
import '../styles/header.css';
import { getHeaderData } from './../actions/ngo.actions';
import Sidebar from './sidebar';

import { setSidebarStatus } from '../actions/sidebarMenu_Actions';
import { toast } from 'react-hot-toast';

let isNightModeOn = false;
var isSliderOn = false;
const toggleNightMode = e => {
  e && e.preventDefault();
  document.cookie = `nightMode=${!document
    .querySelector('html')
    .classList.contains('dark')}; max-age=${60 *
    60 *
    24 *
    365}; samesite; secure; path=/`;
  document.querySelector('html').classList.toggle('dark');
};
document.cookie.split(';').forEach(cookie => {
  const [name, value] = cookie.trim().split('=');
  if (name === 'nightMode' && value === 'true') {
    isNightModeOn = true;
    toggleNightMode();
  }
});

const Header = props => {
  const [nightMode, setNightMode] = useState(isNightModeOn);
  const [headerInfo, setHeaderInfo] = useState({});
  const [toggleLoginInfo, setToggleLoginInfo] = useState(false);
  const [sideBars, setSideBars] = useState(false);
  const dispatch = useDispatch();

  const toggleLoginInformation = e => {
    getHeaderData();
    setToggleLoginInfo(!toggleLoginInfo);
  };
  const handleNightModeToggle = e => {
    e.preventDefault();
    isNightModeOn = !nightMode;
    setNightMode(!nightMode);
    toggleNightMode();
  };
  useEffect(() => {
    getHeaderData();
  }, []);
  useEffect(() => {
    dispatch(setSidebarStatus(sideBars));
  }, [sideBars]);
  const getHeaderData = () => {
    props
      .getHeaderData()
      .then(res => {
        setHeaderInfo(res);
        if (props.setNgoInfo) {
          props.setNgoInfo(res?.result);
        }
      })
      .catch(err => {
        toast.error('Uh-Oh! Looks like we are having server issues.');
      });
  };
  useEffect(() => {
    if (isSliderOn === true && sideBars === false) {
      setSideBars(true);
      isSliderOn = true;
      document.getElementById('main-content').classList.add('col-10');
      document
        .getElementById('main-content')
        .classList.remove('col-md-11', 'col-lg-11', 'ml-3');
    }
    if (document.getElementById('tabs') !== null) {
      document.getElementById('tabs').classList.add('tabs-campaigns');
    }
  }, []);

  const handleSideBar = e => {
    e.preventDefault();
    if (isSliderOn === true && sideBars === false) {
      setSideBars(true);
      isSliderOn = true;
    } else if (sideBars === false) {
      setSideBars(true);
      isSliderOn = true;
      document.getElementById('main-content').classList.add('col-10');
      if (document.getElementById('tabs') !== null) {
        document.getElementById('tabs').classList.remove('tabs-campaigns');
      }
      document
        .getElementById('main-content')
        .classList.remove('col-md-11', 'col-lg-11', 'ml-3');
    } else {
      setSideBars(false);
      isSliderOn = false;
      document.getElementById('main-content').classList.remove('col-10');
      if (document.getElementById('tabs') !== null) {
        document.getElementById('tabs').classList.add('tabs-campaigns');
      }
      document
        .getElementById('main-content')
        .classList.add('col-md-11', 'col-lg-11', 'ml-3');
    }
  };
  return (
    <>
      <nav
        className="navbar bg-white navbar-expand-lg border-bottom pl-0 pr-3 fixed-top justify-content-between"
        style={{ height: '58px' }}>
        <div className="row align-items-center">
          <a
            className={`navbar-brand text-center row flex-grow-0 justify-content-center align-items-center border-right ${
              sideBars ? 'col-2' : 'aside-w'
            }`}
            href="/">
            {/* <img
            src="https://cdn.mettasocial.com/logos/640X180_with_name_color.png"
          <img
            src="https://cdn.mettasocial.com/logos/MS_logo_640x180.svg"
            height="45"
            width="163"
            className="scale-08"
            alt="mettasocial"
          /> */}

            {/* <img
           src="https://cdn.mettasocial.com/logos/MS_logo_640x180.svg"
            height="45"
            width="163"
            className="scale-08"
            alt="mettasocial"
          />  */}

            <div className="display-flex justify-content-center align-items-center p-1 ml-2">
              <img
                src="https://cdn.mettasocial.com/logos/MS_logo_32x32.png"
                height="39"
                width="39"
                className={` ${!isSliderOn ? '' : 'display-none'}`}
                alt="mettasocial"
              />
            </div>

            <div
              className="display-flex justify-content-center align-items-center"
              // style={{ width: !isSliderOn ? "" : "16vw" }}
            >
              <img
                src="https://cdn.mettasocial.com/logos/MS_logo_640x180.svg"
                height="45"
                width="163"
                className={`scale-08 ${
                  !isSliderOn ? 'display-none' : 'display:block'
                }`}
                alt="mettasocial"
              />
            </div>
          </a>
          <div className="cursor-pointer" onClick={handleSideBar}>
            <i className="material-icons" style={{ marginTop: '0.5rem' }}>
              menu
            </i>
          </div>
          <p className="h3 text-212529 dm-bold m-0 pl-3">
            {props.pageTitle || ' '}
          </p>
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0 align-items-center flex-row">
            <span className="mr-2">{props.children}</span>
            {/* commented notification, email, and help icons */}
            {/* <li className="nav-item active mr-3">
           <a
              className="text-info"
              href="/"
              title="Notifications (Coming Soon)">
              <i className="fas fa-bell header-icon fa-2x"></i>
            </a>
          </li>
          <li className="nav-item mr-3">
            <a
              className="text-info"
              href={`https://${process.env.REACT_APP_ENV}chat.mettasocial.com`}
              title="Inbox">
              <i className="fas fa-envelope header-icon fa-2x"></i>
            </a>
          </li>
          <li className="nav-item mr-3">
            <Link
              className="text-info"
              to="/ngo/support"
              title="Contact Support">
              <i className="fas fa-question-circle header-icon fa-2x"></i>
            </Link>
          </li> */}
            <li className="nav-item mr-3">
              <a onClick={handleNightModeToggle} href="/" title="Night Mode">
                {!nightMode ? (
                  <i className="fa fa-moon text-dark lead"></i>
                ) : (
                  <i className="fa fa-sun text-dark fa-2x lead"></i>
                )}
              </a>
            </li>

            <li className="nav-item ml-4 mr-3">
              <span
                onClick={() => toggleLoginInformation(!toggleLoginInfo)}
                href="/"
                title="Profile"
                className="d-flex align-items-center"
                style={{
                  marginTop: '-5px',
                  cursor: 'pointer'
                }}>
                {/* <i
                  className={`fa fa-chevron-up fs-10 ml-3 fa-2x color-1FA4FA ${
                    toggleLoginInfo ? 'rotateImage' : ''
                  }`}
                ></i> */}
                <span className="mx-2 fw-500">
                  {headerInfo?.result?.name?.length > 25
                    ? headerInfo?.result?.name?.slice(0, 25) + '...'
                    : headerInfo?.result?.name}
                </span>
                {/* IMAGE HERE  */}
                <div>
                  <img
                    src={
                      headerInfo?.result?.logo
                        ? headerInfo?.result?.logo
                        : 'https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile-thumbnail.png'
                    }
                    // height="44px"
                    width="45px"
                    alt="NGO logo"
                    style={{
                      borderRadius: '10%',
                      height: 'fitContent',
                      background: 'white',
                      padding: '4px'
                    }}
                  />
                </div>
                {/* <i
                className={`fa fa-solid fa-caret-up ml-3 fa-2x color-1FA4FA ${toggleLoginInfo ? 'rotateImage' : ''
                  }`}
                style={{ marginTop: '3px' }}></i> */}
              </span>
              {toggleLoginInfo && (
                <LoginInfo
                  name={headerInfo?.result?.name}
                  email={headerInfo?.result?.email}
                  userInfo={props.state.login.userInfo}
                  logo={
                    headerInfo?.result?.logo
                      ? headerInfo?.result?.logo
                      : 'https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile-thumbnail.png'
                  }
                  // l1_status={props.state.register.ngoSavedData?.l1_status}
                  // l2_status={props.state.register.ngoSavedData?.l2_status}
                  l1_status={headerInfo?.result?.l1_status}
                  l2_status={headerInfo?.result?.l2_status}
                  l0_status={headerInfo?.result?.l0_status}
                  is_conditional={headerInfo?.result?.is_conditional}
                  is_mettasocial_preonboarded={
                    props.state.ngo.ngoProfile.is_mettasocial_preonboarded
                  }
                />
              )}
              {toggleLoginInfo && (
                <div
                  className="fullscreen-overlay"
                  onClick={() => setToggleLoginInfo(false)}></div>
              )}
            </li>
          </ul>
        </div>
      </nav>
      {/* <PageContentWrapper sideBars = {sideBars}/> */}
      <Sidebar sideBars={sideBars} />
    </>
  );
};

const LoginInfo = ({
  name,
  email,
  userInfo,
  logo,
  l1_status,
  l2_status,
  l0_status,
  is_conditional,
  is_mettasocial_preonboarded
}) => {
  const getConditionalStatus = () => {
    if (is_conditional === true) {
      return 'Conditional Approved';
    }
  };
  const getStatus = () => {
    if (l1_status === 'submitted' || l0_status === 'submitted') {
      return 'Under Review';
    } else if (l1_status === 'l1_approved' || l0_status === 'l0_approved') {
      return 'Approved';
    } else if (l1_status === 'l1_rejected' || l0_status === 'l0_rejected') {
      return 'Rejected';
    } else if (
      l0_status === 'modification_required' ||
      l1_status === 'modification_required' ||
      l2_status === 'modification_required'
    ) {
      return 'Modification Requested';
    } else {
      return 'Profile Not Submitted';
    }
  };

  const getColor = status => {
    switch (status) {
      case 'new':
        return 'blue-chips-tag';
      case 'l1_approved' || 'l0_approved':
        return 'green-chips-tag';
      case 'submitted':
        return 'orange-chips-tag';
      case 'modification-required':
        return 'yellow-chips-tag';
      case 'l1_rejected' || 'l0_rejected':
        return 'red-chips-tag';
      case 'is_conditional':
        return 'pink-chips-tag';
      default:
        return 'grey-chips-tag';
    }
  };

  return (
    <div className="login-info">
      <div className="bg-white pt-1 pb-2">
        <div className="d-flex flex-column m-3">
          <span className="d-flex align-items-center">
            {/* IMAGE HERE  */}
            <img
              src={logo}
              // height="44px"
              width="45px"
              // style={{
              //   border: '1px solid #81BC30'
              // }}
              style={{
                borderRadius: '10%',
                height: 'fitContent',
                background: 'white',
                padding: '4px'
              }}
              alt="NGO logo"
            />
            <span
              className="d-flex flex-column ml-3 mt-2"
              style={{ wordWrap: 'break-word', width: '15.5em' }}>
              <h4 className="fs-14 color-344256 font-weight-bold">
                {name ? name : userInfo.name}
              </h4>
              <h4 className="fs-12 color-344256 text-wrap">
                {email ? email : userInfo.email}
              </h4>
              <div>
                {is_conditional !== true ||
                (is_conditional === true &&
                  is_mettasocial_preonboarded === true) ? (
                  <span
                    id="status-pill"
                    className={`status-pill fs-10 mt-1 ${getColor(l1_status)}`}>
                    {getStatus()}
                  </span>
                ) : (
                  ''
                )}

                {is_conditional && (
                  <div
                    // id="status-pill"
                    className={`status-pill w-125 fs-10 mt-1 text-left ${getColor(
                      'is_conditional'
                    )}`}>
                    {getConditionalStatus()}
                  </div>
                )}
              </div>
            </span>
          </span>

          <hr className="horizontal-line" />

          <Link
            className="text-info"
            to="/ngo/profile"
            title="Contact Support"
            style={{ textDecoration: 'none' }}>
            <span className="d-flex align-items-center cursor-pointer">
              <i
                className="fa fa-solid fa-user text-7B8FA6 mr-4 pl-2"
                style={{ fontSize: '20px' }}></i>
              <h4 className="color-344256 fs-12 m-0">My Profile</h4>
            </span>
          </Link>

          <hr className="horizontal-line" />

          <span
            className="d-flex align-items-center cursor-pointer"
            onClick={logout}>
            <img
              src={LogoutSVG}
              width="18px"
              height="18px"
              alt="Logout"
              className="mr-4 ml-2"
            />
            <h4 className="color-344256 fs-12 m-0">Logout</h4>
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  state: state
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getHeaderData
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
