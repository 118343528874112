import { Action } from '../constants';

const accountTypes = [
  {
    id: '1',
    name: 'Saving'
  },
  {
    id: '2',
    name: 'Current'
  }
];

export const getTypesOfAccount = () => dispatch => {
  dispatch({
    type: Action.TYPE_OF_ACCOUNT_DROPDOWN_SUCCESS,
    payload: accountTypes
  });
};
