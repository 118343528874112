import React, { useState, useEffect, useCallback } from 'react';
import getDeviceType from '../helpers/getDeviceResolution';
// import ngoIcon from '../assets/images/ngo-icon.png';
import thankyouIcon from '../assets/images/thankyou.png';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import {
  contactFormData,
  aboutFormData,
  document_req
} from '../constants/preOnBoardingFormData';
import { convertFormDataToJson } from '../helpers/profileHelpers';
import { sendMail } from '../helpers/emailSender';
import { Common, Action, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';
import PreOnBoardingSingleField from './preOnBoardingSingleField';

export default function PreOnBoardingContent(props) {
  const isMobile = getDeviceType();
  // const [welcomeScreen, setWelcomeScreen] = useState(false);
  const [contactScreen, setContactScreen] = useState(true);
  const [aboutScreen, setAboutScreen] = useState(false);
  const [documentScreen, setDocumentScreen] = useState(false);
  const [thankyouScreen, setThankyouScreen] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [errorMsgForEmail, setErrorMsgForEmail] = useState([]);
  const [emailId, setEmailId] = useState();
  const [ngoNames, setNgoNames] = useState();
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailOTP, setEmailOTP] = useState();
  const [otpCheck, setOtpCheck] = useState(false);
  const [timer, setTimer] = useState(0);
  const [fullNames, setFullNames] = useState('');
  var setFocus = false;
  const timeOutCallback = useCallback(
    () => setTimer(currTimer => currTimer - 1),
    []
  );

  const dispatch = useDispatch();
  var email;
  var ngoName;
  var fullName;
  var errMsgs = [];

  function focusOnField(id) {
    setFocus = true;
    document.getElementById(id).focus();
  }
  const onClick = e => {
    let validationCheck = false;
    e.preventDefault();
    let data = convertFormDataToJson(new FormData(e.target));
    if (e.target.getAttribute('data-step') === '1') {
      setErrorMsg('');
      errMsgs = [];

      if (data['registration_date']) {
        data['registration_date'] = new Date(
          data['registration_date']
        ).toISOString();
      }
      email = data['email_address'];
      ngoName = data['name_of_ngo'];

      //form 1 validations
      if (data['full_name'] === '') {
        errMsgs.push({ msg: 'Please enter Full Name', label: 'full_name' });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('full_name');
        }
      } else if (
        data['full_name'].length < 3 ||
        data['full_name'].length > 100
      ) {
        errMsgs.push({
          msg: 'Full Name should consists of 3 to 100',
          label: 'full_name'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('full_name');
        }
      } else if (!data['full_name'].match('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')) {
        errMsgs.push({ msg: 'Invalid Full Name', label: 'full_name' });

        validationCheck = true;
        if (!setFocus) {
          focusOnField('full_name');
        }
      }

      fullName = data['full_name'];
      if (data['name_of_ngo'] === '') {
        errMsgs.push({ msg: 'Please enter NGO Name', label: 'name_of_ngo' });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('name_of_ngo');
        }
      } else if (
        data['name_of_ngo'].length < 3 ||
        data['name_of_ngo'].length > 100
      ) {
        errMsgs.push({
          msg: 'NGO name should consists of 3 to 100',
          label: 'name_of_ngo'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('name_of_ngo');
        }
      }

      if (data['registration_date'] === '') {
        errMsgs.push({
          msg: 'Please enter Registration Date',
          label: 'registration_date'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('registration_date');
        }
      }
      if (data['mobile_no'].length < 10) {
        errMsgs.push({
          msg: 'Mobile no. should contain only 10 digits',
          label: 'mobile_no'
        });
        validationCheck = true;
      } else if (!data['mobile_no'].match('[0-9]{10}$')) {
        errMsgs.push({ msg: 'Invalid mobile number', label: 'mobile_no' });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('mobile_no');
        }
      } else if (!data['email_address'].length === 0) {
        errMsgs.push({
          msg: 'Please enter Email Address',
          label: 'email_address'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('email_address');
        }
      }
      //form 1 validations end
      if (errMsgs === undefined || errMsgs.length === 0) {
        setErrorMsg([]);
        validationCheck = false;
      } else {
        setErrorMsg(errMsgs);
      }
      if (validationCheck === false) {
        if (otpCheck === false) {
          toast.error('Please verify OTP', {
            icon: '❌'
          });
        } else if (
          data['name_of_ngo'] === ' ' ||
          data['name_of_ngo'].length === 0 ||
          data['name_of_ngo'].trim().length === 0
        ) {
          toast.error('NGO Name cannot be empty', {
            icon: '❌'
          });
          validationCheck = true;
        } else {
          setNgoNames(ngoName);
          setEmailId(email);
          setFullNames(fullName);
          handleClick(3);
          validationCheck = false;
        }
      }
      if (validationCheck === false) {
        props
          .onSurveyStepOne({
            at_survey_step: e.target.getAttribute('data-step'),
            [e.target.getAttribute('data-wrapper-key')]: data
          })
          .then(() => {
            toast.success('Data saved!', {
              icon: '✅'
            });
          })
          .catch(() => {
            toast.error(
              'Uh-Oh! Looks like we are having server issues., please contact our support!',
              {
                icon: '❌'
              }
            );
          });
      }
      // setTimeout(() => {
      //   setErrorMsg([]);
      // }, 6000);
    }

    if (e.target.getAttribute('data-step') === '2') {
      setErrorMsg('');
      errMsgs = [];
      data = { ...data, email_address: emailId };
      //form 2 validations
      if (
        data['ngo_objective'].length < 3 ||
        data['ngo_objective'].length > 2000
      ) {
        errMsgs.push({
          msg: 'NGO Objective should consist of 3-2000 characters',
          label: 'ngo_objective'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('ngo_objective');
        }
      } else if (data['ngo_objective'].match(/^\s*$/)) {
        errMsgs.push({
          msg: 'NGO Objective cannot be empty',
          label: 'ngo_objective'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('ngo_objective');
        }
      }
      if (data['pan_card_no'] === '') {
        errMsgs.push({
          msg: 'Please enter PAN Card number ',
          label: 'pan_card_no'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('pan_card_no');
        }
      } else if (
        !data['pan_card_no'].toUpperCase().match('[A-Z]{5}[0-9]{4}[A-Z]{1}')
      ) {
        errMsgs.push({
          msg:
            'Pancard no. should be in format ABCDE1234F (All capital letters) ',
          label: 'pan_card_no'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('pan_card_no');
        }
      }
      if (
        data['legal_structure'] === '' ||
        data['legal_structure'] === undefined
      ) {
        errMsgs.push({
          msg: 'Please select Legal Structure',
          label: 'legal_structure'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('legal_structure');
        }
      }
      if (data['primary_cause_areas'] === undefined) {
        errMsgs.push({
          msg: 'Please select Primary Cause Areas',
          label: 'primary_cause_areas'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('primary_cause_areas');
        }
      }
      if (data['secondary_cause_areas'] === undefined) {
        errMsgs.push({
          msg: 'Please select Secondary Cause Areas',
          label: 'secondary_cause_areas'
        });
        validationCheck = true;
        if (!setFocus) {
          focusOnField('secondary_cause_areas');
        }
      }
      //form 2 validations end

      if (errMsgs === undefined || errMsgs.length === 0) {
        setErrorMsg('');
        handleClick(4);
        validationCheck = false;
      } else {
        setErrorMsg(errMsgs);
      }

      // setTimeout(() => {
      //   setErrorMsg([]);
      // }, 6000);
    }
    if (e.target.getAttribute('data-step') === '3') {
      let formData = {};

      for (let i = 0; i < e.target.length - 1; i++) {
        let status = e.target.elements[i].value === 'true';
        formData[e.target.elements[i].getAttribute('name')] = status;
      }
      data = { ...formData, email_address: emailId };

      sendMail({
        // templateName: 'preOnboardingSubmit',
        templateName: 'preOnboardingSubmitNewDesign',
        sendTo: emailId,
        subject: 'Success: Pre-registration Submitted',
        templateData: {
          ngo_name: ngoNames, //pass the ngo name
          full_name: fullNames
        }
      });
      sendMail({
        // templateName: 'preOnboardingSubmitAlert',
        templateName: 'preOnboardingSubmitNewDesign',
        sendTo: 'ngo.partnerships@mettasocial.com',
        subject: 'Pre-On Boarding Form Request',
        templateData: {
          ngo_name: ngoNames //pass the ngo name
        }
      });

      handleClick(5);
    }
    if (!validationCheck && emailVerified) {
      if (e.target.getAttribute('data-step') === '2') {
        props
          .onSurveyStepTwo({
            at_survey_step: e.target.getAttribute('data-step'),
            [e.target.getAttribute('data-wrapper-key')]: data
          })
          .then(() => {
            toast.success('Data saved!', {
              icon: '✅'
            });
          })
          .catch(() => {
            toast.error(
              'Uh-Oh! Looks like we are having server issues., please contact our support!',
              {
                icon: '❌'
              }
            );
          });
      } else if (e.target.getAttribute('data-step') === '3') {
        props
          .onSurveyStepThree({
            at_survey_step: e.target.getAttribute('data-step'),
            [e.target.getAttribute('data-wrapper-key')]: data
          })
          .then(() => {
            toast.success('Data saved!', {
              icon: '✅'
            });
          })
          .catch(() => {
            toast.error(
              'Uh-Oh! Looks like we are having server issues., please contact our support!',
              {
                icon: '❌'
              }
            );
          });
      }
    }
    return false;
  };

  function handleClick(screen) {
    if (screen === 2) {
      if (document.getElementById('ngo').classList.contains('select-img')) {
        // setWelcomeScreen(false);
        setAboutScreen(false);
        setDocumentScreen(false);
        setThankyouScreen(false);
        setContactScreen(true);
      } else {
        toast.error('Please select your option', {
          icon: '❌'
        });
      }
    } else if (screen === 3) {
      // setWelcomeScreen(false);
      setContactScreen(false);
      setDocumentScreen(false);
      setThankyouScreen(false);
      setAboutScreen(true);
    } else if (screen === 4) {
      // setWelcomeScreen(false);
      setContactScreen(false);
      setAboutScreen(false);
      setThankyouScreen(false);
      setDocumentScreen(true);
    } else if (screen === 5) {
      // setWelcomeScreen(false);
      setContactScreen(false);
      setAboutScreen(false);
      setDocumentScreen(false);
      setThankyouScreen(true);
    }
    // } else {
    //   setWelcomeScreen(true);
    //   setContactScreen(false);
    //   setAboutScreen(false);
    //   setDocumentScreen(false);
    //   setThankyouScreen(false);
    // }
  }

  let emailOtp = Math.floor(1000 + Math.random() * 9000).toString();

  function verifyEmail(e) {
    e.preventDefault();
    setEmailOTP(emailOtp);
    document.getElementById('otp').value = '';
    document.getElementById('otp').disabled = false;

    const emailElement = document.getElementById('email_address');
    const otpElement = document.getElementById('otpText');
    otpElement.classList.remove('d-none');
    document.getElementById('otp').value = '';
    let ngoEmail = emailElement.value;
    if (!ngoEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setErrorMsgForEmail({
        msg: 'Email should be in "name@example.com" format',
        label: 'email_address'
      });
      otpElement.classList.add('d-none');
    } else {
      setErrorMsgForEmail('');
      otpElement.classList.remove('d-none');
      document.getElementById('email_address').classList.add('disabled');
      document.getElementById('Request OTP').classList.add('disabled');
      let res;
      let instance = HttpRequest.getInstance('HIROKU');
      instance
        .get(
          `${EndPoint.getApiUrl(
            'NGO',
            'PRE_ONBOARDING',
            'HIROKU'
          )}?email=${ngoEmail}`
        )
        .then(response => {
          dispatch({
            type: Action.NGO_PREONBOARDING_SAVED_DATA_SUCCESS,
            payload: response.result
          });
          res = response.data.result;

          if (res.at_survey_step === '3') {
            toast.error('Email ID already exists', {
              icon: '❌'
            });
          } else if (res.at_survey_step !== 3) {
            sendMail({
              // templateName: 'preOnBoardingOtp',
              templateName: 'preOnBoardingOtpNewDesign',
              sendTo: ngoEmail,
              subject: ' OTP to verify email ID',
              templateData: {
                otp: emailOtp
              }
            });
            toast.success('Email sent successfully', {
              icon: '✅'
            });
          }
        })
        .catch(error => {
          console.error(error.response);
        });
    }
  }
  function verifyOtp(e) {
    const otpElement = document.getElementById('otp');
    const resendOtp = document.getElementById('Resend OTP');
    let enteredOtp = otpElement.value;
    if (enteredOtp === emailOTP) {
      setOtpCheck(true);
      setEmailVerified(true);
      toast.success('OTP Verified', {
        icon: '✅'
      });
      resendOtp.classList.add('disabled');
      document.getElementById('otp').disabled = true;
    } else {
      toast.error('Incorrect OTP, please try again', {
        icon: '❌'
      });
    }
  }
  function resendOtp(e) {
    e.preventDefault();
    let ngoEmail = document.getElementById('email_address').value;

    document.getElementById('otp').value = '';
    document.getElementById('otp').disabled = false;
    setEmailOTP(emailOtp);
    setTimer(30);
    setErrorMsg({
      msg: timer,
      label: 'otp'
    });
    sendMail({
      // templateName: 'preOnBoardingOtp',
      templateName: 'preOnBoardingOtpNewDesign',
      sendTo: ngoEmail,
      subject: ' OTP to verify email ID',
      templateData: {
        otp: emailOtp
      }
    });
    toast.success('Email sent successfully', {
      icon: '✅'
    });
    let otpDiv = document.getElementById('Resend OTP');
    otpDiv.classList.add('disabled');
    setTimeout(() => {
      otpDiv.classList.remove('disabled');
      setErrorMsg('');
    }, 30000);
  }
  function resetEmail() {
    setEmailVerified(false);
    document.getElementById('email_address').value = '';
    document.getElementById('otp').value = '';
    document.getElementById('Request OTP').classList.remove('disabled');
    document.getElementById('email_address').classList.remove('disabled');
    document.getElementById('otpText').classList.add('d-none');
    document.getElementById('Resend OTP').classList.remove('disabled');
    setErrorMsg('');
  }
  function getFunction(name) {
    if (name === 'verifyEmail') {
      return verifyEmail;
    } else if (name === 'resendOtp') {
      return resendOtp;
    } else if (name === 'resetEmail') {
      return resetEmail;
    }
  }

  useEffect(() => {
    const masterDataOptions = Common.NGO.MASTER_OPTIONS;
    props.getLegalStructure();
    props.getMasterData([
      masterDataOptions.CAUSE_CATEGORY,
      masterDataOptions.NESTED_CAUSE_CATEGORY
    ]);
  }, []);
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);
  return (
    <div
      className={
        isMobile ? 'bg-white text-left container-device' : 'container-onboard'
      }>
      {/* {!thankyouScreen && (
        <div
          className={
            isMobile ? 'fs-60 font-weight-bold mt-6' : 'fs-36 pd-inner'
          }>
          Welcome...!
        </div>
      )} */}
      {!thankyouScreen && (
        <div
          className={
            isMobile
              ? 'fs-48 font-weight-bold mt-4 lato-bold'
              : 'fs-18 pd-inner lato-bold'
          }>
          Welcome...!{' '}
          <p
            className={
              isMobile ? 'fs-32 mt-6  mt-1 fw-400' : 'fs-14 mt-1 fw-400'
            }>
            Please provide the following information about your NGO for us to
            understand you better.
          </p>
        </div>
      )}
      {thankyouScreen && (
        <div
          className={
            isMobile
              ? 'fs-60 font-weight-bold mt-6 text-center lato-medium'
              : 'fs-36 text-center lato-medium'
          }>
          Thank you...!
        </div>
      )}
      {!thankyouScreen && (
        <div className="row col-12">
          {/* {!welcomeScreen && (
            <div className="col-3">
              <hr className={welcomeScreen ? 'active hr' : 'hr'}></hr>
            </div>
          )} */}
          <div className="col-4">
            <hr className={contactScreen ? 'active hr' : 'hr'}></hr>
          </div>
          <div className="col-4">
            <hr className={aboutScreen ? 'active hr' : 'hr'}></hr>
          </div>
          <div className="col-4">
            <hr className={documentScreen ? 'active hr' : 'hr'}></hr>
          </div>
        </div>
      )}
      {/*welcome screen */}
      {/* {welcomeScreen && (
        <>
          <div className="form-welcm">
            <div
              className={
                isMobile
                  ? 'fs-36 mt-6 fw-500 lato-regular'
                  : 'fs-18 mt-4 ml-20 fw-500 lato-regular'
              }>
              To help Us understand You better, please confirm if you are an NGO
              and fill out the upcoming details.
            </div>
            <div
              className={
                isMobile
                  ? 'fs-48 font-weight-bold mt-10 mb-5'
                  : 'fs-onb-20 mt-5 font-weight-bold ml-20 mb-3'
              }>
              Please confirm:
            </div>

            <div
              onClick={() => selectOption('ngo')}
              id="ngo"
              className="cursor-pointer mt-1 justify-content-around text-center img-ob">
              <img
                src={ngoIcon}
                width={isMobile ? '300px' : '180px'}
                height={'auto'}
                alt="ngo"></img>
              <div
                className={
                  isMobile
                    ? 'fs-36 montserrat-medium'
                    : 'fs-20 montserrat-medium'
                }>
                Yes, I am an NGO
              </div>
            </div>

            <div className="text-center disable" id="welcm-btn">
              <button
                onClick={() => handleClick(2)}
                type="button"
                className={
                  isMobile
                    ? ' btn-custom-1 mt-8 btn-mobile pb-4'
                    : 'btn btn-custom-device btn-lg h5 mt-4 font-weight-bold'
                }>
                Next
              </button>
            </div>
          </div>
        </>
      )} */}
      {/*welcome screen ends*/}

      {/*contact details screen */}
      {contactScreen && (
        <>
          <div className="d-flex ">
            <div
              className={
                isMobile
                  ? 'fs-45 mt-6 fw-600 mb-4 lato-medium'
                  : 'fs-16 fw-600 pd-subtitle lato-medium'
              }>
              Contact Details:
            </div>
          </div>

          <div className="d-flex flex-column w-100">
            <div
              className={
                isMobile
                  ? 'onboarding-form complete-registration-form'
                  : ' complete-registration-form onboarding-form-desktop'
              }>
              <div className="">
                <div className=" ">
                  {contactFormData.map((stepData, stepIndex) => (
                    <section
                      className={
                        isMobile ? 'mb-2' : 'container container-inner mt-3'
                      }
                      key={stepIndex}
                      id={`step-container-${stepIndex + 1}`}>
                      <div className="  font-size">
                        <div className="card card-body px-2">
                          <form
                            method="post"
                            id={`step-form-${stepIndex}`}
                            onSubmit={onClick}
                            action={stepData.contact_details_fields.apiUrl}
                            data-step={'1'}
                            className={
                              stepData.contact_details_fields.field === 0
                                ? ''
                                : 'form-row'
                            }
                            data-wrapper-key={'basic_details'}>
                            <div className="w-100 overflow pd-r40">
                              {stepData.contact_details_fields.map(
                                (input, index) => {
                                  let func = null;
                                  if (
                                    input.clickablefunction ||
                                    input.onchange
                                  ) {
                                    func = getFunction(input.clickablefunction);
                                  }
                                  if (input.resetValue) {
                                    func = getFunction(input.resetValue);
                                  }
                                  return (
                                    <>
                                      {
                                        <div
                                          className={
                                            isMobile &&
                                            !input.inputtype === 'otpText'
                                              ? 'form-group  pl-4  col-md-12'
                                              : input.field === 1
                                              ? 'form-group  pl-4 col-md-5 '
                                              : input.field === 2
                                              ? 'form-group  pl-4  col-md-7'
                                              : input.field === 0 &&
                                                !input.inputtype === 'otpText'
                                              ? 'form-group  pl-4  col-md-12'
                                              : input.field === 0 &&
                                                input.inputtype === 'otpText'
                                              ? 'd-none'
                                              : isMobile &&
                                                input.field === 0 &&
                                                input.inputtype === 'otpText'
                                              ? 'd-none'
                                              : ' '
                                          }
                                          id={
                                            input.inputtype === 'otpText'
                                              ? 'otpText'
                                              : ' '
                                          }>
                                          <PreOnBoardingSingleField
                                            triggerFunction={verifyOtp}
                                            input={input}
                                            key={`${stepIndex}-${index}`}
                                            stepIndex={stepIndex}
                                            disabled={false}
                                            state={props.state}
                                            errorMsg={errorMsg}
                                            errorMsgForEmail={errorMsgForEmail}
                                            clickabletext={
                                              input.clickabletext
                                                ? input.clickabletext
                                                : null
                                            }
                                            clickablefunction={
                                              func ? func : null
                                            }
                                            resetText={input.resetText}
                                            resetFunction={resetEmail}
                                            onchange={func ? func : null}
                                            timer={timer}
                                          />
                                        </div>
                                      }
                                      <br></br>
                                    </>
                                  );
                                }
                              )}
                            </div>
                            <hr className=" hr-bottom"></hr>
                            <div className="form-group col-md-12 d-flex justify-content-center pb-4 mb-0">
                              <div className="text-center preonboard-btn">
                                <button
                                  type="submit"
                                  disabled={!emailVerified}
                                  className={
                                    isMobile
                                      ? ' mt-5 btn-custom-1 btn-mobile pb-4'
                                      : 'btn  btn-custom-device btn-lg h5 mt-2 font-weight-bold'
                                  }>
                                  Next
                                </button>
                                <br></br>
                                <span
                                  className={
                                    isMobile
                                      ? 'color-81BC30 fs-36'
                                      : 'mt-1 color-81BC30 fs-14'
                                  }>
                                  {`Note: Kindly fill & review the details before
                                  clicking on Next`}
                                </span>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </section>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/*contact details screen  ends*/}

      {/*about ngo screen */}
      {aboutScreen && (
        <>
          <div className="d-flex">
            <div
              className={
                isMobile
                  ? 'fs-45 mt-6 fw-600 mb-4 lato-medium'
                  : 'fs-16 fw-600 pd-subtitle lato-medium'
              }>
              About the NGO:
            </div>
          </div>

          <div className="d-flex flex-column w-100">
            <div
              className={
                isMobile
                  ? 'onboarding-form about complete-registration-form'
                  : 'about-form-desktop onboarding-form-desktop complete-registration-form'
              }>
              <div className="">
                <div className=" ">
                  {aboutFormData.map((stepData, stepIndex) => (
                    <section
                      className={
                        isMobile ? 'mb-2' : 'container container-inner'
                      }
                      key={stepIndex}
                      id={`step-container-2`}>
                      <div className="  font-size">
                        <div className="card card-body px-2">
                          <form
                            method="post"
                            id={`step-form-2`}
                            className="about-ngo"
                            onSubmit={onClick}
                            action={stepData.apiUrl}
                            data-step={'2'}
                            data-wrapper-key={'basic_details'}>
                            <div className="overflow pd-r40">
                              {stepData.about_ngo_fields.map((input, index) => (
                                <>
                                  {
                                    <div>
                                      <PreOnBoardingSingleField
                                        input={input}
                                        key={`${stepIndex}-${index}`}
                                        stepIndex={stepIndex}
                                        disabled={false}
                                        state={props.state}
                                        errorMsg={errorMsg}
                                      />
                                    </div>
                                  }
                                </>
                              ))}
                            </div>
                            <hr className=" hr-bottom"></hr>
                            <div className="form-group col-md-12 d-flex justify-content-center pb-4 mb-0">
                              <div className="text-center preonboard-btn">
                                <button
                                  type="submit"
                                  className={
                                    isMobile
                                      ? ' mt-5 btn-custom-1 btn-mobile pb-4'
                                      : 'btn  btn-custom-device btn-lg h5 mt-2 font-weight-bold'
                                  }>
                                  Next
                                </button>
                                <br></br>
                                <span
                                  className={
                                    isMobile
                                      ? 'color-81BC30 fs-36'
                                      : 'mt-1 color-81BC30 fs-14'
                                  }>
                                  {`Note: Kindly fill & review the details before
                                  clicking on Next`}
                                </span>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </section>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/*about ngo screen  ends*/}

      {/*document screen */}
      {documentScreen && (
        <>
          <div className="d-flex">
            <div
              className={
                isMobile
                  ? 'fs-45 mt-6 fw-600 mb-4 lato-medium'
                  : 'fs-16 fw-600 pd-subtitle lato-medium'
              }>
              Preonboard Checklist:
            </div>
          </div>

          {document_req.map((stepData, stepIndex) => (
            <>
              <form
                method="post"
                id={`step-form-3`}
                onSubmit={onClick}
                data-step={'3'}
                data-wrapper-key={'basic_details'}>
                <div className="overflow mt-4">
                  <div className={isMobile ? 'mb-2' : ''}>
                    <div className="row mt-2 ">
                      {stepData.required_doc.map((input, index) => (
                        <>
                          <PreOnBoardingSingleField
                            input={input}
                            key={`${stepIndex}-${index}`}
                            stepIndex={stepIndex}
                            disabled={false}
                            state={props.state}
                            ste="step_3"
                          />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <hr className=" hr-bottom"></hr>
                <div className="form-group col-md-12 d-flex justify-content-center pb-4 mb-0">
                  <div className="text-center preonboard-btn">
                    <button
                      type="submit"
                      className={
                        isMobile
                          ? ' mt-8 btn-mobile btn-custom-1  pb-4'
                          : 'btn  btn-lg h5 mt-2 btn-custom-device font-weight-bold'
                      }>
                      Submit
                    </button>
                    <br></br>
                    <span
                      className={
                        isMobile
                          ? 'color-81BC30 fs-36'
                          : 'mt-1 color-81BC30 fs-14'
                      }>
                      {`Note: Kindly fill & review the details before clicking on Submit`}
                    </span>
                  </div>
                </div>
              </form>
            </>
          ))}
        </>
      )}
      {/*document screen  ends*/}

      {/*Thank you screen */}
      {thankyouScreen && (
        <>
          <div className="fw-600 container text-center">
            <div
              className={
                isMobile
                  ? 'fs-36 mt-6 mb-4 lato-medium'
                  : 'fs-14 mt-4 lato-medium'
              }>
              For your valuable time and for taking a step towards a better
              tomorrow. Your details have been saved successfully in our system.
              We will reach out to you soon..!
            </div>
          </div>
          <div className="d-flex mt-4 justify-content-center">
            <span>
              <img
                src={thankyouIcon}
                width={isMobile ? '300px' : '180px'}
                height={'auto'}
                alt="corporate"></img>
            </span>
          </div>
          <div className="text-center fw-600">
            <div
              className={
                isMobile ? 'fs-36 mt-4 lato-medium' : 'fs-14 mt-4 lato-medium'
              }>
              Meanwhile check out our website for more information..!
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={() =>
                (window.location.href = process.env.REACT_APP_METTASOCIAL_URL)
              }
              className={
                isMobile
                  ? ' mt-4 btn-custom-1 btn-mobile pb-4 w-none'
                  : '  btn-lg btn-custom-device h5 mt-4 font-weight-bold'
              }>
              Visit Website
            </button>
          </div>
        </>
      )}
      {/*Thank you screen  ends*/}
    </div>
  );
}
