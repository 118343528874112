import { Action } from '../constants';
const initialState = {
  missionInfo: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    //list

    case Action.MISSION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.MISSION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        missionList: action.payload
      };

    case Action.MISSION_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    //transactions
    case Action.MISSION_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.MISSION_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        missionTransactionSuccess: action.payload
      };

    case Action.MISSION_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false
      };

    case Action.MISSION_FUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        missionFundsSuccess: action.payload
      };

    case Action.MISSION_FUNDS_FAILURE:
      return {
        ...state,
        loading: false
      };
    //STATISTICS
    case Action.MISSION_STATISTICS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.MISSION_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        missionStatisticsSuccess: action.payload
      };

    case Action.MISSION_STATISTICS_FAILURE:
      return {
        ...state,
        loading: false
      };

    // Create Mission
    case Action.MISSION_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.MISSION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createModifyMissionSuccessResponse: action.payload
      };

    case Action.MISSION_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        createModifyMissionErrorResponse: action.payload
      };
    // Mission edit screen
    case Action.MISSION_EDIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.MISSION_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        editMissionSuccessResponse: action.payload,
        editMissionErrorResponse: { code: action.payload.status }
      };
    case Action.MISSION_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        editMissionErrorResponse: action.payload,
        editMissionSuccessResponse: { code: action.payload.status }
      };
    //NGO - Mission Detail screen
    case Action.MISSION_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.MISSION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        missionDetailSuccessResponse: action.payload
      };
    case Action.MISSION_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        missionDetailErrorResponse: action.payload
      };
    //NGO - Mission Preview screen
    case Action.MISSION_PREVIEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.MISSION_PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        previewMissionSuccessResponse: action.payload
      };
    case Action.MISSION_PREVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        previewMissionErrorResponse: action.payload
      };
    //NGO - Mission Preview screen
    case Action.MISSION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.MISSION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        missionUpdateSuccessResponse: action.payload
      };
    case Action.MISSION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        missionUpdateErrorResponse: action.payload
      };

    case Action.MISSION_RESET_RESPONSE:
      return {
        ...state,
        editMissionSuccessResponse: { code: null },
        editMissionErrorResponse: { code: null },
        createModifyMissionSuccessResponse: { code: null },
        createModifyMissionErrorResponse: { code: null },
        missionDetailSuccessResponse: { code: null },
        missionDetailErrorResponse: { code: null }
      };
    //NGO - Mission Unpublished
    case Action.MISSION_UNPUBLISH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case Action.MISSION_UNPUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        missionUnpublishSuccessResponse: action.payload
      };
    case Action.MISSION_UNPUBLISH_FAILURE:
      return {
        ...state,
        loading: false,
        missionUnpublishErrorResponse: action.payload
      };
    default:
      return state;
  }
};
