import XLSX from 'xlsx/dist/xlsx.full.min.js';
export function downloadExcelForActualAndPlannedBudget(props) {
  var budgetArr = [];
  {
    props.expensesNameArr !== undefined &&
      props.expensesNameArr?.length > 0 &&
      props.expensesNameArr.map((entry, idx) => {
        let total = 0;
        return (
          props.actualVsProposedData[entry]?.length > 0 &&
          props.actualVsProposedData[entry]?.map((itm, index) => {
            total = total + itm.project_actual_amount;

            let obj = {
              'Expense Category': itm.project_expenses_name,
              'Proposed Expense Sub-Category':
                itm.project_actual_amount === null ||
                (itm.project_actual_amount !== null &&
                  itm.project_proposed_amount !== 0)
                  ? itm.expense_sub_category
                  : '--',
              'Actual Expense Sub-Category':
                itm.project_actual_amount !== null ||
                (itm.project_proposed_amount !== 0 &&
                  itm.project_actual_amount !== null)
                  ? itm.expense_sub_category === null
                    ? '--'
                    : itm.expense_sub_category
                  : '--',
              DETAILS: itm.project_expenses_discription,
              'PROPOSED FUND UTILISATION':
                itm.project_proposed_amount !== null
                  ? '₹ ' + itm.project_proposed_amount.toString()
                  : '0',
              'ACTUAL FUND UTILISATION':
                itm.project_actual_amount !== null
                  ? '₹ ' + itm.project_actual_amount.toString()
                  : '0',
              '% OF ACTUAL BUDGET':
                ((itm.project_actual_amount / props.campaignFunds) * 100)
                  .toFixed(2)
                  .toString() + '%'
            };
            budgetArr.push(obj);

            if (index + 1 === props.actualVsProposedData[entry]?.length) {
              let sectionObj = {
                'Expense Category': 'SECTION TOTAL',
                'ACTUAL FUND UTILISATION': '₹' + total.toString(),
                '% OF ACTUAL BUDGET':
                  ((total / props.campaignFunds) * 100).toFixed(2).toString() +
                  '%'
              };
              budgetArr.push(sectionObj);
            }
          })
        );
      });
  }
  const data = budgetArr;
  /* create workbook & worksheet */
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  ws['!cols'] = [
    { width: 30 },
    { width: 40 },
    { width: 40 },
    { width: 40 },
    { width: 40 },
    { width: 40 },
    { width: 40 },
    { width: 40 }
  ];

  /* add styling to headers and section total row */
  ws['A1'].s = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    fill: { patternType: 'solid', fgColor: { rgb: 'D9F0FF' } }
  };
  ws['B1'].s = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    fill: { patternType: 'solid', fgColor: { rgb: 'FFC000' } }
  };
  ws['C1'].s = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    fill: { patternType: 'solid', fgColor: { rgb: 'FFC000' } }
  };
  ws['D1'].s = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    fill: { patternType: 'solid', fgColor: { rgb: 'FBF8DD' } }
  };
  ws['E1'].s = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    fill: { patternType: 'solid', fgColor: { rgb: 'FBF8DD' } }
  };
  ws['F1'].s = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    fill: { patternType: 'solid', fgColor: { rgb: 'FBF8DD' } }
  };
  ws['G1'].s = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    fill: { patternType: 'solid', fgColor: { rgb: 'FBF8DD' } }
  };

  const lastRowIndex = XLSX.utils.decode_range(ws['!ref']).e.r;
  for (let i = 0; i <= lastRowIndex; i++) {
    const cellRef = XLSX.utils.encode_cell({ r: i, c: 0 });
    const cellValue = ws[cellRef]?.v;
    if (cellValue === 'Section Total') {
      const blankRow = { t: 's', v: '' };
      const range = XLSX.utils.decode_range(ws['!ref']);
      range.e.r = range.e.r + 1;
      XLSX.utils.sheet_add_aoa(ws, [[blankRow, blankRow, blankRow, blankRow]], {
        origin: { r: i + 1, c: 0 }
      });
    }
  }
  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, 'Actual And Planned Budget');
  /* save to file */
  XLSX.writeFile(wb, 'Actual_And_PlannedBudget.xlsx');
}
