import { EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';
export const getDonationReportList = (ngoId, skip, limit, data) => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('WORKPLACE');

    instance
      .post(
        `${EndPoint.getApiUrl(
          'NGO',
          'GET_DONATION_REPORT',
          'HIROKU'
        )}?ngo_id=${ngoId}&skip=${skip}&limit=${limit}`,
        data
      )
      .then(res => {
        if (res.status === 200) {
          resolve(res.data?.result);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const sendReceiptToAllRecords = ngoId => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('WORKPLACE');

    instance
      .post(
        `${EndPoint.getApiUrl(
          'NGO',
          'SEND_RECEIPTS_TO_ALL',
          'HIROKU'
        )}?ngo_id=${ngoId}`
      )
      .then(res => {
        if (res.data.code === '200') {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getDonationReportExport = (
  ngoId,
  skip,
  limit,
  data
) => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('HIROKU');
    instance
      .post(
        `${EndPoint.getApiUrl(
          'NGO',
          'GET_DONATION_REPORT_EXPORT',
          'HIROKU'
        )}?ngo_id=${ngoId}&skip=${skip}&limit=${limit}`,
        data
      )
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getDonationReportExport10Bd = (
  ngoId,
  skip,
  limit,
  data
) => dispatch => {
  return new Promise((resolve, reject) => {
    let instance = HttpRequest.getInstance('HIROKU');
    instance
      .post(
        `${EndPoint.getApiUrl(
          'NGO',
          'GET_DONATION_REPORT_EXPORT_10BD',
          'HIROKU'
        )}?ngo_id=${ngoId}&skip=${skip}&limit=${limit}`,
        data
      )
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
