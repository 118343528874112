export const getNumber = str => {
  if (typeof str === 'number') {
    str = str.toString();
  }
  if (str !== null && str !== undefined) {
    str = str.replace(/,/g, ''); // remove commas
    var arr = str.split('.');

    let out = new Array();
    if (!isNaN(arr[0])) {
      out.push(arr[0]);
    }
    if (arr[1] && !isNaN(arr[1])) {
      out.push('.', arr[1]);
    }

    return Number(out.join(''));
  }
};
export const convertInCommaFormat = val => {
  if (val === undefined) {
    return val;
  }
  if (typeof val === 'number') {
    val = val.toString();
  }
  if (typeof val === 'string') {
    let num = getNumber(val);
    if (num === 0) {
      return '0';
    } else if (num === '') {
      return '';
    } else {
      return num.toLocaleString();
    }
    // return num === 0 ? '' : num.toLocaleString();
  } else {
    return '';
  }
};

export const convertInCommaIndianFormat = value => {
  let val = value.replace(/^0+|[^0-9,]|^0*(0+[1-9]+)/g, '');
  val = val.replace(/[^0-9]/g, '');
  if (val) {
    const sanitizedValue = val.replace(/[^0-9]/g, '');
    const formattedValue = Number(sanitizedValue).toLocaleString('en-IN');
    return formattedValue;
  } else {
    return val;
  }
};

export const removeCommas = value => {
  if (value) {
    const rawValue = value;
    const sanitizedValue = rawValue.replace(/,/g, '');
    return sanitizedValue;
  }
};
