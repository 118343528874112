import React, { useState, useEffect } from 'react';
import './Styles.css';
import './Modals/modal.css';
import Table from './commonTable';
import { getExpenseSubCategory } from '../../actions/mneDashboard.actions';
import { fundColumn } from '../../constants/MNEDashboard';
import DayPicker, { DateUtils } from 'react-day-picker';
import allocatedFunds from '../../assets/images/Allocated-Funds.svg';
import balancedFunds from '../../assets/images/Balance-Funds.svg';
import consumedFunds from '../../assets/images/funds-consumed.svg';
import disbursedFunds from '../../assets/images/Disbursed-Funds.svg';
import inHandFunds from '../../assets/images/in-hand-funds.svg';
// import readXlsxFile from 'read-excel-file'; //COMMENTED FOR FUTURE USE
import Modal from './Modals/Modal';
import { toast } from 'react-hot-toast';
import {
  convertInCommaFormat,
  getNumber
} from '../../helpers/commaSeparatedInput';
import { CSVLink } from 'react-csv';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
var arrOfExpenses = [];
var groupByExpenseNameArr = [];
function FundUtilizations(props) {
  const columns = fundColumn;
  const [showDate, setShowDate] = useState(false);
  const [dateRange, setdateRange] = useState({});
  const [expenseData, setExpenseData] = useState([]);
  const [selectedExpenseIdRecord, setSelectedExpenseIdRecord] = useState([]);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const campaignId = window.location.pathname.split('/')[3];
  const [openImg, setOpenImg] = useState(false);
  const [receiptSrc, setReceiptSrc] = useState('');
  const [expenseDescriptions, setExpenseDescriptions] = useState([]);
  const [expenseSubCategory, setExpenseSubCategory] = useState([]);
  const [receiptExtension, setReceiptExtension] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [disableAddExpense, setDisableAddExpense] = useState(false);
  const [showDeleteExpenseModal, setShowDeleteExpenseModal] = useState(false);
  const getExpenseSubCategory = categoryId => {
    let filteredSunCategoryList = props.allExpenseSubCategoryList?.filter(
      x => x.expense_category_id === parseInt(categoryId)
    );
    setExpenseSubCategory(filteredSunCategoryList);
  };

  const isSelectingFirstDay = (fromDate, toDate, day) => {
    const isBeforeFirstDay = fromDate && DateUtils.isDayBefore(day, fromDate);
    const isRangeSelected = fromDate && toDate;
    return !fromDate || isBeforeFirstDay || isRangeSelected;
  };

  if (props.proposedExpenseCategoriesData?.length > 0) {
    groupByExpenseNameArr = [];
    arrOfExpenses = [];
    if (props.proposedExpenseCategoriesData?.length > 0) {
      props.proposedExpenseCategoriesData.forEach(element => {
        let recordExpense = props.expenseList?.filter(itm => {
          return itm.id === parseInt(element?.project_expenses_id);
        });
        let obj = {
          id: parseInt(element.expense_category_id),
          // expense_sub_category: element.project_expenses_id,
          name: element.project_expenses_name,
          description: element.project_expenses_discription,
          budgetId: parseInt(element.project_expenses_id),
          amount: recordExpense[0]?.amount,
          expTypeId: recordExpense[0]?.expense_type_id,
          expense_sub_category_id: recordExpense[0]?.expense_sub_category_id,
          quantity:
            recordExpense[0]?.quantity === null ? 1 : recordExpense[0]?.quantity
          // amount: element.project_proposed_amount,
          // quantity:1
        };
        arrOfExpenses.push(obj);
      });
    }

    groupByExpenseNameArr = arrOfExpenses.reduce((expense, currentValue) => {
      const id = currentValue.id;
      const group = expense[id] || [];
      return {
        ...expense,
        [id]: [...group, currentValue]
      };
    }, {});
    arrOfExpenses = arrOfExpenses.filter(
      (record, index, self) =>
        index === self.findIndex(r => r.name === record.name)
    );
  }
  const clickHandler = {
    handleDateRangeClick: () => {
      this.setState({ renderDateRange: !this.state.renderDateRange });
    },
    handleDayClick: day => {
      const { fromDate, toDate } = dateRange;
      if (fromDate && toDate && day >= fromDate && day <= toDate) {
        // this.clickHandler.handleResetDateFilterClick();
        return;
      }
      if (isSelectingFirstDay(fromDate, toDate, day)) {
        setdateRange({
          fromDate: day,
          toDate: null,
          enteredTo: null
        });
      } else {
        setShowDate(false);
        // clickHandler.handleDateRangeClick();
        setdateRange({
          fromDate: dateRange.fromDate,
          toDate: day,
          enteredTo: day
        });
        props.getProjectExpensesByDate(dateRange.fromDate, day);
      }
    }
  };
  const { fromDate, enteredTo } = dateRange;
  const modifiers = { start: fromDate, end: enteredTo };
  const selectedDays = [fromDate, { from: fromDate, to: enteredTo }];

  const createFieldObject = value => {
    return {
      value: value !== undefined ? value : '',
      error: ''
    };
  };
  const addExpense = () => {
    setDisableAddExpense(true);
    let expenseDataArr = expenseData.slice();
    let obj = {
      expense_description: createFieldObject(),
      // expense_type: 'direct',
      expense_type_id: createFieldObject(),
      expense_category_id: createFieldObject(),
      expense_sub_category_id: createFieldObject(),
      expense_date: createFieldObject(),
      actual_amount: createFieldObject(0),
      // amount: createFieldObject(0),
      reciept: createFieldObject([]),
      isUploading: false
    };
    expenseDataArr.push(obj);
    setExpenseData(expenseDataArr);
  };
  const deleteExpense = index => {
    setDisableAddExpense(false);
    let expenseDataArr = expenseData.slice();
    expenseDataArr.splice(index, 1);
    setExpenseData(expenseDataArr);
  };

  const setIsUploading = (index, value) => {
    let expenseDataArr = expenseData.slice();
    expenseDataArr[index].isUploading = value;
    setExpenseData(expenseDataArr);
  };

  const deleteFile = (expense, attachment, index, idx) => {
    let expenseDataArr = expenseData.slice();
    expenseDataArr[index].reciept.value.splice(idx, 1);
    uploadedFiles.splice(idx, 1);
    setUploadedFiles(uploadedFiles);
    setExpenseData(expenseDataArr);
  };

  // const deleteExistingFile = (expense, attachment, index, idx) => {
  //   let expenseDataArr = expenseData.slice();

  //   let recieptArr = expense[index].reciept;
  //   recieptArr.value.splice(idx, 1);
  //   expense[index].reciept = recieptArr;
  //   // expenseDataArr[index].reciept = createFieldObject();
  //   setExpenseData(expense);
  // };

  const showHideReceipt = (attachment, index, idx) => {
    setReceiptExtension('');
    if (openImg) {
      setOpenImg(false);
      setReceiptSrc('');
    } else {
      // setReceiptSrc(expenseData[index].reciept.value);
      const fileExtension = attachment
        .split('.')
        .pop()
        .toLowerCase();
      if (
        fileExtension !== 'jpg' &&
        fileExtension !== 'jpeg' &&
        fileExtension !== 'png'
      ) {
        setReceiptExtension('document');
        if (
          fileExtension.includes('doc') ||
          fileExtension.includes('xls') ||
          fileExtension.includes('csv')
        ) {
          setReceiptSrc(
            `${process.env.REACT_APP_DOC_FILE_PREVIEW_URL}${attachment}?#toolbar=0&#view=fitH`
          );
        } else if (fileExtension !== 'pdf') {
          setReceiptSrc(attachment);
        } else {
          setReceiptSrc(
            `https://docs.google.com/gview?url=${attachment}&embedded=true&#toolbar=0`
          );
        }
      } else {
        setReceiptSrc(attachment);
      }

      setOpenImg(true);
    }
  };

  const restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  const Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    }
  };

  const validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';
    // let patternMsg = null;
    switch (name) {
      case 'expense_category_id':
        isFieldValid = Validations.required(value);
        msg = isFieldValid ? '' : 'Please select the expense category';
        break;
      case 'expense_description':
        isFieldValid = Validations.required(value);
        msg = isFieldValid ? '' : 'Please select the expense details';
        break;
        /*       case 'expense_type_id':
         */ isFieldValid = Validations.required(value);
        if (
          !isFieldValid ||
          value === 0 ||
          (value !== 'Direct' && value !== 'Overhead')
        ) {
          msg = isFieldValid ? '' : 'Please select the expense type';
        }
        break;
      case 'category':
        isFieldValid = Validations.required(value);
        if (!isFieldValid || value === 'Please select') {
          msg = 'Please select a category';
        }
        break;
      case 'actual_amount':
        isFieldValid = Validations.required(value);
        // patternMsg = checkForPattern('number', value);
        if (!isFieldValid || parseInt(value) === 0) {
          msg = 'Please enter an amount';
        }
        // else if (patternMsg) {
        //   msg = patternMsg;
        // }
        break;
      case 'expense_date':
        isFieldValid = Validations.required(value);
        msg = isFieldValid ? '' : 'Date required';
        break;
      default:
    }
    return { isFieldValid, msg };
  };
  const setFormErrorsAndValue = (event, type, index) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;
    if (event.target.maxLength !== undefined) {
      if (value?.length > event.target.maxLength) {
        toast.error('Max length should be ' + event.target.maxLength);
      }
      value = value.slice(0, event.target.maxLength);
    }
    if (id === 'expense_category_id') {
      let filteredExpDesc = [];
      let descArr = groupByExpenseNameArr[parseInt(value)];

      descArr !== undefined &&
        descArr?.length > 0 &&
        descArr.forEach((element, index) => {
          if (element.amount !== null) {
            var obj = { id: element.budgetId, name: element.description };
            filteredExpDesc.push(obj);
          }
        });
      getExpenseSubCategory(parseInt(value));
      setExpenseDescriptions(filteredExpDesc);
    }

    switch (type) {
      case 'num':
        value = restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }

    const errorResult = validate(id, value);
    let expenseDataArr = expenseData.slice();
    if (id === 'expense_type_id') {
      expenseDataArr[index][id].value = value;
    } else {
      expenseDataArr[index][id].value = value;
      expenseDataArr[index][id].error = errorResult.msg;
    }
    setExpenseData(expenseDataArr);
  };

  const onUploadSuccess = (fileUrl, id, index) => {
    let expenseDataArr = expenseData.slice();
    expenseDataArr[index][id].value = fileUrl;
    expenseDataArr[index][id].error = '';
    setExpenseData(expenseDataArr);
  };

  const checkErrorMessage = () => {
    let errorExists = false;
    let expenseDataArr = expenseData.slice();
    expenseData.map((expense, index) => {
      for (const key in expense) {
        // if (key !== 'expense_type') {
        const errorResult = validate(key, expense[key].value);
        if (errorResult.msg !== '') {
          expenseDataArr[index][key].error = errorResult.msg;
          if (!errorExists) errorExists = true;
        }
        // }
      }
      return false;
    });
    if (errorExists) setExpenseData(expenseDataArr);
    return errorExists;
  };

  const saveExpense = () => {
    setDisableUpdate(true);
    setDisableAddExpense(false);
    const isError = checkErrorMessage();
    if (isError) {
      setDisableUpdate(false);
      return false;
    }
    let expenseDataArr = [];
    let resetExpenseDataArr = [];

    const payload = {
      campaign_id: campaignId,
      expense_data: []
    };
    expenseData.map(exp => {
      let obj = {};
      for (const key in exp) {
        obj[key] = exp[key].value ? exp[key].value : '';
        if (key === 'expense_description') {
          const expenseName = groupByExpenseNameArr[
            parseInt(exp['expense_category_id'].value)
          ]?.filter(itm => {
            return itm.budgetId === parseInt(exp[key].value);
          });
          if (expenseName?.length > 0) {
            if (
              parseInt(exp['expense_category_id'].value) ===
                expenseName['0']?.id &&
              (parseInt(exp['expense_sub_category_id'].value) ===
                expenseName['0']?.expense_sub_category_id ||
                (exp['expense_sub_category_id'].value === 'Please select' &&
                  expenseName['expense_sub_category_id'] === null))
            ) {
              // obj['expense_id'] = exp[key].value; - commented id for maintain unique records
              obj[key] = expenseName[0]?.description;
              obj['amount'] = parseInt(expenseName[0]?.amount);
              obj['quantity'] = parseInt(expenseName[0]?.quantity);
            } else {
              obj[key] = expenseName[0]?.description;
            }
          }
        }
        if (key === 'actual_amount') {
          obj['actual_amount'] = getNumber(exp[key].value);
        }
        if (key === 'expense_type_id') {
          obj['expense_type_id'] = exp[key].value;
        }
        if (key === 'expense_sub_category_id') {
          obj['expense_sub_category_id'] =
            exp[key].value === '' || exp[key].value === 'Please select'
              ? null
              : exp[key].value;
        }
        if (key === 'expense_type_id') {
          obj['expense_type_id'] =
            exp[key].value === '' || exp[key].value === 'Please select'
              ? null
              : exp[key].value;
        }
      }
      expenseDataArr.push(obj);
      exp.reciept.value = '';
      return false;
    });
    payload.expense_data = expenseDataArr;
    let payloadArr = payload.expense_data;
    let expenseArr = payload.expense_data;
    let emptyExpenseDateArr = payloadArr.filter(x => x.expense_date === '');
    let emptyCategoryArr = expenseArr.filter(x => x.expense_category_id === '');

    if (emptyExpenseDateArr.length > 0)
      toast.error('Please enter Reciept Date');
    else if (emptyCategoryArr.length > 0)
      toast.error('Please select Expense Category');
    else {
      props
        .addExpense(payload, window.location.pathname.split('/')[3])
        .then(() => {
          setExpenseData(resetExpenseDataArr);
          if (payload.expense_data?.length > 0) {
            toast.success('Expense data saved!');
            props.getAllProjects(window.location.pathname.split('/')[3]);
          } else {
            toast.error(
              'Please add expenses by clicking on `Add Expense` button!'
            );
          }
          props.getProjectExpenses();
          setDisableUpdate(false);
        })
        .catch(() => {
          setDisableUpdate(false);
          toast.error(
            "Oops! Looks like we couldn't process your request. Kindly contact our support for assistance."
          );
        });
      setDisableUpdate(true);
    }
  };
  const showDeleteExpenseByIdModal = expenseRecord => {
    setShowDeleteExpenseModal(true);
    setSelectedExpenseIdRecord(expenseRecord);
  };

  const deleteExpenseById = expenseId => {
    props
      .deleteExpense(expenseId)
      .then(res => {
        if (res.data.code === '200') {
          props.getProjectExpenses();
          setShowDeleteExpenseModal(false);
          toast.success('Selected expense deleted successfully');
        }
      })
      .catch(() => {
        toast.error('Looks like we are having server issues. Please Try Again');
      });
  };

  const handleCloseDeleteExpenseModal = () => {
    setShowDeleteExpenseModal(false);
    setSelectedExpenseIdRecord([]);
  };
  const showActualAndPlannedBudgetComparison = () => {
    let campaignIdValue = window.location.href.split('/').pop();
    window.location.href =
      '/ngo/actualAndPlannedBudgetComparison/' + campaignIdValue;
  };
  useEffect(() => {}, [props]);

  return (
    <>
      <div className="p-5">
        <div className="w-100 bg-white">
          <div className="p-5">
            <button
              className="float-right btn btn-outline-green btn-lg"
              onClick={() => showActualAndPlannedBudgetComparison()}>
              Compare with Planned Budget
            </button>

            <div>
              <h1 className="color-344256 fs-20 mb-2">Finance</h1>
              <hr align="left" />
            </div>

            <div className="my-4 fundBox">
              <div className="row">
                <div className="funds-card w-20 d-flex my-3">
                  <img
                    src={allocatedFunds}
                    alt="AllocatedFunds"
                    width={50}
                    height={50}
                  />
                  <div className="d-flex flex-column mx-3 justify-content-between">
                    <p className="text-344256 m-0">Allocated Funds</p>
                    <h3 className="text-344256 m-0">
                      ₹{' '}
                      {convertInCommaFormat(
                        // props.fundInformation.allocated_funds
                        props.fundRequestedForProject
                      )}
                    </h3>
                  </div>
                </div>
                <div className="funds-card w-20 d-flex my-3">
                  <img
                    src={disbursedFunds}
                    alt="disbursedFunds"
                    width={50}
                    height={50}
                  />
                  <div className="d-flex flex-column mx-3 justify-content-between">
                    <p className="text-344256 m-0">Disbursed Funds</p>
                    <h3 className="text-344256 m-0">
                      ₹{' '}
                      {props.totalDisbursedFund
                        ? convertInCommaFormat(
                            // props.fundInformation.disbursed_funds
                            props.totalDisbursedFund
                          )
                        : 0}
                    </h3>
                  </div>
                </div>
                <div className="funds-card w-20 d-flex my-3">
                  <img
                    src={balancedFunds}
                    alt="balancedFunds"
                    width={50}
                    height={50}
                  />
                  <div className="d-flex flex-column mx-3 justify-content-between">
                    <p className="text-344256 m-0">Yet to be Disbursed</p>
                    <h3 className="text-344256 m-0">
                      ₹{' '}
                      {/* {props.fundInformation.balance_funds
                        ? props.fundInformation.balance_funds
                        : 0} */}
                      {/* {props.consumedFunds[1].value
                        ? props.consumedFunds[1].value
                        : 0} */}
                      {props.allocatedGraphFunds[1]?.value
                        ? convertInCommaFormat(
                            // props.allocatedGraphFunds[1]?.value
                            props.fundRequestedForProject -
                              props.totalDisbursedFund
                          )
                        : 0}
                    </h3>
                  </div>
                </div>
                <div className="funds-card w-20 d-flex my-3">
                  <img
                    src={consumedFunds}
                    alt="balancedFunds"
                    width={50}
                    height={50}
                  />
                  <div className="d-flex flex-column mx-3 justify-content-between">
                    <p className="text-344256 m-0">Consumed Funds</p>
                    <h3 className="text-344256 m-0">
                      {props.consumedFunds[0]?.value
                        ? '₹' +
                          new Intl.NumberFormat('en-IN').format(
                            props.fundProposed
                          )
                        : // convertInCommaFormat(props.consumedFunds[0]?.value)
                          '₹ ' + 0}
                    </h3>
                  </div>
                </div>
                <div className="funds-card w-20 d-flex my-3">
                  <img
                    src={inHandFunds}
                    alt="Consumed Funds"
                    width={50}
                    height={50}
                  />
                  <div className="d-flex flex-column mx-3 justify-content-between">
                    <p className="text-344256 m-0">In-Hand Funds</p>
                    <h3 className="text-344256 m-0">
                      ₹{' '}
                      {convertInCommaFormat(
                        props.totalDisbursedFund - props.fundProposed
                      ) || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <hr className="w-100" />
            <div className="d-flex justify-content-between my-3 align-items-center">
              <div
                className="fs-16 col-6 color-344256 m-0"
                style={{ font: 'normal normal 600 16px/19px Montserrat' }}>
                Utilisation Report
              </div>
              <div className="col-5 d-flex justify-content-end align-items-center">
                {/* <div className="text-1FA4FA fs-12">Download all expense</div> */}
                <CSVLink
                  className="text-1FA4FA fs-12 pr-4"
                  filename={'All Expenses.csv'}
                  data={props.csvData}>
                  Download All Expenses
                </CSVLink>
                {/* <div
                  className="text-1FA4FA cursor-pointer fs-12 mx-3"
                  onClick={() => {
                    !showDate ? setShowDate(true) : setShowDate(false);
                  }}>
                  Date Range
                </div> */}
                {/* <button
                  type="button"
                   className="btn btn-link text-decoration-none mx-3 cursor-pointer">*/}
                {/* <i className="fas fa-file-upload color-1FA4FA " /> */}
                {/* <span className="text-31AEFD">Bulk Upload Expenses</span>
                  <input
                    type="file"
                    accept=".xlsx"
                    id="upload_excel_sheet"
                    // onChange={e => handleBulkUpload(e)}
                    className="invisible-input"
                  />
                </button> */}
                {props.isUserAuthorized && (
                  <button
                    disabled={disableAddExpense}
                    className="btn btn-outline-primary"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      addExpense();
                    }}>
                    Add Expense
                  </button>
                )}
              </div>
            </div>
            <Table
              column={columns}
              utilisationRow={props.expenseList}
              campaignId={campaignId}
              expenseData={expenseData}
              // expenseCategoryList={props.expenseCategoryList}
              expenseCategoryList={arrOfExpenses}
              expenseSubCategoryLists={expenseSubCategory}
              groupByExpenseNameArr={groupByExpenseNameArr}
              expenseDescriptionList={expenseDescriptions}
              expenseType={props.expenseLists}
              disableUpdate={disableUpdate}
              setFormErrorsAndValue={setFormErrorsAndValue}
              onUploadSuccess={onUploadSuccess}
              saveExpense={saveExpense}
              deleteExpenseById={deleteExpenseById}
              showDeleteExpenseByIdModal={showDeleteExpenseByIdModal}
              deleteFile={deleteFile}
              deleteExpense={deleteExpense}
              setIsUploading={setIsUploading}
              showHideReceipt={showHideReceipt}
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              // wrapperClass="table-height-50vh"
            />
            {props.isUserAuthorized && (
              <div className="d-flex mt-3 justify-content-center">
                <button
                  className="btn px-4 py-2 btn-submit mb-5 btn-outline-primary"
                  disabled={disableUpdate}
                  onClick={() => saveExpense()}>
                  Update
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {showDate && (
        <div className="position-absolute w-100 h-100">
          <div
            className="position-absolute w-100 h-100"
            onClick={() => {
              showDate ? setShowDate(false) : setShowDate(true);
            }}></div>
          <div className="col-12 financeDateTab">
            <DayPicker
              className={`date-range-picker bg-white`}
              numberOfMonths={2}
            />
            <DayPicker
              className={`date-range-picker bg-white`}
              numberOfMonths={2}
              selectedDays={selectedDays}
              modifiers={modifiers}
              onDayClick={clickHandler.handleDayClick}
            />
          </div>
        </div>
      )}

      <Modal
        show={openImg}
        handleCloseModal={showHideReceipt}
        sizeClass={'modal-xl'}>
        {receiptExtension === 'document' ? (
          <div>
            <iframe src={receiptSrc} width="1200" height="1000"></iframe>;
          </div>
        ) : (
          <div className="text-center mb-4">
            <img src={receiptSrc} alt="Receipt" width={'800'}></img>
          </div>
        )}
      </Modal>
      <Modal
        show={showDeleteExpenseModal}
        title={'Delete selected expense?'}
        handleCloseModal={handleCloseDeleteExpenseModal}
        sizeClass={'modal-lg'}>
        <div>
          <p className="fs-14">
            Are you sure you want to delete the selected expense?
          </p>
          <div className="d-flex justify-content-end mt-4">
            <button
              type="button"
              className="btn btn-light px-4 mr-3"
              onClick={handleCloseDeleteExpenseModal}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={() => deleteExpenseById(selectedExpenseIdRecord?.id)}>
              Yes, Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
const mapStateToProps = state => ({
  state: state
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getExpenseSubCategory
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FundUtilizations);
