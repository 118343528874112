import React, { useEffect, useState } from 'react';
import UploadService from '../../services/upload.service';
import toast from 'react-hot-toast';
// var filesArr = [];
var uploadedArrayState = [];
function DragAndDropFiles(props) {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getSavedValues(props.value);
  }, [props.value]);

  function getSavedValues(files) {
    if (files !== undefined && files !== null) {
      const segments = files.toString().split('/');
      var fileName = segments !== undefined ? segments[5] : '';
    }

    let obj = { fileUrl: files, fileName: fileName };
    setUploadedFiles([obj]);
  }
  const handleDragEnter = event => {
    event.preventDefault();
    event.persist();
    setDragging(true);
  };

  const handleDragLeave = event => {
    event.preventDefault();
    event.persist();
    setDragging(false);
  };

  const handleDragOver = event => {
    event.preventDefault();
    event.persist();
  };

  const handleDrop = (event, jsonfield) => {
    event.preventDefault();
    event.persist();

    setDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles?.length > 0) {
      setIsLoading(true);
      if (
        props.input.disableDependentOn !== '' &&
        props.input.dependentOnFieldType === 'checkbox'
      ) {
        document.getElementById(props.input.disableDependentOn).disabled = true;
      }
    } else {
      setIsLoading(false);
    }
    for (let i = 0; i < droppedFiles?.length; i++) {
      uploadFile(droppedFiles[i], jsonfield, droppedFiles);
    }
    setFiles([...files, ...droppedFiles]);
  };

  const handleFileRemove = (fileToRemove, id, index) => {
    var disableCheckbox = false;
    props.setErrMsgsArr([]);
    const newFiles = uploadedFiles.filter(file => file !== fileToRemove);
    setUploadedFiles(newFiles);
    setFiles(newFiles);
    setTimeout(() => {
      if (props.input.disableRelatedField !== '') {
        for (let i = 0; i < props.input.disableRelatedField?.length; i++) {
          if (
            document
              .getElementById(props.input.disableRelatedField[i])
              .value?.trim()?.length > 0
          ) {
            disableCheckbox = true;
          }
        }
      }

      if (!disableCheckbox) {
        if (
          props.input.disableDependentOn !== '' &&
          props.input.dependentOnFieldType === 'checkbox'
        ) {
          if (newFiles?.length > 0) {
            document.getElementById(
              props.input.disableDependentOn
            ).disabled = true;
          } else {
            document.getElementById(
              props.input.disableDependentOn
            ).disabled = false;
          }
        }
      }
    }, 1000);
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.persist();
    console.error(files);
  };
  const handleClick = (e, jsonfield) => {
    e.preventDefault();
    e.preventDefault();
    e.persist();

    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles?.length > 0) {
      setIsLoading(true);
      if (
        props.input.disableDependentOn !== '' &&
        props.input.dependentOnFieldType === 'checkbox'
      ) {
        document.getElementById(props.input.disableDependentOn).disabled = true;
      }
    } else {
      setIsLoading(false);
    }
    for (let i = 0; i < selectedFiles?.length; i++) {
      uploadFile(selectedFiles[i], jsonfield, selectedFiles);
    }

    setFiles([...files, ...selectedFiles]);
  };

  const uploadFile = async (files, jsonfield, fileDetails) => {
    let fileName = fileDetails[0].name;
    // event.persist();

    // try {
    //   if (!event.target.files) {
    //     throw new Error('Some thing went wrong..');
    //   }

    //   if (
    //     event.target.files &&
    //     fileSizeLimit &&
    //     event.target.files.length &&
    //     event.target.files[0].size > fileSizeLimit * 1000 * 1000
    //   ) {
    //     throw new Error(`File size can not be more than ${fileSizeLimit}MB`);
    //   }

    //   if (event.target.files[0].name.split('.').length > 2) {
    //     throw new Error(
    //       'Incorrect file format. File name should not contain dots'
    //     );
    //   }
    //   if (validateFile) {
    //     await validateFile(event.target.files[0]);
    //   }
    // } catch (e) {
    //   event.target.value = '';
    //   toast.error(e.message);
    //   return false;
    // }

    let formData = new FormData();
    formData.append('file', files);
    formData.append('mimeType', files.type);
    formData.append('docType', 'campaign_image');
    formData.append('moduleName', 'ngo');
    formData.append('refId', props.refId);

    setUploadedFiles([]);
    // const filesArr =
    //   uploadedArrayState[jsonfield] !== undefined
    //     ? uploadedArrayState[jsonfield]
    //     : [];
    const filesArr = [];

    UploadService.upload(formData, props.userId, 'doc', '')
      .then(res => {
        // setIsFlileUploaded(true);
        let obj = {
          fileUrl: res.data.fileUrl,
          fileName: fileName
        };

        const newArray = Array.isArray(uploadedArrayState[jsonfield])
          ? [...uploadedArrayState[jsonfield]]
          : [];

        newArray.push(obj);
        uploadedArrayState[jsonfield] = obj;

        filesArr.push(obj);

        setUploadedFiles(filesArr);
        if (filesArr?.length > 0) {
          props.setErrMsgsArr([]);
          setTimeout(() => {
            if (
              props.input.disableDependentOn !== '' &&
              props.input.dependentOnFieldType === 'checkbox'
            ) {
              if (filesArr?.length > 0) {
                document.getElementById(
                  props.input.disableDependentOn
                ).disabled = true;
              } else {
                document.getElementById(
                  props.input.disableDependentOn
                ).disabled = false;
              }
            }
          }, 500);

          setIsLoading(false);
        }
      })
      .catch(err => {
        toast.error(
          'Uh-Oh! Looks like we are having server issues., please contact our support!'
        );
        setIsLoading(false);
      });
    return false;
  };

  return (
    <>
      <label htmlFor={props.uniqueIdentifierForInput}>
        {props.label}
        <span className="color-FF2300">
          {props.input.required ||
          (props.input.validation && props.input.validation === 'required') ||
          props.input.showrequired ||
          props.input.preOnboardRequiredField
            ? ' * '
            : ''}
        </span>
      </label>

      <div
        className={`drop-zone ${dragging ? 'active' : ''}`}
        onDragEnter={event => handleDragEnter(event)}
        onDragLeave={event => handleDragLeave(event)}
        onDragOver={event => handleDragOver(event)}
        onDrop={event => handleDrop(event, props.input.jsonfield)}>
        <form onSubmit={handleSubmit}>
          <label
            htmlFor={props.input.jsonfield}
            id={`pre_${props.input.jsonfield}`}
            className={` ${props.disabled ? 'disble-drag-area' : ''} `}>
            <div className={`drag-area`}>
              <div className="icon">
                <i className="fa fa-upload text-007bff fs-16"></i>
                <span className="text-007bff fs-14 ml-4">
                  Drag &amp; Drop files here OR Click to upload
                </span>
              </div>
            </div>
          </label>

          {props.errMsgsArr !== undefined &&
            props.errMsgsArr[props.input.jsonfield] === true &&
            !props.showNextStepIndicator && (
              <div className="mt-1 color-FF0000 text-left">
                {props.input.validationMsg}
              </div>
            )}

          <input
            className="position-relative"
            id={props.input.jsonfield}
            name={props.input.jsonfield}
            type="file"
            onChange={event => handleClick(event, props.input.jsonfield)}
            disabled={props?.disabled}
            multiple={false}
            // value={files}
          />
          {/* <button type="submit">Upload</button> */}
        </form>

        {isLoading ? (
          <span>
            <i className="fas fa-spinner fa-pulse fa-2x" aria-hidden="true"></i>
          </span>
        ) : (
          uploadedFiles !== undefined &&
          uploadedFiles?.length > 0 && (
            <div className="files-list">
              {/* <div className="fs-14">Selected files:</div> */}
              <li className="mt-neg-1 mb-4">
                {uploadedFiles !== undefined &&
                  uploadedFiles?.map((file, index) => {
                    return (
                      <>
                        {file.fileName !== undefined && (
                          <>
                            <div>
                              <input
                                type="text"
                                id={`${props.input.jsonfield}_${index}`}
                                name={`${props.input.jsonfield}`}
                                className="file-input"
                                value={file.fileUrl}
                                hidden
                              />
                            </div>
                            <span className="ml-2 mt-2 fs-12 cursor-pointer d-flex align-items-center w-100 checkmrk grayed-out">
                              <i
                                className="fa fa-check-circle-o mr-2"
                                style={
                                  props.surveyStatus === 'submitted'
                                    ? {
                                        color: '#808080',
                                        fontSize: '18px'
                                      }
                                    : {
                                        color: '#81bc30',
                                        fontSize: '22px'
                                      }
                                }></i>

                              <span className="cursor-pointer">
                                <a
                                  href={file.fileUrl}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  {file.fileName?.length > 25
                                    ? file.fileName?.slice(0, 25) + '...'
                                    : file.fileName}
                                </a>
                              </span>

                              <i
                                className="fas fa-trash-alt text-007bff fs-16 fas-imp ml-4 grayed-out-hide"
                                onClick={() => handleFileRemove(file)}></i>
                            </span>
                          </>
                        )}
                      </>
                    );
                  })}
              </li>
            </div>
          )
        )}
      </div>
      <div></div>
    </>
  );
}

export default DragAndDropFiles;
