import React, { useEffect, useState } from 'react';
export default function RadioButtonWithBorder(props) {
  let {
    uniqueIdentifierForInput,
    value,
    checked,
    disabled,
    input,
    handleFieldsOnCheck,
    setNoteForRadioBtn
  } = props;
  const inputProps = {
    value: value,
    name: uniqueIdentifierForInput
  };
  const [activeRadioBtn, setActiveRadioBtn] = useState(null);
  const [yesChecked, setYesChecked] = useState(null);
  const [noChecked, setNoChecked] = useState(null);

  if (disabled) {
    inputProps.disabled = true;
  }
  useEffect(() => {
    handleCheckedField(checked);
  }, [checked]);

  function handleCheckedField(checked) {
    if (checked === true) {
      setActiveRadioBtn(true);
      setYesChecked(true);
      setNoteForRadioBtn('');
    } else {
      setYesChecked(null);
    }

    if (checked === false) {
      setActiveRadioBtn(false);
      setNoChecked(true);
      if (input.jsonfield.includes('csr')) {
        // setNoteForRadioBtn('Note: CSR details not available');
      } else if (input.jsonfield.includes('fcra')) {
        // setNoteForRadioBtn('Note: FCRA details not available');
      } else {
        setNoteForRadioBtn('');
      }
    } else {
      setNoChecked(null);
    }
  }
  const handleChange = (e, fieldValue) => {
    handleCheckedField(fieldValue);
    handleFieldsOnCheck(e, fieldValue, input.dependentFields, 'radio');
  };
  return (
    <div className="card-container grayed-out">
      <div
        className={`${
          activeRadioBtn === true ? 'card-radio-active' : ''
        } card-radio`}>
        <label>
          <input
            type="radio"
            name={input.jsonfield}
            value={true}
            checked={yesChecked}
            onChange={e => {
              handleChange(e, true);
            }}
          />
          <span>YES</span>
        </label>
      </div>
      <div
        className={`${
          activeRadioBtn === false ? 'card-radio-active' : ''
        } card-radio`}>
        <label>
          <input
            type="radio"
            name={input.jsonfield}
            value={false}
            checked={noChecked}
            onChange={e => {
              handleChange(e, false);
            }}
          />
          <span>NO</span>
        </label>
      </div>
    </div>
  );
}
