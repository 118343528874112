import toast from 'react-hot-toast';
import { Action, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

export const getRegionalSettings = ngoId => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  let apiUrl = EndPoint.getApiUrl('NGO', 'GET_REGIONAL_SETTINGS', 'HIROKU');
  apiUrl = apiUrl.replace('{ngo_id}', ngoId);
  return instance
    .get(apiUrl)
    .then(response => {
      if (response.data.code !== 500) {
        dispatch({
          type: Action.GET_REGIONAL_SETTINGS_SUCCESS,
          payload: response.data.result
        });
      } else {
        toast.error(response.data.message);
      }
      return response;
    })
    .catch(err => {
      toast.error('Something Went Wrong Please Try Again');
    });
};
