import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  getCampaign,
  createOrModifyIndicators,
  getGoalsIndicators
} from '../../actions';
import { Link } from 'react-router-dom';
import { InputSelect, InputText } from '../FormElements';

class ProjectIndicators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: this.props.id,
      campaignName: '',
      noOfPhases: 1,
      indicatorsArr: [],
      saved: false
    };
  }

  componentDidMount() {
    this.props.getCampaign(this.props.id, 'edit');
    this.props.getGoalsIndicators(this.props.id);
    this.addIndicator();
  }

  componentWillUnmount() {
    this.setState({ indicatorsArr: [] });
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (
      nextprops.editSuccessResponse &&
      nextprops.editSuccessResponse.code === 200
    ) {
      let indicatorsArray = this.state.indicatorsArr.slice();
      this.setState({
        campaignName: nextprops.editSuccessResponse.data.title,
        indicatorsArr: indicatorsArray
      });
    }
    if (
      nextprops.indicatorsDetailsSuccessResponse &&
      nextprops.indicatorsDetailsSuccessResponse.code === 200
    ) {
      const indicatorsDataArr = nextprops.indicatorsDetailsSuccessResponse.data;
      let indicatorsArr = [];
      // console.log("indicatorsDataArr", indicatorsDataArr);
      indicatorsDataArr.map(indicator => {
        const obj = {
          indicator_description: this.createFieldObject(
            indicator.id,
            indicator.indicator_description
          ),
          uom_id: this.createFieldObject(indicator.id, indicator.uom_id),
          frequency_id: this.createFieldObject(
            indicator.id,
            indicator.frequency
          ),
          baseline_value: this.createFieldObject(
            indicator.id,
            indicator.baseline_value
          ),
          target_value: this.createFieldObject(
            indicator.id,
            indicator.target_value
          )
        };
        indicatorsArr.push(obj);
        return false;
      });
      this.setState({
        indicatorsArr: indicatorsArr
        // saved: true
      });
    }
  }
  createFieldObject(id, value) {
    return {
      id: id,
      value: value !== undefined ? value : '',
      error: ''
    };
  }
  addIndicator() {
    let indicatorsArr = this.state.indicatorsArr.slice();
    const description = indicatorsArr.length === 0 ? 'Beneficiaries' : null;
    const obj = {
      indicator_description: this.createFieldObject(null, description),
      uom_id: this.createFieldObject(null),
      frequency_id: this.createFieldObject(null, 1),
      baseline_value: this.createFieldObject(null, 0),
      target_value: this.createFieldObject(null, 0)
    };

    indicatorsArr.push(obj);
    this.setState({ indicatorsArr: indicatorsArr });
  }
  deleteIndicator(index) {
    let indicatorsArr = this.state.indicatorsArr.slice();
    indicatorsArr.splice(index, 1);
    this.setState({ indicatorsArr: indicatorsArr });
  }

  eventToValue(e, trimResults, toNumber) {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      if (value !== 'true' || value !== 'false') {
        value = Number(value);
      }
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  }
  Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    }
  };
  checkForPattern(type, value) {
    if (type === 'number') {
      let pattern = /^[-,0-9]+$/;
      if (!pattern.test(value)) return 'Must contain only numbers';
    } else if (type === 'aplhabets') {
      let pattern = /^[A-Za-z\s]+$/;
      if (!pattern.test(value)) return 'Must contain only characters';
    } else if (type === 'aplhaNumeric') {
      let pattern = /^[a-zA-Z0-9]+$/;
      if (!pattern.test(value)) return 'Must be alphanumeric';
    }
    return false;
  }
  validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';
    let patternMsg = null;
    switch (name) {
      case 'indicator_description':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please enter the indicator description';
        break;
      case 'uom_id':
        isFieldValid = this.Validations.required(value);
        if (!isFieldValid || value === 0) {
          msg = 'Please select a unit of measurement';
        }
        break;
      case 'frequency':
        isFieldValid = this.Validations.required(value);
        msg =
          isFieldValid && value !== 'Please select'
            ? ''
            : 'Please select a frequency value';
        break;
      case 'baseline_value':
        isFieldValid = this.Validations.required(value) || 0;
        patternMsg = this.checkForPattern('number', value);
        if (!isFieldValid || parseInt(value) === 0) {
          msg = 'Please enter baseline value';
        } else if (patternMsg) {
          msg = patternMsg;
        }
        break;
      case 'target_value':
        isFieldValid = this.Validations.required(value);
        patternMsg = this.checkForPattern('number', value);
        if (!isFieldValid || parseInt(value) === 0) {
          msg = 'Please enter target value';
        } else if (patternMsg) {
          msg = patternMsg;
        }
        break;
      default:
    }
    return { isFieldValid, msg };
  };
  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  setFormErrorsAndValue = (event, type, index) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;

    if (!type) {
      value = this.eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = this.restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    const errorResult = this.validate(id, value);
    let indicatorsArr = this.state.indicatorsArr.slice();
    indicatorsArr[index][id].value = value;
    indicatorsArr[index][id].error = errorResult.msg;
    this.setState({ indicatorsArr: indicatorsArr });
  };
  onHandleChangeOrBlur = (event, type, index) => {
    this.setFormErrorsAndValue(event, type, index);
  };

  checkErrorMessage() {
    let errorExists = false;
    let tempIndicatorsArr = this.state.indicatorsArr.slice();
    this.state.indicatorsArr.map((indicator, index) => {
      for (const key in indicator) {
        const errorResult = this.validate(key, indicator[key].value);
        if (errorResult.msg !== '') {
          tempIndicatorsArr[index][key].error = errorResult.msg;
          if (!errorExists) errorExists = true;
        }
      }
      return false;
    });
    if (errorExists) this.setState({ indicatorsArr: tempIndicatorsArr });
    return errorExists;
  }
  saveIndicators() {
    const errorExists = this.checkErrorMessage();
    if (errorExists) return false;
    let indicatorsArr = [];
    // console.log("this.state.indicatorsArr", this.state.indicatorsArr);
    this.state.indicatorsArr.map((indicator, index) => {
      if (!indicator.indicator_description.id) {
        const object = {
          indicator_description: indicator.indicator_description.value,
          is_primary: index === 0 ? true : false,
          uom_id: indicator.uom_id.value,
          frequency_id: indicator.frequency,
          baseline_value: isNaN(parseInt(indicator.baseline_value.value))
            ? 0
            : parseInt(indicator.baseline_value.value),
          target_value: isNaN(parseInt(indicator.target_value.value))
            ? 0
            : parseInt(indicator.target_value.value)
        };
        // console.log("object", object)
        indicatorsArr.push(object);
      }
      return false;
    });
    if (indicatorsArr.length > 0) {
      const payloadObj = {
        campaign_id: this.props.id,
        project_indicators: indicatorsArr
      };
      this.props
        .createOrModifyIndicators(payloadObj)
        .then(response => {
          this.props.getGoalsIndicators(this.props.id);
          toast.success('Indicators saved successfully!');
          setTimeout(() => {
            this.props.history.push('/ngo/ngoDashboard');
          }, 2000);
        })
        .catch(error => {
          toast.error('Uh-Oh! Looks like we are having server issues.');
          console.error(error);
        });
    }
  }
  render() {
    const unitOfMeasurement = [{ name: 'Number of People', id: 1 }];
    const frequencyList = [
      { name: 'Monthly', id: 'Monthly' },
      { name: 'Quarterly', id: 'Quarterly' },
      { name: 'Half-Yearly', id: 'Half-Yearly' },
      { name: 'Yearly', id: 'Yearly' }
    ];

    return (
      <div className="row p-4 create-campaign">
        <div className={`row`}>
          <div className="col-12">
            <h4 className="d-flex align-items-center mb-5 pb-4">
              <Link to={'/ngo/project/activitiesPlan/' + this.props.id}>
                <i className="fas fa-angle-left fs-36 color-888686 cursor-pointer"></i>
              </Link>
              <Link to={'/ngo/project/activitiesPlan/' + this.props.id}>
                <span className="d-inline-block mr-4 color-888686 font-weight-bold pl-4 cursor-pointer">
                  Back
                </span>
              </Link>
              <span className="d-inline-block font-weight-bolder">
                {this.state.campaignName !== '' ? this.state.campaignName : '-'}
              </span>
            </h4>
            <div className="d-flex justify-content-center mb-5">
              <span
                className="fs-16 position-relative project-bullet project-tab-title project-bullet-active
									project-top-border-active text-center project-top-border px-4">
                Project Details Overview
              </span>
              <span
                className="fs-16 position-relative project-bullet project-bullet-active
                project-top-border-active project-tab-title text-center project-top-border px-4">
                Project Activities Plan
              </span>
              <span
                className="fs-16 font-weight-bold position-relative project-bullet project-bullet-active
                project-tab-title text-center px-4">
                Project Impact Indicators
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className={`col-12 p-4 bg-white`}>
              <div className="row justify-content-between mb-4">
                <h3 className="fs-16 font-weight-bold">
                  Project Indicators Details
                </h3>
              </div>
              <div className="row bg-B1E0FF py-2">
                <div className="col-4 pl-0 pr-2">
                  <span className="fs-16 font-weight-bold px-2">
                    Indicator Description
                  </span>
                </div>
                <div className="col-2 px-2">
                  <span className="fs-16 font-weight-bold px-2">
                    Unit of Measurement
                  </span>
                </div>
                <div className="col-2 px-2">
                  <span className="fs-16 font-weight-bold px-2">Frequency</span>
                </div>
                <div className="col-2 px-2">
                  <span className="fs-16 font-weight-bold px-2">
                    Baseline value
                  </span>
                </div>
                <div className="col-2 pl-2 pr-0">
                  <span className="fs-16 font-weight-bold px-2">
                    Target value
                  </span>
                </div>
              </div>
              {this.state.indicatorsArr.map((indicator, index) => {
                return (
                  <>
                    <div
                      className={`row ${
                        index === 0 ? 'bg-87C7F0 pt-4' : 'mt-4 '
                      }`}>
                      <InputText
                        required={true}
                        labelText=""
                        maxLength={100}
                        type={'text'}
                        PlaceHolder={'Maximum Length is 100 Characters'}
                        id="indicator_description"
                        name="indicator_description"
                        fieldValue={indicator.indicator_description.value}
                        fieldError={indicator.indicator_description.error}
                        onHandleChange={(e, c, t) =>
                          this.onHandleChangeOrBlur(e, t, index)
                        }
                        onHandleBlur={(e, c, t) =>
                          this.onHandleChangeOrBlur(e, t, index)
                        }
                        wrapperClassName={'col-4  pr-2'}
                        disableInput={this.state.saved}
                        className="activity_border-radius"
                      />
                      <InputSelect
                        required={true}
                        labelText=""
                        id="uom_id"
                        name="uom_id"
                        options={unitOfMeasurement}
                        fieldValue={indicator.uom_id.value}
                        fieldError={indicator.uom_id.error}
                        onHandleChange={(e, c, t) =>
                          this.onHandleChangeOrBlur(e, t, index)
                        }
                        onHandleBlur={(e, c, t) =>
                          this.onHandleChangeOrBlur(e, t, index)
                        }
                        parentElement={true}
                        dataType="num"
                        wrapperClassName={'col-2 px-2'}
                        disableInput={this.state.saved}
                        className="activity_border-radius"
                      />
                      <InputSelect
                        required={true}
                        labelText=""
                        id="frequency"
                        name="frequency"
                        options={frequencyList}
                        fieldValue={indicator.frequency.value}
                        fieldError={indicator.frequency.error}
                        onHandleChange={(e, c, t) =>
                          this.onHandleChangeOrBlur(e, t, index)
                        }
                        onHandleBlur={(e, c, t) =>
                          this.onHandleChangeOrBlur(e, t, index)
                        }
                        parentElement={true}
                        wrapperClassName={'col-2 px-2'}
                        disableInput={this.state.saved}
                        className="activity_border-radius"
                      />
                      <InputText
                        required={true}
                        labelText=""
                        maxLength={100}
                        type={'text'}
                        PlaceHolder={''}
                        id="baseline_value"
                        name="baseline_value"
                        fieldValue={indicator.baseline_value.value}
                        fieldError={indicator.baseline_value.error}
                        onHandleChange={(e, c, t) =>
                          this.onHandleChangeOrBlur(e, t, index)
                        }
                        onHandleBlur={(e, c, t) =>
                          this.onHandleChangeOrBlur(e, t, index)
                        }
                        wrapperClassName={'col-2 px-2'}
                        disableInput={this.state.saved}
                        className="activity_border-radius"
                      />
                      <div className="col-2 pl-2 pr-0 d-flex">
                        <InputText
                          required={true}
                          labelText=""
                          maxLength={100}
                          type={'text'}
                          PlaceHolder={''}
                          id="target_value"
                          name="target_value"
                          fieldValue={indicator.target_value.value}
                          fieldError={indicator.target_value.error}
                          onHandleChange={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          onHandleBlur={(e, c, t) =>
                            this.onHandleChangeOrBlur(e, t, index)
                          }
                          wrapperClassName={
                            index === 0 && !this.state.saved ? 'mr-5' : ' '
                          }
                          disableInput={this.state.saved}
                          className="activity_border-radius"
                        />
                        {index !== 0 && !this.state.saved && (
                          <i
                            className="fas fa-trash text-31AEFD fs-19 ml-4 mt-2 cursor-pointer"
                            onClick={() => this.deleteIndicator(index)}></i>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
              <div className="row">
                <div className="col-12 pl-0 mt-5 mb-3">
                  <button
                    type="button"
                    onClick={() => this.addIndicator()}
                    className="btn btn-outline-primary btn-custom"
                    disabled={
                      this.state.indicatorsArr.length === 50 || this.state.saved
                    }>
                    Add New Indicator
                  </button>
                </div>
              </div>
              <hr className="section-divider w-100" />
              <div className="row mt-4">
                <div className="col-12 pl-0 mb-3 d-flex justify-content-center">
                  <Link to={'/ngo/project/activitiesPlan/' + this.props.id}>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-custom">
                      Previous
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={() => this.saveIndicators()}
                    className="btn btn-primary btn-custom ml-3"
                    // disabled={this.state.saved}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  editSuccessResponse: state.campaign.editSuccessResponse,
  editErrorResponse: state.campaign.editErrorResponse,
  userInfo: state.login.userInfo,
  indicatorsDetailsSuccessResponse: state.mne.indicatorsDetailsSuccessResponse,
  indicatorsDetailsErrorResponse: state.mne.indicatorsDetailsErrorResponse
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getCampaign, createOrModifyIndicators, getGoalsIndicators },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIndicators);
