export const Action = {
  //NGO - login screen

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  // NGO - Register Screen
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  REGISTER_COMPLETE: 'REGISTER_COMPLETE',

  //NGO - Campaign List screen
  CAMPAIGN_LIST_REQUEST: 'CAMPAIGN_LIST_REQUEST',
  CAMPAIGN_LIST_SUCCESS: 'CAMPAIGN_LIST_SUCCESS',
  CAMPAIGN_LIST_FAILURE: 'CAMPAIGN_LIST_FAILURE',

  //NGO - Campaign CREATE screen
  MISSION_RESET_RESPONSE: 'MISSION_RESET_RESPONSE',
  CAMPAIGN_RESET_RESPONSE: 'CAMPAIGN_RESET_RESPONSE',
  CAMPAIGN_CREATE_REQUEST: 'CAMPAIGN_CREATE_REQUEST',
  CAMPAIGN_CREATE_SUCCESS: 'CAMPAIGN_CREATE_SUCCESS',
  CAMPAIGN_CREATE_FAILURE: 'CAMPAIGN_CREATE_FAILURE',

  //NGO - Project Activities CREATE screen
  ACTIVITIES_CREATE_REQUEST: 'ACTIVITIES_CREATE_REQUEST',
  ACTIVITIES_CREATE_SUCCESS: 'ACTIVITIES_CREATE_SUCCESS',
  ACTIVITIES_CREATE_FAILURE: 'ACTIVITIES_CREATE_FAILURE',

  //NGO - Project expense CREATE screen
  EXPENSE_CREATE_REQUEST: 'EXPENSE_CREATE_REQUEST',
  EXPENSE_CREATE_SUCCESS: 'EXPENSE_CREATE_SUCCESS',
  EXPENSE_CREATE_FAILURE: 'EXPENSE_CREATE_FAILURE',

  //NGO - Project Finance
  FINANCE_CREATE_REQUEST: 'FINANCE_CREATE_REQUEST',
  FINANCE_CREATE_SUCCESS: 'FINANCE_CREATE_SUCCESS',
  FINANCE_CREATE_FAILURE: 'FINANCE_CREATE_FAILURE',
  FINANCE_DETAIL_REQUEST: 'FINANCE_DETAIL_REQUEST',
  FINANCE_DETAILS_SUCCESS: 'FINANCE_DETAILS_SUCCESS',
  FINANCE_DETAILS_FAILURE: 'FINANCE_DETAILS_FAILURE',

  UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE',
  //NGO - Project Activities details
  ACTIVITIES_DETAIL_REQUEST: 'ACTIVITIES_DETAIL_REQUEST',
  ACTIVITIES_DETAILS_SUCCESS: 'ACTIVITIES_DETAILS_SUCCESS',
  ACTIVITIES_DETAILS_FAILURE: 'ACTIVITIES_DETAILS_FAILURE',

  //NGO - Project Indicators CREATE screen
  INDICATORS_CREATE_REQUEST: 'INDICATORS_CREATE_REQUEST',
  INDICATORS_CREATE_SUCCESS: 'INDICATORS_CREATE_SUCCESS',
  INDICATORS_CREATE_FAILURE: 'INDICATORS_CREATE_FAILURE',

  //NGO - Project Indicators details
  INDICATORS_DETAILS_REQUEST: 'INDICATORS_DETAILS_REQUEST',
  INDICATORS_DETAILS_SUCCESS: 'INDICATORS_DETAILS_SUCCESS',
  INDICATORS_DETAILS_FAILURE: 'INDICATORS_DETAILS_FAILURE',

  //NGO - Project Beneficiaries CREATE screen
  BENEFICIARIES_CREATE_REQUEST: 'BENEFICIARIES_CREATE_REQUEST',
  BENEFICIARIES_CREATE_SUCCESS: 'BENEFICIARIES_CREATE_SUCCESS',
  BENEFICIARIES_CREATE_FAILURE: 'BENEFICIARIES_CREATE_FAILURE',

  //NGO - Project Beneficiaries details
  BENEFICIARIES_DETAILS_REQUEST: 'BENEFICIARIES_DETAILS_REQUEST',
  BENEFICIARIES_DETAILS_SUCCESS: 'BENEFICIARIES_DETAILS_SUCCESS',
  BENEFICIARIES_DETAILS_FAILURE: 'BENEFICIARIES_DETAILS_FAILURE',
  //NGO- Project Beneficiaries Dynamic
  BENEFICIARIES_DYNAMIC_REQUEST: 'BENEFICIARIES_DYNAMIC_REQUEST',
  BENEFICIARIES_DYNAMIC_SUCCESS: 'BENEFICIARIES_DYNAMIC_SUCCESS',
  BENEFICIARIES_DYNAMIC_FAILURE: 'BENEFICIARIES_DYNAMIC_FAILURE',
  //NGO- Project Remove Beneficiaries Dynamic
  REMOVE_BENEFICIARIES_DYNAMIC_REQUEST: 'REMOVE_BENEFICIARIES_DYNAMIC_REQUEST',
  REMOVE_BENEFICIARIES_DYNAMIC_SUCCESS: 'REMOVE_BENEFICIARIES_DYNAMIC_SUCCESS',
  REMOVE_BENEFICIARIES_DYNAMIC_FAILURE: 'REMOVE_BENEFICIARIES_DYNAMIC_FAILURE',
  //NGO- Project Select Beneficiaries Type
  SELECT_BENEFICIARIES_TYPE_REQUEST: ' SELECT_BENEFICIARIES_TYPE_REQUEST',
  SELECT_BENEFICIARIES_TYPE_SUCCESS: 'SELECT_BENEFICIARIES_TYPE_SUCCESS',
  SELECT_BENEFICIARIES_TYPE_FAILURE: 'SELECT_BENEFICIARIES_TYPE_FAILURE',
  //NGO - Campaign Edit screen
  CAMPAIGN_EDIT_REQUEST: 'CAMPAIGN_EDIT_REQUEST',
  CAMPAIGN_EDIT_SUCCESS: 'CAMPAIGN_EDIT_SUCCESS',
  CAMPAIGN_EDIT_FAILURE: 'CAMPAIGN_EDIT_FAILURE',

  //NGO - Campaign Delete screen
  CAMPAIGN_DELETE_REQUEST: 'CAMPAIGN_DELETE_REQUEST',
  CAMPAIGN_DELETE_SUCCESS: 'CAMPAIGN_DELETE_SUCCESS',
  CAMPAIGN_DELETE_FAILURE: 'CAMPAIGN_DELETE_FAILURE',

  //NGO - Campaign Preview screen
  CAMPAIGN_PREVIEW_REQUEST: 'CAMPAIGN_PREVIEW_REQUEST',
  CAMPAIGN_PREVIEW_SUCCESS: 'CAMPAIGN_PREVIEW_SUCCESS',
  CAMPAIGN_PREVIEW_FAILURE: 'CAMPAIGN_PREVIEW_FAILURE',

  //NGO - Campaign Detail screen
  CAMPAIGN_DETAIL_REQUEST: 'CAMPAIGN_DETAIL_REQUEST',
  CAMPAIGN_DETAIL_SUCCESS: 'CAMPAIGN_DETAIL_SUCCESS',
  CAMPAIGN_DETAIL_FAILURE: 'CAMPAIGN_DETAIL_FAILURE',

  //NGO - Mission CREATE screen
  MISSION_CREATE_REQUEST: 'MISSION_CREATE_REQUEST',
  MISSION_CREATE_SUCCESS: 'MISSION_CREATE_SUCCESS',
  MISSION_CREATE_FAILURE: 'MISSION_CREATE_FAILURE',

  MISSION_LIST_REQUEST: 'CAMPAIGN_LIST_REQUEST',
  MISSION_LIST_SUCCESS: 'CAMPAIGN_LIST_SUCCESS',
  MISSION_LIST_FAILURE: 'CAMPAIGN_LIST_FAILURE',

  MISSION_FUNDS_SUCCESS: 'MISSION_FUNDS_SUCCESS',
  MISSION_FUNDS_FAILURE: 'MISSION_FUNDS_FAILURE',

  MISSION_TRANSACTIONS_REQUEST: 'MISSION_TRANSACTIONS_REQUEST',
  MISSION_TRANSACTION_SUCCESS: 'MISSION_TRANSACTION_SUCCESS',
  MISSION_TRANSACTION_FAILURE: 'MISSION_TRANSACTION_FAILURE',

  MISSION_STATISTICS_REQUEST: 'MISSION_STATISTICS_REQUEST',
  MISSION_STATISTICS_SUCCESS: 'MISSION_STATISTICS_SUCCESS',
  MISSION_STATISTICS_FAILURE: 'MISSION_STATISTICS_FAILURE',

  //NGO - Mission Edit screen
  MISSION_EDIT_REQUEST: 'MISSION_EDIT_REQUEST',
  MISSION_EDIT_SUCCESS: 'MISSION_EDIT_SUCCESS',
  MISSION_EDIT_FAILURE: 'MISSION_EDIT_FAILURE',

  //NGO - Mission Preview screen
  MISSION_PREVIEW_REQUEST: 'MISSION_PREVIEW_REQUEST',
  MISSION_PREVIEW_SUCCESS: 'MISSION_PREVIEW_SUCCESS',
  MISSION_PREVIEW_FAILURE: 'MISSION_PREVIEW_FAILURE',

  //NGO - Mission Detail screen
  MISSION_DETAIL_REQUEST: 'MISSION_DETAIL_REQUEST',
  MISSION_DETAIL_SUCCESS: 'MISSION_DETAIL_SUCCESS',
  MISSION_DETAIL_FAILURE: 'MISSION_DETAIL_FAILURE',

  //NGO - Mission Detail screen
  MISSION_UPDATE_REQUEST: 'MISSION_UPDATE_REQUEST',
  MISSION_UPDATE_SUCCESS: 'MISSION_UPDATE_SUCCESS',
  MISSION_UPDATE_FAILURE: 'MISSION_UPDATE_FAILURE',

  //NGO - Master data
  MASTER_LIST_REQUEST: 'MASTER_LIST_REQUEST',
  MASTER_LIST_SUCCESS: 'MASTER_LIST_SUCCESS',
  MASTER_SECONDARY_LIST_SUCCESS: 'MASTER_SECONDARY_LIST_SUCCESS',
  MASTER_LIST_FAILURE: 'MASTER_LIST_FAILURE',

  //NGO - Mission Unpublish
  MISSION_UNPUBLISH_REQUEST: 'MISSION_UNPUBLISH_REQUEST',
  MISSION_UNPUBLISH_SUCCESS: 'MISSION_UNPUBLISH_SUCCESS',
  MISSION_UNPUBLISH_FAILURE: 'MISSION_UNPUBLISH_FAILURE',

  NGO_SAVED_DATA_SUCCESS: 'NGO_SAVED_DATA_SUCCESS',
  NGO_PREONBOARDING_SAVED_DATA_SUCCESS: 'NGO_PREONBOARDING_SAVED_DATA_SUCCESS',
  NGO_SUPPORT_SAVED_DATA_SUCCESS: 'NGO_SUPPORT_SAVED_DATA_SUCCESS',
  // NGO VERFIY DATA

  NGO_VERIFY_DATA: 'NGO_VERIFY_SUCCESS',

  // ROLES
  ROLES_LIST_SUCCESS: 'ROLES_LIST_SUCCESS',
  USERS_WITH_ROLES_LIST_SUCCESS: 'USERS_WITH_ROLES_LIST_SUCCESS',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  DELETE_USER_SUCCESS: 'ADD_USER_SUCCESS',

  // CORPORATE LIST SUCCESS
  CORPORATE_LIST_REQUEST: 'CORPORATE_LIST_REQUEST',
  CORPORATE_LIST_SUCCESS: 'CORPORATE_LIST_SUCCESS',
  CORPORATE_LIST_FAILURE: 'CORPORATE_LIST_FAILURE',
  SEARCH_LIST_SUCCESS: 'SEARCH_LIST_SUCCESS',

  //NGO_PROFILE
  NGO_PROFILE_REQUEST: 'NGO_PROFILE_REQUEST',
  NGO_PROFILE_SUCCESS: 'NGO_PROFILE_SUCCESS',
  NGO_PROFILE_FAILURE: 'NGO_PROFILE_FAILURE',
  //NGO_DASHBOARD
  NGO_DASHBOARD_DATA_SUCCESS: 'NGO_DASHBOARD_DATA_SUCCESS',

  //TRANSACTIONS
  TRANSACTIONS_LIST_REQUEST: 'TRANSACTIONS_LIST_REQUEST',
  TRANSACTIONS_LIST_SUCCESS: 'TRANSACTIONS_LIST_SUCCESS',
  TRANSACTIONS_LIST_FAILURE: 'TRANSACTIONS_LIST_FAILURE',

  //DONATION
  DONATION_LIST_REQUEST: 'DONATION_LIST_REQUEST',
  DONATION_LIST_SUCCESS: 'DONATION_LIST_SUCCESS',
  DONATION_LIST_FAILURE: 'DONATION_LIST_FAILURE',

  //LEGAL STRUCTURE DROPDOWN
  LEGAL_STRUCTURE_DROPDOWN_SUCESS: 'LEGAL_STRUCTURE_DROPDOWN_SUCESS',
  EDUCATIONAL_BACKGROUND_SUCCESS: 'EDUCATIONAL_BACKGROUND_SUCCESS',

  //SECONDARY CAUSE AREAS
  SECONDARY_CAUSE_AREA_SUCCESS: 'SECONDARY_CAUSE_AREA_SUCCESS',
  FCRA_PURPOSE_SUCCESS: 'FCRA_PURPOSE_SUCCESS',
  NGO_COMMENTS: 'NGO_COMMENTS',

  // CAMPAIGN_REJECT
  CAMPAIGN_REJECT: 'CAMPAIGN_REJECT',
  CAMPAIGN_APPROVE: 'CAMPAIGN_APPROVE',
  SHARE_CAMPAIGN: 'SHARE_CAMPAIGN',

  // TYPES OF ACCOUNT DROPDOWN SUCESS
  TYPE_OF_ACCOUNT_DROPDOWN_SUCCESS: 'TYPE_OF_ACCOUNT_DROPDOWN_SUCCESS',

  // NO OF FULL TIME EMPLOYEES
  NO_OF_FULL_TIME_EMPLOYEES: 'NO_OF_FULL_TIME_EMPLOYEES',

  //NGO - Campaign location data
  LIST_COUNTRIES_STATES_DISTRICTS: 'LIST_COUNTRIES_STATES_DISTRICTS',
  LIST_COUNTRIES_SUCCESS: 'LIST_COUNTRIES_SUCCESS',
  LIST_STATE_SUCCESS: 'LIST_STATE_SUCCESS',
  LIST_DISTRICT_SUCCESS: 'LIST_DISTRICT_SUCCESS',
  LIST_LOCATION_FAILURE: 'LIST_LOCATION_FAILURE',
  //CURRENCIES
  LIST_CURRENCY_SUCCESS: 'LIST_CURRENCY_SUCCESS',
  // GUIDE STAR CERTIFICATION LEVEL
  GUIDE_STAR_CERTIFICATION_LEVEL: 'GUIDE_STAR_CERTIFICATION_LEVEL',

  //LIST OF FREQUENCY
  LIST_FREQUENCY_SUCCESS: 'LIST_FREQUENCY_SUCCESS',

  //Side bar
  SET_SIDEBAR_STATUS: 'SET_SIDEBAR_STATUS',

  //currency symbol
  GET_REGIONAL_SETTINGS_SUCCESS: 'GET_REGIONAL_SETTINGS'
};
