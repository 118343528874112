import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import {
  convertInCommaFormat,
  getNumber
} from '../helpers/commaSeparatedInput';
export default function TextNumber(props) {
  const { uniqueIdentifierForInput, input, savedValue } = props;

  const [value, setValue] = useState(savedValue || 0);
  const handleChange = e => {
    if (getNumber(e.target.value) > 3000000000) {
      toast.error('Maximum Limit: 300 Cr');
      return setValue(value);
    } else {
      setValue(getNumber(e.target.value));
    }
  };
  let extraProps = {
    name: uniqueIdentifierForInput,
    className: 'form-control',
    id: uniqueIdentifierForInput
  };
  return (
    <>
      <label htmlFor={uniqueIdentifierForInput}>
        {input.label} {input.labelAppend && input.labelAppend}
        <span className="color-FF2300">
          {input.required ||
          (input.validation && input.validation === 'required') ||
          input.showrequired ||
          input.preOnboardRequiredField
            ? ' *'
            : ''}{' '}
        </span>
      </label>
      <input
        {...input}
        {...extraProps}
        value={
          convertInCommaFormat(value) ? convertInCommaFormat(value) : value
        }
        id={uniqueIdentifierForInput}
        onChange={e => handleChange(e)}
        onKeyUp={e =>
          props.fieldUpdated && props.stepIndex >= 0
            ? props.fieldUpdated(props.stepIndex)
            : false
        }
      />
    </>
  );
}
