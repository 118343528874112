import { Action, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';
import MasterService from '../services/master.service';
export const getMasterData = criteria => dispatch => {
  dispatch(masterListRequest());
  MasterService.get(criteria)
    .then(response => {
      if (response.status === 200) {
        dispatch(masterListSuccess(response.data));
      }
    })
    .catch(error => {
      if (error.response) dispatch(masterListFailure(error));
    });
};
export const getGuestMasterData = criteria => dispatch => {
  dispatch(masterListRequest());
  MasterService.getGuestMasterList(criteria)
    .then(response => {
      if (response.status === 200 && response.data.status === 200) {
        dispatch(masterListSuccess(response.data));
      }
    })
    .catch(error => {
      if (error.response) dispatch(masterListFailure(error));
    });
};

export const getSecondaryObjectiveList = primaryCauseId => dispatch => {
  if (!primaryCauseId || primaryCauseId.length === 0) {
    dispatch({
      type: Action.SECONDARY_CAUSE_AREA_SUCCESS,
      payload: {}
    });
    return;
  }
  let instance = HttpRequest.getInstance('HIROKU');
  instance
    .get(
      `${EndPoint.getApiUrl(
        'NGO',
        'SECONDARY_OBJECTIVE',
        'HIROKU',
        false
      )}/${primaryCauseId}`
    )
    .then(response => {
      dispatch({
        type: Action.SECONDARY_CAUSE_AREA_SUCCESS,
        payload: response.data.result
      });
    });
};

export const getFcraPurpose = () => dispatch => {
  let instance = HttpRequest.getInstance('HIROKU');
  instance
    .get(`${EndPoint.getApiUrl('NGO', 'FCRA_PURPOSE', 'HIROKU', false)}`)

    .then(response => {
      dispatch({
        type: Action.FCRA_PURPOSE_SUCCESS,
        payload: response.data
      });
    });
};

export const getMasterDataById = masterId => dispatch => {
  dispatch(masterListRequest());
  return MasterService.getById(masterId)
    .then(response => {
      // if (response.data.code === 200) {
      dispatch(masterListSecondarySuccess(response.data));
      // }
    })
    .catch(error => {
      if (error.response) dispatch(masterListFailure(error));
    });
};

export const masterListRequest = () => {
  return {
    type: Action.MASTER_LIST_REQUEST
  };
};

export const masterListSuccess = response => {
  return {
    type: Action.MASTER_LIST_SUCCESS,
    payload: response,
    masterResponseStatus: response.status
  };
};

export const masterListSecondarySuccess = response => {
  return {
    type: Action.MASTER_SECONDARY_LIST_SUCCESS,
    payload: response.result,
    masterResponseStatus: response.code
  };
};

export const masterListFailure = error => {
  return {
    type: Action.MASTER_LIST_FAILURE,
    masterResponseStatus: error.response.status
  };
};

export const getMasterListCurrencies = () => dispatch => {
  dispatch(masterListRequest());
  return MasterService.getAllCurrencies()
    .then(response => {
      if (response.status === 200) {
        dispatch(ListCurrenciesSuccess(response));
      }
    })
    .catch(error => {
      if (error.response) dispatch(masterListFailure(error));
    });
};

export const getMasterListCountries = () => dispatch => {
  dispatch(ListCountriesStatesDistrictsRequest());
  MasterService.getAllCountries()
    .then(response => {
      if (response.status === 200) {
        dispatch(ListCountriesSuccess(response));
      }
    })
    .catch(error => {
      if (error.response) dispatch(ListLocationFailure(error));
    });
};

export const getStatesDataById = countryId => dispatch => {
  dispatch(masterListRequest());
  return MasterService.getStatesById(countryId)
    .then(response => {
      if (response.status === 200) {
        dispatch(ListStatesSuccess(response));
      }
    })
    .catch(error => {
      if (error.response) dispatch(masterListFailure(error));
    });
};

export const getAllStatesForArea = () => dispatch => {
  dispatch(masterListRequest());
  return MasterService.getAllStatesForArea()
    .then(response => {
      if (response.status === 200 || response.status === '200') {
        dispatch(ListStatesSuccessArea(response));
      }
    })
    .catch(error => {
      if (error.response) dispatch(masterListFailure(error));
    });
};

export const getAllDistByStateForArea = states => dispatch => {
  //dispatch(masterListRequest());
  return MasterService.getAllDistByStateForArea(states)
    .then(response => {
      if (response.status === 200 || response.status === '200') {
        dispatch(ListDistrictsSuccessArea(response));
      }
    })
    .catch(error => {
      if (error.response) dispatch(masterListFailure(error));
    });
};

export const getDistrictsDataById = stateId => dispatch => {
  dispatch(masterListRequest());
  return MasterService.getDistrictsById(stateId)
    .then(response => {
      if (response.status === 200) {
        dispatch(ListDistrictsSuccess(response));
        return response.data;
      }
    })
    .catch(error => {
      if (error.response) dispatch(masterListFailure(error));
    });
};

export const ListCountriesStatesDistrictsRequest = () => {
  return {
    type: Action.LIST_COUNTRIES_STATES_DISTRICTS
  };
};
export const ListCurrenciesSuccess = response => {
  return {
    type: Action.LIST_STATE_SUCCESS,
    payload: response.data,
    masterResponseStatus: response.status
  };
};
export const ListCountriesSuccess = response => {
  return {
    type: Action.LIST_COUNTRIES_SUCCESS,
    payload: response.data,
    masterResponseStatus: response.status
  };
};

export const ListStatesSuccess = response => {
  return {
    type: Action.LIST_STATE_SUCCESS,
    payload: response.data,
    masterResponseStatus: response.status
  };
};

export const ListStatesSuccessArea = response => {
  return {
    type: Action.LIST_STATE_SUCCESS,
    payload: response.data.result,
    masterResponseStatus: response.status
  };
};

export const ListDistrictsSuccessArea = response => {
  return {
    type: Action.LIST_DISTRICT_SUCCESS,
    payload: response.data.result.flat(Infinity),
    masterResponseStatus: response.status
  };
};

export const ListDistrictsSuccess = response => {
  return {
    type: Action.LIST_DISTRICT_SUCCESS,
    payload: response.data,
    masterResponseStatus: response.status
  };
};

export const ListLocationFailure = error => {
  return {
    type: Action.LIST_LOCATION_FAILURE,
    masterResponseStatus: error.response.status
  };
};

export const getSecondaryCauses = primaryCauseId => dispatch => {
  return new Promise((resolve, reject) => {
    if (!primaryCauseId || primaryCauseId.length === 0) {
      dispatch({
        type: Action.SECONDARY_CAUSE_AREA_SUCCESS,
        payload: {}
      });
      return;
    }
    let instance = HttpRequest.getInstance('HIROKU');
    instance
      .get(
        `${EndPoint.getApiUrl(
          'NGO',
          'SECONDARY_OBJECTIVE',
          'HIROKU',
          false
        )}/${primaryCauseId}`
      )
      .then(response => {
        resolve(response.data.result);
        // dispatch({
        //   type: Action.SECONDARY_CAUSE_AREA_SUCCESS,
        //   payload: response.data.data
        // });
      });
  });
};
