import { Action } from '../constants';
const initialState = {
  userInfo: { email: null },
  isLogin: false,
  loading: false,
  registrationComplete: false,
  ngoSavedData: {},
  onBoardingSavedData: {},
  messageL: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.REGISTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload
      };

    case Action.REGISTER_FAILURE:
      return {
        ...state,
        loading: false
      };

    case Action.REGISTER_COMPLETE:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case Action.NGO_SAVED_DATA_SUCCESS:
      return {
        ...state,
        ngoSavedData: {
          ...action.payload
          /* ngo_doc: action.payload.ngo_doc ? action.payload.ngo_doc[0] : null,
          trust_report: {
            ...action.payload.trust_report,
            primary_funding_source: Array.isArray(
              action.payload.trust_report.primary_funding_source
            )
              ? action.payload.trust_report.primary_funding_source
              : []
          }, */
          /* existence: action.payload.existence,
          financial_capacity: action.payload.financial_capacity,
          legal_compliance: action.payload.legal_compliance,
          institutional_capacity: action.payload.institutional_capacity,
          impact_assessment: action.payload.impact_assessment,
          social_activity: action.payload.social_activity,
          consent: action.payload.consent, */
        }
      };
    case Action.NGO_PREONBOARDING_SAVED_DATA_SUCCESS:
      return {
        ...state,
        ngoSavedData: {
          ...action.payload
        }
      };

    case Action.NGO_VERIFY_DATA:
      return {
        ...state,
        ngoSavedData: {
          ...action.payload.data,
          ngo_doc: action.payload.data.ngo_doc[0]
        },
        trust_report: {
          ...action.payload.trust_report,
          primary_funding_source: Array.isArray(
            action.payload.data.trust_report.primary_funding_source
          )
            ? action.payload.data.trust_report.primary_funding_source
            : [action.payload.data.trust_report.primary_funding_source]
        },
        message: {
          ...action.payload.message
        }
      };
    default:
      return state;
  }
};
