import axios from 'axios';

export function sendMail({
  templateName,
  sendTo,
  subject,
  isHtml = true,
  cc = '',
  bcc = '',
  templateData = {}
}) {
  if (process.env.REACT_APP_ENV) {
    subject = `${subject} - ${process.env.REACT_APP_ENV.replace(
      '-',
      ''
    ).trim()}`;
  }
  let data = {
    templateName: templateName,
    templateData: templateData,
    moduleName: 'mettasocial-platform',
    cc: cc,
    bcc: bcc,
    sendTo: sendTo,
    subject: subject,
    isHtml: isHtml
  };
  axios.post('https://email-prod.mettasocial.com/sendMail', data, {
    headers: {
      Authorization: `Basic ${btoa(
        process.env.REACT_APP_MAIL_ENGINE_USERNAME +
          ':' +
          process.env.REACT_APP_MAIL_ENGINE_PASSWORD
      )}`
    }
  });
}
