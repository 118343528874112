import React from 'react';
import './modal.css';
import { toast } from 'react-hot-toast';
import FileInputMultipleNew from '../../../components/fileInputMultipleNew';
import TextArea from '../../../components/textArea';
import { convertFormDataToJson } from '../../../helpers/profileHelpers';

function AddUpdateModal(props) {
  const input = {
    label: 'Note',
    rows: '4',
    validation: 'required',
    maxLength: 3000
  };

  const validate = (name, value) => {
    let error = '';
    const elements = document.getElementsByClassName(`${name}-error`);
    if (name === 'note') {
      if (value.trim() === '') error = `Please fill in this field`;
    }
    if (elements.length > 0) {
      if (error === '') {
        elements[0].classList.remove('d-none');
      } else {
        elements[0].classList.remove('d-none');
      }
      elements[0].innerHTML = error;
    }
    return error === '' ? true : false;
  };

  const getFileType = value => {
    if (
      value.includes('jpg') ||
      value.includes('JPG') ||
      value.includes('jpeg') ||
      value.includes('JPEG') ||
      value.includes('bmp') ||
      value.includes('BMP') ||
      value.includes('png') ||
      value.includes('PNG')
    ) {
      return 'image';
    } else {
      return 'video';
    }
  };

  const addUpdate = e => {
    e.preventDefault();
    let data = convertFormDataToJson(new FormData(e.target));
    const valid = validate('note', data.note);

    if (!valid) {
      return toast.error(
        'Oops! Error(s) found. Please fix the Error(s) and save the data.'
      );
    }
    let documentArr = [];
    let videosArr = [];
    let imagesArr = [];
    if (data.documents && data.documents !== '') {
      documentArr = data.documents.includes(',')
        ? data.documents.split(',')
        : [data.documents];
    }
    if (data.media && data.media !== '') {
      if (data.media.includes(',')) {
        const media = data.media.split(',');
        media.map(item => {
          if (getFileType(item) === 'image') {
            imagesArr.push(item);
          } else {
            videosArr.push(item);
          }
          return false;
        });
      } else {
        if (getFileType(data.media) === 'image') {
          imagesArr.push(data.media);
        } else {
          videosArr.push(data.media);
        }
      }
    }

    let payload = {
      campaign_id: parseInt(window.location.pathname.split('/')[3]),
      project_images: imagesArr,
      project_video: videosArr,
      project_docs: documentArr,
      notes: data.note
    };
    props
      .createTimeline(payload)
      .then(() => {
        toast.success('Timelines added successfully');
        props.setShowTimeline(false);
        props.getTimeline();
      })
      .catch(() => {
        toast.error('Error updating Timelines');
      });
  };

  return (
    <div className="px-2">
      <form id="timeline-add-update" onSubmit={e => addUpdate(e)}>
        <div className="row">
          <div className="col-12 form-group">
            <TextArea
              uniqueIdentifierForInput={'note'}
              input={input}
              savedValue={''}
              disabled={false}></TextArea>
            <label className="fs-10">Max 3000 characters</label>
            <p className={`text-danger fs-10 mb-0 note-error d-none`}></p>
          </div>
          <div className="col-6">
            <FileInputMultipleNew
              label={'Documents'}
              id={'documents'}
              name={'documents'}
              accept={'.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX'}
              fileSizeLimit={25}
              type={'file'}
              previewDoc={true}
              value={[]}
              disabled={false}
              refId={props.userProfile.sub}
            />
          </div>
          <div className="col-6">
            <FileInputMultipleNew
              label={'Media'}
              id={'media'}
              name={'media'}
              accept={'.jpg,.JPG,.jpeg,.JPEG,.bmp,.BMP,.png,.PNG,video/*'}
              fileSizeLimit={25}
              type={'file'}
              previewDoc={true}
              value={[]}
              disabled={false}
              refId={props.userProfile.sub}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center pt-3 mt-4">
          <button
            type="submit"
            className="btn px-5 py-3 btn-submit btn-update mb-2 fs-13">
            Save Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddUpdateModal;
