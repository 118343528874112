export const sampleData = {
  pledgeSummary: [
    {
      payment_id: '11458712',
      occurrence: 'Monthly',
      org_email: 'name.surname1@mettasocial.co.in',
      personal_email: 'name.surname1@private.com',
      pledged_on: '2022-10-05T00:00:00',
      amount: 4000
    },
    {
      payment_id: '11458713',
      occurrence: 'One Time',
      org_email: 'name.surname2@mettasocial.com',
      personal_email: 'name.surname2@private.com',
      pledged_on: '2022-10-05T00:00:00',
      amount: 5000
    },
    {
      payment_id: '11458714',
      occurrence: 'One Time',
      org_email: 'name.surname3@mettasocial.com',
      personal_email: 'name.surname3@private.com',
      pledged_on: '2022-10-10T00:00:00',
      amount: 3000
    },
    {
      payment_id: '11458715',
      occurrence: 'Monthly',
      org_email: 'name.surname4@mettasocial.com',
      personal_email: 'name.surname4@private.com',
      pledged_on: '2022-10-10T00:00:00',
      amount: 2000
    },
    {
      payment_id: '11458716',
      occurrence: 'Monthly',
      org_email: 'name.surname5@mettasocial.com',
      personal_email: 'name.surname5@private.com',
      pledged_on: '2022-10-10T00:00:00',
      amount: 500
    },
    {
      payment_id: '11458717',
      occurrence: 'Monthly',
      org_email: 'name.surname6@mettasocial.com',
      personal_email: 'name.surname6@private.com',
      pledged_on: '2022-10-15T00:00:00',
      amount: 1500
    },
    {
      payment_id: '11458718',
      occurrence: 'One Time',
      org_email: 'name.surname7@mettasocial.com',
      personal_email: 'name.surname7@private.com',
      pledged_on: '2022-10-15T00:00:00',
      amount: 2500
    },
    {
      payment_id: '11458719',
      occurrence: 'Monthly',
      org_email: 'name.surname8@mettasocial.com',
      personal_email: 'name.surname8@private.com',
      pledged_on: '2022-10-15T00:00:00',
      amount: 2000
    },
    {
      payment_id: '11458720',
      occurrence: 'Monthly',
      org_email: 'name.surname9@mettasocial.com',
      personal_email: 'name.surname9@private.com',
      pledged_on: '2022-10-20T00:00:00',
      amount: 1000
    },
    {
      payment_id: '11458721',
      occurrence: 'One Time',
      org_email: 'name.surname10@mettasocial.com',
      personal_email: 'name.surname10@private.com',
      pledged_on: '2022-20-10T00:00:00',
      amount: 3500
    },
    {
      payment_id: '11458722',
      occurrence: 'Monthly',
      org_email: 'name.surname11@mettasocial.com',
      personal_email: 'name.surname11@private.com',
      pledged_on: '2022-20-10T00:00:00',
      amount: 1500
    },

    {
      payment_id: '11458723',
      occurrence: 'Monthly',
      org_email: 'name.surname2@mettasocial.com',
      personal_email: 'name.surname2@private.com',
      pledged_on: '2022-11-05T00:00:00',
      amount: 3000
    },
    {
      payment_id: '11458724',
      occurrence: 'Monthly',
      org_email: 'name.surname4@mettasocial.com',
      personal_email: 'name.surname4@private.com',
      pledged_on: '2022-11-05T00:00:00',
      amount: 2000
    },
    {
      payment_id: '11458725',
      occurrence: 'Monthly',
      org_email: 'name.surname9@mettasocial.com',
      personal_email: 'name.surname9@private.com',
      pledged_on: '2022-11-10T00:00:00',
      amount: 1500
    },
    {
      payment_id: '11458726',
      occurrence: 'One Time',
      org_email: 'name.surname12@mettasocial.com',
      personal_email: 'name.surname12@private.com',
      pledged_on: '2022-11-10T00:00:00',
      amount: 2500
    },
    {
      payment_id: '11458727',
      occurrence: 'One Time',
      org_email: 'name.surname13@mettasocial.com',
      personal_email: 'name.surname13@private.com',
      pledged_on: '2022-11-15T00:00:00',
      amount: 1000
    },
    {
      payment_id: '11458728',
      occurrence: 'Monthly',
      org_email: 'name.surname14@mettasocial.com',
      personal_email: 'name.surname14@private.com',
      pledged_on: '2022-11-15T00:00:00',
      amount: 3500
    },
    {
      payment_id: '11458729',
      occurrence: 'Monthly',
      org_email: 'name.surname6@mettasocial.com',
      personal_email: 'name.surname6@private.com',
      pledged_on: '2022-11-20T00:00:00',
      amount: 500
    },
    {
      payment_id: '11458730',
      occurrence: 'One Time',
      org_email: 'name.surname15@mettasocial.com',
      personal_email: 'name.surname15@private.com',
      pledged_on: '2022-11-20T00:00:00',
      amount: 1700
    },

    {
      payment_id: '11458731',
      occurrence: 'Monthly',
      org_email: 'name.surname16@mettasocial.com',
      personal_email: 'name.surname16@private.com',
      pledged_on: '2022-12-05T00:00:00',
      amount: 3600
    },
    {
      payment_id: '11458732',
      occurrence: 'Monthly',
      org_email: 'name.surname17@mettasocial.com',
      personal_email: 'name.surname17@private.com',
      pledged_on: '2022-12-05T00:00:00',
      amount: 5000
    },
    {
      payment_id: '11458733',
      occurrence: 'One Time',
      org_email: 'name.surname18@mettasocial.com',
      personal_email: 'name.surname18@private.com',
      pledged_on: '2022-12-05T00:00:00',
      amount: 1400
    },
    {
      payment_id: '11458734',
      occurrence: 'Monthly',
      org_email: 'name.surname6@mettasocial.com',
      personal_email: 'name.surname6@private.com',
      pledged_on: '2022-12-10T00:00:00',
      amount: 5300
    },
    {
      payment_id: '11458735',
      occurrence: 'Monthly',
      org_email: 'name.surname7@mettasocial.com',
      personal_email: 'name.surname7@private.com',
      pledged_on: '2022-12-15T00:00:00',
      amount: 1500
    },
    {
      payment_id: '11458736',
      occurrence: 'Monthly',
      org_email: 'name.surname1@mettasocial.com',
      personal_email: 'name.surname1@private.com',
      pledged_on: '2022-12-15T00:00:00',
      amount: 900
    },
    {
      payment_id: '11458737',
      occurrence: 'One Time',
      org_email: 'name.surname2@mettasocial.com',
      personal_email: 'name.surname2@private.com',
      pledged_on: '2022-12-20T00:00:00',
      amount: 1500
    },

    {
      payment_id: '11458738',
      occurrence: 'Monthly',
      org_email: 'name.surname7@mettasocial.com',
      personal_email: 'name.surname7@private.com',
      pledged_on: '2023-01-05T00:00:00',
      amount: 600
    },
    {
      payment_id: '11458739',
      occurrence: 'Monthly',
      org_email: 'name.surname12@mettasocial.com',
      personal_email: 'name.surname12@private.com',
      pledged_on: '2023-01-10T00:00:00',
      amount: 1700
    },
    {
      payment_id: '11458740',
      occurrence: 'Monthly',
      org_email: 'name.surname11@mettasocial.com',
      personal_email: 'name.surname11@private.com',
      pledged_on: '2023-01-10T00:00:00',
      amount: 3000
    },
    {
      payment_id: '11458741',
      occurrence: 'One Time',
      org_email: 'name.surname6@mettasocial.com',
      personal_email: 'name.surname6@private.com',
      pledged_on: '2023-01-15T00:00:00',
      amount: 1500
    },
    {
      payment_id: '11458742',
      occurrence: 'One Time',
      org_email: 'name.surname19@mettasocial.com',
      personal_email: 'name.surname19@private.com',
      pledged_on: '2023-01-15T00:00:00',
      amount: 800
    },
    {
      payment_id: '11458743',
      occurrence: 'Monthly',
      org_email: 'name.surname20@mettasocial.com',
      personal_email: 'name.surname20@private.com',
      pledged_on: '2023-01-20T00:00:00',
      amount: 4200
    },
    {
      payment_id: '11458744',
      occurrence: 'One Time',
      org_email: 'name.surname1@mettasocial.com',
      personal_email: 'name.surname1@private.com',
      pledged_on: '2023-01-20T00:00:00',
      amount: 800
    },
    {
      payment_id: '11458745',
      occurrence: 'Monthly',
      org_email: 'name.surname2@mettasocial.com',
      personal_email: 'name.surname2@private.com',
      pledged_on: '2023-01-20T00:00:00',
      amount: 1500
    },

    {
      payment_id: '11458746',
      occurrence: 'One Time',
      org_email: 'name.surname4@mettasocial.com',
      personal_email: 'name.surname4@private.com',
      pledged_on: '2023-02-05T00:00:00',
      amount: 900
    },
    {
      payment_id: '11458747',
      occurrence: 'One Time',
      org_email: 'name.surname3@mettasocial.com',
      personal_email: 'name.surname3@private.com',
      pledged_on: '2023-02-05T00:00:00',
      amount: 4000
    },
    {
      payment_id: '11458748',
      occurrence: 'Monthly',
      org_email: 'name.surname8@mettasocial.com',
      personal_email: 'name.surname8@private.com',
      pledged_on: '2023-02-10T00:00:00',
      amount: 5000
    },
    {
      payment_id: '11458749',
      occurrence: 'Monthly',
      org_email: 'name.surname13@mettasocial.com',
      personal_email: 'name.surname13@private.com',
      pledged_on: '2023-02-10T00:00:00',
      amount: 1000
    },
    {
      payment_id: '11458750',
      occurrence: 'One Time',
      org_email: 'name.surname14@mettasocial.com',
      personal_email: 'name.surname14@private.com',
      pledged_on: '2023-02-15T00:00:00',
      amount: 1500
    },
    {
      payment_id: '11458750',
      occurrence: 'Monthly',
      org_email: 'name.surname16@mettasocial.com',
      personal_email: 'name.surname16@private.com',
      pledged_on: '2023-02-15T00:00:00',
      amount: 3700
    },
    {
      payment_id: '11458751',
      occurrence: 'One Time',
      org_email: 'name.surname7@mettasocial.com',
      personal_email: 'name.surname7@private.com',
      pledged_on: '2023-02-20T00:00:00',
      amount: 1400
    },
    {
      payment_id: '11458752',
      occurrence: 'Monthly',
      org_email: 'name.surname8@mettasocial.com',
      personal_email: 'name.surname8@private.com',
      pledged_on: '2023-02-20T00:00:00',
      amount: 3300
    },

    {
      payment_id: '11458753',
      occurrence: 'Monthly',
      org_email: 'name.surname9@mettasocial.com',
      personal_email: 'name.surname9@private.com',
      pledged_on: '2023-03-05T00:00:00',
      amount: 1400
    },
    {
      payment_id: '11458754',
      occurrence: 'One Time',
      org_email: 'name.surname19@mettasocial.com',
      personal_email: 'name.surname19@private.com',
      pledged_on: '2023-03-06T00:00:00',
      amount: 1200
    },
    {
      payment_id: '11458755',
      occurrence: 'Monthly',
      org_email: 'name.surname20@mettasocial.com',
      personal_email: 'name.surname20@private.com',
      pledged_on: '2023-03-10T05:00:00',
      amount: 1600
    }
  ],
  stats: {
    profileImage:
      'https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile-thumbnail.png',
    recurring: [
      { name: 'John Doe #1', amount: 20000 },
      { name: 'Jane Doe #1', amount: 45000 },
      { name: 'John Doe #2', amount: 75000 },
      { name: 'Jane Doe #1', amount: 13000 },
      { name: 'Jane Doe #3', amount: 5000 }
    ],
    oneTime: [
      { name: 'John Doe #1', amount: 10000 },
      { name: 'Jane Doe #1', amount: 4500 },
      { name: 'John Doe #2', amount: 7500 },
      { name: 'Jane Doe #1', amount: 1000 },
      { name: 'Jane Doe #3', amount: 2500 }
    ],
    amountRaised: [
      { name: 'Recurring (Monthly)', value: 350000 },
      { name: 'One Time', value: 118000 }
    ],
    fundUtilisation: [
      { name: 'Funds Raised', value: 468900 },
      { name: 'Funds Utilised', value: 310900 }
    ],
    activeSUpporters: [0, 10, 12, 14, 22, 24, 21, 30, 35, 40, 36, 40],
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
  }
};
