import React, { useMemo } from 'react';
import '../styles/pagination.css';

export const DOTS = '...';

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    postsPerPageSelectArray = []
  } = props;
  const totalPages = Math.ceil(totalCount / pageSize);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  return (
    <div className="row my-3">
      <div className="col-4 d-flex gap p-0 align-self-center">
        <div className="dropdown bg-white rounded">
          <button
            className="btn dropdown dropdown-toggle filter-select text-left pagination_select_box"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            {pageSize}
          </button>
          <ul
            className="dropdown-menu pagination_dropdown_menu "
            aria-labelledby="dLabel">
            {postsPerPageSelectArray.map(item => {
              return (
                <li
                  key={item}
                  className="pagination_dropdown_item"
                  onClick={() => onPageChange(['postsPerPage', item])}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="align-middle align-self-center text_color_pagination">
          Rows per page
        </div>
      </div>
      <div className="col-8 d-flex ml-auto p-0">
        <div className={`ml-auto d-flex mr-4rm`}>
          <div className="d-flex text_color_pagination align-self-center">
            Displaying
            <span className="ml-2 mr-2">{`${(currentPage - 1) * pageSize +
              1}  -  ${Math.min(
              currentPage * pageSize,
              totalCount
            )}`}</span>{' '}
            of
            <span className="ml-2 mr-2">{`${totalCount}`}</span>
          </div>
          {currentPage === 0 || paginationRange?.length < 2 ? (
            <></>
          ) : (
            <div className="d-flex align-self-center">
              <button
                className="pagination_icon_btn m-1"
                disabled={currentPage === 1}
                onClick={() => onPageChange(['Prev'])}>
                <i className="fas fa-chevron-left"></i>
                {/* <img src={LeftArrow} alt="Prev" /> */}
              </button>
              <div className="m-1 d-flex gap">
                {paginationRange &&
                  paginationRange.map((pageNumber, key) => {
                    if (pageNumber === DOTS) {
                      return (
                        <div key={`nsl-${key}`} className="mt-1">
                          &#8230;
                        </div>
                      );
                    }
                    return (
                      <div key={`nsl-${key}`}>
                        <button
                          onClick={() =>
                            onPageChange(['currentPage', pageNumber])
                          }
                          className={
                            pageNumber === currentPage
                              ? 'pagination_number_btn_active'
                              : 'pagination_number_btn'
                          }>
                          {pageNumber}
                        </button>
                      </div>
                    );
                  })}
              </div>
              <button
                disabled={currentPage === totalPages}
                className="pagination_icon_btn my-1 ml-1 mr-0"
                onClick={() => onPageChange(['Next'])}>
                <i className="fas fa-chevron-right"></i>
                {/* <img src={RightArrow} alt="Next" /> */}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
