import React, { useState } from 'react';
import Modal from '../../newDesign/Modals/Modal';
import toast from 'react-hot-toast';

function ReceiptSentSuccessModal(props) {
  const [disableBtn, setDisableBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    setIsLoading(false);
    setDisableBtn(false);
    props.setReceiptsSent(false);
  };
  const sendReceiptToAll = () => {
    setDisableBtn(true);
    setIsLoading(true);
    props
      .sendReceiptToAllRecords(props.ngoId)
      .then(res => {
        if (res.code === '200') {
          setIsLoading(false);
          if (res.message === 'Request is successful') {
            toast.success('Receipts sent successfully!');
          } else {
            toast.success(res.message);
          }

          props.getDonationReport(false, false, props.initialFyDate);
          handleCloseModal();
        }
      })
      .catch(error => {
        if (error) {
          setDisableBtn(false);
          setIsLoading(false);
          toast.error('Error while sending receipts. Please try again later.');
        }
      });
  };
  const getSpinner = loader => {
    if (isLoading) {
      return (
        <div
          className="spinner-border spinner-border-lg mx-3 text-primary"
          role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    }
  };
  return (
    <>
      <Modal
        show={props.receiptsSent}
        title={'Confirm Sending Receipts'}
        handleCloseModal={() => handleCloseModal()}
        sizeClass={'modal-md'}>
        <div className="fs-14 text-1B1C1D">
          By confirming, receipts will be generated and sent to all donors for
          their contribution
        </div>
        <div className="d-flex float-right">
          <button
            type="button"
            className="btn btn-light px-3 py-2 mx-4"
            onClick={() => handleCloseModal()}>
            Cancel
          </button>
          <button
            className="btn btn-primary position-relative cursor-pointer mx-2"
            onClick={() => sendReceiptToAll()}
            disabled={disableBtn}>
            Confirm
          </button>
          <div>{getSpinner()}</div>
        </div>
        <br></br>
      </Modal>
    </>
  );
}
export default ReceiptSentSuccessModal;
