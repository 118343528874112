import axios from 'axios';
import { EndPoint } from '../constants';
import { isTokenExpired, login, token, updateToken } from './keycloak';

const HttpRequest = {
  getInstance: (serverName = 'HIROKU') => {
    const axiosinstance = axios.create({
      baseURL: EndPoint.getBaseUrl(serverName)
    });
    axiosinstance.interceptors.request.use(config => {
      if (isTokenExpired(10)) {
        return updateToken(10)
          .then(() => {
            config.headers['Authorization'] = `Bearer ${token()}`;
            return Promise.resolve(config);
          })
          .catch(err => {
            console.error(err);
            login();
          });
      } else {
        config.headers['Authorization'] = `Bearer ${token()}`;
        return Promise.resolve(config);
      }
    });
    return axiosinstance;
  },
  getInstanceS: serverName => {
    const axiosinstance = axios.create({
      baseURL: EndPoint.getBaseUrl(serverName)
    });
    axiosinstance.interceptors.request.use(config => {
      if (isTokenExpired(10)) {
        return updateToken(10)
          .then(() => {
            config.headers['Authorization'] = `Bearer ${token()}`;
            return Promise.resolve(config);
          })
          .catch(err => {
            console.error(err);
            login();
          });
      } else {
        config.headers['Authorization'] = `Bearer ${token()}`;
        return Promise.resolve(config);
      }
    });
    return axiosinstance;
  },
  getInstance: (serverName = 'WORKPLACE') => {
    const axiosinstance = axios.create({
      baseURL: EndPoint.getBaseUrl(serverName)
    });
    axiosinstance.interceptors.request.use(config => {
      if (isTokenExpired(10)) {
        return updateToken(10)
          .then(() => {
            config.headers['Authorization'] = `Bearer ${token()}`;
            return Promise.resolve(config);
          })
          .catch(err => {
            console.error(err);
            login();
          });
      } else {
        config.headers['Authorization'] = `Bearer ${token()}`;
        return Promise.resolve(config);
      }
    });
    return axiosinstance;
  },
  getInstance: (serverName = 'WORKPLACE') => {
    const axiosinstance = axios.create({
      baseURL: EndPoint.getBaseUrl(serverName)
    });
    axiosinstance.interceptors.request.use(config => {
      if (isTokenExpired(10)) {
        return updateToken(10)
          .then(() => {
            config.headers['Authorization'] = `Bearer ${token()}`;
            return Promise.resolve(config);
          })
          .catch(err => {
            console.error(err);
            login();
          });
      } else {
        config.headers['Authorization'] = `Bearer ${token()}`;
        return Promise.resolve(config);
      }
    });
    return axiosinstance;
  }
};
export default HttpRequest;
