import React, { useEffect, useState } from 'react';
import MultiSelectCheckbox from '../multiselectCheckbox';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/date-picker.css';
import SelectOptionDropdown from '../selectOptionDropdown';
import DonarDetailsTable from './iGivXnReports/donarDetailsTable';
import Pagination from '../paginationFullRow';
import DonationReceipts from './iGivXnReports/donationReceipts';
import {
  reportListConditional,
  reportListMettaVerified
} from './iGivXnReports/dropdownList';
import { financialYears } from './iGivXnReports/financialYearCalculation';

var sortBy = 'transaction_date';
var orderBy = 'desc';
var currentPageValue = 1;
var postsOnPage = 10;
var initFinancialYear1 = [];
var initFinancialYear2 = [];
var initialFyDate = '';
var financialYearDropdown = [];
function DonationReportIGivXn(props) {
  var reportList = reportListMettaVerified;
  var dateOfRegistration = '';
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const policies = JSON.parse(window.localStorage.getItem('userPolicies'));
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [donationReportList, setDonationReportList] = useState([]);
  const [donationMissionList, setDonationMissionList] = useState([]);
  const [checkedMissions, setCheckedMissions] = useState([]);
  const [selectedMissionCount, setSelectedMissionCount] = useState(0);
  const [selectAllMissions, setSelectAllMissions] = useState(false);
  const [donationReportListCount, setDonationReportListCount] = useState(0);
  // const [exportReportData, setExportReportData] = useState([]);
  const [exportReportData10Bd, setExportReportData10Bd] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [missionList, setMissionList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(reportList[0]);
  const [selectedFy, setSelectedFy] = useState('');
  const [reportName, setReportName] = useState('Donor Details');
  const [uploadedReceiptData, setUploadedReceiptData] = useState('');

  let policy = policies.filter(x =>
    x.resource_feature.toLowerCase().includes('donation report export')
  );
  const getInitialFyDate = () => {
    if (props?.registrationInfo?.result !== undefined) {
      dateOfRegistration = props?.registrationInfo?.result?.date_of_incorp?.slice(
        0,
        10
      );
      dateOfRegistration = new Date(dateOfRegistration);
      financialYearDropdown = financialYears(dateOfRegistration, new Date());
      initFinancialYear1 = financialYearDropdown?.filter(
        option => option?.fy_id === 0
      );
      initFinancialYear2 = financialYearDropdown?.filter(
        option => option?.fy_id === 1
      );
      initialFyDate = initFinancialYear1;
      if (initFinancialYear2?.length > 0 && currentMonth <= 4) {
        initialFyDate = initFinancialYear2;
      }
    }
    setSelectedFy(initialFyDate[0]);
  };

  if (
    props?.state?.ngo?.ngoProfile?.is_conditionally_approved === true &&
    props?.state?.ngo?.ngoProfile?.is_mettasocial_preonboarded === false
  ) {
    reportList = reportListConditional;
  }

  useEffect(() => {
    getInitialFyDate();
    getMissionList();
    getUploadedData();
    getDonationReport(false, false, initialFyDate[0]);
  }, [props?.registrationInfo]);

  const getUploadedData = () => {
    props
      .getDonationReceiptUploadedData(
        props?.state?.login?.userInfo?.orgId ||
          props?.state?.login?.userInfo?.sub
      )
      .then(res => {
        setUploadedReceiptData(res);
      });
  };

  const clearFilters = () => {
    setCheckedMissions(donationMissionList);
    setMissionList([]);
    setSelectedMissionCount(donationMissionList?.length);
    setSelectAllMissions(true);
    setSelectedFy(initialFyDate[0]);
    setEndDate(initialFyDate[0].end_date);
    setStartDate(initialFyDate[0].start_date);
    sortBy = 'transaction_date';
    orderBy = 'desc';

    if (startDate !== '' || endDate !== '' || missionList?.length > 0) {
      getDonationReport(true, true, initialFyDate[0]);
    } else {
      getDonationReport(true, false, initialFyDate[0]);
    }
  };

  const getDonationReport = (clearFilter, isFilter, selectedDates) => {
    let skip = postsOnPage * currentPageValue - postsOnPage;
    if (isFilter === true) {
      setCurrentPage(1);
      skip = 0;
    }
    let data = {
      start_date: selectedDates?.start_date,
      end_date: selectedDates?.end_date,
      mission_names_list: !clearFilter
        ? checkedMissions?.map(item => item.name)
        : [],
      sort_by: sortBy,
      order_by: orderBy
    };

    props
      .getDonationReportList(
        props.state.login.userInfo.orgId || props.state.login.userInfo.sub,
        skip,
        postsOnPage,
        data
      )
      .then(res => {
        setDonationReportList(res?.donation_summary_records || []);
        setDonationReportListCount(res?.total_records_count);

        props
          .getDonationReportExport(
            props.state.login.userInfo.orgId || props.state.login.userInfo.sub,
            skip,
            res?.total_records_count,
            data
          )
          .then(res => {
            //  setExportReportData(res);
          });

        props
          .getDonationReportExport10Bd(
            props.state.login.userInfo.orgId || props.state.login.userInfo.sub,
            skip,
            res?.total_records_count,
            data
          )
          .then(res => {
            setExportReportData10Bd(res);
          });
      });
  };
  const sortByColumn = name => {
    if (orderBy === '') {
      orderBy = 'desc';
    } else if (orderBy === 'desc') {
      orderBy = 'asc';
    } else {
      orderBy = 'desc';
    }
    sortBy = name;
    getDonationReport(false, false, selectedFy);
  };

  const getMissionList = () => {
    setDonationMissionList(missionList);
    props
      .getDonationMissionList(
        props.state.login.userInfo.orgId || props.state.login.userInfo.sub
      )
      .then(res => {
        setDonationMissionList(res);
      });
  };

  const onPageChange = option => {
    if (option[0] === 'Prev') {
      setCurrentPage(prev => prev - 1);
      currentPageValue = currentPage - 1;
    } else if (option[0] === 'Next') {
      setCurrentPage(prev => prev + 1);
      currentPageValue = currentPage + 1;
    } else if (option[0] === 'postsPerPage') {
      setCurrentPage(1);
      currentPageValue = 1;
      setPostsPerPage(option[1]);
      postsOnPage = option[1];
    } else if (option[0] === 'currentPage') {
      setCurrentPage(option[1]);
      currentPageValue = option[1];
    }
    getDonationReport(false, false, selectedFy);
  };

  const handleReportSelection = option => {
    setSelectedOption(option);
    setReportName(option?.name);
    clearFilters();
  };
  const handleFySelection = option => {
    setSelectedFy(option);
    getDonationReport(false, true, option);
  };
  const downloadCsv = csvData => {
    const url = window.URL.createObjectURL(
      new Blob([csvData], {
        type: 'text/csv;charset=utf-8,'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Donation_Report');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const getExportDataButton = () => {
    if (donationReportListCount > 0) {
      return (
        <button
          className="btn btn-outline-primary btn-lg mr-2 px-4 fs-14 dm-bold "
          onClick={() =>
            downloadCsv(exportReportData10Bd ? exportReportData10Bd : [])
          }>
          Export
        </button>
      );
    }
  };

  const getPagination = () => {
    if (donationReportListCount > 0) {
      return (
        <div className="px-4 bottom-bar no-print">
          <Pagination
            pageSize={postsPerPage}
            totalCount={donationReportListCount}
            currentPage={currentPage}
            onPageChange={onPageChange}
            postsPerPageSelectArray={[10, 20, 30, 40, 50]}></Pagination>
        </div>
      );
    }
  };
  const handleMissionSelections = () => {
    getDonationReport(false, true, selectedFy);
  };
  const getReportTable = () => {
    if (reportName === 'Donor Receipts') {
      return (
        <DonationReceipts
          ngoId={
            props?.state?.login?.userInfo?.orgId ||
            props?.state?.login?.userInfo?.sub
          }
          donationReportListCount={donationReportListCount}
          donationReportList={donationReportList}
          sortByColumn={sortByColumn}
          uploadedReceiptData={uploadedReceiptData}
          serialNumber={currentPage * postsPerPage + 1 - postsPerPage}
          postDonationReceiptUploadedData={
            props.postDonationReceiptUploadedData
          }
          sendReceiptToAllRecords={props.sendReceiptToAllRecords}
          getDonationReport={getDonationReport}
          initialFyDate={selectedFy}
        />
      );
    } else {
      return (
        <DonarDetailsTable
          donationReportListCount={donationReportListCount}
          donationReportList={donationReportList}
          sortByColumn={sortByColumn}
          reportName={reportName}
          serialNumber={currentPage * postsPerPage + 1 - postsPerPage}
          exportReportData10Bd={exportReportData10Bd}
          policy={policy}
        />
      );
    }
  };
  return (
    <React.Fragment>
      <div className="row mt-3  ">
        <div className="mx-4 w-15 no-print">
          <label className="mx-1 mt-3">Select Report</label>
          <SelectOptionDropdown
            hideSearch={true}
            selectedData={reportList}
            selectedOption={selectedOption}
            handleSelection={handleReportSelection}
            setSelectedCount={setSelectedMissionCount}
            selectedCount={selectedMissionCount}
            setFinalList={setMissionList}
            searchPlaceholder="Search Report"
          />
        </div>
        <div className="w-15 no-print">
          <label className="mx-1 mt-3">Duration</label>
          <SelectOptionDropdown
            hideSearch={false}
            selectedData={financialYearDropdown}
            selectedOption={selectedFy || initialFyDate[0]}
            handleSelection={handleFySelection}
            setSelectedCount={setSelectedMissionCount}
            selectedCount={selectedMissionCount}
            setFinalList={setMissionList}
            searchPlaceholder="Search Year"
            searchText={'Search Duration'}
          />
        </div>
        <div className="w-15 mt-3 no-print">
          <label className="mx-2 mt-5">
            {selectedFy?.label || initialFyDate[0]?.label}
          </label>
        </div>
        {reportName !== 'Generate 10BD' && (
          <>
            <div className="col-1 mx-4"></div>
            {policy && policy?.length > 0 && (
              <div className=" no-print mx-4 mt-5">{getExportDataButton()}</div>
            )}
            <div className=" no-print mx-2 w-20 float-right">
              {reportName !== 'Generate 10BD' && (
                <>
                  <label className="mx-2 mt-3">Missions</label>
                  <MultiSelectCheckbox
                    selectedData={donationMissionList}
                    checkedOptions={checkedMissions}
                    setCheckedOptions={setCheckedMissions}
                    setSelectedCount={setSelectedMissionCount}
                    selectedCount={selectedMissionCount}
                    setSelectAll={setSelectAllMissions}
                    selectAll={selectAllMissions}
                    setFinalList={setMissionList}
                    searchPlaceholder="Search Mission"
                    selectAllText={'All Missions'}
                    allOptionsSelected={true}
                    handleMissionSelections={handleMissionSelections}
                  />
                </>
              )}
            </div>
          </>
        )}

        <div className="no-print mx-4 mt-5 ml-8">
          <button
            type="link"
            className="btn text-007bff fs-14 cursor-pointer"
            onClick={() => clearFilters()}>
            Clear Filters
          </button>
        </div>
      </div>
      <div>
        <div className="p-3 h-80 mx-2 ">
          <>
            {getReportTable()}

            <div className="col-12 row"></div>
          </>
        </div>
        {getPagination()}
      </div>
    </React.Fragment>
  );
}
export default DonationReportIGivXn;
