import dayjs from 'dayjs';
import { defaults, forEach, map, pick } from 'lodash';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import {
  createOrModifyCampaign,
  getCampaign,
  getMasterData,
  getMasterDataById,
  getMasterListCountries,
  getStatesDataById,
  getDistrictsDataById,
  getMasterListCurrencies,
  getProjectActivities,
  getCampaignProposalStatus,
  getCampaignProposalModificationStatus,
  acceptCampaignProposalModification,
  checkForCorpInterest,
  resetCampaignFormData,
  getNGOCorpForPublished,
  getNgoProfile,
  getAllExpenseSubCategory
} from '../../actions';
import { getHeaderData } from '../../actions/ngo.actions';
// import { Common } from '../../constants';
import { Common, EndPoint } from '../../constants';
import { Routes } from '../../constants/path.constants';
import { sendMail } from '../../helpers/emailSender';
import HttpRequest from '../../helpers/http-request';
import imageResolutionValidator from '../../helpers/imageResValidator';
import { getCloudinaryUrlForImage } from '../../helpers/util';
import FileInput from '../fileInput';
import {
  InputCheckBox,
  // InputDaypicker,
  InputSelect,
  InputText,
  InputTextArea,
  InputMultiSelect,
  InputSelectSplit,
  InputRadio
} from '../FormElements';
import InputRichTextEditor from '../richTextEditorInput';
import Loader from '../loader';
import CampaignPreview from './preview';
import Modal from '../newDesign/Modals/Modal';
import {
  convertInCommaFormat,
  getNumber
} from '../../helpers/commaSeparatedInput';
import StickyBottomBar from '../ngocomponent/stickyBottomBar';
import ProposedTable from '../component/proposedProjectTables';
import PopupModal from './popupModals';
import thankyou from '../../assets/images/thankyou.png';
import MultiSelect from '../multifilter/corpSelect';
import { isAuthorized } from '../../helpers/auth-roles';
import AuthHideShow from '../authHideShow';

var campaignIdValue;
var isCampaignUnderReview;
var isCampaignWaitingForFunds;
const $ = window.$;
var adminName = window.location.href.split('#')[1];
var ngoEmail = window.location.href.split('email=')[1];
ngoEmail = ngoEmail?.includes('#')
  ? window.location.href.split('email=')[1]?.split('#')[0]
  : window.location.href.split('email=')[1];
class CreateModifyCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.fileInfo = null;
    let ngoId =
      this.props.userProfile.orgId || this.props.userProfile.sub || null;
    this.state = {
      publishedFor: [],
      corpPublishedList: [],
      pubSelected: [],
      campaignId: null,
      campaignDetails: null,
      campaignName: '',
      campaignStatus: '',
      fundAmount: 0,
      ngoName: '',
      ngoId: ngoId,
      isCorpInterested: false,
      corpEmail: '',
      corpName: '',
      uploadInProgress: false,
      showPreview: false,
      currentCampaignStatus: '',
      saveRequestMade: false,
      ngoApproved: props.isNgoApproved,
      showProjectDateOverlay: true,
      disable_preview_button: true,
      disable_campaign_type: true,
      disable_campaign_content_save_button: true,
      tabName: 'ConceptNoteDetails',
      isCampaignSaved: false,
      proposedBudget: false,
      submitCampaign: false,
      activity: false,
      projectIndicator: false,
      showCampaignDetails: true,
      showCancelPopup: false,
      successModalPopup: false,
      proposalStatus: [],
      praposalModificationStatus: false,
      praposalModificationObject: {},
      newSection: [],
      allExpenseSubCategoryList: [],
      formFields: {
        campaignName: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'title'
        },
        projectSummary: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'project_summary'
        },
        projectGoal: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'project_goal'
        },
        projectObjective: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'project_objective'
        },
        primaryCauseArea: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'cause_area'
        },
        secondaryCauseArea: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'sub_cause_area'
        },
        campaignType: {
          isValid: true,
          value: 3,
          error: '',
          jsonField: 'campaign_type_id'
        },
        beneficiariesImpacted: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'target_beneficiary'
        },
        sdg: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'sdg'
        },
        requestedFundAmount: {
          isValid: false,
          value: null,
          error: '',
          jsonField: 'requested_fund_amount'
        },
        beneficiaries: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'no_of_beneficiaries'
        },
        beneficiaryCost: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'beneficiary_cost'
        },
        projectPeriod: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'project_period'
        },

        rolloverImage: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'image_url'
        },
        problemStatement: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'problem_statement'
        },
        natureOfSolution: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'nature_of_solution'
        },
        isNewProject: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'is_new_project'
        },
        projectSustainability: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'project_sustainability'
        },
        budget: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'budget'
        },
        proposalDoc: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'proposal_doc'
        },
        needsAssessment: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'needs_assessment'
        },
        surveyDocument: {
          isValid: false,
          value: '',
          error: '',
          jsonField: 'survey_document'
        },

        fundAmount: {
          isValid: true,
          value: '',
          error: '',
          jsonField: 'fund_amount'
        },
        projectLocation: {
          isValid: true,
          value: [
            {
              id: null,
              projectAddress: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'address'
              },
              projectCountry: {
                isValid: false,
                value: 101,
                error: '',
                jsonField: 'country'
              },
              pinCode: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'pincode'
              },
              projectState: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'state'
              },
              projectDistrict: {
                isValid: false,
                value: '',
                error: '',
                jsonField: 'city'
              }
            }
          ],
          error: '',
          jsonField: 'project_location'
        },

        framework: {
          isValid: false,
          value: 'BASIC',
          error: '',
          jsonField: 'framework'
        },
        tnc_accepted: {
          isValid: false,
          value: false,
          error: '',
          jsonField: 'tnc_accepted'
        }
      },
      deletedLocationArr: [],
      formValid: false,
      campTypeVolunteer: false,
      campTypeCommunity: false,
      campTypeDirect: false,
      districtsLists: {
        district: [[]]
      }
    };
    this.fetchingDistrict = {
      inProgress: false,
      value: null,
      itemIndex: null
    };
    this.editDetailRequested = false;
    this.editDetailRecieved = false;
    this.praposalDocUploadSucess = false;
    this.tempDistrictListStorage = [];
    this.fetchingPreview = false;
  }

  handleCancelPopup = value => {
    this.setState({
      showCancelPopup: value
    });
  };

  showSuccessModal = () => {
    this.setState({ successModalPopup: true });
  };
  closeSuccessModal = () => {
    this.setState({ successModalPopup: false });
  };

  removeUnsavedData = e => {
    if (window.location.href.includes('email')) {
      let email = window.location.href.split('email=')[1];
      this.props.history.push(`/ngo/project/list?email=${email}`);
    } else {
      this.props.history.push(`/ngo/project/list`);
    }
    // window.location.href = '/ngo/project/list';
  };

  getCampaignProposalStatus = campaignId => {
    this.props
      .getCampaignProposalStatus(parseInt(campaignId))
      .then(res => {
        this.setState({
          proposalStatus: res.result
        });
      })
      .catch(async err => {});
    this.props.checkForCorpInterest(parseInt(campaignId)).then(res => {
      if (res.data.result?.corp_interest !== false) {
        this.setState({
          isCorpInterested: true,
          corpEmail: res.data.result?.corp_email,
          corpName: res.data.result?.corp_name
        });
      }
    });
  };

  comgetNGOCorpForPublished = (ngoId, emailId, isEv) => {
    this.props
      .getNGOCorpForPublished(ngoId, emailId)
      .then(res => {
        if (res.code === '200') {
          this.setState(prevState => {
            return {
              publishedFor: Object.keys(res?.result)?.length
                ? isEv
                  ? [
                      {
                        name: 'Everyone',
                        id: 'everyone',
                        is_condtional: false
                      },
                      ...res.result.final_cond_list_for_campaign
                    ]
                  : [...res.result.final_cond_list_for_campaign]
                : [...prevState.publishedFor]
            };
          });
          this.setState({ corpPublishedList: this.state.publishedFor });
        }
      })
      .catch(e => {});
  };

  getCampaignProposalModificationStatus = campaignId => {
    this.props
      .getCampaignProposalModificationStatus(parseInt(campaignId))
      .then(res => {
        //if (res.result.is_campaign_mod !== 'DRAFT') {
        const {
          is_campaign_mod,
          is_ngo_approved,
          fund_amount,
          no_of_beneficiaries
        } = res.result;
        if (
          is_campaign_mod !== 'False' &&
          is_ngo_approved &&
          (fund_amount || no_of_beneficiaries)
        ) {
          this.setState({
            praposalModificationStatus:
              is_campaign_mod !== 'False' &&
              is_ngo_approved &&
              (fund_amount || no_of_beneficiaries),
            praposalModificationObject: res.result,
            tabName: 'ConceptNoteDetails',
            proposedBudget: false,
            activity: false,
            projectIndicator: false,
            showCampaignDetails: true
          });
        } else {
          setTimeout(() => {
            this.enableSaveButton();

            this.setState({
              // tabName: 'CampaignDetails',
              tabName:
                adminName === undefined
                  ? 'ProposeBudget'
                  : 'ConceptNoteDetails',
              proposedBudget: adminName === undefined ? true : false,
              activity: false,
              projectIndicator: false,
              showCampaignDetails: adminName === undefined ? false : true
            });
          }, 1000);
        }
      })
      .catch(async err => {
        this.setState({
          praposalModificationStatus: false,
          praposalModificationObject: {},
          tabName: 'ProposeBudget',
          proposedBudget: true,
          activity: false,
          projectIndicator: false,
          showCampaignDetails: false
        });
      });
  };

  acceptCampaignProposalModification = (campaignId, data) => {
    this.props
      .acceptCampaignProposalModification(campaignId)
      .then(res => {
        const { campaignName, campaignDetails } = this.state;

        try {
          sendMail({
            templateName: 'projectProposalModificationCorp',
            sendTo: data.corp_email,
            subject: 'Project Proposal Modified',
            templateData: {
              campaignName: campaignName
                ? campaignName
                : campaignDetails.title
                ? campaignDetails.title
                : ''
            }
          });
          sendMail({
            templateName: 'projectProposalModificationAlertAdmin',
            sendTo: 'ngo.partnerships@mettasocial.com',
            subject: 'Modification Alert - Project',
            templateData: {
              campaignName: campaignName
                ? campaignName
                : campaignDetails.title
                ? campaignDetails.title
                : '',
              corpName: data.corp_name ? data.corp_name : ''
            }
          });
          toast.success('Proposal Modification request accepted');
        } catch (e) {
          throw new Error(
            'Some error occurred while sending email, please try again in sometime'
          );
        }
      })
      .catch(async err => {
        toast.error('Some error occurred, please try again in sometime', {
          id: 'saveError'
        });
      });
    toast.dismiss();
  };

  handleAddNewSection = () => {
    const item = {};
    this.setState({ newSection: [...this.state.newSection, item] });
  };

  checkNgoApproved = () => {
    if (!this.props.isNgoApproved) {
      return true;
    }
  };
  Validations = {
    required: value => {
      return value ? value.toString().trim().length > 0 : false;
    },
    requiredSelect: value => {
      return value && value.length > 0;
    },
    richTextSize: value => {
      return value.toString().trim().length > 50000;
    }
  };
  validate = (name, value) => {
    let isFieldValid = true;
    let msg = '';
    switch (name) {
      case 'publishedFor':
        msg = Common.NGO.PAGE_ERRORS.MISSION_VISIBILITY_REQUIRED;
        break;
      case 'campaignName':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value.length < 10) msg = Common.NGO.PAGE_ERRORS.MIN_10_CHARACTERS;
          else if (value.length > 300)
            msg = Common.NGO.PAGE_ERRORS.ERROR_300_CHARACTERS;
          else msg = '';
        } else {
          msg = Common.NGO.PAGE_ERRORS.CAMPAIGN_NAME_REQUIRED;
        }
        break;
      case 'projectSummary':
        isFieldValid = this.Validations.required(value);
        // msg = isFieldValid
        //   ? this.Validations.richTextSize(value)
        //     ? Common.NGO.PAGE_ERRORS.PROJECT_OBJECTIVE_SUMMARY_LENGTH
        //     : Common.NGO.PAGE_ERRORS.PROJECT_OBJECTIVE_SUMMARY
        //   : '';

        msg = isFieldValid
          ? this.Validations.richTextSize(value)
            ? Common.NGO.PAGE_ERRORS.PROJECT_OBJECTIVE_SUMMARY_LENGTH
            : ''
          : Common.NGO.PAGE_ERRORS.PROJECT_OBJECTIVE_SUMMARY;
        break;
      case 'projectGoal':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : Common.NGO.PAGE_ERRORS.PROJECT_GOAL;
        break;
      case 'projectObjective':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value.length > 3000)
            msg = Common.NGO.PAGE_ERRORS.ERROR_3000_CHARACTERS;
          else msg = '';
        } else {
          msg = Common.NGO.PAGE_ERRORS.PROJECT_OBJECTIVE_REQUIRED;
        }
        break;
      case 'primaryCauseArea':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value.length > 5) {
            msg = Common.NGO.PAGE_ERRORS.ERROR_MORE_THAN_5_AREAS;
          }
        } else {
          msg = Common.NGO.PAGE_ERRORS.PRIMARY_CAUSE_AREA_REQUIRED;
        }
        break;
      case 'secondaryCauseArea':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value.length > 5) {
            msg = Common.NGO.PAGE_ERRORS.ERROR_MORE_THAN_5_AREAS;
          }
        } else {
          msg = Common.NGO.PAGE_ERRORS.SECONDARY_CAUSE_AREA_REQUIRED;
        }
        break;
      // case 'campaignType':
      //   isFieldValid = this.Validations.required(value);
      //   msg = isFieldValid ? '' : Common.NGO.PAGE_ERRORS.CAMPAIGN_TYPE_REQUIRED;
      //   break;
      case 'currency':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : Common.NGO.PAGE_ERRORS.CAMPAIGN_TYPE_REQUIRED;
        break;
      case 'projectPeriod':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value > 60 || value < 1) {
            msg = Common.NGO.PAGE_ERRORS.PROJECT_PERIOD;
          }
        } else {
          msg = Common.NGO.PAGE_ERRORS.PROJECT_PERIOD_REQUIRED;
        }
        break;
      case 'beneficiariesImpacted':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value.length > 5) {
            msg = Common.NGO.PAGE_ERRORS.ERROR_MORE_THAN_5_BENEFICIARIES;
          }
        } else {
          msg = Common.NGO.PAGE_ERRORS.BENEFICIARY_REQUIRED;
        }
        break;
      case 'sdg':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value.length > 5) {
            msg = Common.NGO.PAGE_ERRORS.ERROR_MORE_THAN_5_SDG;
          }
        } else {
          msg = Common.NGO.PAGE_ERRORS.SDG_REQUIRED;
        }
        break;
      case 'beneficiaries':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value <= 0) {
            isFieldValid = false;
            msg = Common.NGO.PAGE_ERRORS.NUM_BENEFICIARIES_CORRECT;
          }
          if (value > 100000000) {
            isFieldValid = false;
            msg = Common.NGO.PAGE_ERRORS.MAX_VALUE_REACHED;
          }
        } else {
          msg = Common.NGO.PAGE_ERRORS.NUM_BENEFICIARIES_REQUIRED;
        }
        break;
      case 'beneficiaryCost':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value.length > 5) {
            if (value <= 0 || value > 1000000) {
              isFieldValid = false;
              msg = Common.NGO.PAGE_ERRORS.ERROR_COST_PER_BENEFICIARY;
            }
          }
        }
        break;
      case 'problemStatement':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value.length > 1500)
            msg = Common.NGO.PAGE_ERRORS.ERROR_3000_CHARACTERS;
          else msg = '';
        } else {
          msg = Common.NGO.PAGE_ERRORS.PROBLEM_STATEMENT_REQUIRED;
        }
        break;
      case 'natureOfSolution':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid
          ? this.Validations.richTextSize(value)
            ? Common.NGO.PAGE_ERRORS
                .PROJECT_MODELS_OR_WAYS_TO_ADDRESS_THE_PROBLEM
            : ''
          : Common.NGO.PAGE_ERRORS
              .PROJECT_MODELS_OR_WAYS_TO_ADDRESS_THE_PROBLEM;
        // msg = isFieldValid
        //   ? ''
        //   : Common.NGO.PAGE_ERRORS
        //       .PROJECT_MODELS_OR_WAYS_TO_ADDRESS_THE_PROBLEM;
        break;
      // case 'methodology':
      //   isFieldValid = this.Validations.required(value);
      //   msg = isFieldValid
      //     ? ''
      //     : Common.NGO.PAGE_ERRORS.PROJECT_OBJECTIVE_SUMMARY;
      //   break;
      // case 'measurableOutcome':
      //   isFieldValid = this.Validations.required(value);
      //   msg = isFieldValid
      //     ? ''
      //     : Common.NGO.PAGE_ERRORS.PROJECT_OBJECTIVE_SUMMARY;
      //   break;
      case 'fundAmount':
        if (
          this.state.formFields.campaignType.value ===
            Common.NGO.CAMPAIGN_TYPE_COMMUNITY_FUNDING_ID ||
          this.state.formFields.campaignType.value ===
            Common.NGO.CAMPAIGN_TYPE_DIRECT_FUNDING_ID
        ) {
          isFieldValid = this.Validations.required(value);
          if (isFieldValid) {
            if (value <= 0 || value > 9999999999) {
              isFieldValid = false;
              msg = Common.NGO.PAGE_ERRORS.FUND_AMOUNT_CORRECT;
            }
          } else {
            msg = Common.NGO.PAGE_ERRORS.FUND_AMOUNT_REQUIRED;
          }
        }
        break;
      case 'rolloverImage':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please upload a rollover image';
        break;
      case 'isNewProject':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid || value === false ? '' : 'Please select an option';
        break;
      // case 'proposalDoc':
      //   isFieldValid = this.Validations.required(value);
      //   msg = isFieldValid ? '' : 'Please upload a proposal document';
      //   break;
      // case 'projectAddress':
      //   isFieldValid = this.Validations.required(value);
      //   if (isFieldValid) {
      //     if (value.length > 3000)
      //       msg = Common.NGO.PAGE_ERRORS.ERROR_3000_CHARACTERS;
      //     else msg = '';
      //   } else {
      //     msg = Common.NGO.PAGE_ERRORS.PROJECT_ADDRESS_REQUIRED;
      //   }
      //   break;
      case 'projectAddress':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid
          ? ''
          : Common.NGO.PAGE_ERRORS.PROJECT_ADDRESS_REQUIRED;
        break;
      case 'projectCountry':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid
          ? ''
          : Common.NGO.PAGE_ERRORS.PROJECT_COUNTRY_REQUIRED;
        break;
      case 'projectState':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : Common.NGO.PAGE_ERRORS.PROJECT_STATE_REQUIRED;
        break;
      case 'pinCode':
        isFieldValid = this.Validations.required(value);
        if (isFieldValid) {
          if (value.toString().length !== 6) {
            msg = Common.NGO.PAGE_ERRORS.INVALID_PIN_CODE;
          } else {
            msg = '';
          }
        } else {
          msg = Common.NGO.PAGE_ERRORS.PIN_CODE_REQUIRED;
        }
        break;
      case 'projectDistrict':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid
          ? ''
          : Common.NGO.PAGE_ERRORS.PROJECT_DISTRICT_REQUIRED;
        break;
      case 'tnc_accepted':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please accept terms & conditions';
        break;
      case 'framework':
        isFieldValid = this.Validations.required(value);
        msg = isFieldValid ? '' : 'Please select framework';
        break;
      default:
    }
    return { isFieldValid, msg };
  };

  getCostValue = (benefValue, fundAmtVal) => {
    let fundVal = 0;
    let benefVal = 0;
    if (benefValue !== '' && fundAmtVal !== '') {
      let fundStrVal = fundAmtVal?.toString();
      let benefStrVal = benefValue?.toString();

      if (benefStrVal?.includes(',') || fundStrVal?.includes(',')) {
        benefVal = benefStrVal?.replace(/,/g, '');
        fundVal = fundStrVal?.replace(/,/g, '');
      } else {
        benefVal = benefValue;
        fundVal = fundAmtVal;
      }

      // let fundVal = parseInt(fundAmtVal.replace(/[,]/g, ''));
      // let benefVal = parseInt(benefValue.replace(/[,]/g, ''));

      let costVal = parseInt(fundVal) / parseInt(benefVal);
      return costVal.toFixed(2);
    } else {
      return 0;
    }
  };
  restrictToNumber = value => {
    value = value.replace(/\D/g, '');
    if (value) {
      value = Number(value);
    } else {
      value = 0;
    }
    return value;
  };
  eventToValue(e, trimResults, toNumber) {
    let { target = {} } = e;
    let { value = '' } = target;
    if (toNumber && value !== '') {
      value = Number(value);
    } else {
      value = value || '';
      if (value && trimResults) {
        value = value.trim();
      }
    }
    return value;
  }
  getDependentFieldVisibleStatus = (value, event) => {
    let campTypeVolunteer,
      campTypeDirect,
      campTypeCommunity = false;
    if ((event && event.type === 'change') || !event) {
      switch (value) {
        case Common.NGO.CAMPAIGN_TYPE_VOLUNTEER_ID:
          $('.select2-container--default').css({ width: '393px' });
          campTypeVolunteer = true;
          campTypeCommunity = false;
          campTypeDirect = false;
          break;
        case Common.NGO.CAMPAIGN_TYPE_COMMUNITY_FUNDING_ID:
          campTypeVolunteer = false;
          campTypeCommunity = true;
          campTypeDirect = false;
          break;
        case 3: // direct campaign type
        default:
          campTypeVolunteer = false;
          campTypeCommunity = false;
          campTypeDirect = true;
          break;
      }
    }
    return { campTypeVolunteer, campTypeCommunity, campTypeDirect };
  };
  setFormErrorsAndValue = (event, callback, type, childCallBack) => {
    const { target } = event;
    const { id } = target;
    let { value = '' } = target;
    if (event.target.maxLength !== undefined) {
      value = value.slice(0, event.target.maxLength);
    }
    if (!type) {
      value = this.eventToValue(event, false);
    }
    switch (type) {
      case 'num':
        value = this.restrictToNumber(value);
        break;
      case 'boolean':
        value = event.target.checked;
        break;
      default:
    }
    let visibleStatus =
      id === 'campaignType' &&
      this.getDependentFieldVisibleStatus(value, event);
    let formFields = {};
    const errorResult = this.validate(id, value);
    formFields[id] = {
      isValid: errorResult.isFieldValid,
      error: errorResult.msg,
      value,
      jsonField: this.state.formFields[id].jsonField
    };

    formFields = defaults({}, formFields, this.state.formFields);
    if (id === 'campaignType') {
      this.setState(
        {
          ...this.state,
          formFields,
          campTypeVolunteer: visibleStatus.campTypeVolunteer,
          campTypeCommunity: visibleStatus.campTypeCommunity,
          campTypeDirect: visibleStatus.campTypeDirect
        },
        () => {
          if (typeof callback === 'function') {
            callback();
          }
          if (typeof childCallBack === 'function') {
            childCallBack();
          }
        }
      );
    } else {
      this.setState({ ...this.state, formFields }, () => {
        if (typeof callback === 'function') {
          callback();
        }
        if (typeof childCallBack === 'function') {
          childCallBack();
        }
      });
    }
  };
  setRTEValueAndError = (id, value, callback) => {
    if (this.state.formFields[id].value !== value) {
      let formFields = {};
      const errorResult = this.validate(id, value);
      formFields[id] = {
        isValid: errorResult.isFieldValid,
        error: errorResult.msg,
        value,
        jsonField: this.state.formFields[id].jsonField
      };
      formFields = defaults({}, formFields, this.state.formFields);
      this.setState({ ...this.state, formFields }, () => {
        if (typeof callback === 'function') {
          callback();
        }
      });
      this.enableSaveButton();
    }
  };
  setLocationValueAndError = (index, event) => {
    const id = event.target.id;
    const value = event.target.value;
    if (
      this.state.formFields.projectLocation.value[index][id].value !== value
    ) {
      let formFieldsObj = this.state.formFields;
      const errorResult = this.validate(id, value);
      formFieldsObj.projectLocation.value[index][id] = {
        isValid: errorResult.isFieldValid,
        error: errorResult.msg,
        value,
        jsonField: this.state.formFields.projectLocation.value[index][id]
          .jsonField
      };
      // formFields = defaults({}, formFields, this.state.formFields);
      this.setState({ ...this.state, formFieldsObj });
      this.enableSaveButton();
    }
  };
  setLocationValue = (index, event, callback, type, childCallback) => {
    const { target } = event;
    const name = target.name;
    const _this = this;

    const value =
      target.value && target.name !== 'projectAddress'
        ? parseInt(target.value)
        : target.value;
    let stateFinalVal = '';
    let cityFinalVal = '';
    let cityVal = '';

    // function displayLocations(pincode, locationUpdated) {
    var request = new XMLHttpRequest();
    var method = 'GET';
    var url =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      value +
      '&sensor=true&key=AIzaSyDxc9HLGnlXL0JWiftdZaVEv5quIAmTsyo';
    var async = true;

    request.open(method, url, async);
    request.onreadystatechange = function() {
      if (request.readyState === 4 && request.status === 200) {
        var data = JSON.parse(request.responseText);
        // var address = data.results[0];

        var address = data.results[0]?.formatted_address?.split(',');
        var address_components = data.results[0]?.address_components;

        if (data.results.length > 0) {
          if (address.length < 3) {
            cityVal = address_components[1]?.long_name;
            stateFinalVal = address[address.length - 1]?.replace(/[0-9]/g, '');
            cityFinalVal = cityVal?.replace(/[0-9]/g, '');

            document.getElementsByName('projectDistrict')[
              index
            ].value = cityVal;
          } else {
            cityVal = address[address.length - 3].split(' ');
            stateFinalVal = address[address.length - 2]?.replace(/[0-9]/g, '');
            cityFinalVal = cityVal[cityVal.length - 1]?.replace(/[0-9]/g, '');
            // document.getElementById('projectDistrict').value = cityVal[
            //   cityVal.length - 1
            // ].replace(/[0-9]/g, '');
            document.getElementsByName('projectDistrict')[
              index
            ].value = cityVal[cityVal.length - 1].replace(/[0-9]/g, '');
          }
          document.getElementsByName('projectState')[index].value = address[
            address.length - 2
          ]?.replace(/[0-9]/g, '');
          // locationUpdated(stateFinalVal, cityFinalVal);
          document.getElementsByName('projectState')[
            index
          ].value = stateFinalVal;
        }
      } else {
        document.getElementsByName('projectState')[index].value = '--';
        document.getElementsByName('projectDistrict')[index].value = '--';
        // locationUpdated(null, null);
      }

      _this.setLocationArrValue(
        stateFinalVal,
        cityFinalVal,
        name,
        target,
        value,
        index
      );
    };
    request.send();
    // }

    this.enableSaveButton();
  };
  handleProjectLocationChange = (index, field, value) => {
    const updatedProjectLocation = { ...this.state.formFields.projectLocation };
    const updatedLocation = { ...updatedProjectLocation.value[index] };

    updatedLocation[field].value = value;

    updatedProjectLocation.value[index] = updatedLocation;

    this.setState({
      ...this.state.formFields,
      projectLocation: updatedProjectLocation
    });
  };

  setLocationArrValue = (state, city, name, target, value, index) => {
    let locationsArray = this.state.formFields.projectLocation.value;
    const errorResult = this.validate(target.id, value);
    locationsArray[index][name].isValid = errorResult.isFieldValid;
    locationsArray[index][name].error = errorResult.msg;
    locationsArray[index][name].value = value;
    locationsArray[index].projectState.value = state;
    locationsArray[index].projectDistrict.value = city;
    this.setState({
      ...this.state.formFields,
      projectLocation: locationsArray
    });
  };

  setOrClearStateOrDistrictFetch = (type, op, value, index) => {
    if (op === 'set') {
      this.fetchingDistrict = {
        inProgress: true,
        value: value,
        itemIndex: index
      };
    } else {
      this.fetchingDistrict = {
        inProgress: false,
        value: null,
        itemIndex: null
      };
    }
  };
  setFormValidStatus = () => {
    const self = this;
    const formInvalidFields = map(self.state.formFields, value => {
      return value.isValid === false;
    });
    self.setState({
      formValid: formInvalidFields.some(value => {
        return value === true;
      })
        ? false
        : true
    });
  };
  onHandleBlur = (event, callback, type) => {
    this.setFormErrorsAndValue(event, callback, type);
  };
  clearAllFormFields() {}

  onUploadSuceess = (fileUrl, event) => {
    this.setState(
      {
        formFields: {
          ...this.state.formFields,
          [event.target.id]: {
            isValid: true,
            error: '',
            value: fileUrl,
            jsonField: this.state.formFields[event.target.id].jsonField
          }
        }
      },
      () => {
        this.setFormValidStatus();
      }
    );
    this.enableSaveButton();
  };
  onHandleChange = (event, callback, type, childCallback, newValue) => {
    this.setFormErrorsAndValue(event, callback, type, childCallback, newValue);
    this.enableSaveButton();
  };
  currenciesList = () => {
    this.props.getMasterListCurrencies();
  };
  fillSecondaryCauseList = () => {
    this.props.getMasterDataById(this.state.formFields.primaryCauseArea.value);
  };
  fillStatesList = countryId => {
    this.props.getStatesDataById(
      countryId ? countryId : this.state.formFields.projectCountry.value
    );
  };
  fillDistrictsList = stateId => {
    this.props.getDistrictsDataById(
      stateId ? stateId : this.state.formFields.projectState.value
    );
  };

  handleOptionChange = (selectedOption, eleId) => {
    let allSelectedOptions = [];
    forEach(selectedOption, obj => {
      allSelectedOptions.push(obj.id);
    });
    const event = {
      target: { id: eleId, value: [].concat(allSelectedOptions) }
    };
    this.onHandleChange &&
      this.onHandleChange(
        event,
        this.setFormValidStatus ? this.setFormValidStatus : () => {},
        ''
      );
  };
  setMultiSelectOptions = (/*selector, options*/) => {
    return null;
  };
  updateLocationData = locArr => {
    if (locArr && locArr.length > 0) {
      let locationsArr = [];
      locArr.map(loc => {
        const obj = {
          id: loc.id,
          location_id: loc.id,
          projectAddress: {
            isValid: false,
            value: loc.address,
            error: '',
            jsonField: 'address'
          },
          projectCountry: {
            isValid: false,
            value: 101,
            error: '',
            jsonField: 'country'
          },
          pinCode: {
            isValid: false,
            value: loc.pincode,
            error: '',
            jsonField: 'pincode'
          },
          projectState: {
            isValid: false,
            value: loc.state,
            error: '',
            jsonField: 'state'
          },
          projectDistrict: {
            isValid: false,
            value: loc.city,
            error: '',
            jsonField: 'city'
          }
        };
        locationsArr.push(obj);
        return false;
      });
      let campaignFormDetails = this.state.formFields;
      campaignFormDetails.projectLocation.value = locationsArr;
      this.setState({
        formFields: campaignFormDetails
      });
    }
  };
  setCorp = list => {
    let corpIdList = [];
    list.forEach(element => corpIdList.push(element.id));
    this.setState({ pubSelected: [...corpIdList] });
    this.enableSaveButton();
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      l1_status: currentL1Status,
      l2_status: currentL2Status
    } = this.props.profileCompleted;

    const {
      l1_status: prevL1Status,
      l2_status: prevL2Status
    } = prevProps.profileCompleted;

    const isStatusApproved =
      currentL1Status === 'l1_approved' || currentL2Status === 'l2_approved';

    if (currentL1Status !== prevL1Status || currentL2Status !== prevL2Status) {
      this.comgetNGOCorpForPublished(this.state.ngoId, null, isStatusApproved);

      if (isStatusApproved) {
        this.setState({
          publishedFor: [
            {
              name: 'Everyone',
              id: 'everyone',
              is_condtional: false
            }
          ]
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops?.isCreateCampaign === true) {
      const {
        l1_status: currentL1Status,
        l2_status: currentL2Status
      } = this.props.profileCompleted;

      const {
        l1_status: nxtL1Status,
        l2_status: nxtL2Status
      } = nextprops.profileCompleted;
      const isStatusApproved =
        nxtL1Status === 'l1_approved' || nxtL2Status === 'l2_approved';

      if (!nextprops.profileCompleted.is_conditionally_approved) {
        this.setState({
          publishedFor: this.state.corpPublishedList
          // publishedFor: [
          //   {
          //     name: 'Everyone',
          //     id: 'everyone',
          //     is_condtional: false
          //   }
          // ]
        });
      }
      if (currentL1Status !== nxtL1Status || currentL2Status !== nxtL2Status) {
        if (isStatusApproved) {
          this.setState({
            pubSelected: ['everyone']
          });
        }
      }
    }
    if (
      nextprops.editSuccessResponse &&
      nextprops.editSuccessResponse.code !== null &&
      !this.editDetailRecieved &&
      this.props.isModify
    ) {
      let campaignInfo = nextprops.editSuccessResponse;
      let campaignFormDetails = this.state.formFields;

      forEach(campaignFormDetails, obj => {
        if (obj.jsonField !== 'project_location') {
          obj.value =
            campaignInfo[obj.jsonField] !== null
              ? campaignInfo[obj.jsonField]
              : '';
          obj.isValid = obj.value !== null ? true : false;
        }
      });

      if (campaignInfo.locations && campaignInfo.locations.length > 0) {
        let locationsArr = [];
        campaignInfo.locations.map(loc => {
          const obj = {
            id: loc.id,
            projectAddress: {
              isValid: false,
              value: loc.address,
              error: '',
              jsonField: 'address'
            },
            projectCountry: {
              isValid: false,
              value: loc.country,
              error: '',
              jsonField: 'country'
            },
            pinCode: {
              isValid: false,
              value: loc.pincode,
              error: '',
              jsonField: 'pincode'
            },
            projectState: {
              isValid: false,
              value: loc.state,
              error: '',
              jsonField: 'state'
            },
            projectDistrict: {
              isValid: false,
              value: loc.city,
              error: '',
              jsonField: 'city'
            }
          };
          locationsArr.push(obj);
          return false;
        });
        // if (locationsArr.length > 0) this.getStateDistrictList(locationsArr);
        campaignFormDetails.projectLocation.value = locationsArr;
      }
      this.editDetailRecieved = true;
      this.setState(
        {
          ...this.state,
          formFields: campaignFormDetails,
          campaignId: campaignInfo.campaign_id,
          fundAmount: campaignInfo.fund_amount,
          currentCampaignStatus: campaignInfo['status'],
          campaignDetails: campaignInfo
        },
        () => {
          this.fillSecondaryCauseList();
          this.currenciesList();
          let visibleStatus = this.getDependentFieldVisibleStatus(
            this.state.formFields.campaignType.value
          );
          this.setState({
            campTypeVolunteer: visibleStatus.campTypeVolunteer,
            campTypeCommunity: visibleStatus.campTypeCommunity,
            campTypeDirect: visibleStatus.campTypeDirect
          });
        }
      );
      if (campaignInfo.is_public) {
        this.setState({
          publishedFor: [
            {
              name: 'Everyone',
              id: 'everyone',
              is_condtional: false
            }
          ],
          pubSelected: ['everyone']
        });
      } else {
        let corpList = campaignInfo.corp_list;
        this.setCorp(corpList);
      }
    } else if (
      !this.editDetailRecieved &&
      nextprops.editErrorResponse &&
      nextprops.editErrorResponse.code !== null
    ) {
      this.editDetailRecieved = true;
      this.setState({ showLoader: false }, () => {
        toast.dismiss();
      });
    }
    if (
      this.state.saveRequestMade &&
      nextprops.createModifySuccessResponse &&
      nextprops.createModifySuccessResponse.code === 200 &&
      this.state.showPreview === false
    ) {
      const campaignUnderReviewStatus = Common.NGO.CAMPAIGN_STATUS.UNDER_REVIEW;
      const campaignDraftStatus = Common.NGO.CAMPAIGN_STATUS.DRAFT;
      const campaignApprovedStatus = Common.NGO.CAMPAIGN_STATUS.APPROVED;
      const campaignRejectedStatus = Common.NGO.CAMPAIGN_STATUS.REJECTED;
      const campaignNewStatus = Common.NGO.CAMPAIGN_STATUS.NEW;

      this.setState(
        {
          campaignId: nextprops.editSuccessResponse.campaign_id,
          deletedLocationArr: []
        },
        () => {
          if (this.state.currentCampaignStatus === campaignUnderReviewStatus) {
            sendMail({
              // templateName: 'submitCampaign'
              templateName: 'submitCampaignNewDesign',
              sendTo: this.props.userProfile.email
                ? this.props.userProfile.email
                : window.location.href?.split('email=')[1],
              subject: 'Project Submitted Successfully',
              templateData: {
                campaignName: this.state.formFields.campaignName.value,
                firstName: this.props.userProfile.given_name,
                lastName: this.props.userProfile.family_name
              }
            });
            // this.showSuccessModal();
          }

          if (this.state.submitCampaign === false) {
            toast.success(
              () => (
                <div>
                  <div>
                    Project{' '}
                    {this.state.currentCampaignStatus === '' ? 'saved' : ''}
                    {(this.state.currentCampaignStatus ===
                      campaignUnderReviewStatus &&
                      'created & ') ||
                      (this.state.currentCampaignStatus ===
                        campaignDraftStatus &&
                        'created & ')}
                    {this.state.currentCampaignStatus === campaignDraftStatus &&
                      'saved'}
                    {this.state.currentCampaignStatus === campaignNewStatus &&
                      'saved'}
                    {this.state.currentCampaignStatus ===
                      'WAITING FOR FUNDING' && 'submitted'}
                    {this.state.currentCampaignStatus ===
                      campaignApprovedStatus && 'approved'}
                    {this.state.currentCampaignStatus ===
                      campaignRejectedStatus && 'rejected'}{' '}
                    successfully.
                  </div>
                  {[
                    campaignUnderReviewStatus,
                    campaignApprovedStatus,
                    campaignRejectedStatus,
                    campaignDraftStatus
                  ].indexOf(this.state.currentCampaignStatus) > -1 && (
                    <NavLink
                      key={'nav-list-page'}
                      exact
                      to={`${
                        this.props.userProfile.user_type === 1
                          ? Routes.NGO.ADMIN_CAMPAIGN_LIST
                          : Routes.NGO.CAMPAIGN_LIST
                      }`}
                      className="white">
                      <span style={{ color: '#fff' }}>
                        Click here to go to Project List
                      </span>
                    </NavLink>
                  )}
                </div>
              ),
              { id: 'saveSuccess' },
              { autoClose: 15000 },

              setTimeout(() => {
                // this.showSuccessModal();
                this.setState({
                  tabName: 'ProposedBudget',
                  proposedBudget: true,
                  activity: false,
                  projectIndicator: false,
                  showCampaignDetails: false
                });
              }, 1000)
            );
          }
        }
      );

      setTimeout(() => {
        if (this.state.currentCampaignStatus === campaignUnderReviewStatus) {
          if (window.location.href.includes('email')) {
            // let email = window.location.href.split('email=')[1];
            let email = ngoEmail;
            this.props.history.push(`/ngo/project/list?email=${email}`);
          } else this.props.history.push(`/ngo/project/list`);
        }
        if (
          this.state.currentCampaignStatus !== campaignUnderReviewStatus &&
          !window.location.pathname.includes('/ngo/project/edit')
        ) {
          if (window.location.href.includes('email')) {
            // let email = window.location.href.split('email=')[1];
            let email = ngoEmail;
            this.props.history.push(
              `/ngo/project/edit/${nextprops.createModifySuccessResponse.campaignId}?email=${email}`
            );
          } else {
            this.enableSaveButton();
            this.props.history.push(
              `/ngo/project/edit/${nextprops.createModifySuccessResponse.campaignId}`
            );
            window.location.reload();
          }
        }
      }, 3000);
    } else {
      if (nextprops?.createModifyErrorResponse?.code === '400') {
        toast.error(nextprops?.createModifyErrorResponse?.error);
        this.setState({
          tabName: 'ConceptNoteDetails',
          proposedBudget: false,
          activity: false,
          projectIndicator: false,
          showCampaignDetails: true
        });
      }
      if (
        nextprops.createModifyErrorResponse &&
        nextprops.createModifyErrorResponse.isAxiosError
      ) {
        toast.dismiss();
        // toast.error('Some error occurred, please try again in sometime');
        toast.error('Some error occurred, please try again in sometime', {
          id: 'saveError'
        });
        this.enableSaveButton();
      }
    }
    if (this.fetchingDistrict.inProgress) {
      let districtsLists = this.state.districtsLists;
      if (
        districtsLists.district.length >=
        this.fetchingDistrict.itemIndex + 1
      ) {
        districtsLists.district[this.fetchingDistrict.itemIndex] =
          nextprops.listDistricts;
      } else {
        districtsLists.district.push(nextprops.listDistricts);
      }
      this.setState({
        districtsLists: districtsLists
      });
      this.setOrClearStateOrDistrictFetch(
        'projectDistrict',
        'clear',
        null,
        null
      );
    }
  }
  onCancel = () => {
    this.props.history.push('/ngo/project/list');
  };

  componentDidMount() {
    if (adminName !== undefined) {
      this.setState({ praposalModificationStatus: false });
    }
    const {
      l1_status: currentL1Status,
      l2_status: currentL2Status
    } = this.props.profileCompleted;
    const isStatusApproved =
      currentL1Status === 'l1_approved' || currentL2Status === 'l2_approved';

    if (isStatusApproved) {
      this.setState({
        publishedFor: [
          {
            name: 'Everyone',
            id: 'everyone',
            is_condtional: false
          }
        ],
        pubSelected: ['everyone']
      });
    }

    this.enableSaveButton();

    this.setState({ praposalModificationStatus: false });
    if (window.location.href?.includes('email=')) {
      campaignIdValue = window.location.href
        .split('/')
        .pop()
        .split('?')[0];
    } else {
      campaignIdValue = window.location.href.split('/').pop();
    }

    if (campaignIdValue !== 'create' && campaignIdValue) {
      this.getAllExpenseSubCategory();
      this.getCampaignProposalStatus(campaignIdValue);
      this.getCampaignProposalModificationStatus(campaignIdValue);
    }
    this.props.getHeaderData().then(data => {
      const {
        name,
        l1_status,
        l2_status,
        l0_status,
        is_conditional
      } = data.result;

      this.setState({
        ngoName: name,
        isConditionalState: is_conditional,
        isL0State: l0_status,
        isL1State: l1_status,
        isL2State: l2_status
      });
      const isStatusApprovedNew =
        l1_status === 'l1_approved' || l2_status === 'l2_approved';
      if (this.state.ngoId) {
        this.comgetNGOCorpForPublished(
          this.state.ngoId,
          null,
          isStatusApproved || isStatusApprovedNew
        );
      } else if (ngoEmail !== '' && ngoEmail !== undefined) {
        this.comgetNGOCorpForPublished(
          null,
          ngoEmail,
          isStatusApproved || isStatusApprovedNew
        );
      }
    });
    const masterDataOptions = Common.NGO.MASTER_OPTIONS;
    let campaignId = '';
    this.props.getMasterData([
      masterDataOptions.PRIMARY_BENEFICIARIES,
      masterDataOptions.CAUSE_CATEGORY,
      masterDataOptions.CAMPAIGN_TYPE,
      masterDataOptions.VOLUNTEER_SKILLS,
      masterDataOptions.SDG
    ]);
    this.props.getMasterListCountries();
    // this.props.getMasterListCurrencies();
    // this.fillStatesList(101);
    if (
      this.props.isCreateCampaign &&
      this.props.profileCompleted.isProfileComplete === 'l1_approved'
    ) {
      let instance = HttpRequest.getInstance('HIROKU');
      instance
        .post(EndPoint.getApiUrl('NGO', 'CAMPAIGN_CREATE', 'HIROKU'), {
          ngo_id: this.state.ngoId,
          status: Common.NGO.CAMPAIGN_STATUS.NEW
        })
        .then(resp => {
          if (resp.data.status === 400) {
            return;
          }
          campaignId = resp.data.campaignId;
          window.localStorage.setItem('campaignId', campaignId);
          this.setState({
            campaignId: campaignId,
            currentCampaignStatus: 'NEW'
          });
        })
        .catch(() => {
          /* toast.error('this error'); */
        });
    }
    if (
      window.localStorage.getItem('campaignId') !== null &&
      window.localStorage.getItem('campaignId') !== 'undefined' &&
      !this.props.isModify
    ) {
      this.setState({
        campaignId: window.localStorage.getItem('campaignId'),
        currentCampaignStatus: 'NEW'
      });
    }
    if (this.props.isModify) {
      this.enablePreviewButton();
      this.disableSaveButton();
      this.setState(() => {
        return { isFormValid: this.setFormValidStatus() };
      });
    }
  }
  getAllExpenseSubCategory() {
    this.props
      .getAllExpenseSubCategory()
      .then(res => {
        this.setState({ allExpenseSubCategoryList: res.result });
      })
      .catch(async err => {});
  }
  onPreview = () => {
    this.setState({ showPreview: true });
  };
  onClosePreview = () => {
    this.setState({ showPreview: false });
    this.setFetchPreview(false);
  };
  enablePreviewButton = () => {
    this.setState({
      disable_preview_button: false
    });
  };
  setFetchPreview = value => {
    this.fetchingPreview = value;
  };

  enableSaveButton = () => {
    let formFields = this.state.formFields;
    let checkedVal = document.getElementById('tnc_accepted')?.checked;
    let pubSelected = this.state.pubSelected.length;
    let addrList = document.getElementsByName('projectAddress');
    let pincodeList = document.getElementsByName('pinCode');
    let isAddrEmpty = false;
    let isPincodeEmpty = false;

    if (addrList?.length > 0) {
      for (let i = 0; i < addrList?.length; i++) {
        if (addrList[i]?.value?.trim() === '') {
          isAddrEmpty = true;
        }
      }
    }
    if (pincodeList?.length > 0) {
      for (let i = 0; i < pincodeList?.length; i++) {
        if (pincodeList[i]?.value === '' || pincodeList[i]?.value === null) {
          isPincodeEmpty = true;
        }
      }
    }

    if (
      document.getElementById('campaignName')?.value?.trim() === '' ||
      document.getElementById('projectGoal')?.value?.trim() === '' ||
      document.getElementById('projectObjective')?.value?.trim() === '' ||
      formFields.projectSummary?.value?.trim() === '' ||
      document.getElementById('projectPeriod')?.value === '' ||
      formFields.primaryCauseArea?.value === '' ||
      formFields.primaryCauseArea.value?.length === 0 ||
      formFields.secondaryCauseArea?.value === '' ||
      formFields.secondaryCauseArea.value?.length === 0 ||
      formFields.beneficiaries?.value === '' ||
      formFields.beneficiariesImpacted?.value === '' ||
      formFields.beneficiariesImpacted.value?.length === 0 ||
      formFields.sdg?.value === '' ||
      formFields.sdg.value?.length === 0 ||
      formFields.sdg.value === undefined ||
      formFields.sdg.value === null ||
      document.getElementById('fundAmount')?.value.trim() === '' ||
      document.getElementById('beneficiaries')?.value.trim() === '' ||
      formFields.rolloverImage?.value?.trim() === '' ||
      document.getElementById('problemStatement')?.value?.trim() === '' ||
      formFields.natureOfSolution?.value?.trim() === '' ||
      formFields.isNewProject?.value === '' ||
      checkedVal === false ||
      isPincodeEmpty === true ||
      isAddrEmpty === true ||
      pubSelected === 0
      // isLocationError
    ) {
      this.setState({
        disable_campaign_content_save_button: true
      });
    } else {
      this.setState({
        disable_campaign_content_save_button: false
      });
    }
  };

  disableSaveButton = () => {
    this.setState({
      disable_campaign_content_save_button: true
    });
  };
  checkValidation = () => {
    const { formFields } = this.state;
    let errorExists = false;
    const isPublishedSelected = this.state.pubSelected.length === 0;
    if (isPublishedSelected) {
      errorExists = true;
      this.validate('publishedFor', this.state.pubSelected);
    }
    for (let field in formFields) {
      if (field !== 'projectLocation') {
        const errorResult = this.validate(field, formFields[field].value);
        if (errorResult.msg !== '' && !errorExists) {
          errorExists = true;
        }
        formFields[field] = {
          isValid: errorResult.isFieldValid,
          error: errorResult.msg,
          value: formFields[field].value,
          jsonField: formFields[field].jsonField
        };
      } else {
        const { projectLocation } = formFields;
        let locErrExists = false;
        projectLocation.value.map((locObject, index) => {
          for (let locField in locObject) {
            if (locField !== 'id') {
              const locErrorResult = this.validate(
                locField,
                locObject[locField]?.value
              );
              if (locErrorResult.msg !== '' && !locErrExists) {
                locErrExists = true;
              }
              if (locField !== 'is_deleted')
                locObject[locField] = {
                  isValid: locErrorResult.isFieldValid,
                  error: locErrorResult.msg,
                  value: locObject[locField].value,
                  jsonField: locObject[locField].jsonField
                };
            }
          }
          return false;
        });
        if (locErrExists) {
          errorExists = true;
        }

        formFields['projectLocation'] = projectLocation;
        return errorExists;
      }
    }
    this.setState({
      ...this.state,
      formFields: formFields
    });
    return errorExists;
  };
  onSubmit = status => {
    this.setState({ submitCampaign: true });
    // const errorExists = this.checkValidation();
    const { formFields } = this.state;
    if (this.documentFileName === '') {
      return toast.error('Document upload is mandatory');
    }
    if (formFields.framework.value === undefined) {
      formFields.framework.value = 'BASIC';
      // formFields.currency.value = "1";
    }
    let isLocationError = false;
    // let campaignInfo = this.state.campaignDetails
    formFields.projectLocation.value.map(location => {
      if (
        location.projectAddress.value === '' ||
        location.projectCountry.value === '' ||
        location.projectState.value === '' ||
        location.projectDistrict.value === '' ||
        location.pinCode.value === ''
      ) {
        if (!isLocationError) isLocationError = true;
      }
      return false;
    });
    this.setState({
      currentCampaignStatus: this.state.currentCampaignStatus,
      saveRequestMade: true
    });
    if (
      [
        Common.NGO.CAMPAIGN_STATUS.APPROVED,
        Common.NGO.CAMPAIGN_STATUS.REJECTED
      ].indexOf(status) > -1
    ) {
      this.props.createOrModifyCampaign(
        { campaignId: this.state.campaignId },
        this.state.currentCampaignStatus === Common.NGO.CAMPAIGN_STATUS.DRAFT
          ? Common.NGO.CAMPAIGN_STATUS.UNDER_REVIEW
          : this.state.currentCampaignStatus === ''
          ? Common.NGO.CAMPAIGN_STATUS.DRAFT
          : this.state.currentCampaignStatus
      );
    } else {
      this.props
        .createOrModifyCampaign(
          this.getJson(
            status === Common.NGO.CAMPAIGN_STATUS.DRAFT
              ? Common.NGO.CAMPAIGN_STATUS.UNDER_REVIEW
              : status
          )
        )
        .then(() => {
          if (
            this.state.currentCampaignStatus === 'UNDER_REVIEW' ||
            this.state.currentCampaignStatus === 'DRAFT'
          ) {
            sendMail({
              // templateName: 'submitCampaign'
              templateName: 'submitCampaignNewDesign',
              sendTo: this.props.userProfile.email
                ? this.props.userProfile.email
                : ngoEmail,
              // : window.location.href.split('email=')[1],
              subject: 'Project Submitted Successfully',
              templateData: {
                campaignName: this.state.formFields.campaignName.value,
                firstName: this.props.userProfile.given_name,
                lastName: this.props.userProfile.family_name
              }
            });
            sendMail({
              // templateName: 'submitCampaignAlert',
              templateName: 'submitCampaignAlertNewDesign',
              sendTo: 'ngo.partnerships@mettasocial.com',
              subject: 'New Project Submitted',
              templateData: {
                campaignName: this.state.formFields.campaignName.value,
                ngoName: this.state.ngoName
              }
            });
            this.setState({ showPreview: false });
          }
          if (this.state.isCorpInterested) {
            sendMail({
              templateName: 'projectActivityModificationCorp',
              sendTo: this.state.corpEmail,
              subject: 'Project Alterations Deployed',
              templateData: {
                campaignName: this.state.formFields.campaignName.value
              }
            });
            sendMail({
              templateName: 'projectProposalModificationAlertAdmin',
              sendTo: 'ngo.partnerships@mettasocial.com',
              subject: 'Project Alterations Deployed',
              templateData: {
                campaignName: this.state.formFields.campaignName.value,
                corpName: this.state.corpName
              }
            });
          }
          this.disableSaveButton();
          // this.setState({ submissionModal: true });
        });
    }
    // }
    this.setState({
      campaignId: this.state.campaignId
    });

    // this.showSuccessModal();
    this.setState({ submissionModal: true });
  };

  componentWillUnmount() {
    this.props.resetCampaignFormData();
    window.localStorage.removeItem('campaignId');
  }

  onSave = status => {
    if (this.state.praposalModificationStatus) {
      const {
        fund_amount,
        no_of_beneficiaries
      } = this.state.praposalModificationObject;
      const { formFields } = this.state;

      const { beneficiaries, fundAmount } = formFields;

      const benVal = beneficiaries.value
        ? typeof beneficiaries.value == 'number'
          ? beneficiaries.value
          : beneficiaries?.value?.replace(/\,/g, '')
        : 0;

      const benNum = no_of_beneficiaries ? no_of_beneficiaries : benVal;

      const funVal = fundAmount.value
        ? typeof fundAmount.value == 'number'
          ? fundAmount.value
          : fundAmount?.value?.replace(/\,/g, '')
        : 0;
      const funNum = fund_amount ? fund_amount : funVal;

      if (
        parseInt(benVal) !== parseInt(benNum) ||
        parseInt(funVal) !== parseInt(funNum)
      ) {
        return toast.error(
          'The requested fund amount and the number of beneficiaries do not match the corporate request.'
        );
      } else {
        if (window.location.href?.includes('email=')) {
          var campaignIdValue = window.location.href
            .split('/')
            .pop()
            .split('?')[0];
        } else {
          var campaignIdValue = window.location.href.split('/').pop();
        }
        // const campaignIdValue = window.location.href.split('/').pop();
        this.acceptCampaignProposalModification(
          campaignIdValue,
          this.state.praposalModificationObject
        );
        this.setState({
          tabName: 'ProposeBudget',
          proposedBudget: true,
          activity: false,
          projectIndicator: false,
          showCampaignDetails: false,
          saveCampaignModal: false
        });
      }
      this.setState({
        disable_campaign_content_save_button: true
      });
      return;
    }

    //if (this.state.i) this.setState({ submitCampaign: false });
    const errorExists = this.checkValidation();
    const { formFields } = this.state;
    const { isModify } = this.props;
    if (this.documentFileName === '') {
      return toast.error('Document upload is mandatory');
    }
    const trimmed_campaign_name = this.state.formFields.campaignName.value.trim();
    let isLocationError = false;
    formFields.projectLocation.value.map(location => {
      if (
        location.projectAddress.value === '' ||
        location.projectCountry.value === '' ||
        location.projectState.value === '' ||
        location.projectDistrict.value === '' ||
        location.pinCode.value === ''
      ) {
        if (!isLocationError) isLocationError = true;
      }
      return false;
    });
    if (
      trimmed_campaign_name === '' ||
      formFields.projectGoal.value === '' ||
      formFields.projectObjective.value === '' ||
      formFields.projectSummary.value === '' ||
      formFields.projectPeriod.value === '' ||
      formFields.primaryCauseArea.value === '' ||
      formFields.secondaryCauseArea.value === '' ||
      formFields.campaignType.value === '' ||
      // formFields.currency.value === '' ||
      formFields.beneficiaries.value === '' ||
      !formFields.beneficiariesImpacted.value ||
      formFields.beneficiariesImpacted.value === '' ||
      !formFields.sdg.value ||
      formFields.sdg.value === '' ||
      formFields.rolloverImage.value === '' ||
      formFields.problemStatement.value === '' ||
      formFields.natureOfSolution.value === '' ||
      formFields.isNewProject.value === '' ||
      formFields.framework.value === '' ||
      // formFields.methodology.value === '' ||
      // formFields.measurableOutcome.value === '' ||
      // formFields.proposalDoc.value === '' ||
      formFields.fundAmount.value === '' ||
      // formFields.projectAddress.value === '' ||
      formFields.tnc_accepted.value === false ||
      isLocationError
    ) {
      return toast.error(`Please fill the ${this.getEmptyFieldName()}`);
    }
    if (errorExists) {
      toast.error(`Error(s) found! Correct the error(s) and try saving again.`);
      return false;
    }
    // if (
    //   getNumber(formFields.fundAmount.value) <= 0 ||
    //   getNumber(formFields.fundAmount.value) >
    // ) {
    //   return toast.error('Please enter fund amount between 0 and 99,999,99999,99999');
    // }
    if (isModify && status === 'DRAFT') {
      // this.enablePreviewButton();
      const trimmed_campaign_name = this.state.formFields.campaignName.value.trim();
      this.setFormValidStatus();
      if (
        trimmed_campaign_name !== '' &&
        this.state.formFields.campaignType.value !== 0
      ) {
        this.setState({
          currentCampaignStatus: status,
          saveRequestMade: true
        });

        if (
          [
            Common.NGO.CAMPAIGN_STATUS.APPROVED,
            Common.NGO.CAMPAIGN_STATUS.REJECTED
          ].indexOf(status) > -1
        ) {
          this.props.createOrModifyCampaign(
            { campaignId: this.state.campaignId },
            status
          );
          this.disableSaveButton();
        } else {
          this.props
            .createOrModifyCampaign(this.getJson(status))
            .then(res => {
              if (res && res.data.code === '200') {
                this.updateLocationData(res.data?.result.locations);
                toast.success('Project details successfully updated');
                if (!window.location.href.includes('edit')) {
                  var email = '';
                  if (window.location.href.includes('email')) {
                    email = ngoEmail;
                    this.props.history.push(
                      '/ngo/project/edit/' +
                        res.result[0].campaign_id +
                        '?email=' +
                        email
                    );
                  } else {
                    this.props.history.push(
                      '/ngo/project/edit/' + res.result[0].campaign_id
                    );
                    this.setState({ isCampaignSaved: true });
                  }
                }

                this.setState({
                  tabName: 'ProposeBudget',
                  proposedBudget: true,
                  activity: false,
                  projectIndicator: false,
                  showCampaignDetails: false,
                  saveCampaignModal: false
                });
              }
            })
            .catch(error => {});
          this.disableSaveButton();
        }
      } else {
        toast.error(
          'Please input below fields to continue:\n\n' +
            ['Project Name', 'Project Type'].join(',\n')
        );
      }
    } else {
      if (
        !this.state.formFields.campaignName.value ||
        !this.state.formFields.campaignType.value
      ) {
        return toast.error('Please Enter the Project Name and Project Type');
      } else {
        this.setState({
          // currentCampaignStatus: status,
          saveRequestMade: true,
          disable_campaign_type: true
        });
        if (
          [
            Common.NGO.CAMPAIGN_STATUS.APPROVED,
            Common.NGO.CAMPAIGN_STATUS.REJECTED
          ].indexOf(status) > -1
        ) {
          this.props.createOrModifyCampaign(
            { campaignId: this.state.campaignId },
            status
          );
          this.disableSaveButton();
        } else {
          this.props.createOrModifyCampaign(this.getJson(status)).then(res => {
            if (res && res.data.code === '200') {
              if (status === 'DRAFT') {
                if (window.location.href.includes('email')) {
                  // let email = window.location.href.split('email=')[1];
                  let email = ngoEmail;
                  this.props.history.push(
                    '/ngo/project/edit/' +
                      res.data.result[0].campaign_id +
                      '?email=' +
                      email
                  );
                } else {
                  this.props.history.push(
                    '/ngo/project/edit/' + res.data.result[0].campaign_id ||
                      res.data.result.campaignId
                  );
                  this.setState({ isCampaignSaved: true });
                  this.setState({
                    tabName: 'ProposeBudget',
                    proposedBudget: true,
                    activity: false,
                    projectIndicator: false,
                    showCampaignDetails: false
                  });
                }
              }
              if (
                this.state.currentCampaignStatus === 'UNDER_REVIEW' ||
                this.state.currentCampaignStatus === 'DRAFT'
              ) {
                sendMail({
                  // templateName: 'submitCampaign'
                  templateName: 'submitCampaignNewDesign',
                  sendTo: this.props.userProfile.email
                    ? this.props.userProfile.email
                    : ngoEmail,
                  // : window.location.href.split('email=')[1],
                  subject: 'Project Submitted Successfully',
                  templateData: {
                    campaignName: this.state.formFields.campaignName.value,
                    firstName: this.props.userProfile.given_name,
                    lastName: this.props.userProfile.family_name
                  }
                });
                sendMail({
                  // templateName: 'submitCampaignAlert',
                  templateName: 'submitCampaignAlertNewDesign',
                  sendTo: 'ngo.partnerships@mettasocial.com',
                  subject: 'New Project Submitted',
                  templateData: {
                    campaignName: this.state.formFields.campaignName.value,
                    ngoName: this.state.ngoName
                  }
                });
              }
            }
            if (
              this.state.currentCampaignStatus === 'NEW' &&
              res.status === 200 &&
              res.data.code === '200'
            ) {
              if (window.location.href.includes('email')) {
                // let email = window.location.href.split('email=')[1];
                let email = ngoEmail;

                this.props.history.push(
                  '/ngo/project/edit/' +
                    res.result[0].campaign_id +
                    '?email=' +
                    email
                );
              } else {
                this.props.history.push(
                  '/ngo/project/edit/' + res.data.result[0].campaign_id
                );
                this.setState({ isCampaignSaved: true });
              }
            }
            this.disableSaveButton();
          });
        }
        this.setState({
          // tabName: 'CampaignDetails',
          tabName: 'ProposeBudget',
          proposedBudget: true,
          activity: false,
          projectIndicator: false,
          showCampaignDetails: false
        });
        // this.setState({ submissionModal: true });
        window.localStorage.removeItem('campaignId');
      }
    }
    // this.enablePreviewButton();
  };
  getFormattedValue = value => {
    let formattedValue;
    switch (true) {
      case value && Array.isArray(value):
        formattedValue = value.toLocaleString();
        break;
      case value && Object.prototype.toString.call(value) === '[object Date]':
        formattedValue = dayjs('value').format('YYYY-MM-DD');
        break;
      case value:
      default:
        formattedValue = value;
    }
    return formattedValue;
  };
  getJson = status => {
    let dataToSave = {};
    let campaignFormDetails = {};
    forEach(this.state.formFields, obj => {
      if (
        obj.jsonField !== 'cause_area' &&
        obj.jsonField !== 'sub_cause_area' &&
        obj.jsonField !== 'target_beneficiary' &&
        obj.jsonField !== 'sdg'
      ) {
        let formattedValue = this.getFormattedValue(obj.value);
        if (formattedValue) {
          campaignFormDetails[obj.jsonField] = formattedValue;
        }
      } else {
        campaignFormDetails[obj.jsonField] =
          obj.jsonField !== 'target_beneficiary'
            ? obj.value.map(Number)
            : obj.value;
      }
    });
    if (
      this.state.formFields.campaignType.value ===
      Common.NGO.CAMPAIGN_TYPE_COMMUNITY_FUNDING_ID
    ) {
      dataToSave = pick(
        campaignFormDetails,
        [].concat(this.getCommonFields(), this.getCommunityFundFields())
      );
    } else if (
      this.state.formFields.campaignType.value ===
      Common.NGO.CAMPAIGN_TYPE_VOLUNTEER_ID
    ) {
      dataToSave = pick(
        campaignFormDetails,
        [].concat(this.getCommonFields(), this.getVolunteerFields())
      );
    } else if (
      this.state.formFields.campaignType.value ===
      Common.NGO.CAMPAIGN_TYPE_DIRECT_FUNDING_ID
    ) {
      dataToSave = pick(
        campaignFormDetails,
        [].concat(this.getCommonFields(), this.getDirectFundFields())
      );
    } else {
      dataToSave = pick(campaignFormDetails, this.getCommonFields());
    }
    dataToSave['status'] = status;
    dataToSave['framework'] = 'BASIC';
    dataToSave['sdg'] = campaignFormDetails.sdg;
    dataToSave['ngo_id'] = this.state.ngoId;
    if (this.state.campaignId && this.state.campaignId !== 'undefined') {
      dataToSave['campaign_id'] = this.state.campaignId;
    }
    dataToSave['project_location'] = this.getLocation();
    if (typeof dataToSave.no_of_beneficiaries === 'string')
      dataToSave.no_of_beneficiaries = getNumber(
        dataToSave.no_of_beneficiaries
      );
    if (typeof dataToSave.fund_amount === 'string') {
      dataToSave.fund_amount = getNumber(dataToSave.fund_amount);
    }
    if (typeof dataToSave.project_period === 'string')
      dataToSave.project_period = parseInt(dataToSave.project_period);
    if (typeof dataToSave.beneficiary_cost === 'string')
      dataToSave.beneficiary_cost = getNumber(dataToSave.beneficiary_cost);
    if (
      dataToSave.is_new_project &&
      typeof dataToSave.is_new_project === 'string'
    )
      dataToSave.is_new_project =
        dataToSave.is_new_project === 'true' ? true : false;

    if (this.state.pubSelected.length) {
      if (
        this.state.pubSelected.length === 1 &&
        this.state.pubSelected[0] === 'everyone'
      ) {
        dataToSave['corp_list'] = [];
        dataToSave['is_public'] = true;
      } else {
        dataToSave['corp_list'] = this.state.pubSelected;
        dataToSave['is_public'] = false;
      }
    }

    return dataToSave;
  };
  getLocation = () => {
    let addressArray = [];
    if (this.state.formFields.projectLocation.value.length > 0) {
      this.state.formFields.projectLocation.value.map(location => {
        if (
          location.projectCountry.value !== '' &&
          location.projectState.value !== '' &&
          location.projectDistrict.value !== ''
        ) {
          const obj = {
            address: location.projectAddress.value,
            country: location.projectCountry.value,
            state: location.projectState.value,
            city: location.projectDistrict.value,
            pincode: parseInt(location.pinCode.value)
          };
          if (location.id) {
            obj['location_id'] = parseInt(location.id);
          }
          addressArray.push(obj);
        }
        return false;
      });
    }
    if (this.state.deletedLocationArr.length > 0) {
      this.state.deletedLocationArr.map(location => {
        if (
          location.projectAddress.value !== '' &&
          location.projectCountry.value !== '' &&
          location.projectState.value !== '' &&
          location.projectDistrict.value !== ''
        ) {
          const obj = {
            address: location.projectAddress.value,
            country: location.projectCountry.value,
            state: location.projectState.value,
            city: location.projectDistrict.value,
            pincode: parseInt(location.pinCode.value),
            location_id: parseInt(location.id),
            is_deleted: true
          };

          addressArray.push(obj);
        }
        return false;
      });
    }

    return addressArray;
  };
  getCommunityFundFields = () => {
    return ['fund_amount', 'campaign_needs'];
  };
  getDirectFundFields = () => {
    return ['fund_amount', 'campaign_needs'];
  };
  getVolunteerFields = () => {
    return [
      'volunteer_count',
      'volunteer_skills',
      'hours_per_day',
      'selection_criteria',
      'address'
    ];
  };
  getCommonFields = () => {
    return [
      'title',
      'project_summary',
      'project_goal',
      'project_objective',
      'project_period',
      'cause_area',
      'is_new_project',
      'sub_cause_area',
      'campaign_type_id',
      'target_beneficiary',
      'no_of_beneficiaries',
      'image_url',
      'problem_statement',
      'nature_of_solution',
      'beneficiary_cost',
      'survey_document',
      // 'methodology',
      // 'measurable_outcome',
      // 'monitoring_and_reporting',
      'project_sustainability',
      'budget',
      'proposal_doc',
      'needs_assessment',
      // 'project_address',
      'framework',
      'tnc_accepted'
    ];
  };
  getEmptyFieldName = () => {
    const { formFields } = this.state;
    const trimmed_campaign_name = this.state.formFields.campaignName.value.trim();
    if (trimmed_campaign_name === '') return 'Project Title';
    if (formFields.projectGoal.value === '') return 'Project Goal';
    if (formFields.projectObjective.value === '') return 'Project Objective';
    if (formFields.projectSummary.value === '') return 'Project Synopsis';
    if (formFields.projectPeriod.value === '') return 'Project Period';
    if (formFields.primaryCauseArea.value === '') return 'Primary Cause Area';
    if (formFields.secondaryCauseArea.value === '')
      return 'Secondary Cause Area';
    if (formFields.campaignType.value === '') return 'Project Type';
    if (
      !formFields.beneficiariesImpacted.value ||
      formFields.beneficiariesImpacted.value === ''
    )
      return 'Target Beneficiaries';
    if (formFields.sdg.value === '')
      return 'Sustainable Development Goals (SDGs)';
    if (formFields.beneficiaries.value === '') return 'Number of Beneficiaries';
    if (formFields.rolloverImage.value === '') return 'Rollover image';
    if (formFields.problemStatement.value === '') return 'Problem Statement';
    if (formFields.natureOfSolution.value === '')
      return 'Models or ways to address the problem';
    if (formFields.isNewProject.value === '')
      return 'Project Implementation/Execution';
    // if (formFields.methodology.value === '') return 'Methodology';
    // if (formFields.measurableOutcome.value === '') return 'Measurable Outcome';
    // if (formFields.proposalDoc.value === '') return 'Project Proposal document';
    if (formFields.fundAmount.value === '')
      return 'Requested Funding Amount (INR)';
    // if (formFields.projectAddress.value === '') return 'Project Address';
    if (formFields.tnc_accepted.value === false) return 'T&C Checkbox';
    let isLocationError = false;
    formFields.projectLocation.value.map(location => {
      if (
        location.projectAddress.value === '' ||
        location.projectCountry.value === '' ||
        location.projectState.value === '' ||
        location.projectDistrict.value === '' ||
        location.pinCode.value === ''
      ) {
        if (!isLocationError) isLocationError = true;
      }
      return false;
    });
    if (isLocationError) return 'Project Location';
    return false;
  };
  getStateDistrictList = locationArr => {
    let fetchingStateList = false;
    this.tempDistrictListStorage = [];
    locationArr.map((location, index) => {
      if (
        !fetchingStateList &&
        location.projectCountry.value &&
        location.projectCountry.value !== ''
      ) {
        fetchingStateList = true;
        this.fillStatesList(location.projectCountry.value);
      }
      if (
        location.projectDistrict.value &&
        location.projectDistrict.value !== ''
      ) {
        this.props
          .getDistrictsDataById(location.projectState.value)
          .then(resp => {
            this.tempDistrictListStorage.push({
              id: location.projectState.value,
              list: resp
            });
            if (this.tempDistrictListStorage.length === locationArr.length) {
              let districtListsArr = [];
              locationArr.map((loc, index) => {
                let listIndex = this.tempDistrictListStorage.findIndex(
                  x => x.id === loc.projectState.value
                );
                if (listIndex > -1) {
                  districtListsArr.push(
                    this.tempDistrictListStorage[listIndex].list
                  );
                }
                return false;
              });
              this.setState({
                districtsLists: { district: districtListsArr }
              });
            }
          });
      }
      return false;
    });
  };
  addLocation = () => {
    let formFields = this.state.formFields;
    let districtsLists = this.state.districtsLists;
    const obj = {
      id: null,
      projectAddress: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'address'
      },
      projectCountry: {
        isValid: false,
        value: 101,
        error: '',
        jsonField: 'country'
      },
      pinCode: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'pincode'
      },
      projectState: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'state'
      },
      projectDistrict: {
        isValid: false,
        value: '',
        error: '',
        jsonField: 'city'
      }
    };
    formFields.projectLocation.value.push(obj);
    districtsLists.district.push([]);
    this.setState({
      formFields: formFields,
      districtsLists: districtsLists
    });
    this.setState({
      disable_campaign_content_save_button: true
    });
  };
  deleteLocation = index => {
    let locationsArray = this.state.formFields.projectLocation.value;
    let districtsLists = this.state.districtsLists;
    let delLocArr = this.state.deletedLocationArr;
    if (districtsLists.district.length === locationsArray.length)
      districtsLists.district.splice(index, 1);
    // locationsArray[index].is_deleted = 'true';
    if (locationsArray[index].id) delLocArr.push(locationsArray[index]);
    locationsArray.splice(index, 1);
    this.setState({
      projectLocation: locationsArray,
      districtsLists: districtsLists,
      deletedLocationArr: delLocArr
    });

    // this.enableSaveButton();
    this.setState({
      disable_campaign_content_save_button: false
    });
    setTimeout(() => {
      this.enableSaveButton();
    }, 2000);
  };
  createMainHtml = () => {
    const primaryCauseArea = this.props.masterInfo
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.CAUSE_CATEGORY]
      : null;
    const secondaryCauseArea = this.props.masterSecondaryInfo
      ? this.props.masterSecondaryInfo[
          Common.NGO.MASTER_OPTIONS.SUB_CAUSE_CATEGORY
        ]
      : null;
    const beneficiariesImpacted = this.props.masterInfo
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.PRIMARY_BENEFICIARIES]
      : null;
    /* const campaignType = this.props.masterInfo   ** link to master data
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.CAMPAIGN_TYPE]
      : null; */
    const campaignType = [
      { id: 2, name: 'Community Funding' },
      { id: 3, name: 'Direct Funding' }
    ];
    const currency = this.props.masterInfo
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.CAMPAIGN_TYPE]
      : null;
    const sdg = this.props.masterInfo
      ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.SDG]
      : null;
    // const volunteersSkills = this.props.masterInfo
    //   ? this.props.masterInfo[Common.NGO.MASTER_OPTIONS.VOLUNTEER_SKILLS]
    //   : null;
    // const countryList = this.props.listCountries;
    const countryList = [{ id: 101, name: 'India' }];
    // const stateList = this.props.listStates;
    // const districtList = this.props.listDistricts;
    if (this.props.masterInfo) {
      if (!this.editDetailRequested && this.props.isModify) {
        this.editDetailRequested = true;
        this.props.getCampaign(this.props.campaignId, 'edit');
      }
    }

    if (adminName !== undefined) {
      isCampaignUnderReview = false;
      isCampaignWaitingForFunds = false;
    } else {
      isCampaignUnderReview =
        this.state.currentCampaignStatus ===
        Common.NGO.CAMPAIGN_STATUS.UNDER_REVIEW;
      isCampaignWaitingForFunds =
        this.state.currentCampaignStatus ===
        Common.NGO.CAMPAIGN_STATUS.WAITING_FOR_FUNDING;
    }

    const handleCloseModal = e => {
      if (!ngoEmail) {
        e.preventDefault();
        this.props.history.push('/ngo/profile');
      }
    };
    const showToastOnTabClick = () => {
      toast('Please fill the Concept Note to proceed ahead');
    };
    const showNext = (tab, campaignId) => {
      if (tab === 'ProposedBudget') {
        this.setState({
          tabName: tab,
          proposedBudget: true,
          activity: false,
          projectIndicator: false,
          showCampaignDetails: false
        });
        //if (campaignIdValue !== 'create') {
        this.getCampaignProposalStatus(campaignIdValue);
        //}
      } else if (tab === 'ActivitiesPlan') {
        this.setState({
          tabName: 'ActivitiesPlan',
          proposedBudget: false,
          activity: true,
          projectIndicator: false,
          showCampaignDetails: false
        });
        //if (campaignIdValue !== 'create') {
        this.getCampaignProposalStatus(campaignIdValue);
        //}
      } else if (tab === 'ProjectIndicators') {
        this.setState({
          tabName: 'ProjectIndicators',
          proposedBudget: false,
          activity: false,
          projectIndicator: true,
          showCampaignDetails: false
        });
        //if (campaignIdValue !== 'create') {
        this.getCampaignProposalStatus(campaignIdValue);
        //}
      } else if (tab === 'ConceptNoteDetails') {
        this.setState({
          tabName: 'ConceptNoteDetails',
          proposedBudget: false,
          activity: false,
          projectIndicator: false,
          showCampaignDetails: true
        });
        //if (campaignIdValue !== 'create') {
        this.getCampaignProposalStatus(campaignIdValue);
        this.enableSaveButton();
        //}
      }
      // document.getElementsByName(tab)[0].removeClass("project-bullet-inactive");
    };

    const closeSubmitModal = () => {
      this.setState({
        submissionModal: false
      });
      if (window.location.href.includes('email')) {
        // let email = window.location.href.split('email=')[1];
        let email = ngoEmail;
        window.location.href = `/ngo/project/list?email=${email}`;
      } else {
        window.location.href = `/ngo/project/list`;
      }
    };

    const closeModal = () => {
      // this.setState({
      //   submissionModal: false
      // });
      this.setState({
        saveCampaignModal: false,
        tabName: 'ProposeBudget',
        proposedBudget: true,
        activity: false,
        projectIndicator: false,
        showCampaignDetails: false
      });
      if (window.location.href.includes('email')) {
        let email = ngoEmail;
        window.location.href = `/ngo/project/list?email=${email}`;
      } else {
        window.location.href = `/ngo/project/list`;
      }
      // window.location.href = '/ngo/project/list';
    };

    const removeCommas = value => {
      if (typeof value === 'string') return value.replace(/,/g, '');
      return value;
    };

    const renderTabsStepper = () => {
      const {
        formFields,
        campaignId,
        currentCampaignStatus,
        proposalStatus,
        isCampaignSaved,
        proposedBudget,
        activity,
        projectIndicator
      } = this.state;

      return (
        <div className="col-12">
          <h4 className="d-flex align-items-center mb-5 pb-4">
            <span className="d-inline-block font-weight-bolder">
              {formFields.campaignName.value !== ''
                ? formFields.campaignName.value
                : '-'}
            </span>
          </h4>

          <div className="d-flex justify-content-center mb-5">
            <span
              name="ConceptNoteDetails"
              className={` ${
                currentCampaignStatus === 'DRAFT' ||
                currentCampaignStatus === 'UNDER_REVIEW' ||
                currentCampaignStatus === 'WAITING_FOR_FUNDING' ||
                currentCampaignStatus === 'FUNDED' ||
                currentCampaignStatus === 'REJECTED' ||
                currentCampaignStatus === 'ON_GOING_PROJECT'
                  ? 'project-top-border-active project-bullet-active'
                  : ''
              } 
          fs-16 font-weight-bold position-relative project-bullet project-tab-title 
           text-center project-top-border  px-4 cursor-pointer`}
              onClick={e => showNext('ConceptNoteDetails', campaignId)}>
              Concept Note
            </span>

            <span
              name="ProposedBudget"
              id="ProposedBudget"
              className={` ${
                currentCampaignStatus === 'DRAFT' && proposedBudget === true
                  ? 'project-top-border project-bullet'
                  : ''
              }  
            ${
              proposalStatus.is_project_finances === true ||
              proposalStatus.is_project_finances === 'True'
                ? 'project-top-border-active project-bullet-active'
                : ''
            }
            
            ${
              isCampaignSaved === true ||
              proposedBudget === true ||
              proposalStatus.is_project_finances === true ||
              proposalStatus.is_project_finances === 'True'
                ? ''
                : 'project-bullet-inactive'
            }
        fs-16 font-weight-bold position-relative project-bullet project-tab-title 
         text-center project-top-border  px-4 cursor-pointer`}
              onClick={() =>
                this.state.campaignId && currentCampaignStatus !== 'NEW'
                  ? showNext('ProposedBudget', campaignId)
                  : showToastOnTabClick()
              }>
              Proposed Budget{' '}
            </span>

            <span
              name="ActivitiesPlan"
              id="ActivitiesPlan"
              className={` ${
                proposalStatus.is_project_activities === true ||
                proposalStatus.is_project_activities === 'True'
                  ? 'project-top-border-active project-bullet-active'
                  : ''
              } 
            ${
              activity === true ||
              proposalStatus.is_project_activities === true ||
              proposalStatus.is_project_activities === 'True'
                ? ''
                : 'project-bullet-inactive'
            }
            fs-16 font-weight-bold position-relative project-bullet project-tab-title 
            text-center project-top-border  px-4 cursor-pointer`}
              onClick={() =>
                this.state.campaignId && currentCampaignStatus !== 'NEW'
                  ? showNext('ActivitiesPlan', campaignId)
                  : showToastOnTabClick()
              }>
              Activities Plan
            </span>

            <span
              id="ProjectIndicators"
              name="ProjectIndicators"
              className={` ${
                proposalStatus.is_project_indicators === true ||
                proposalStatus.is_project_indicators === 'True'
                  ? 'project-top-border-active project-bullet-active'
                  : ''
              } 
            ${
              projectIndicator === true ||
              proposalStatus.is_project_indicators === true ||
              proposalStatus.is_project_indicators === 'True'
                ? ''
                : 'project-bullet-inactive'
            }
          fs-16 font-weight-bold position-relative project-bullet project-tab-title 
           text-center  px-4 cursor-pointer`}
              onClick={() =>
                this.state.campaignId && currentCampaignStatus !== 'NEW'
                  ? showNext('ProjectIndicators', campaignId)
                  : showToastOnTabClick()
              }>
              Project Indicators
            </span>
          </div>
        </div>
      );
    };

    return (
      <>
        {/*********timeline start*****/}
        {renderTabsStepper()}
        {/*********timeline end*****/}
        {this.state.submissionModal && (
          <Modal
            show={this.state.submissionModal}
            title={'Project Submitted Successfully'}
            handleCloseModal={e => closeModal(e)}
            sizeClass="modal-hw">
            {/* <div className="fs-18 dm-bold text-left">
            Campaign Submitted Successfully
          </div> */}
            <p className="my-2 fs-16 dm-regular">
              Your Project{' '}
              <span className="dm-bold">
                {' '}
                {` "${this.state.formFields.campaignName.value}" `}
              </span>{' '}
              has been submitted successfully and will be reviewed by our team.
            </p>
            <div className="text-center mt-5 mb-5">
              <img src={thankyou} height={116} width={115} alt="ty" />
            </div>
            <div className="fs-16 dm-regular">
              You can create more Projects to create impact at scale
            </div>
            <div className="float-right">
              <span
                onClick={e => closeSubmitModal(e)}
                className="ml-4 p-4 dm-medium color-007bff cursor-pointer">
                Close
              </span>
            </div>
          </Modal>
        )}
        {this.state.saveCampaignModal && (
          <Modal
            show={true}
            title={''}
            handleCloseModal={e => closeModal(e)}
            sizeClass="modal-lg overflow-hidden">
            <div className="fs-18 dm-bold text-left">
              Project Saved Successfully
            </div>
            <p className="my-2 fs-16 dm-regular">
              {`Your Project "${this.state.formFields.campaignName.value}" has been saved successfully.`}
            </p>
            <div className="text-center mt-4 mb-4">
              <img src={thankyou} height={115} width={115} alt="ty" />
            </div>
            <div className="fs-16 dm-regular">
              {/* You can create more campaigns to reach create impact at scale */}
            </div>
            <div className="float-right">
              <span
                onClick={e => closeModal(e)}
                className="ml-4 p-4 dm-medium color-007bff cursor-pointer">
                Close
              </span>
            </div>
          </Modal>
        )}

        {this.state.tabName === 'ConceptNoteDetails' &&
          this.props.isCreateCampaign &&
          (this.props.profileCompleted?.is_conditionally_approved === false
            ? this.props.profileCompleted.isProfileComplete !== 'l1_approved' ||
              this.props.isNgoApproved === false
            : this.props.profileCompleted?.is_conditionally_approved ===
              false) &&
          this.props.userProfile.user_type === 2 && (
            <Modal
              show={true}
              title={'Create Project'}
              handleCloseModal={handleCloseModal}
              sizeClass="modal-hw">
              <p className="my-2 fs-18 font-weight-normal">
                You can create Project only after your profile is completed and
                approved. Please wait while we approve your profile if you have
                already submitted it or{' '}
                <button
                  className="btn-link btn btn-lg p-0 fs-18 font-weight-normal text-normal text-lowercase"
                  onClick={() => {
                    this.props.history.push('/ngo/profile');
                  }}>
                  Click Here To Complete Profile
                </button>
              </p>
            </Modal>
          )}

        {this.state.tabName === 'ConceptNoteDetails' &&
          this.state.showCampaignDetails && (
            <div className={`row mb-5`}>
              <div className="col-8 px-0 mx-auto mb-5">
                <div className={`col-12 px-0 bg-white`}>
                  <div className="row justify-content-between  px-5 py-4">
                    <h3 className="fs-16">Concept Note</h3>
                    {/* <button
                  type="button"
                  onClick={() => this.onSave(Common.NGO.CAMPAIGN_STATUS.DRAFT)}
                  disabled={this.state.disable_campaign_content_save_button}
                  className={`btn btn-primary btn-custom ${
                    isCampaignUnderReview ? 'd-none' : 'd-block'
                  }`}>
                  Save
                </button> */}
                  </div>
                  <div className="bg_EFF9FF ">
                    <div className="form-group required col-6 p-5">
                      <MultiSelect
                        labelText={'This Project is to be published for'}
                        required={true}
                        ngoStatus={
                          this.props.profileCompleted?.is_conditionally_approved
                        }
                        areaOptions={this.state.publishedFor}
                        selectedData={this.state.pubSelected}
                        fieldUpdated={data => {
                          this.setState({
                            pubSelected: data
                          });
                          if (data.length > 0) {
                            this.setState({
                              disable_campaign_content_save_button: false
                            });
                          } else {
                            this.setState({
                              disable_campaign_content_save_button: true
                            });
                          }
                        }}
                        permissions={this.props.permissions}
                      />
                    </div>
                  </div>
                  <div className="row mt-3 p-5">
                    <InputText
                      required={true}
                      labelText="Project Title"
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      maxLength={300}
                      type={'text'}
                      PlaceHolder={'Maximum Length is 300 Characters'}
                      id="campaignName"
                      name="campaignName"
                      fieldError={this.state.formFields.campaignName.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleBlur={this.onHandleBlur}
                      fieldValue={this.state.formFields.campaignName.value}
                      wrapperClassName={'col-12 pl-0 pr-0'}
                      permissions={this.props.permissions}
                    />
                    <InputTextArea
                      required={true}
                      labelText="Project Goal"
                      id="projectGoal"
                      name="projectGoal"
                      maxLength={3000}
                      PlaceHolder={'Maximum Length is 3000 Characters'}
                      fieldError={this.state.formFields.projectGoal.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleBlur={this.onHandleBlur}
                      fieldValue={this.state.formFields.projectGoal.value}
                      className="campaign_details campaign-textarea"
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      wrapperClassName="w-100"
                      permissions={this.props.permissions}
                    />

                    <InputTextArea
                      required={true}
                      labelText="Project Objective"
                      id="projectObjective"
                      name="projectObjective"
                      maxLength={2000}
                      PlaceHolder={'Maximum Length is 2000 Characters'}
                      fieldError={this.state.formFields.projectObjective.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleBlur={this.onHandleBlur}
                      fieldValue={this.state.formFields.projectObjective.value}
                      className="campaign_details campaign-textarea"
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      wrapperClassName="w-100"
                      permissions={this.props.permissions}
                    />
                    <InputRichTextEditor
                      required={true}
                      labelText="Project Synopsis"
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      maxLength={50000}
                      PlaceHolder={
                        'Maximum Length is 50,000 Characters (Including Style)'
                      }
                      id="projectSummary"
                      name="projectSummary"
                      fieldError={this.state.formFields.projectSummary.error}
                      onHandleChange={this.setRTEValueAndError}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleBlur={this.onHandleBlur}
                      fieldValue={this.state.formFields.projectSummary.value}
                      wrapperClassName={'col-12 pl-0 pr-0'}
                      docType="campaign_image"
                      refId={this.state.campaignId}
                      permissions={this.props.permissions}
                    />
                    <InputText
                      required={true}
                      labelText="Proposed Period of
                      the project in months"
                      type={'text'}
                      maxLength={2}
                      id="projectPeriod"
                      name="projectPeriod"
                      fieldError={this.state.formFields.projectPeriod.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleBlur={this.onHandleBlur}
                      fieldValue={this.state.formFields.projectPeriod.value}
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      PlaceHolder={'Value between 1 - 60'}
                      wrapperClassName={'col-12 pl-0 pr-0'}
                      permissions={this.props.permissions}
                    />
                    <InputMultiSelect
                      required={true}
                      labelText="Primary Cause Area"
                      id="primaryCauseArea"
                      name="primaryCauseArea"
                      options={primaryCauseArea}
                      fieldValue={this.state.formFields.primaryCauseArea.value}
                      fieldError={this.state.formFields.primaryCauseArea.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      parentElement={true}
                      childCallBack={this.fillSecondaryCauseList}
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      wrapperClassName={'col-6 pl-0'}
                      permissions={this.props.permissions}
                    />
                    <InputMultiSelect
                      className="secondary-cause"
                      required={true}
                      labelText="Secondary Cause Area"
                      id="secondaryCauseArea"
                      name="secondaryCauseArea"
                      options={secondaryCauseArea}
                      fieldValue={
                        this.state.formFields.secondaryCauseArea.value
                      }
                      fieldError={
                        this.state.formFields.secondaryCauseArea.error
                      }
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      disableInput={
                        !this.state.formFields.primaryCauseArea.value ||
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      wrapperClassName={'col-6 pr-0'}
                      permissions={this.props.permissions}
                    />
                    <InputSelect
                      required={false}
                      labelText="Project Type"
                      id="campaignType"
                      name="campaignType"
                      options={campaignType}
                      fieldValue={this.state.formFields.campaignType.value}
                      fieldError={this.state.formFields.campaignType.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      dataType="num"
                      disableInput={
                        this.props.isModify ||
                        this.state.disable_campaign_type ||
                        isCampaignWaitingForFunds ||
                        isCampaignUnderReview ||
                        this.state.praposalModificationStatus
                      }
                      wrapperClassName={'col-6 pl-0'}
                      extraInfoAboutField={
                        'This field is use to choose who is going to fund your Project.'
                      }
                      permissions={this.props.permissions}
                    />
                    <InputMultiSelect
                      required={true}
                      labelText="Target Beneficiaries"
                      id="beneficiariesImpacted"
                      name="beneficiariesImpacted"
                      options={beneficiariesImpacted}
                      fieldValue={
                        this.state.formFields.beneficiariesImpacted.value ===
                        'Please Select'
                          ? null
                          : this.state.formFields.beneficiariesImpacted.value
                      }
                      fieldError={
                        this.state.formFields.beneficiariesImpacted.error
                      }
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      wrapperClassName="col-6 pr-0"
                      permissions={this.props.permissions}
                    />
                    <InputMultiSelect
                      required={true}
                      labelText="Sustainable Development Goals (SDGs)"
                      id="sdg"
                      name="sdg"
                      options={sdg}
                      fieldValue={
                        this.state.formFields.sdg.value === 'Please Select'
                          ? null
                          : this.state.formFields.sdg.value
                      }
                      fieldError={this.state.formFields.sdg.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      wrapperClassName="col-6 pl-0"
                      permissions={this.props.permissions}
                    />

                    <InputSelectSplit
                      required={true}
                      maxLength={13}
                      labelText="Requested Funding Amount"
                      id="fundAmount"
                      name="fundAmount"
                      options={currency}
                      fieldError={this.state.formFields.fundAmount.error}
                      fieldValue={convertInCommaFormat(
                        this.state.formFields.fundAmount.value
                      )}
                      onHandleChange={e => this.onHandleChange(e)}
                      disableInput={
                        isCampaignUnderReview || isCampaignWaitingForFunds
                      }
                      wrapperClassName="col-6 pr-0"
                      isPraposalModification={
                        this.state.praposalModificationStatus &&
                        this.state.praposalModificationObject.fund_amount
                      }
                      praposalModificationObject={
                        this.state.praposalModificationObject
                      }
                      permissions={this.props.permissions}
                    />

                    <InputText
                      required={true}
                      labelText="Number of Beneficiaries that will be impacted"
                      type={'text'}
                      maxLength={11}
                      id="beneficiaries"
                      name="beneficiaries"
                      fieldError={this.state.formFields.beneficiaries.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleBlur={this.onHandleBlur}
                      fieldValue={convertInCommaFormat(
                        this.state.formFields.beneficiaries.value
                      )}
                      disableInput={
                        isCampaignUnderReview || isCampaignWaitingForFunds
                      }
                      PlaceHolder={'Maximum value 10,00,00,000'}
                      wrapperClassName={'col-6 pl-0 required-field'}
                      isPraposalModification={
                        this.state.praposalModificationStatus &&
                        this.state.praposalModificationObject
                          .no_of_beneficiaries
                      }
                      praposalModificationObject={
                        this.state.praposalModificationObject
                      }
                      permissions={this.props.permissions}
                    />

                    <InputSelectSplit
                      required={false}
                      labelText="Cost per beneficiary (Project budget divided by number of beneficiaries)"
                      type={'text'}
                      maxLength={9}
                      id="beneficiaryCost"
                      name="beneficiaryCost"
                      fieldError={this.state.formFields.beneficiaryCost.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleBlur={this.onHandleBlur}
                      // fieldValue={convertInCommaFormat(
                      //   this.state.formFields.beneficiaryCost.value
                      // )}
                      fieldValue={new Intl.NumberFormat('en-IN').format(
                        this.getCostValue(
                          this.state.formFields.beneficiaries.value,
                          this.state.formFields.fundAmount.value
                        )
                      )}
                      // disableInput={
                      //   isCampaignUnderReview || isCampaignWaitingForFunds
                      // }
                      // fieldValue={this.state.formFields.fundAmount.value / this.state.formFields.beneficiaries.value}
                      PlaceHolder={'Maximum value 10,00,000'}
                      wrapperClassName={'col-6 pr-0'}
                      disableInput={true}
                      permissions={this.props.permissions}
                    />

                    {this.state.formFields.projectLocation.value.map(
                      (location, index) => {
                        return (
                          <>
                            <span className="col-12 px-4 mt-3">
                              {index !== 0 && !isCampaignUnderReview && (
                                <span
                                  className=" float-right text-1FA4FA text-underline cursor-pointer"
                                  onClick={() => this.deleteLocation(index)}>
                                  <i className="fas fa-trash-alt text-007bff fs-16 fas-imp ml-4 tooltipss">
                                    <span className="tooltip-text tooltipss-dark">
                                      Delete Project Location
                                    </span>
                                  </i>
                                </span>
                              )}
                            </span>

                            {/* <InputTextArea
                            required={true}
                            labelText={`Project Address ${index + 1}`}
                            id={`projectAddress${index + 1}`}
                            name={`projectAddress`}
                            maxLength={3000}
                            PlaceHolder={'Maximum Length is 3000 Characters'}
                            fieldError={location.projectAddress.error}
                            onHandleChange={this.onHandleChange}
                            onChangeCallback={this.setFormValidStatus}
                            onHandleBlur={this.onHandleBlur}
                            fieldValue={location.projectAddress.value}
                            className="campaign_details campaign-textarea"
                            disableInput={isCampaignUnderReview}
                            wrapperClassName="w-100"
                          /> */}

                            {/* <InputTextArea
                            required={true}
                            labelText={`Project Address ${index + 1}`}
                            id={`ProjectAddress ${index + 1}`}
                            name="projectAddress"
                            PlaceHolder={'Maximum Length is 1500 Characters'}
                            maxLength={1500}
                            fieldError={location.projectAddress.error}
                            onHandleChange={this.onHandleChange}
                            onChangeCallback={this.setFormValidStatus}
                            onHandleBlur={this.onHandleBlur}
                            fieldValue={location.projectAddress.value}
                            className="campaign_objectives campaign-textarea"
                            disableInput={isCampaignUnderReview}
                            wrapperClassName="w-100"
                          /> */}

                            <InputTextArea
                              required={true}
                              labelText={`Project Address ${index + 1}`}
                              id="projectAddress"
                              name="projectAddress"
                              maxLength={255}
                              PlaceHolder={'Maximum Length is 255 Characters'}
                              fieldError={location.projectAddress.error}
                              onHandleChange={(e, cb, t, ccb, nv) =>
                                this.setLocationValueAndError(index, e)
                              }
                              onChangeCallback={this.setFormValidStatus}
                              // onHandleBlur={this.onHandleBlur}
                              fieldValue={location.projectAddress.value}
                              className="campaign_details campaign-textarea"
                              disableInput={
                                isCampaignUnderReview ||
                                isCampaignWaitingForFunds ||
                                this.state.praposalModificationStatus
                              }
                              wrapperClassName="w-100"
                              permissions={this.props.permissions}
                            />

                            <div className="col-12 p-0">
                              <label className="">Project Location</label>
                            </div>
                            <div
                              key={index + location.projectCountry.jsonField}
                              className="w-100 d-flex align-items-center ">
                              <InputSelect
                                required={true}
                                labelText="Project Country"
                                id="projectCountry"
                                name="projectCountry"
                                options={countryList}
                                fieldValue={location.projectCountry.value}
                                fieldError={location.projectCountry.error}
                                onHandleChange={(e, cb, t, ccb, nv) =>
                                  this.setLocationValue(
                                    index,
                                    e,
                                    cb,
                                    t,
                                    ccb,
                                    nv
                                  )
                                }
                                onChangeCallback={this.setFormValidStatus}
                                // parentElement={true}
                                // childCallBack={this.fillStatesList}
                                dataType="num"
                                disableInput={
                                  true || this.state.praposalModificationStatus
                                }
                                wrapperClassName={'w-20 pr-2'}
                                permissions={this.props.permissions}
                              />
                              <InputText
                                required={true}
                                type={'text'}
                                labelText="Pin Code"
                                id="pinCode"
                                name="pinCode"
                                fieldError={location.pinCode.error}
                                onHandleChange={(e, cb, t, ccb, nv) =>
                                  this.setLocationValue(
                                    index,
                                    e,
                                    cb,
                                    t,
                                    ccb,
                                    nv
                                  )
                                }
                                onChangeCallback={this.setFormValidStatus}
                                // onHandleBlur={this.onHandleBlur}
                                fieldValue={location.pinCode.value}
                                disableInput={
                                  isCampaignUnderReview ||
                                  isCampaignWaitingForFunds ||
                                  this.state.praposalModificationStatus
                                }
                                maxLength={6}
                                wrapperClassName={'w-20 px-2'}
                                permissions={this.props.permissions}
                              />

                              <InputText
                                required={true}
                                labelText="Project State"
                                type={'text'}
                                id="projectState"
                                name="projectState"
                                fieldValue={location.projectState.value}
                                fieldError={location.projectState.error}
                                wrapperClassName={'w-20 px-2'}
                                permissions={this.props.permissions}
                                disableInput={true}
                              />
                              <InputText
                                required={true}
                                labelText="Project City"
                                type={'text'}
                                id="projectDistrict"
                                name="projectDistrict"
                                fieldValue={location.projectDistrict.value}
                                fieldError={location.projectDistrict.error}
                                wrapperClassName={'w-20 px-2'}
                                permissions={this.props.permissions}
                                disableInput={true}
                              />

                              <span className="w-20 px-4 d-flex mt-3">
                                {isCampaignWaitingForFunds &&
                                this.state.formFields.projectLocation.value
                                  .length < 5 &&
                                this.state.formFields.projectLocation.value
                                  .length ===
                                  index + 1 &&
                                isAuthorized(this.props.permissions) ? (
                                  <span
                                    className="text-1FA4FA mr-3 text-underline cursor-pointer ml-4"
                                    // onClick={this.addLocation}
                                  >
                                    {/* <i
                                        className="material-icons  text-007bff tooltipss"
                                        style={{ marginTop: '0.5rem' }}>
                                        add_circle_outline
                                        <span className="tooltip-text tooltipss-dark">
                                          Add Project Location
                                        </span>
                                      </i> */}
                                  </span>
                                ) : (
                                  this.state.formFields.projectLocation.value
                                    .length < 5 &&
                                  this.state.formFields.projectLocation.value
                                    .length ===
                                    index + 1 && (
                                    <span
                                      className="text-1FA4FA mr-3 text-underline cursor-pointer ml-4"
                                      onClick={this.addLocation}>
                                      <i
                                        className="material-icons  text-007bff tooltipss"
                                        style={{ marginTop: '0.5rem' }}>
                                        add_circle_outline
                                        <span className="tooltip-text tooltipss-dark">
                                          Add Project Location
                                        </span>
                                      </i>
                                    </span>
                                  )
                                )}
                              </span>
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
              <div className="col-8 mx-auto mb-5 campaign-content">
                <div className={`col-12 p-4 bg-white`}>
                  <div className="row justify-content-between mb-4">
                    <h3 className="campaign-details-heading">
                      Concept Note Content
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-6 pl-0 mb-3">
                      <FileInput
                        required={true}
                        label="Rollover image"
                        placeholder="Resolution in between 600x400 and 4096x4096"
                        id="rolloverImage"
                        name="rolloverImage"
                        refId={this.state.campaignId}
                        fileSizeLimit={10}
                        validateFile={imageResolutionValidator}
                        value={this.state.formFields.rolloverImage.value || ''}
                        docType="campaign_image"
                        input={this.inputAttributes}
                        accept={'.jpg,.JPG,.bmp,.BMP,.png,.PNG'}
                        disabled={
                          isCampaignUnderReview ||
                          isCampaignWaitingForFunds ||
                          this.state.praposalModificationStatus
                        }
                        permissions={this.props.permissions}
                        // disableInput={
                        //   isCampaignUnderReview || isCampaignWaitingForFunds
                        // }
                        fieldError={this.state.formFields.rolloverImage.error}
                        onSuccess={this.onUploadSuceess}
                        extraInfoAboutField={
                          'Recommended high resolution, landscape oriented image (dimensions should be at least 600x400 and maximum 4096x4096) and 10MB max file size'
                        }
                      />
                      {this.state.formFields.rolloverImage.value && (
                        <div className="w-100 img-16x9 mb-4 rounded-m loading-bg">
                          <img
                            src={
                              getCloudinaryUrlForImage(
                                this.state.formFields.rolloverImage.value,
                                200
                              ) ||
                              getCloudinaryUrlForImage(
                                'https://content.mettasocial.com/content/defualt-image-campaign.jpg',
                                200
                              )
                            }
                            data-src={
                              this.state.formFields.rolloverImage.value ||
                              'https://content.mettasocial.com/content/defualt-image-campaign.jpg'
                            }
                            onError={e =>
                              (e.target.src = e.target.getAttribute('data-src'))
                            }
                            alt=""
                            className="abs-full rounded-m"
                          />
                        </div>
                      )}
                    </div>
                    <InputTextArea
                      required={true}
                      labelText="Problem Statement"
                      id="problemStatement"
                      name="problemStatement"
                      PlaceHolder={'Maximum Length is 1500 Characters'}
                      maxLength={1500}
                      fieldError={this.state.formFields.problemStatement.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleBlur={this.onHandleBlur}
                      fieldValue={this.state.formFields.problemStatement.value}
                      className="campaign_objectives campaign-textarea"
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      wrapperClassName="w-100"
                      permissions={this.props.permissions}
                    />
                    <InputRichTextEditor
                      required={true}
                      labelText="Models or ways to address the problem"
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      maxLength={50000}
                      PlaceHolder={'Maximum Length is 50,000 Characters'}
                      id="natureOfSolution"
                      name="natureOfSolution"
                      fieldError={this.state.formFields.natureOfSolution.error}
                      onHandleChange={this.setRTEValueAndError}
                      onChangeCallback={this.setFormValidStatus}
                      onHandleBlur={this.onHandleBlur}
                      fieldValue={this.state.formFields.natureOfSolution.value}
                      wrapperClassName={'col-12 pl-0 pr-0'}
                      docType="campaign_image"
                      refId={this.state.campaignId}
                      permissions={this.props.permissions}
                    />

                    <div className="mb-2 col-12 pl-0  pr-0 radio">
                      <label htmlFor="isNewProject" className="d-block">
                        Project Implementation/Execution{' '}
                        <span className="color-FF2300">*</span>
                      </label>

                      <InputRadio
                        type="radio"
                        id="isNewProject"
                        name="isNewProject"
                        label="This is a new project which is being executed for the first time"
                        value={true}
                        checked={
                          this.state.formFields.isNewProject.value === true ||
                          this.state.formFields.isNewProject.value === 'true'
                        }
                        required={false}
                        disableInput={
                          isCampaignUnderReview ||
                          isCampaignWaitingForFunds ||
                          this.state.praposalModificationStatus
                        }
                        onHandleChange={this.onHandleChange}
                        onChangeCallback={this.setFormValidStatus}
                        permissions={this.props.permissions}
                      />
                      <InputRadio
                        type="radio"
                        id="isNewProject"
                        name="isNewProject"
                        label="We have already implemented this project in the past"
                        value={false}
                        checked={
                          this.state.formFields.isNewProject.value === false ||
                          this.state.formFields.isNewProject.value === 'false'
                        }
                        required={false}
                        disableInput={
                          isCampaignUnderReview ||
                          isCampaignWaitingForFunds ||
                          this.state.praposalModificationStatus
                        }
                        onHandleChange={this.onHandleChange}
                        onChangeCallback={this.setFormValidStatus}
                        permissions={this.props.permissions}
                      />
                      <div
                        className={`${
                          this.state.formFields.isNewProject.error === ''
                            ? 'd-none'
                            : ''
                        }`}>
                        <small id="isNewProject" className="text-danger">
                          {this.state.formFields.isNewProject.error}
                        </small>
                      </div>
                    </div>

                    {/* <div className="col-6 pl-0 mb-3">
                  <FileInput
                    required={false}
                    label="Budget (Please attach the Project Budget)"
                    id="budget"
                    placeholder="Choose a pdf/word doc"
                    name="budget"
                    refId={this.state.campaignId}
                    fileSizeLimit={20}
                    value={this.state.formFields.budget.value || ''}
                    accept={
                      '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX'
                    }
                    previewDoc={true}
                    disabled={isCampaignUnderReview}
                    docType="campaign_proposal_doc"
                    onSuccess={this.onUploadSuceess}
                    extraInfoAboutField={
                      'This field is use to upload a document related to your campaign'
                    }
                  />
                </div> */}

                    <div className="col-6 pl-0 mb-3">
                      <FileInput
                        required={false}
                        label="Baseline Survey Document"
                        id="surveyDocument"
                        placeholder="Choose a pdf/word doc"
                        name="surveyDocument"
                        refId={this.state.campaignId}
                        fileSizeLimit={25}
                        value={this.state.formFields.surveyDocument.value || ''}
                        accept={
                          '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX'
                        }
                        previewDoc={true}
                        // disabled={isCampaignUnderReview}
                        disabled={
                          isCampaignUnderReview ||
                          isCampaignWaitingForFunds ||
                          this.state.praposalModificationStatus
                        }
                        docType="campaign_proposal_doc"
                        onSuccess={this.onUploadSuceess}
                        extraInfoAboutField={
                          'This field is use to upload a document related to your Concept Note'
                        }
                        permissions={this.props.permissions}
                      />
                    </div>
                    <div className="col-6 pl-0 mb-3">
                      <FileInput
                        label="Needs Assessment Report"
                        id="needsAssessment"
                        placeholder="Choose a pdf/word doc"
                        name="needsAssessment"
                        refId={this.state.campaignId}
                        fileSizeLimit={20}
                        value={
                          this.state.formFields.needsAssessment.value || ''
                        }
                        accept={
                          '.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX'
                        }
                        previewDoc={true}
                        disabled={
                          isCampaignUnderReview ||
                          isCampaignWaitingForFunds ||
                          this.state.praposalModificationStatus
                        }
                        docType="campaign_proposal_doc"
                        onSuccess={this.onUploadSuceess}
                        extraInfoAboutField={
                          'This field is use to upload a document related to your Concept Note needs'
                        }
                        permissions={this.props.permissions}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8 mx-auto">
                <div className={`col-12 p-4 pr-0 sticky-top bg-white`}>
                  <h3 className="card-title project-details-heading mb-4 fs-16">
                    Project Details
                  </h3>

                  {/* <div className="row">
                <InputText
                  required={true}
                  wrapperClassName="w-50"
                  type={'text'}
                  labelText="Requested Funding Amount (INR)"
                  id="fundAmount"
                  name="fundAmount"
                  fieldError={this.state.formFields.fundAmount.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  fieldValue={convertInCommaFormat(
                    this.state.formFields.fundAmount.value
                  )}
                  disableInput={isCampaignUnderReview}
                  maxLength={12}
                  PlaceHolder={'Maximum value 99,99,99,999'}
                />
              </div> */}

                  <div className="row">
                    {/* <InputTextArea
                  required={true}
                  labelText="Project Address"
                  id="projectAddress"
                  name="projectAddress"
                  fieldError={this.state.formFields.projectAddress.error}
                  onHandleChange={this.onHandleChange}
                  onChangeCallback={this.setFormValidStatus}
                  onHandleBlur={this.onHandleBlur}
                  fieldValue={this.state.formFields.projectAddress.value}
                  wrapperClassName="w-100"
                  className="campaign-textarea"
                  disableInput={isCampaignUnderReview}
                  maxLength={3000}
                  PlaceHolder={'Maximum Length is 3000 Characters'}
                /> */}

                    <div className="row mb-4">
                      <label className=" mt-3 mb-3">
                        Select Framework for Monitoring and Evaluation
                        <span className="color-FF2300">*</span>
                      </label>
                      <div className="row">
                        <div className="col-md-3">
                          <button
                            type="button"
                            className="btn btn-framework"
                            name="framework"
                            id="framework"
                            value={'BASIC'}>
                            <span className="fs-14 dm-medium">
                              Basic Framework
                            </span>{' '}
                            <div className="dm-regular fs-10">
                              Designed by Metta Social
                            </div>{' '}
                          </button>
                        </div>

                        <div className="col-md-3 tooltipss-campagin">
                          <button
                            type="button"
                            className="btn btn-framework "
                            disabled>
                            <span className="fs-18 font-weight-regular  text-0085FF">
                              LFA
                            </span>
                            <div className="dm-regular fs-10 color-C1C1C1">
                              Logical Framework Analysis
                            </div>
                          </button>{' '}
                          <span className="tooltipss-campagin-text">
                            Coming Soon
                          </span>
                        </div>

                        <div className="col-md-3 tooltipss-campagin">
                          <button
                            type="button"
                            className="btn btn-framework"
                            disabled>
                            <span className="fs-18  font-weight-regular text-0085FF">
                              RBM
                            </span>{' '}
                            <div className="dm-regular fs-10  color-C1C1C1">
                              Result Based Management
                            </div>{' '}
                          </button>{' '}
                          <span className="tooltipss-campagin-text">
                            Coming Soon
                          </span>
                        </div>

                        <div className="col-md-3 tooltipss-campagin">
                          <button
                            type="button"
                            className="btn btn-framework"
                            disabled>
                            <span className="fs-18 font-weight-regular text-0085FF">
                              Theory of Change
                            </span>{' '}
                            <div className="dm-regular fs-10  color-C1C1C1">
                              Theory Based Framework
                            </div>{' '}
                          </button>{' '}
                          <span className="tooltipss-campagin-text">
                            Coming Soon
                          </span>
                        </div>
                      </div>
                    </div>
                    <InputCheckBox
                      required
                      className="mr-1 tnc-checkbox"
                      id="tnc_accepted"
                      name="Tnc Accepted"
                      value={this.state.formFields.tnc_accepted.value}
                      dataType="boolean"
                      fieldError={this.state.formFields.tnc_accepted.error}
                      onHandleChange={this.onHandleChange}
                      onChangeCallback={this.setFormValidStatus}
                      disableInput={
                        isCampaignUnderReview ||
                        isCampaignWaitingForFunds ||
                        this.state.praposalModificationStatus
                      }
                      wrapperClassName="row form-group border-0 pl-0"
                      permissions={this.props.permissions}
                    />
                    <div className="col-12 d-flex p-0 justify-content-between align-items-center">
                      {/* <span
                        className={`text-1998EA cursor-pointer ${
                          this.state.campaignId === null ||
                          this.state.uploadInProgress === true ||
                          this.state.disable_preview_button ||
                          this.state.uploadInProgress === true ||
                          this.state.currentCampaignStatus !== 'DRAFT'
                            ? 'disabled'
                            : ''
                        } ${
                          this.state.currentCampaignStatus === 'DRAFT'
                            ? 'd-block'
                            : 'd-none'
                        }`}
                        onClick={() => this.onPreview('ACTIVE')}>
                        Preview Campaign
                      </span> */}

                      {/* <div>
                        <button
                          type="button"
                          onClick={() =>
                            this.onSave(Common.NGO.CAMPAIGN_STATUS.DRAFT)
                          }
                          disabled={
                            this.state.disable_campaign_content_save_button
                          }
                          className={`btn btn-outline-primary btn-custom fs-13 mr-4 ${isCampaignUnderReview ? 'd-none' : 'd-inline-block'
                            }`}>
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            this.onSubmit(
                              Common.NGO.CAMPAIGN_STATUS.UNDER_REVIEW
                            )
                          }
                          className="btn btn-primary save-button btn-sm"
                          disabled={
                            this.state.currentCampaignStatus !== 'DRAFT'
                          }>
                          Submit
                        </button>
                      </div> */}

                      {/* ank */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/**************Proposal**********************************/}

        <>
          <div
            style={{
              backgroundColor: '#FFFFFF',
              height: 'auto',
              width: '1151px'
            }}
            className="col-12">
            {this.state.proposedBudget && (
              <ProposedTable
                name={'Project Budget'}
                proposedBudget={this.state.proposedBudget}
                campaignId={this.state.campaignId}
                fundAmount={this.state.fundAmount}
                RequestedFundAmount={parseInt(
                  removeCommas(this.state.formFields.fundAmount.value)
                )}
                isCampaignUnderReview={isCampaignUnderReview}
                campaignName={this.state.formFields.campaignName.value}
                currentCampaignStatus={this.state.currentCampaignStatus}
                showNext={showNext}
                getCampaignProposalStatus={this.getCampaignProposalStatus}
                proposalStatus={this.state.proposalStatus}
                previewCampaign={this.onPreview}
                permissions={this.props.permissions}
                allExpenseSubCategoryList={this.state.allExpenseSubCategoryList}
              />
            )}

            {this.state.activity && (
              <ProposedTable
                name={'Project Activities'}
                campaignId={this.state.campaignId}
                fundAmount={this.state.fundAmount}
                showNext={showNext}
                getCampaignProposalStatus={this.getCampaignProposalStatus}
                currentCampaignStatus={this.state.currentCampaignStatus}
                proposalStatus={this.state.proposalStatus}
                previewCampaign={this.onPreview}
                permissions={this.props.permissions}
              />
            )}

            {this.state.projectIndicator && (
              <ProposedTable
                name={'Project Indicators'}
                campaignId={this.state.campaignId}
                fundAmount={this.state.fundAmount}
                isCampaignUnderReview={isCampaignUnderReview}
                showNext={showNext}
                onSubmit={this.onSubmit}
                campaignName={this.state.formFields.campaignName.value}
                getCampaignProposalStatus={this.getCampaignProposalStatus}
                proposalStatus={this.state.proposalStatus}
                previewCampaign={this.onPreview}
                currentCampaignStatus={this.state.currentCampaignStatus}
                permissions={this.props.permissions}
                adminName={adminName}
              />
            )}
          </div>
        </>

        {this.state.tabName === 'ConceptNoteDetails' && (
          <>
            <PopupModal
              showCancelPopup={this.state.showCancelPopup}
              handleCancelPopup={this.handleCancelPopup}
              removeUnsavedData={this.removeUnsavedData}
              tabName={this.state.tabName}></PopupModal>

            <AuthHideShow permissions={this.props.permissions}>
              <StickyBottomBar
                onSave={this.onSave}
                isCampaignUnderReview={isCampaignUnderReview}
                campaignName={this.state.formFields.campaignName.value}
                onSubmit={this.onSubmit}
                adminName={adminName}
                disable_campaign_content_save_button={
                  this.state.disable_campaign_content_save_button
                }
                showNext={showNext}
                currentCampaignStatus={this.state.currentCampaignStatus}
                type={'CreateCampaign'}
                tabName={this.state.tabName}
                clearAllFormFields={this.clearAllFormFields}
                handleCancelPopup={this.handleCancelPopup}
                disableCampaignDetails={
                  isCampaignUnderReview || isCampaignWaitingForFunds
                }
                previewCampaign={this.onPreview}
              />
            </AuthHideShow>

            <PopupModal
              submitted={this.state.successModalPopup}
              closeSuccessModal={this.closeSuccessModal}></PopupModal>
          </>
        )}
      </>
    );
  };

  render() {
    if (this.props.loading) {
      return <Loader loaderText="Loading..." />;
    }
    if (!this.state.showPreview) {
      return (
        <div className="row p-4 create-campaign">{this.createMainHtml()}</div>
      );
    }

    return (
      <>
        <CampaignPreview
          campaignId={this.state.campaignId}
          onClosePreview={this.onClosePreview}
          fetchingPreview={this.fetchingPreview}
          setFetchPreview={this.setFetchPreview}
          onSubmit={this.onSubmit}
          proposalStatus={this.state.proposalStatus}
          currentCampaignStatus={this.state.currentCampaignStatus}
          showPreview={this.state.showPreview}
        />
      </>
    );
  }
}
const mapStateToProps = state => ({
  state: state,
  userProfile: state.login.userInfo,
  masterInfo: state.common.masterInfo,
  masterSecondaryInfo: state.common.masterSecondaryInfo,
  masterSecondaryResponseStatus: state.common.masterSecondaryResponseStatus,
  listCountries: state.common.listCountries,
  listStates: state.common.listStates,
  listDistricts: state.common.listDistricts,
  createModifySuccessResponse: state.campaign.createModifySuccessResponse,
  createModifyErrorResponse: state.campaign.createModifyErrorResponse,
  editSuccessResponse: state.campaign.editSuccessResponse,
  editErrorResponse: state.campaign.editErrorResponse,
  loading: state.campaign.loading,
  profileCompleted: state.ngo.ngoProfile,
  campaignList: state.missionData.missionList
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMasterData,
      getMasterDataById,
      createOrModifyCampaign,
      getCampaign,
      getMasterListCountries,
      getMasterListCurrencies,
      getStatesDataById,
      getDistrictsDataById,
      getHeaderData,
      getProjectActivities,
      getCampaignProposalStatus,
      getCampaignProposalModificationStatus,
      acceptCampaignProposalModification,
      checkForCorpInterest,
      resetCampaignFormData,
      getNGOCorpForPublished,
      getNgoProfile,
      getAllExpenseSubCategory
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateModifyCampaign);
