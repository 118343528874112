import React, { useRef, useState, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { isAuthorized } from '../helpers/auth-roles';
import {
  rteButtonConstants,
  rteButtonMDConstants
} from '../constants/common.constants';

export default function InputRichTextEditor(props) {
  const editor = useRef(null);
  const [value, setValue] = useState(props.fieldValue);
  const isImpersonator = window.location.href.includes('email=');
  const config = {
    height: 300,
    enableDragAndDropFileToEditor: true,
    buttons: rteButtonConstants,
    buttonsMD: rteButtonMDConstants,
    buttonsXS: rteButtonConstants,
    spellcheck: true,
    imageDefaultWidth: 200,
    limitChars: props.maxLength,
    disable: true,
    showToolTip: true,
    fullSize: true,
    showWordsCounter: false,
    showCharsCounter: false,
    showXPathInStatusbar: false,
    defaultActionOnPaste: 'insert_clear_html',

    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    readonly:
      props.disableInput ||
      (!isAuthorized(props.permissions) && !isImpersonator),
    namespace: true,
    allowResizeY: false,
    allowResizeX: false,
    autofocus: true,
    placeholder: props.PlaceHolder,
    uploader: {
      url: 'https://nonprod-fileuploader.mettasocial.com/upload-file',
      format: 'json',
      headers: {
        accept: 'application/json'
      },
      prepareData: function(formData) {
        // formData.append('docType', props.docType || 'campaign_image');
        // formData.append('moduleName', 'ngo');
        // formData.append('refId', props.refId);
        // console.log('formData', formData);
        // formData.append('files', props.file, props.file.name);
      },
      buildData: function(data) {
        let file = null;
        data.forEach(function(value, key) {
          if (key.includes('file')) {
            file = value;
          }
        });
        let userId = JSON.parse(localStorage.getItem('ngoId'));
        let uploadToProd = process.env.UPLOAD_TO_PROD;
        if (!uploadToProd) {
          uploadToProd = false;
        }
        let formData = new FormData();
        formData.append('files', file, file.name);
        formData.append('upload_to_prod', uploadToProd);
        if (userId) {
          formData.append('user_id', userId);
        } else {
          formData.append('used_id', 'bc6f4b08-f48c-4e42-ab86-c8aeb0357ca9');
        }

        return formData;
      },
      isSuccess: function(resp) {
        return !resp.error;
      },
      getMessage: function(resp) {
        return resp.message;
      },
      process: function(resp) {
        return {
          files: [
            {
              contentType: 'image/jpeg',
              encoding: '7bit',
              fieldname: 'file',
              filename: 'earthquake.jpeg'
            }
          ],
          path: resp.data.objects[0].uploaded_file_url,
          error: 'resp.error',
          msg: 'File Saved Successfully'
        };
      },
      defaultHandlerSuccess: function(data, resp) {
        var i,
          field = 'files';
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(data.path);
          }
        }
      },
      error: function(e) {
        this.e.fire('errorMessage', [e.getMessage(), 'error', 4000]);
      }
    }
  };
  const handleOnChange = newValue => {
    setValue(newValue);
    if (props.onHandleChange) {
      props.onHandleChange(props.id, newValue, props.onChangeCallback);
    }
  };

  return (
    <div
      className={`form-group ${
        props.required ? 'required' : ''
      }  ${props.wrapperClassName || ''}`}>
      {props.labelText !== '' && (
        <label className={`${props.labelClassName || ''}`} htmlFor={props.id}>
          {props.labelText}{' '}
          {props.required ? <span className="color-FF2300">*</span> : ' '}
        </label>
      )}
      {useMemo(
        () => (
          <JoditEditor
            ref={editor}
            value={value}
            config={config}
            tabIndex={1}
            onChange={value => {
              handleOnChange(value);
            }}
          />
        ),
        []
      )}
      <div className={`d-flex justify-content-between`}>
        <div className={` ${props.fieldError === '' ? 'd-none' : 'w-50'}`}>
          <small id={props.id} className="text-danger">
            {props.fieldError}
          </small>
        </div>
        <div className="w-100">
          <small
            id={props.id}
            className="text-black mr-1 d-flex justify-content-end">
            {value?.length > 0 && `CHAR (Including Style): ${value?.length}`}
          </small>
        </div>
      </div>
    </div>
  );
}
