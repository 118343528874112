import React, { useState } from 'react';
import '../newDesign/Styles.css';
import Table from '../newDesign/commonTable';
import // activitiesColumn,
// editActivitiesColumn
'../../constants/MNEDashboard';
import { proposedBudget, activityPlan, projectIndicators } from './tabFields';
import { toast } from 'react-hot-toast';
import Modal from '../../components/newDesign/Modals/Modal';
var sectionWiseRecordsData = [];
export default function ProposedProject(props) {
  const [projectStatus, setProjectStatus] = useState('ConceptNoteDetails');
  const [showModal, setShowModal] = useState(false);
  const [activityType, setActivityType] = useState('');
  const [activityId, setActivityId] = useState('');
  const [columns, setColumns] = useState(proposedBudget);
  const [tableRows, setTableRows] = useState([]);
  const [budget, setBudget] = useState([]);

  function handleClick(type) {
    if (type === 'ConceptNoteDetails') {
      setProjectStatus(type);
      document.getElementById(props.viewType).style.display = 'block';
    } else {
      document.getElementById(props.viewType).style.display = 'none';
      if (type === 'ProposedBudget') {
        setProjectStatus(type);
        setColumns(proposedBudget);
        // setTableRows(props.financeList);
        sortRecordsSectionWise();
      }
      if (type === 'ActivitiesPlan') {
        setProjectStatus(type);
        setColumns(activityPlan);
        setTableRows(props.activityList);
      }
      if (type === 'ProjectIndicators') {
        setProjectStatus(type);
        setColumns(projectIndicators);
        if (
          props.indicatorsList?.length === 0 ||
          (props.indicatorsList?.length === 1 &&
            props.indicatorsList[0]?.indicator_description === '')
        ) {
          setTableRows([]);
        } else if (props.indicatorsList?.length > 0) {
          setTableRows(props.indicatorsList);
        }
      }
    }
  }

  const showHideModal = (value, id) => {
    if (showModal) {
      setShowModal(false);
      setActivityType('');
      setActivityId(id);
    } else {
      setShowModal(true);
      setActivityType(value);
      setActivityId(id);
    }
  };

  const updateActivity = type => {
    if (type === 'start') {
      showHideModal();
    } else {
      showHideModal();
    }

    const tempActivityList = props.activityList.slice();
    const row = tempActivityList.find(x => x.id === activityId);
    if (row) {
      if (
        type === 'start' &&
        (row.actual_start_date === 'None' || row.actual_start_date === 'None')
      )
        return toast.error('Please select Actual Start Date ');
      if (
        type === 'In Progress' &&
        (row.actual_end_date === 'None' || row.actual_end_date === 'None')
      )
        return toast.error('Please select Actual End Date ');
    }
    let payloadObj = {
      status: type === 'start' ? 'In Progress' : 'Completed',
      campaign_id: window.location.pathname.split('/')[3],
      activity_id: activityId
    };
    if (row) {
      if (type === 'start')
        payloadObj['actual_start_date'] = row.actual_start_date;
      else payloadObj['actual_end_date'] = row.actual_end_date;
    }

    props
      .updateActivity(payloadObj)
      .then(() => {
        toast.success('Activity updated successfully');
      })
      .catch(() => {
        toast.error("Oops! Looks like we couldn't process your request.");
      });

    setActivityType('');
    props.getProjectActivities();
  };

  function sortRecordsSectionWise() {
    let expenseArr = [];

    let sectionObject = {};
    var expenseDataArr = props.financeList;
    const uniqueCatIds = [
      ...new Set(expenseDataArr.map(obj => obj.expense_category_id))
    ];
    if (expenseDataArr?.length > 0) {
      const catIdMap = Object.fromEntries(
        uniqueCatIds.map((id, index) => [id, index])
      );

      expenseDataArr = expenseDataArr.sort((a, b) => {
        const indexA = catIdMap[a.expense_category_id];
        const indexB = catIdMap[b.expense_category_id];
        return indexA - indexB;
      });
    }

    if (expenseDataArr !== undefined) {
      const groupByExpenseNameArr = expenseDataArr.reduce(
        (accumulator, currentValue) => {
          const expenseHeader = currentValue.expense_header;
          const projectExpensesName = currentValue.expense_category;

          // Find the index of the expense_header in the accumulator array
          const headerIndex = accumulator.findIndex(
            item => item.expense_header === expenseHeader
          );

          // If the expense_header exists in the accumulator array, update its corresponding project_expenses_name array
          if (headerIndex !== -1) {
            const projectIndex = accumulator[headerIndex].sections.findIndex(
              item => item.project_expenses_name === projectExpensesName
            );
            if (projectIndex !== -1) {
              accumulator[headerIndex].sections[projectIndex].data.push(
                currentValue
              );
            } else {
              accumulator[headerIndex].sections.push({
                project_expenses_name: projectExpensesName,
                data: [currentValue]
              });
            }
          } else {
            // If the expense_header does not exist in the accumulator array, create a new entry
            accumulator.push({
              expense_header: expenseHeader,
              sections: [
                {
                  project_expenses_name: projectExpensesName,
                  data: [currentValue]
                }
              ]
            });
          }

          return accumulator;
        },
        []
      );
      setBudget(groupByExpenseNameArr);
      if (Object.keys(sectionObject).length > 0) {
        expenseArr.push(sectionObject);
      }
      sectionWiseRecordsData = expenseArr;
      setTableRows(sectionWiseRecordsData);
      // setSctionWiseRecords(expenseArr);
    }
  }
  const commaSeparatedNumber = number => {
    if (number) {
      return new Intl.NumberFormat('en-IN').format(number);
    }
    return 0;
  };

  return (
    <div className="col-12 mt-4 pl-0 pr-0">
      <div className="w-100 bg-white">
        <div className="p-2">
          {props.tabActivity ? (
            <>
              {/* <Table
                column={columns}
                editRowActivity={props.activityList}
                editActivity={props.tabActivity}
                handleChange={handleChange}
                activityUploadSuccess={activityUploadSuccess}
                handleFunction1={showHideModal}
              /> */}
              <div className="d-flex mt-3 justify-content-center">
                {/* <button
                  className="btn btn-activityPlan btn-custom"
                  onClick={e => submitActivities(e)}>
                  Update
                </button> */}
              </div>
            </>
          ) : (
            <>
              <div className="inline-tabs row d-flex align-items-center mb-4 mt-4">
                <span
                  className={`tab ${
                    projectStatus === 'ConceptNoteDetails'
                      ? 'active-tab color-0085FF'
                      : ''
                  } fs-14 font-weight-normal position-relative pl-2 pb-2 mr-3 cursor-pointer select-none`}
                  onClick={() => handleClick('ConceptNoteDetails')}>
                  Concept Note
                </span>
                <span
                  className={`tab ${
                    projectStatus === 'ProposedBudget'
                      ? 'active-tab color-0085FF'
                      : ''
                  } fs-14 font-weight-normal position-relative px-2 pb-2 mr-3 cursor-pointer select-none`}
                  onClick={() => handleClick('ProposedBudget')}>
                  Proposed Budget
                </span>

                <span
                  className={`tab ${
                    projectStatus === 'ActivitiesPlan'
                      ? 'active-tab color-0085FF'
                      : ''
                  } fs-14 font-weight-normal position-relative px-2 pb-2 mr-3 cursor-pointer select-none`}
                  onClick={() => handleClick('ActivitiesPlan')}>
                  Activities Plan
                </span>
                <span
                  className={`tab ${
                    projectStatus === 'ProjectIndicators'
                      ? 'active-tab color-0085FF'
                      : ''
                  } fs-14 font-weight-normal position-relative px-2 pb-2 mr-3 cursor-pointer select-none`}
                  onClick={() => handleClick('ProjectIndicators')}>
                  Project Indicators
                </span>
              </div>
              {projectStatus !== 'ConceptNoteDetails' &&
                projectStatus !== 'ProposedBudget' && (
                  <Table
                    column={columns}
                    records={tableRows}
                    projectStatus={projectStatus}
                    fundAmount={props.fundAmount}
                    expenseCategoryList={props.expenseCategoryList}
                  />
                )}
              {projectStatus === 'ProposedBudget' && (
                <div>
                  {budget.map((section, index) => {
                    const getTotalHeader = data => {
                      return data.reduce((acc, sec) => {
                        const sectionSum = sec?.data.reduce((sum, obj) => {
                          return (
                            sum +
                            Number(obj?.amount) *
                              Number(obj.quantity) *
                              Number(obj.metrics)
                          );
                        }, 0);
                        return acc + sectionSum;
                      }, 0);
                    };
                    return (
                      <div key={index} className="border rounded mb-4">
                        <div className="bg_FFF0C3 fw-600 fs-16 p-3 mb-4">
                          {`Header ${index + 1}`}
                          {' : '}
                          {section?.expense_header ||
                            `Expense Header ${index + 1}`}
                        </div>
                        <div className="px-4">
                          {section.sections.map((subsection, id) => {
                            const getTotal = data => {
                              return data?.reduce((acc, obj) => {
                                return (
                                  acc +
                                  Number(obj?.amount) *
                                    Number(obj.quantity) *
                                    Number(obj.metrics)
                                );
                              }, 0);
                            };
                            return (
                              <div
                                key={id + subsection?.project_expenses_name}
                                className="border rounded mb-3">
                                <div className="bg_E8F2FF fw-600 fs-14 p-3">
                                  {`Section ${id + 1}`}
                                  {' : '}
                                  {subsection?.project_expenses_name}
                                </div>
                                <table
                                  id="dtBasicExample"
                                  className="table table-borders table-hover fs-14 bg-white mb-0">
                                  <thead className="table-head border-bottom">
                                    <tr className="text-left h-lh-45">
                                      <th style={{ width: '5%' }}>SR</th>
                                      <th style={{ width: '15%' }}>DETAILS</th>
                                      <th style={{ width: '20%' }}>
                                        EXPENSE SUB-CATEGORY
                                      </th>
                                      <th style={{ width: '8%' }}>RATE</th>
                                      <th style={{ width: '9%' }}>QTY</th>
                                      <th style={{ width: '9%' }}>METRICS</th>
                                      <th style={{ width: '10%' }}>TOTAL</th>
                                      <th style={{ width: '10%' }}>BUDGET</th>
                                      <th style={{ width: '15%' }}>
                                        JUSTIFICATION
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {subsection.data.map((itm, subIndex) => {
                                      return (
                                        <>
                                          <tr
                                            key={subIndex}
                                            className="text-left h-40 border-bottom">
                                            <td className="align-top">
                                              {subIndex + 1}
                                            </td>
                                            <td className="align-top">
                                              {itm.expense_description}
                                            </td>
                                            <td className="align-top">
                                              {itm.expense_sub_category}
                                            </td>
                                            <td className="align-top">
                                              {itm.amount}
                                            </td>
                                            <td className="align-top">
                                              {itm.quantity}
                                            </td>
                                            <td className="align-top">
                                              {itm.metrics}
                                            </td>
                                            <td className="align-top ">
                                              {'₹ '}
                                              {commaSeparatedNumber(
                                                Number(itm?.amount) *
                                                  Number(itm.quantity) *
                                                  Number(itm.metrics)
                                              ) || '0'}
                                            </td>
                                            <td className="align-top fw-800">
                                              {(
                                                ((Number(itm?.amount) *
                                                  Number(itm.quantity) *
                                                  Number(itm.metrics)) /
                                                  props?.fundAmount) *
                                                100
                                              ).toFixed(2)}
                                              {' %'}
                                            </td>
                                            <td className="align-top">
                                              {itm?.justification}
                                            </td>
                                          </tr>
                                          {/* )} */}
                                        </>
                                      );
                                    })}

                                    <tr className="text-let  h-40 bg_F4F4F4 mb-0">
                                      <td className="align-top"></td>
                                      <td className="align-top  fs-14 text-344256">
                                        {'Section Total'}
                                      </td>
                                      <td className="align-top"></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>

                                      <td className="align-top  fs-14 text-344256 sumtotal_budget fw-800">
                                        {' ₹ '}
                                        {commaSeparatedNumber(
                                          getTotal(subsection?.data)
                                        )}
                                      </td>
                                      <td className="align-top  fs-14 text-344256 sumtotal_budget fw-800">
                                        {(
                                          (getTotal(subsection?.data) /
                                            props?.fundAmount) *
                                          100
                                        ).toFixed(2)}
                                        %
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          })}
                        </div>
                        <table
                          id="dtBasicExample"
                          className="table table-borders table-hover fs-14 bg-white mb-0">
                          <thead className="table-head ">
                            <tr className="text-left h-lh-45">
                              <th style={{ width: '5%' }}></th>
                              <th style={{ width: '15%' }}></th>
                              <th style={{ width: '20%' }}></th>
                              <th style={{ width: '8%' }}></th>
                              <th style={{ width: '9%' }}></th>
                              <th style={{ width: '9%' }}></th>
                              <th style={{ width: '10%' }}></th>
                              <th style={{ width: '10%' }}></th>
                              <th style={{ width: '15%' }}></th>
                            </tr>
                          </thead>

                          <tbody>
                            {' '}
                            <tr className="text-let   h-40 bg_EAF7F6">
                              <td className="align-top"></td>

                              <td className="align-top fw-800 fs-14 text-344256">
                                {'Header Total'}
                              </td>
                              <td className="align-top"></td>
                              <td></td>
                              <td></td>
                              <td></td>

                              <td className="align-top  fs-14 text-344256  fw-800">
                                {' ₹ '}
                                {commaSeparatedNumber(
                                  getTotalHeader(section?.sections)
                                )}
                              </td>
                              <td className="align-top  fs-14 text-344256  fw-800">
                                {(
                                  (getTotalHeader(section?.sections) /
                                    props?.fundAmount) *
                                  100
                                ).toFixed(2)}
                                %
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                  <div></div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        show={showModal}
        title={
          activityType === 'start' ? 'Start Activity?' : 'Complete Activity?'
        }
        handleCloseModal={showHideModal}
        sizeClass={'modal-md'}>
        {activityType === 'start' ? (
          <p className="fs-18 font-weight-normal mb-5">
            Are you sure you want to start this activity? Please note that this
            action is irreversible.
          </p>
        ) : (
          <p className="fs-18 font-weight-normal mb-5">
            Are you sure you want to mark the activity as Complete? Please note
            that this action is irreversible.
          </p>
        )}
        <div className="text-center">
          <button
            className="btn btn-activityPlan"
            onClick={() => updateActivity(activityType)}>
            {activityType === 'start' ? 'Start Activity' : 'Mark Complete'}
          </button>
        </div>
      </Modal>
    </div>
  );
}
