import React, { useEffect, useState } from 'react';
import CheckboxList from './checkBox';

const MultiSelect = ({
  input,
  key,
  stepIndex,
  disabled,
  state,
  fieldUpdated,
  locationUpdated,
  areaOptions: StateList,
  selectedData,
  isError,
  missingState
}) => {
  const [selectedItems, setSelectedItem] = useState([]);
  const [options, setOptions] = useState(StateList);
  const [searchText, setSearch] = useState('');
  useEffect(() => {
    setOptions(StateList);
  }, [StateList]);

  useEffect(() => {
    setSelectedItem(selectedData);
  }, [selectedData]);

  const renderStateList = () => {
    return (
      <CheckboxList
        options={options}
        itemsInEachCol={Math.ceil(StateList?.length / 3)}
        type={input.jsonfield}
        selectedItems={selectedItems}
        onChange={selectedData => {
          setSelectedItem(selectedData);
        }}
      />
    );
  };

  const searchOptions = () => {};
  const setSelectedOptions = () => {
    fieldUpdated(stepIndex);
    locationUpdated(input.jsonfield, selectedItems);
  };

  const clearSelection = () => {
    setSelectedItem([]);
    setSearch('');
  };

  const searchStateByName = (stateArray, name) => {
    // Convert the name to lowercase for case-insensitive search
    const lowerCaseName = name.toLowerCase();

    // Use the Array.find() method to search for the first state that matches the name
    const matchingStates = stateArray.filter(state =>
      state.name.toLowerCase().includes(lowerCaseName)
    );

    return matchingStates;
  };

  useEffect(() => {
    // const newSelectedItems = selectedItems;
    if (searchText) {
      const newState = searchStateByName(StateList, searchText);
      // setSelectedItem([]);
      setOptions(newState);
    } else {
      // setSelectedItem([]);
      setOptions(StateList);
    }
    //setSelectedItem(newSelectedItems);
  }, [searchText]);

  const renderValidationError = () => {
    if (isError && selectedData.length === 0) {
      return <p className={`text-danger fs-12 my-2`}>{input.validationMsg}</p>;
    } else if (isError && missingState?.length > 0) {
      const states = missingState.join(', ');
      return (
        <p
          className={`text-danger fs-12 my-2`}>{`You have not selected a district for ${states} state(s).`}</p>
      );
    }
  };

  return (
    <>
      {/* <MultiSelectModal /> */}
      {input.heading && (
        <span className={'mt-0  b-25  ml-4 w-100 position-relative py-2  '}>
          {input.heading}
        </span>
      )}
      <div className="form-group col-sm-12 pl-4 pr-4 col-md-6 col-lg-6">
        <label>{input.label}</label>
        <span className="color-FF2300">{`*`}</span>
        <button
          className="btn btn-dropdown ht-36"
          type="button"
          id={input.label}
          data-toggle="dropdown">
          <span className="float-left ">Selected</span>
          <div className=" float-right dropdown-toggle">
            <span className=" state-dropdown-count">
              {selectedItems?.length}
            </span>
          </div>
        </button>
        {renderValidationError()}

        <div
          id={`show_${input.label}`}
          className={`dropdown-menu p-2 `}
          aria-labelledby="dropdownMenu2"
          style={{ width: '419px' }}>
          <div className="position-relative rounded p-0 d-flex justify-content-left">
            <div className="form-group col-10 rounded p-0">
              <div className="text-1C1C1C fs-14 dm-medium mx-3">
                Select {input.label}
                {'(s)'}
              </div>
            </div>
          </div>

          <div className="" style={{ margin: 0 }}>
            <div className="col-12 position-relative rounded p-0 d-flex justify-content-center">
              <span className="form-group col-10 rounded p-0">
                <div
                  type="button"
                  className="btn position-absolute"
                  style={{ right: '0px', top: '4px', zIndex: '1' }}
                  onClick={() => searchOptions()}>
                  <i className="fa fa-search form-control-feedback"></i>
                </div>

                <input
                  type="text"
                  name="search"
                  value={searchText}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Search"
                  className="col-12 standard-search-style rounded"
                />
              </span>
            </div>
          </div>

          <div className="row">
            <div
              className={`col-12 position-relative rounded p-0 d-flex justify-content-center ${
                StateList?.length > 9 ? 'dropDownHeight' : ''
              }`}>
              <div className="row">{renderStateList()}</div>
            </div>
          </div>
          <hr />
          <div className="col-12  justify-content-end position-relative">
            <button
              type="button"
              className="btn btn-primary btn-sm ht-34 mb-2 mx-2 float-right "
              onClick={() => setSelectedOptions()}>
              Apply
            </button>
            <button
              type="button"
              className="btn btn-cancel ht-34 mx-2 mb-2 float-right justify-content-end"
              onClick={() => clearSelection()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiSelect;
