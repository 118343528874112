import React, { useState } from 'react';
export default function Text(props) {
  const {
    uniqueIdentifierForInput,
    input,
    savedValue,
    masterfield,
    visibilityFunc,
    triggerFunction,
    disabled
  } = props;
  const { maxLength, type } = input;
  const [value, setValue] = useState(savedValue);
  const handleChange = e => {
    if (masterfield) {
      visibilityFunc(masterfield, e.target.value);
    }
    if (
      type === 'number' &&
      maxLength &&
      e.target.value.toString().length > maxLength
    ) {
      return setValue(value);
    } else {
      setValue(e.target.value);
    }
    if (type === 'emailText') {
      return setValue(e.target.value);
    }
    if (type === 'otpText') {
      if (e.target.value.length === 4) {
        triggerFunction();
      }
    }
    if (uniqueIdentifierForInput === 'pan_card_no') {
      return setValue(e.target.value.toUpperCase());
    }
    if (
      uniqueIdentifierForInput === 'pincode' ||
      uniqueIdentifierForInput === 'pan_number' ||
      uniqueIdentifierForInput === 'gst_number'
    ) {
      if (uniqueIdentifierForInput === 'pincode') {
        return setValue(e.target.value.replace(/\D/g, ''));
      } else {
        return setValue(e.target.value);
      }
    }
  };
  let extraProps = {
    name: uniqueIdentifierForInput,
    className: 'form-control',
    id: uniqueIdentifierForInput,
    defaultValue: savedValue,
    disabled: disabled
  };
  if (type === 'number') {
    extraProps.onChange = handleChange;
    extraProps.value = value;
  }

  if (
    uniqueIdentifierForInput === 'pan_card_no' ||
    type === 'otpText' ||
    type === 'emailText'
  ) {
    extraProps.onChange = handleChange;
    extraProps.value = value;
  }
  if (
    uniqueIdentifierForInput === 'pincode' ||
    uniqueIdentifierForInput === 'pan_number' ||
    uniqueIdentifierForInput === 'gst_number'
  ) {
    extraProps.onChange = handleChange;
    extraProps.value = value;
  }
  return (
    <>
      <label htmlFor={uniqueIdentifierForInput}>
        {input.label} {input.labelAppend && input.labelAppend}
        <span className="color-FF2300">
          {input.required ||
          (input.validation && input.validation === 'required') ||
          input.showrequired ||
          input.preOnboardRequiredField
            ? ' * '
            : ''}
        </span>
      </label>
      <input
        {...input}
        {...extraProps}
        id={uniqueIdentifierForInput}
        onKeyUp={() =>
          props.fieldUpdated && props.stepIndex >= 0
            ? props.fieldUpdated(props.stepIndex)
            : false
        }
      />
    </>
  );
}
