import React, { useEffect } from 'react';

const PageContentWrapper = props => {
  useEffect(() => {
    document.body.focus();
  }, []);
  return (
    <main
      className="col pl-0 pr-0 min-vh-100 page-wrapper-container"
      // className={` pl-0 pr-0 min-vh-100 page-wrapper-container ${props.sideBars ? "col-10" : "col-md-11 col-lg-11"}`}
      // style={{ marginLeft: `${props.sideBars ? "" : "40px"}`}}
      id="main-content">
      <section
        className="d-flex flex-column flex-grow h-100 overflow-hidden"
        // id="page-wrapper-container"
      >
        {props.children}
      </section>
    </main>
  );
};

export default PageContentWrapper;
