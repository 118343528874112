import $ from 'jquery/dist/jquery.slim';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { getNgoProfile } from '../actions/ngo.actions';
import { Routes } from '../constants/path.constants';
import CampaignsScreen from '../pages/campaignsScreen';
import CompleteRegistrationScreen from '../pages/completeRegistrationScreen';
import CorporateScreen from '../pages/corporateScreen';
import Transactions from '../pages/transactions';
// import DashboardScreen from '../pages/dashboardScreen';
import PreOnBoardingScreen from '../pages/preOnBoardingScreen';
import PreRegistrationScreen from '../pages/preRegistration/preRegistrationScreen';
import ApproveCampaignModScreen from '../pages/approveCampaignModScreen';
import RejectCampaignModScreen from '../pages/rejectCampaignModScreen';
import ProgressScreen from '../pages/progressScreen';
import SupportScreen from '../pages/supportScreen';
import ProjectScreen from '../pages/projectScreen';
// import NewProfile from '../pages/newProfile';
import { securedHOC } from './securedHOC';
// import Sidebar from './sidebar';
import MNEDashboardScreen from '../pages/MNEScreens/Dashboard/index';
import MNESingleDashboard from '../pages/MNEScreens/Dashboard/singleProject';
import ProjectProposalScreen from '../pages/projectProposalScreen';
import MissionScreen from '../pages/missionScreen';
import RoleManagementScreen from '../pages/roleManagementScreen';
import ActualAndPlannedBudgetComparisonScreen from '../pages/MNEScreens/Dashboard/actualAndPlannedBudgetComparisonScreen';
import DonationReportScreen from '../pages/DonationReport/donationReportScreen';
import invoicesScreen from '../pages/invoicesScreen';

// toast.configure({
//   autoClose: 3000,x
//   draggable: true
// });

window.addEventListener('online', () => {
  toast.success('You are back online', {
    autoClose: 2500,
    delay: 1000
  });
  toast.error('Unable to connect to the network');
});

window.addEventListener('offline', () => {
  toast.error('Looks like some issue with your network', {
    toastId: 'network-issue-toast',
    autoClose: false
  });
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.props.getNgoProfile(nextprops.userInfo);
  }

  componentDidMount() {
    $('#menu-toggle ').click(function(e) {
      e.preventDefault();
      $('#wrapper ').toggleClass('toggled ');
    });
  }

  render() {
    return (
      <React.Fragment>
        <a
          href="#main-content"
          id="skip-to-content"
          onClick={() => document.getElementById('main-content').focus()}
          className="sr-only sr-only-focusable bg-light text-dark lead p-3">
          Skip to main content
        </a>
        <div className="router-wrapper row min-vh-100" id="router-wrapper">
          <BrowserRouter>
            <Toaster
              position="top-center"
              toastOptions={{
                style: {
                  borderRadius: '10px',
                  background: '#000000',
                  color: '#fff',
                  fontSize: '18px'
                }
              }}
            />
            {/* {this.props.userInfo && <Sidebar />} */}
            <Switch>
              <Route exact path="/">
                {(this.props.userInfo.user_type === 2 ||
                  this.props.userInfo.user_type === undefined) && (
                  <Redirect
                    to={Routes.NGO.USER_PROFILE}
                    // to={Routes.NGO.PRE_REGISTRATION}
                  />
                )}
              </Route>
              {this.props.userInfo.user_type === undefined && (
                <Route
                  exact
                  path={Routes.NGO.PRE_ONBOARDING}
                  component={PreOnBoardingScreen}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.PRE_REGISTRATION}
                  component={securedHOC(
                    PreRegistrationScreen,
                    Routes.NGO.PRE_REGISTRATION
                  )}
                  // component={PreRegistrationScreen}
                />
              )}
              {this.props.userInfo.user_type === undefined && (
                <Route
                  exact
                  path={Routes.NGO.ACCEPT_CAMPAIGN_MOD}
                  component={ApproveCampaignModScreen}
                />
              )}

              {this.props.userInfo.user_type === undefined && (
                <Route
                  exact
                  path={Routes.NGO.REJECT_CAMPAIGN_MOD}
                  component={RejectCampaignModScreen}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.TRANSACTIONS_LIST}
                  component={securedHOC(
                    Transactions,
                    Routes.NGO.TRANSACTIONS_LIST
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.CAMPAIGN_LIST}
                  component={securedHOC(
                    CampaignsScreen,
                    Routes.NGO.CAMPAIGN_LIST
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.CAMPAIGN_CREATE}
                  component={securedHOC(
                    CampaignsScreen,
                    Routes.NGO.CAMPAIGN_CREATE
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.CAMPAIGN_EDIT + '/:id'}
                  component={securedHOC(
                    CampaignsScreen,
                    Routes.NGO.CAMPAIGN_EDIT
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.CAMPAIGN_DETAILS + '/:id'}
                  component={securedHOC(
                    CampaignsScreen,
                    Routes.NGO.CAMPAIGN_DETAILS
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.CORPORATE_LIST}
                  component={securedHOC(
                    CorporateScreen,
                    Routes.NGO.CORPORATE_LIST
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.DONATION_REPORT}
                  component={securedHOC(
                    DonationReportScreen,
                    Routes.NGO.DONATION_REPORT
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.INVOICES}
                  component={securedHOC(invoicesScreen, Routes.NGO.INVOICES)}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.PROJECT_REPORT + '/:campaignId'}
                  component={securedHOC(
                    ProgressScreen,
                    Routes.NGO.PROJECT_REPORT
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.USER_PROFILE}
                  component={securedHOC(
                    CompleteRegistrationScreen,
                    Routes.NGO.USER_PROFILE
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.SUPPORT}
                  component={securedHOC(SupportScreen, Routes.NGO.SUPPORT)}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.SUPPORT_TICKET_LIST}
                  component={securedHOC(
                    SupportScreen,
                    Routes.NGO.SUPPORT_TICKET_LIST
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.SUPPORT_TICKET + '/:id'}
                  component={securedHOC(
                    SupportScreen,
                    Routes.NGO.SUPPORT_TICKET
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.MNEDASHBOARD}
                  component={securedHOC(
                    MNEDashboardScreen,
                    Routes.NGO.MNEDASHBOARD
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={
                    Routes.NGO.ACTUAL_AND_PLANNED_BUDGET_COMPARISON + '/:id'
                  }
                  component={securedHOC(ActualAndPlannedBudgetComparisonScreen)}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.MNESINGLEDASHBOARD + '/:id'}
                  component={securedHOC(
                    MNESingleDashboard,
                    Routes.NGO.MNESINGLEDASHBOARD
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.PROJECT_DETAILS_OVERVIEW + '/:id'}
                  component={securedHOC(
                    ProjectScreen,
                    Routes.NGO.PROJECT_DETAILS_OVERVIEW
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.PROJECT_ACTIVITIES_PLAN + '/:id'}
                  component={securedHOC(
                    ProjectScreen,
                    Routes.NGO.PROJECT_ACTIVITIES_PLAN
                  )}
                />
              )}

              {/* /*****************Project Proposal************************ */}

              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.PROJECT_PROPOSAL_DETAILS_OVERVIEW + '/:id'}
                  component={securedHOC(
                    ProjectProposalScreen,
                    Routes.NGO.PROJECT_PROPOSAL_DETAILS_OVERVIEW
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.PROJECT_PROPOSAL_ACTIVITIES_PLAN + '/:id'}
                  component={securedHOC(
                    ProjectProposalScreen,
                    Routes.NGO.PROJECT_PROPOSAL_ACTIVITIES_PLAN
                  )}
                />
              )}

              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.PROJECT_PROPOSAL_IMPACT_INDICATORS + '/:id'}
                  component={securedHOC(
                    ProjectProposalScreen,
                    Routes.NGO.PROJECT_PROPOSAL_IMPACT_INDICATORS
                  )}
                />
              )}

              {/* /*****************Mission************************ */}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.CREATE_MISSION}
                  component={securedHOC(
                    MissionScreen,
                    Routes.NGO.CREATE_MISSION
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.MODIFY_MISSION + '/:id'}
                  component={securedHOC(
                    MissionScreen,
                    Routes.NGO.MODIFY_MISSION
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.MISSION_DETAILS + '/:id'}
                  component={securedHOC(
                    MissionScreen,
                    Routes.NGO.MISSION_DETAILS
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.MISSION_PLEDGE_SUMMARY + '/:id'}
                  component={securedHOC(
                    MissionScreen,
                    Routes.NGO.MISSION_PLEDGE_SUMMARY
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.MISSION_STATS + '/:id'}
                  component={securedHOC(
                    MissionScreen,
                    Routes.NGO.MISSION_STATS
                  )}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.MISSION_EDIT + '/:id'}
                  component={securedHOC(MissionScreen, Routes.NGO.MISSION_EDIT)}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.MISSION_LIST}
                  component={securedHOC(MissionScreen, Routes.NGO.MISSION_LIST)}
                />
              )}
              {(this.props.userInfo.user_type === undefined ||
                this.props.userInfo.user_type === 2) && (
                <Route
                  exact
                  path={Routes.NGO.ROLE_MANAGEMENT}
                  component={securedHOC(
                    RoleManagementScreen,
                    Routes.NGO.ROLE_MANAGEMENT
                  )}
                />
              )}

              {/********end ***************************************/}

              <Redirect to={Routes.NGO.USER_PROFILE} />
              {/* <Redirect to={Routes.NGO.PRE_REGISTRATION} /> */}
            </Switch>
          </BrowserRouter>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.login.userInfo
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getNgoProfile
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
