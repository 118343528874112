import { Action } from '../constants';
const initialState = {
  legalStructure: null,
  educationalBackground: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.LEGAL_STRUCTURE_DROPDOWN_SUCESS:
      return {
        ...state,
        loading: true,
        legalStructure: action.payload
      };
    case Action.EDUCATIONAL_BACKGROUND_SUCCESS:
      return {
        ...state,
        loading: true,
        educationalBackground: action.payload
      };
    default:
      return state;
  }
};
