import { Action } from '../constants';

const guide_star_certification_level = [
  {
    id: 'Champion Level GuideStar India Platinum',
    name: 'Champion Level GuideStar India Platinum'
  },
  {
    id: 'Advanced Level GuideStar India Gold/ Silver',
    name: 'Advanced Level GuideStar India Gold/ Silver'
  },
  {
    id: 'Foundation Level GuideStar India Transparency Key/ Badge',
    name: 'Foundation Level GuideStar India Transparency Key/ Badge'
  }
];

export const getGuideStarCertificationLevel = () => dispatch => {
  dispatch({
    type: Action.GUIDE_STAR_CERTIFICATION_LEVEL,
    payload: guide_star_certification_level
  });
};
