import { Action } from '../constants';

const initialState = {
  corporateList: null,
  searchedList: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.CORPORATE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case Action.CORPORATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        corporateList: action.payload
      };

    case Action.CORPORATE_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };

    case Action.SEARCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        corporateList: action.payload
      };

    default:
      return state;
  }
};
