import React, { useEffect, useState } from 'react';

const MultiSelectCheckbox = ({
  selectedData,
  checkedOptions,
  setCheckedOptions,
  setSelectedCount,
  selectedCount,
  setSelectAll,
  selectAll,
  searchPlaceholder,
  setFinalList,
  disabled,
  selectAllText,
  allOptionsSelected,
  handleMissionSelections
}) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    initialSelection();
  }, [selectedData]);

  const initialSelection = () => {
    if (allOptionsSelected === true) {
      handleCheckboxChange('selectAll');
      setSelectedCount(selectedData.length);
      // setSelectAll(true);
    } else {
      setSelectedCount([]);
      setSelectedCount(0);
      setSelectAll(false);
    }
  };
  const handleCheckboxChange = option => {
    if (option === 'selectAll') {
      setSelectAll(!selectAll);
      if (!selectAll) {
        setCheckedOptions(selectedData);
      } else {
        setCheckedOptions([]);
      }
    } else {
      setSelectAll(false);
      const index = checkedOptions.indexOf(option);
      if (index === -1) {
        setCheckedOptions([...checkedOptions, option]);
      } else {
        setCheckedOptions(
          checkedOptions.filter(optionId => optionId?.id !== option?.id)
        );
      }
    }
  };
  const getSelectedOptionsCount = () => {
    if (checkedOptions.length === 0) {
      initialSelection();
    } else {
      setSelectedCount(checkedOptions.length);
      setFinalList(checkedOptions);
    }
    setTimeout(() => {
      handleMissionSelections();
    }, 1000);
  };
  const clearSelectedOptions = () => {
    setSearchText('');
    initialSelection();
  };

  const renderOptionsList = () => {
    const filteredOptions = searchOptionsByName();
    return filteredOptions?.map(option => (
      <div key={option.id} className={`cursor-pointer my-2 mx-2 d-flex `}>
        <input
          type="checkbox"
          className="tnc-checkbox mx-3 cursor-pointer w-20"
          onChange={() => handleCheckboxChange(option)}
          checked={checkedOptions.some(item => item.id === option.id)}
          disabled={selectAll === true}
        />
        <span className="fs-14">{option.name}</span>
      </div>
    ));
  };

  const searchOptionsByName = () => {
    const lowerCaseSearchText = searchText.toLowerCase();
    return selectedData?.filter(option =>
      option.name.toLowerCase().includes(lowerCaseSearchText)
    );
  };

  return (
    <>
      <div className="form-group">
        <button
          className="btn btn-dropdown ht-36"
          type="button"
          data-toggle="dropdown"
          disabled={disabled}>
          <span className="float-left mt-1">Selected</span>
          <div className="float-right dropdown-toggle">
            <span className="state-dropdown-count mt-1">{selectedCount}</span>
          </div>
        </button>

        <div className={`dropdown-menu col-12 px-0 w-30`}>
          <div className="px-4">
            <div className="col-12 position-relative rounded p-0 d-flex justify-content-center">
              <span className="form-group col-12 rounded p-0">
                <div
                  type="button"
                  className="btn position-absolute"
                  style={{ right: '0px', top: '4px', zIndex: '1' }}>
                  <i className="fa fa-search form-control-feedback"></i>
                </div>
                <input
                  type="text"
                  name="search"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                  placeholder={searchPlaceholder}
                  className="col-12 standard-search-style rounded cursor-pointer"
                />
              </span>
            </div>
          </div>
          <div className={`col-12 px-0 `}>
            <div className="overflow-scroll-40vh">
              {searchOptionsByName()?.length > 0 ? (
                <div
                  key={'select-all'}
                  className={`cursor-pointer my-1 mx-2 d-flex`}>
                  <input
                    type="checkbox"
                    className=" tnc-checkbox mx-3 cursor-pointer w-20"
                    onChange={() => handleCheckboxChange('selectAll')}
                    checked={selectAll}
                  />
                  <span className="fs-14">{selectAllText}</span>
                </div>
              ) : (
                <div className="fs-14 text-center text-1C1C1C">
                  No Records Found !
                </div>
              )}
              {renderOptionsList()}
            </div>
            <hr />
            <div className="float-right">
              <div className="d-flex">
                <button
                  type="link"
                  className="btn text-007bff"
                  onClick={() => clearSelectedOptions()}>
                  Clear Selection
                </button>
                <button
                  className="btn btn-primary position-relative mx-4"
                  onClick={() => getSelectedOptionsCount()}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiSelectCheckbox;
