import { Common } from '../../constants';

export const Validations = {
  required: value => {
    return value ? value.toString().trim().length > 0 : false;
  },
  requiredSelect: value => {
    return value && value.length > 0;
  }
};

export const validate = (name, value) => {
  let isFieldValid = true;
  let msg = '';
  // console.log('VALIDATE() >>> name: ', name, ', value: ', value);
  switch (name) {
    case 'publishedFor':
      isFieldValid = Validations.requiredSelect(value);
      if (!isFieldValid)
        msg = Common.NGO.PAGE_ERRORS.MISSION_VISIBILITY_REQUIRED;
      break;
    case 'missionType':
      isFieldValid = Validations.required(value);
      if (!isFieldValid || (isFieldValid && value === 'Please select'))
        msg = 'Mission type required';
      break;
    case 'missionTitle':
      isFieldValid = Validations.required(value);
      if (!isFieldValid) msg = 'Mission title required';
      if (value && value.length < 3) msg = 'Minimum 3 characters required';
      if (value && value.length > 300) msg = 'Maximum 300 characters allowed';
      break;
    case 'primaryCauseArea':
      isFieldValid = Validations.required(value);
      // if (isFieldValid) {
      //   if (value.length > 5) msg = Common.NGO.PAGE_ERRORS.ERROR_MORE_THAN_5_AREAS;
      // } else {
      if (!isFieldValid)
        msg = Common.NGO.PAGE_ERRORS.PRIMARY_CAUSE_AREA_REQUIRED;
      // }
      break;
    case 'secondaryCauseArea':
      isFieldValid = Validations.required(value);
      if (isFieldValid) {
        if (value.length > 5)
          msg = Common.NGO.PAGE_ERRORS.ERROR_MORE_THAN_5_AREAS;
      } else {
        msg = Common.NGO.PAGE_ERRORS.SECONDARY_CAUSE_AREA_REQUIRED;
      }
      break;
    case 'targetBeneficiaries':
      isFieldValid = Validations.required(value);
      if (isFieldValid) {
        if (value.length > 5)
          msg = Common.NGO.PAGE_ERRORS.ERROR_MORE_THAN_5_BENEFICIARIES;
      } else {
        msg = Common.NGO.PAGE_ERRORS.BENEFICIARY_REQUIRED;
      }
      break;
    case 'sdg':
      isFieldValid = Validations.required(value);
      if (isFieldValid) {
        if (value.length > 5)
          msg = Common.NGO.PAGE_ERRORS.ERROR_MORE_THAN_5_SDG;
      } else {
        msg = Common.NGO.PAGE_ERRORS.SDG_REQUIRED;
      }
      break;
    case 'rolloverImage':
      isFieldValid = Validations.required(value);
      msg = isFieldValid ? '' : Common.NGO.PAGE_ERRORS.ROLLOVER_IMAGE_REQUIRED;
      break;
    case 'story':
      isFieldValid = Validations.required(value);
      if (!isFieldValid) msg = 'Story required';
      if (value && value.length < 50) {
        msg = 'Minimum 50 characters required (Including Style)';
      } else if (value && value.length > 20000) {
        msg = 'Maximum 20,000 characters required (Including Style)';
      }
      // if (value && value.length > 5000) msg = 'Maximum 5000 characters allowed';
      break;
    case 'activities':
      isFieldValid = Validations.required(value);
      if (!isFieldValid) msg = 'Activities required';
      if (value && value.length < 50) {
        msg = 'Minimum 50 characters required (Including Style)';
      } else if (value && value.length > 20000) {
        msg = 'Maximum 20,000 characters required (Including Style)';
      }

      // if (value && value.length > 5000) msg = 'Maximum 5000 characters allowed';
      break;
    case 'roadmap':
      isFieldValid = Validations.required(value);
      if (!isFieldValid) msg = 'Roadmap required';
      if (value && value.length < 50) {
        msg = 'Minimum 50 characters required (Including Style)';
      } else if (value && value.length > 20000) {
        msg = 'Maximum 20,000 characters required (Including Style)';
      }
      // if (value && value.length > 5000) msg = 'Maximum 5000 characters allowed';
      break;
    case 'missionCountry':
      isFieldValid = Validations.required(value);
      msg = isFieldValid ? '' : Common.NGO.PAGE_ERRORS.MISSION_COUNTRY_REQUIRED;
      break;
    case 'pinCode':
      isFieldValid = Validations.required(value);
      if (isFieldValid) {
        msg =
          value.toString().length !== 6
            ? Common.NGO.PAGE_ERRORS.INVALID_PIN_CODE
            : '';
      } else {
        msg = Common.NGO.PAGE_ERRORS.PIN_CODE_REQUIRED;
      }
      break;
    case 'missionState':
      isFieldValid = Validations.required(value);
      msg = isFieldValid ? '' : Common.NGO.PAGE_ERRORS.MISSION_STATE_REQUIRED;
      break;
    case 'missionDistrict':
      isFieldValid = Validations.required(value);
      msg = isFieldValid ? '' : Common.NGO.PAGE_ERRORS.MISSION_CITY_REQUIRED;
      break;
    case 'update_type':
      isFieldValid = Validations.required(value);
      msg = isFieldValid
        ? ''
        : Common.NGO.PAGE_ERRORS.MISSION_UPDATE_TYPE_REQUIRED;
      break;
    case 'funds_utilised':
      isFieldValid = Validations.required(value);
      msg = isFieldValid
        ? ''
        : Common.NGO.PAGE_ERRORS.MISSION_FUNDS_UTILIZED_REQUIRED;
      break;
    default:
  }
  return { isFieldValid, msg };
};
