import React from 'react';
import connect from 'react-redux/es/connect/connect';

import CreateModifyCampaign from '../components/campaigns/create';
import CampaignDetails from '../components/campaigns/detail';
import CampaignsList from '../components/campaigns/list';
import Header from '../components/header';
import PageContent from '../components/pageContent';
import PageContentWrapper from '../components/pageContentWrapper';
import { Routes } from '../constants';
import { isAuthorized } from '../helpers/auth-roles';

class CampaignsScreen extends React.Component {
  getPageTitleByPathName = () => {
    if (this.props.location.pathname.includes('ngo/project/detail/')) {
      return 'Project';
    }
    if (this.props.location.pathname.includes(Routes.NGO.CAMPAIGN_LIST)) {
      return 'All Projects';
    }
    if (this.props.location.pathname.includes(Routes.NGO.CAMPAIGN_CREATE)) {
      return 'Create Project';
    }
    if (this.props.location.pathname.includes(Routes.NGO.CAMPAIGN_EDIT)) {
      return 'Edit Project';
    }
  };

  getCampaignMainScreenByPathName = ({
    id,
    isModifyCampaign,
    isCreateCampaign,
    isAdmin,
    permissions
  }) => {
    if (this.props.location.pathname.includes('ngo/project/detail/')) {
      return (
        <CampaignDetails
          campaignId={id}
          isAdmin={isAdmin}
          history={this.props.history}
          isNgoApproved={this.props.isNgoApproved}
          permissions={permissions}
        />
      );
    }
    if (this.props.location.pathname.includes(Routes.NGO.CAMPAIGN_LIST)) {
      return <CampaignsList permissions={permissions} />;
    }

    if (
      this.props.location.pathname.includes(Routes.NGO.CAMPAIGN_CREATE) ||
      this.props.location.pathname.includes(Routes.NGO.CAMPAIGN_EDIT)
    ) {
      return (
        <CreateModifyCampaign
          history={this.props.history}
          isNgoApproved={this.props.isNgoApproved}
          isCreateCampaign={isCreateCampaign}
          campaignModified={this.campaignModified}
          isModify={isModifyCampaign}
          campaignId={id}
          permissions={permissions}
        />
      );
    }
  };

  getRole = () => {
    const policies = JSON.parse(window.localStorage.getItem('userPolicies'));
    if (policies) {
      if (policies[0].role === 'NGO_Superadmin') {
        return 'NGO_Superadmin';
      }
    }
    return null;
  };

  render() {
    let { id } = this.props.match.params;
    let isModifyCampaign =
      this.props.location.pathname.indexOf('/ngo/project/edit/') > -1 && !!id;
    let isCreateCampaign =
      this.props.location.pathname.indexOf('/ngo/project/create') > -1 && !id;
    let isAdmin = this.props.userInfo
      ? this.props.userInfo.user_type === 1
      : false;
    let email = '';
    // const role = this.getRole();
    const permissions = this.props.permissions;
    if (window.location.href.includes('email='))
      email = window.location.href.split('email=')[1];
    const isImpersonator = window.location.href.includes('email=');

    return (
      <React.Fragment>
        <Header pageTitle={this.getPageTitleByPathName()}>
          {(isAuthorized(permissions) || isImpersonator) && (
            <li className="nav-item active">
              <button
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_NEW_CREATE_PROJECT}${
                      isImpersonator ? `?email=${email}#metta-analyst` : ''
                    }`,
                    '_blank'
                  );
                }}
                className="btn btn-primary btn-lg mr-3"
                exact="true">
                <i className="fas fa-plus-square fa-1x mr-2 text-light"></i>
                Create Project
              </button>
            </li>
          )}
        </Header>
        <PageContentWrapper>
          <PageContent
            mainScreen={this.getCampaignMainScreenByPathName({
              id,
              isCreateCampaign,
              isModifyCampaign,
              isAdmin,
              permissions
            })}
          />
        </PageContentWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.login.userInfo,
  isNgoApproved: state.ngo.ngoProfile.isProfileApproved
});

export default connect(mapStateToProps, null)(CampaignsScreen);
