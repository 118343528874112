export function financialYears(startDate, endDate) {
  // April is the start of fiscal year
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const years = [];
  var count = endYear - startYear;
  for (let year = startYear; year <= endYear; year++) {
    years.push({
      label: `1st Apr ${year} - 31st Mar ${year + 1}`,
      start_date: `${year}-04-01`,
      end_date: `${year + 1}-03-31`,
      id: `FY ${year}-${(year % 100) + 1}`,
      name: `FY ${year}-${(year % 100) + 1}`,
      fy_id: count--
    });

    for (let quarter = 1; quarter <= 4; quarter++) {
      let fiscalStartMonth = 1 + quarter * 3;
      let fyYear = year;
      let endDay = 31;
      let suffix = 'st';
      if (fiscalStartMonth === 13) {
        fyYear = year + 1;
        fiscalStartMonth = 1;
      }
      if (fiscalStartMonth + 2 === 6 || fiscalStartMonth + 2 === 9) {
        endDay = 30;
        suffix = 'th';
      }

      const quarterLabel = `Q${quarter} FY ${fyYear}`;
      const quarterStartDate = new Date(`${fyYear}-${fiscalStartMonth}-01`);
      const quarterEndDate = new Date(`${fyYear}-${fiscalStartMonth + 2}- 30`);

      if (fyYear <= endYear && fyYear >= startYear) {
        const formattedStartMonth = (quarterStartDate.getMonth() + 1)
          .toString()
          .padStart(2, '0');
        const formattedEndMonth = (quarterEndDate.getMonth() + 1)
          .toString()
          .padStart(2, '0');
        years.push({
          label: `1st ${quarterStartDate.toLocaleDateString('en-US', {
            month: 'short'
          })} ${fyYear} -  ${endDay}${suffix}${' '}${quarterEndDate.toLocaleDateString(
            'en-US',
            {
              month: 'short'
            }
          )} ${fyYear}`,
          start_date: `${fyYear}-${formattedStartMonth}-01`,
          end_date: `${fyYear}-${formattedEndMonth}-${endDay}`,
          id: `${quarterLabel}-${(fyYear % 100) + 1}`,
          name: `${quarterLabel}-${(fyYear % 100) + 1}`
        });
      }
    }
  }
  return years?.reverse();
}
