import { Common, EndPoint } from '../constants';
import HttpRequest from '../helpers/http-request';

const CampaignService = {
  getAllCampaignsForNgo: function(ngoId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'CAMPAIGN_LIST', 'HIROKU');
    if (window.location.href.includes('email=')) {
      let email = window.location.href.split('email=')[1];

      apiUrl = apiUrl.replace('{ngoId}', ngoId);
      apiUrl = apiUrl + '?email=' + `${email}`;
    } else {
      apiUrl = apiUrl.replace('{ngoId}', ngoId);
    }
    return instance.get(apiUrl);
  },
  deleteDraftCampiagn: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'CAMPAIGN_DELETE', 'HIROKU');
    apiUrl = apiUrl.replace('{campaignId}', campaignId);
    return instance.post(apiUrl);
  },
  duplicateCampaign: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'DUPLICATE_CREATE', 'HIROKU');
    apiUrl = apiUrl.replace('{campaignId}', campaignId);
    return instance.post(apiUrl);
  },

  getAllCampaignsForAdmin: function() {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'ADMIN_CAMPAIGN_LIST', 'HIROKU');
    return instance.get(apiUrl);
  },

  getCampaign: function(campaignId, mode) {
    let instance = HttpRequest.getInstance('HIROKU');
    let endPointKey, isQstr;
    switch (mode) {
      case 'preview':
        endPointKey = 'CAMPAIGN_PREVIEW';
        isQstr = true;
        break;

      case 'edit':
        endPointKey = 'CAMPAIGN_EDIT';
        isQstr = false;
        break;

      case 'detail':
      default:
        endPointKey = 'CAMPAIGN_DETAIL';
        isQstr = true;
    }
    let apiUrl = EndPoint.getApiUrl('NGO', endPointKey, 'HIROKU');
    if (!isQstr) {
      apiUrl = apiUrl.replace('{campaignId}', campaignId);
    }
    return instance.get(
      apiUrl,
      isQstr
        ? {
            params: {
              campaignId: campaignId
            }
          }
        : null
    );
  },
  createOrModifyCampaign: function(data, status) {
    let instance = HttpRequest.getInstance('HIROKU');

    let pathKey, isQstr;

    switch (status) {
      case Common.NGO.CAMPAIGN_STATUS.APPROVED:
        pathKey = 'APPROVE_CAMPAIGN';
        isQstr = true;
        break;

      case Common.NGO.CAMPAIGN_STATUS.REJECTED:
        pathKey = 'REJECT_CAMPAIGN';
        isQstr = true;
        break;

      case Common.NGO.CAMPAIGN_STATUS.DRAFT:
      case Common.NGO.CAMPAIGN_STATUS.UNDER_REVIEW:
      case Common.NGO.CAMPAIGN_STATUS.ON_GOING_PROJECT:
      default:
        pathKey = 'CAMPAIGN_CREATE';
        isQstr = false;
        break;
    }
    let apiUrl = EndPoint.getApiUrl('NGO', pathKey, 'HIROKU');
    if (window.location.href.includes('email=')) {
      let email = window.location.href.split('email=')[1];
      apiUrl = apiUrl + '?email=' + `${email}`;
    }
    return instance.post(
      apiUrl,
      isQstr ? null : data,
      isQstr
        ? {
            params: {
              campaignId: data.campaignId
            }
          }
        : null
    );
  },
  getAllProjects: function(ngoId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'ALL_PROJECTS_DASHBOARD', 'HIROKU');
    apiUrl = apiUrl.replace('{ngoId}', ngoId);
    return instance.get(apiUrl);
  },
  getFundInformation: function(ngoId) {
    let instance = HttpRequest.getInstanceS('SOCIONEXT');
    let apiUrl =
      EndPoint.getBaseUrl('SOCIONEXT') +
      '/corporate/get_funds_utilisation/' +
      ngoId;
    apiUrl = apiUrl.replace('{ngoId}', ngoId);
    return instance.get(apiUrl);
  },
  getAllProjectActivites: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'PROJECT_ACTIVITIES', 'HIROKU');
    apiUrl = apiUrl.replace('${campaignId}', campaignId);
    return instance.get(apiUrl);
  },
  getGoalsIndicators: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'PROJECT_GOALS', 'HIROKU');
    apiUrl = apiUrl.replace('${campaignId}', campaignId);
    return instance.get(apiUrl);
  },

  getMNEBeneficiaries: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'PROJECT_BENEFICIARIES', 'HIROKU');
    apiUrl = apiUrl.replace('{campaignId}', campaignId);
    return instance.get(apiUrl);
  },
  getMNEBeneficiariesDynamic: function(projectId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl(
      'NGO',
      'PROJECT_BENEFICIARIES_DYNAMIC',
      'HIROKU'
    );
    apiUrl = apiUrl.replace('${projectId}', projectId);
    return instance.get(apiUrl);
  },
  removeMNEBeneficiariesDynamic: function(beneficiaryId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl(
      'NGO',
      'REMOVE_PROJECT_BENEFICIARIES_DYNAMIC',
      'HIROKU'
    );
    apiUrl = apiUrl.replace('${beneficiaryId}', beneficiaryId);
    return instance.put(apiUrl);
  },
  selectProjectBeneficiariesType: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.put(
      EndPoint.getApiUrl('NGO', 'SELECT_PROJECT_BENEFICIARIES_TYPE', 'HIROKU'),
      data
    );
  },
  getProjectExpenses: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'PROJECT_EXPENSES', 'HIROKU');
    apiUrl = apiUrl.replace('${campaignId}', campaignId);
    return instance.get(apiUrl);
  },
  getGoalHistory: function(indicatorId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'INDICATORS_HISTORY', 'HIROKU');
    apiUrl = apiUrl.replace('{indicatorId}', indicatorId);
    return instance.get(apiUrl);
  },
  getTimeline: function(indicatorId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'TIMELINE', 'HIROKU');
    apiUrl = apiUrl.replace('{campaignId}', indicatorId);
    return instance.get(apiUrl);
  },
  getExpenseCategory: function() {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'EXPENSE_CATEGORY', 'HIROKU');
    return instance.get(apiUrl);
  },
  getExpenseType: function() {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'EXPENSE_TYPE', 'HIROKU');
    return instance.get(apiUrl);
  },

  getExpenseSubCategory: function(expenseId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'EXPENSE_SUB_CATEGORY', 'HIROKU');
    apiUrl = apiUrl.replace('{expenseId}', expenseId);
    return instance.get(apiUrl);
  },
  getAllExpenseSubCategory: function() {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl(
      'NGO',
      'All_EXPENSE_SUB_CATEGORY',
      'HIROKU'
    );
    return instance.get(apiUrl);
  },

  getActualAndPlannedBudget: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl(
      'NGO',
      'ACTUAL_AND_PLANNED_BUDGET',
      'HIROKU'
    );
    apiUrl = apiUrl.replace('{campaignId}', parseInt(campaignId));
    return instance.get(apiUrl);
  },

  getFrequencyListMasterData: function() {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'GET_MASTER_FREQUENCY', 'HIROKU');
    return instance.get(apiUrl);
  },

  getUomListMasterData: function() {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'GET_MASTER_UOM', 'HIROKU');
    return instance.get(apiUrl);
  },

  campaignProposalStatus: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'PROJECTPROPOSALSTATUS', 'HIROKU');
    apiUrl = apiUrl.replace('{campaignId}', parseInt(campaignId));
    return instance.get(apiUrl);
  },

  campaignProposalModificationStatus: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl(
      'NGO',
      'CAMPAIGNMODIFICATIONPROPOSALSTATUS',
      'HIROKU'
    );
    apiUrl = apiUrl.replace('${campaignId}', parseInt(campaignId));
    return instance.get(apiUrl);
  },

  campaignProposalModificationAccept: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl(
      'NGO',
      'CAMPAIGNMODIFICATIONPROPOSALACCEPT',
      'HIROKU'
    );
    apiUrl = apiUrl.replace('{campaignId}', parseInt(campaignId));
    return instance.post(apiUrl);

    // let instance = HttpRequest.getInstance('HIROKU');
    // return instance.put(
    //   EndPoint.getApiUrl('NGO', 'CAMPAIGNMODIFICATIONPROPOSALACCEPT', 'HIROKU'),
    //   data
    // );
  },

  startProject: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'START_PROJECT', 'HIROKU');
    apiUrl = apiUrl.replace('{campaignId}', parseInt(campaignId));
    return instance.put(apiUrl);
  },

  createOrModifyActivities: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.post(
      EndPoint.getApiUrl('NGO', 'ACTIVITIES_CREATE', 'HIROKU'),
      data
    );
  },

  modifyProjectActivities: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.put(
      EndPoint.getApiUrl('NGO', 'PROJECT_ACTIVITIES', 'HIROKU'),
      data
    );
  },

  createOrModifyExpense: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.post(
      EndPoint.getApiUrl('NGO', 'EXPENSE_CREATE', 'HIROKU'),
      data
    );
  },

  updateActivity: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.post(
      EndPoint.getApiUrl('NGO', 'UPDATE_ACTIVITY', 'HIROKU'),
      data
    );
  },
  getActivitiesById: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.get(
      EndPoint.getApiUrl('NGO', 'ACTIVITIES_DETAIL', 'HIROKU').replace(
        '{campaignId}',
        campaignId
      )
    );
  },
  deleteBudgetSection: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.post(
      EndPoint.getApiUrl('NGO', 'DELETE_BUDGET_SECTION', 'HIROKU'),
      data
    );
  },
  getNgoEmailByCampaign: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'GET_NGO_EMAIL', 'HIROKU');
    apiUrl = apiUrl.replace('${campaign_id}', campaignId);
    return instance.get(apiUrl);
  },
  acceptCampaignMod: function(campaign_id) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'ACCEPT_MOD_REQ', 'HIROKU');
    apiUrl = apiUrl.replace('{campaign_id}', campaign_id);
    return instance.post(apiUrl);
  },
  rejectCampaignMod: function(campaign_id) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'REJECT_MOD_REQ', 'HIROKU');
    apiUrl = apiUrl.replace('{campaign_id}', campaign_id);
    return instance.post(apiUrl);
  },
  createOrModifyIndicators: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.post(
      EndPoint.getApiUrl('NGO', 'INDICATORS_CREATE', 'HIROKU'),
      data
    );
  },
  createOrModifyBeneficiaries: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.post(
      EndPoint.getApiUrl('NGO', 'BENEFICIARY_CREATE', 'HIROKU'),
      data
    );
  },
  creategoalHistory: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.post(
      EndPoint.getApiUrl('NGO', 'INDICATORS_HISTORY_CREATE', 'HIROKU'),
      data
    );
  },
  createTimeline: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    return instance.post(
      EndPoint.getApiUrl('NGO', 'TIMELINE_CREATE', 'HIROKU'),
      data
    );
  },
  updateProject: function(data) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'UPDATE_PROJECT', 'HIROKU');
    apiUrl = apiUrl.replace('${campaignId}', parseInt(data.campaign_id));

    return instance.put(apiUrl, data);

    // let instance = HttpRequest.getInstance('HIROKU');
    // return instance.put(
    //   EndPoint.getApiUrl('NGO', 'UPDATE_PROJECT', 'HIROKU'),
    //   data
    // );
  },
  checkForCorpInterest: function(campaignId) {
    let instance = HttpRequest.getInstance('HIROKU');
    let apiUrl = EndPoint.getApiUrl('NGO', 'CHECK_FOR_CORP_INTEREST', 'HIROKU');
    apiUrl = apiUrl.replace('${campaign_id}', campaignId);
    return instance.get(apiUrl);
  }
};

export default CampaignService;
